import { determineEnvironment } from "../../config/config";
import { pathToUrl } from "./URL";

export function fetchRequest(method, urlPath, options = {}, withAuth = true) {
  const newOptions = {
    ...options,
    method,
    headers: options.headers || {},
  };

  if (withAuth) {
    newOptions.headers.Authorization = Cookies.get("cb_login");
  }

  return (
    fetch(pathToUrl(urlPath), newOptions).then(async (response) => {
      let body
      if (!response.ok) {
        if (response.status === 422) {
          body = await response.text()
          return ({"422": response.status, body})
        } if (response.status === 429) {
          body = await response.text()
          return ({"429": response.status, body})
        } if (response.status === 400) {
          body = await response.text()
          return ({"400": response.status, body})
        }
        throw Error(response.statusText);
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json();
      }
      return response.text();
    }).then((parsed) => {
      if (!Array.isArray(parsed) && typeof parsed === "object" && ("422" in parsed || "400" in parsed || "429" in parsed)) {
        throw Error(parsed.body)
      } else {
        return parsed;
      }
    })
  )
}

export function bsliGet(urlPath) {
  return fetchRequest("GET", urlPath, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    }
  });
}

export function bsliGetNoAuth(urlPath) {
  return fetchRequest("GET", urlPath, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    }
  }, false);
}

export function bsliDelete(urlPath) {
  return fetchRequest("DELETE", urlPath, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    }
  });
}

export function bsliPost(urlPath, data) {
  let strdata =  (typeof data !== 'string') ?  JSON.stringify(data || {}) : data;
  return fetchRequest("POST", urlPath, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: strdata
  });
}

export function bsliPostCSP(urlPath, data) {
  let strdata =  (typeof data !== 'string') ?  JSON.stringify(data || {}) : data;
  return fetchRequest("POST", urlPath, {
    cache: "no-cache",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: strdata
  });
}

export function bsliPostScores(urlPath, data) {
  let strdata =  (typeof data !== 'string') ?  JSON.stringify(data || {}) : data;

  return fetchRequest("POST", urlPath, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: strdata
  });
}

export function bsliPut(urlPath, data) {
  let strdata =  (typeof data !== 'string') ?  JSON.stringify(data || {}) : data;
  return fetchRequest("PUT", urlPath, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: strdata
  });
}

export function bsliPutCSP(urlPath, data, xhrCallback, metadata) {
//  console.log("bsliPutCSP url = " + urlPath);
//  console.log("bsliPutCSP data = ", data);

  return new Promise((resolve, reject) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.onload = function(e) {
        if (xhr.status == 200) resolve(xhr.response);
        else reject(xhr.statusText);
      };
      xhr.open("PUT", urlPath, true);
      xhr.setRequestHeader("x-amz-meta-payload", metadata);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
      xhr.send(data);

      if (xhrCallback) {
        xhrCallback()
      }
    } catch (e) {
      console.error(
        "getAs failed with url=" + url + ", responseType=" + responseType
      );
      reject(xhr.statusText);
    }
  })
}

export function getAs(url, responseType) {
  return new Promise((resolve, reject) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.onload = function(e) {
        if (xhr.status == 200) resolve(xhr.response);
        else reject(xhr.statusText);
      };
      xhr.open("GET", url, true);
      xhr.setRequestHeader("Authorization", Cookies.get("cb_login"));
      xhr.responseType = responseType;
      xhr.send();
    } catch (e) {
      console.error(
        "getAs failed with url=" + url + ", responseType=" + responseType
      );
      reject(xhr.statusText);
    }
  })
}

// test code
let testScenarios = {
  //from scorable.js
  scorableSave: {
    enabled: false,
    method: "PUT",
    url: /sections\/\d+\/assignments\/\d+\/scorables/,
  },
  //checkAccessAndGoToScoring
  scoreStatus: {
    enabled: false,
    method: "GET",
    url: /scorables\/\d+\/scoreStatus/,
  },
  WRSave: {
    enabled: false,
    method: "POST",
    url: /sections\/\d+\/assignments\/\d+\/uploads/,
  },
  WRViewFile: {
    enabled: false,
    method: "POST",
    url: /sections\/\d+\/assignments\/\d+\/uploads\/\d+\/download/,
  },
  WRFinalSubmit: {
    enabled: false,
    method: "POST",
    url: /sections\/\d+\/assignments\/\d+\/scorables\/\d+/,
  },
  finalizeCourse: { enabled: false, method: "PUT", url: /finalizeCourse/ },
  rubric: { enabled: false, method: "GET", url: /assignments\/\d+\/rubric/ },
  profile: { enabled: false, method: "GET", url: /education-periods/ },
  "current courses": {
    enabled: false,
    method: "GET",
    url: /admin\/current-course-instances/,
  },
  tsp1: {
    enabled: false,
    method: "PUT",
    url: /admin\/scoring-access-descriptors/,
  },
  tsp2: {
    enabled: false,
    method: "GET",
    url: /admin\/scoring-access-descriptors/,
  },
  "create course": {
    enabled: false,
    method: "POST",
    url: /admin\/create-course/,
  },
  "delete score": {
    enabled: false,
    method: "GET",
    url: /scorables\/\d+\/deleteScore/,
  },
  reports: { enabled: false, method: "GET", url: /reports/ },
};

// should we do a failure test on this url?
function shouldTestFail(url, method) {
  let env = determineEnvironment()
  if (env === "local") {
    return Object.values(testScenarios).some((scenario) => {
      let found =
        scenario.enabled &&
        scenario.method === method && // all method attributes in this file are upper case
        scenario.url.test(url);
      if (found) {
        //console.log('Going to artificially fail an ajax call for url ' + scenario.url + ' and method ' + scenario.method)
      } else {
        //console.log('url ' + url + ' did not match url ' + scenario.url + ' and method ' + scenario.method)
      }
      return found;
    });
  } else {
    return false;
  }
}
