import React                            from 'react'
import { StudentContentOverview }       from './views/studentContentOverview'
import { TeacherContentOverview }       from './views/teacherContentOverview'
import HelpComp                         from './views/help'
import { getCurrentPerson }             from '../entities/person.jsx'
import { getModuleHolder }              from '../entities/modules'
import { isCSP, isSeminar, isResearch, isWeCourse }           from '../entities/courseinstance'
import { enrollmentForSection }                               from '../enrollment/entities/enrollment'
import { showLoadingView }                                    from '../_core/views/loading_view'
import { bsliGet, bsliPostCSP }                               from '../_core/util/request'
import { entitiesToChr, strToCheckboxes, checkboxesToStr }    from '../utility/util'
import { cmsHelp, contentField, cmsDocuments }                from '../content/static_content'
import { getCourseInstanceId, getCourseInstanceBySectionId }  from '../entities/courseinstance'
import { showMainContent, showCourseContent }                 from '../main/instruction_controller'
import { filterModules }                                      from '../entities/modules'
import { filterStudentModules }                               from './ContentOverview'
import { getStudentProgress }                                 from '../progress/entities/progress'

function matchesUser(faq) {
  var faqRoles = (faq['Target Audience'] || '').split(', ')
  // APPortfolioCoordiator and APCoordinator should be treated the same
  // that is, a faq with one should have the other
  let roleA = 'ApPortfolioCoordinator'
  let roleB = 'ApCoordinator'
  if (_.contains(faqRoles, roleA)) {
    faqRoles.push(roleB)
  } else if (_.contains(faqRoles, roleB)) {
    faqRoles.push(roleA)
  }
  var currentUserRoles = getCurrentPerson().roles
  return _.intersection(faqRoles, currentUserRoles).length > 0
}

//matches course if current course id matches one of the ids of the faq
function matchesCourse(faq, courseId) {
  let ids = faq['Course Instance Id'] // string, comma separated list of ids
  if (ids) {
    return _.some(ids.split(','), id => id.trim() === courseId)
  } else {
    return false
  }
}

function show(faqs, keyword, courseInstanceId) {
  if (courseInstanceId) {
    showLoadingView('region-course-content');
  } else {
    showLoadingView('region-main-content');
  }

  let predicate
  if (courseInstanceId) {
    predicate = faq => matchesUser(faq) && matchesCourse(faq, courseInstanceId)
  } else {
    predicate = faq => matchesUser(faq) && !faq['Course Instance Id']
  }

  //logger.debug('show faqs',faqs)
  var filtered = _.filter(faqs, predicate)
  //logger.debug('show filtered faqs',filtered)

  _.each(filtered,faq => {
    faq.Question = entitiesToChr(faq.Question)
    faq.Answer = entitiesToChr(faq.Answer)
  })

  if (courseInstanceId) {
    let props = { content: filtered, courseInstanceId: courseInstanceId, keyword: keyword }
    const element = <HelpComp parms={props} />
    showCourseContent(element)
  } else {
    let props = { content: filtered, courseInstanceId: null, keyword: keyword }
    const element = <HelpComp parms={props} />
    showMainContent(element, 'Digital Portfolio Help')
  }
  document.dispatchEvent(new CustomEvent('pagevisit', {detail: 'help'}))
}

export async function showHelp(keyword) {
  var faqs = await cmsHelp();
  show(faqs, keyword)
}

// content:help:section:show
export async function showCourseInstanceHelp(courseInstanceId, keyword) {
  var faqs = await cmsHelp();
  show(faqs, keyword, courseInstanceId)
}

// content:help:section:show
export async function showOverview(sectionId, keyword) {
  //contentController.showOverview(sectionId, keyword)
  await showOverviewAsync(sectionId, keyword)
  .catch(e => {
    //console.log('showOverview has error ',e)
  })
}

async function showOverviewAsync(sectionId, keyword) {
  //console.log('showOverviewAsync called with sectionId=' + sectionId + ', keyword ' + keyword)

  let courseInstance = getCourseInstanceBySectionId(sectionId)
  let courseInstanceId = courseInstance ? courseInstance.courseId : getCourseInstanceId()
  let moduleHolder = null
  let docs = []

  await Promise.allSettled([getModuleHolder(courseInstanceId), cmsDocuments()])
    .then(results => {
      let result = (results && results.length > 0) ? results[0] : null
      if (!!result && result.status==='fulfilled') {
        moduleHolder = result.value
      } else {
        console.error('getModuleHolder should at least return an empty value')
      }
      result = (results && results.length > 1) ? results[1] : null
      if (!!result && result.status==='fulfilled') {
        docs = result.value
      }
    })
    .catch(err => {
      let msg = 'showOverviewAsync had an error'
    })

  let role = getCurrentPerson().isStudent() ? 'student' : 'teacher';
  let textContent = await contentField(role + '_class_summary')

  let roles = getCurrentPerson().roles;
  let filtered = _.filter(docs, doc => {
    let publishable = doc.publishable === 1 || doc.publishable === '1'
    return doc['Target Audience'] &&
      _.intersection(doc['Target Audience'].split(', '), roles).length &&
      !publishable &&
      doc.LocationId && _.contains(doc.LocationId.split(', '), courseInstanceId)
  })

  let partition = filtered && _.partition(filtered, doc => {
    return !(doc.File === null || doc.File.length === 0)
    //doc.File !== null 
  }); //two arrays: files, blurb
  //console.log('showOverviewAsync partition',partition)

  let elem
  if (role === 'student') {
    let studentProps = await getStudentProps(sectionId, moduleHolder)
    elem = <StudentContentOverview
      sectionId={sectionId} moduleHolder={moduleHolder}
      studentProps={studentProps}
      textContent={textContent} partition={partition} />
  } else if (role === 'teacher') {
    let selections = await getTeacherSelections(sectionId)
    let saveSelections = getTeacherSaveSelections()
    elem = <TeacherContentOverview
      sectionId={sectionId}
      selections={selections} saveSelections={saveSelections}
      textContent={textContent} partition={partition} />
  }

  showCourseContent(elem)
  document.dispatchEvent(new CustomEvent('pagevisit', { detail: 'overview' }))
}

export async function getTeacherSelections(sectionId) {
  let teacherSelections = false
  try {
    let url = 'sections/sectionInfo/' + sectionId
    const json = await bsliGet(url)

    if (json.detailMessage) {
      teacherSelections = getTeacherSelectionsEmpty()
    } else {
      teacherSelections = {
        // these are for Seminar and Research
        department:             json.deptOrProgLang,
        fulfill:                json.deptReqDescOrCurriculum,
        // these are for Seminar 
        themes:                 json.themes,
        grade:                  json.grade,
        schedule:               json.schedule, 
        // these are for CSP
        languages:              strToCheckboxes(json.deptOrProgLang),
        langOther:              json.deptOrProgLangOther,
        curriculums:            strToCheckboxes(json.deptReqDescOrCurriculum),
        curriculumOther:        json.curriculumOther
      }
    }
  } catch (e) {
   // console.log('teacherSelections error returning empty data')
    teacherSelections = getTeacherSelectionsEmpty()
  }
  //console.log('teacherSelections returning')
  return teacherSelections
}

export function getTeacherSaveSelections() {
  let saveSelections = () => { }
  if (isCSP()) {
    saveSelections = saveCSPTeacherSelections
  } else if (isSeminar()) {
    saveSelections = saveSeminarTeacherSelections
  } else if (isResearch()) {
    saveSelections = saveResearchTeacherSelections
  }
  return saveSelections
}

async function getStudentProps(sectionId, moduleHolder) {
  let notWeCourse = !isWeCourse()
  let enrollment = enrollmentForSection(sectionId)
  //console.log('getStudentProps has enrollment',enrollment)
  //this.showProgress = notWeCourse
  if (notWeCourse) {
    var moduleHolder = filterModules(moduleHolder, false)
    filterStudentModules(moduleHolder)
    const studentProgress = await getStudentProgress(sectionId, moduleHolder)
    return {
      isStudent: true,
      studentProgress: studentProgress,
      moduleHolder: moduleHolder,
      sectionId: sectionId,
      isWe: false,
      permissionToReuse: !!enrollment.enrollment.permissionToReuse,
      enrollment: enrollment
    }
  } else {
    return false
  }
}

async function saveCSPTeacherSelections(sectionId,selections) {
  try {
    let url = 'sections/sectionInfo'

    let data = {
      sectionId:                  sectionId,
      deptOrProgLang:             checkboxesToStr(selections.languages),
      deptOrProgLangOther:        selections.langOther,
      deptReqDescOrCurriculum:    checkboxesToStr(selections.curriculums),
      curriculumOther:            selections.curriculumOther
    }
    await bsliPostCSP(url,data)  
    //console.log('saveTeacherSelections POST worked')
  } catch(e) {
    console.error('saveTeacherSelections POST failed',e)
  }
}

async function saveSeminarTeacherSelections(sectionId,selections) {
  try {
    let url = 'sections/sectionInfo'
    let data = {
      sectionId:                  sectionId,
      deptOrProgLang:             selections.department,
      deptReqDescOrCurriculum:    selections.fulfill,
      themes:                     selections.themes,
      grade:                      selections.grade,
      schedule:                   selections.schedule 
    }
    await bsliPostCSP(url,data)  
    //console.log('saveTeacherSelections POST worked')
  } catch(e) {
    console.error('saveTeacherSelections POST failed',e)
  }
}

async function saveResearchTeacherSelections(sectionId,selections) {
  try {
    let url = 'sections/sectionInfo'

    let data = {
      sectionId:                  sectionId,
      deptOrProgLang:             selections.department,
      deptReqDescOrCurriculum:    selections.fulfill,
    }
    await bsliPostCSP(url,data)
    //console.log('saveTeacherSelections POST worked')
  } catch(e) {
    console.error('saveTeacherSelections POST failed',e)
  }
}

function getTeacherSelectionsEmpty() {
  return {
    department: '',
    fulfill: '',
    grades: {}, //{'9th grade': true}
    languages: {}, //strToCheckboxes(json.lang), //{EarSketch: true},
    langOther: '',
    curriculums: {},
    curriculumOther: '',
    themes:'',
    grade:'',
    schedule:'',
  }
}
