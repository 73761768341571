import React, { Fragment, useEffect, useState } from 'react';
import SectionTableComp from './sectionTable';
import { AlertComp, errorCallback, staticCallback } from '../../_core/views/alert.jsx';
import { contentField, showLoadingText } from '../../content/static_content';
import { Legend } from './legend';

const CoordinatorLayoutComp = (props) => {
  const [alertProps, setAlertProps] = useState(false);
  const [staticHtml, setStaticHtml] = useState('');

  useEffect(() => {
    onRender();
  }, []);

  useEffect(() => {
    onRender();
  }, [props.parms.isWe]);

  const onRender = () => {
    contentField('coordinator_progress')
      .then((res) => {
        if (props.parms.isWe) {
          showLoadingText(res, { setState: setStaticHtml, state: staticHtml, isFunctional: true, alertProps, setAlertProps }, staticCallback, errorCallback, null, 'WE_PROGRESS');
        } else {
          showLoadingText(res, { setState: setStaticHtml, state: staticHtml, isFunctional: true, alertProps, setAlertProps }, staticCallback, errorCallback, null, 'DP_PROGRESS');
        }
      });
  };

  const legendProps = { isStudent: false, isWe: props.parms.isWe };

  return (
    <Fragment>
      <h3 id='title' className='h1' aria-labelledby='title alert-container region-static-content'>
        Progress
      </h3>
      <div id='alert-container'>
        <AlertComp params={alertProps} />
      </div>
      <div id='region-static-content' dangerouslySetInnerHTML={{ __html: staticHtml }} />
      <div id='region-legend'>
        <Legend {...legendProps} />
      </div>
      <div id='region-modal'></div>
      <div id='region-section-table' className='table-responsive'>
        {props.parms.coordinatorProgress.sections.length > 0 ? (
          <SectionTableComp parms={props.parms} />
        ) : (
          <p>There are currently no sections available for this course.</p>
        )}
      </div>
    </Fragment>
  );
};

export default CoordinatorLayoutComp;
