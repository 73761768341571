import React                        from 'react';
import ReactDOM                     from 'react-dom'
import { reactRemove }              from '../../utility/util'
import { YellowButton }             from "@cb/apricot-react";
import { SelectComp }               from './select'
import { AlertComp }                from '../../_core/views/alert.jsx'
import { searchForStudent, jsonToStudent, getPlagiarizedLesson }        from '../entities/plagiarism.jsx'
import { showLoadingView, closeLoadingView }                            from '../../_core/views/loading_view'
import { isValidStudentId }                                             from '../../utility/util'

export default class PlagiarismSearchComp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            student: props.student,
            studentDefault: props.studentDefault,
            alertProps: false
        }
        _.bindAll(this, 'onAPNumberChange', 'updateSelectedValue', 'getResults', 'resetForm', 'clearErrorMessage')
    }

    componentDidMount() {
        this.checkButtonAvailability()
    }

    // resets the form keeping the given apNumber intact
    resetForm(apNumber) {
        this.setState(
            (state, props) => {
                let newStudent = _.extend(_.clone(state.studentDefault),
                    {
                        apNumber: apNumber
                    }
                )
                return { student: newStudent }
            }, () => {
            }
        )
    }

    /*
    select * from dbo.ap_number
    where education_period_cd=20
    limit 50
    */
    //test palm numbers
    //49X2U607 dduserff research/Academic Paper

    render() {
        //console.log('render called',this.state)
        return (
            <div id='plagiarism-form'>
                <p className='cb-margin-bottom-16 cb-margin-up-16'>Enter AP number/AP ID and select course and lesson to view plagiarism report.</p>
                <div className="row">
                    <div className="cb-width-100">
                        <div id="alertWrapper">
                          <AlertComp params={{...this.state.alertProps}}></AlertComp>
                        </div>
                        <div className="cb-input form-group cb-margin-bottom-16">
                            <input type="text" maxLength="8"
                                placeholder="Enter AP Number/AP ID" aria-label="Enter AP Number/AP ID"
                                id="plagiarismInputAPNumber"
                                onChange={this.onAPNumberChange} />
                        </div>
                        <div className="form-group cb-margin-bottom-16" id="plagiarismCourseSelectRegion">
                            <SelectComp
                                type='course'
                                options={this.state.student.courseOptions}
                                selectedValue={this.state.student.selectedCourse}
                                defaultLabel='Select Course'
                                updateSelectedValue={this.updateSelectedValue}
                            />
                         </div>
                        <div className="form-group cb-margin-bottom-16" id="plagiarismLessonSelectRegion">
                            <SelectComp
                                type='lesson'
                                options={this.state.student.lessonOptions}
                                selectedValue={this.state.student.selectedLesson}
                                defaultLabel='Select Performance Task Component'
                                updateSelectedValue={this.updateSelectedValue}
                            />
                        </div>
                        <div className="form-group cb-margin-bottom-4">
                            <YellowButton onClick={this.getResults} title="View Student Information" disabled={!this.state.student.apNumber && !this.state.student.selectedCourse && !this.state.student.selectedLesson}>View</YellowButton>
                        </div>
                        <div className="cb-spacerv-32"></div>
                    </div>
                </div>
            </div>
        )
    }

    addErrorMessage(message) {
        this.setState(
            {
                alertProps:
                {
                    message: 'Error: ' + message,
                    isDismissable: false,
                    uniqueId: 'general-alert-apnumber-error',
                    className: 'alert alert-danger alert-dismissable',
                    dismissAction: this.clearErrorMessage
                }
            }//,
            //() => { console.log('addErrorMessage state is now ', this.state) }
        )
    }

    clearErrorMessage() {
        this.setState(
            {
                alertProps: false
            }//,
            //() => { console.log('clearErrorMessage state is now ', this.state) }
        )
    }

    onAPNumberChange() {
        this.props.setResults(null)
        this.clearErrorMessage()
        let $elem = $('#plagiarismInputAPNumber')
        var apNumber = $elem.val()

        this.checkButtonAvailability()

        if (!isValidStudentId(apNumber)) {
            this.addErrorMessage('AP number/AP ID should be eight digits/letters. Please recheck your entry.')
            this.resetForm(apNumber)
            return
        }

        if (apNumber === this.state.student.apNumber) {
            return
        }

        $elem.prop('disabled', true)

        searchForStudent(apNumber)
            .then(json => {
                this.setState(
                    { student: jsonToStudent(json, apNumber) }//,
                    //() => { console.log('state is now ',this.state)}
                )
            })
            .catch((xhr) => {
                var message = (xhr.status === 404) ? 'AP Number does not exist.' : 'Error retrieving AP Number.'
                this.addErrorMessage(message)
                this.resetForm(apNumber)
            })
            .finally(() => {
                $elem.prop('disabled', false)
                this.checkButtonAvailability()
                $elem.focus()
            })
    }

    checkButtonAvailability() {
        let apNumber = $('#plagiarismInputAPNumber').val()
        let noLesson = this.state.student.selectedLesson === '';
        let badApNumber = !isValidStudentId(apNumber)
        $('#plagiarism-form button').prop('disabled', noLesson || badApNumber)
    }

    async updateSelectedValue(value, type) {
        this.props.setResults(null)
        if (type === 'course') {
            await this.setState(
                (state, props) => {
                    let course = _.find(state.student.courses, (c) => c.courseId == value)
                    let newStudent = _.extend(_.clone(state.student),
                        {
                            selectedCourse: value,
                            lessonOptions: course ? course.lessonOptions : [],
                            selectedLesson: ''
                        }
                    )
                    return { student: newStudent }
                },
                () => {
                }
            )
        } else if (type === 'lesson') {
            await this.setState((state, props) => {
                let newStudent = _.extend(_.clone(state.student),
                    {
                        selectedLesson: value
                    }
                )
                return { student: newStudent }
            })
        }

        //this.model.set(`${type}Selected`, value)
        this.checkButtonAvailability()
        this.clearErrorMessage()
    }

    getResults() {
        var getTitle = (arr, value) => {
            var selected = _.find(arr, c => c.value == value) || {}
            return selected.label
        }
        showLoadingView('region-spinner')
        let student = this.state.student
        getPlagiarizedLesson(student.apNumber, student.selectedLesson)
            .then(json => {
                let results = {
                    json: json,
                    apNumber: student.apNumber,
                    assignmentId: student.selectedLesson,
                    courseTitle: getTitle(student.courseOptions, student.selectedCourse),
                    lessonTitle: getTitle(student.lessonOptions, student.selectedLesson)
                }
                this.clearErrorMessage();
                let assignment = results.json.assignment
                let hasPlagiarism = assignment &&
                    (assignment.plagiarismReasons.length || assignment.iThenticate)
                if (hasPlagiarism) {
                    this.props.setResults(results)
                }
                else {
                    this.addErrorMessage('Plagiarism was not reported for this performance task component.')
                }
                closeLoadingView('region-spinner')
            })
            .catch((response) => {
                closeLoadingView('region-spinner')
            })
    }
}
