import { Spinner, YellowButton } from "@cb/apricot-react";
import { pathToUrl } from "../../_core/util/URL";
import { bsliGet, bsliPut } from "../../_core/util/request";
import { AlertComp } from "../../_core/views/alert.jsx";
import ShowCourseStructureComp from "./showCourseStructure.jsx";
import ShowRubricsComp from "./showRubrics.jsx";
import { ShowCourseParamsComp } from "./showCourseParams.jsx";
import { useParams } from "react-router-dom";
import { savedGetRequests } from "./admin-layout";

const EditCourseComp = () => {
    const [props, setProps] = useState(null)
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({
        assigmnent: "-1",
        rubrics: null,
        selectedparam: null,
        checked: false,
        originalparams: [],
        date: null,
        selectedModule: null,
        selectedlesson: null,
        selectedRubricScore: null,
        selectedRubric: null,
        flag: true,
        alertProps: false
    })
    const { id } = useParams()
    let title, courseYear

    const showFinalizeButton = () => {
        let show = props?.parms.courseCreationEnabled && props?.parms.model.isCourseActive;
        if (show) {
            return <YellowButton className="cb-spacerv-top-72 cb-float-right" onClick={finalizeCourse} >Finalize Course</YellowButton>
        }
    }

    const showExportCourseButton = () => {
        let show = props?.parms.courseCreationEnabled && !props?.parms.model.isCourseActive;
        if (show) {
            return <YellowButton className="cb-spacerv-top-72 cb-float-right" onClick={exportCourse} >Export Course</YellowButton>
        }
    }

    const finalizeCourse = () => {
        let url = 'admin/course-instances/' + props.parms.courseInstanceId + '/finalizeCourse'
        bsliPut(url, {}
        ).then(() => {
            props.parms.model.isCourseActive = false;
            bounceState();
        })
        .catch(() => {
            setState(state => ({
                ...state,
                alertProps: {
                  showAlert: true,
                  message: 'Error: An error occurred finalizing this course. Please contact support.'
                }
            }))
        })
    }

    const exportCourse = () => {
        let downloadUrl = 'admin/course-instances/' + props.parms.courseInstanceId + '/downloadCourse'
        let url = pathToUrl(downloadUrl)
        download(url)
    }

    const download = (url) => {
        var xhr = new XMLHttpRequest();

        xhr.open('GET', url);
        xhr.setRequestHeader('Authorization', Cookies.get('cb_login'));
        xhr.responseType = 'blob';

        xhr.onload = function () {
            var contentDisposition = xhr.getResponseHeader('Content-Disposition');
            var fileName = /filename="(.*)"/.exec(contentDisposition)[1];
            if (navigator.msSaveBlob) { // IE >= 10
                navigator.msSaveBlob(xhr.response, fileName);
            } else {
                var link = document.createElement('a');
                if ('download' in document.createElement('a'))
                    link.setAttribute('download', fileName);
                else
                    link.setAttribute('target', '_blank');
                link.setAttribute('href', URL.createObjectURL(xhr.response));
                link.dispatchEvent(new MouseEvent('click'));
            }
        }

        xhr.send();
    }

    // dummy method so force screen refresh
    const bounceState = () => {
        let flag = !state.flag
        setState(state => ({ ...state, flag }))
    }

    const getContent = async () => {
        try {
            let enabled = await savedGetRequests('/admin/course-instances/edit')
            let modules = await bsliGet('course-instances/'+ id + '/modules')
            let assignmentTypes = await savedGetRequests('/admin/assignments/types')
            let parameters = await bsliGet('course-instances/' +  id +  '/config')
            let courses = await bsliGet('admin/current-course-instances')
            let course = courses.find(c => c.id === id)
            let parms = {
                model: course,
                modules: modules,
                assignmentTypes: assignmentTypes,
                parameters: parameters,
                courseInstanceId: id,
                courseCreationEnabled: enabled.courseCreationEnabled
            }
            setProps({ parms })
        } catch (e) {
            logger.error('EditCourseComp failed with an error: ', e)
        }
    }

    useEffect(() => {
        getContent()
    }, [id])

    useEffect(() => {
        if (props) setLoading(false)
    }, [props])

    if (loading) return <Spinner />

    if (props) {
        title = props.parms.model.title
        courseYear = props.parms.model.courseYear
        return (
          <div>
              <div className="row">
                  <div className="col-xs-8">
                      <h4 className="cb-spacerv-top-72">Edit Course</h4>
                  </div>
                  <div className="col-xs-2" >
                      {showExportCourseButton()}
                      {showFinalizeButton()}
                  </div>
              </div>
              <div className="alert-wrapper" style={{marginTop: '10px'}}>
                  {state && state.alertProps && state.alertProps.showAlert &&
                    <AlertComp params={state.alertProps} />
                  }
              </div>
              <div className="row">
                  <div className="col-xs-10">
                      <h5 className="cb-spacerv-top-24 cb-spacerv-bottom-16 cb-spacerh-left-8" >{title} {courseYear}</h5>
                      <ShowCourseStructureComp parms={props.parms}/>
                      <ShowCourseParamsComp courseInstanceId={props.parms.courseInstanceId} parameters={props.parms.parameters} course={props.parms.model}/>
                      <ShowRubricsComp parms={props.parms}/>
                  </div>
              </div>
          </div>
        )
    }
}

export default EditCourseComp
