import { Tabs, Tab } from '@cb/apricot-react'
import { subject, updateSubscription } from '../../../utility/service'
import { AlertComp } from '../../../_core/views/alert.jsx'
import { getArtUtil,
         isHashChangeInProgress,
         getFormatedData,
         checkAllImages,
         getOverLimitWork,
         hasUploadedFile ,
         buildImageError} from '../entities/artUtil'
import {downloadArt, removeLock} from '../entities/artAjax'
import useImages, { isSectionArray } from '../entities/imageData'
import { initSave, saveHandler, finalSubmitHandler } from '../entities/saveUtil'
import { isNullish } from '../../../utility/util'
import { ArtButtonBar } from './buttonBar'
import { ArtCard } from './card'
import { ImageGallery } from './gallery'
import { ReorderComp } from './reorder'
import {
  getCourseName,
  getCourseInstanceByCurrentSectionId,
  getCurrentSection,
  isArt3D,
} from '../../../entities/courseinstance'
import SubmitFinalModal from '../../views/submitFinalModal'

export function ArtImagesComp({ params }) {
  const {
    isAllReady, isDifferentFromOrig, getClone,
    findSection, getDataToSave, eraseFileObjects,
    imageDocumentId, passAroundImageId,
    imageUrl, imageFilename, imageUploadError,
    sectionsHaveErrors, imageUploadId,
  } = useImages()
  // parms: type, saveUrl, finalDate, readOnly, data, autoSaveUrl, finalSubmitInfo, scoringEnabled
  let isSI = params.type === 'SI'
  let isSW = params.type === 'SW'
  let is3Dart = isArt3D()
  let uploadId = null

  let artUtil = getArtUtil()
  const [compData, setCompData] = useState(getFormatedData(params.data , isSW, is3Dart))
  const [compDataOrig, setCompDataOrig] = useState(getClone(compData))
  const [cardReorderUpdateCount, setCardReorderUpdateCount] = useState(0)
  const [canSave, setCanSave] = useState(false)
  const [canFinalSave, setCanFinalSave] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isFinalSaving, setIsFinalSaving] = useState(false)
  const [showOneCard, setShowOneCard] = useState({ bool: false, index: 0 })
  const [alertProps, setAlertProps] = useState(false)
  const [metaHasError, setMetaHasError] = useState(false)
  const [modalProps, setModalProps] = useState({ modalOpen: false })
  const [clickedSave, setClickedSave] = useState(false)
  const [hasSaveProblem, setHasSaveProblem] = useState([])
  const courseName = getCourseName()
  const isReadOnly = new Date(getCourseInstanceByCurrentSectionId().softEndDate) < new Date() && !getCurrentSection().enrollment.hasExtension
  let subscription

  useEffect(() => {
    const newData = getFormatedData(params.data, isSW, is3Dart)
    setCompData(newData)
    setCompDataOrig(getClone(newData))
  }, [params.data])

  // listeners effect, run once
  useEffect(() => {
    window.addEventListener('dragover', function(e) {
      e = e || event
      e.preventDefault()
    })

    window.addEventListener('drop', function(e) {
      e = e || event
      e.preventDefault()
    })

    // startAutoSaveFunc returns a function that stops the auto saving
    // the returned function from useEffect will execute when its time to 'clean up'
    // https://reactjs.org/docs/hooks-effect.html
    // return startAutoSave()

    subscription = subject.subscribe(message => {
      if (message.refreshMenuBar) {
        params.finalDate = moment(new Date()).format('MM/DD')
        params.readOnly = true
      }
    })
    updateSubscription('refreshMenuBar', subscription)
  }, [])

  useEffect(() => {
    initSave(setCanSave, setIsSaving, setIsFinalSaving, params.saveUrl, setModalProps, params.removeLockUrl)
    setCanSave(isDifferentFromOrig(compData, compDataOrig))
    setCanFinalSave(isAllReady(compData, isSW, is3Dart) && !hasSaveProblem.length)
    setMetaHasError(sectionsHaveErrors(compData))
    // activation code for input and textarea
    document.querySelectorAll('.cb-floating-label').forEach((elem) => {
      window.cb.apricot.CBForm.floatingLabel(elem.querySelector('input, textarea'))
    })

    // we cannot call onSave directly from the autoSave module because it will be seeing old data
    // from old replaced functions
    let subscription = subject.subscribe(message => {
      if (message.suggestAutoSave) {
        try {
          let enabled = !isHashChangeInProgress() && canSave && !isSaving
          if (enabled) {
            autoSave()
          }
        } catch (e) {
          console.error('suggestAutoSave had an error')
        }
      }
    })
  }, [compData])

  useEffect(() => {
    const imageFixed = hasSaveProblem?.every(val => {
      if (is3Dart && isSW) {
        const images = compData.find(c => c[0].meta.id === val || c[1].meta.id === val)
        const image = images.find(i => i.meta.id === val)
        return image.img.url.includes('data:image')
      } else {
        const image = compData.find(c => c.meta.id === val)
        return image.img.url.includes('data:image')
      }
    })
    if (hasSaveProblem.length && !imageFixed) {
      const workNumbers = hasSaveProblem.reduce((acc, curr, index, arr) => {
        acc = `${acc + (curr)}${arr.length - 1 === index ? '' : ','} `
        return acc
      }, '')
      setCanSave(false)
      setCanFinalSave(false)
      setAlertProps({
        showAlert: true,
        message: `Work ${workNumbers} ${hasSaveProblem.length > 1 ? 'are' : 'is'} missing required data. Please check your entr${hasSaveProblem.length > 0 ? 'ies' : 'y'} for Work ${workNumbers} and try again.`,
        uniqueId: 'save-issue-alert'
      })
    } else {
      setCanFinalSave(true)
    }
  }, [hasSaveProblem])

  const onBlur = (event) => {
    if (Array.isArray(event)) {
      let clone = artUtil.reorder(compData, event[0], event[1] - 1)
      setCardReorderUpdateCount(prev => prev + 1)
      setCompData(clone)
    } else {
      let elem = event.target
      if (!!elem.dataset && !!elem.dataset.index && !!elem.dataset.type) {
        let index = elem.dataset.index
        let type = elem.dataset.type
        let value = (type === 'height' || type === 'width' || type === 'depth') && elem.value === '.' ? '0.' : elem.value

        setCompData(data => {
          return data.map((card, i) => {
            if (parseInt(index) === i) {
              if (isSectionArray(card)) {
                return card.map(c => ({ ...c, meta: { ...c.meta, [type]: value } }))
              } else {
                return { ...card, meta: { ...card.meta, [type]: value } }
              }
            }
            return card
          })
        })
        imageUploadError({ ...compData[index], meta: { ...compData[index].meta, [type]: value } }, '')
        setAlertProps(false)
      }
    }
  }

  const onSave = (deleteSave = null, newData = null, index = null, clickedClear = false) => {
    const canDeleteSave = typeof deleteSave === 'boolean' && deleteSave === true
    let hasSaveError = null
    const overLimitItemWork = !!compData && getOverLimitWork(compData, isSW, is3Dart, params) || !!newData && getOverLimitWork(newData, isSW,is3Dart, params)
    if (overLimitItemWork) {
      setAlertProps({
        showAlert: true,
        message: `Text input for one or more fields for Work ${overLimitItemWork} has exceeded the allowed character limit. Please check your entry for Work ${overLimitItemWork} and try again.`,
        uniqueId: 'art-save-over-limit-meta',
      })
      return
    }
    setClickedSave(true)
    if (canSave || canDeleteSave) {
      return new Promise((resolve, reject) => {
        try {
          let dataToSave = deleteSave && newData ? getDataToSave(newData, compData) : getDataToSave(compData, compDataOrig)
          passAroundImageId(dataToSave, compData)
          if (dataToSave.length > 0) {
            saveHandler(dataToSave)
              .then(statuses => {
                // now erase the File objects so they don't get saved again
                let clone = clickedClear
                  ? newData ? newData : compData
                  : newData ? eraseFileObjects(newData) : eraseFileObjects(compData)
                statuses.forEach((status) => {
                  if (clone.some(c => isSectionArray(c))) {
                    const index = parseInt(status.documentIndex) - 1
                    clone[index].forEach((section, cloneIndex) => {
                      const correctSection = dataToSave.map(d => {
                        const [image1, image2] = d
                        if (image1.meta.id === section.meta.id) return image1
                        if (image2.meta.id === section.meta.id) return image2
                        return null
                      }, {})
                      if (section.hasImage && section.meta.id === correctSection[0]?.meta?.id && section.meta.id === status.documentIndex) {
                        let sectionClone = findSection(status, clone)
                        if (!sectionClone[cloneIndex].uploadId) {
                          imageUploadId(sectionClone[cloneIndex], status.uploadId, cloneIndex)
                        }
                        if (!uploadId) {
                          uploadId = sectionClone[cloneIndex].uploadId || status.uploadId
                        }
                        imageDocumentId(sectionClone[cloneIndex], status.uploadSubDocumentId, cloneIndex)
                        imageUploadError(sectionClone[cloneIndex], status.error, cloneIndex)
                        if (!isNullish(status.error)) {
                          imageUrl(sectionClone, '', cloneIndex)
                          imageFilename(sectionClone, '', cloneIndex)
                          hasSaveError = status.error
                        }
                      }
                    })
                  } else {
                    let sectionClone = findSection(status, clone)
                    if (!sectionClone.uploadId) {
                      imageUploadId(sectionClone, status.uploadId)
                    }
                    if (!uploadId) {
                      uploadId = sectionClone.uploadId || status.uploadId
                    }
                    imageDocumentId(sectionClone, status.uploadSubDocumentId)
                    imageUploadError(sectionClone, status.error)
                    if (!isNullish(status.error)) {
                      imageUrl(sectionClone, '')
                      imageFilename(sectionClone, '')
                      hasSaveError = status.error
                    }
                  }
                })
                if (deleteSave && newData) {
                  if (clone.some(c => isSectionArray(c))) {
                    const parsedIndex = parseInt(index)
                    imageDocumentId(clone[parsedIndex], '', '0')
                    imageDocumentId(clone[parsedIndex], '', '1')
                    imageUploadId(clone[parsedIndex], '', '0')
                    imageUploadId(clone[parsedIndex], '', '1')
                  } else {
                    imageDocumentId(clone[index], '')
                    imageUploadId(clone[index], '')
                  }
                }
                // Check to see if there is any errors left in the compData array
                if (clone.some(c => isSectionArray(c))) {
                  clone.forEach(c => {
                    const img1 = c[0], img2 = c[1]
                    if (img1.uploadError) c[0].uploadError = ''
                    if (img2.uploadError) c[1].uploadError = ''
                  })
                } else {
                  clone.forEach(c => {
                    if (c.uploadError) c.uploadError = ''
                  })
                }
                setCompData(clone)
                if (clickedClear) {
                  setCompDataOrig(compDataOrig => {
                    const items = compDataOrig.map((item, index) => {
                      if (index === dataToSave[0].key) {
                        return {
                          ...item,
                          file: {},
                          hasError: false,
                          hasImage: false,
                          img: {
                            fileName: '',
                            url: '',
                          },
                          meta: {
                            ...item.meta,
                            depth: '',
                            height: '',
                            ideas: '',
                            materials: '',
                            processes: '',
                            citations: '',
                            width: '',
                          },
                        }
                      }
                      return item
                    })
                    return eraseFileObjects(items)
                  })
                } else {
                  setCompDataOrig(clone)
                }
                if (hasSaveError) {
                  reject({ message: hasSaveError })
                } else {
                  removeLock(params.removeLockUrl)
                  setAlertProps({
                    showAlert: false,
                  })
                  resolve('onSave complete')
                  subject.next({
                    refreshActivityFeed: {},
                  })
                }
                params.refreshStudentLayout()
              })
              .catch(err => {
                let message = typeof err?.message === 'string' ? `Error: ${err.message}` : 'Error: This submission could not be saved. Please try again.'
                setAlertProps({
                  showAlert: true,
                  message,
                  uniqueId: 'art-save',
                })
              })
          } else {
            resolve('There were no sections that needed saving')
          }
        } catch (e) {
          console.error('onSave had error', e)
          reject('Save had an Error')
        }
      })
    } else {
      console.info('cant save or dont need to')
      return Promise.resolve()
    }
  }

  const onFinalSubmit = async () => {
    try {
      await onSave()
      setIsFinalSaving(true)
      if (!params.finalSubmitInfo.upload.id) params.finalSubmitInfo.upload.id = uploadId
      const problemImgs = await checkAllImages(compData, params)
      setHasSaveProblem(problemImgs)
      if (!problemImgs.length) {
        let clone = getClone(compData)
        params.finalSubmitInfo.artData = clone
        params.finalSubmitInfo.artType = params.type
        const props = await finalSubmitHandler(params.finalSubmitInfo)
        setIsFinalSaving(false)
        setModalProps(props)
      } else setIsFinalSaving(false)
    } catch (err) {
      setIsFinalSaving(false)
      let message = typeof err?.message === 'string' ? `${err.message}` : 'Error: This submission could not be saved.  Please try again.'
      setAlertProps({
        showAlert: true,
        message: message,
        uniqueId: 'art-finalSubmit',
      })
    }
  }

  const buttonBar = (
    <ArtButtonBar
      save={onSave}
      isSaving={isSaving}
      isFinalSaving={isFinalSaving}
      finalDate={params.finalDate}
      canSave={canSave && !metaHasError}
      canFinalSave={canFinalSave}
      finalSubmit={onFinalSubmit}
      scoringEnabled={params.scoringEnabled}
    />
  )

  // 'eventOrElem' may be an event or an elem
  // file is a File type and is only present when called via callback in loadFromDesktop
  const onImageChange = (eventOrElem, file, imageIndex, clickedClear) => {
    let elem = eventOrElem.target ? eventOrElem.target : eventOrElem
    if (elem.dataset) {
      let index = elem.dataset.index
      if (!index) {
        // eslint-disable-next-line no-undef
        elem = $(elem).parent()[0]
        index = elem.dataset.index
      }
      if (index) {
        let type = elem.dataset.type
        if (type && type === 'delete') {
          let clone = artUtil.deleteCard(compData, index)
          const hasUploadSubDocumentId = clone.some(c => isSectionArray(c))
            ? !!(clone[index][0].uploadSubDocumentId || clone[index][1].uploadSubDocumentId)
            : !!clone[index].uploadSubDocumentId
          if (hasUploadSubDocumentId) {
            onSave(true, clone, index, clickedClear)
          } else {
            setCompData(compData => {
              return compData.map((data, i) => {
                if (i === parseInt(index)) {
                  return clone[index]
                }
                return data
              })
            })
            setAlertProps(false)
          }
        } else {
          let fileName = elem.dataset.filename ? elem.dataset.filename : ''
          let url = elem.src
          let clone = getClone(compData)
          if (index.includes('.')) {
            index = parseInt(index) - 1
            index = `${index}`
          }
          // Check to see if file as been uploaded before
          if (hasUploadedFile(clone, fileName)) {
            onImageError(index, buildImageError(fileName, courseName, params.type), imageIndex)
          } else {
            artUtil.alterImage(clone, index, url, fileName, file, imageIndex)
            setCompData((compData) => {
              return compData.map((data, i) => {
                if (i === parseInt(index)) {
                  if (isSectionArray(data)) {
                    return data.map((d, ind) => ind === parseInt(imageIndex) ? clone[index][imageIndex] : d)
                  } else {
                    return clone[index]
                  }
                }
                return data
              })
            })
            setAlertProps(false)
          }
        }
      } else {
        //  console.info('element and parent missing index')
      }
    } else {
      // console.log('onImageChange elem did not have dataset')
    }
  }

  const onImageError = (index, msg, imageIndex = null) => {
    let clone = getClone(compData)
    imageUploadError(clone[index], msg, imageIndex)
    setCompData(clone)
  }

  const reorderChange = (inst) => {
    let index1 = inst.fromId - 1
    let index2 = inst.toId - 1
    let clone = artUtil.reorder(compData, index1, index2)
    setCardReorderUpdateCount(prev => prev + 1)
    setCompData(clone)
    setAlertProps(false)
  }

  const setScreenSize = (fullScreen, index) => {
    if (fullScreen) {
      setShowOneCard({ bool: true, index: index })
    } else {
      setShowOneCard({ bool: false, index: index })
    }
  }

  return (
    <div id='Art_SI_Images'>
      {buttonBar}
      <SubmitFinalModal props={modalProps} />
      <div className='alert-wrapper container-fluid'>
        {alertProps && alertProps.showAlert &&
          <AlertComp params={alertProps} />
        }
      </div>

      <Tabs>
        <Tab label='Works' panelId='tab1' selected>
          <div className='cb-margin-top-16 cb-margin-bottom-16'><p>Upload your images and enter the dimensions,
            materials, citations, {isSW ? 'processes, and ideas' : 'and processes'}. Remember to
            select <strong>Save</strong> if you make any changes.</p></div>
          <input type='file' className='browse hidden' id='fileupload' name='fileupload' />
          <form>
            <div className='display-flex flex-wrap'>
              {compData && compData.length > 0 && compData.map((section, index) => {
                  let idRoot = `${params.type}_` // SW_ or SI_
                  return (
                    <div className={showOneCard.bool ? 'col-sm-12' : 'col-xl-6 col-xs-12'} key={index}>
                      <ArtCard idRoot={idRoot} index={index} key={`ArtCard__${index}`}
                               type={params.type} readOnly={params.readOnly || isReadOnly} isSaving={isSaving}
                               showOneCard={showOneCard} setScreenSize={setScreenSize}
                               section={section} onImageChange={onImageChange} onBlur={onBlur}
                               onImageError={onImageError} setCanSave={setCanSave} isReorder={cardReorderUpdateCount}
                               clickedSave={clickedSave} setClickedSave={setClickedSave}
                      />
                    </div>
                  )
                },
              )}
            </div>
          </form>
        </Tab>

        {!params.readOnly &&
          <Tab label='Reorder' panelId='reorderTab'>
            <div className='cb-margin-top-16 cb-margin-bottom-16'><p>You can change the order of your images by dragging
              and dropping an image into the desired location. Remember to select <strong>Save</strong> if you make any
              changes.</p></div>
            <div id={params.type + '_reorder' /*_' + galleryId*/}
                 style={{ width: '100%', minHeight: '500px' }}>
              <ReorderComp key={params.type + '_reorder_key' /*_' + galleryId*/}
                           sections={compData} onChange={reorderChange}>
              </ReorderComp>
            </div>
          </Tab>
        }
        <Tab label='Gallery' panelId='tab2'>
          <div className='cb-margin-top-16 cb-margin-bottom-16'><p>This tab provides a complete view of the works you’ve
            uploaded. Select an image below to view a slideshow of your images, including the information you’ve entered
            for each image.</p></div>
          <div id='gallery' /*{'gallery' + galleryId}*/ style={{ width: '100%', minHeight: '500px' }}>
            <ImageGallery key='SI_gallery_key' /*{'SI_gallery_key' + galleryId}*/
                          galleryId={params.type + '_gallery'/* + galleryId*/}
                          sections={compData} type={params.type}
            />
          </div>
        </Tab>

      </Tabs>
      {buttonBar}
    </div>
  )
}
