
import React, { useEffect, useState } from 'react'
import { Input, Select, TextArea } from '@cb/apricot-react'
import {
    validateArtMetaKey,
    validateDimensions,
} from '../../../utility/util'
import useImages from '../entities/imageData'

// this paints the top portion of the image card
export function ArtCardBottom({ idRoot, index, type, readOnly, showOneCard, isSaving, section, onBlur, is3D, setHasError, setCanSave, clickedSave, setClickedSave }) {
    const { imageIdeas, imageMaterials, imageProcesses, imageCitations, imageHeight, imageWidth, imageId, imageDepth } = useImages()

    let id          = idRoot + index
    let height      = imageHeight(section)
    let width       = imageWidth(section)
    let depth       = imageDepth(section)
    let card        = imageId(section)
    let materials   = imageMaterials(section)
    let processes   = imageProcesses(section)
    let citations   = imageCitations(section)
    let ideas       = imageIdeas(section)
    let disabled    = !section.hasImage || showOneCard.bool || readOnly || isSaving

    if (is3D && type === 'SW') {
        height      = imageHeight(section)[0] === imageHeight(section)[1] ? imageHeight(section)[0]: ""
        width       = imageWidth(section)[0] === imageWidth(section)[1] ? imageWidth(section)[0]: ""
        depth       = imageDepth(section)[0] === imageDepth(section)[1] ? imageDepth(section)[0]: ""
        card        = index + 1
        materials   = imageMaterials(section)[0] ===  imageMaterials(section)[1]?  imageMaterials(section)[0]: ""
        processes   = imageProcesses(section)[0] ===  imageProcesses(section)[1] ? imageProcesses(section)[0] : ""
        citations   = imageCitations(section)[0] ===  imageCitations(section)[1] ? imageCitations(section)[0] : ""
        ideas       = imageIdeas(section)[0] === imageIdeas(section)[1] ? imageIdeas(section)[0]: ""
        disabled    = (!section[0].hasImage && !section[1].hasImage) || showOneCard.bool || readOnly || isSaving
    }

    const [validationError, setValidationError] = useState( {depth : false , height : false, width : false})
    const [materialCnt, setMaterialCnt] = useState(materials.length)
    const [processCnt, setProcessCnt] = useState(processes.length)
    const [citationCnt, setCitationCnt] = useState(citations.length)
    const [ideaCnt, setIdeaCnt] = useState(ideas.length)
    const [screenWidth, setScreenWidth]   = useState(window.innerWidth)
    const [screenHeight, setScreenHeight] = useState(window.innerHeight)

    useEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => window.removeEventListener('resize', updateDimensions)
    }, [])

    useEffect(() => {
        const cardImageDiv = document.getElementById(`Art_Image_${type}_${index}`)
        if (is3D && !!cardImageDiv) {
            const orderOfWorkRow = cardImageDiv.nextElementSibling.nextElementSibling
            const textAreaRow = cardImageDiv.nextElementSibling.nextElementSibling.nextElementSibling
            const workRowStyle = window.getComputedStyle(orderOfWorkRow, 'width')
            textAreaRow.style.width = workRowStyle.width
        }
    }, [screenWidth, screenHeight])

    useEffect(() => {
        if (is3D && type === 'SW')  {
           document.querySelector(`#height_${id}`).value = height
           document.querySelector(`#width_${id}`).value = width
           document.querySelector(`#depth_${id}`).value = depth
        }
    }, [section] )

    const updateDimensions = () => {
        setScreenWidth(window.innerWidth)
        setScreenHeight(window.innerHeight)
    }

    const cardValues = () => {
        let values = []
        const length = type === 'SW' ? 5 : 15
        for (let i = 1; i <= length; i ++) {
            values.push({ value: i, label: i + '' })
        }
        return values
    }

    const handleCardChange = (val) => {
        onBlur([index, val])
    }

    const ideaChange = event => {
        setIdeaCnt(event.target.value.length)
        onBlur(event)
    }

    const materialChange = (event) => {
        setMaterialCnt(event.target.value.length)
        onBlur(event)
    }

    const processChange = (event) => {
        setProcessCnt(event.target.value.length)
        onBlur(event)
    }

    const citationChange = (event) => {
        setCitationCnt(event.target.value.length)
        onBlur(event)
    }

    const hasValidationErrors = (errorVal) => {
        return errorVal.depth || errorVal.height || errorVal.width
    }

    const updateValidationError = (type, val) => {
        if (type === 'height' || type === 'width' || type === 'depth') {
            let copy = { ...validationError }
            copy[type] = val
            setHasError(hasValidationErrors(copy))
            setValidationError(copy)
        }
    }

    const onChangeDimensionValidation = (event) => {
        let elem = event.target
        if (!!elem.dataset && !!elem.dataset.index && !!elem.dataset.type) {
            let elemType = elem.dataset.type
            let value = elem.value
            setClickedSave(false)
            if (elemType === 'height' || elemType === 'width' || elemType === 'depth') {
                if (validateDimensions(value , type)) {
              //      console.log('onChange VALIDATION TRUE')
                    updateValidationError(elemType,false)

                } else {
                //    console.log('onChange VALIDATION FALSE')
                    setCanSave(false)
                    updateValidationError(elemType,true)
                    setHasError(true)
                    return
                }
            }
            if (elemType === 'index') {
                if (value < 1 || value > 15) {
                    updateValidationError({ type: 'index', msg: 'not used' })
                    return
                }
            }

            onBlur(event)
        }
    }

    const validationMessaging = (type, dimension) => {
        if (type === 'SW') {
            return `Enter Numeric ${dimension} in Inches or you have exceeded the allowed character limit`
        } else {
            return 'Dimensions must be numeric, NA or N/A (case insensitive) or you have exceeded the allowed character limit'
        }
    }

    return (
        <>
            <div className='row cb-margin-bottom-16 cb-margin-top-16'>
                <h4 className="cb-font-size-small">
                    Order of Work and Dimensions (in inches)
                </h4>
            </div>
            <div className='row align-items-center'>
                <div className={'col-md-3'}>
                    <Select label='Work *' labelNoValue='' data-index={index} data-type='index' disabled={disabled}
                        ariaLabel={`enter the order of this artwork, ${type === 'SW' ? '1-5' : '1-15'}`}
                        values={cardValues()} condensed={true} floating={false}
                        value={card}
                        onChange={handleCardChange}

                    />
                </div>
                <div className={'col-md-3'}>
                    <Input data-id={'height_' + id} label="Height *" data-index={index} data-type='height'
                        ariaLabel='enter the height of your original art work'
                        condensed={true} clearable={false} floating={false}
                        value={height} disabled={disabled}
                        maxLength='4'
                        id={`height_${id}`}
                        onChange={onChangeDimensionValidation}
                        validation={validationError?.['height'] || !validateDimensions(height , type, clickedSave) ? 'error' : ''}
                        validationMsg={validationMessaging(type, 'Height')}
                    />
                </div>
                <div className={'col-md-3'}>
                    <Input data-id={'width_' + id} label="Width *" data-index={index} data-type='width'
                        ariaLabel='enter the width of your original art work'
                        condensed={true} clearable={false} floating={false}
                        value={width} disabled={disabled}
                        maxLength='4'
                        id={`width_${id}`}
                        onChange={onChangeDimensionValidation}
                        validation={validationError?.['width'] || !validateDimensions(width , type, clickedSave) ? 'error' : ''}
                        validationMsg={validationMessaging(type, 'Width')}
                    />
                </div>
                <div className='col-md-3'>
                    <Input data-id={'depth_' + id} label={is3D ? "Depth *" : "Depth"} data-index={index} data-type='depth'
                        ariaLabel='enter the depth of your original art work'
                        condensed={true} clearable={false} floating={false}
                        value={depth} disabled={disabled}
                        maxLength='4'
                        id={`depth_${id}`}
                        onChange={onChangeDimensionValidation}
                        validation={validationError?.['depth'] || !validateDimensions(depth , type, clickedSave) ? 'error' : ''}
                        validationMsg={validationMessaging(type, 'Depth')}
                    />
                </div>
            </div>
            <div className='row'>
                {type === 'SW' && !disabled && (
                    <div className="col-md-12 cb-input">
                        <TextArea id={`${idRoot}_materials_${index}`} label={'Idea(s) *'} rows='2' disabled={disabled}
                            data-index={index} data-type='ideas' value={ideas}
                            maxLength='100' onKeyPress={validateArtMetaKey} onChange={ideaChange}
                            validation={ideaCnt > 100 ? 'error' : ''}
                            validationMsg='You have exceeded the allowed character limit'
                        />
                        <div className='count'>
                            <span>{`${ideaCnt}/100`}</span>
                        </div>
                    </div>
                )}
                {type === 'SW' && disabled && <div className="col-md-12 cb-input">
                    <label htmlFor={`${idRoot}_materials_${index}`} style={{ color: '#888888' }} >
                        Idea(s) *
                    </label>
                    <TextArea id={idRoot + 'inquiry'} rows='2' disabled={disabled}
                        value={ideas}
                        maxLength='100'
                        validation={ideaCnt > 100 ? 'error' : ''}
                        validationMsg='You have exceeded the allowed character limit'
                    />
                    <div className='count'>
                        <span>{`${ideaCnt}/100`}</span>
                    </div>
                </div>
                }
                {!disabled ?
                    <div className="col-md-12 cb-input">
                        <TextArea id={`${idRoot}_materials_${index}`} label={'Material(s) *'} rows='2' disabled={disabled}
                            data-index={index} data-type='materials' value={materials}
                            maxLength='100' onKeyPress={validateArtMetaKey} onChange={materialChange}
                            validation={materialCnt > 100 ? 'error' : ''}
                            validationMsg='You have exceeded the allowed character limit'
                        />
                        <div className='count'>
                            <span>{materialCnt + '/100'}</span>
                        </div>
                    </div>
                    : <div className="col-md-12 cb-input">
                        <label htmlFor={`${idRoot}_materials_${index}`} style={{ color: '#888888' }} >
                            Material(s) *
                        </label>
                        <TextArea id={idRoot + 'inquiry'} rows='2' disabled={disabled}
                            value={materials}
                            maxLength='100'
                            validation={materialCnt > 100 ? 'error' : ''}
                            validationMsg='You have exceeded the allowed character limit'
                        />
                        <div className='count'>
                            <span>{materialCnt + '/100'}</span>
                        </div>
                    </div>
                }
                {!disabled ?
                    <div className="col-md-12 cb-input">
                        <TextArea id={`${idRoot}_processes_${index}`} label={!disabled && 'Process(es) *'} rows='2' disabled={disabled}
                            data-index={index} data-type='processes' value={processes}
                            maxLength='100' onKeyPress={validateArtMetaKey} onChange={processChange}
                            validation={processCnt > 100 ? 'error' : ''}
                            validationMsg='You have exceeded the allowed character limit'
                        />
                        <div className='count'>
                            <span>{processCnt + '/100'}</span>
                        </div>
                    </div>
                    :
                    <div className="col-md-12 cb-input">
                        <label htmlFor={`${idRoot}_materials_${index}`} style={{ color: '#888888' }} >
                            Process(es) *
                        </label>
                        <TextArea id={`${idRoot}inquiry`} rows='2' disabled={disabled}
                            value={processes}
                            maxLength='100'
                            validation={processCnt > 100 ? 'error' : ''}
                            validationMsg='You have exceeded the allowed character limit'
                        />
                        <div className='count'>
                            <span>{processCnt + '/100'}</span>
                        </div>
                    </div>
                }
                {!disabled ?
                    <div className="col-md-12 cb-input">
                        <TextArea id={`${idRoot}_citations_${index}`} label={!disabled && 'Citation(s)'} rows='2' disabled={disabled}
                            data-index={index} data-type='citations' value={citations}
                            maxLength='100' onKeyPress={validateArtMetaKey} onChange={citationChange}
                            validation={citationCnt > 100 ? 'error' : ''}
                            validationMsg='You have exceeded the allowed character limit'
                        />
                        <div className='count'>
                            <span>{citationCnt + '/100'}</span>
                        </div>
                    </div>
                    :
                    <div className="col-md-12 cb-input">
                        <label htmlFor={`${idRoot}_materials_${index}`} style={{ color: '#888888' }} >
                            Citation(s)
                        </label>
                        <TextArea id={`${idRoot}inquiry`} rows='2' disabled={disabled}
                            value={citations}
                            maxLength='100'
                            validation={citationCnt > 100 ? 'error' : ''}
                            validationMsg='You have exceeded the allowed character limit'
                        />
                        <div className='count'>
                            <span>{citationCnt + '/100'}</span>
                        </div>
                    </div>
                }

            </div>
        </>
    )

}
