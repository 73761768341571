import React, { Fragment }                              from 'react';
import { YellowButton, RadioButton, RadioButtonGroup }  from "@cb/apricot-react";
import { bsliPut }                                      from '../../_core/util/request';
import { showLoadingView, closeLoadingView }            from '../../_core/views/loading_view'
import { cleanUpBody  }                                 from '../../_core/views/modal.jsx'
import CBModal                                          from '@cb/apricot/CBModal';

export default class AcceptanceComp extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let results = this.props.results

        const onSubmit = () => {
            this.popModal('confirmModal');
        }

        const isInputIncomplete = () => {
            return !results.appeal.studentHasAppealed || 
                results.appeal.acceptAppeal === null || 
                results.appeal.decisionRationale.length === 0
        }

        return (
            <Fragment>
                {results &&
                    <Fragment>
                        <h6 className="cb-spacerv-48" >Step 3: Enter your decision</h6>

                        <p>You must first indicate student has appealed in step one.</p>

                        {this.renderRadioButtons(results)}

                        <div id="region-spinner" className="cb-spacerv-32"></div>

                        <div className="cb-input">
                            <label htmlFor="textareaID">Rationale for Decision</label>
                            <textarea id="appealRationale" rows="5" maxLength="1200"
                                defaultValue={results.appeal.decisionRationale}
                                onBlur={(e) => { this.props.updateAppeal( {decisionRationale: e.target.value} ) }}
                                disabled={results.isAcceptancePosted || !results.appeal.studentHasAppealed}
                            >
                            </textarea>
                        </div>

                        <div className="cb-spacerv-32"></div>

                        <YellowButton 
                            onClick={(e) => onSubmit(e)} 
                            disabled={results.isAcceptancePosted || isInputIncomplete()}>
                            Submit
                        </YellowButton>
                        {this.modalConfirmMessage(results)}
                        {this.modalInfoMessage()}
                    </Fragment>
                }
            </Fragment>
        )
    }

    getMessage(acceptAppeal) {
        if (acceptAppeal) {

        } else {

        }
    }

    modalConfirmMessage(results) {
        return (
            <div className="cb-modal" id="confirmModal" aria-hidden="true" data-cb-apricot="modal">
                <div className="cb-modal-overlay" data-cb-modal-close>
                    <div className="cb-modal-container" role="dialog" aria-modal="true" aria-labelledby="modalID4Title">
                        <div className="cb-modal-header">
                            <h2 className="cb-modal-title" id="modalID4Title"> Confirm Submission   </h2>
                        </div>
                        <div className="cb-modal-content">
                            {results.appeal.acceptAppeal &&
                                <p>
                                    <strong>Accept Appeal.</strong>
                                    <br />
                                    You are about to accept the student's appeal. Please confirm that this is your intention.
                                    You will not be able to change your decision once submitted.
                                </p>
                            }
                            {!results.appeal.acceptAppeal &&
                                <p>
                                    <strong>Deny Appeal.</strong>
                                    <br />
                                    You are about to deny the student's appeal. Please confirm that this is your intention.
                                    You will not be able to change your decision once submitted.
                                </p>
                            }
                        </div>
                        <div className="cb-modal-footer">
                            <div className="cb-btn-row">
                                <button type="button" className="cb-btn cb-btn-naked cb-no-padding"
                                    data-cb-modal-close aria-label="Close this dialog window">
                                    Cancel
                                </button>
                                <button type="button" className="cb-btn cb-btn-yellow"
                                    onClick={(e) => { this.postAcceptance(results) }} >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderRadioButtons(results) {
        if (results.appeal.studentHasAppealed && !results.isAcceptancePosted) {
            return (
                <RadioButtonGroup
                    onChange={(value) => { 
                        this.props.updateAppeal( {acceptAppeal: value==='true'} ) }}
                    vertical={false}
                    name="optRadios">
                    <RadioButton label="Accept Appeal" value="true" />
                    <RadioButton label="Deny Appeal" value="false" />
                </RadioButtonGroup>
            )
        } else if (results.isAcceptancePosted) {
            if(!results.appeal.acceptAppeal) {
                return (
                    <RadioButtonGroup
                        onChange={(value) => { 
                            this.props.updateAppeal( {acceptAppeal: value==='true'} ) }}
                        vertical={false}
                        value="false"
                        name="optRadios">
                        <RadioButton label="Accept Appeal" value="true" />
                        <RadioButton label="Deny Appeal" value="false" />
                    </RadioButtonGroup>
                )
            } else {
                return (
                    <fieldset aria-labelledby="apricot_fieldset_5" role="radiogroup" aria-invalid="false" className="">
                        <div className="cb-spacerv-8"></div>
                        <div className="display-flex flex-row">
                            <div className="cb-radio  cb-disabled">
                                <label><input type="radio" name="optRadiosDisabled" checked={results.appeal.acceptAppeal} disabled />
                                    <span className="cb-span"></span>Accept Appeal</label>
                            </div>
                            <div className="cb-spacerh-16"></div>
                            <div className="cb-radio  cb-disabled">
                                <label><input type="radio" name="optRadiosDisabled" checked={!results.appeal.acceptAppeal} disabled />
                                    <span className="cb-span"></span>Deny Appeal</label>
                            </div>
                        </div>
                    </fieldset>
                )
            }
        } else {
            return (
                <fieldset aria-labelledby="apricot_fieldset_5" role="radiogroup" aria-invalid="false" className="">
                    <div className="cb-spacerv-8"></div>
                    <div className="display-flex flex-row">
                        <div className="cb-radio  cb-disabled">
                            <label><input type="radio" name="optRadiosDisabled" disabled />
                                <span className="cb-span"></span>Accept Appeal</label>
                        </div>
                        <div className="cb-spacerh-16"></div>
                        <div className="cb-radio  cb-disabled">
                            <label><input type="radio" name="optRadiosDisabled" disabled />
                                <span className="cb-span"></span>Deny Appeal</label>
                        </div>
                    </div>
                </fieldset>
            )
        }
    }

    modalInfoMessage() {
        return <div className="cb-modal" id="modalInfoMessage" aria-hidden="true"
            data-cb-apricot="modal">
            <div className="cb-modal-overlay" data-cb-modal-close>
                <div className="cb-modal-container" role="dialog" aria-modal="true" aria-labelledby="modalID4Title">
                    <div className="cb-modal-header">
                        <h2 className="cb-modal-title" id="modalID4Title">Confirm Submission</h2>
                    </div>
                    <div className="cb-modal-content">
                        <p> Your decision was submitted. </p>
                    </div>
                    <div className="cb-modal-footer">
                        <div className="cb-btn-row">
                            <button type="button" className="cb-btn cb-btn-yellow" 
                                aria-label="Close this dialog window" 
                                onClick={(e) => this.restart(e)} >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    popModal(target) {
        CBModal({
            elem: target
        }).show()
    }

    dissmissModal(target) {
        CBModal({
            elem: target
        }).close()
        cleanUpBody()
    }

    postAcceptance(results) {
        this.dissmissModal('confirmModal');
        if (results) {    
            let url = '/review/plagiarism/scorable/' + results.sisId;
            let data = {
                "studentHasAppealed":   results.appeal.studentHasAppealed,
                "decisionRationale":    results.appeal.decisionRationale,
                "acceptAppeal":         results.appeal.acceptAppeal,
                "apNumber":             results.appeal.apNumber
            };
            showLoadingView('region-spinner')
            bsliPut(url, data)
                .then(() => {
                    this.popModal('modalInfoMessage');
                })
                .finally(() => {
                    closeLoadingView('region-spinner');
                })    
        }
    }

    restart(e) {
        this.dissmissModal('modalInfoMessage');
        window.location.href = "/"
    }
}