import { getCurrentPerson } from "../entities/person"
import { Spinner } from "@cb/apricot-react"
import ReviewerComp from "./views/reviewer"
import DeniedComp from "./views/denied"

const Reviewer = () => {
  const [loading, setLoading] = useState(true)
  const [props, setProps] = useState(null)
  let reviewer = getCurrentPerson().isReviewer()

  useEffect(() => {
    setProps({
      student: {
        apNumber: null,
        courseOptions: [],
        lessonOptions: [],
        courses: [],
        selectedCourse: "",
        selectedLesson: "",
        error: null
      },
      studentDefault: _.clone({
        apNumber: null,
        courseOptions: [],
        lessonOptions: [],
        courses: [],
        selectedCourse: "",
        selectedLesson: "",
        error: null
      })
    })
    setLoading(false)
  }, [])


  if (loading) return <Spinner />

  if (props) {
    if (reviewer) return <ReviewerComp params={props} />
    return <DeniedComp />
  }

  return null
}

export default Reviewer
