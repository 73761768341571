import { Legend } from './legend'
import { StudentTable } from './studentTable'
import { TeamTable } from './teamTable'
import { AlertComp } from '../../_core/views/alert.jsx'
import { cleanDeadTooltips } from '../../utility/tooltip'

export const ProgressTeacherLayout = (props) => {
  const [state, setState] = useState({ alertProps: true })

  useEffect(() => {
    cleanDeadTooltips()
  })


  let legendProps = { isStudent: false, isWe: props.isWe }

  if (props) {
    return (
      <>
        <h3 id='title' className='h1' aria-labelledby='title alert-container region-static-content'>
          <span>Progress</span>
        </h3>
        <div id='region-modal'></div>
        <div id='alert-container'>
          <AlertComp {...state.alertProps} />
        </div>
        <div id='region-static-content' dangerouslySetInnerHTML={{ __html: props.staticText }} />
        <div id='region-legend'>
          <Legend {...legendProps} />
        </div>
        <div className='alert-wrapper add-top-margin-1x'></div>
        <div id='region-student-table' className='table-responsive'
             aria-labelledby='region-legend'>
          {props.studentProgress.students.length > 0 &&
            <StudentTable {...props} />
          }
          {props.studentProgress.students.length === 0 &&
            <p>There are currently no students enrolled in this section.</p>
          }
        </div>
      </>
    )
  }
}

export const ProgressTeacherTeamLayout = props => {
  const [state, setState] = useState({ alertProps: false })
  let cbTab = null

  useEffect(() => {
    try {
      cbTab = new window.cb.apricot.CBTab({
        elem: document.querySelector('.cb-tabs-menu'),
      })
    } catch (e) {
      console.error('Error in Team Progress Component: ', e)
    }
  }, [])

  let legendProps = { isStudent: false, isWe: props.isWe }
  let studentCls, teamCls, studentAria, teamAria
  if (props.tab === 'student') {
    studentCls = 'cb-selected'
    teamCls = ''
    studentAria = true
    teamAria = false
  } else {
    studentCls = ''
    teamCls = 'cb-selected'
    studentAria = false
    teamAria = true
  }

  return (
    <div>
      <h3 id='title' className='h1' aria-labelledby='title alert-container region-static-content'>
        <span>Progress</span>
      </h3>
      <div id='region-modal'></div>
      <div id='alert-container'><AlertComp {...state.alertProps} /></div>
      <div id='region-static-content' dangerouslySetInnerHTML={{ __html: props.staticText }} />
      <div id='region-legend'><Legend {...legendProps} /></div>
      <>
        <div className='cb-tabs-menu' data-cb-apricot='tabs-menu'>
          <ul role='tablist'>
            <li role='presentation'>
              <a href='#byStudent' className={'cb-menu-link ' + studentCls}
                 onClick={props.selectStudent} role='tab'
                 aria-controls='byStudent' aria-selected={studentAria}>
                <span>By Student</span>
              </a>
            </li>
            <li role='presentation'>
              <a href='#byTeam' className={'cb-menu-link ' + teamCls}
                 onClick={props.selectTeam} role='tab'
                 aria-controls='byTeam' aria-selected={teamAria}>
                <span>By Team</span>
              </a>
            </li>
          </ul>
        </div>
        <div className='cb-tabs-container'>
          <div className='alert-wrapper add-top-margin-1x'></div>
          <div id='byStudent' role='tabpanel' className={'cb-tabs-content ' + studentCls}>
            <div className='table-responsive' id='region-student-table'>
              {props.studentProgress.students.length > 0 &&
                <StudentTable {...props} />
              }
              {props.studentProgress.students.length === 0 &&
                <p className='add-top-margin-2x'>There are currently no students enrolled in this section.</p>
              }
            </div>
          </div>
          <div id='byTeam' role='tabpanel' className={'cb-tabs-content ' + teamCls}>
            <div className='table-responsive' id='region-team-table'>
              {props.teamProgress.teams.length > 0 &&
                <TeamTable {...props} />
              }
              {props.teamProgress.teams.length === 0 &&
                <p className='add-top-margin-2x'>Go to the “Manage Teams” page to create teams.</p>
              }
            </div>
          </div>
        </div>
      </>
    </div>
  )
}


