import React from "react";
import CourseListComp from "./courseList";
import { TeacherCourseListComp } from "./teacherCourseList";
import { getCurrentPerson } from "../../entities/person.jsx";
import { cmsGeneral } from "../../content/static_content";
import { AlertComp } from "../../_core/views/alert.jsx";
import { Link } from "react-router-dom";
import { Spinner } from "@cb/apricot-react";

const CourseContainer = ({ props }) => {
    const [componentState, setComponentState] = useState({ alertProps: false, staticHtml: '', element: null })
    const [loading, setLoading] = useState(true)
    const [helpers, setHelpers] = useState({})

    const templateHelpers = (params) => {
        let person = getCurrentPerson()
        // some persons (teacher, coordinator) have org some don't (student)
        let pageKey = 'person_' + person.id
        if (params.org) {
            pageKey = pageKey + '_org_' + params.org.orgId
        }
        return {
            isStudent: person.isStudent(),
            isTeacher: person.isTeacher(),
            isCoordinator: person.isCoordinator(),
            hasArchivedCourses: params.hasArchivedCourses,
            academicYear: params.academicYear,
            pageKey: pageKey
        }
    }

    const onRender = async (params) => {
        let role = getCurrentPerson().isStudent() ? 'student' : 'teacher'
        // cmsGeneral().then(res => (showLoadingText(_.property('dashboard_' + role)(res), compRef, staticCallback, errorCallback)))
        const res = await cmsGeneral()
        const text = res[`dashboard_${role}`]
        setComponentState(state => ({ ...state, alertProps: true, staticHtml: text }))

        let person = getCurrentPerson()
        let courses = (person.isTeacher() || person.isCoordinator()) ? params.activeCourses : params.expandedCourses
        let props = {
            'key': 'courseList_' + helpers.pageKey,
            'courses': courses,
            'helpers': helpers
        }

        if (person.isTeacher()) {
            let title = 'My ' + params.academicYear + ' Classes'
            let element = <TeacherCourseListComp courses={courses} title={title}   />
            setComponentState(state => ({ ...state, element }))
            setLoading(false)
        } else {
            let element = <CourseListComp parms={props} />
            setComponentState(state => ({ ...state, element }))
            setLoading(false)
        }
    }

    useEffect(() => {
        const compHelpers = templateHelpers(props)
        setHelpers(state => ({ ...state, ...compHelpers }))
    }, [])

    useEffect(() => {
        onRender(props)
    }, [helpers, props])

    return (
      <>
          {loading ? <Spinner /> : null}
          {componentState.element ?
            <div key={helpers.pageKey}>
              <div id='alert-container'>
                  <AlertComp params={componentState.alertProps} />
              </div>
              <div id="content-region" tabIndex='-1' className="add-top-margin-4x js-content-region"
                   dangerouslySetInnerHTML={{ __html: componentState.staticHtml }} />
              <h2 className="cb-h4  add-top-margin-4x"  style={{letterSpacing: '0'}} >My {props.academicYear} Classes</h2>
              <div className='js-courses-region'>
                  {componentState.element}
              </div>
              {helpers.hasArchivedCourses &&
                <p>View your <Link to="../archive"><strong>archived classes</strong></Link></p>
              }
            </div>
          : null}
      </>
    )
}

export default CourseContainer
