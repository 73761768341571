import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { uploadAndScorable } from '../entities/upload'
import { getCurrentPerson } from '../../entities/person.jsx'
import { bsliGet, bsliPostCSP, bsliPutCSP, fetchRequest } from '../../_core/util/request'
import { ConfirmModal } from '../../_core/views/modal.jsx'
import SubmitFinalModalComp from '../views/submitFinalModal'
import CSP_WR_3B from './CSP_WR_3B'
import CSP_WR_3C from './CSP_WR_3C'
import CSP_WR_ButtonBar from './CSP_WR_ButtonBar'
import { refreshActivityFeed, refreshMenuBarWR, refreshMenuBarWR1 } from '../../utility/service'
import { AppContext } from '../../../context.js'

const CSP_WR = (props) => {
    const history = useNavigate()
    const [data_3A, setData_3A] = useState([])
    const [data_3B, setData_3B] = useState([])
    const [data_3C, setData_3C] = useState([])
    const [data_3D, setData_3D] = useState([])
    const [dirty, setDirty] = useState(false)
    const [dirtySinceSave, setDirtySinceSave] = useState(false)

    const [wordCount, setWordCount] = useState(0)
    const [canSave, setCanSave] = useState(false)
    const [frozen, setFrozen] = useState(false)
    const [wouldExceed, setWouldExceed] = useState(false)
    const [badImage, setBadImage] = useState({ bool: false, id: '', msg: '' })
    const [wordCounts, setWordCounts] = useState([])
    const [isSaving, setIsSaving] = useState(false)
    const [isFinalSaving, setIsFinalSaving] = useState(false)
    const [isFinalSubmitComplete, setIsFinalSubmitComplete] = useState(false)
    const [finalDate, setFinalDate] = useState()
    const [finalSubmissionModal, setFinalSubmissionModal] = useState({});
    const [errorModal, setErrorModal] = useState({});
    const [hasAutoSaved, setHasAutoSaved] = useState(false)

    const context = useContext(AppContext);
    const getEmptyData = () => {
        let initialText = ''
        let initImage = null
        return {
            data_3A: [
                { text: initialText, img: initImage },
                { text: initialText, img: initImage },
                { text: initialText, img: initImage },
            ],
            data_3B: [
                { text: initialText, img: initImage },
                { text: initialText, img: initImage },
                { text: initialText, img: initImage },
                { text: initialText, img: initImage },
                { text: initialText, img: initImage },
            ],
            data_3C: [
                { text: initialText, img: initImage },
                { text: initialText, img: initImage },
                { text: initialText, img: initImage },
                { text: initialText, img: initImage }
            ],
            data_3D: [
                { text: initialText, img: initImage },
                { text: initialText, img: initImage },
                { text: initialText, img: initImage },
                { text: initialText, img: initImage },
                { text: initialText, img: initImage },
                { text: initialText, img: initImage }
            ]
        }
    }

    const jsonToState = (json) => {
        let ret = {}
        const sections = ['data_3A', 'data_3B', 'data_3C', 'data_3D']
        sections.forEach(section => {
            let altered = _.map(json[section], (elem, index) => {
                return {
                    text: elem.text,
                    delta: elem.delta,
                    images: elem.img ? elem.img : []
                }
            })
            ret[section] = altered
        })
        return ret
    }

    const refreshUploads = async () => {
        let result = await uploadAndScorable(props.parms.assignmentId, props.parms.personId)
        if (result) {
            let finalDateTemp = result[1].scorable.submittedAt ? moment(result[1].scorable.submittedAt).format('MM/DD') : ''
            setFinalDate(finalDateTemp)
        }
    }

    useEffect(() => {
        window.addEventListener("dragover", function (event) {
            event.preventDefault()
        })
        window.addEventListener("drop", function (event) {
            event.preventDefault()
        })

    }, [])

    useEffect(() => {
        setHasAutoSaved(true)
        const timer = setInterval(autoSaveCaller, 120*1000);
        return () => clearInterval(timer);
    }, [dirty, dirtySinceSave])

    useEffect(() => {
        if (props.parms.data) {
            const data = props.parms.data
            if (typeof data === "string") {
                let beginIndex = data.indexOf("{\"data_3A\"")
                let endIndex = data.lastIndexOf("\"}]}")
                let body = data.substring(beginIndex, endIndex + 4).trim()
                const studentData = JSON.parse(body)
                let ret = {}
                const sections = ['data_3A', 'data_3B', 'data_3C', 'data_3D']
                sections.forEach(section => {
                    ret[section] = _.map(studentData[section], (elem, index) => {
                        return {
                            text: elem.text,
                            delta: elem.delta,
                            images: elem.img ? elem.img : []
                        }
                    })
                })
                setData_3C([...ret.data_3C])
                setData_3B([...ret.data_3B])
            } else {
                const defaultData = jsonToState(getEmptyData())
                setData_3C([...defaultData.data_3C])
                setData_3B([...defaultData.data_3B])
            }
        } else {
            const defaultData = jsonToState(getEmptyData())
            setData_3C([...defaultData.data_3C])
            setData_3B([...defaultData.data_3B])
        }

    }, [props.parms.data])

    useEffect(() => {
        if (dirty || dirtySinceSave || hasAutoSaved) {
            context?.updateHasUnsavedWork(true);
            setCanSave(true)
        } else {
            context?.updateHasUnsavedWork(false);
            setCanSave(false)
        }
    }, [dirty, dirtySinceSave])

    const showErrorModal = (err) => {
        let parms = {
            title: 'Submission Failed',
            hideYesButton: false,
            hideNoButton: true,
            yesButtonText: 'Ok',
            onClose: () => {
                setErrorModal({});
                handleSaveError()
            },
            modalId: 'failedModal',
            message: err.message,
            isErrorMsg: true,
        }
        setErrorModal({
            display: true,
            parms: parms
        })
    }

    const savePostamble = async (reload) => {
        setIsSaving(false)
        setDirty(false)
        setDirtySinceSave(false)
        setCanSave(false)
        window.scrollTo(0, 0)
    }

    const getStateDataAsPostableJson = () => {
        let altered = {}
        altered["data_3A"] = [
            {
                "text": "",
                "delta": "",
                "img": ""
            },
            {
                "text": "",
                "delta": "",
                "img": ""
            },
            {
                "text": "",
                "delta": "",
                "img": ""
            }
        ]
        altered["data_3B"] = data_3B.map((elem, index) => {
            return {
                text: '',
                delta: '',
                img: elem.images.length > 0 ? elem.images : ''
            }
        })
        altered["data_3C"] = data_3C.map((elem, index) => {
            return {
                text: '',
                delta: '',
                img: elem.images.length > 0 ? elem.images : ''
            }
        })
        altered["data_3D"] = [
            {
                "text": "",
                "delta": "",
                "img": ""
            },
            {
                "text": "",
                "delta": "",
                "img": ""
            },
            {
                "text": "",
                "delta": "",
                "img": ""
            },
            {
                "text": "",
                "delta": "",
                "img": ""
            },
            {
                "text": "",
                "delta": "",
                "img": ""
            },
            {
                "text": "",
                "delta": "",
                "img": ""
            }
        ]

        return altered
    }

    const autoSaveCaller = async () => {
        if (dirty || dirtySinceSave) await autoSaveHandler()
    }

    const autoSaveHandler = async () => {
        setIsSaving(true)
        setCanSave(false);
        let altered = getStateDataAsPostableJson()
        let blob = new Blob([JSON.stringify(altered)])
        let formData = new FormData()
        formData.append('sections', blob)
        let person = getCurrentPerson()
        let filename = person ? `${person.firstName}_${person.lastName}_WR.pdf` : 'APUID_WR.pdf'
        let dataFilename = JSON.stringify({ filename: filename })
        const metaData = await bsliPostCSP(props.parms.autoSaveUrl, dataFilename)
        if (metaData) {
            const ajaxCallBack = () => {}
            await bsliPutCSP(metaData.signedS3Url, formData, ajaxCallBack, metaData.metadata)
            refreshActivityFeed()
            setIsSaving(false)
            setDirty(false)
            setDirtySinceSave(false)
        }
    }
    const saveHandler = async () => {
        setCanSave(false)
        setIsSaving(true)
        let altered = getStateDataAsPostableJson()
        let blob = new Blob([JSON.stringify(altered)])
        let formData = new FormData()
        formData.append('sections', blob)
        let person = getCurrentPerson()
        let filename = person ? `${person.firstName}_${person.lastName}_WR.pdf` : 'APUID_WR.pdf'
        let dataFilename = JSON.stringify({ filename: filename })
        try {
            const metaData = await bsliPostCSP(props.parms.saveUrl, dataFilename)
            if (metaData) {
                const ajaxCallBack = () => { }
                await bsliPutCSP(metaData.signedS3Url, formData, ajaxCallBack, metaData.metadata)
                let endIndex = props.parms.saveUrl.indexOf('?')
                let strippedurl = (endIndex === -1) ? props.parms.saveUrl : props.parms.saveUrl.substring(0, endIndex).trim()
                let pollUrl = strippedurl + "/" + metaData.lambdaUploadId + '/status'
                let uploadStatus
                let poll = 0
                let status
                while (poll <= 12) {
                    try {
                        uploadStatus = await fetchRequest('GET', pollUrl, { credentials: 'include' })
                    } catch (err) {
                        console.log(err)
                    }
                    if (uploadStatus.uploadStatusInfo.done) {
                        if (uploadStatus.uploadStatusInfo.description === "Complete") {
                            status = "SUCCESS"
                            break
                        } else {
                            throw new uploadStatus.uploadStatusInfo.err
                        }
                    }
                    poll++
                    if (poll === 12) {
                        throw new Error("Upload Timed out")
                    }
                    await new Promise(resolve => setTimeout(resolve, 5000))
                }
                await savePostamble(true)
                refreshActivityFeed()
                setHasAutoSaved(false)
            }
        } catch (err) {
            console.log(err)
            showErrorModal(err)
        }
    }

    const finalSubmitHandler = async () => {
        setIsFinalSubmitComplete(false)
        let autosaveAfterSave = props.parms.autosaveAfterSave
        if (dirty || dirtySinceSave || autosaveAfterSave || hasAutoSaved) {
            await saveHandler()
        }
        let finalSubmitInfo = props.parms.finalSubmitInfo
        let assignment = finalSubmitInfo.assignment
        let modalId = 'finalUploadModal'
        let upload
        if (finalSubmitInfo.upload.id === undefined) {
            let personId = getCurrentPerson().id
            const uploadArr = await uploadAndScorable(assignment.id, personId)
            upload = uploadArr.length ? uploadArr[0] : undefined
        } else {
            upload = finalSubmitInfo.upload
        }
        let parms = {
            assignment: assignment,
            lesson: finalSubmitInfo.lesson,
            upload: upload,
            scorableHolder: finalSubmitInfo.scorableHolder,
            fileReqs: {
                extensions: assignment.permittedFileExtensions,
                maxSize: assignment.permittedMaxFileSize,
                minSize: assignment.permittedMinFileSize
            },
            onClose: async () => {
                setFinalSubmissionModal({})
                refreshActivityFeed()
                refreshMenuBarWR()
                refreshMenuBarWR1()
                await refreshUploads()
            },
            modalId: modalId,
            modalOpen: true,
            finalSubmitCompleted: () => {
                setIsFinalSubmitComplete(true)
            }
        }

        setHasAutoSaved(false)
        setFinalSubmissionModal({
            display: true,
            parms: parms
        })
    }

    const downloadLatest = async () => {
        const urlPromise = await bsliGet(props.parms.finalSubmitInfo.upload.getDownloadUrl())
        const url = await urlPromise;
        window.open(url.url, '_blank')
    }


    const initializeDrag = () => { }
    const onUploadError = () => { }
    const onImageChange_3C = (file, id) => {
        if (file.source === "canvas") {
            const idAfterRemoval = id.replace("canvas_")
            const idSplit = idAfterRemoval.split("_")
            const seriesId = idSplit[1]
            const imageId = idSplit[2]
            const data3cTemp = [...data_3C]
            data3cTemp[seriesId].images[imageId] = {
                bytes: file.data,
                fileName: file.name
            }
            setData_3C([...data3cTemp])
        } else {
            const index = id.split("_")[1]
            const dataToBeUpdated = data_3C[index]
            if (dataToBeUpdated?.images?.length > -1) {
                dataToBeUpdated?.images.push({
                    bytes: file.data,
                    fileName: file.name
                })
            } else {
                dataToBeUpdated.images = [{
                    bytes: file.data,
                    fileName: file.name
                }]
            }
            const tempData = [...data_3C]
            tempData[index] = dataToBeUpdated
            setData_3C([...tempData])
        }
        setDirty(true)
        setDirtySinceSave(true)
    }
    const onImageChange_3B = (file, id) => {
        if (file.source === "canvas") {
            const idAfterRemoval = id.replace("canvas_")
            const idSplit = idAfterRemoval.split("_")
            const seriesId = idSplit[1]
            const imageId = idSplit[2]
            const data3bTemp = [...data_3B]
            data3bTemp[seriesId].images[imageId] = {
                bytes: file.data,
                fileName: file.name
            }
            setData_3B([...data3bTemp])

        } else {
            const index = id.split("_")[1]
            const dataToBeUpdated = data_3B[index]
            if (dataToBeUpdated?.images?.length > -1) {
                dataToBeUpdated?.images.push({
                    bytes: file.data,
                    fileName: file.name
                })
            } else {
                dataToBeUpdated.images = [{
                    bytes: file.data,
                    fileName: file.name
                }]
            }
            const tempData = [...data_3B]
            tempData[index] = dataToBeUpdated
            setData_3B([...tempData])
        }
        setDirty(true)
        setDirtySinceSave(true)
    }

    const clearImg = (params) => {
        if (params.id.includes("3C")) {
            const index = params.index
            const imageSeriesIndex = params.id.replace("canvas_3C_", "").split("_")[0]
            const tempData3c = [...data_3C]
            tempData3c[imageSeriesIndex].images.splice(index, 1)
            setData_3C([...tempData3c])
        } else if (params.id.includes("3B")) {
            const index = params.index
            const imageSeriesIndex = params.id.replace("canvas_3B_", "").split("_")[0]
            const tempData3b = [...data_3B]
            tempData3b[imageSeriesIndex].images.splice(index, 1)
            setData_3B([...tempData3b])
        }
        setDirty(true)
        setDirtySinceSave(true)
    }

    const offset3A = 0
    const offset3B = offset3A + data_3A.length
    const offset3C = offset3B + data_3B.length

    let anyImageSectionEmpty =
        data_3B[0]?.images?.length === 0 ||
        data_3B[1]?.images?.length === 0 ||
        data_3C[0]?.images?.length === 0 ||
        data_3C[1]?.images?.length === 0
    return (
        <div id='uploadDoc'>
            {finalSubmissionModal.display &&  <SubmitFinalModalComp props={finalSubmissionModal.parms} />}
            {errorModal.display && <ConfirmModal parms={errorModal.parms}></ConfirmModal>}

            <div id='region-modal' />
            <CSP_WR_ButtonBar
                save={saveHandler}
                isSaving={isSaving}
                isFinalSaving={isFinalSaving}
                finalDate={props.parms.finalDate || finalDate}
                anyEmpty={anyImageSectionEmpty}
                canSave={canSave}
                toolKey={'CSP_WR_1'}
                wordCount={wordCount}
                finalSubmit={finalSubmitHandler}
                downloadLatest={downloadLatest}
                scoringEnabled={props.parms.scoringEnabled}
                assignmentId={props.parms.assignmentId}
                personId={props.parms.personId}
                isPastSoftDate={props.parms.isPastSoftDate}
                hasExtension={props.parms.enrollmentHasExtension}
            />
            <span className='index cb-k-12-program'>Component C: PERSONALIZED PROJECT REFERENCE </span>
                <span className='grey'>(CREATED INDEPENDENTLY)</span>
                <div>
                    <span>
                        To assist in responding to the written response prompts on exam day, submit required portions of your code by capturing and pasting program code segments you developed during the administration of this task. Screen captures should not be blurry, and text should be at least 10-point font size. Your code segments should not include any comments. These code segments will be made available to you on exam day.
                    </span>
                </div>
            {data_3C?.length > 0 && <CSP_WR_3C
                data={data_3C}
                sectionOffset={offset3C}
                wouldExceed={wouldExceed}
                frozen={frozen}
                readOnly={props.parms.readOnly || props.parms.finalDate?.length > 0 || finalDate?.length > 0}
                badImage={badImage}
                initializeDrag={initializeDrag}
                onUploadError={onUploadError}
                wordCounts={wordCounts}
                onChange={onImageChange_3C}
                clearImg={(param) => clearImg(param)}>
            </CSP_WR_3C>}

            {data_3B?.length > 0 && <CSP_WR_3B
                data={data_3B}
                sectionOffset={offset3B}
                wouldExceed={wouldExceed}
                frozen={frozen}
                readOnly={props.parms.readOnly || props.parms.finalDate?.length > 0 || finalDate?.length > 0}
                badImage={badImage}
                onUploadError={onUploadError}
                initializeDrag={initializeDrag}
                wordCounts={wordCounts}
                onChange={onImageChange_3B}
                clearImg={(param) => clearImg(param)}>
            </CSP_WR_3B>}
            <CSP_WR_ButtonBar
                save={saveHandler}
                isSaving={isSaving}
                isFinalSaving={isFinalSaving}
                toolKey={'CSP_WR_2'}
                finalDate={props.parms.finalDate || finalDate}
                anyEmpty={anyImageSectionEmpty}
                canSave={canSave}
                wordCount={wordCount}
                finalSubmit={finalSubmitHandler}
                downloadLatest={downloadLatest}
                scoringEnabled={props.parms.scoringEnabled}
                assignmentId={props.parms.assignmentId}
                isPastSoftDate={props.parms.isPastSoftDate}
                hasExtension={props.parms.enrollmentHasExtension}
                personId={props.parms.personId} />
        </div>)
}

export default CSP_WR
