import React from 'react'
import { Checkbox } from '@cb/apricot-react'

export function SubmitFinalCheckGalleryComp({
  finalCheckParms,
  artData,
  artType,
  checkboxChange,
  openGalleryModal,
  disableCheckboxes,
}) {
  const getTaskName = (assignment) => {
    let taskName = ''
    if (assignment.isArt_SI_Images) {
      taskName = 'Sustained Investigation'
    } else if (assignment.isArt_SW_Images) {
      taskName = 'Selected Works'
    } else if (assignment.isArt_WrittenEvidence) {
      taskName = 'Sustained Investigation'
    }
    return taskName
  }

  const getAssignmentName = (assignment) => {
    let assignmentName = ''
    if (assignment.isArt_SI_Images) {
      assignmentName = 'Images'
    } else if (assignment.isArt_SW_Images) {
      assignmentName = 'Works'
    } else if (assignment.isArt_WrittenEvidence) {
      assignmentName = 'Written Evidence'
    }
    return assignmentName

  }

  let taskName = getTaskName(finalCheckParms.assignment)
  let assignmentName = getAssignmentName(finalCheckParms.assignment)

  // console.log(disableCheckboxes)


  return (
    <>
      <p style={{ marginBottom: '10px' }}>
        You are about to Submit the following as your final submission for {assignmentName} within {taskName}. You must
        click and
        view your work below to verify that it is correct.
      </p>
      <div className='row check-file'>
        <div className='col-md-3 col-sm-4'>
          <button className='cb-btn cb-btn-sm cb-btn-yellow btn-sm' style={{ float: 'right' }}
                  onClick={() => openGalleryModal(true)} data-cb-title='View File'>
            View Gallery
          </button>
        </div>
      </div>
      <fieldset className='add-top-margin-2x'>
        <legend className='sr-only'>Confirm file</legend>
        <Checkbox label='I have reviewed the gallery of images to ensure it is the correct version'
                  onChange={checkboxChange} disabled={disableCheckboxes} />
        <Checkbox
          label='I have confirmed that my submission does not contain my name or any other personally identifying information'
          onChange={checkboxChange} disabled={disableCheckboxes} />
        <Checkbox
          label='I understand that I will not be able to make any changes to my submission once I complete the final submission process'
          onChange={checkboxChange} disabled={disableCheckboxes} />
      </fieldset>
      <a id='downloadLink' className='hidden' />
    </>
  )
}
