import React from 'react'
import { BlackButton } from '@cb/apricot-react'
import { getCurrentPerson } from '../../entities/person.jsx'
import { drupalTest } from '../../content/static_content'
import { Link } from 'react-router-dom'

export default class HomeComp extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let helpers = this.templateHelpers()
    let hasDrupalTest = false

    return (
      <div>
        <h4 style={{ marginTop: '24px', marginBottom: '12px' }}>Digital Portfolio Admin Dashboard</h4>
        <p>Please select the page you would like to visit.</p>
        <ul style={{ marginTop: '12px' }}>
          <li>
            <Link to="/admin/view-reports">View Reports</Link>
          </li>
          <li>
            <Link to="/admin/teacher-scoring-provisioning">Provision Teacher Scoring</Link>
          </li>
          <li>
            <Link to="/admin/delete-score">Delete Scores</Link>
          </li>
          {helpers.systemAdmin && (
            <li>
              <Link to="/admin/manage-courses">Manage Courses</Link>
            </li>
          )}
          {helpers.anyAdmin && (
            <li>
              <Link to="/admin/manage-extensions">Manage Extensions</Link>
            </li>
          )}
          {hasDrupalTest && (
            <li>
              <BlackButton small="true" onClick={drupalTest}>
                Drupal Test
              </BlackButton>
            </li>
          )}
        </ul>
      </div>
    )
  }

  templateHelpers() {
    let person = getCurrentPerson()
    return {
      systemAdmin: person.isSystemAdmin(),
      anyAdmin: person.isAdmin() || person.isSystemAdmin(),
    }
  }
}
