import {
  getCourseParameter,
  isReadOnlyMode,
  isResearch_PREP,
  isSeminar_IRR_CP,
  isSeminar_IWA_CP
} from "../../entities/courseinstance"
import { CloseButton, Modal, NakedButton, SpacerH, YellowButton } from "@cb/apricot-react"
import { isEmpty } from "underscore"
import { useNavigate } from "react-router-dom"

const Header = (props) => {
  let lessonTitle = props.lesson.lessonTitle || props.lesson.title
  let confirmScorables = getCourseParameter("confirmScorables")
  const [openExitConfirmModal, setOpenExitConfirmModal] = useState(false)
  const [exitConfirmModalProps, setExitConfirmModalProps] = useState(null)
  const navigate = useNavigate()
  const isSemOrResSpecificAssignment = isSeminar_IWA_CP(props.lesson.assignmentId) || isSeminar_IRR_CP(props.lesson.assignmentId) || isResearch_PREP(props.lesson.assignmentId)

  const showExitConfirmModal = (e) => {
    e.stopPropagation()
    let noRubric = isEmpty(props.rubricHolder.rubrics)
    if (isReadOnlyMode() || noRubric) {
      navigate("..", { relative: "path" })
    } else {
      setExitConfirmModalProps({
        modalId: "exitConfirmModal",
        lessonTitle

      })
      setOpenExitConfirmModal(true)
    }
  }

  const ExitConfirmModal = (props) => {
    const [helpers, setHelpers] = useState({})
    const [open, setOpen] = useState(false)

    useEffect(() => {
      setHelpers({
        confirm: getCourseParameter("confirmScorables")
      })
    }, [])

    useEffect(() => {
      setOpen(props.open)
    }, [props.open])


    const Footer = (
      <>
        <NakedButton type="button" className="cb-btn-close" data-cb-modal-close>
          Cancel
        </NakedButton>
        <YellowButton type="button" className="js-confirm"
                onClick={() => goToProgress()} data-cb-modal-close>
          Proceed
        </YellowButton>
      </>
    )

    const goToProgress = () => {
      navigate("..", { relative: "path" })
    }

    return (
      <Modal
        title={`Exit ${helpers.confirm ? 'Confirmation' : isSemOrResSpecificAssignment ? 'Teacher Attestion' : 'Teacher Scoring'}`}
        open={open}
        escClose={false}
        withHeader
        withFooter
        modalId={props.modalId}
        footer={Footer}
      >
        <p>
          {helpers.confirm &&
            <span>
              You haven’t finished confirming this submission. Click Proceed to return to the Progress page without confirming.
              Click Cancel to continue confirming this submission.
            </span>
          }
          {!helpers.confirm &&
            <span>
              You haven’t finished {isSemOrResSpecificAssignment ? 'saving your changes' : 'scoring this submission'}. Click Proceed to return to the Progress page without {isSemOrResSpecificAssignment ? 'saving your changes' : 'saving the scores'}.
              Click Cancel to continue {isSemOrResSpecificAssignment ? 'submitting attestion for this submission' : 'scoring this submission'}.
            </span>
          }
        </p>
      </Modal>
    )
  }

  return (
    <>
      <div className="cb-blue1-bg">
        <div className="container-fluid">
          <div className="row cb-padding-top-16 cb-padding-bottom-16">
            <div className="col-md-10 col-sm-10 col-xs-10">
              <h5 className="display-flex cb-padding-top-4 cb-white-color">
                {!confirmScorables &&
                  <>
                    <p>
                      <strong>Teacher Scoring</strong>
                    </p>
                    <SpacerH size={"8"} />
                    <span> | </span>
                    <SpacerH size={"8"} />
                  </>
                }
                <p className="cb-no-margin"><strong>{lessonTitle}</strong></p>
              </h5>
            </div>
            <div className="col-md-2 col-sm-2 col-xs-2 display-flex flex-row-reverse">
              <CloseButton label="Close Scoring Page" onClick={showExitConfirmModal} />
            </div>
          </div>
        </div>
      </div>
      <ExitConfirmModal {...exitConfirmModalProps} open={openExitConfirmModal} />
    </>
  )
}

export default Header
