import { bsliGet } from '../_core/util/request'
import { getCurrentPerson } from './person.jsx'
import {
  amOnACourseSpecificPage,
  courseInstanceIdFromUrl,
  isDPCourse,
  lastSectionId,
  sectionIdFromUrl,
} from './course'
import { sortBy } from '../utility/util'
import { subject } from '../utility/service'

// models:
// CourseInstance, CourseInstanceCollection, CourseModel
// CourseInstanceCollection is a collection of CourseInstance
// CourseModel holds a CourseInstanceCollection with some metadata
// there is only one instance of CourseModel which holds a single instance of CourseInstanceCollection

class CourseInstance {
  constructor(course) {
    this.abbrev = course.abbrev
    this.academicYear = course.academicYear
    this.active = course.active
    this.educationPeriodId = course.educationPeriodId
    this.gracePeriod = course.gracePeriod
    this.hardEndDate = course.hardEndDate
    this.lateSubmissionDate = course.lateSubmissionDate
    this.id = course.id
    this.courseId = course.id
    this.parameters = this.initialize(course.parameters)
    this.readOnly = course.readOnly
    this.readOnlyModeMessage = course.readOnlyModeMessage
    this.sections = course.sections
    this.sectionId = course.sections[0] ? course.sections[0].id : 0
    this.softEndDate = course.softEndDate
    this.title = course.title
    this.weParameters = course.weParameters
  }

  isActive() {
    return this.active
  }

  isReadOnlyMode() {
    return this.readOnly || this.gracePeriod
  }

  isAfterHardEndDate() {
    return Date.now() >= this.hardEndDate
  }

  isAfterSoftEndDate() {
    return Date.now() >= this.softEndDate
  }

  isBetweenHardAndSoftEndDate() {
    return Date.now() >= this.softEndDate && Date.now() <= this.hardEndDate
  }

  hasLateSubmissionDate() {
    return !!this.lateSubmissionDate
  }

  isAfterLateSubmissionDate() {
    return hasLateSubmissionDate() && (Date.now() >= this.lateSubmissionDate)
  }

  isInsertOnlyMode() {
    return this.gracePeriod
  }

  abbreviation() {
    return this.abbrev
  }

  initialize(parameters) {
    let today = moment()
    let newParameters = _.clone(parameters)

    _.each(['examintentEnabled', 'apnumberEnabled', 'noreusecheckboxEnabled'], parameter => {
      //the three parameters in dateParameters will need to be set to true or false depending on
      //whether the date set has been passed or not.
      let newParm = newParameters[parameter]
      if (newParm) {
        let parameterDate = moment(newParm, 'MM/DD/YYYY')
        newParameters[parameter] = today.isAfter(parameterDate)
      }
    })
    return newParameters
  }
}

export class CourseInstanceCollection {
  constructor(data) {
    this.courses = []
    // temporarily filter out art and design course until launch
    if (data.courses) {
      this.courses = data.courses.reduce((acc, course) => {
        const { id } = course
        const isArtCourse = id.includes('32') || id.includes('33') || id.includes('34')
        return [...acc, new CourseInstance(course)]
      }, [])
    }

    this.courses = sortBy(this.courses, ['active', 'title'])
    /*_.sortBy(this.courses, course => {
      if (course.active) {
        return course.title
      } else {
        return null
      }
    })*/

    this.educationPeriodCode = data.educationPeriodCode
    this.orgInfos = data.orgInfos
  }

  getCourseById(id) {
    return _.findWhere(this.courses, { id: id })
  }

  getCourses(org, onlyActive) {
    let orgId = org ? org.orgId : null
    let person = getCurrentPerson()
    let usesOrgs = person.isTeacher() || person.isCoordinator()

    let coursesForOrg = _.filter(this.courses, course => {
      let activeContraint = onlyActive ? course.isActive() : !course.isActive()
      let orgConstraint = true
      if (usesOrgs) {
        orgConstraint = _.some(course.sections, section => (section.orgId === orgId))
      }
      return activeContraint && orgConstraint
    })

    _.each(coursesForOrg, course => {
      let sectionsOfOrg = []
      _.each(course.sections, section => {
        let orgConstraint = true
        if (usesOrgs) {
          orgConstraint = section.orgId === orgId
        }
        if (orgConstraint) {
          sectionsOfOrg.push(section)
        }
      })
      course.sectionsOfOrg = sectionsOfOrg // archived needs it here for toJSON to see it
    })
    return coursesForOrg
  }

  hasArchivedCourses() {
    return _.any(this.courses, course => !course.isActive())
  }

  getOrgs() {
    if (this.orgs) {
      return this.orgs
    }
    let person = getCurrentPerson()
    let orgs
    if (person.isTeacher()) {
      orgs = this.getTeacherOrgs()
    } else if (person.isCoordinator()) {
      orgs = this.getCoordinatorOrgs(person)
    } else {
      orgs = []
    }
    this.orgs = orgs
    return orgs
  }

  getTeacherOrgs() {
    return _.sortBy(
      _.uniq(
        _.flatten(
          _.map(this.courses, course => {
            return _.map(course.sections, section => {
              return { orgId: section.orgId, orgName: section.orgName }
            })
          }),
        ),
        false, section => section.orgId),
      'orgName')
  }

  getCoordinatorOrgs(person) {
    return _.sortBy(
      _.uniq(
        _.map(person.orgUserRoles, org => {
            return { orgId: org.orgId, orgName: org.orgName }
          },
        ),
        false, org => org.orgId),
      'orgName')
  }
}

const CourseModel = (props, data = null) => {
  const isActive = props.isActive // is the collection active
  const cachedYear = props.cachedYear || ''
  const currentOrg = props.currentOrg || undefined
  const courseInstances = props.courseInstances || new CourseInstanceCollection([])
  let weHardEndDate

  const parse = (data) => {
    courseInstances.courses.forEach(course => {
      course.sections.forEach(section => {
        let org = courseInstances.orgInfos.find(org => org.orgId === parseInt(section.orgId))
        if (org) {
          section.orgName = org.orgName
        }
        let courseType = section.courseType
        let descr = section.descr
        let title = section.title
        descr = descr ? descr.trim() : ''
        if (courseType === 2) {
          section.subTitle = descr
          section.fullTitle = descr + ': ' + title
        } else {
          section.subTitle = ''
          section.fullTitle = title
        }
      })
    })
    courseInstances.courses.forEach(course => {
      course.isDPCourse = isDPCourse(course.id)
    })
    weHardEndDate = data.weHardEndDate
    retrieveFromCache()
    return data
  }

  if (data) {
    parse(data)
  }

  return {
    isActive,
    cachedYear,
    currentOrg,
    courseInstances,
    weHardEndDate,
  }
}

export let courseModel = new CourseModel({ isActive: true, cachedYear: '', currentOrg: undefined })

export const makeSureCourseModelHasData = async () => {
  if (!courseModel.courseInstances?.length) {
    courseModel = await fetchCourseConfiguration()
  }
}

export function fetchCourseConfiguration() {
  const isReviewer = getCurrentPerson()?.isReviewer()
  const apId = sessionStorage.getItem('apId')

  return bsliGet(`courseInstances${isReviewer && apId ? '/id/' + apId : ''}`)
    .then(data => {
      courseModel.courseInstances = new CourseInstanceCollection(data)
      return CourseModel({ ...courseModel }, data)
    })
}

export function getCourseInstances() {
  return courseModel.courseInstances
}

export function getWeServiceCourseInstance() {
  let courseInstances = courseModel.courseInstances || []
  return !!courseInstances.courses && !!courseInstances.courses.length &&
    courseInstances.courses.find(course => {
      const educationPeriodCd = parseInt(course.educationPeriodId)
      const isWe = course.id === `12${educationPeriodCd}`
      return isWe && course.active
    })
}

export function getCurrentCourseInstance() {
  //app.reqres.setHandler('currentCourseInstance', function() {
  // returns a course or false, which is ok
  let courseInstances = courseModel.courseInstances || []
  return amOnACourseSpecificPage() &&
    !!courseInstances.courses && courseInstances.courses.length &&
    courseInstances.getCourseById(getCourseInstanceId())
}

// this is different from course.isActive() or course.active
// it refers to is the collection of courses active
export function isActive(active) {
  if (active !== undefined) {
    courseModel.isActive = active
  }
  return courseModel.isActive
}

export function getCachedYear(year) {
  //app.reqres.setHandler('cachedYear', year => {
  if (year) {
    courseModel.cachedYear = year
  }
  return courseModel.cachedYear
}

export function getCurrentWe(isWe) {
  //app.reqres.setHandler('currentWe', isWe => {
  if (typeof isWe === 'boolean') {
    courseModel.isWe = isWe
  }
  return courseModel.isWe
}

// retrieve the current org doing the following checks first:
// 1) if the passed in org is defined and not null, set the current org to it
// 2) initialize the currentOrg to the first one on the org list if it is not defined
// or if it does not appear in the list of orgs for the current course
export function getCurrentOrg(org) {
  if (org) {
    if (courseModel.currentOrg && org.orgId === courseModel.currentOrg.orgId) {
    } else {
      ////console.log('getCurrentOrg updating org')
      courseModel.currentOrg = org
      subject.next({
        orgChanged: {
          org: org,
        },
      })
    }
  } else {
    let orgs = courseModel.courseInstances.getOrgs()
    let currentOrg = courseModel.currentOrg

    // set the current to the default if currentOrg is not defined or is not in
    // the set of orgs of the courseInstances, which may have changed.
    if (currentOrg && _.findWhere(orgs, { orgId: currentOrg.orgId })) {
    } else {
      if (orgs && orgs.length > 0) {
        courseModel.currentOrg = orgs[0]
      }
    }
  }
  return courseModel.currentOrg
}

export function refreshCourseInstances() {
  //app.reqres.setHandler('refreshCourseInstances', function() {
  return bsliGet('courseInstances')
    .then(data => {
      courseModel.courseInstances = new CourseInstanceCollection(data)
      courseModel.parse(data)
    })
}

export function saveToCache() {
  let json = JSON.stringify(
    {
      isActive: isActive(),
      cachedYear: getCachedYear(),
      currentOrg: getCurrentOrg(),
    },
  )
  sessionStorage.setItem('orgData', json)
}

export function retrieveFromCache() {
  let cacheVal = sessionStorage.getItem('orgData')
  if (cacheVal) {
    let data = JSON.parse(cacheVal)
    isActive(data.isActive)
    getCachedYear(data.cachedYear)
    getCurrentOrg(data.currentOrg)
    logger.debug('retrieveFromCache ', data)
  } else {
    //logger.debug('retrieveFromCache had no data ', cacheVal)
  }
}

export function getWeHardEndDate() {
  //app.reqres.setHandler('weHardEndDate', function() {
  return courseModel.weHardEndDate
}

export function getWeCourseParameter(parameterName, defValue) {
  //app.reqres.setHandler('weCourse:parameter', function(parameterName, defValue) {
  let courseInstance = getCurrentCourseInstance()
  return courseInstance ? courseInstance.weParameters[parameterName] : defValue
}

export function getCourseParameter(parameterName, defValue) {
  //app.reqres.setHandler('course:parameter', function(parameterName, defValue) {
  let courseInstance = getCurrentCourseInstance()
  return courseInstance ? courseInstance.parameters[parameterName] : defValue
}

//app.reqres.setHandlers({
export function isReadOnlyMode() {
  let courseInstance = getCurrentCourseInstance()
  return courseInstance ? courseInstance.isReadOnlyMode() : false
}

export function isInsertOnlyMode() {
  //'isInsertOnlyMode': function() {
  let courseInstance = getCurrentCourseInstance()
  return courseInstance ? courseInstance.isInsertOnlyMode() : false
}

export function isAfterHardEndDate(isWe) {
  let courseInstance = isWe ? getWeServiceCourseInstance() : getCurrentCourseInstance()
  return courseInstance ? courseInstance.isAfterHardEndDate() : false
}

export function isAfterSoftEndDate() {
  let courseInstance = getCurrentCourseInstance()
  return courseInstance ? courseInstance.isAfterSoftEndDate() : false
}

export function isBetweenHardAndSoftEndDate() {
  let courseInstance = getCurrentCourseInstance()
  return courseInstance ? courseInstance.isBetweenHardAndSoftEndDate() : false
}

export function hasLateSubmissionDate() {
  let courseInstance = getCurrentCourseInstance()
  return courseInstance ? courseInstance.hasLateSubmissionDate() : false
}

export function isAfterLateSubmissionDate() {
  let courseInstance = getCurrentCourseInstance()
  return courseInstance ? courseInstance.isAfterLateSubmissionDate() : false
}

export function isWeCourse() {
  //'isWeCourse': function() {
  let courseInstance = getCurrentCourseInstance()
  return courseInstance && courseInstance.title.toLowerCase().indexOf('we service') >= 0
}

export function isWeSection() {
  //'isWeSection': function() {
  let courseInstance = getCurrentCourseInstance()
  let currentSectionId = getCurrentSectionId()
  let section = _.find(courseInstance.sections, section => {
    return section.id == currentSectionId
  })
  // courseType 1 is DP only, others are both DP and We and only We
  return section && section.courseType !== 1
}

//});
export function getCurrentSection() {
  return getSection(getCurrentSectionId())
}

var getSectionFunc = function(id) {
  if (!courseModel.courseInstances.courses.length) {
    makeSureCourseModelHasData()
  }

  for (const courseInstance of courseModel.courseInstances?.courses) {
    for (const section of courseInstance.sections) {
      if (Number(section.id) === Number(id)) return section
    }
  }
  return null
}

export function getSection(id) {
  return getSectionFunc(id)
}

export function idOfFirstSectionOfFirstCourse() {
  let courses = courseModel.courseInstances.courses
  return courses && courses[0] ? (courses[0].sections[0] ? courses[0].sections[0].id : 0) : 0
}

export function getCourseInstanceId() {
  return courseInstanceIdFromUrl() || getCurrentSection()?.courseInstanceId
}

export function isSeminar() {
  return getCourseInstanceId()?.slice(0, 2) === '40'
}

export function isAfricanAmericanStudies() {
  return getCourseInstanceId()?.slice(0, 2) === '43'
}

export function isResearch() {
  return getCourseInstanceId()?.slice(0, 2) === '41'
}

export function isCSP() {
  return getCourseInstanceId()?.slice(0, 2) === '42'
}

export function isArt() {
  const courseInstanceId = getCourseInstanceId()
  return courseInstanceId ? ['32', '33', '34'].indexOf(courseInstanceId.slice(0, 2)) > -1 : false
}

export function isArt3D() {
  return getCourseInstanceId()?.slice(0, 2) === '33'
}

export function getCourseName() {
  const courseInstanceId = getCourseInstanceId().slice(0, 2)
  let courseName = ''

  switch (courseInstanceId) {
  case '40':
    courseName = 'AP Seminar'
    break
  case '41':
    courseName = 'AP Research'
    break
  case '42':
    courseName = 'AP Computer Science Principles'
    break
  case '43':
    courseName = 'AP African American Studies'
    break
  case '32':
    courseName = 'AP 2-D Art and Design'
    break
  case '33':
    courseName = 'AP 3-D Art and Design'
    break
  case '34':
    courseName = 'AP Drawing'
    break
  default:
    break
  }

  return courseName
}

export function isArtSI(assignmentId) {
  // today the pattern for Art SI is 3xx00001 where xx=year
  // in the future I believe it will be 34xx0001
  return /3\d{2}00001/.test(assignmentId) || /3[2,3,4]\d{2}00001/.test(assignmentId)
}

export function isArtSI_Images(lessonId) {
  // today the pattern for Art SI is 3xx0101 where xx=year
  // in the future I believe it will be 34xx0101
  return ['32', '33', '34'].indexOf(lessonId.slice(0, 2)) > -1 && lessonId.includes('0101')
}

export function isArt_SW_Images(lessonId) {
  // today the pattern for Art SI is 3xx0101 where xx=year
  // in the future I believe it will be 34xx0101
  return ['32', '33', '34'].indexOf(lessonId.slice(0, 2)) > -1 && lessonId.includes('0201')
}

export function isArt_WrittenEvidence(lessonId) {
  // today the pattern for Art SI is 3xx0102 where xx=year
  // in the future I believe it will be 34xx0102
  return ['32', '33', '34'].indexOf(lessonId.slice(0, 2)) > -1 && lessonId.includes('0102')
}

export function isResearch_PREP(assignmentId) {
  return isResearch() && assignmentId?.includes('06')
}

export function isSeminar_IWA_CP(assignmentId) {
  return isSeminar() && assignmentId?.includes('10')
}

export function isSeminar_IRR_CP(assignmentId) {
  return isSeminar() && assignmentId?.includes('09')
}

export function isScorableAssignment(assignmentId, assignmentAbbreviation) {
  assignmentId = assignmentId.slice(4)
  if (assignmentAbbreviation === 'Recognition') {
    return true
  }
  return isResearch() &&
    (assignmentId?.includes('06') || assignmentId?.includes('02')) ||
    isSeminar() &&
    (assignmentId?.includes('10') || assignmentId?.includes('04') || assignmentId?.includes('05') || assignmentId?.includes('02') || assignmentId?.includes('09')) ||
    isAfricanAmericanStudies() && assignmentId?.includes('01') ||
    isWeCourse() && assignmentId?.includes('03')
}

export function canEdit(sectionId) {
  let currentSection = getSection(sectionId)
  let isPastSoftDate = currentSection.pastSoftEndDate
  let hasExtension = currentSection.hasExtension
  let afterHardEndDate = isAfterHardEndDate()
  return afterHardEndDate ? hasExtension : (!isPastSoftDate || hasExtension)
}

export function getCurrentSectionId() {
  //return currentSectionId || (currentSectionId = sectionIdFromUrl() || idOfFirstSectionOfFirstCourse());
  let sectionId = lastSectionId()
  if (sectionId) {
    return sectionId
  } else {
    sectionId = sectionIdFromUrl()
    if (sectionId) {
      return sectionId
    } else {
      return idOfFirstSectionOfFirstCourse()
    }
  }
}

export function getCourseInstanceBySectionId(sectionId) {
  let courseInstance = courseModel.courseInstances.courses.find(c => {
    if (c.sections.length && c.sections.find(s => s.id === sectionId)) return c
    else return c.sectionId === sectionId
  })
  return courseInstance
}

export function getCourseInstanceByCurrentSectionId() {
  let sectionId = getCurrentSectionId()
  let courseInstance = courseModel.courseInstances.courses.find(c => c.sectionId === sectionId)
  return courseInstance
}
