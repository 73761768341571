import { Modal, Input, Select, FileUpload, NakedButton, YellowButton } from '@cb/apricot-react'
import { refreshSupportingMaterials } from '../../utility/service'
import { getMessage } from '../../utility/util'
import { fetchRequest } from '../../_core/util/request'

const AddResourceModal = ({ open, modalId, fileReqs, handleClose, url }) => {
  const [title, setTitle] = useState('')
  const [link, setLink] = useState('')
  const [type, setType] = useState('file')
  const [isLoading, setIsLoading] = useState(false)
  const [linkError, setLinkError] = useState('')
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [file, setFile] = useState(null)
  const [fileError, setFileError] = useState('')

  useEffect(() => {
    if (type === 'link' && title && link || type === 'file' && title && file) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  }, [title, link, file, type])

  const closeModal = () => {
    handleCancel()
    handleClose()
  }

  const handleCancel = () => {
    setTitle('')
    setLink('')
    setType('file')
    setIsLoading(false)
    setFile(null)
    setFileError('')
    setLinkError('')
    setDisableSubmit(false)
  }

  const validateLink = () => {
    if (link && !link.startsWith("https://")) {
      setLinkError("Error: Link should begin with https://")
      return false
    }
    return true
  }

  const validateFile = () => {
    const fileExtension = file?.fileName.slice(file?.fileName.lastIndexOf('.') + 1);
    if (!fileReqs?.extensions.includes(fileExtension?.toLowerCase())) {
      setFileError(`Error: Only the following file types are allowed: ${fileReqs?.extensions.join(', ')}`)
      return false
    }
    if (file?.file.size < fileReqs.minSize || file?.file.size > fileReqs.maxSize) {
      setFileError(`Error: File size should between ${fileReqs?.minSize} - ${fileReqs?.maxSize}`)
      return false
    }
    return true
  }

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      const truncatedPart = str.slice(0, maxLength);
      const fileExtension = str.slice(str.lastIndexOf('.') + 1);
      return truncatedPart + '...' + fileExtension;
    } else {
      return str;
    }
  }

  const uploadLink = (title, link) => {
    setDisableSubmit(true)
    setIsLoading(true)
    const formBody = `title=${encodeURIComponent(title)}&link=${encodeURIComponent(link)}`
    fetchRequest('POST', url.replace('?', '/link?'), {
      credentials: 'include',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: formBody
    }).then(() => {
      setIsLoading(false)
      refreshSupportingMaterials()
      closeModal()
    }).catch((err) => {
      setIsLoading(false)
      setLinkError(getMessage('uploadLinkFailed'))
    })
  }

  const uploadFile = () => {
    setDisableSubmit(true)
    setIsLoading(true)
    const formData = new FormData()
    formData.set('title', title)
    formData.set('file', file?.file)
    fetchRequest('POST', url, {
      credentials: 'include',
      body: formData
    }).then(() => {
      setIsLoading(false)
      refreshSupportingMaterials()
      closeModal()
    }).catch((err) => {
      setIsLoading(false)
      setFileError(getMessage('uploadFailed'))
    })
  }

  const handleSubmit = () => {
    if (link) {
      if (!validateLink()) {
        return
      }
      uploadLink(title, link)
    } else {
      if (!validateFile()) {
        return
      }
      uploadFile()
    }
  }

  const submitCls=''
  const Footer = (
    <Fragment>
      <NakedButton type='button' className='cb-btn-close' data-cb-modal-close onClick={handleCancel}>
        Cancel
      </NakedButton>
      {!isLoading &&
        <YellowButton type='button' className='js-next' disabled={disableSubmit} onClick={handleSubmit}>
          Submit
        </YellowButton>
      }
      {isLoading &&
        <button className="cb-btn cb-btn-sm cb-btn-pulse" type="button">
          Default
            <div className="cb-btn-pulse-dots">
            <div className="cb-btn-dots"></div>
          </div>
        </button>
      }
    </Fragment>
  )

  return (
    <Modal
      title="Add Additional Materials"
      open={open}
      escClose={true}
      withHeader
      withFooter
      modalId={modalId}
      footer={Footer}
      onClose={() => {
        closeModal()
      }}
    >
      <form role="form">
        <div className="cb-input form-group">
          <label htmlFor="material-title">Title</label>
          <Input
            type="text"
            id="material-title"
            name="material-title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            maxLength="256"
          />
        </div>
        <div className="cb-select form-group">
          <label htmlFor="material-type">
            <span>Type</span>
            <span className='sr-only'>Type of file to upload, please select either File to select a file from your system, or Link to enter a URL</span>
          </label>
          <Select
              id="assignmentType"
              name="assignmentType"
              ariaLabel="Select Assignment Type"
              values={[{
                label: 'File',
                value: 'file',
              }, {
                label: 'Link',
                value: 'link',
              }]}
              onChange={(val) => {
                setType(val)
              }}
              value={type}
          />
        </div>
        {type === 'file' ? (
          <div className='cb-file-upload form-group js-file-group display-flex flex-column align-items-start' style={{ width: '500px' }} data-cb-apricot='file-upload'>
            <label htmlFor="material-file">File</label>
            <div className='display-flex'>
              <FileUpload
                name='material-file'
                onFileSelected={(e) => {
                  setFile(e.data)
                }}
                fdbk={false}
              />
              {file?.fileName ? (
                <div className="cb-margin-left-4 display-flex align-items-center">
                  {truncateString(file?.fileName, 20)}
                  <NakedButton
                    type="button"
                    className="cb-btn-close cb-margin-left-4"
                    noPadding
                    noOutline
                    onClick={() => {
                      setFileError('')
                      setFile(null)
                    }}
                   >
                    X
                  </NakedButton>
                </div>
              ) : (
                <div className="cb-margin-left-4 display-flex align-items-center">
                  No file selected
                </div>
              )}
            </div>
            <span className={fileError ? "cb-red1-color" : "sr-only"} id="fileError">
               {fileError}
             </span>
          </div>
        ) : (
          <div className="cb-input form-group js-link-group">
            <label htmlFor="material-link">Link</label>
            <Input
              type="text"
              id="material-link"
              name="material-link"
              value={link}
              onChange={(e) => {
                setLinkError('')
                setLink(e.target.value)
              }}
              maxLength="256"
            />
            <span className={linkError ? "cb-red1-color" : "sr-only"} id="linkError">
               {linkError}
             </span>
          </div>
        )}
      </form>
    </Modal>
  )
}

export default AddResourceModal
