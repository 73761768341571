import { Spinner } from '@cb/apricot-react'
import LegendComp from './legend'
import SubmissionTable from './submissionTable'
import { filterModules, getModuleHolderForReviewer } from '../../entities/modules'
import { filterStudentModules } from '../../content/ContentOverview'
import { getStudentProgress } from '../../progress/entities/progress'
import { bsliGet } from '../../_core/util/request'
import { StudentTable } from '../../progress/views/studentTable'

const SubmissionResultsComp = ({ results }) => {
  const [moduleHolder, setModuleHolder] = useState()
  const [tableProps, setTableProps] = useState()
  const [isProgressTableLoading, setIsProgressTableLoading] = useState(false)

  useEffect(() => {
    if (!results) {
      setTableProps(null)
    }
    if (results && results?.courseInstanceId) {
      getModuleHolder(results.courseInstanceId)
    }
    if (results?.json?.section?.id) {
      sessionStorage.setItem('currentSectionId', results?.json?.section?.id)
    }
  }, [results])

  useEffect(() => {
    if (results && results?.apNumber && results?.json?.section.id && moduleHolder) {
      setTablePropsData()
    }
  }, [moduleHolder])

  const setTablePropsData = async () => {
    let newModuleHolder = filterModules(moduleHolder, false)
    filterStudentModules(newModuleHolder)
    const studentProgress = await getStudentProgress(results.json.section.id, newModuleHolder, results.apNumber, true)

    setTableProps({
      moduleHolder: newModuleHolder,
      studentProgress,
      sectionId: results?.json?.section.id,
      isStudent: true,
      isWe: false,
      apNumber: results.apNumber,
      courseInstanceId: results?.courseInstanceId,
    })
    setIsProgressTableLoading(false)
  }

  const getModuleHolder = async (courseInstanceId) => {
    setIsProgressTableLoading(true)
    const modules = await bsliGet(`/course-instances/${courseInstanceId}/modules`)

    let lessons = []
    modules.map(module => {
      lessons = [...lessons, ...module.lessons]
    })

    setModuleHolder(getModuleHolderForReviewer({ modules, lessons }))
  }

    return (
        results && <>
            <SubmissionTable results={results} />
            {isProgressTableLoading && <Spinner />}
            {tableProps && (
              <>
                <LegendComp />
                <StudentTable {...tableProps} />
              </>
            )}
            <div id='region-modal'></div>
        </>
    )
}

export default SubmissionResultsComp
