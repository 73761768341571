import React from "react"
import { BlackButton } from "@cb/apricot-react"
import { PhotoGallery } from "../../../_core/gallery/photo-gallery"
import useImages from "../entities/imageData"
import { isArt3D } from "../../../entities/courseinstance"

export function ImageGallery({ galleryId, sections, type }) {
  const {
    imageHeight,
    imageWidth,
    imageId,
    imageUrl,
    imageIdeas,
    imageMaterials,
    imageProcesses,
    imageCitations,
    imageDepth,
    imageFilename
  } = useImages()

  let filtered = []
  let sectionHasSingleImg = !Array.isArray(sections[0])
  let sectionHasMultipleImg = !sectionHasSingleImg

  if (sectionHasSingleImg) {
    filtered = sections.filter(section => {
        return section.hasImage
      }
    )
  } else {
    sections.forEach(section => {
      section.forEach(subsection => {
        if (subsection.hasImage) {
          filtered.push(subsection)
        }
      })
    })
  }


  // console.log('ImageGallery #filtered=' + filtered.length)
  const downloadImage = async (event, s3url, fileName) => {
    event.preventDefault()
    event.stopPropagation()
    // Create download link element, add the flename and s3url/data:image url to link and click
    const downloadImageLink = document.createElement('a')
    downloadImageLink.href = s3url
    downloadImageLink.download = fileName
    downloadImageLink.click()
    return false
  }

  const panels = _.map(filtered, (section, index) => {
    let workId = imageId(section)
    let height = imageHeight(section)
    let width = imageWidth(section)
    let ideas = imageIdeas(section)
    let materials = imageMaterials(section)
    let processes = imageProcesses(section)
    let citations = imageCitations(section)
    let url = imageUrl(section)
    let thumbnail = imageUrl(section)
    let is3DArt = isArt3D()
    let depth = imageDepth(section)
    let fileName = imageFilename(section)
    //imageThumbnail(section)
    // data-cb-img, is used to pass main image for modal
    // data-cb-lazy, is used to pass thumbnail image
    return (
      <li className="col-xs-12 col-sm-6 col-md-4 col-lg-3" data-cb-thumb={index} key={"gallery_" + index}>
        <a href="#" aria-haspopup="dialog">
          <div className="cb-photo-thumb" data-cb-img={url} style={{ height: "250px" }}>
            {/* use src for placeholder until main image has been loaded */}
            <img
              className="cb-lazy cb-fade-in cb-active-effect"
              style={{ margin: "auto", objectFit: "scale-down", height: "100%", verticalAlign: "middle" }}
              src={thumbnail}
              data-cb-lazy={thumbnail}
              alt={"ArtWork " + index}

            />
          </div>
          {/* <!-- description for thumbnail and download image button --> */}
          <div className="cb-photo-thumb-info" style={{ textAlign: "center" }}>
            <h3>Work {workId}</h3>
          </div>
        </a>
        <div className="row">
          <div className="col-md-12" style={{ textAlign: "center" }}>
            <BlackButton
              type="button"
              className="cb-btn"
              small={true}
              onClick={e => downloadImage(e, url, fileName)}
            >
              <span
                className={"cb-glyph cb-batch-download cb-glyph-blue3"}
                id={"btn" + index}
              >
                {" "}
                Download
              </span>
              <span className="sr-only">Click to Download Image</span>
            </BlackButton>
          </div>
        </div>
        {/* MetaData description for modal */}
        <div className="cb-photo-details-info">
          {type === "SW" &&
            <h5>Selected Works</h5>
          }
          {type === "SI" &&
            <h5>Sustained Investigation</h5>
          }
          <div id="workHeight">
            <h5>Height: </h5>
            {height}
          </div>
          <div id="workWidth">
            <h5>Width: </h5>
            {width}
          </div>
          {depth &&
            <div id="workDepth">
              <h5>Depth:</h5>  {depth}
            </div>
          }
          {type === "SW" &&
            <div id="workMaterial">
              <h5>Idea(s): </h5>{ideas}
            </div>
          }
          <div id="workMaterial">
            <h5>Material(s): </h5>{materials}
          </div>
          <div id="workProcess">
            <h5>Process(es): </h5>{processes}
          </div>
          {citations && <div id="workCitation">
            <h5>Citation(s): </h5>{citations}
          </div>}
        </div>
      </li>
    )
  })

  return (
    <PhotoGallery photoGalleryId={galleryId}>
      <div id="photoGallery"
           className="cb-photo-gallery cb-margin-left-48 cb-margin-top-48 cb-margin-bottom-48">
        <ul>{panels}</ul>
      </div>
    </PhotoGallery>
  )
}
