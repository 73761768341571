import { bsliGet, fetchRequest } from '../_core/util/request'
import { removeCurrentPerson, setCurrentPerson } from '../entities/person.jsx'
import { request } from '../config/config'
import { fetchCourseConfiguration } from '../entities/courseinstance'

// TODO: Figure out if this is necessary anymore
export const initializeAuth = () => {
  let timeoutError = () => {}

  // eslint-disable-next-line no-undef
  $.ajaxSetup({
    statusCode: {
      401 (data) {
        logger.debug('401', data)
        if (!!data && !!data.responseJSON) {
          logger.debug('object type', typeof data)
          let jsonData
          if ((typeof data) === 'string')
            jsonData = JSON.parse(data)
          else
            jsonData = data.responseJSON

          redirect(jsonData)
        }
      },
      504: timeoutError,
      408: timeoutError
    }
  })
}

let cachedData

export const useAuth = () => {
  const iam = window.cb.core.iam
  const token = Cookies.get('cb_login')
  const hasAWSCreds = Boolean(iam.getTempAWSCredsSubject())
  const [auth, setAuth] = useState({
    loading: token && !hasAWSCreds,
    loggedIn: hasAWSCreds,
    loggedOut: !token && !hasAWSCreds,
    error: false
  })

  const login = async () => {
    try {
      // reload is 0, 1 or undefined
      const authParam = auth.loaded ? '1' : '0'
      const iamAuthUrl = request('config:value', 'wsBaseUrl') + '/iam/auth/' + authParam
      const options = {
        type: 'GET',
        contentType: 'application/json',
        xhrFields: {
          withCredentials: true
        }
      }
      const data = await fetchRequest('GET', iamAuthUrl, options)
      const { hasProfile } = data.person
      cachedData = data
      const shouldRedirect = redirect(data)
      if (!hasProfile && !shouldRedirect) {
        setCurrentPerson(data.person)
        setAuth({ ...auth, loggedOut: false, loading: false, hasProfile })
      } else if (shouldRedirect) {
        setCurrentPerson(data.person)
        setAuth({ ...auth, loading: false, loggedIn: true, loggedOut: false, hasProfile })
      } else {
        await fetchCourseConfiguration()
        setCurrentPerson(data.person)
        setAuth({ ...auth, loading: false, loggedIn: true, loggedOut: false, hasProfile: true })
      }
    } catch (err) {
      console.error('error in useAuth: ', err)
      if (cachedData?.responseJSON) redirect(cachedData.responseJSON)
      else redirect({ status: err.status, code: 11 })
      setAuth({ ...auth, loading: false, loggedOut: true })
    }
  }

  useEffect(() => {
    if (token) login()
  }, [])

  useEffect(() => {
    const logoutApp = async () => await logout()
    if (auth.loggedOut) {
      sessionStorage.setItem('orgData', '')
      logoutApp()
    }
  }, [auth.loggedOut])

  return auth
}

export async function logout() {
  await bsliGet('/iam/logout')
  removeCurrentPerson()
  let url = request('config:redirect', 1)
  window.location.replace(url)
}

// TODO: Figure out if this is necessary anymore
function redirect(jsonData) {
  let redirectUrl = getRedirectUrl(jsonData)
  if (redirectUrl) {
    // window.location.replace(redirectUrl)
    return redirectUrl
  } else {
    let renewUrl = sessionStorage.getItem('renewUrl')
    if (renewUrl) {
      //console.log('renewUrl ' + renewUrl)
      sessionStorage.setItem('renewUrl', '')
      window.location.replace(renewUrl)
      //navigate(renewUrl)
    }
    return false
  }
}

// TODO: Figure out if this is necessary anymore
function getRedirectUrl(jsonData) {
  if (jsonData && jsonData.status && jsonData.code !== 0) {
    let redirectConfig = request('config:redirect', jsonData.code) || request('config:redirect', 1)
    let userType
    if (jsonData.person && jsonData.person.orgUserRoles)
      userType = _.findWhere(jsonData.person.orgUserRoles, { orgRole: 'student' }) ? 'st' : 'pf'
    let url = redirectConfig[userType] || redirectConfig
    if (redirectConditions(url, jsonData.code, userType)) return url
  }
}

// TODO: Figure out if this is necessary anymore
function redirectConditions(url, code, usertype) {
  let urlHash = '/' + window.location.hash
  if (usertype === 'st' && code === 5 && urlHash.indexOf('enrollment') !== -1) return false
  if (urlHash.indexOf(url) === -1 && code !== 0 && code !== 13 && !/help/.test(location.hash)) return true
  return false
}
