import React from 'react'
import { Spinner, YellowButton } from '@cb/apricot-react'
import { getExpandedStudentData, getStatusFields } from './student_view'
import { StudentStatusCellComp } from './studentStatusCell'
import DPToolTip from '../../utility/tooltip'
import { getCurrentPerson } from '../../entities/person.jsx'
import { getCurrentCourseInstance, isArt } from '../../entities/courseinstance'
import { getAllPortfolioSubmissions, PortfolioPDF } from '../../utility/portfoliodownload'
import { getModuleHolder } from '../../entities/modules'
import { getStudentProgress } from '../entities/progress'
import { updateDate } from '../../assignment/art/entities/artAjax'
import moment from 'moment-timezone'

const StudentRow = (props) => {
  let toolTip
  const [toolKey, setToolKey] = useState(props.toolKey)

  const statusCells = () => {
    return props.lessons?.map((lesson, index) => {
      let newToolkey = props.toolKey + '_lesson_' + lesson.id
      let cellId = 'studentTab_' + studentData.dpPersonId + '_lesson_' + index

      let newProps = {
        studentData: studentData,
        scorableHolder: props.studentModel.scorables[index],
        team: props.studentModel.team,
        isWe: props.isWe,
        sectionId: props.sectionId,
        lesson,
        toolKey: toolKey,
        cellId,
        apNumber: props?.apNumber,
      }
      return <StudentStatusCellComp {...newProps} key={newToolkey} />
    })
  }

  const retrieveStatusFields = () => {
    let hasTeams = props.hasTeamLessons && !props.isWe
    return {
      ...getStatusFields(props.studentModel.jsonStudent, props.isWe),
      hasTeams,
    }
  }

  // Used for Art Download Portfolio function to get current course name based on lessonId
  const getCurrentCourseName = lessonId => {
    let courseName = '', courseInstanceFromLesson = lessonId.substr(0, 2)
    switch (courseInstanceFromLesson) {
    case '32':
      courseName = 'AP 2-D Art & Design'
      break
    case '33':
      courseName = 'AP 3-D Art & Design'
      break
    case '34':
      courseName = 'AP Drawing'
      break
    }
    return courseName
  }

  const rowIsArt = () => {
    let { courseInstanceId } = props
    return ['32', '33', '34'].indexOf(courseInstanceId.slice(0, 2)) > -1
  }

  let studentModel = props.studentModel
  let statusFields = retrieveStatusFields()
  let studentData = getExpandedStudentData(studentModel)
  let isStudent = getCurrentPerson().isStudent()
  let isTeacher = getCurrentPerson().isTeacher()
  let isCoordinator = getCurrentPerson().isCoordinator()
  let isReviewer = getCurrentPerson().isReviewer()
  let fullName = !isStudent ? `${studentData.lastName}, ${studentData.firstName}` : `${studentData.lastName}, ${studentData.prefFirstName || studentData.firstName}`
  let rowCls = statusFields.statusClass ? statusFields.statusClass : 'has-ap-number'
  let teamTitle
  let isAllSubmitted = studentModel.scorables.filter(score => {
    const otherAssignmentId = String(score.scorable.assignmentId).substring(8)
    if (otherAssignmentId === '1' || otherAssignmentId === '2' || otherAssignmentId === '3') return score.scorable
  }).every(item => item.scorable.status === 4)
  let art = isArt() || (props.courseInstanceId && rowIsArt())
  if (statusFields.hasTeams) {
    teamTitle = studentModel.team ? studentModel.team.title : ''
  }
  let personId = isStudent ? getCurrentPerson().id : studentData.dpPersonId, lessonIds = props.lessons.map(l => l.id),
    currentSectionId = props.sectionId
  const [loading, setLoading] = useState(false)
  const [isPrintedDate, setIsPrintedDate] = useState(false)
  const [downloadDate, setDownloadDate] = useState(studentData.downloadDate || '')
  const courseName = art && isReviewer ? getCurrentCourseName(props.lessons[0]?.id) : ''

  useEffect(() => {
    toolTip = new DPToolTip(toolKey)
    toolTip.createTooltips()
  }, [])

  useEffect(() => {
    // Loading state needs to be set to false after retrieving downloadDate so that component can re-render
    const refreshStudentData = async () => {
      let courseInstanceId = getCurrentCourseInstance()?.courseId
      const moduleHolder = await getModuleHolder(courseInstanceId)
      const studentProgress = await getStudentProgress(props.sectionId, moduleHolder)
      let studentModel = studentProgress.students[0]
      let studentData = getExpandedStudentData(studentModel)
      setDownloadDate(studentData.downloadDate)
      setLoading(false)
    }
    if (isPrintedDate) refreshStudentData()
  }, [isPrintedDate])

  const usePreferredName = studentData.prefFirstName && studentData.prefFirstName !== 'null'

  return (
    <tr role='row' id={'studentTab_' + studentData.dpPersonId}>
      <th scope='row' className={rowCls}>
        {fullName}
        {usePreferredName && !isStudent ?
          <span style={{ fontWeight: 400 }}> (Preferred Name: {studentData.prefFirstName})</span> : null}
        {statusFields.showAPNumber &&
          <div className='no-bold no-break-word-md' style={{ marginTop: '2px' }}>
            {statusFields.aproStudentId &&
              <span>{'#' + statusFields.aproStudentId}</span>
            }
          </div>
        }
        {statusFields.intentNo &&
          <div className='no-bold no-break-word-md' style={{ marginTop: '2px' }}>
            Not Taking Exam
          </div>
        }
        {statusFields.notRegistered &&
          <div className='no-bold no-break-word-md' style={{ marginTop: '2px' }}>
                            <span data-cb-apricot='tooltip' id={toolTip?.addToolTip()}
                                  className='progress_icon' data-cb-title='The student is not registered for the exam.'
                                  aria-describedby={'desc-' + statusFields.aproStudentId}>
                                <span className='cb-icon-icn_deadline cb-glyph cb-glyph-circular text-danger'
                                      style={{ color: 'white', float: 'left', marginTop: '4px' }}>
                                </span>
                                <span id={'desc-' + statusFields.aproStudentId} className='sr-only'>
                                    {studentData.lastName}, {studentData.firstName} is not registered for the exam.
                                </span>
                            </span>
            <span style={{
              verticalAlign: 'top',
              fontSize: '12px',
              fontWeight: 400,
              position: 'relative',
              top: '4px',
              whiteSpace: 'nowrap',
            }}>
                                No Selection
                            </span>
          </div>
        }
      </th>
      {statusFields.hasTeams &&
        <td className='cb-black1-color cb-white-bg'>{teamTitle}</td>
      }
      {statusCells()}
      {art && (isStudent || isTeacher || isCoordinator || isReviewer) && !props.isWe && 
        <td className='cb-white-bg'>
          <div className='cb-align-center'>
            {loading ? <Spinner size='small' className='cb-margin-right-4' /> :
              <YellowButton
                disabled={!isAllSubmitted}
                small='true'
                onClick={async () => {
                  setLoading(true)
                  const submissions = await getAllPortfolioSubmissions(lessonIds, currentSectionId, personId, studentData.enrollmentId)
                  if (submissions) {
                    await PortfolioPDF(submissions, {
                      firstName: studentData.firstName,
                      lastName: studentData.lastName,
                      apUid: statusFields.aproStudentId,
                      courseName,
                    })
                    if (isStudent && submissions.neededImagesIds && submissions.neededImagesIds[0]) {
                      await updateDate(submissions.neededImagesIds[0].assignmentId, submissions.neededImagesIds[0].uploadId)
                    }
                  }
                  setLoading(false)
                  setIsPrintedDate(true)
                }}>
                Download
              </YellowButton>
            }
          </div>
          {art && (isStudent && (downloadDate || isPrintedDate)) &&
            <div className='cb-black1-color'>
              <span style={{ fontSize: '9px' }}
                    className='cb-font-weight-black position-relative position-top align-self-center cb-padding-top-8'>Last printed on {moment(new Date(downloadDate)).tz(moment.tz.guess()).format('MM/DD/yyyy')}</span>
            </div>
          }
        </td>
      }
    </tr>
  )
}

export default StudentRow
