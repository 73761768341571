import { ConfirmModal } from '../../_core/views/modal'
import DPToolTip from '../../utility/tooltip'
import { ScorableHolder, scorableStatus } from '../../assignment/entities/scorable'
import { getNoResponseText, serializeData } from '../entities/statusCellUtil'
import { outdatedDataRefreshMessage } from '../progress_module'
import NoResponseModal from './noResponseModal'
import SubmissionModal from '../modal/submissionModal'
import { Enrollment } from '../../enrollment/entities/enrollment'
import { entitiesUpload } from '../../assignment/entities/upload'
import { getRubricHolder } from '../../entities/rubric'
import { entitiesScore } from '../../entities/score'
import { subtask } from '../../content/static_content'
import { getCurrentSectionId } from '../../entities/courseinstance'
import { Spinner } from '@cb/apricot-react'
import { getPlagiarizedLesson } from '../../reviewer/entities/plagiarism'
import { checkScoringAccess } from '../entities/scoring-util'
import { useNavigate } from 'react-router-dom'
import { ProgressContext } from '../../../context'

export function StudentStatusCellComp(props) {
  const [isSaving, setIsSaving] = useState(false)
  const [scorableHolder, setScorableHolder] = useState(props.scorableHolder)
  const [submissionModalProps, setSubmissionModalProps] = useState(null)
  const [confirmModalProps, setConfirmModalProps] = useState(null)
  const [noResponseModalProps, setNoResponseModalProps] = useState(null)
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showNoResponseModal, setShowNoResponseModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  let toolTip = new DPToolTip(props.toolKey)

  useEffect(() => {
    // creating the tooltips only once rather than removing them and recreating them on every
    // refresh seems appropriate. And it got rid of the double tooltip error
    let cellId = props.cellId
    document.querySelectorAll('#' + cellId + ' [data-cb-apricot="tooltip"]')
      .forEach(elem => {
        toolTip.addToolTip({ elem: elem, trigger: 'focus' })
      })
    toolTip.createTooltips()
    // setScorableHolder(new ScorableHolder(props.scorable, props.scorableHolder.assignmentId, false))
  }, [])

  let helpers = serializeData(props, scorableHolder)
  if (!helpers || !Object.entries(helpers).length) {
    return (
      <td className='cb-white-bg' />
    )
  }
  let cellId = props.cellId
  let ariaId = `${cellId}_aria`
  let showNoResponseButtonCls = helpers.showNoResponseButton && (helpers.scoreButtonText || helpers.showDraftInScoring) ? 'half-size' : ''


  const focusHere = (event) => {
    event.target.focus()
  }

  const onFocus = (e) => {
    e.preventDefault()
    e.stopPropagation()
    toggle(true)
  }

  const onMouseEnter = (event) => {
    toggle(true)
  }

  const onMouseLeave = (event) => {
    toggle(false)
  }

  const onBlur = (event) => {
    // target is button we are leaving, related target is the one we are entering
    let targetIsButton = !!event.target && !!event.target.dataset && !!event.target.dataset.isButton
    let relatedTargetIsButton = !!event.relatedTarget && !!event.relatedTarget.dataset && !!event.relatedTarget.dataset.isButton
    let targetIsHidden = event.target.classList.contains('hidden')

    if (targetIsHidden || (relatedTargetIsButton && targetIsButton)) {
    } else {
      // this is the case where we are leaving the action buttons
      toggle(false)
    }
  }

  const toggle = show => setShowActionBtns(show)

  const setShowActionBtns = (show) => {
    let cell = document.getElementById(props.cellId)
    let statusContainer = cell.getElementsByClassName('js-status')[0]
    let actionContainer = cell.getElementsByClassName('js-action')[0]
    let actionBtn = actionContainer.getElementsByTagName('button')[0]

    if (show && actionBtn) {
      statusContainer.classList.add('hidden')
      actionContainer.classList.remove('hidden')
      actionBtn.focus()
    } else {
      statusContainer.classList.remove('hidden')
      actionContainer.classList.add('hidden')
    }
  }

  const openScoringExperience = async () => {
    const resp = await checkScoringAccess(props)
    if (resp.showConfirmModal) {
      setConfirmModalProps({
        title: resp.confirmModalTitle,
        message: resp.message,
        modalId: `${props.cellId}-confirmScoringModal`,
        accessDenied: resp.accessDenied,
        onConfirmYes: (accessDenied) => {
          if (accessDenied) setShowConfirmModal(false)
          else navigate('./teacher-scoring', {
            state: {
              scorableHolder,
              lesson: props.lesson,
              isWe: props.isWe,
              team: props.team,
              studentData: props.studentData,
            },
          })
        },
        onConfirmNo: () => setShowConfirmModal(false),
        ...resp.confirmModalButtons,
      })
      setShowConfirmModal(true)
    } else navigate('./teacher-scoring', {
      state: {
        scorableHolder,
        lesson: props.lesson,
        isWe: props.isWe,
        team: props.team,
        studentData: props.studentData,
      },
    })
  }

  const openNoResponseModal = () => {
    setNoResponseModalProps({
      noResponseText: getNoResponseText(props),
      lesson: props.lesson,
      studentData: props.studentData,
      team: props.team,
      scorableHolder: scorableHolder,
      sectionId: props.sectionId,
      isWe: props.isWe,
      modalId: `${props.cellId}-NoResponseModal`,
      selectTeam: props.selectTeam,
      ...props
    })
    setShowNoResponseModal(true)
  }

  const undoNoResponse = (contextValue) => {
    let { studentData, lesson, sectionId } = props
    setIsSaving(true)
    let previousStatus = getPrevState(lesson, scorableHolder)
    let isNonParticipant = lesson.isTeamLesson && !!studentData
    let promise = isNonParticipant ?
      scorableHolder.removeNonParticipant(studentData.dpPersonId, sectionId) :
      scorableHolder.modifyScorableStatus(previousStatus, null, sectionId)
    promise
      .then(response => {
        undoNoResponseSuccessFunction(response, contextValue)
      })
      .catch(openUndoNoResponseModal)
      .finally(() => {
        setIsSaving(false)
        if (!isNonParticipant) {
          reloadTeams()
        }
      })
  }

  const reloadTeams = () => {
    if (props.selectTeam) {
      props.selectTeam(true)
    }
  }

  const getPrevState = (lesson, scorableHolder) => {
    let status = scorableStatus()
    if (lesson.isDocument) {
      if (scorableHolder.scorable && scorableHolder.scorable.uploadId) {
        if (scorableHolder.scorable.attestants.length) {
          return status.AttestationIncomplete
        } else {
          return status.DraftIn
        }
      } else {
        return status.NoDraft
      }
    } else {
      return status.FinalSubmitted
    }
  }

  const undoNoResponseSuccessFunction = (response, contextValue) => {
    replaceHolder(response)
    contextValue.refreshContent(true)
  }

  const replaceHolder = (response) => {
    let scorableHolder = new ScorableHolder(response, response.assignmentId)
    setScorableHolder(scorableHolder)
  }

  const openUndoNoResponseModal = (xhr) => {
    let name = props.studentData ? props.studentData.fullName : props.team.title
    let GenericUndoErrorMessage = 'Error: An error occurred undoing "No Response" for ' + name + '.'
    let isRefreshStatus = xhr.status === 422
    let formattedErrorMessage = (isRefreshStatus ? outdatedDataRefreshMessage :
      (xhr.responseText || GenericUndoErrorMessage))

    setConfirmModalProps({
      title: 'Undo No Response',
      hideNoButton: true,
      yesButtonText: 'Ok',
      message: formattedErrorMessage,
      reloadOnConfirm: false,
      modalId: `${props.cellId}-undoModal`,
    })
    setShowConfirmModal(true)
  }

  const handleViewSubmission = async () => {
    if (!submissionModalProps) {
      setLoading(true)
      let { scorableHolder, lesson, isWe, apNumber } = props
      const json = await getPlagiarizedLesson(apNumber, lesson.assignmentId, true)
      let scoree = lesson.isTeamLesson ? new Team(props.team) : new Enrollment(props.studentData)
      let weIndicator = isWe ? '1' : '0'
      let url = 'scoring/sections/' + (scorableHolder.sectionId || getCurrentSectionId()) + '/scorable/' + scorableHolder.scorable.id + '/' + weIndicator
      let scorable = scorableHolder.scorable
      let upload = scorable.uploadId ? await entitiesUpload(scorable.uploadId, scorable.assignmentId) : null
      let cmsLesson = await subtask(lesson.id || lesson.lessonId)
      let rubricHolder = await getRubricHolder(scorable.assignmentId)
      let scoreHolder = await entitiesScore(scorable.id)

      if (_.isEmpty(scoreHolder.scores)) {
        scoreHolder.scores = rubricHolder?.rubrics?.map(rubric => ({ rubricDimensionId: rubric.id }))
      }

      setSubmissionModalProps({
        url,
        lesson,
        cmsLesson,
        upload,
        rubricHolder,
        scoree,
        scorable,
        scorableHolder,
        scoreHolder,
        isWe,
        assignment: json.assignment,
      })
      setLoading(false)
    }
  }

  const handleClick = async () => {
    if (helpers.showDraftInScoring) {
      const response = await checkScoringAccess(props)
      if (response.showScoringPage) navigate('./teacher-scoring', {
        state: {
          scorableHolder,
          lesson: props.lesson,
          isWe: props.isWe,
          team: props.team,
          studentData: props.studentData,
        },
      })
    }
  }

  const Icon = ({ display, isTeacher, lessonTitle, name }) => (
    <div tabIndex={isTeacher ? '0' : null} className='js-status' aria-labelledby={ariaId}
         role={isTeacher ? 'link' : null} onFocus={isTeacher ? onFocus : null}>
      <span className={display.icon} style={{ fontSize: '24px' }}
            data-cb-title={!isTeacher ? `${name} has Status: ${display.text}  for ${lessonTitle}` : null}
            data-cb-apricot={!isTeacher ? 'tooltip' : null} />
      <span id={ariaId} className='sr-only'>
        {`${name} has Status: ${display.text} for ${lessonTitle}`}
      </span>
    </div>
  )

  const Scoring = ({
    lessonTitle,
    noResponseText,
    scoreButtonText,
    scoringEnabled,
    showDraftInScoring,
    showNoResponseButton,
    showUndoNoResponseButton,
    name,
    isReviewer,
    disableScoring,
    contextValue
  }) => (
    <div className='js-action hidden'>
      {(showDraftInScoring || (helpers.isReviewer && helpers.display.text !== 'No Draft')) &&
        <button type='button' data-is-button='true' onClick={isReviewer ? async () => {
          await handleViewSubmission()
          setShowSubmissionModal(true)
        } : handleClick} onMouseEnter={focusHere} className={`js-score ${showNoResponseButtonCls}`}
                data-cb-apricot='tooltip'
                data-cb-title={scoreButtonText ? scoreButtonText : `View ${isReviewer ? 'Submission' : 'Draft'} for ${lessonTitle} for ${name}`}>
          View {isReviewer ? 'Submission' : 'Draft'}
        </button>
      }
      {scoreButtonText && (scoreButtonText === 'View Submission' || !disableScoring) &&
        <button type='button' data-is-button='true' onClick={openScoringExperience} onMouseEnter={focusHere}
                className={`js-score ${showNoResponseButtonCls}`} data-cb-apricot='tooltip'
                data-cb-title={`${scoreButtonText} for ${lessonTitle} for ${name}`}>
          {scoreButtonText}
        </button>
      }
      {scoringEnabled && showNoResponseButton && !disableScoring &&
        <button type='button' data-is-button='true' onClick={openNoResponseModal} onMouseEnter={focusHere}
                className={`js-score ${showNoResponseButtonCls}`} data-cb-apricot='tooltip'
                data-cb-title={`Enter No Response for ${lessonTitle} for ${name}`}>
          {noResponseText}
        </button>
      }
      {scoringEnabled && showUndoNoResponseButton && !disableScoring &&
        <>
          <>
            {isSaving &&
              <button className='cb-btn-pulse' type='button' data-is-button='true'>
                Default
                <div className='cb-btn-pulse-dots'>
                  <div className='cb-btn-dots'></div>
                </div>
              </button>
            }
            {!isSaving &&
              <button type='button' data-is-button='true' className='js-undo-no-response' onClick={() => undoNoResponse(contextValue)}
                      data-cb-apricot='tooltip' data-cb-title={`Undo No Response for ${name}`}>
                Undo
              </button>
            }
          </>
        </>
      }
    </div>
  )

  return (
    <ProgressContext.Consumer>
      {value => (
        <>
          <td className='hover-table-cell cb-white-bg' id={cellId} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
              onBlur={onBlur}>
            {loading && <Spinner />}
            {!loading && (
              <>
                <Icon {...helpers} />
                <Scoring {...helpers} contextValue={value} />
                <SubmissionModal parms={submissionModalProps} open={showSubmissionModal}
                                 onClose={() => setShowSubmissionModal(false)} />
                <ConfirmModal {...confirmModalProps} open={showConfirmModal} onClose={() => setShowConfirmModal(false)} />
                <NoResponseModal {...noResponseModalProps} open={showNoResponseModal} onClose={response => {
                  if (response) {
                    replaceHolder(response)
                    value.refreshContent(true)
                  }
                  setShowNoResponseModal(false)
                }} />
              </>
            )}
          </td>
        </>
      )}
    </ProgressContext.Consumer>
  )
}
