import { bsliGet }                      from '../_core/util/request'
import { getCurrentSectionId }          from '../entities/courseinstance'
import { Assignment }                   from './assignment'
import { getCurrentPerson }             from './person.jsx'

export function getAssignmentTeamStatus(sectionId, assignmentId) {
    return bsliGet('sections/' + sectionId + '/assignments/' + assignmentId + '/team')
        .then(json => {
            return new AssignmentStatus(json)
        })
}

// called by teacherBrowser and studentBrowser
export function getAssignmentHolder(lessonId, sectionId) {
    let assignmentHolder = new AssignmentHolder()

    return new Promise((resolve,reject) => {
        assignmentHolder.init(lessonId, sectionId)
            .then(() => {
                resolve(assignmentHolder)
            })
            .catch(() => {
                reject('assignmentHolder did not load')
            })
        })
}

class AssignmentHolder {
    constructor() {
        this.assignments = []
    }

    init(lessonId, sectionId) {
        return bsliGet('sections/' + (sectionId || getCurrentSectionId()) + '/lessons/' + lessonId + '/assignments')
            .then(json => {
                //console.log('assignment json', json)
                _.each(json, assignment => {
                    this.assignments.push(new Assignment(assignment))
                }
                )
                //TODO need to sort json, we do?
                // json is an array of module info including their lessons
                //this.parse(json)
            })
    }
}

export class AssignmentStatus {
    constructor(jsonStatus) {
        this.id = jsonStatus.id
        this.firstName = jsonStatus.firstName
        this.prefFirstName = jsonStatus.prefFirstName
        this.lastName = jsonStatus.lastName
        this.submissionStatus = jsonStatus.submissionStatus
        this.submittedAt = jsonStatus.submittedAt
        this.title = jsonStatus.title
        this.enrollment = jsonStatus.enrollment // actually an array
    }

    isTeam() { return !!this.title }

    titleName() {
      const isTeacher = getCurrentPerson().isTeacher()
      const fullName = isTeacher
        ? (
          this.prefFirstName ? `${this.lastName} ${this.firstName} (Preferred Name: ${this.prefFirstName})` : `${this.lastName} ${this.firstName}`
        )
        : `${this.lastName} ${this.prefFirstName || this.firstName}`
        return this.isTeam() ? this.title.trim() : fullName
    }

    teamNames() {
        var enrollments = this.enrollment
        if (enrollments) {
            return _.map(enrollments, (enrollment) => {
                return enrollment.prefFirstName ? `${enrollment.firstName} ${enrollment.lastName} (Preferred Name: ${enrollment.prefFirstName})` : `${enrollment.firstName} ${enrollment.lastName}`
            })
                .sort().join(', ')
        } else {
            return false
        }
    }

    getPersonId() {
        var enrollment = this.enrollment
        return this.isTeam() ? (enrollment.length && enrollment[0].personId) : this.id;
    }
}
