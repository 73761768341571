import { getCourseInstanceId, getCurrentCourseInstance } from '../entities/courseinstance'
import {bsliGet} from '../_core/util/request'

class APNumber {
  constructor(educationPeriodId) {
    this.educationPeriodId = educationPeriodId
    this.apNumber = null
  }

  url() {
    var courseInstanceId = getCourseInstanceId()
    return 'education-periods/' + this.educationPeriodId + '/apnumber?courseInstanceId=' + courseInstanceId
    //return addBaseUrl(path)
  }

  // returns a promise that resolves to this instance
  fetch() {
    return bsliGet(this.url())
    .then(json => {
      this.apNumber = json.apNumber
      return this
    })
  }

  entered() { return this.apNumber }
  notEntered() { return !this.apNumber }
}

// function that returns an instance of APNumber class
const getApNumber = _.memoize(educationPeriodId => {
  return new APNumber(educationPeriodId)
})

// on the first call this returns a promise that resolves to an instance APNumber which has fetched its apNumber
// on subsequent calls, the 'memorized' version of
export async function profileApnumber() {
  let currentCourseInstance = getCurrentCourseInstance()
  let apNumber = getApNumber(currentCourseInstance.educationPeriodId)

  if (!apNumber.apNumber) {
    try {
      await apNumber.fetch()
    } catch(e) {
      console.error('profileApnumber could not get apNumber')
    }
  }
  return apNumber
}
