import React from 'react';
import { NakedButton }  from '@cb/apricot-react'
import { subject, updateSubscription } from '../../utility/service'

export default class TimeoutComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
        _.bindAll(this, 'dismiss', 'onShow')
        this.showing = false
    }

    componentDidMount() {
        let subscription = subject.subscribe(message => {
            // this message is sent by session_monitor
            if (message.session) {
                // session has properties: sessMon, beforeWarningMin, show
                // see session_monitor.js
                //console.log('MONITOR got message session=', message.session)
                this.setState(message.session, () => {
                    //console.log('MONITOR after setState state=', this.state)
                    if (this.state.show && !this.showing) {
                        this.onShow()
                    }
                })
            }
        })
        updateSubscription('session',subscription)
    }

    componentWillUnmount() {
        updateSubscription('session',null)
        //console.log('MONITOR timeout componentWillUnmount')
        window.clearTimeout(this.updateId);
    }

    render() {
        return (
            <div id='timeout' className='col-xs-12 padding-0'>
                {this.state.show &&
                    <div style={{ textAlign: 'center' }}>
                        <span id='jqsm-label'>
                            Your credentials expire in <span id='jqsm-time'>{this.state.beforeWarningMin}</span> minutes.
                        </span>
                        <NakedButton aria-describedby='jqsm-label' onClick={this.dismiss}>
                            <span>Renew</span>
                        </NakedButton>
                    </div>
                }
            </div>
        )
    }

    // sets up the 30 second updates
    onShow() {
        this.showing = true
        let minToMs = 60 * 1000
        this.time = this.state.beforeWarningMin * minToMs

        let excess = this.state.beforeWarningMin * 2 - Math.floor(this.state.beforeWarningMin * 2)
        if (excess < .2) {
            excess = excess + 1
        }
        this.delay = excess * 30000

        let update = () => {
            this.time = this.time - this.delay
            if (this.time > 0) {
                let str = (this.time / minToMs).toFixed(1)
                $('#jqsm-time').text(str)
                $('#jqsm-stay-logged-in').focus() // so screen reader will read
                this.delay = 30000
                another()
            } else {
                let str = (this.time / minToMs).toFixed(1)
                $('#jqsm-label').text('session has expired')
            }
        }
        let another = () => {
            //console.log('MONITOR setTimeout ' + this.delay)
            this.updateId = window.setTimeout(update, this.delay)
        }
        another()
    }

    dismiss() {
        this.state.sessMon.renew()
    }
}
