import React                                  from 'react';
import { getModuleHolder, filterModules }     from '../entities/modules'
import { ManageTeamsLayoutComp }              from './views/manageTeamsLayout'
import { bsliGet }                            from '../_core/util/request';
import { showCourseContent }                  from '../main/instruction_controller'
import { showLoadingView }                    from '../_core/views/loading_view'
import { useParams } from "react-router-dom";
import { Spinner } from "@cb/apricot-react";
import TeacherLayoutComp from "../artifactbrowser/views/teacherLayout";
import CourseLayout from "../main/views/courseLayout";

const Teams = () => {
  const [loading, setLoading] = useState(true)
  const [props, setProps] = useState(null)
  const { sectionId, moduleId } = useParams()

  const getContent = async () => {
    let moduleHolder = await getModuleHolder();
    let selectedModuleId = moduleId || moduleHolder.modules[0].id;
    let teamUrl = '/sections/' + sectionId + '/modules/' + selectedModuleId + '/teams';
    let teams = await bsliGet(teamUrl)
    let enrollmentsUrl = 'sections/' + sectionId + '/enrollments?enrolledStudentsOnly=true'
    let enrollments = await bsliGet(enrollmentsUrl)
    let modules = moduleHolder.getTeamModules()

    setProps({
      moduleHolder,
      'teams': teams,
      'enrollments': enrollments,
      'modules': modules,
      'selectedModuleId': selectedModuleId,
      'sectionId': sectionId
    })
  }

  useEffect(() => {
    getContent()
    document.dispatchEvent(new CustomEvent('pagevisit', {detail: 'manage-teams'}))
  }, [])

  useEffect(() => {
    if (props) setLoading(false)
  }, [props])

  if (loading) return <Spinner />

  if (props.moduleHolder) return (
    <>
      <CourseLayout props={{ moduleHolder: props.moduleHolder }}>
        <ManageTeamsLayoutComp parms={props} />
      </CourseLayout>
    </>
  )

  return null
}

export default Teams
