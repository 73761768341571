import { bsliGet } from '../_core/util/request'

// export default function(Entities,app) {

//   var RubricDimension = app.Entities.Model.extend({
//     descriptionFor: function(score) {
//       return _.findWhere(this.get('descriptions'), {score: parseInt(score)}).description
//     }
//   })

//   var Rubric = app.Entities.Collection.extend({
//     model: RubricDimension,

//     urlPath: function() {
//       return 'assignments/'+this.options.assignmentId+'/rubric'
//     }
//   })

//   app.reqres.setHandler('entities:rubric', function(assignmentId) {
//     return new Rubric({}, {assignmentId: assignmentId}).fetch()
//   })
// }

export class Rubric {
  constructor(rubric) {
    this.id                   = rubric.id
    this.assignmentId         = rubric.assignmentId
    this.title                = rubric.title
    this.defaultDescription   = rubric.defaultDescription
    this.descriptions         = rubric.descriptions
    // descriptions is an array of score, description, abbrev
  }
  
  descriptionFor(score) {
    return _.findWhere(this.descriptions, { score: parseInt(score) }).description
  }
}

export class RubricHolder {
  constructor(assignmentId) {
    this.assignmentId = assignmentId
    this.rubrics = []
    this.url = 'assignments/' + assignmentId + '/rubric'
  }

  init() {
    return bsliGet(this.url)
      .then(rubrics => {
        //console.log('rubrics', rubrics)
        _.each(rubrics, rubric => this.rubrics.push(new Rubric(rubric)))
        return this
      })
  }
}

export function getRubricHolder(assignmentId) {
  let rh = new RubricHolder(assignmentId)
  return rh.init()
}
