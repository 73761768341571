import "regenerator-runtime/runtime"
import { bsliGet, bsliPost, bsliPut } from "../_core/util/request"
import { cmsTerms } from "../content/static_content"

let url = '/policies/termsandconditions'
let termsVersion = { appId: 295, version: 1 }
let policyData
let cmsVersion

let result
export async function confirmTermsAndConditions() {
  try {
    const urlAdj = url + '?' + $.param(termsVersion)
    const terms = await cmsTerms()
    cmsVersion = terms.version
    const final = await bsliGet(urlAdj)
    if (final) {
      policyData = final ? { ...final } : null
    }
  } catch (err) {
    policyData = null
    console.error(err)
  } finally {
    result = (!policyData || policyData.cmsVersion !== cmsVersion)
  }

  return result
}

export async function saveTermsAndConditions() {
  if (policyData) {
    if (policyData.termsAndConditionsHash) {
      let { termsAndConditionsHash, ...remaining } = policyData
      policyData = { ...remaining }
    }
    const newPolicyData = { ...policyData, appId: termsVersion.appId.toString(), policyVersion: termsVersion.version, cmsVersion }
    await bsliPut(url, newPolicyData)
  } else {
    const newPolicyData = { appId: termsVersion.appId.toString(), policyVersion: termsVersion.version, cmsVersion }
    await  bsliPost(url, newPolicyData)
  }
  window.location.replace('/')
}
