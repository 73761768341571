import {
  getCourseParameter,
  isAfricanAmericanStudies,
  isArt,
  isCSP,
  isResearch,
  isSeminar,
} from '../../entities/courseinstance'

export function getExpandedStudentData(studentModel) {
  return {
     fullName:               studentModel.fullName,
     dpPersonId:             studentModel.jsonStudent.dpPersonId,
     firstName:              studentModel.jsonStudent.firstName,
     prefFirstName:              studentModel.jsonStudent.prefFirstName,
     lastName:               studentModel.jsonStudent.lastName,
     hasExtension:           studentModel.jsonStudent.hasExtension,
     scoreFromSameSection:   studentModel.jsonStudent.scoreFromSameSection,
     enrollmentId:           studentModel.jsonStudent.enrollmentId,
      downloadDate:           studentModel.jsonStudent.downloadDate

  }
}

// export function getStudentStatusCellOptions(studentModel, index) {
//    return {
//     student: {
//       fullName:               studentModel.fullName,
//       dpPersonId:             studentModel.jsonStudent.dpPersonId,
//       firstName:              studentModel.jsonStudent.firstName,
//       lastName:               studentModel.jsonStudent.lastName,
//       hasExtension:           studentModel.jsonStudent.hasExtension,
//       scoreFromSameSection:   studentModel.jsonStudent.scoreFromSameSection},
//     scorableHolder: studentModel.scorables[index],
//     team: studentModel.team,
//     index: index
//   }
// }

// student is of type jsonStudent
// only uses aproStudentId and examIntent from student
// we could remove sortStatus as it is computed separately when needed
export function getStatusFields(student,isWe) {
    let aproStudentId = student.aproStudentId
    if (isWe) {
      return {
        intentNo: false,
        statusClass: null,
        sortStatus: 1,
        notRegistered: false,
        showAPNumber: false,
        aproStudentId: aproStudentId
      }
    }
    var intentEnabled = getCourseParameter('examintentEnabled')
    var apnumberEnabled = getCourseParameter('apnumberEnabled')

    let examIntent = student.examIntent
    //let apnumber = student.apNumber
    var statusClass = null
    let csp = isCSP()
		let research = isResearch()
    let seminar = isSeminar()
    let art = isArt()
    let africanAmericanStudies = isAfricanAmericanStudies()
    // These status numbers are used only for sorting by status in the table
    let sortStatus = 1 // Default status, has exam intent and has provided AP number
    if(csp || art) {
			if (intentEnabled && examIntent === false) {
				statusClass = 'no-intent' // not taking exam grey
				sortStatus = 3
			}
			else if (intentEnabled && examIntent === null) {
				statusClass = 'missing-intent' //red
				sortStatus = 4
			}
		}
		else if (research || seminar || africanAmericanStudies) {
			if (!intentEnabled && apnumberEnabled && examIntent === null && aproStudentId){
        statusClass = 'missing-intent' //red
        sortStatus = 4
      }
      else if (!intentEnabled && apnumberEnabled && examIntent === false && aproStudentId){
        statusClass = 'no-intent' //grey
        sortStatus = 3
      }
    }

    let intentNo = (csp || art) ? (examIntent === false && intentEnabled) : (examIntent === false && apnumberEnabled && !intentEnabled)
    let notRegistered = (examIntent === null && aproStudentId && apnumberEnabled && (seminar || research || africanAmericanStudies))

    return {
      intentNo: intentNo,
      statusClass: statusClass,
      sortStatus: sortStatus,
      notRegistered: notRegistered || statusClass === 'missing-intent',
      showAPNumber: !(statusClass === 'no-intent' || statusClass === 'missing-intent') && apnumberEnabled,
      aproStudentId: aproStudentId
    }
  }

// student is of type jsonStudent
export function getSortStatus(student, isWe) {
  if (isWe) {
    return 1
  }
  var intentEnabled = getCourseParameter('examintentEnabled')
  var apnumberEnabled = getCourseParameter('apnumberEnabled')
  let aproStudentId = student.aproStudentId
  let examIntent = student.examIntent

  let csp = isCSP()
  let research = isResearch()
  let seminar = isSeminar()
  let art = isArt()
  let africanAmericanStudies = isAfricanAmericanStudies()

  // These status numbers are used only for sorting by status in the teacher student table
  let sortStatus = 1 // Default status, has exam intent and has provided AP number

  if (csp || art) {
    if (intentEnabled && examIntent === false) {
      sortStatus = 3
    }
    else if (intentEnabled && examIntent === null) {
      sortStatus = 4
    }
  }
  else if (research || seminar || africanAmericanStudies) {
    if (!intentEnabled && apnumberEnabled && examIntent === null && aproStudentId) {
      sortStatus = 4
    }
    else if (!intentEnabled && apnumberEnabled && examIntent === false && aproStudentId) {
      sortStatus = 3
    }
  }

  return sortStatus
}

export function getDownloadDt(student , assignmentId) {
  let status = student.statuses.find(s => assignmentId === s.assignmentId);
  return status?.downloadedAt ?  moment(status?.downloadedAt).format('MM/DD/yyyy'): '';

}

export function setPPRDownloadDt( student, assignmentId) {
  let status = student.statuses.find (s => assignmentId === s.assignmentId);
  status.downloadedAt = new Date();
}
