import { getStatusFields } from './student_view'
import { StudentStatusCellComp } from './studentStatusCell'
import DPToolTip, { cleanDeadTooltips } from '../../utility/tooltip'

export const TeamSubRow = props => {
  const [toolKey] = useState(props.toolKey)
  let toolTip = new DPToolTip(toolKey)

  useEffect(() => {
    toolTip.createTooltips()
    return () => cleanDeadTooltips()
  })

  let studentData = props.jsonStudent
  let statusFields = getStatusFields(studentData, props.isWe)
  let teamModel = props.teamModel
  let rowCls = statusFields.statusClass ? statusFields.statusClass : 'has-ap-number'
  let completionText = props.extraColumnData
  toolTip.removeTooltips()

  let statusCells = () => {
    return _.map(props.lessons, (lesson, index) => {
      let newToolKey = toolKey + '_lesson_' + lesson.abbreviation
      let cellId = 'teamTab_' + studentData.dpPersonId + '_lesson_' + index
      let newProps = {
        studentData: studentData,
        scorableHolder: teamModel.scorables[index],
        team: teamModel.jsonTeam,
        isWe: props.isWe,
        sectionId: props.sectionId,
        lesson: lesson,
        toolKey: newToolKey,
        cellId: cellId,
        selectTeam: props.selectTeam,
        ...props
      }
      return <StudentStatusCellComp {...newProps} key={newToolKey} />
    })
  }

  return ( // same tmpl as studentRow
    <tr className='cb-gray9-bg sub-row' role='row' id={'teamTab_' + studentData.dpPersonId}>
      <th scope='row' className={rowCls}>
        {studentData.lastName}, {studentData.firstName}
        {statusFields.showAPNumber &&
          <div className='no-bold no-break-word-md' style={{ marginTop: '2px' }}>
            {statusFields.aproStudentId &&
              <span>{'#' + statusFields.aproStudentId}</span>
            }
          </div>
        }
        {statusFields.intentNo &&
          <div className='no-bold no-break-word-md' style={{ marginTop: '2px' }}>
            Not Taking Exam
          </div>
        }
        {statusFields.notRegistered &&
          <div className='no-bold no-break-word-md' style={{ marginTop: '2px' }}>
            <span data-cb-apricot='tooltip' id={toolTip.addToolTip()}
              className='progress_icon' data-cb-title='The student is not registered for the exam.'
              aria-describedby={'desc-' + statusFields.aproStudentId}>
            <span className='cb-icon-icn_deadline cb-glyph cb-glyph-circular text-danger'
              style={{ color: 'white', float: 'left', marginTop: '4px' }} aria-hidden='true'>
            </span>
              <span id={'desc-' + statusFields.aproStudentId} className='sr-only'>
                {studentData.lastName}, {studentData.firstName} is not registered for the exam.
              </span>
            </span>
            <span style={{ verticalAlign: 'top', fontSize: '12px', fontWeight: 400, position: 'relative', top: '4px' }}>
              No Selection
            </span>
          </div>
        }
      </th>
      {statusCells()}
      <td className='text-center'>{completionText}</td>
    </tr>
  )
}