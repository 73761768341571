import { getCurrentSectionId } from '../entities/courseinstance'
import { bsliGet } from '../_core/util/request'

class MessageHolder {
	constructor() {
	}

	init(assignmentId, personId) {
		var sectionId = getCurrentSectionId()
		return bsliGet('sections/' + sectionId + '/assignments/' + assignmentId + '/messages?personId=' + personId)
			.then(messages => {
				// messages is array of {firstName, lastName, message, rejectionReason, timestamp, type, upload}
				// upload has id, fileName, submittedToTurnitin
				this.messages = messages
			})
	}
}

export function getMessages(assignmentId, personId) {
	let messageHolder = new MessageHolder()
	return new Promise((resolve, reject) => {
		messageHolder.init(assignmentId, personId)
		.then(() => {
			resolve(messageHolder)
		})
		.catch(() => {
			reject('messageHolder did not load')
		})
	})
}
	//app.reqres.setHandler('messages', function(assignmentId, personId) {
    //return new Entities.Messages([], { assignmentId: assignmentId, personId: personId }).fetch();
  //};
//}
