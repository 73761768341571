import { getCurrentPerson } from '../entities/person.jsx'
import { amOnACourseSpecificPage } from '../entities/course'
import { getCourseInstanceId } from '../entities/courseinstance'
import { bsliGetNoAuth } from '../_core/util/request'

var drupalBaseUrl = _.memoize(function () {
  return 'https://apcontent' + CMS_ENV + '.collegeboard.org/'
})

export function pathToUrl(path) {
  if (path) {
    if (path[0]==='/') {
      return drupalBaseUrl() + path.substring(1)
    } else if (path.startsWith('http')) {   // case of Drupal7
      return path
    } else {
      return drupalBaseUrl() + path
    }
  } else {
    return ''
  }
}

let getFirstNode = response => {
  // response is one of these types:
  //  1) Has a property called 'nodes' which is an array of objects, each having a property 'node'.
  //     We return the first 'node' object.
  //     The 'node' object can be an array, in which case, if it has elements, we return the first element.
  //  2) Is an array of objects containing what used to be in the node property
  let node = ''
  if (response.nodes) {
    if (response.nodes.length) {
      if (response.nodes[0].node) {
        let n = response.nodes[0].node
        if (_.isArray(n)) {
          if (n.length) {
            return n[0]
          }
        } else {
          node = n
        }
      }
    }
  } else {
    if (_.isArray(response) && response.length) {
      if (response[0]) {
        node = response[0]
      }
    }
  }
  return node
}

// Function transforms drupal's response which can be of 3 variations:
// An object with property 'nodes': {node: {title,id,...}}
// An array which is returned directly
// An array with objects that contain a property 'node': [{node: {title,id,...}]
let getAllNodes = response => {
  let nodes = []
  if (Array.isArray(response)) {
    nodes = response
  } else {
    const isNodesArray = Array.isArray(response.nodes)
    if (!isNodesArray) nodes = response.nodes.node
    else nodes = response.nodes.map(r => r.node)
  }
  return nodes
}

// the only difference between getDrupalNode and getDrupalNodes is what they do with the response, the calls are the same
let getDrupalNodeFunc = async function (viewPath, id) {
  //put the 'xx' back in to test what happens when Drupal is down
  let url = drupalBaseUrl() + viewPath /*+ 'xx'*/ + (id ? '/' + id : '')
  //console.log('getDrupalNode viewPath ' + viewPath + ', url ' + url)
  let res = ''
  try {
    await bsliGetNoAuth(url)
      .then(response => {
        res = getFirstNode(response)
        //console.log('getDrupalNode done')
      })
  } catch (err) {
    console.error('getDrupalNode in catch', err)
  }
  //console.log('getDrupalNode res',res)
  return res
}
var getDrupalNode = _.memoize(getDrupalNodeFunc, (viewPath, id) => viewPath + id) //by default memoize uses the first argument as the cache property, we need to make it more unique

var getDrupalNodesFunc = async function (viewPath, id) {
  let url = drupalBaseUrl() + viewPath + (id ? '/' + id : '')
  //console.log('getDrupalNodes viewPath ' + viewPath + ', url ' + url)
  let res = []
  try {
    await bsliGetNoAuth(url)
      .then(response => {
        //console.log('getDrupalNodes done', response)
        res = getAllNodes(response)
      })
  } catch (err) {
    console.error('getDrupalNodes in catch', err)
  }
  //console.log('getDrupalNodes res',res)
  return res
}
var getDrupalNodes = _.memoize(getDrupalNodesFunc, (viewPath, id) => viewPath + id) //by default memoize uses the first argument as the cache property, we need to make it more unique

export function contentField(fieldName, courseInstanceId) {
  return getDrupalNode('course-list', courseInstanceId || getCourseInstanceId()).then(_.property(fieldName))
}
export function cmsModule(contentId) {
  return getDrupalNode('module', contentId)
}
export function subtask(contentId) {
  return getDrupalNode('subtask', contentId)
}
export function cmsTerms() {
  return getDrupalNode('terms')
}
export function cmsHelp() {
  return getDrupalNodes('help')
}
export function cmsGeneral() {
  return getDrupalNode('general-content')
}
export function cmsDocuments() {
  return getDrupalNodes('docs')
}

// this 'and' function is too eoteric for me
var and = function() {
  var predicates = [].slice.call(arguments)
  return function() {
    for (var predicate of predicates)
      if (!predicate.apply(null, arguments))
        return false
    return true
  }
}

export function alterHTML(text, marker) {
  let html = ''
  try {
    if (marker) {
      let re = new RegExp(marker + '(.*)' + marker)
      let groups = text ? re.exec(text.replace(/\n|\r|/g, '')) : []
      if (groups && groups.length > 1) {
        html = groups[1]
      } else {
        html = ''
      }
    } else {
      html = text ? text : ''
    }
  } catch (e) {
    console.log('alterHTML failed on text=' + text + ', marker=' + marker)
  }
  return html
}

export function showLoadingText(property, context, textCallback, errorCallback, targetId, marker) {
  var failureMessage = 'Error: Content could not be loaded. Please try again later.'

  function showText(text) {
    let html
    if (marker) {
      let re = new RegExp(marker + '(.*)' + marker)
      let groups = re.exec(text.replace(/\n|\r|/g,''))
      if (groups && groups.length > 1) {
        html = groups[1]
      } else {
        html = ''
      }
    } else {
      html = text ? text : ''
    }
    textCallback(context, html)

    setTimeout(function () { // without this delay, doesn't work if overview was already loaded
      if (targetId) { // scroll to the specified id, if specified and present
        var target = $('#' + targetId)
        if (target[0]) $('html, body').animate({ scrollTop: target.offset().top }, 0)
      }
    }, 0)
  }

  if (!!property) {
    showText(property)
  } else {
    console.error('showLoadingText had empty property')
    errorCallback && errorCallback(context, failureMessage)
  }
}

// courseStructureId is a moduleId or lessonId
// CMS types 'document' and 'document blurb' are both in the document list returned by cmsDocuments()
// we find a (document, document blurb) pair that match the user type and each other
// CMS does not match these types, as you would expect
// first we find the first document matches the user and has a file and is publishable
// then we search for the first 'document blurb' that matches the document
// in converting from webpack version, I altered the locationId logic in the first search since it failed
// when the locationId was of the form 'a, b'
// why the second locationId check in webpack used '===' I don't understand, but it does
// all this can easily get messed up
export function cmsPublishable(courseStructureId, roles) {
  //console.log('cmsPublishable courseStructureId=' + courseStructureId)
  return cmsDocuments()
    .then(documents => {
      let publishableDocument = _.find(documents, doc => {
        let bool1 = !!_.intersection(doc['Target Audience'].split(', '), roles).length
        let bool2 = !!_.find(doc.LocationId.split(', '), loc => { return loc.trim() === courseStructureId })
        let bool3 = doc.publishable === 1 || doc.publishable === '1'
        let bool4 = !!doc.File
        return bool1 && bool2 && bool3 && bool4
      })

      if (!publishableDocument) return;
      let blurb = _.find(documents, doc => {
        let bool1 = !!_.intersection(doc['Target Audience'].split(', '), roles).length
        let bool2 = doc.LocationId === publishableDocument.LocationId // different than the above
        let bool3 = doc.publishable === 1 || doc.publishable === '1'
        return bool1 && bool2 && bool3
      })

      //console.log('blurb',blurb)
      if (!!blurb) {
        return _.extend(publishableDocument, _.pick(blurb, 'Body'))
      } else {
        return false
      }
    })
}

export async function cmsNotifications() {
  let person = getCurrentPerson()
  if (amOnACourseSpecificPage()) {
    let list1 = await getDrupalNodes('notifications/' + getCourseInstanceId() + '/everyone')
    if (person.isStudent() || person.isTeacher() || person.isCoordinator()) {
      // we need to separately get 'everyone' and the audience, then merge them
      let audience = person.isStudent() ? 'students' : person.isTeacher() ? 'teachers' : 'apcoordinators'
      let list2 = await getDrupalNodes('notifications/' + getCourseInstanceId() + '/' + audience)
      return [...new Set(list1.concat(...list2))]
    } else {
      return list1
    }
  } else {
    // drupal9 requires both course and audience
    // blank course is not allowed, val+val is not allowed
    const allEveryone = await getDrupalNodes('notifications/0/everyone')
    let allStudentsOrTeachers = []
    if (person.isStudent() || person.isTeacher() || person.isCoordinator()) {
      let audience = person.isStudent() ? 'students' : person.isTeacher() ? 'teachers' : 'apcoordinators'
      allStudentsOrTeachers = await getDrupalNodes(`notifications/0/${audience}`)
    }
    return [...new Set(allEveryone.concat(...allStudentsOrTeachers))]
  }
}
let start
async function runDrupalIter(urlPath,id,cnt) {
  if (cnt <= 0) {
    let end = (new Date()).getTime()
    let total = (end-start)/1000
  //  console.log('runDrupalIter finished, path=' + urlPath + ', id=' + id + ', total time=' + total + ' seconds')
  } else {
  //  console.log('runDrupalIter in progress cnt=' + cnt + ', path=' + urlPath + ', id=' + id)
    await getDrupalNodeFunc(urlPath,id)
    .then(res => {
      // we wanted a delay here to test the cache problem
      // I had set it to 5 min, but I could not successfully
      // alter the cache-control header
      setTimeout(() => {
      //  console.log('runDrupalIter res ',res)
        runDrupalIter(urlPath,id,cnt-1)  
      }, 10)
    })  
  }
}

export function drupalTest() {
  console.log('drupalTest')
  start = (new Date()).getTime()
  //11950
  let startingCnt = 12
  let endpoints = [
    { path: 'general-content', id: '', count: startingCnt },
    { path: 'docs', id: '', count: startingCnt },
    { path: 'terms', id: '', count: startingCnt },
    { path: 'help', id: '', count: startingCnt },
    { path: 'course-list', id: '', count: startingCnt },
    { path: 'subtask', id: '42230201', count: startingCnt },
    { path: 'subtask', id: '42230202', count: startingCnt },
    { path: 'notifications/4023/everyone', id: '', count: startingCnt },
    { path: 'notifications/4023/teachers', id: '', count: startingCnt },
    { path: 'notifications/4023/students', id: '', count: startingCnt },
    { path: 'notifications/4123/everyone', id: '', count: startingCnt },
    { path: 'notifications/4123/teachers', id: '', count: startingCnt },
    { path: 'notifications/4123/students', id: '', count: startingCnt },
    { path: 'notifications/4223/everyone', id: '', count: startingCnt },
    { path: 'notifications/4223/teachers', id: '', count: startingCnt },
    { path: 'notifications/4223/students', id: '', count: startingCnt }
  ]
  // this will start all the recursive calls on the endpoints immediately
  endpoints.forEach(
    endpoint => {
      runDrupalIter(endpoint.path, endpoint.id, endpoint.count)
    }
  )
}