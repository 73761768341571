import React, { useState, useEffect }                                                    from 'react';
import { YellowButton, NakedButton, Checkbox, BlackButton }     from '@cb/apricot-react';
import DatePicker                                               from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { bsliPut }                                              from '../../_core/util/request';
import moment                                                   from 'moment';
import CBRegExpValidation                                       from '@cb/apricot/CBRegExpValidation'

export function ShowCourseParamsComp({courseInstanceId, parameters,course }) {
    const [alertProps, setAlertProps] = useState(false)
    const [date, setDate] = useState(null)
    // selectedParam holds the one and only parameter that is being edited, if any
    const [selectedParam, setSelectedParam] = useState(false)
    const [workingParameters, setWorkingParameters] = useState(adjustParameters())
    //let originalParams = JSON.parse(JSON.stringify(parameters))

    function adjustParameters() {
        return _.map(parameters, param => {
            param.originalValue = param.value
            return param
        })
    }

    function boolDirections(name, value) {
        return (
            <div id='boolDirections'>
                <span className='sr-only'>{'You are in a cell with three controls for changing the state of ' + name}</span>
                <span className='sr-only'>{', the current value of ' + name + ' is ' + value}</span>
                <span className='sr-only'>{', please make your selection by altering the checkbox'}</span>
                <span className='sr-only'>{' and then select either the Save or Cancel buttons.'}</span>
            </div>
        )
    }

    function dateDirections(name, value) {
        return (
            <div id='boolDirections'>
                <span className='sr-only'>{'You are in a cell with three controls for changing the date of ' + name}</span>
                <span className='sr-only'>{', the current value of ' + name + ' is ' + value}</span>
                <span className='sr-only'>{' please make your selection by altering the text date field'}</span>
                <span className='sr-only'>{' and then click the Save or Cancel buttons.'}</span>
                <span className='sr-only'>Please type the date in the format mm/dd/yyyy</span>
            </div>
        )
    }

    const displayBooleanParam = (parameter) => {
        let name = parameter.name
        let id = parameter.id
        let currentVal = parameter.value ? 'enabled' : 'disabled'
        let isSelected = (selectedParam && selectedParam.id === id);
        if (isSelected) {
            return (
                <div className="row" id={id} aria-describedby='boolDirections'>
                    {boolDirections(name, currentVal)}
                    {showAlert("param")}

                    <span id="checkedCheckBox" >
                        <Checkbox
                            label="Enabled" checked={selectedParam.value}
                            onChange={(c, e) => { handleCheck(c, e) }} />
                    </span>
                    <span>
                        <YellowButton small="true" onClick={() => { saveParam() }} >Save</YellowButton>
                    </span>
                    <span className="cb-spacerh-left-8">
                        <BlackButton small="true" onClick={() => cancelAndResetParamEdit(id)}>Cancel</BlackButton>
                    </span>
                </div>
            )
        } else {
            if (currentVal === 'enabled') {
                return (
                    <NakedButton
                        id={id} noPadding
                        onClick={editParam} icon='check' aria-label={'parameter ' + name + " Is Enabled"}>
                    </NakedButton>
                )
            } else {
                return (
                    <NakedButton
                        id={id} noPadding className='cb-red1-color'
                        onClick={editParam} icon='error' aria-label={'parameter ' + name + " Is Disabled"}>
                    </NakedButton>
                )
            }
        }
    }

    const displayRestricteDateParam = (parameter) => {
        let id = parameter.id
        let isSelected = (selectedParam && selectedParam.id === id);
        if (isSelected) {
            return (
                <div className="row" id={id} aria-describedby='dateDirections'>
                    {dateDirections(parameter.name, date)}
                    {showAlert("param")}
                    <DatePicker
                        id="datepicker"
                        maxDate = {course.hardEndDate}
                        dateFormat="MM/dd/yyyy"
                        selected={date}
                        onChange={(newDate) => {
                            setDate(newDate);
                            let selected = _.clone(selectedParam)
                            selected.value = moment(newDate).format('MM/DD/YYYY')
                            setSelectedParam(selected);
                        }
                        }
                    />
                    <span>
                        <YellowButton small="true" onClick={() => { saveParam() }} >Save</YellowButton>
                    </span>
                    <span className="cb-spacerh-left-8">
                        <BlackButton small="true" onClick={() => cancelAndResetParamEdit(id)}>Cancel</BlackButton>
                    </span>
                </div>
            )
        } else {
            return (
                <NakedButton id={id} noPadding onClick={editParam}>
                    {parameter.value}
                </NakedButton>
            )
        }
    }

    const displayDateParam = (parameter) => {
        let id = parameter.id
        let isSelected = (selectedParam && selectedParam.id === id);
        if (isSelected) {
            return (
                <div className="row" id={id} aria-describedby='dateDirections'>
                    {dateDirections(parameter.name, date)}
                    {showAlert("param")}
                    <DatePicker
                        id="datepicker"
                        dateFormat="MM/dd/yyyy"
                        selected={date}
                        onChange={(newDate) => {
                            setDate(newDate);
                            let selected = _.clone(selectedParam)
                            selected.value = moment(newDate).format('MM/DD/YYYY')
                            setSelectedParam(selected);
                        }
                        }
                    />
                    <span>
                        <YellowButton small="true" onClick={() => { saveParam() }} >Save</YellowButton>
                    </span>
                    <span className="cb-spacerh-left-8">
                        <BlackButton small="true" onClick={() => cancelAndResetParamEdit(id)}>Cancel</BlackButton>
                    </span>
                </div>
            )
        } else {
            return (
                <NakedButton id={id} noPadding onClick={editParam}>
                    {parameter.value}
                </NakedButton>
            )
        }
    }

    const displayParam = (parameter) => {
        if (parameter.type === 'boolean') {
            return displayBooleanParam(parameter)
        } else {
            if(parameter.name==='requestExtensionEnabledDate' || parameter.name==='requestExtensionDisabledDate'){
                return displayRestricteDateParam(parameter)
            }
            return displayDateParam(parameter)
        }
    }

    const showParameters = workingParameters.map((parameter, index) =>
        <tr key={"param".concat(index)}>
            <td scope="col">{parameter.name}</td>
            <td scope="col"> {displayParam(parameter)} </td>
        </tr>
    )

    return (
        <div>
            <div className="row cb-spacerv-top-16 cb-spacerv-bottom-8 " >
                <h6 className="cb-spacerh-left-8" > Course Parameters</h6>
            </div>

            <table id="adminparams" className="cb-table cb-table-striped cb-spacerv-top-8  cb-spacerh-left-8">
                <thead>
                    <tr>
                        <th scope="col">Parameters</th>
                        <th scope="col">Values</th>
                    </tr>
                </thead>
                <tbody >
                    {showParameters}
                </tbody>
            </table>
        </div>
    )

    function handleCheck(c, e) {
        let selected = _.clone(selectedParam)
        selected.value = c
        setSelectedParam(selected);
    }

    function editParam(e) {
        clearAlert();
        e.preventDefault();
        resetAllparams();
        let id = e.currentTarget.id;

        let selected = workingParameters.find(p => p.id === id);
        if (!selected) {
            return;
        }
        setSelectedParam(selected);

        let val = selected.value;

        if (!(selected.type === 'boolean')) {
            let date = stringToDate(val);
            setDate(date);
        }
    }

    function stringToDate(datestr) {
        let momentDate = moment(datestr, "MM/DD/YYYY", true)
        if (momentDate.isValid()) {
            return momentDate.toDate();
        } else {
            return new Date();
        }
    }

    function saveParam() {
        if (selectedParam) {
            clearAlert();
            if (selectedParam.type !== 'boolean' && !CBRegExpValidation.date(selectedParam.value)) {
                displayAlert("Not valid date parameter.", "param")
                return;
            }

            let working = cloneWorkingParamters()
            let workingParam = working.find(p => p.id === selectedParam.id);
            if (workingParam && workingParam.value != selectedParam.value) {
                let url = 'course-instances/' + courseInstanceId + '/config/' + selectedParam.id;
                clearAlert();
                bsliPut(url,
                    { value: selectedParam.value }
                ).then(() => {
                    workingParam.originalValue = selectedParam.value;
                    workingParam.value = selectedParam.value;
                    setSelectedParam(false)
                    setWorkingParameters(working)
                }).catch(() => {
                    displayAlert("Error happenned please try again", "param");
                })
            } else {
                setSelectedParam(false)
            }
        }
    }

    function cloneWorkingParamters() {
        let working = []
        workingParameters.forEach((parameter) => {
            working.push(_.clone(parameter))
        })
        return working
    }

    function resetParam(id) {
        let working = cloneWorkingParamters()
        let param = working.find(p => p.id === id);
        if (param) {
            param.value = param.originalValue;
        }
        setWorkingParameters(working)
    }

    function cancelAndResetParamEdit(id) {
        resetParam(id);
        setSelectedParam(false)
    }

    function resetAllparams() {
        let working = []
        workingParameters.forEach((parameter) => {
            parameter.value = parameter.originalValue
            working.push(parameter)
        })
        setWorkingParameters(working)
    }

    function clearAlert() {
        setAlertProps(false)
    }

    function showAlert(section) {
        return (
            <div aria-live='polite'>
                {alertProps && alertProps.alertSection === section &&
                    <div className='alert alert-danger'>
                        <span>{alertProps.alertMsg}</span>
                    </div>
                }
            </div>
        )
    }

    // section is always 'param'
    function displayAlert(message, section) {
        setAlertProps({
            alertMsg: message,
            alertSection: section
        })
    }
}
