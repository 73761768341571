import React, { Fragment, useState, useEffect } from 'react';
import { AlertComp  }                           from './alert.jsx'

export function FileUpload({name, title, extensions, errorCallback, fileSelectedCallback}) {
    let fileSelectElemId = 'file-select-' + name
    let extensionStr = ''
    let extensionArr = []
    if (typeof extensions === 'string') {
        extensionStr = extensions
        extensionArr = _.map(extensions.split(','), ext => ext.trim())
    } else if (_.isArray(extensions)) {
        extensionStr = _.map(extensions, ext => '.' + ext).join(', ')
        extensionArr = _.map(extensions, ext => ext.trim())
    }
    let errorMessageFileType   = 'Error: Only the following file types are allowed: ' + extensionStr + '.'
    let errorMessageFileLength = 'Error: The file could not be uploaded because the file name is longer than 255 characters. Please rename your file and try again.'
    let errorMessageCharacters = 'Error: The file could not be uploaded because there are encoded characters in the file name. Please rename your file and try again.'

    const FILE_UPLOAD_ID = 'file-upload'
    const VALIDATE_ID = 'validate'

    const [alertProps, setAlertProps] = useState(false)

    useEffect(() => {
        const uploadBtn = document.getElementById(fileSelectElemId)
        window.cb.apricot.CBForm.fileUpload({
            elem: uploadBtn
        })

        //console.log('useEffect initializing')
        // fileSelectedCallback(isFileDefined())
        // errorCallback(isFileDefined() && isFileGood())

        // custom event when a file has been selected
        uploadBtn.addEventListener('apricot_fileSelected', (e) => {
            //console.log('File Added')
            fileSelectedCallback(true)
         })

        // custom event when selected file has been removed
        uploadBtn.addEventListener('apricot_fileRemoved', (e) => {
            //console.log('File Removed')
            fileSelectedCallback(false)
        })
    }, []); // the second parameter ensures useEffect runs only once

    // function isFileDefined() {
    //     let fileInput = document.getElementById(fileSelectElemId)
    //     let file = fileInput.files[0]
    //     return !!file
    // }

    // function isFileGood() {
    //     return !alertProps
    // }

    function fileChange() {
        let fileGood = true
        removeErrorMessage();
        let fileInput = document.getElementById(fileSelectElemId)
        let file = fileInput.files[0]
        var extension = file.name.split('.').pop().toLowerCase();

        if (!_.contains(extensionArr, extension)) {
            addErrorMessage(errorMessageFileType, VALIDATE_ID)
            fileGood = false
        } else if (file.name.length > 255) {
            addErrorMessage(errorMessageFileLength, VALIDATE_ID)
            fileGood = false
        } else if (/[^\x00-\x7F]/g.test(file.name)) { //check for ASCII
            addErrorMessage(errorMessageCharacters, VALIDATE_ID)
            fileGood = false
        }
        errorCallback(fileGood)
    }

    function addErrorMessage(message, type) {
        setAlertProps({
            isDismissable: false,
            showAlert: true,
            message: message,
            uniqueId: type
        })
    }

    function removeErrorMessage() {
        setAlertProps(false)
    }

    return (
        <Fragment>
            <div className="alert-wrapper">
                {alertProps && alertProps.showAlert &&
                    <AlertComp params={alertProps}></AlertComp>
                }
            </div>
            <div id={'file-upload-' + name} className='cb-file-upload' data-cb-apricot='file-upload'
                 style={{'height': '80px'}}>
                <label htmlFor={fileSelectElemId}
                       style={{'fontWeight': 500, 'float': 'left', 'marginRight': '10px', 'marginTop': '12px'}}>
                    {title}
                </label>
                <input type='file' id={fileSelectElemId} name={name}
                    accept={extensionStr} aria-labelledby="uploadFileLimitations"
                    onChange={fileChange} />
                <span className="sr-only" id="uploadFileLimitations">
                    Document needs to be one of the follow file types:
                    {extensionStr}.
                </span>
            </div>
        </Fragment>
    )
  }
