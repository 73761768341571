import React from 'react'
import moment from 'moment'
import { getCurrentPerson } from '../../entities/person.jsx'
import {
  getCourseParameter,
  getCurrentSectionId,
  getSection,
  hasLateSubmissionDate,
  isAfterHardEndDate,
  isAfterLateSubmissionDate,
  isArt3D,
  isScorableAssignment,
} from '../../entities/courseinstance'
import { addBaseUrl, addBaseUrlAndApiKey } from '../../_core/util/URL.jsx'
import SubmissionAlert from '../views/submissionAlert.jsx'
import CSP_WR from '../IWR/CSP_WR'
import MenuBarComp from './menuBar'
import { ArtImagesComp } from '../art/views/Images'
import { ArtWrittenEvidenceComp } from '../art/views/writtenEvidence'
import { parse } from '../art/entities/downloadArt'
import { Spinner } from '@cb/apricot-react'

export default function FileUploadAndSubmissionComp(props) {
  const [loading, setLoading] = useState(true)
  const { menuParms, refreshStudentLayout } = props.parms;
  const getCspParms = (menuParms) => {
    let cspHelpers = {
      isCSP_WR: false,
      parms: false
    }
    if (menuParms && menuParms.lesson && menuParms.isCSP_WR && getCurrentPerson().isStudent()) {
      cspHelpers.isCSP_WR = true
      let uri = [
        'sections/',
        getCurrentSectionId(),
        '/assignments/',
        menuParms.assignment.id,
        '/uploads'
      ].join('')
      let saveUrl = addBaseUrlAndApiKey(uri)
      let finalSubmittedDate = ''
      if (menuParms.scorableHolder.scorable.submittedAt && !isNaN(menuParms.scorableHolder.scorable.submittedAt)) {
        finalSubmittedDate = moment(menuParms.scorableHolder.scorable.submittedAt).format('MM/DD')
      }
      let isFinalSubmitted = finalSubmittedDate.length > 0
      let autoSaveUri = [
        'sections/',
        getCurrentSectionId(),
        '/assignments/',
        menuParms.assignment.id,
        '/autosave'
      ].join('')
      let autoSaveUrl = addBaseUrl(autoSaveUri)
      let scoringEnabled = getCourseParameter('scoringEnabled')
      let currentSection = getSection(getCurrentSectionId()),
        isPastSoftDate = currentSection.pastSoftEndDate,
        afterHardEndDate = isAfterHardEndDate()
      let isAfterHardOrLateDate = hasLateSubmissionDate() ? isAfterLateSubmissionDate() : afterHardEndDate
      let courseReadOnly = isAfterHardOrLateDate ||
        (isPastSoftDate && !menuParms.scorableHolder.scorable.enrollmentHasExtension)
      let readOnly = isFinalSubmitted || courseReadOnly
      cspHelpers.parms = {
        saveUrl,
        finalDate: finalSubmittedDate,
        readOnly,
        data: menuParms.wrData,
        autoSaveUrl,
        finalSubmitInfo: menuParms,
        scoringEnabled,
        autosaveAfterSave: menuParms.autosaveAfterSave,
        assignmentId: menuParms.assignment.id,
        personId: props.parms.personId,
        isPastSoftDate,
        enrollmentHasExtension: menuParms.scorableHolder.scorable.enrollmentHasExtension,
      }
    }
    return cspHelpers
  }

  const getArtImagesParms = (menuParms, type) => {
    let helpers = {
      isArt: false,
      parms: false
    }
    let correctData = type === 'SI' ? menuParms.isArt_SI_Images : menuParms.isArt_SW_Images
    if (menuParms && menuParms.lesson && correctData && getCurrentPerson().isStudent()) {
      helpers.isArt = true
      let uri = [
        'sections/',
        getCurrentSectionId(),
        '/assignments/',
        menuParms.assignment.id,
        '/uploadsmulti'
      ].join('')
      let saveUrl = addBaseUrlAndApiKey(uri)
      uri = [
        'sections/',
        getCurrentSectionId(),
        '/assignments/',
        menuParms.assignment.id,
        '/removelock'
      ].join('')
      let removeLockUrl = addBaseUrlAndApiKey(uri)
      let finalSubmittedDate = ''
      if (menuParms.scorableHolder.scorable.submittedAt && !isNaN(menuParms.scorableHolder.scorable.submittedAt)) {
        finalSubmittedDate = moment(menuParms.scorableHolder.scorable.submittedAt).format('MM/DD')
      }
      let isFinalSubmitted = finalSubmittedDate.length > 0
      let scoringEnabled = getCourseParameter('scoringEnabled')
      let currentSection = getSection(getCurrentSectionId()),
        isPastSoftDate = currentSection.pastSoftEndDate,
        afterHardEndDate = isAfterHardEndDate()
      let isAfterHardOrLateDate = hasLateSubmissionDate() ? isAfterLateSubmissionDate() : afterHardEndDate
      let courseReadOnly = isAfterHardOrLateDate || (isPastSoftDate && !menuParms.scorableHolder.scorable.enrollmentHasExtension)
      let readOnly = isFinalSubmitted || courseReadOnly
      helpers.parms = {
        type,
        saveUrl,
        removeLockUrl,
        finalDate: finalSubmittedDate,
        readOnly,
        data: parse(menuParms.artData,type, isArt3D()),
        finalSubmitInfo: menuParms,
        scoringEnabled: scoringEnabled,
        refreshStudentLayout,
      }
    }
    return helpers
  }

  const getArtWrittenEvidenceParms = (menuParms) => {
    let helpers = {
      isArt: false,
      parms: false
    }
    if (menuParms && menuParms.lesson && menuParms.isArt_WrittenEvidence && getCurrentPerson().isStudent()) {
      helpers.isArt = true

      // save url is the same as that for CSP WR
      let uri = [
        'sections/',
        getCurrentSectionId(),
        '/assignments/',
        menuParms.assignment.id,
        '/uploads'
      ].join('')
      let saveUrl = addBaseUrlAndApiKey(uri)
      let finalSubmittedDate = ''
      if (menuParms.scorableHolder.scorable.submittedAt && !isNaN(menuParms.scorableHolder.scorable.submittedAt)) {
        finalSubmittedDate = moment(menuParms.scorableHolder.scorable.submittedAt).format('MM/DD')
      }
      let isFinalSubmitted = finalSubmittedDate.length > 0
      let scoringEnabled = getCourseParameter('scoringEnabled')
      let currentSection = getSection(getCurrentSectionId()),
        isPastSoftDate = currentSection.pastSoftEndDate,
        afterHardEndDate = isAfterHardEndDate()
      let isAfterHardOrLateDate = hasLateSubmissionDate() ? isAfterLateSubmissionDate() : afterHardEndDate
      let courseReadOnly = isAfterHardOrLateDate || (isPastSoftDate && !menuParms.scorableHolder.scorable.enrollmentHasExtension)
      let readOnly = isFinalSubmitted || courseReadOnly
      helpers.parms = {
        saveUrl,
        finalDate: finalSubmittedDate,
        readOnly,
        data: menuParms.artData,
        finalSubmitInfo: menuParms,
        scoringEnabled
      }
    }
    return helpers
  }

  let cspHelpers = getCspParms(props.parms.menuParms)
  let artSIImagesHelpers = getArtImagesParms(props.parms.menuParms, 'SI')
  let artSWImagesHelpers = getArtImagesParms(props.parms.menuParms, 'SW')
  let artWrittenEvidenceHelpers = getArtWrittenEvidenceParms(props.parms.menuParms)

  let isCSP = cspHelpers.isCSP_WR && cspHelpers.parms
  let isArtSIImages = artSIImagesHelpers.isArt && artSIImagesHelpers.parms
  let isArtSWImages = artSWImagesHelpers.isArt && artSWImagesHelpers.parms
  let isArtWrittenEvidence = artWrittenEvidenceHelpers.isArt && artWrittenEvidenceHelpers.parms
  let isArt = isArtSIImages || isArtSWImages || isArtWrittenEvidence
  let isMenu = !(isCSP || isArt)
  let isScorableAssignmentFlag = isScorableAssignment(menuParms.assignment.id, menuParms.assignment.assignmentAbbreviation)

  useEffect(() => {
    if (props.parms.menuParms) setLoading(false)
  }, [props.parms.menuParms])

  if (loading) return <Spinner  />

  return (
    <div>
      {props.parms.menuParms && !isScorableAssignmentFlag && (
        <div>
          <h5 className="add-top-margin-1x">File Upload and Submission</h5>
          <div className="cb-gray9-bg add-padding">
            <div id="region-submission-alert-WR">
              {(isCSP || isArt) && props.parms.alertReason && !isArtWrittenEvidence &&
            <SubmissionAlert alertReason={props.parms.alertReason} lesson={props.parms.menuParms.lesson} index={0} />
              }
            </div>
            <div id="region-assignment-menubar">
              {isCSP &&
               <CSP_WR parms={cspHelpers.parms} toolKey={`CSP_WR_${props.parms.personId}`} />
              }
              {isArtSIImages &&
                <ArtImagesComp params={artSIImagesHelpers.parms} />
              }
              {isArtSWImages &&
                <ArtImagesComp params={artSWImagesHelpers.parms} />
              }
              {isArtWrittenEvidence && (
                <ArtWrittenEvidenceComp
                  parms={artWrittenEvidenceHelpers.parms}
                  toolKey={`ArtWrittenEvidence_${props.parms.personId}`}
                />
              )}
              {isMenu &&
                <MenuBarComp parms={props.parms} />
              }
            </div>
            <div id="region-submission-alert">
              {props.parms.alertReason &&
                <SubmissionAlert alertReason={props.parms.alertReason} lesson={props.parms.menuParms.lesson} index={1}/>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
