import { Modal, Dropdown, DropdownItem, Button, YellowButton, PrimaryButton } from '@cb/apricot-react'
import { bsliPut } from '../../_core/util/request'

const reasons = [
  { key: 'Technical issue', label: 'Technical issue – customer related technical issues'},
  { key: 'Illness', label: 'Illness'},
  { key: 'Issues with AP Registration and Ordering', label: 'Issues with AP Registration and Ordering'},
  { key: 'Issues with AP Capstone Teacher scoring training', label: 'Issues with AP Capstone Teacher scoring training'},
]

const RequestExtensionModal = ({
  fullName,
  enrollmentId,
  courseTitle,
  modalOpen,
  setModalOpen,
  requestExtensionDisabledDate,
  setExtendedEndDate,
}) => {
  const [selectedReason, setSelectedReason] = useState()
  const [extensionSubmitted, setExtensionSubmitted] = useState(false)

  const submitExtendedEndDate = async (extendedEndDate, extendedEndDtReason) => {
    const url = 'admin/extension/enrollmentid/' + enrollmentId

    try {
      await bsliPut(url, {
        extendedEndDate: new Date(extendedEndDate),
        extendedEndDtReason: extendedEndDtReason,
      })
      setExtendedEndDate(extendedEndDate)
      setExtensionSubmitted(true)
    } catch (error) {
      console.error(error)
    }
  }

  const reasonItems = () =>
    reasons.map((reason, key) => (
      <DropdownItem
        key={key}
        label={reason.label}
        // selected={reason.selected}
        dropdownItemId={`extension_reason_${key}`}
        onClick={() => setSelectedReason(reason)}
        data-testid="reason-select-option"
      />
    ))
  const footer = extensionSubmitted ? (
    <div className="cb-btn-row">
      <PrimaryButton
        className="modal-btn"
        small
        onClick={() => {
          setExtensionSubmitted(false)
          setSelectedReason()
        }}
        data-cb-modal-close
        data-testid="ok-button"
      >
        OK
      </PrimaryButton>
    </div>
  ) : (
    <div className="cb-btn-row">
      <Button type="button" className="modal-btn" small data-cb-modal-close>
        Cancel
      </Button>
      <PrimaryButton
        className="modal-btn"
        small
        disabled={!selectedReason}
        onClick={() => {
          submitExtendedEndDate(requestExtensionDisabledDate, selectedReason.key)
        }}
        data-testid="submit-button"
      >
        Submit
      </PrimaryButton>
    </div>
  )
  const renderSubmissionContent = () => (
    <>
      <p>
        {`You are requesting an extension for ${fullName} enrolled in ${courseTitle}. Please select the reason this student needs an extension below:`}
      </p>
      <Dropdown title={''} className="drop-down" classNameToggle="drop-down-toggle" selectList closeOnClick closeOnClickOutside>
        {reasonItems()}
      </Dropdown>
    </>
  )

  const renderConfirmationContent = () => (
    <>
      <p>
        {`Your extension request for ${fullName} enrolled in ${courseTitle} has been granted. The extended submission date for this student is 11:59 EDT on ${new Date(
          requestExtensionDisabledDate
        ).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' })}.`}
      </p>
      <p>
        {`If you have any questions or issues regarding the extension, please contact customer service at  apexams@info.collegeboard.org or 1-877-274-6474.`}
      </p>
    </>
  )
  return (
    <Modal
      title={'Request Extensions'}
      escClose
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      withHeader
      footer={footer}
      className="extension-modal"
    >
      {extensionSubmitted ? renderConfirmationContent() : renderSubmissionContent()}
    </Modal>
  )
}

export default RequestExtensionModal
