import { getMessage }     from '../../utility/util'
import { BaseModal }      from './modal'
import { setStateValue }  from '../../utility/util'
import { fetchRequest }   from '../../_core/util/request'
import {refreshSupportingMaterials}    from '../../utility/service'

const FILE_UPLOAD_ID = 'file-upload'
const VALIDATE_ID = 'validate'

// formerly Common.FileUploadModal used by FileUploadToS3Modal and AddModalComp
export default class FileUploadModal extends BaseModal {
  constructor(props) {
    super(props);
            
    // props.parms has url, fileReqs, modalId
    // props.parms has url, assignment, fileReqs, modalId
    // fileReqs has extensions, maxSize, minSize  
    let id = this.props.parms.modalId ? '#' + this.props.parms.modalId + ' ' : ''
    this.ui = {
      errorContainer:   () => $(id + '.alert-wrapper)'),
      fileInput:        () => $(id + 'input[type=file]'),
      srHelp:           () => $(id + '.js-sr-help')
    }
    _.bindAll(this, 'uploadFailed', 'uploadFinished',
      'validateFile', 'validate', 'validateExtensionAndSize', 'validateName',
      'uploadLink', 'uploadFile', 'setUploadingButtonState', 'resetUploadingButtonState', 'uploadStarted', 'uploadFinished');
  }

  componentDidMount() {
    this.onRender()
  }
  componentDidUpdate() {
    this.onRender()
  }

  //   events: _.extend({} Common.Modal.prototype.events, {
  //     'click @ui.btnSubmit': 'upload',
  //     'change.bs.fileinput @ui.fileContainer': 'validate',
  //     'clear.bs.fileinput @ui.fileContainer': 'resetForm'
  //   }),


  onRender() {
    this.checkFileAPI();
  }

  serializeData() {
    return {
      fileExtensions: this.props.parms.fileReqs.extensions.join(', '),
      acceptExtensions: _.map(this.props.parms.fileReqs.extensions, ext => '.' + ext).join(', '),
      fileMinSize: filesize(this.props.parms.fileReqs.minSize).toUpperCase(),
      fileMaxSize: filesize(this.props.parms.fileReqs.maxSize).toUpperCase()
    }
  }

  checkFileAPI() {
    if (window.File && window.FileReader && window.FileList && window.Blob) return;
    else {
      this.addErrorMessage(getMessage('invalidBrowserVersion'))
      this.enableSubmit(false)
    }
  }

  validate(file,duration) {
    var errorMessage = this.validateFile(file,duration);
    if (_.isEmpty(errorMessage)) {
      this.removeErrorMessage();
      return true
    } else {
      this.addErrorMessage(errorMessage, VALIDATE_ID)
      return false
    }
  }

  validateFile(file,duration) {
    //var file = fileIn || this.ui.fileInput()[0].files[0]
    let fileDuration = duration ? duration : null
    if (file) {
      return this.validateExtensionAndSize(file.size, file.name, fileDuration) || this.validateName(file.name)
    } else {
      return 'No file selected'
    }
  }

  validateExtensionAndSize(fileSize, fileName, fileDuration) {
    //console.log('fileDuration=' + fileDuration)
    var filesizeOpts = { spacer: '', round: 0 }
    var fileExtension;

    if (!fileName) {
      return this.createErrorMessage(getMessage('invalidFileType'), this.props.parms.fileReqs.extensions.join(', '));
    }

    fileExtension = fileName.split('.').pop().toLowerCase();

    let retVal = ''
    if (!_.contains(this.props.parms.fileReqs.extensions, fileExtension)) {
      retVal = this.createErrorMessage(getMessage('invalidFileType'), this.props.parms.fileReqs.extensions.join(', '));
    } else if ((fileDuration > 60) && (fileSize && fileSize > this.props.parms.fileReqs.maxSize)) {
      retVal = this.createErrorMessage(getMessage('invalidFileDurationAndMaxSize'), filesize(this.props.parms.fileReqs.minSize, filesizeOpts))
    } else if (fileSize !== undefined && (fileSize <= this.props.parms.fileReqs.minSize)) {
      retVal = this.createErrorMessage(getMessage('invalidFileMinSize'), filesize(this.props.parms.fileReqs.minSize, filesizeOpts));
    } else if (fileSize && fileSize > this.props.parms.fileReqs.maxSize) {
      retVal = this.createErrorMessage(getMessage('invalidFileMaxSize'), filesize(this.props.parms.fileReqs.maxSize, filesizeOpts));
    } else if ((fileDuration > 60) && !(fileSize && fileSize > this.props.parms.fileReqs.maxSize)) {
      retVal = this.createErrorMessage(getMessage('invalidFileDuration'), filesize(this.props.parms.fileReqs.minSize, filesizeOpts))
    }
    return retVal
  }

  validateName(fileName) {
    let retVal = ''
    if (fileName.length > 255) {
      retVal = this.createErrorMessage(getMessage('invalidFileLength'), fileName);
      //} else if (/[^a-zA-Z0-9!@#$%^&{}\[\]()_\+\-=,.~'` \\]/g.test(fileName)) { //modified to match Java regex in back-end
    } else if (/[^\x00-\x7F]/g.test(fileName)) { //check for ASCII
      retVal = this.createErrorMessage(getMessage('invalidFileCharacters'), fileName);
    }
    return retVal
  }

  fileRemove() {
    this.enableSubmit(false)
    this.setState(state => {
      let alertProps = state.alertProps
      if (alertProps && 
          (alertProps.uniqueId === FILE_UPLOAD_ID || alertProps.uniqueId === VALIDATE_ID)) {
          return {
            alertProps: false
          }
        }
    })
  }

  enableSubmit(bool) {
    ////console.log('enableSubmit ' + bool)
    setStateValue(this,'submitEnabled',bool)
    // this.setState({
    //   submitEnabled: bool
    // })
  }

  setUploadingButtonState() {
    setStateValue(this,'submit',this.SUBMIT_LOADING())
    // this.setState({
    //   submit: this.SUBMIT_LOADING()
    // })
  }

  resetUploadingButtonState() {
    setStateValue(this,'submit',this.SUBMIT_REGULAR())
    // this.setState({
    //   submit: this.SUBMIT_REGULAR()
    // })
  }

  uploadLink(title, link) {
    this.enableSubmit(false)
    this.uploadStarted()
    let formBody = `title=${encodeURIComponent(title)}&link=${encodeURIComponent(link)}` 
    fetchRequest('POST', this.props.parms.url.replace('?', '/link?'), {
      credentials: 'include',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: formBody
    }).then(() => {
      this.uploadFinished();
      refreshSupportingMaterials()
    }).catch((err) => {
      this.uploadFailed(err, getMessage('uploadLinkFailed'))
    })
  }

  uploadFile(form, file) {
    var formData = new FormData(form)
    formData.set('file', file)
    //console.log('upload formData file property', formData.get('file'))
    this.uploadStarted()
    fetchRequest('POST', this.props.parms.url, {
      credentials: 'include',
      body: formData
    }).then(() => {
      this.uploadFinished()
      refreshSupportingMaterials();
    }).catch((err) => {
      this.uploadFailed(err)
    })
  }

  uploadStarted() {
    this.setUploadingButtonState()
  }

  uploadFinished() {
    this.resetUploadingButtonState()
    this.enableSubmit(false)
     this.close(false)
  }

  uploadFailed(response, defaultMessage) {
    if (response) {
      let message = response.responseText ? response.responseText : (response.message ? response.message : (defaultMessage ? defaultMessage : getMessage('uploadFailed')))
      this.addErrorMessage(message, FILE_UPLOAD_ID)  
    }

    this.resetUploadingButtonState()
    this.enableSubmit(false)
    //this.ui.btnSubmit().prop('disabled', true);
  }

  // cant seem to declare class static constants
  SUBMIT_REGULAR() { return 'submit' }
  SUBMIT_LOADING() { return 'loading' }

}
