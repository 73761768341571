import React       from 'react'
import {Checkbox} from '@cb/apricot-react'

export function StepOneContent(scoreeName, courseTitle, csp, art, rationales, formGood) {
  let rationalesList = rationales => _.map(rationales, rational => {
    return RationalFunc(rational)
  })

  let [text1, text2, text3] = getText(csp)
  return art ? (
    <div id='stepOnePlag' tabIndex='-1'>
      <p>
                You are about to report {scoreeName} as not adhering to the Artistic Integrity Agreement for AP Art and Design portfolios. Please ensure you have reviewed the agreement carefully prior to reporting a violation as this may differ from your own institution's policy.
      </p>
      <p>
                To assist with our investigation of this case, please select one or more of the reasons for reporting this work.
      </p>
      <p></p>
      <div className="row">
        <div className='col-sm-1'></div>
        <div id='reasonHeading' role='region' className="col-sm-6 col-sm-offset-1"><strong>Reason for Reporting (select any that apply)</strong></div>
        <div id='evidenceHeading' role='region' className="col-sm-5"><strong>Evidence Required (attach in Step 2)</strong></div>
      </div>
      {rationalesList(rationales)}
    </div>
  ) : (
    <div id='stepOnePlag' tabIndex='-1'>
      <p>
                You are about to report {scoreeName} as not adhering to the {courseTitle} policy on plagiarism{text1}. Please ensure you have reviewed the plagiarism policy{text2}.
      </p>
      <p>
                To assist with our investigation of this case, please select one or more of the reasons for reporting this work as plagiarized{text3}.
      </p>
      <p></p>
      <div className="row">
        <div className='col-sm-1'></div>
        <div id='reasonHeading' role='region' className="col-sm-6 col-sm-offset-1"><strong>Reason for Reporting (select any that apply)</strong></div>
        <div id='evidenceHeading' role='region' className="col-sm-5"><strong>Evidence Required (attach in Step 2)</strong></div>
      </div>
      {rationalesList(rationales)}
    </div>
  )

  function getText(csp) {
    let text1 = ''
    let text2 = ''
    let text3 = ''
    if (csp) {
      text1 = ' or the guidelines for completing the performance tasks'
      text2 = ' or guidelines for each performance task carefully prior to reporting a violation. The plagiarism policy may differ from your own institution\'s policy'
      text3 = ' or in violation of the performance task guidelines'
    } else {
      text1 = ' and falsification or fabrication of information'
    }
    return [text1, text2, text3]
  }

  function RationalFunc(rational) {
    //console.log('rational ' + JSON.stringify(rational, null, 3))
    // can't use the Checkbox widget because the apricot 4.3 version does not support the aria tags we want
    // Mitra has a workaround, but only for 4.4
    return (
      <div className='row add-top-margin-1x' key={'rational_' + rational.id}>
        <div className="col-sm-1">
          <div style={{ maxHeight: '54px' }} className='display-flex align-items-center cb-height-100'>
            <Checkbox ariaDescribedby={`describe_${rational.id}`} onChange={selectionChanged} id={rational.id}/>
          </div>
        </div>
        <div id={'reason' + rational.id} className="col-sm-6 cb-margin-top-4"
          dangerouslySetInnerHTML={{ __html: rational.reason }}>
        </div>
        <div id={'describe' + rational.id} className="col-sm-5"
          dangerouslySetInnerHTML={{ __html: rational.evidenceDescr }} >
        </div>
      </div>
    )
  }

  function selectedRationaleIds() {
    return $('#stepOnePlag input:checked').toArray().map(cb => cb.id)
  }

  function selectionChanged() {
    let ids = selectedRationaleIds()
    //console.log('selected ids',ids)
    formGood(ids.length > 0, ids)
  }
}
