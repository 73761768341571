import { Link } from 'react-router-dom'
import { MyClasses }  from './myClasses'
import { AppContext } from "../../../context"
import { isArt, isCSP } from "../../entities/courseinstance"
import { Icon } from "@cb/apricot-react"

export function RightMenuComp({ helpers }) {

    useEffect(() => {
        const links = document.getElementsByClassName('right-menu-link'), filteredLinks = []
        // Remove links not in RightMenu component
        for (const link of links) {
            if (!link.id || !link.id.includes('apricot')) filteredLinks.push(link)
        }
        const currentLocation = location.pathname
        filteredLinks.forEach(link => {
            if (link.classList.contains('cb-selected') && link.pathname !== currentLocation) link.classList.remove('cb-selected')
            else if (link.id && !currentLocation.includes('dashboard') && !currentLocation.includes('help')) link.classList.add('cb-selected')
            else if (!link.id && link.pathname === currentLocation) link.classList.add('cb-selected')
        })
    }, [location.pathname])

    const checkOnClick = (contextValue, event) => {
        let cspSave, artSave
        if (isArt()) {
            artSave = document.getElementById("save")
            if (!contextValue.clickEvent && !artSave.hasAttribute('disabled')) {
                contextValue.updateHasUnsavedWork(true)
                contextValue.updateClickEvent(true)
                contextValue.setDestinationLink(event.currentTarget.href)
                event.preventDefault()
            }
        }
        else if (isCSP()) {
            cspSave = document.getElementById("CSP_WR_1_1")
            if (!contextValue.clickEvent && !cspSave.hasAttribute('disabled')) {
                contextValue.updateHasUnsavedWork(true)
                contextValue.updateClickEvent(true)
                contextValue.setDestinationLink(event.currentTarget.href)
                event.preventDefault()
            }
        }
    }

    const ContextChild = (value) => {
        return (
            <div className="cb-nav-items col-xs-12 hidden-xs-only">
                <nav className="cb-menu-list cb-menu-list-horizontal" aria-label="menu containing home, classes and help">
                    <ul>
                        <li className="cb-nav-link cb-procedural">
                            <Link to='dashboard' className='cb-menu-link right-menu-link' role='button' onClick={(e) => { checkOnClick(value, e) }}>
                                <Icon name='home' />
                                <span className='cb-margin-left-8'>Home</span>
                            </Link>
                        </li>
                        {helpers.enabled &&
                            <li className='cb-nav-link'>
                                <div className='cb-dropdown'>
                                    <MyClasses helpers={helpers} context={value} />
                                </div>
                            </li>
                        }
                        {helpers.helpURL &&
                            <li className="cb-nav-link cb-procedural">
                                <Link to={helpers.helpURL} title="Help" data='help' className='help cb-menu-link right-menu-link' role='button' onClick={(e) => { checkOnClick(value, e) }}>
                                    <span className="cb-glyph cb-question left-glyph cb-margin-right-8" aria-hidden="true" />
                                    <span>Help</span>
                                </Link>
                            </li>
                        }
                    </ul>
                </nav>
            </div>
        )
    }

    return (
        <AppContext.Consumer>
            {ContextChild}
        </AppContext.Consumer>
    )
}
