import React from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { bsliGet } from "../../_core/util/request"
import { Select, Spinner, YellowButton } from "@cb/apricot-react";
import { savedGetRequests } from "./admin-layout";
import { useParams } from "react-router-dom";

const Reports = () => {
  const [state, setState] = useState({})
  const [props, setProps] = useState(null)
  const [otherProps, setOtherProps] = useState({
    lengthOfYears: '',
    year: '',
    courseInstancesNotWe: '',
    weCourse: '',
    notWeReportTypes: ["2", "4", "5"],
    isWeIncluded: true
  })
  const [loading, setLoading] = useState(true)
  const { id } = useParams()

  const changeHandler = (event) => {
    let nam = event.target.name
    let val = event.target.value
    setState({ [nam]: val })
    setState({ ["url"]: null })
    if (nam === "report") {
      setState({ ["course"]: "-1" })
      // isWeIncluded = !notWeReportTypes.includes(val);
    }
  }

  const getData = async () => {
    try {
      let asyncFuncs = [
        savedGetRequests("reports"),
        savedGetRequests("courseInstancesByYear")
      ]
      let [reports, courses] = await Promise.all(asyncFuncs)
      //sort by year, desc:
      courses.sort((a, b) => {
        return a.year > b.year ? -1 : 1
      })
      //create an object that matches the structure of the old response
      let reportTypes = {}
      _.each(reports, function(obj) {
        reportTypes[String(obj.id)] = obj.name
      })

      let parms = {
        reports,
        reportTypes,
        courseInstancesByYear: courses,
        selectedReport: id || '-1'
      }
      setProps({ parms })
    } catch (e) {
      console.error("showViewReports had error: ", e)
    }
  }

  const DatePickerInput = React.forwardRef(({ onChange, placeholder, value, id, onClick }, ref) => (
    <span className="row">
       <span>
        <input
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          id={id}
          onClick={onClick}
        />
      </span>
      <span>
        <span className="cb-glyph-content cb-glyph-12 cb-calendar-full cb-blue2-color cb-margin-top-8 cb-margin-left-8" onClick={onClick}>
          <span className='sr-only'>Show Calendar</span>
        </span>
      </span>
     </span>
  ))

  const handleReportChange = (val) => {
    setState(state => ({ ...state, ["report"]: val, ["url"]: null, ["course"]: "-1" }))
    setOtherProps(state => ({ ...state, isWeIncluded: !state.notWeReportTypes.includes(val) }))
  }

  const handleCourseChange = (val) => {
    setState(state => ({ ...state, ["course"]: val, ["url"]: null }))
  }

  const isValidDate = date => {
    let today = new Date()
    let day = date.getDate()
    let month = date.getMonth()
    let todayMoment = moment([today.getFullYear(), today.getMonth(), today.getDate()])
    let dateMoment = moment([date.getFullYear(), date.getMonth(), date.getDate()])
    let monthlyDateDifference = Math.abs(todayMoment.diff(dateMoment, "days"))
    //reports before april first were not generated so we do not have monthly or daily reports.
    //Should only enable December 1 before April 1 2019
    //After April 1 2019, we should enable only the first of each month if the date is more than a year back of current date
    //We should also be blocking future dates from availability
    if (dateMoment.isAfter(todayMoment, "days")) { //future dates should be disabled
      return false
    } else if (dateMoment.isBefore(moment("03/19/2019", "MM/DD/YYYY"), "days") && day === 1 && month === 11) {
      return true
    } else if (dateMoment.isAfter(moment("03/19/2019", "MM/DD/YYYY"), "days") && day === 1 && monthlyDateDifference >= 365) {
      return true
    } else if (dateMoment.isAfter(moment("03/19/2019", "MM/DD/YYYY"), "days") && monthlyDateDifference < 365) {
      return true
    } else {
      return false
    }
  }

  const reports = () => {
    return [<option value="-1">Select Report</option>].concat(
      _.map(props.parms.reportTypes, (report, index) => {
        return <option value={index} key={index}>{report}</option>
      })
    )
  }

  const reportLabelValues = () => {
    return [{ value: "-1", label: "Select Report" }].concat(
      _.map(props?.parms.reportTypes, (report, index) => {
        return { value: index, label: report }
      })
    )
  }

  const courses = () => {
    let res = []
    res = _.map(courseInstancesNotWe, (course) => {
      return <option value={course.courseId} key={course.courseId}>{course.title}</option>
    })
    if (isWeIncluded && weCourse) {
      res.push(<option value={weCourse.courseId} key={weCourse.courseId}>{weCourse.title}</option>)
    }
    return res
  }

  const courseLabelValues = () => {
    const { courseInstancesNotWe, isWeIncluded, weCourse } = otherProps
    let res = []
    res = [{ value: "-1", label: "Select Course" }].concat(_.map(courseInstancesNotWe, (course) => {
      return { value: course.courseId, label: course.title }
    }))
    if (isWeIncluded && weCourse) {
      res.push({ value: weCourse.courseId, label: weCourse.title })
    }
    return res
  }

  const selectionMade = () => !!(state?.report !== "-1" && state?.course !== "-1" && state.date)

  const getDateFormat = (date) => {
    return moment(date, "MM-DD-YYYY").format("YYYY-MM-DD")
  }

  const formatDate = (date) => {
    var d = new Date(date),
      month = (d.getMonth() + 1),
      day = d.getDate(),
      year = d.getFullYear()

    if (month < 10)
      month = "0" + month
    if (day < 10)
      day = "0" + day
    return [year, month, day].join("-")
  }

  const getReportData = async (reportId, courseId, date) => {
    try {
      let formatedDate = formatDate(date)
      return await bsliGet("reports/" + reportId + "/" + courseId + "/" + formatedDate)
    } catch (e) {
      console.error("getReportData had error")
      return null
    }
  }

  const generate = async () => {
    let reportData = await getReportData(state.report, state.course, state.date)
    if (reportData) {
      setState(state => ({ ...state, ["url"]: reportData.url }))
    }
  }

  const resetCourseSelect = () => {
    //  reset the text in course select control after the report selection change:
    if (state && state.course && state.course === "-1" && document.querySelector("#courseContainer")) {
      let elem = document.querySelector("#courseContainer").querySelector("div").querySelector("span").querySelector("span").querySelector("span")
      if (elem && elem.innerText !== "Select Course") {
        let arrow = elem.children[0]
        elem.innerText = "Select Course"
        elem.appendChild(arrow)
      }
    }
  }

  useEffect(() => {
    document.querySelectorAll(".form-group").forEach(function(elem) {
      window.cb.apricot.CBForm.customSelectElement({
        elem: elem.querySelector("select")
      })
    })
    getData()
  }, [])

  useEffect(() => {
    getData()
  }, [id])

  useEffect(() => {
    if (props) {
      setOtherProps(state => ({
        ...state,
        lengthOfYears: props.parms.courseInstancesByYear.length,
        year: props.parms.courseInstancesByYear[0].year,
        courseInstancesNotWe: props.parms.courseInstancesByYear[0].courseInstances.filter((course) => course.courseId !== "12"),
        weCourse: props.parms.courseInstancesByYear[0].courseInstances.find((course) => course.courseId === "12"),
        isWeIncluded: !state.notWeReportTypes.includes(props.parms.selectedReport)
      }))
      setState({
        report: props.parms.selectedReport ? props.parms.selectedReport : "-1",
        course: "-1",
        date: "",
        url: ""
      })
      resetCourseSelect()
      setLoading(false)
    }
  }, [props])

  if (loading) return <Spinner />

  return (
    <div>
      <div id="admin-search-form" className="row ">
        <div className="col-lg-8 col-md-9 col-xs-12 padding-0">
          <h4 className="h1">View Reports </h4>
          <p>Select Report and Course</p>
          <div className="form-group">
            <Select
              id="report"
              name="report"
              ariaLabel="Select Report"
              values={reportLabelValues()}
              onChange={handleReportChange}
              value={state.report}
            />
          </div>

          <div className="form-group">
            <Select
              id="course"
              name="course"
              ariaLabel="Select Course"
              values={courseLabelValues()}
              onChange={handleCourseChange}
              value={state.course}
            />
          </div>

          <div className="cb-input form-group">
            <DatePicker
              placeholderText="mm-dd-yyyy"
              filterDate={isValidDate}
              maxDate={new Date()}
              minDate={new Date("2015/01/01")}
              dateFormat="MM-dd-yyyy"
              selected={state.date}
              onChange={date => setState(state => ({ ...state, ["date"]: date, ["url"]: null }))}
              customInput={<DatePickerInput />}
            />
          </div>
          <div className="cb-input form-group">
            <YellowButton data-cb-title="Submit Report" disabled={!selectionMade()} hidden={!!state.url} onClick={generate}>
              Submit
            </YellowButton>
            <a href={state.url} target="_blank" hidden={!state.url} data-cb-title="Download Report">
              <YellowButton>Download</YellowButton>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reports
