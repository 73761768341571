import React from 'react'
import { reactRemove, reactRender } from '../../utility/util'
import { Spinner } from '@cb/apricot-react'

export function showLoadingView(region, big) {
  //console.log('showLoadingView region=' + region)
  showLoadingViewIcon(region, big)
}

export function closeLoadingView(region) {
  console.log('closeLoadingView region=' + region)
  closeLoadingViewIcon(region)
}

function getIconContainer(region) {
  let container = region ? region : 'region-main-content'
  if (!document.getElementById(container)) {
    container = 'app-region'
  }
  return container
}

function showLoadingViewIcon(region, big) {
  let spinner
  if (big) {
    spinner = (
      <div className="loading_view" style={{ marginTop: '30vh' }}>
        <Spinner background="gray4" show label="Loading Content" />
      </div>
    )
  } else {
    spinner = (
      <div>
        <Spinner background="gray4" show label="Loading Content" />
      </div>
    )
  }
  reactRender(spinner, getIconContainer(region))
}

function closeLoadingViewIcon(region) {
  reactRemove(getIconContainer(region))
}
