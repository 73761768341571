import { getCurrentPerson } from "../entities/person";
import { getAssignmentHolder, getAssignmentTeamStatus } from "../entities/browserHelper";
import { getModuleHolder } from "../entities/modules";
import { getAssignmentStatuses } from "../entities/teacherHelper";
import { useParams } from "react-router-dom";
import { Spinner } from "@cb/apricot-react";
import CourseLayout from "../main/views/courseLayout";
import StudentLayoutComp from "./views/studentLayout";
import { cmsMaterials, supportingData } from "../supportingmaterial/supportingmaterial_controller";
import { subtask } from "../content/static_content";
import TeacherLayoutComp from "./views/teacherLayout";

const getSupportingMaterialAndCMS = async (lessonId, moduleHolder) => {
  try {
    let asyncFuncs = [
      supportingData(lessonId, moduleHolder),
      cmsMaterials(lessonId)
    ]
    const awaitedData = await Promise.all(asyncFuncs)
    return {
      supportingMaterialData: awaitedData[0],
      cmsData: awaitedData[1]
    }
  } catch (e) {
    console.error('getSupportingMaterialAndCMS awaitedData failed: ', e)
    return {
      supportingMaterialData: false,
      cmsData: false
    }
  }
}
const getLessonInfo = async (lessonId) => {
  let task = await subtask(lessonId)
  //console.log('getLessonInfo returned ', task)
  if (!!task) {
    return task.Body ? task.Body : task.body
  } else {
    return null
  }
}

const Browser = () => {
  const role = getCurrentPerson()
  const { lessonId, sectionId } = useParams()
  const [loading, setLoading] = useState(true)
  const [props, setProps] = useState({})

  const getCourseInfo = async (role, sectionId, lessonId) => {
    const missingTeamMessage = "Error: You are not assigned to a team for this assignment. Please contact your instructor."
    try {
      const asyncFuncs = [
        getAssignmentHolder(lessonId),
        getModuleHolder(),
        getLessonInfo(lessonId)
      ]
      const [assignmentHolder, moduleHolder, lessonInfo] = await Promise.all(asyncFuncs)
      const SMAndCMS = await getSupportingMaterialAndCMS(lessonId, moduleHolder)
      if (role.isStudent()) {
        const lesson = moduleHolder.lessonById(lessonId)
        if (lesson) {
          let teamNames = []
          let inTeamWithoutMembers
          if (lesson.isTeamLesson) {
            try {
              let team = await getAssignmentTeamStatus(sectionId, assignmentHolder.assignments[0].id)
              if (team) {
                teamNames = team.teamNames()
                inTeamWithoutMembers = false
              } else {
                inTeamWithoutMembers = true
              }
            } catch (e) {
              inTeamWithoutMembers = true
            }
          } else {
            // if it's not a team lesson, the person is the team
            inTeamWithoutMembers = false
          }

          return setProps({
            moduleHolder,
            assignments: assignmentHolder.assignments,
            lesson,
            inTeamWithoutMembers,
            teamNames,
            message: missingTeamMessage,
            cmsData: SMAndCMS.cmsData,
            supportingMaterialData: SMAndCMS.supportingMaterialData,
            lessonInfo
          })
        }
      } else {
        const lesson = !!moduleHolder ? moduleHolder.lessonById(lessonId) : {}
        const students = await getAssignmentStatuses(sectionId, assignmentHolder.assignments[0].id, lesson.isTeamLesson ? 'teams' : 'individuals');

        return setProps({
          moduleHolder,
          lesson,
          students, // has property 'statuses' which is an array of AssignmentStatus
          assignments: !!assignmentHolder ? assignmentHolder.assignments : [],
          cmsData: !!SMAndCMS ? SMAndCMS.cmsData : false,
          supportingMaterialData: !!SMAndCMS ? SMAndCMS.supportingMaterialData : false,
          lessonInfo: !!lessonInfo ? lessonInfo : false
        })
      }
    } catch (err) {
      console.error('getCourseInfo has failed: ', err)
      return {
        error: err
      }
    }
  }

  useEffect(() => {
    setLoading(true)
    getCourseInfo(role, sectionId, lessonId)
    if (props.moduleHolder) {
      const moduleAbbreviation = props.moduleHolder.getModuleByLessonId(lessonId).abbreviation
      document.dispatchEvent(new CustomEvent('pagevisit', { detail: moduleAbbreviation + '/' + props.lesson.abbreviation }))
    }
  }, [lessonId])

  useEffect(() => {
    if (props.lesson || props.moduleHolder) setLoading(false)
  }, [props])

  if (loading) return <Spinner />

  if (role.isStudent() && props.moduleHolder) return (
    <>
      <CourseLayout props={{ moduleHolder: props.moduleHolder }}>
        <StudentLayoutComp parms={props} />
      </CourseLayout>
    </>
  )

  if (role.isTeacher() && props.moduleHolder && props.lesson) return (
    <>
      <CourseLayout props={{ moduleHolder: props.moduleHolder }}>
        <TeacherLayoutComp parms={props} />
      </CourseLayout>
    </>
  )

  return null
}

export default Browser