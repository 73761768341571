import {
  CircularButton,
  NakedButton, PrimaryButton,
  RadioButton,
  RadioButtonGroup,
  ToggleButton,
  ToggleButtonGroup
} from "@cb/apricot-react"
import { isResearch, isSeminar } from "../../entities/courseinstance"

export function RubricDimension(props) {
  // props.props has rubric of type Rubric, scoreHolder, readOnly, scoreHolderChanged
  // Rubric has id, assignmentId, title, defaultDescription, descriptions
  // descriptions is an array of [score, description, abbrev]

  let fieldSetId = `rubricDimension_${props.rubric.id}`
  let currentScore = null
  let needMouseMove = false
  let nextEvent = null

  const [btnDesc, setBtnDesc] = useState(getBtnDesc())
  const [showTextArea, setShowTextArea] = useState(false)

  useEffect(() => {
    document.querySelectorAll("#" + fieldSetId + " .cb-toggles-btn label").forEach(elem => {
      elem.addEventListener("mouseenter", mouseEnter)
      elem.addEventListener("mouseleave", mouseLeave)
      elem.addEventListener("keydown", keydown)
      if (elem.children[0].value === currentScore) {
        elem.children[0].checked = true
        if (props.readOnly) {
          elem.classList.add("cb-opacity-5")
        }
      }
    })
    document.querySelectorAll("#" + fieldSetId + " .cb-radio label").forEach(elem => {
      if (elem.children[0].value === currentScore) {
        elem.children[0].checked = true
        if (props.readOnly) {
          elem.classList.add("cb-opacity-5")
        }
      }
    })
    let elem = document.querySelector("#" + fieldSetId)
    elem.addEventListener("mousemove", mouseMove)
    elem.addEventListener("mouseleave", mouseLeaveAll)
  }, [])


  // original code made reference to highestScoreValue which was not defined anywhere
  let rubric = props.rubric
  // highestScoreValue was in a sr-only
  //let highestScoreValue = _.max(rubric.descriptions, description => { return description.score }).score

  let id = rubric.id
  let name = "rubric" + id
  let descriptionId = "description" + id

  let savedScore = props.scoreHolder.scoreForDimension(id)
  currentScore = (savedScore && savedScore.score) ? savedScore.score : ""

  let useRadio = rubric.descriptions.length > 0 && !!rubric.descriptions[0].abbrev

  let notes = savedScore && savedScore.comments ? savedScore.comments : ""
  let hasNotes = notes && notes.length > 0

  let showNotes = showTextArea || hasNotes
  let showNotesBtn = !showNotes && !props.readOnly

  let notesBtnCls = showNotesBtn ? "" : " hidden"
  let notesTextAreaCls = showNotes ? "" : " hidden"

  let notesBtnDisabled = props.readOnly
  let notesTextAreaDisabled = props.readOnly

  const isReplaceCheckAssignmentDisplay = () => {
    let isPrpAssignment = isResearch() && props.rubric.assignmentId % 10 === 6
    let isIrrCpAssignment = isSeminar() && props.rubric.assignmentId % 10 === 0
    let isIwaCpAssignment = isSeminar() && props.rubric.assignmentId % 10 === 9
    return isPrpAssignment || isIrrCpAssignment || isIwaCpAssignment
  }
  const replacementText = (value, descriptionsLength) => {
    if (descriptionsLength === 2) {
      return value === 0 ? "No" : "Yes"
    } else if (descriptionsLength === 3) {
      if (value === 1) {
        return "Consistent and Authentic"
      } else if (value === 2) {
        return "Inconsistencies Resolved"
      } else if (value === 3) {
        return "Inconsistencies Unresolved"
      }
    }
  }
  let buttonList = rubric.descriptions?.map((description, index) => {
    let { score, abbrev } = description
    let buttonScore = score

    let label = ""
    if (abbrev) {
      label = abbrev.toString()
    } else {
      label = "The score for " + rubric.title + " is " + buttonScore + ", use the left and right arrow keys to change"
    }

    return (
      <div key={`rubric-button-${index}`} className={`${index === 0 ? '' : 'cb-padding-left-8'} cb-padding-top-8`}>
        {useRadio &&
          <RadioButton
            value={buttonScore}
            label={label} ariaLabel={label} key={`rubricButton_${index}`}
            disabled={props.readOnly}>
          </RadioButton>
        }
        {!useRadio && isReplaceCheckAssignmentDisplay() && rubric.descriptions.length === 2 &&
          <ToggleButton key={`toggleKey_${props.rubric.id}_${index}`}
                        value={buttonScore} tabIndex="0"
                        ariaLabel={label}
                        disabled={props.readOnly}>
            {replacementText(buttonScore, rubric.descriptions.length)}
          </ToggleButton>
        }

        {!useRadio && isReplaceCheckAssignmentDisplay() && rubric.descriptions.length === 3 &&
          <span className="cb-toggles-btn-wide">
            <ToggleButton key={`toggleKey_${props.rubric.id}_${index}`}
                          value={buttonScore} tabIndex="0"
                          ariaLabel={label}
                          disabled={props.readOnly}>
                {replacementText(buttonScore, rubric.descriptions.length)}
            </ToggleButton>
          </span>
        }
        {!useRadio && !isReplaceCheckAssignmentDisplay() &&
          <ToggleButton key={`toggleKey_${props.rubric.id}_${index}`}
                        value={buttonScore} tabIndex="0"
                        ariaLabel={label}
                        disabled={props.readOnly}>
            {buttonScore}
          </ToggleButton>
        }
      </div>
    )
  })


  return (
    <fieldset id={fieldSetId} className="add-bottom-margin-2x">
      {useRadio &&
        <RadioButtonGroup legend={rubric.title} name={name} defaultValue={currentScore} onChange={scoreChanged}>
          {buttonList}
        </RadioButtonGroup>
      }
      {!useRadio &&
        <>
          <legend key="title">{rubric.title}</legend>
          <ToggleButtonGroup name={name} defaultValue={currentScore} key="toggleList" onChange={scoreChanged}>
            {buttonList}
          </ToggleButtonGroup>
          <p id={descriptionId} className="cb-margin-top-8 cb-margin-bottom-8" aria-live="polite" key="toggleP"
             dangerouslySetInnerHTML={{ __html: btnDesc }} />
        </>
      }
      <NakedButton
        className={`cb-no-padding cb-blue5-color ${notesBtnCls}`}
        disabled={notesBtnDisabled}
        icon="plus"
        iconLeft
        iconDecorative
        light
        onClick={() => updateTextState(true)}>
        Add Notes (Optional)
      </NakedButton>
      <div className={"cb-input" + notesTextAreaCls}>
        <label htmlFor={"notes_" + id}>Notes (Optional)</label>
        <textarea
          className="form-control js-notes" rows="3"
          disabled={notesTextAreaDisabled}
          title={"Add notes for " + rubric.title} maxLength="1200" id={"notes" + rubric.id}
          onChange={notesChanged} defaultValue={notes} />
      </div>
    </fieldset>
  )

  function getRubricDescription(hasScore, currentScore, rubric) {
    if (hasScore) {
      let int = parseInt(currentScore)
      let obj = _.findWhere(rubric.descriptions, { "score": int })
      if (obj && obj.description) {
        return obj.description
      }
    }
    return rubric.defaultDescription || ""
  }

  function updateTextState(bool) {
    setShowTextArea(bool)
  }

  function scoreChanged(newScore) {
    props.scoreHolder.setScore(newScore, props.rubric.id)
    props.scoreHolderChanged()
  }

  function notesChanged(e) {
    let notes = e.target.value
    let hasNotes = notes.length > 0
    props.scoreHolder.setComments(notes, props.rubric.id)
    if (!hasNotes) { // the user must have removed the notes, go back to the buttom displayed
      updateTextState(false)
    }
  }

  function mouseLeaveAll(e) {
    setBtnDesc(getBtnDesc())
  }

  function arrowDirection(keyCode) {
    switch (keyCode) {
    case 9:
      return "tab"
    case 37:
      return "left"
    case 38:
      return "up"
    case 39:
      return "right"
    case 40:
      return "down"
    default:
      return null
    }
  }

  // needMouseMove prevents the flickering problem, which was
  // swapping the text caused the mouse to be out of the button box
  // which triggered another text change which triggered another text change...
  function mouseMove(event) {
    needMouseMove = false
    if (nextEvent) {
      if (nextEvent.name === "enter") {
        handleMouseEnter(nextEvent.score, nextEvent.rubric)
      } else {
        handleMouseLeave(nextEvent.rubric)
      }
    }
    nextEvent = null
  }

  function mouseEnter(event) {
    if (needMouseMove) {
      //console.log('need mouse move first')
    } else {
      if (!props.readOnly) {
        //console.log('mouse enter')
        let buttonScore = event.currentTarget.children[0].value
        //handleMouseEnter(buttonScore, props.rubric)
        nextEvent = { name: "enter", score: buttonScore, rubric: props.rubric }
      }
    }
  }

  function mouseLeave() {
    if (needMouseMove) {
      //console.log('need mouse move first')
    } else {
      if (!props.readOnly) {
        //console.log('mouse leave')
        //handleMouseLeave(props.rubric)
        nextEvent = { name: "leave", score: null, rubric: props.rubric }
      }
    }
  }

  function handleMouseEnter(buttonScore, rubric) {
    let btnDesc = getRubricDescription(true, buttonScore, rubric)
    setBtnDesc(btnDesc)
    needMouseMove = true
  }

  function handleMouseLeave(rubric) {
    let btnDesc = getBtnDesc(rubric)
    setBtnDesc(btnDesc)
    needMouseMove = true
  }

  function getBtnDesc() {
    let rubric = props.rubric
    let savedScore = props.scoreHolder.scoreForDimension(rubric.id)
    let currentScore = (savedScore && savedScore.score) ? savedScore.score : ""
    let hasScore = currentScore && currentScore.length > 0
    return getRubricDescription(hasScore, currentScore, rubric)
  }

  function keydown(event) {
    let direction = arrowDirection(event.keyCode)
    if (direction) {
      if (direction === "left" || direction === "right") {
        let $curElem = $(event.currentTarget)
        let currentButtonScore = $curElem.find("input")[0].value
        let currentButtonIndex
        let values = _.map($curElem.parents("fieldset").find("input"), (input, index) => {
          if (input.value === currentButtonScore) {
            currentButtonIndex = index
          }
          return input.value
        })
        let dir = direction === "right" ? 1 : (values.length - 1) // mod does not handle negative values right
        // left and right buttons circle around
        let nextButtonIndex = (currentButtonIndex + dir) % values.length
        let nextButtonScore = values[nextButtonIndex]
        handleMouseEnter(nextButtonScore, props.rubric)
      }
    }
  }
}
