import { bsliGet } from '../_core/util/request'

var nonDPCourse = '12'

// var course = Entities.Model.extend({
//   urlRootPath: 'courses',

//   defaults: { title: '', schema: '2' },
// });

// var courseCollection = Entities.Collection.extend({
//   model: course,
//   urlPath: 'courses',
//   comparator: 'title'
// });

export function getCourseEntities() {
  //app.reqres.setHandler("course:entities", function () {
  return bsliGet('courses');
  //return new Entities.CourseCollection().fetch()
}

// app.reqres.setHandler("course:entity", function (courseId) {
//   return new Entities.Course({id: courseId}).fetch()
// });

// ---- Section-Management ----
// let currentSectionId = null;

// triggers were moved to applicationmode_controller
// $(window).on('hashchange', function () {
//   //console.log('hashchange ' + location.hash)
//   if (location.hash.match(/sections.(\d+)/)) {
//     currentSectionId = RegExp.$1;
//   }
//  });

export function amOnACourseSpecificPage() {
  return (location.pathname.includes('sections') || location.pathname.includes('courses'))
}

export function sectionIdFromUrl() {
  if (location.pathname.match(/sections.(\d+)/)) {
    sessionStorage.setItem('currentSectionId', RegExp.$1)
    return RegExp.$1
  } else {
    return null
  }
}

export function courseInstanceIdFromUrl() {
  if (location.pathname.match(/courses.(\d+)/)) {
    return RegExp.$1
    // Get course instance id from url with lessons if needed
  } else if (location.pathname.match(/lessons.(\d+)/)) {
    return RegExp.$1.substring(0, 4)
  } else {
    return null
  }
}

export function lastSectionId() {
  return sessionStorage.getItem('currentSectionId')
}

export function setSectionId( sectionId) {
   sessionStorage.setItem('currentSectionId', sectionId)
}
// ---- End Section-Management ----
export function isDPCourse(courseId) {
  //app.reqres.setHandler('isDPCourse', function(courseId) {
  return courseId.substr(0, 2) != nonDPCourse
};
