const SubmissionTable = ({ results }) => {
    const [data, setData] = useState(null)

    useEffect(() => {
        setData([
            [
                'Student First Name',
                results?.json?.student?.firstName,
                'Course',
                results?.courseTitle,
                'Teacher First Name',
                results?.json?.teacher?.teacherName.split(' ')[0],
            ],
            [
                'Student Last Name',
                results?.json?.student?.lastName,
                'Class/Section',
                results?.json?.section?.name,
                'Teacher Last Name',
                results?.json?.teacher?.teacherName.split(' ')[1],
            ],
            [
                'AP Number/AP ID',
                results?.apNumber,
                'AI Code',
                results?.json?.teacher?.aiCode,
                'Teacher Email',
                results?.json?.teacher?.email,
            ],
        ])
    }, [results])

    return (
        <table className='cb-table cb-margin-bottom-48'>
            <tbody>
                {data && data.map((row, rowIndex) => (
                    <tr key={`row-${rowIndex}`}>
                        {row.map((item, index) => (
                            <td
                                key={`item-${index}`}
                                className={index % 2 === 0 ? 'cb-gray5-bg' : ''}
                                style={{
                                    width: `${index % 2 === 0 ? '180px' : ''}`,
                                    minWidth: '100px'
                                }}
                            >
                                {item}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default SubmissionTable
