import {isArt_SW_Images, isArtSI_Images} from '../../entities/courseinstance'

const Title = (props) => {
  const serializeData = () => {
    const { scoree } = props
    let data = scoree.team ? {
      teamName: scoree.team.title,
      studentNames: scoree.team.prefFullName,
      fullName: scoree.team.fullName
    } : {
      teamName: scoree.title(),
      studentNames: scoree.prefFullName(),
      fullName: scoree.fullName(),
    }
    if (props.upload)
      _.extend(data, {
        prefFirstName: scoree.enrollment?.prefFirstName ? scoree.enrollment.prefFirstName : '',
        scoredBy: props.scorableHolder.scorable.scoredBy,
        isImages: isArt_SW_Images(props.lesson.id) || isArtSI_Images(props.lesson.id),
        fileName: props.upload.fileName,
        dateSubmitted: moment(new Date(props.scorableHolder.scorable.submittedAt)).format('MM/DD')
      })
    return data
  }

  let helpers = serializeData()

  return (
    <>
      {helpers.teamName &&
        <>
          <h5 className="h5 add-vertical-space-halfx break-word">
            {helpers.teamName}
            {helpers.fileName &&
              <span className="font-normal">| {!helpers.isImages && helpers.fileName} | {helpers.dateSubmitted}</span>
            }
          </h5>
          <h5>
            {helpers.studentNames.map((student, index) => (
              <span key={index}>
                {index !== 0 ? ', ' : ''}
                {student.fullName}
                {student.prefFirstName && student.prefFirstName !== 'null' ?
                  <span
                    className="cb-font-weight-regular"> (Preferred Name: {student.prefFirstName})</span> : null}</span>))
            }
          </h5>
        </>
      }
      {!helpers.teamName &&
        <>
          <h5 className="h5 add-vertical-space-halfx break-word">
            {helpers.fullName}
            {helpers.prefFirstName && helpers.prefFirstName !== 'null' ?
              <span className="cb-font-weight-regular"> (Preferred Name: {helpers.prefFirstName})</span> : null}
            {(helpers.fileName && !helpers.isImages) &&
              <p className="cb-font-weight-regular">{helpers.fileName} | {helpers.dateSubmitted}</p>}
            {helpers.isImages &&
              <span className="font-normal"> | {helpers.dateSubmitted}</span>
            }
          </h5>
        </>
      }
      {helpers.scoredBy &&
        <p>Scored By: {helpers.scoredBy}</p>
      }
    </>
  )
}

export default Title
