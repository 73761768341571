import { getCurrentOrg } from '../../entities/courseinstance'
import { bsliGet } from '../../_core/util/request'
import { ScorableHolder } from '../../assignment/entities/scorable'
import { Assignment } from '../../entities/assignment'
import { getCurrentPerson } from '../../entities/person.jsx'

// returns an array of ScorableHolder, one for each lesson
export function getScorables(lessons, statuses) {
	return _.map(lessons, lesson => {
		let status = _.findWhere(statuses, { assignmentId: parseInt(lesson.assignmentId) }) || {}
		// student progress has submissionStatusId instead of status
		if (!status.status) {
			status.status = status.submissionStatusId
			if (!status.submissionStatusId) {
				//console.log('undefined status')
			}
		}
		return new ScorableHolder(status, lesson.assignmentId)//TODO: make assignmentId consistent
	})
}

class StudentModel {
	constructor(jsonStudent, options) {
		this.jsonStudent = jsonStudent
		this.scorables = getScorables(options.lessons, jsonStudent.statuses) // an array of ScorableHolder
		var personId = jsonStudent.dpPersonId
        this.lessons = options.lessons
		this.completionStatus = _.find(this.scorables, (scorable) => !scorable.isComplete(personId, this.lessons)) ? 0 : 1
		this.firstName = jsonStudent.firstName
		this.lastName = jsonStudent.lastName
		const isTeacher = getCurrentPerson().isTeacher()
		const isCoordinator = getCurrentPerson().isCoordinator()
		this.fullName = (isTeacher || isCoordinator)
			? (
				jsonStudent.prefFirstName !== null && jsonStudent.prefFirstName !== "null"
					? `${jsonStudent.firstName} ${jsonStudent.lastName} (Preferred Name: ${jsonStudent.prefFirstName})`
					: `${jsonStudent.firstName} ${jsonStudent.lastName}`
			)
			: `${jsonStudent.prefFirstName || jsonStudent.firstName} ${jsonStudent.lastName}`
		this.teams = options.teams
		this.team = _.find(options.teams, (team) => _.findWhere(team.enrollment, { personId: personId }))
		this.sortStatus = 0
	}
}

// used by teacher and student, for student an array of size 1 is sent
export class ProgressStudentCollection {
	constructor(jsonStudents, options) {
		this.students = jsonStudents?.map(jsonStudent => {
			return new StudentModel(jsonStudent, options)
		});
		this.forCoordinator = options?.forCoordinator
		this.lessons = options?.lessons
	}
}

class SectionModel {
	constructor(jsonSection, options) {
		this.jsonSection = jsonSection
		this.options = options || {}
		//this.lessons = this.options.lessons
	}
}

class ProgressSectionCollection {
	constructor(jsonSections, options) {
		this.sections = _.map(jsonSections, jsonSection => {
			return new SectionModel(jsonSection, options)
		})
	}
}

class TeamModel {
	// options has lessons: teamLessonsAndAssignmentCombo, students: json.students
	constructor(jsonTeam, options) {
		this.options = options || {}
		this.jsonTeam = jsonTeam
		this.jsonStudents = _.compact(
			_.map(options.students,
				student => {
					let enrollment = _.findWhere(jsonTeam.enrollment, { personId: student.dpPersonId })
					if (enrollment) {
						const isTeacher = getCurrentPerson().isTeacher()
						const fullName = isTeacher
							? student.prefFirstName ? `${student.firstName} ${student.lastName} (Preferred Name: ${student.prefFirstName})` : `${student.firstName} ${student.lastName}`
							: `${student.prefFirstName || student.firstName} ${student.lastName}`
						student.fullName = fullName
						return student
					} else {
						return null
					}
				})
		)
		this.scorables = getScorables(options.lessons, this.jsonStudents.length ? this.jsonStudents[0].statuses : [])
	}
}

class ProgressTeamCollection {
	// options has lessons: teamLessonsAndAssignmentCombo, students: json.students
	constructor(jsonTeams, options) {
		this.teams = _.map(jsonTeams, jsonTeam => {
			return new TeamModel(jsonTeam, options)
		})
		// sometimes a team is sent twice
		this.teams = _.uniq(this.teams, false, teamModel => teamModel.jsonTeam.id)
		this.lessons = options.lessons
	}
}

var getCurrentOrgSections = sections => {
	let filteredSections
	if (sections) {
		let currentOrg = getCurrentOrg()
		filteredSections = _.filter(sections, s => {
			return s.orgId == currentOrg.orgId
		})
	} else {
		filteredSections = []
	}
	return filteredSections
}

var getLessonsFromModules = (moduleHolder, assignments) => _.map(moduleHolder.getLessonsFromModules(), function (lesson) {
	var assignment = new Assignment(_.findWhere(assignments, { lessonId: lesson.id }) || {})
	return _.extend(lesson, { assignmentId: assignment.id, isDocument: assignment.isDocument() })
})

export function handleCourseJson(json, moduleHolder, we) {
	//let handleJson = (json, modules, we) => {
	//console.log('handleCourseJson')
	progressCourseJson = json
	var lessonAndAssignmentCombo = getLessonsFromModules(moduleHolder, json.assignments)
	let allSections = getCurrentOrgSections(json.section)
	let weSections = _.filter(allSections, s => {
		return s.courseType == 2 || s.courseType == 3
	})
	areThereWeSections = weSections.length > 0

	// if we==0 we dont filter out any sections. the regular 'Progress' link
	// if we==1 keep only sections with courseType 2 or 3, WE only and DP with WE
	// we only filter out the non WE sections when displaying the WE progress
	// the regular Progress has all sections in it
	if (we == 0) {
		return new ProgressSectionCollection(allSections, { lessons: lessonAndAssignmentCombo })
	} else {
		return new ProgressSectionCollection(weSections, { lessons: lessonAndAssignmentCombo })
	}
}

// some globals
var progressSectionData = {}
var progressCourseJson = {}
let areThereWeSections = false

export function isSectionExamOnly(id) {
	const foundSection = progressCourseJson.section?.find(item => item.sectionId === id);
	const examOnly = foundSection?.examOnly;
	return examOnly
}

export function getButtonText(assignmentId, type) {
	let assignment = _.findWhere(progressSectionData.assignments, { id: assignmentId })
	if (assignment && assignment.buttonText) {
		var assignmentButton = assignment.buttonText;
		var assignmentButtonSelected = _.findWhere(assignmentButton, { name: type });
		return (typeof assignmentButtonSelected !== "undefined" ? assignmentButtonSelected.value : "");
	} else {
		return ''
	}
}

export function isScoreFromSameSection(sectionId, personId) {
	if (progressCourseJson && progressCourseJson.students) {
		let student = _.findWhere(progressSectionData.students, { dpPersonId: personId })
		return student ? student.scoreFromSameSection : false
	} else {
		return false
	}
}

export function haveWeSections() { // formerly setHandler('have:we:sections' same as setHandler('org:have:we:sections'
	return areThereWeSections
}

export function getTeacherIds(id) { // formerly setHandler('progress:teacherIds'
	if (progressCourseJson && progressCourseJson.section) {
		let section = _.findWhere(progressCourseJson.section, { sectionId: id })
		return section ? section.teacherIds : []
	} else {
		return []
	}
}

// called by teacher directly from progress_module
export function getProgressSection(sectionId, moduleHolder, forCoordinator) {
	return bsliGet(`sections/${sectionId}/progress`)
		.then(function (json) {
			var lessonAndAssignmentCombo = getLessonsFromModules(moduleHolder, json.assignments)
			var teamLessonsAndAssignmentCombo = _.filter(lessonAndAssignmentCombo, (l) => l.isTeamLesson)
			progressSectionData = json
			return [
				new ProgressStudentCollection(
					json.students || {},
					{ lessons: lessonAndAssignmentCombo, teams: json.teams, forCoordinator: forCoordinator }),
				new ProgressTeamCollection(json.teams || {},
					{ lessons: teamLessonsAndAssignmentCombo, students: json.students })
			]
		})
}

// called by student
export async function getStudentProgress(sectionId, moduleHolder, apId, isReviewer) {
	//app.reqres.setHandler('progress:student', function (sectionId, modules/*, apNumber*/) {
	const json = await bsliGet(`sections/${sectionId}/progress-student${isReviewer ? '/id/'+apId : ''}`)
	var allLessons = moduleHolder.getLessonsFromModules()
	return new ProgressStudentCollection(
		[json.student] || [],
		{ lessons: allLessons, teams: json?.team, forCoordinator: undefined },
	)
}

let lastCourseId = 0
// called by coordinator
export function getCourseProgress(courseInstanceId, moduleHolder, we) {
	//app.reqres.setHandler('progress:course', function (courseInstanceId, modules, we) {
	//console.log('getCourseProgress formerly progress:course')
	if (lastCourseId == courseInstanceId) {
		return new Promise((resolve) => {
			resolve(handleCourseJson(progressCourseJson, moduleHolder, we))
		})
	}

	return bsliGet(`courses/${courseInstanceId}/progress`)
		.then(function (json) {
			lastCourseId = courseInstanceId
			progressCourseJson = json
			return handleCourseJson(json, moduleHolder, we)
		})
}
