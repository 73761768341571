export const OrgSingleComp = ({ currentOrg }) => {
    let orgName = currentOrg ? currentOrg.orgName : ''

    return (
        <div>
            <nav className="col-xs cb-margin-top-16 cb-margin-bottom-4" style={{ paddingRight: 0, paddingLeft: 0 }}
                 aria-label='single organization'>
                <div className='no-pointer'>
                    <span>{orgName}</span>
                </div>
            </nav>
        </div>
    )
}
