import React                            from 'react';
import * as drag                        from './dragging'

export function NewTeam({createTeam, dragName}) {
    //console.log('newTeam')
    let cls = 'cb-card cb-no-box-shadow cb-no-border-radius cb-border-top cb-card-content cb-text-disabled cb-no-padding-left cb-no-padding-right cb-spacerv-bottom-4'
    let dropInstruction = ''
    if (dragName) {
        dropInstruction = 'drop ' + dragName + ' to create a new team'
    }
    return (
        <div className='row'>
            <div className="col-mg-12 col-sm-12 cb-text-disabled cb-no-padding">
                <div id="createTeam" className={cls}
                    onDrop={(e) => { createTeamOnDrop(e) }}
                    onDragOver={drag.onDragOver} onDragLeave={drag.onDragLeave}
                    tabIndex='-1'>
                    <span className='sr-only dropInstruction hidden'>{dropInstruction}</span>
                    Drag and drop students to create a new team.
                </div>
            </div>
        </div>
    )
    
    async function createTeamOnDrop(ev) {
        ev.preventDefault();
        const data = ev.dataTransfer.getData("text");
        let enrollmentId = Number(data);
        await createTeam(enrollmentId)
    }
}
