import * as PDFJS from 'pdfjs-dist'
import PDFJSWorker from 'pdfjs-dist/build/pdf.worker.min'
import * as ReactDOM from 'react-dom/client'
import App from '../assets/app/js/application'

require('../assets/app/js/modules/_core/util/jquery_helpers')
require('../assets/app/less/main.less')

window.moment = require('moment')
window._ = require('underscore')
window.Cookies = require('js-cookie')
window.filesize = require('filesize')
window.logger = console

PDFJS.GlobalWorkerOptions.workerSrc = PDFJSWorker
window.PDFJS = PDFJS

const root = ReactDOM.createRoot(document.getElementById('app-region'))
root.render(<App />)
