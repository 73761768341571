
import React                                from 'react';
import ReactDOM                             from 'react-dom'
import { cleanDeadTooltips }                from './tooltip'
import {isEmpty}                            from "underscore";
import CBModal                              from '@cb/apricot/CBModal';
import { getCurrentPerson }                             from '../entities/person.jsx'

const messages = {
invalidFileType:         "Error: Only the following file types are allowed: {content}.",
invalidFileMinSize:      "Error: Your uploaded file must be greater than {content}. Please try again.",
invalidFileMaxSize:      "Error: The file can't be larger than {content}. Please upload a file that is less than {content}.",
invalidFileLength:       "Error: The file could not be uploaded because the file name is longer than 255 characters. Please rename your file and try again.",
invalidFileCharacters:   "Error: The file could not be uploaded because there are encoded characters in the file name. Please rename your file and try again.",
uploadFailed:            "Error: The file was not uploaded. Please try again.",
uploadLinkFailed:        "Error: The submission did not complete successfully. Please try again.",
invalidBrowserVersion:   "Error: This feature will not work with your current internet browser. Please use Chrome, Firefox, Internet Explorer 10 and above, or Safari.",
invalidFileDurationAndMaxSize:   "Error: The file can't be larger than 30MB and the video uploaded exceeds 60 seconds in length. Please upload a file that is less than 30MB and 60 seconds or less.",
invalidFileDuration:             "Error: The video uploaded exceeds 60 seconds in length. Please upload a file that is 60 seconds or less."
}

export function replaceClass(query, clsLose, clsGain) {
    // this does not work since sometimes these classes appear later and we need to wait for them
    // let $clsLose = $(query).find('.' + clsLose)
    // let $clsGain = $(query).find('.' + clsGain)
    // if ($clsLose.length===0 && $clsGain.length>0) {
    //     console.log('replaceClass ' + clsLose + ' has been replaced')
    //     return // already has been replaced
    // }
    waitFor(
        () => {
            let $elem = $(query).find('.' + clsLose)
            return $elem.length > 0
        },
        () => {
            let $elem = $(query).find('.' + clsLose)
            $elem.removeClass(clsLose).addClass(clsGain)
        },
        'replaceClass ' + clsLose + '=>' + clsGain
    )
}
export function removeFakepath(modalId) {
    //console.log('removeFakepath ' + modalId)
    let $elem = $('#' + modalId + ' .cb-file-element')
    if ($elem.length) {
        let text = $elem.text()
        let groups = /.*fakepath\\(.*)/.exec(text)
        if (groups && groups.length > 1) {
            //console.log('removeFakepath is replacing ' + text + ' with ' + groups[1])
            $elem.text(groups[1])
        }
    }
}

// wait for predicate function to return true and then do work function
// will wait for up to 1 seconds
export function waitFor(predicate, work, label) {
    let cnt = 0;
    let maxTries = 5
    let delay = 200
    let labeled = label ? label : 'unlabeled'
    let recursive = () => {
        setTimeout(() => {
            cnt++
            if (predicate()) {
                work()
            } else if (cnt < maxTries) {
                //console.log('predicate ' + labeled + ' false after ' + delay + 'ms cnt=' + cnt)
                recursive()
            } else {
                //console.log('predicate ' + labeled + ' was never true after ' + cnt*delay + 'ms')
            }
        }, delay)
    }
    recursive()
}

// render React component in the DOM element with given id
// modalId should be present only for modals and should be the id of the modal, modal component reads this parameter
// if modalId is defined, we unmount that id before rendering
export function reactRender(elem, id, modalId, focus) {
    //console.log('reactRender id=' + id + ', modalId=' + modalId + ', focus=' + focus)
    let container = document.getElementById(id)
    if (container) {
        if (modalId) {
            ReactDOM.unmountComponentAtNode(container)
            ReactDOM.render(elem, container, renderCallback)
            CBModal({elem: modalId})?.show()
        } else {
            // renderCallback is called when the elem renders
            ReactDOM.render(elem, container, renderCallback)
        }
    } else {
        console.error('element with id ' + id + ' is not there')
    }
    function renderCallback() {
        if (focus) {
            focusOnFirst(container)
         }
    }
}

// an attempt to set the focus right when returning to or reloading a page
let focusElemId = null
let focusContainer = null

export function recordFocus(id) {
    focusElemId = id
    focusContainer = null
}

// container is either a DOM node or a string of an id, the above focusElemId takes precedence
export function focusOnFirst(container) {
    if (focusContainer || focusElemId) {
        //console.log('already waiting to focus focusElemId=' + focusElemId,focusContainer)
    } else {
        focusContainer = container // could be null or undefined
    }

    setTimeout(() => {
        if (focusElemId) {
            let elem = document.getElementById(focusElemId)
            if (elem) {
                $(elem).focus()
        //        console.log('focus is now on ', document.activeElement)
            } else {
       //         console.log('focusOnFirst could NOT find element with id ' + focusElemId)
            }
        } else {
            if (focusContainer) {
                if (typeof focusContainer === 'string') {
                    focusContainer = document.getElementById(focusContainer)
                }
            } else {
                focusContainer = document.getElementsByClassName('cb-modal-container')[0]
            }
            if (focusContainer) {
                // menuBar sets focus to this id
                if (!!sessionStorage.getItem("selectedButtonId") && focusContainer.id === 'region-course-content') {
                    //return;
                } else {
                    let focusableElems = getKeyboardFocusableElements(focusContainer)
                    if (focusableElems && focusableElems.length > 0) {
                        focusableElems[0].focus()
                        //console.log('focusOnFirst set focus on element', focusableElems[0])
                        cleanDeadTooltips()
                    } else {
                        //console.log('focusOnFirst found no focusable element in ', container)
                    }
                }
            }
        }
        focusElemId = null
        focusContainer = null
    }, 500)
}

export function getKeyboardFocusableElements(container) {
    let focusableElems = container.querySelectorAll(
      'a[href], button, input, textarea, select, details, [tabindex]'
    )
    if (focusableElems && focusableElems.length > 0) {
        focusableElems = [...focusableElems].filter(el => {
            let $el = $(el)
            return !el.hasAttribute('disabled') &&
            !el.getAttribute("aria-hidden") &&
            !$el.hasClass('cb-modal-container')
        })
    }
    return focusableElems
}

export function reactRemove(id) {
    let container = document.getElementById(id)
    if (container) {
        ReactDOM.unmountComponentAtNode(container)
    }
}

export function getMessage(key) {
    let msg = messages[key]
    if (msg) {
        return msg
    } else {
        //console.log('message ' + key + ', was not found')
        return ''
    }
}

export function sortBy(list, properties) {
    try {
        if(list && list.length > 0) {
            return list.sort((firstElement, secondElement) => {
                for (const property of properties) {
                    const firstElementValue = firstElement[property] || ''
                    const secondElementValue = secondElement[property] || ''
                    const comparison = String(firstElementValue).localeCompare(String(secondElementValue))
                    if (comparison !== 0) {
                        return comparison
                    }
                }
                return 0;
            })
        }
    } catch (err) {
        return list
    }
    return list
}


export function sortByName(list) {
    return sortBy(list, ['lastName','firstName'])
}

export function getIconClass(status) {
  let statusIcons_apricot4 = {
    NoDraft:                  'cb-glyph cb-glyph-sm cb-no-draft cb-gray3-color',
    DraftIn:                  'cb-glyph cb-glyph-sm cb-draft-in cb-orange-color',
    AttestationIncomplete:    'ap-icon-triangle-open cb-orange1-color fa-2x',
    ReadyToScore:             'cb-glyph cb-glyph-sm cb-needs-score cb-green1-color',
    FinalSubmitted:           'cb-glyph cb-glyph-sm cb-test-scored cb-black1-color',
    Scored:                   'cb-glyph cb-glyph-sm cb-test-scored cb-black1-color',
    NoResponseWe:             'ap-text-np',
    NoResponse:               'ap-text-nr',
    SubmissionConfirmed:      'ap-icon-triangle-open cb-green1-color fa-2x'
  }

  // DraftIn and AttestationIncomplete are both labeled 'draft in' in studentStatusCell.display
  let statusIcons_apricot3 = {
    NoDraft:                  'ap-icon-square-open cb-gray1-color fa-2x',
    DraftIn:                  'ap-icon-triangle-open cb-orange1-color fa-2x',
    AttestationIncomplete:    'ap-text-attestationincomplete',
    ReadyToScore:             'ap-icon-circle-open cb-green1-color fa-2x',
    FinalSubmitted:           'ap-icon-check cb-black1-color fa-2x',
    Scored:                   'ap-icon-check cb-black1-color fa-2x',
    NoResponseWe:             'ap-text-np',
    NoResponse:               'ap-text-nr',
    SubmissionConfirmed:      'ap-text-submissioncomplete'
  }

  let statusIcon = statusIcons_apricot3[status]
  return statusIcon ? statusIcon : ''
}

export function navigate(url) {
  let history = useHistory()
  history.push(url)
}

export function isNumeric_1_9(id) {
 //   console.log('isNumeric_1_9 ' + id)
    let isValid = id && /^[1-9]+$/.test(id)
    return isValid
}

export function isNumeric_0_9(id) {
 //   console.log('isNumeric_0_9 ' + id)
    let isValid = id && /^[0-9]+$/.test(id)
    return isValid
}

export function isFloat(id) {
 //   console.log('isFloat ' + id)
    let isValid = id && /^[0-9\.]+$/.test(id)
    return isValid
}

export function isDecimal(id) {
    let isValid = id && /^(\d+)?(\.)?(\d+)?$/.test(id)
    return isValid
}

export function isFloatandNA(id) {
 //   console.log('isFloatandNA ' + id)
    let isValid = id && /^[0-9\.NnAa\/]+$/.test(id)
    return isValid
}

export function isNA(id) {
    return id && /([Nn][Aa])|([Nn]\/[Aa])/.test(id);
}

export function isNotCompleteNA(id) {
  //  console.log('isNotCompleteNA ' + id)

    return id && /^N(?!A)$|^N\/(?!A)$/.test(id);
}

export function isValidDimension(id) {
  return id && /^[0-9]+[\.]?[0-9]+$/.test(id);
}

export function isValidSectionId(id) {
    let isValid = id && /^[0-9]+$/.test(id)
    return isValid
}

export function isValidStudentId(apNumber) {
    let isValid = apNumber && /^[0-9a-zA-Z]+$/.test(apNumber) && (apNumber.length===8)
    return isValid
}

export function isValidEnterprisePersonId(enterprisePersonId) {
    let isValid = enterprisePersonId && /^[0-9]+$/.test(enterprisePersonId) && (enterprisePersonId.length < 10)
    return isValid
}

// allow underscore, hyphen, spaces and others
export function isAlphaNumericWords(val) {
    let isValid = _.isString(val) && /[0-9,a-z,A-Z, ,_,,",',\-\?\.\!`~@#\$%^\&*()+=/\\|<>:;\[\]\{\}\|\r]/.test(val)
    // allow underscore, hyphen and spaces and others
    return isValid
}

export function isValidCurriculum(val) {
    let isValid = _.isString(val) && /^[0-9,a-z,A-Z, ,_,\-]*$/.test(val)
    return isValid
}

export function isValidProgLang(val) {
    let isValid = _.isString(val) && /^[0-9,a-z,A-Z, ,_,\-,\+,\!,@,#,\$,%,^,\&,\*,\(,\)]*$/.test(val)
    return isValid
}

export function isValidTheme(val) {
    let isValid = _.isString(val) && /^[0-9,a-z,A-Z, ,_,\-,\+,\!,@,#,\$,%,^,\&,\*,\(,\)]*$/.test(val)
    return isValid
}

// keypress event handler for Art Work #
export function validateWorkIdKey(evt) {
    //console.log('validateKey',evt)
    evt = evt || window.event
    var charCode = evt.which || evt.keyCode
    var charStr = String.fromCharCode(charCode)
    //console.log('validateKey ' + charStr)
    if (!isNumeric_1_9(charStr)) {
        //console.log('returning false')
        evt.preventDefault()
        return false
    }
}
// keypress event handler for Art height and width - Selected Works
export function validateHeightWidthKey(evt) {
    evt = evt || window.event
    var charCode = evt.which || evt.keyCode
    var charStr = String.fromCharCode(charCode)
    if (!isFloat(charStr)) {
        //console.log('returning false')
        evt.preventDefault()
        return false
    }
}

// keypress event handler for Art Dimension - Sustained Investigation
export function validateDimensionsKey(evt) {
    evt = evt || window.event
    var charCode = evt.which || evt.keyCode
    var charStr = String.fromCharCode(charCode)
    if (!isFloatandNA(charStr)) {
        //console.log('returning false')
        evt.preventDefault()
        return false
    }
}

export function validateDimensions(value, type, isInputting = true)  {
    if (value.toString().length > 4) return false
    return  type === 'SI' ? isNA(value) || isInputting && (value === 'n' || value === 'N' || value === 'N/' || value === 'n/')
        || isDecimal(value) || isEmpty(value) : isDecimal(value) || isEmpty(value)
}
// keypress event handler for Art materials, processes and ideas
export function validateArtMetaKey(evt) {
    evt = evt || window.event
    var charCode = evt.which || evt.keyCode
    var charStr = String.fromCharCode(charCode)
    if (!isAlphaNumericWords(charStr)) {
        evt.preventDefault()
        return false
    }
}

// keypress event handler
export function validateKey(evt) {
    //console.log('validateKey',evt)
    evt = evt || window.event
    var charCode = evt.which || evt.keyCode
    var charStr = String.fromCharCode(charCode)
    //console.log('validateKey ' + charStr)
    if (!isAlphaNumericWords(charStr)) {
        //console.log('returning false')
        evt.preventDefault()
        return false // not sure if this is helpful in rejecting the key change
    }
}


// json <==> checkboxes
// 'name1,name2' <==>
// checkboxes={name1: true, name2: true, name3: false}
export function strToCheckboxes(str) {
    let checkboxes = {}
    if (str) {
        _.each(str.split(','), name => {
            checkboxes[name] = true
        })
    }
    return checkboxes
}

export function checkboxesToStr(checkboxes) {
    let arr = []
    _.each(checkboxes, (value,key) => {
        if (value) {
            arr.push(key)
        }
    })
    return arr.join(',')
}

function checkboxesToArr(checkboxes,other) {
    let arr = []
    _.each(checkboxes, (value,key) => {
        if (value) {
            if (key.toLowerCase().indexOf('other') >= 0) {
                if (other) {
                    arr.push('Other (' + other + ')')
                }
            } else {
                arr.push(key)
            }
        }
    })
    return arr
}

// return the checkboxes as a comma separated list
// replacing 'other' with the second argument, if there
export function checkboxesToUI(checkboxes,other) {
    let arr = checkboxesToArr(checkboxes,other)
    return arr.join(', ')
}

// sort alphabetically with 'Other' at the end
export function textToUI(checkboxes,other) {
    let arr = checkboxesToArr(checkboxes,other)
    arr = _.sortBy(arr, text => {
        let txt = text.toLowerCase()
        if (txt.indexOf('none') >= 0) {
            txt = 'zz1'
        } else if (txt.indexOf('other') >= 0) {
            txt = 'zz2'
        }
        return txt
    })
    return arr.join(', ')
}

export function gradesToUI(checkboxes) {
    let arr = checkboxesToArr(checkboxes)
    arr = _.sortBy(arr, grade => {
        let groups = /(\d+).*/.exec(grade)
        return groups && groups.length > 0 ? parseInt(groups[1]) : -1
    })
    return arr.join(', ')
}

function updateObject(state,obj,name,value) {
    if (_.isString(name) && !_.isObject(value)) {
        if (state[name] !== value) {
            obj[name] = value
        }
    } else {
        console.error('updateObject cannot be called on arguments',arguments)
    }
}

// react state update for simple state values, not objects or arrays
// name and value can be string and non-object or arrays of such
export function setStateValue(context,name,value) {
    context.setState(state => {
        let obj = {}
        if (_.isArray(name) && _.isArray(value) && name.length===value.length) {
            _.each(name, (nm,index) => {
                updateObject(state,obj,nm,value[index])
            })
        } else if (_.isString(name) && !_.isObject(value)) {
            updateObject(state,obj,name,value)
        } else {
            console.error('setStateValue cannot be called on arguments',arguments)
        }
        if (!_.isEmpty(obj)) return obj
    })
}

export function clickIt(id) {
    let elem = document.getElementById(id)
    if (elem) {
        elem.dispatchEvent(new MouseEvent('click'))
    } else {
        console.error('clickIt did not find id ' + id)
    }
}

export function selectedLink(e,id) {
    let list = document.getElementsByClassName(id)
    for (let elem of list) {
        elem.classList.remove('cb-selected')
        elem.removeAttribute('aria-current')
    }
    // $('.' + id).each(index => {
    //     console.log('selectedLink',$(this))
    //     $(this).removeClass('cb-selected')
    //     $(this).removeAttr('aria-current')
    // })
    let elem = e.currentTarget
    elem.classList.add('cb-selected')
    elem.setAttribute('aria-current', 'page')
}


// args is an object with first and last name properties
// or (first,last)
export function commaName() {
    if (arguments.length===1) {
        const isTeacher = getCurrentPerson().isTeacher()
        const fullName = isTeacher
          ? arguments[0].prefFirstName && arguments[0].prefFirstName !== 'null' ? `${arguments[0].lastName}, ${arguments[0].firstName} (Preferred Name: ${arguments[0].prefFirstName})` : `${arguments[0].lastName}, ${arguments[0].firstName}`
          : `${arguments[0].lastName}, ${arguments[0].prefFirstName || arguments[0].firstName}`
        return fullName
    } else if (arguments.length===2) {
        return arguments[1] + ', ' + arguments[0]
    }
    return ''
}

// name is in comma name format
export function ariaName(name) {
    let groups = /([a-z,A-Z]*)[\s,\,]*([a-z,A-Z]*)/.exec(name)
    if (groups && groups.length > 2) {
        return groups[2] + ' ' + groups[1]
    } else {
        return name
    }
}

//https://stackoverflow.com/questions/1354064/how-to-convert-characters-to-html-entities-using-plain-javascript
export function entitiesToChr(text) {

    //let altered = false
    // first convert entities like &quot; to &#39;
    let retText = text.replace(/\&(\w+);/g, function (match,word) {
        //altered = true
        //console.log('match=' + match + ', word=' + word)
        return '&#' + (htmlEntitiesTables.strToNum[word] || word) + ';'
    })
    retText = retText.replace(/&#([0-9]{1,3});/gi, function(match, numStr) {
        //altered = true
        //console.log('match=' + match + ', numStr=' + numStr)
        var num = parseInt(numStr, 10); // read num as normal number
        return String.fromCharCode(num);
    })
    // if (altered) {
    //     console.log('altered text ' + text + ', to ' + retText)
    // }
    return retText
}

// all HTML4 entities as defined here: http://www.w3.org/TR/html4/sgml/entities.html
// added: amp, lt, gt, quot and apos

//console.log('definig htmlEntitiesTables')
let htmlEntitiesTables = {}

htmlEntitiesTables.numToStr = {
    34: 'quot',         38: 'amp',          39: 'apos',         60: 'lt',           62: 'gt',
    160: 'nbsp',        161: 'iexcl',       162: 'cent',        163: 'pound',       164: 'curren',
    165: 'yen',         166: 'brvbar',      167: 'sect',        168: 'uml',         169: 'copy',
    170: 'ordf',        171: 'laquo',       172: 'not',         173: 'shy',         174: 'reg',
    175: 'macr',        176: 'deg',         177: 'plusmn',      178: 'sup2',        179: 'sup3',
    180: 'acute',       181: 'micro',       182: 'para',        183: 'middot',      184: 'cedil',
    185: 'sup1',        186: 'ordm',        187: 'raquo',       188: 'frac14',      189: 'frac12',
    190: 'frac34',      191: 'iquest',      192: 'Agrave',      193: 'Aacute',      194: 'Acirc',
    195: 'Atilde',      196: 'Auml',        197: 'Aring',       198: 'AElig',       199: 'Ccedil',
    200: 'Egrave',      201: 'Eacute',      202: 'Ecirc',       203: 'Euml',        204: 'Igrave',
    205: 'Iacute',      206: 'Icirc',       207: 'Iuml',        208: 'ETH',         209: 'Ntilde',
    210: 'Ograve',      211: 'Oacute',      212: 'Ocirc',       213: 'Otilde',      214: 'Ouml',
    215: 'times',       216: 'Oslash',      217: 'Ugrave',      218: 'Uacute',      219: 'Ucirc',
    220: 'Uuml',        221: 'Yacute',      222: 'THORN',       223: 'szlig',       224: 'agrave',
    225: 'aacute',      226: 'acirc',       227: 'atilde',      228: 'auml',        229: 'aring',
    230: 'aelig',       231: 'ccedil',      232: 'egrave',      233: 'eacute',      234: 'ecirc',
    235: 'euml',        236: 'igrave',      237: 'iacute',      238: 'icirc',       239: 'iuml',
    240: 'eth',         241: 'ntilde',      242: 'ograve',      243: 'oacute',      244: 'ocirc',
    245: 'otilde',      246: 'ouml',        247: 'divide',      248: 'oslash',      249: 'ugrave',
    250: 'uacute',      251: 'ucirc',       252: 'uuml',        253: 'yacute',      254: 'thorn',
    255: 'yuml',        402: 'fnof',        913: 'Alpha',       914: 'Beta',        915: 'Gamma',
    916: 'Delta',       917: 'Epsilon',     918: 'Zeta',        919: 'Eta',         920: 'Theta',
    921: 'Iota',        922: 'Kappa',       923: 'Lambda',      924: 'Mu',          925: 'Nu',
    926: 'Xi',          927: 'Omicron',     928: 'Pi',          929: 'Rho',         931: 'Sigma',
    932: 'Tau',         933: 'Upsilon',     934: 'Phi',         935: 'Chi',         936: 'Psi',
    937: 'Omega',       945: 'alpha',       946: 'beta',        947: 'gamma',       948: 'delta',
    949: 'epsilon',     950: 'zeta',        951: 'eta',         952: 'theta',       953: 'iota',
    954: 'kappa',       955: 'lambda',      956: 'mu',          957: 'nu',          958: 'xi',
    959: 'omicron',     960: 'pi',          961: 'rho',         962: 'sigmaf',      963: 'sigma',
    964: 'tau',         965: 'upsilon',     966: 'phi',         967: 'chi',         968: 'psi',
    969: 'omega',       977: 'thetasym',    978: 'upsih',       982: 'piv',         8226: 'bull',
    8230: 'hellip',     8242: 'prime',      8243: 'Prime',      8254: 'oline',      8260: 'frasl',
    8472: 'weierp',     8465: 'image',      8476: 'real',       8482: 'trade',      8501: 'alefsym',
    8592: 'larr',       8593: 'uarr',       8594: 'rarr',       8595: 'darr',       8596: 'harr',
    8629: 'crarr',      8656: 'lArr',       8657: 'uArr',       8658: 'rArr',       8659: 'dArr',
    8660: 'hArr',       8704: 'forall',     8706: 'part',       8707: 'exist',      8709: 'empty',
    8711: 'nabla',      8712: 'isin',       8713: 'notin',      8715: 'ni',         8719: 'prod',
    8721: 'sum',        8722: 'minus',      8727: 'lowast',     8730: 'radic',      8733: 'prop',
    8734: 'infin',      8736: 'ang',        8743: 'and',        8744: 'or',         8745: 'cap',
    8746: 'cup',        8747: 'int',        8756: 'there4',     8764: 'sim',        8773: 'cong',
    8776: 'asymp',      8800: 'ne',         8801: 'equiv',      8804: 'le',         8805: 'ge',
    8834: 'sub',        8835: 'sup',        8836: 'nsub',       8838: 'sube',       8839: 'supe',
    8853: 'oplus',      8855: 'otimes',     8869: 'perp',       8901: 'sdot',       8968: 'lceil',
    8969: 'rceil',      8970: 'lfloor',     8971: 'rfloor',     9001: 'lang',       9002: 'rang',
    9674: 'loz',        9824: 'spades',     9827: 'clubs',      9829: 'hearts',     9830: 'diams',
    338: 'OElig',       339: 'oelig',       352: 'Scaron',      353: 'scaron',      376: 'Yuml',
    710: 'circ',        732: 'tilde',       8194: 'ensp',       8195: 'emsp',       8201: 'thinsp',
    8204: 'zwnj',       8205: 'zwj',        8206: 'lrm',        8207: 'rlm',        8211: 'ndash',
    8212: 'mdash',      8216: 'lsquo',      8217: 'rsquo',      8218: 'sbquo',      8220: 'ldquo',
    8221: 'rdquo',      8222: 'bdquo',      8224: 'dagger',     8225: 'Dagger',     8240: 'permil',
    8249: 'lsaquo',     8250: 'rsaquo',     8364: 'euro'
}

htmlEntitiesTables.strToNum = _.invert(htmlEntitiesTables.numToStr)
//console.log('strToNum',htmlEntitiesTables.strToNum)
// some quick tests
//escapeHtmlEntities('abcABC&amp;there is more&#219;that is it')

// https://stackoverflow.com/questions/21003059/how-do-you-clone-an-array-of-objects-using-underscore
export function deepClone(obj, opts) {
    var newObject = {};
    if (obj instanceof Array) {
        return obj.map(function (i) { return deepClone(i, opts); });
    } else if (obj instanceof Date) {
        return new Date(obj);
    } else if (obj instanceof RegExp) {
        console.error('deepClone does not do regular expressions')
        return false
    } else if (obj instanceof Function) {
        console.error('deepClone does not do functions')
        return false
    } else if (obj instanceof Object) {
        Object.keys(obj).forEach(function (key) {
            newObject[key] = deepClone(obj[key], opts);
        });
        return newObject;
    } else if ([ undefined, null ].indexOf(obj) > -1) { // handles non-object constants as well as null, undefined
        return obj;
    } else {
        return _.clone(obj)
    }
}

// these were tried on the studentStatusCell but not used in the end
function arrowDirection(keyCode) {
    switch (keyCode) {
      case 9:
        return 'tab'
        break
      case 37:
        return 'left'
        break
      case 38:
        return 'up'
        break
      case 39:
        return 'right'
        break
      case 40: 'down'
        break
      default:
        return null
    }
  }

  function arrowKeyAccessibleTabs(e) {
    let direction = arrowDirection(e.keyCode)
    if (direction) {
      if (direction === 'tab') {

      } else {
        e.preventDefault();
        // it does not matter the direction, we only have two buttons so toggle between them
        let currentIndex = e.currentTarget.dataset.buttonindex
        let allButtons = document.querySelectorAll('[data-buttonindex]')
        let nextIndex = (currentIndex + 1) % allButtons.length
        if (currentIndex !== nextIndex) {
          let nextElem
          allButtons.forEach(el => {
            if (el.dataset.buttonindex == nextIndex) {
              nextElem.focus()
            }
          })
        }
      }
    }
  }

  // was used in SI_Images to debug
  export function reportImageSizes(list,startStr) {
    let str = _.reduce(list, (memo, obj, index) => {
        let len = obj.img && obj.img.bytes ? obj.img.bytes.length : 0
        return memo + ', ' + index + ' len=' + len
    },startStr)
}

export function isNullish(val) {
    return _.isUndefined(val) || _.isNull(val)
}

export function noModalEscape(modalId) {
    let $selector = $('#' + modalId)
    $selector.on('keydown', function (event) {
        if (event.keyCode === 27) {
            event.preventDefault()
            event.stopPropagation()
        }
    })
}

export function noContentError(msg) {
    let failureMessage = msg || 'Error: Content could not be loaded. Please try again later.'
    return (<div><span style={{color: 'red'}}>{failureMessage}</span></div>)
}

const badInjection = new RegExp([
    '^(?!.*?javascript:(.*?))',
    '(?!(.*?)<script>(.*?)</script>(.*?))',
    '(?!(.*?)</script>(.*?))',
    '(?!(.*?)<script>(.*?))',
    '(?!(.*?)onload(.*?))',
    '(?!(.*?)svg(.*?))',
    '(?!(.*?)<img(.*?))',
    '(?!src[\r\n]*=[\r\n]*\\\'(.*?)\\\')',
    '(?!src[\r\n]*=[\r\n]*\\\"(.*?)\\\")',
    '(?!eval\\((.*?)\\))',
    '(?!(.*?)expression\\((.*?)\\))',
    '(?!(.*?)vbscript:(.*?))',
    '([\\x20-\\x7E\n\r]*)$',
    ].join(''))

    export function checkInjection(text) {
        return badInjection.test(text)
    }

    export function hasText(txt) {
        if (txt && typeof txt === 'string') {
             return txt.trim().length > 0
        }
    }
