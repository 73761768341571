import React from 'react';
import {  bsliPut, bsliDelete } from '../../_core/util/request';
import { Input, Select, YellowButton, Expandable, Modal, NakedButton } from '@cb/apricot-react';

export default class ShowCourseStructureComp extends React.Component {

    constructor(props) {
        super(props);
        let originalparams = [];
        originalparams = JSON.parse(JSON.stringify(this.props.parms.parameters));
        this.state = {
            assigmnent: "-1", rubrics: null, selectedparam: null, checked: false, originalparams: originalparams, date: null, selectedModule: null, selectedlesson: null, selectedRubricScore: null,
            selectedRubric: null, flag: true,
            showAlert: false, alertMsg: '', alertSection: ''
        };

    }

    componentDidUpdate() {

    }

    componentDidMount() {
    }

    render() {
        const showAlert = (section) => {
            if (this.state.showAlert && this.state.alertSection === section) {
                return <div className='alert alert-danger'>
                    <span>{this.state.alertMsg}</span>
                </div>
            }
        }

        const showEditDeleteButtonLesson = (lesson, index, moduleId) => {
            let show = this.props.parms.courseCreationEnabled && this.props.parms.model.isCourseActive;
            if (show) {
                let editLessonId = "editLesson" + lesson.id;
                let deleteLessonId = "deleteLesson" + lesson.id;
                let selectedLessonName = lesson.title;
                let selectedLessonAbbreviation = lesson.abbreviation;
                let selectedAssignmentTypeId = lesson.assignmentTypeId;
                const editDeleteButtonLesson = <div className="cb-btn-row">
                    <NakedButton type="button" id={editLessonId} noPadding data-cb-modal-close >
                        Cancel
                    </NakedButton>
                    <YellowButton
                        onClick={(e) => this.saveLesson(this.state.selectedLesson.title, this.state.selectedLesson.abbreviation, this.state.selectedLesson.assignmentTypeId, moduleId, lesson.id, e.target, index)}>Save</YellowButton>
                </div>
                const deleteButtonLesson = <div className="cb-btn-row">
                    <NakedButton type="button" id={deleteLessonId} noPadding data-cb-modal-close >
                        No
                    </NakedButton>
                    <YellowButton
                        onClick={(e) => this.deleteLesson(lesson.id, moduleId, e.target, index)}>Yes</YellowButton>
                </div>
                return (
                <span>
                    <NakedButton type="button" noPadding id={editLessonId}  >
                        <span className="cb-glyph cb-compose" aria-hidden='true'/> 
                        <span className="sr-only">{'Edit Sub-Task for lesson,' + lesson.title}</span>
                    </NakedButton >

                    <NakedButton type="button" noPadding id={deleteLessonId}  >
                        <span className='cb-glyph cb-x-mark cb-red1-color' aria-hidden='true'/>
                        <span className="sr-only">{'Delete Sub-Task for lesson,' + lesson.title}</span>
                    </NakedButton>

                    <Modal
                        customAttributes={null}
                        title="Edit Performance Sub-task"
                        trigger={editLessonId}
                        withFooter
                        withHeader
                        footer={editDeleteButtonLesson}
                        onClose={() => { this.setState({ ["selectedLesson"]: null }) }}
                        onShow={() => { this.setState({ ["selectedLesson"]: this.copyObject(lesson) }) }}>

                        <div className="row">
                            {showAlert("editlesson")}
                            <div className="col-xs-6">
                                <label htmlFor={"selected_lesson_name".concat(lesson.id)}> Name </label>
                            </div>
                            <div className="col-xs-6" >
                                <Input type="text" id={"selected_lesson_name".concat(lesson.id)} name="selected_lesson_name"
                                    defaultValue={this.state.selectedLesson ? this.state.selectedLesson.title : selectedLessonName}
                                    onChange={(e) => { this.state.selectedLesson.title = e.target.value }} maxLength="256" /> 
                            </div>
                        </div>
                        <br/>

                        <div className="row">
                            <div className="col-xs-6">
                                <label htmlFor={"selectedTaskAbbreviation".concat(lesson.id)}> Abbreviation </label>
                            </div>
                            <div className="col-xs-6" >
                                <Input type="text" name="selectedTaskAbbreviation" id={"selectedTaskAbbreviation".concat(lesson.id)}
                                    defaultValue={this.state.selectedLesson ? this.state.selectedLesson.abbreviation : selectedLessonAbbreviation}
                                    onChange={(e) => { this.state.selectedLesson.abbreviation = e.target.value }} maxLength="256" />
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-xs-6">
                                <label htmlFor="assignmentType" >Assignment Type</label>
                            </div>
                            <div className="col-xs-6" >
                                <Select
                                    id="assignmentType"
                                    name="assignmentType"
                                    ariaLabel="Select Assignment Type"
                                    values={this.assignmentTypesLabelValues()}
                                    onChange={(val) => this.state.selectedLesson.assignmentTypeId = val}
                                    value={this.state.selectedLesson ? this.state.selectedLesson.assignmentTypeId : selectedAssignmentTypeId}
                                />
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        customAttributes={null}
                        title="Delete Peformance Sub-Task"
                        trigger={deleteLessonId}
                        withFooter
                        withHeader
                        footer={deleteButtonLesson}>
                        {showAlert("deletelesson")}
                        This will permanently delete this. Once deleted, it can not be restored. Are you sure you want to continue?
                    </Modal>
                </span>
                )
            }
        }

        const showLessons = (lessons, moduleId) => {
            let listLessons = lessons.map((lesson, index) =>
                <div className="row" key={"lesson_".concat(lesson.id)}>
                    <span id={"lessontitle_".concat(lesson.id)} className="cb-spacerv-bottom-8 cb-spacerh-left-24">{lesson.title}</span>
                    {showEditDeleteButtonLesson(lesson, index, moduleId)}
                </div>
            )
            return listLessons
        }

        const showExpandableModule = (module) => {
            if (module.lessons && module.lessons.length > 0) {
                return (
                    <span style={{ float: 'right' }}>
                        <Expandable id={module.id} closedLabel="Show Subtasks" openLabel="Hide Subtasks" linkPosition="top">
                            {showLessons(module.lessons, module.id)}
                        </Expandable>
                    </span>
                )
            }
        }

        const showEditDeleteButtonModule = (module, index) => {
            let show = this.props.parms.courseCreationEnabled && this.props.parms.model.isCourseActive;

            if (show) {
                let editModalId = "editModule" + module.id;
                let deleteModalId = "deleteModule" + module.id;
                let moduleId = "module" + module.id;
                let selectedModuleName = module.title;
                let selectedModuleAbbreviation = module.abbreviation;
                const editPerformanceTaskButtons = <div className="cb-btn-row">
                    <NakedButton type="button" id={editModalId} noPadding data-cb-modal-close>
                        Cancel
                    </NakedButton>
                    <YellowButton
                        onClick={(e) => this.saveModule(this.state.selectedModule.title, this.state.selectedModule.abbreviation, module.id, e.target, index)}>Save</YellowButton>
                </div>
                const deletePerformanceTaskButtons = <div className="cb-btn-row">
                    <NakedButton type="button" id={deleteModalId} noPadding data-cb-modal-close>
                        No
                    </NakedButton>
                    <YellowButton
                        onClick={(e) => this.deleteModule(module.id, e.target, index)}>Yes</YellowButton>
                </div>
                return (
                    <span>
                    <NakedButton type="button" noPadding id={editModalId}>
                        <span className="cb-glyph cb-compose" />
                        <span className="sr-only">{'Edit Task for module,' + module.title}</span>
                    </NakedButton >
                    <NakedButton type="button" id={deleteModalId} noPadding >
                        <span className="cb-glyph cb-x-mark cb-red1-color " />
                        <span className="sr-only">{'Delete Task for module,' + module.title}</span>
                    </NakedButton>


                    <Modal
                        onClose={() => { this.setState({ ["selectedModule"]: null }) }}
                        onShow={() => { this.setState({ ["selectedModule"]: this.copyObject(module) }) }}
                        customAttributes={null}
                        title="Edit Performance Task"
                        trigger={editModalId}
                        withFooter
                        withHeader
                        id={moduleId}
                        footer={editPerformanceTaskButtons}
                    >
                        <div className="row">
                            {showAlert("editmodule")}
                            <div className="col-xs-4"> <label htmlFor={"selected_module_name".concat(module.id)}>  Name  </label> </div> <div className="col-xs-8" >
                                <Input type="text" name="selected_module_name" id={"selected_module_name".concat(module.id)}
                                    defaultValue={this.state.selectedModule ? this.state.selectedModule.title : selectedModuleName}
                                    onChange={(e) => { this.state.selectedModule.title = e.target.value }} maxLength="256" />  </div> </div>
                        <br/>
                        <div className="row">  <div className="col-xs-4"> <label htmlFor={"selectedTaskAbbreviation".concat(module.id)}  > Abbreviation </label> </div> <div className="col-xs-8" >
                            <Input type="text" id={"selectedTaskAbbreviation".concat(module.id)} name="selectedTaskAbbreviation"
                                defaultValue={this.state.selectedModule ? this.state.selectedModule.abbreviation : selectedModuleAbbreviation}
                                onChange={(e) => { this.state.selectedModule.abbreviation = e.target.value }} maxLength="256" />  </div> </div>

                    </Modal>

                    <Modal
                        customAttributes={null}
                        title="Delete Performance Task"
                        trigger={deleteModalId}
                        withFooter
                        withHeader
                        footer={deletePerformanceTaskButtons}>
                        {showAlert("deletemodule")}
                        This will permanently delete this. Once deleted, it can not be restored. Are you sure you want to continue?
                    </Modal>


                    </span>
                )
            }
        }

        const showModules = this.props.parms.modules.map((module, index) =>
            <div className="adminshowmodules cb-spacerv-top-16 cb-spacerh-left-16" key={module.id}>
                <span id={"moduletitle_".concat(index)}>
                    {module.title}
                </span>
                {showEditDeleteButtonModule(module, index)}
                {showExpandableModule(module)}
            </div>
        )

        return (<div className="cb-spacerv-top-24 cb-spacerh-left-8">
            <h5>Course Structure </h5>
            {showModules}
        </div>
        )
    }

    assignmentTypesLabelValues() {
        return [{ value: "-1", label: "Assignment Type" }].concat(_.map(this.props.parms.assignmentTypes, (assignmentType) => {
            return { value: assignmentType.assignmentTypeId, label: assignmentType.typeName }
        }));
    }

    saveModule(name, abbreviation, moduleId, target, index) {
        let courseInstanceId = this.props.parms.courseInstanceId;
        let url = '/admin/course-instances/' + courseInstanceId + '/modules/' + moduleId;
        this.clearAlert();

        if (!name) {
            this.displayAlert("Input required for Module Name.", "editmodule");
            return;
        }

        bsliPut(url, {
            "title": name,
            "abbreviation": abbreviation
        }).then(() => {
            let module = this.props.parms.modules.find(m => m.id === moduleId)
            if (module) {
                module.title = name;
                module.abbreviation = abbreviation;
            }

            let label = document.getElementById('moduletitle_'.concat(index)).children[0];
            if (label) {
                label.innerText = name;
            }

            let el = target ? target.previousElementSibling : null
            if (el) {
                el.click();
            }
            this.bounceState();
        }).catch(() => {
            this.displayAlert("Error happenned please try again.", "editmodule");
        })
    }

    saveLesson(name, abbreviation, assignmentTypeId, moduleId, lessonId, target, index) {
        let courseInstanceId = this.props.parms.courseInstanceId;
        let url = '/admin/course-instances/' + courseInstanceId + '/modules/' + moduleId + '/lessons/' + lessonId;
        this.clearAlert();
        let missingParam = false;
        let displayMessage = "Input required for ";

        if (!name) {
            displayMessage += 'Lesson Name'
            missingParam = true;
        }

        if (assignmentTypeId === "-1") {
            if (missingParam) {
                displayMessage += ", Assignment Type."
            } else {
                displayMessage += "Assignment Type."
            }
            missingParam = true;
        } else {
            if (missingParam) {
                displayMessage += ".";
            }
        }
        if (missingParam) {
            this.displayAlert(displayMessage, "editlesson");
            returnl
        }

        bsliPut(url, {
            "title": name,
            "abbreviation": abbreviation,
            "assignmentTypeId": assignmentTypeId
        }).then(() => {
            let module = this.props.parms.modules.find(m => m.id === moduleId)
            let lesson;
            if (module) {
                lesson = module.lessons.find(l => l.id === lessonId)
            }
            if (lesson) {
                lesson.title = name;
                lesson.abbreviation = abbreviation;
                lesson.assignmentTypeId = assignmentTypeId;
            }
            let label = document.getElementById('lessontitle_'.concat(lessonId));
            if (label) {
                label.innerText = name;
            }
            let el = target ? target.previousElementSibling : null
            if (el) {
                el.click();
            }
            this.bounceState()
        }).catch(() => {
            this.displayAlert("Error happenned please try again", "editlesson");
        })
    }


    deleteModule(moduleId, target, index) {
        let courseInstanceId = this.props.parms.courseInstanceId;
        let url = '/admin/course-instances/' + courseInstanceId + '/modules/' + moduleId;
        this.clearAlert();
        bsliDelete(url).then(() => {
            this.props.parms.modules.splice(index, 1);
            let el = target ? target.previousElementSibling : null
            if (el) {
                el.click();
            }
            setTimeout(() => { this.bounceState() }, 500);
        }).catch(() => {
            this.displayAlert("Error happenned please try again.", "deletemodule");
        })
    }


    deleteLesson(lessonId, moduleId, target, index) {
        let courseInstanceId = this.props.parms.courseInstanceId;
        let url = '/admin/course-instances/' + courseInstanceId + '/modules/' + moduleId + '/lessons/' + lessonId;
        this.clearAlert();
        bsliDelete(url).then(() => {
            let module = this.props.parms.modules.find(m => m.id === moduleId)
            if (module) {
                module.lessons.splice(index, 1);
            }

            let el = target ? target.previousElementSibling : null
            if (el) {
                el.click();
                this.bounceState();
            }
        }).catch(() => {
            this.displayAlert("Error happenned please try again.", "deletelesson");
        })

    }

    clearAlert() {
        this.setState({ ["showAlert"]: false, ["alertMsg"]: "", ["alertSection"]: "" });
    }

    displayAlert(message, section) {
        this.setState({ ["showAlert"]: true, ["alertMsg"]: message, ["alertSection"]: section });
    }

    copyObject(object) {
        return Object.assign({}, object)
    }

    // dummy method so force screen refresh

    bounceState() {
        let flag = this.state.flag;
        this.setState({ ["flag"]: !flag });
    }
}