import React from 'react';
import { CourseComp } from './course'
import { CBAccordion } from "@cb/apricot";

export default class CourseListComp extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        CBAccordion({
            elem: document.querySelector('.cb-accordion')
        })
    }

    render() {
        let parms = this.props.parms
        let courseList = courses => {
            return _.map(courses, (course, index) => {
                let studentHref = 'instruction/sections/' + course.sectionId + '/overview'
                let coordHref = 'instruction/courses/' + course.id + '/progress/0'
                return (
                    <div key={'courseKey' + index} className='course'>
                        {parms.helpers.isStudent &&
                            <CourseComp course={course} href={studentHref}/>
                        }
                        {parms.helpers.isCoordinator &&
                            <CourseComp course={course} href={coordHref}/>
                        }
                    </div>
                )
            })
        }

        return (
            <div key={parms.key} id="coursesAccordion">
                <div className='js-courses-region table-borders'>
                    {courseList(parms.courses)}
                </div>
            </div>
        )
    }
}
