import React                                    from 'react'
import PlagiarismSearchComp                     from './plagiarismSearch'
import PlagiarismResultsComp                    from './plagiarismResults'
import { bsliPostScores }                       from '../../_core/util/request'
import { showLoadingView , closeLoadingView}    from '../../_core/views/loading_view'

const ViewPlagiarismReport = (props) => {
    const [results, setResults] = useState(false)

    const setResultData = (results) => {
        setResults(interpretResults(results))
    }

    const updateStudentHasAppealed = (studentHasAppealed) => {
        const appeal = results ? results.appeal : false
        if (appeal) {
            const url = '/review/plagiarism/scorable/' + results.sisId;
            const data = {
                decisionRationale:      appeal.decisionRationale,
                acceptAppeal:           appeal.acceptAppeal,
                studentHasAppealed:     studentHasAppealed,
                apNumber:               appeal.apNumber
            }

            showLoadingView('region-spinner')
            bsliPostScores(
              url, JSON.stringify(data)
            ).then(() => {
                // just update the appeal property
                updateAppeal( {studentHasAppealed: studentHasAppealed} )
            }).finally(() => {
                closeLoadingView('region-spinner')
            })
        } else {
            console.error('updateStudentHasAppealed did not expect missing results')
        }
    }

    const updateAppeal = (newValues) => {
        setResults(prev => {
            const results = _.clone(prev)
            results.appeal = _.extend(results.appeal, newValues)
            // only update this in interpretResults after POST call
            results.isAcceptancePosted = false;//this.getAcceptencePosted(results.appeal)
            return results
        })
    }

    const interpretResults = (results) => {
        let retVal = false
        if (results) {
            let json = results.json ? results.json : {}
            let appeal
            if (json.appeal) {
                appeal = json.appeal
                appeal.acceptAppeal = appeal.acceptAppeal // let it be null
                appeal.studentHasAppealed = !!appeal.studentHasAppealed
                appeal.decisionRationale = appeal.decisionRationale ? appeal.decisionRationale : ''
            } else {
                appeal = {decisionRationale: '', acceptAppeal: null, studentHasAppealed: false, apNumber: null}
            }

            retVal = {
                json:                   json,
                appeal:                 appeal,
                sisId:                  json.assignment ? json.assignment.sisId : null,
                uploadId:               json.assignment ? json.assignment.uploadId : null,
                assignmentId:           results.assignmentId,
                isAcceptancePosted:     getAcceptencePosted(appeal)
            }
        }
        return retVal
    }

    const getAcceptencePosted = (appeal) => {
        return appeal && appeal.decisionRationale.length > 0 && appeal.acceptAppeal != null
    }

    return (
        <div id='view-plagiarism-report'>
            <h4 className='cb-margin-top-16 cb-margin-bottom-16 cb-padding-top-8 cb-padding-bottom-8 cb-padding-left-8 cb-gray4-bg'>Plagiarism Dashboard</h4>
            <div id="plagiarism-search-form">
                <PlagiarismSearchComp
                    student={props.params.student}
                    studentDefault={props.params.studentDefault}
                    setResults={setResultData}
                />
            </div>
            <div id="region-spinner" className="cb-spacerv-32"></div>
            <div id="plagiarism-search-results">
                <PlagiarismResultsComp
                    results={results}
                    updateStudentHasAppealed={updateStudentHasAppealed}
                    updateAppeal={updateAppeal}
                />
            </div>
        </div>
    )
}

export default ViewPlagiarismReport
