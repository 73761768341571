import { bsliPutCSP, fetchRequest }    from '../_core/util/request'

let pollingCnt
const maxPollingCnt = 10
let progress, finished, failed
let disableStatusCheck = false

export function uploadToS3(data, metaData, url, wait, progressFunc, finishedFunc, failedFunc) {
    pollingCnt = 0
    progress = progressFunc
    finished = finishedFunc
    failed = failedFunc
    disableStatusCheck = false

    let endIndex = url.indexOf('?');
    let strippedurl = (endIndex === -1) ? url : url.substring(0, endIndex).trim();
    let pollUrl = strippedurl + "/" + metaData.lambdaUploadId + '/status';
    return bsliPutCSP(metaData.signedS3Url, data, progress, metaData.metadata)
        .then(() => {
            if (!wait) {
                // not going to wait for the S3Status to be 'done'
                return
            }
            // start a promise chain waiting for the S3Status call to return 'done'
            return checkStatusAgain(pollUrl)
        })
        .catch(err => {
            if (wait) {
                failed('uploadToS3', err, true);
            }
        })
}

export async function checkS3Status(pollUrl, invocationid) {
    try {
      const data = await fetchRequest('GET', pollUrl, {
          credentials: 'include'
      })

      if (invocationid) {
        if (data.status) {
            if (data.status === 'success') {
               await downloadPPR(invocationid)
               return
            } else if (data.status === 'error') {
                reject('error')
            } else {
                if (pollingCnt++ > maxPollingCnt) {
                    reject('error')
                } else {
                    await checkStatusAgain(pollUrl, invocationid)
                }
            }
        } else {
            console.log('status is not returned', data)
        }
      } else {
        if (data.uploadStatusInfo) {
            if (data.uploadStatusInfo.done) {
                finished()
                return data.uploadStatusInfo
            } else {
                if (pollingCnt > maxPollingCnt) {
                    reject('error')
                } else {
                    return await checkStatusAgain(pollUrl)
                }
            }
        } else {
            reject('error')
        }
      }
    } catch(err) {
        reject('error')
    }
}

export async function checkStatusAgain(url, invocationid) {
    if (disableStatusCheck) {
        return
    }
    return new Promise((resolve,reject) => {
        pollingCnt++
        setTimeout(() => {
            checkS3Status(url, invocationid)
                .then(data => {
                    resolve(data)
                })
                .catch(() => {
                    reject('error')
                })
        }, 10000)
    })
}

export async function downloadPPR(invocationid) {
    const url = `sections/downloadpdf/${invocationid}`
    const signedURL = await fetchRequest('GET', url , {
        credentials: 'include'
    })
    window.open(signedURL.url, '_blank')
}

export async function requestIndividual(apid) {
    disableStatusCheck = false
    return new Promise( async (resolve, reject) => {
        let url = `/api/sections/apidpdf/${apid}`
        try {
            let invocation  = await fetchRequest('GET', url , {
                credentials: 'include'
            })
            let invocationId = invocation.invocationid
            let pollUrl = `sections/pdfstatus/${invocationId}`
            await checkS3Status(pollUrl, invocationId)
            resolve('success')
        } catch(e) {
            reject('error')
        }
    })
}

export function requestSection(sectionId) {
    disableStatusCheck = false
    return new Promise( async (resolve, reject) => {
        let url = `/api/sections/sectionpdf/${sectionId}`
        try {
            let invocation  = await fetchRequest('GET', url , {
                credentials: 'include'
            })
            let invocationId = invocation.invocationid
            let pollUrl = `sections/pdfstatus/${invocationId}`
            await checkS3Status(pollUrl, invocationId)
            resolve('success')
        } catch(e) {
            reject('error')
        }
    })
}

export function stopStatusCheck() {
    disableStatusCheck = true
}