import React, { Fragment, useEffect}            from 'react';
import { AccordionList, AccordionItem }         from '@cb/apricot-react';
import {Link} from 'react-router-dom'
import { CBAccordion } from "@cb/apricot";

export function TeacherCourseListComp(props) {
    useEffect(() => {
        CBAccordion({
            elem: document.querySelector('.cb-accordion')
        })
        let expandAll = document.getElementsByClassName('cb-accordion-expand')[0];
        if (expandAll) expandAll.setAttribute('aria-label', 'Expand All ' + props.title);
        let collapseAll = document.getElementsByClassName('cb-accordion-collapse')[0];
        if (collapseAll) collapseAll.setAttribute('aria-label', 'Collapse All ' + props.title);

    }, []); // the second parameter ensures useEffect runs only once

    let teacherCourseList = () => {
        return _.map(props.courses, (course, index) => {
            return (
                <div key={'courseKey' + index} className='course'>
                    <CourseTeacherComp course={course} />
               </div>
            )
        })
    }

    return (
        <AccordionList title='' controls='true'>
            {teacherCourseList()}
        </AccordionList>
    )
}

function CourseTeacherComp({ course }) {

    let adjustedTitle = course.title.replace('AP ', '')

    let orgList = orgs => {
        return _.map(orgs, (org, index) => {
            return (
                <div key={'org' + index} style={{ 'marginBottom': '5px' }}>
                    <Link to={'/instruction/sections/' + org.id + '/overview'}>
                        {org.fullTitle}
                    </Link>
                </div>
            )
        })
    }

    let title = (
        <span>
            <span className="logo logo-sm" />
            <span className="sr-only">AP</span>
            <span className='course-title'>{adjustedTitle}</span>
        </span>
    )

    return (
        <AccordionItem title={title}>
            {orgList(course.sectionsOfOrg)}
        </AccordionItem>
    )
}