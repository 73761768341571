import { subject }                              from '../../../utility/service'
import { isFloat, isNullish } from '../../../utility/util'
import { bsliPostCSP, bsliPutCSP, bsliGet, fetchRequest, bsliPut }   from '../../../_core/util/request'
import { addBaseUrl }                           from '../../../_core/util/URL.jsx'
import { getCurrentSectionId }                  from '../../../entities/courseinstance'
import { stateToJson }                          from './downloadArt'
import { isSectionArray } from "./imageData";
import { uploadToS3 as uploadToS3WR}                           from '../../../utility/upload'

let pollingCnt = 0

export async function downloadArt(assignmentId, uploadId) {
    const url = 'sections/' + getCurrentSectionId() + '/assignments/' + assignmentId + '/uploads/' + uploadId + '/downloadart'
    let json = await bsliGet(url)
      .catch(() => {
          return {}
      })
    return json
}

export async function updateDate(assignmentId, uploadId) {
    const url = 'sections/' + getCurrentSectionId() + '/assignments/' + assignmentId + '/uploads/' + uploadId + '/downloadDate'
    let json = await bsliGet(url)
      .catch(() => {
          return {}
      })
    return json
}

// returns Promise, even on error
// stateData called dataToSave in caller, is a subset of the UI 'sections' to save
export function upload(url, stateData, wait, isArtWE, removeLockUrl) {
    if (isArtWE) {
        let formData = new FormData();
        formData.append( 'formData', JSON.stringify({Art_WE_inquiry: stateData.inquiry , Art_WE_evidence: stateData.evidence}))
        return validateAndGetUploadMetaDataWR(url, formData, wait)
          .then(statuses => {
              return statuses
          })
          .catch(err => {
              let msg = 'upload validateAndGetUploadMetaDataWR failed'
              msg = _.isString(err) ? msg + ' err=' + err : msg
              return Promise.reject(err)
          })
    } else {
        return validateAndGetUploadMetaData(url, stateData, wait, removeLockUrl)
          .then(statuses => {
              return statuses
          })
          .catch(err => {
              let msg = 'upload validateAndGetUploadMetaData failed'
              msg = _.isString(err) ? msg + ' err=' + err : msg
              return Promise.reject(err)
          })
    }
}

export async function removeLock(url) {
    let json = await bsliPut(url)
        .catch(() => {
            return {}
        })
}

// are all the 5 input and textarea fields empty, ignore the index and id
function isEmpty(item) {
    return item.documentHeight      === '' &&
      item.documentWidth          === '' &&
      item.documentMaterial       === '' &&
      item.documentProcess        === '' &&
        item.citation               === '' &&
      item.documentIdea           === ''
}

// Replace quotation marks not coming from keypress function
const replaceQuotationMarks = item => {
    let newItem = { ...item }
    if (item.documentMaterial.length) {
        if (item.documentMaterial.includes("“")) newItem.documentMaterial = newItem.documentMaterial.replaceAll("“", "\"")
        if (item.documentMaterial.includes("”")) newItem.documentMaterial = newItem.documentMaterial.replaceAll("”", "\"")
        if (item.documentMaterial.includes("’")) newItem.documentMaterial = newItem.documentMaterial.replaceAll("’", "\'")
        if (item.documentMaterial.includes("‘")) newItem.documentMaterial = newItem.documentMaterial.replaceAll("‘", "\'")
    }
    if (item.documentProcess.length) {
        if (item.documentProcess.includes("“")) newItem.documentProcess = newItem.documentProcess.replaceAll("“", "\"")
        if (item.documentProcess.includes("”")) newItem.documentProcess = newItem.documentProcess.replaceAll("”", "\"")
        if (item.documentProcess.includes("’")) newItem.documentProcess = newItem.documentProcess.replaceAll("’", "\'")
        if (item.documentProcess.includes("‘")) newItem.documentProcess = newItem.documentProcess.replaceAll("‘", "\'")
    }
    if (item.citation.length) {
        if (item.citation.includes("“")) newItem.citation = newItem.citation.replaceAll("“", "\"")
        if (item.citation.includes("”")) newItem.citation = newItem.citation.replaceAll("”", "\"")
        if (item.citation.includes("’")) newItem.citation = newItem.citation.replaceAll("’", "\'")
        if (item.citation.includes("‘")) newItem.citation = newItem.citation.replaceAll("‘", "\'")
    }
    if (item.documentIdea.length) {
        if (item.documentIdea.includes("“")) newItem.documentIdea = newItem.documentIdea.replaceAll("“", "\"")
        if (item.documentIdea.includes("”")) newItem.documentIdea = newItem.documentIdea.replaceAll("”", "\"")
        if (item.documentIdea.includes("’")) newItem.documentIdea = newItem.documentIdea.replaceAll("’", "\'")
        if (item.documentIdea.includes("‘")) newItem.documentIdea = newItem.documentIdea.replaceAll("‘", "\'")
    }
    return newItem
}

// this computes the blob that is sent with uploadsmulti REST endpoint
// json is in the format return by downloadArt, see methods stateToJson and jsonToState
function getFileNames(json) {
    const itemsToSend = (obj) => {
        let meta = obj.meta
        let item = {
            documentDepth:          meta.depth,
            documentHeight:         meta.height,
            documentWidth:          meta.width,
            documentIndex:          meta.id,
            documentMaterial:       meta.materials,
            documentProcess:        meta.processes,
            citation:               meta.citations,
            documentIdea:           meta.ideas,
            uploadSubDocumentId:    obj.uploadSubDocumentId
        }
        item = replaceQuotationMarks(item)
        let hasFile = !isNullish(obj.file) && obj.file instanceof File
        let hasFileName = !isNullish(obj.img.fileName) && obj.img.fileName.length > 0
        let isUpdate = !!obj.uploadSubDocumentId

        if (hasFile || (hasFileName && !isUpdate)) {
            item.fileName = obj.img.fileName
        } else {
            if (isUpdate) {
                // when updating, the file object is missing in two situations
                // the card was deleted, fileName will be blank and the metadata will be blank
                // only the metadata was changed, filname will still be there
                if  (!hasFileName && isEmpty(item)) {
                    item.action = 'D'
                } else {
                    item.action = 'U'
                }
            } else {
                // if is NOT an update then there MUST be a file
                console.info('New entries require a file when section isn\'t multi-image')
                return null
            }
        }
        return item
    }

    let fileNames = json.map(obj => (isSectionArray(obj) ? obj.map(o => itemsToSend(o)) : itemsToSend(obj)))
    fileNames = fileNames.filter(item => !!item)
    return fileNames
}

// returns Promise, even on error
// url is the uploadsmulti REST endpoint, stateData is a subset of 'sections' from UI state
function validateAndGetUploadMetaData(url, stateData, wait, removeLockUrl) {
    let json = stateToJson(stateData)
    let fileNames = getFileNames(json)
    let isMultiImageSection = fileNames.some(f => isSectionArray(f))
    if (fileNames.length === 0) {
        let msg = 'validateAndGetUploadMetaData had no data to send'
        return Promise.resolve(msg)
    } else {
        if (isMultiImageSection) {
            fileNames = fileNames.map(img => img.filter(i => i !== null))
            fileNames = fileNames.flat()
        }
        let indicesForMultiImageSections = isMultiImageSection ? fileNames.map(file => ({id: file.documentIndex})) : null
        let uploadId = isMultiImageSection ? json[0][0].uploadId : json[0].uploadId
        let data = { uploadFiles: fileNames, uploadId}
        let strToSend = JSON.stringify(data)
        // url = uploadsmulti REST endpoint
        return bsliPostCSP(url, strToSend)
          .then(metaData => {
              isMultiImageSection ? metaData = metaData.map((meta, ind) => ({...meta, id: indicesForMultiImageSections[ind].id})) : null
              return handleBsliPostCSP(metaData, json, wait, url, removeLockUrl)
          })
          .catch(err => {
              console.error('validateAndGetUploadMetaData bsliPostCSP failed ' + err.message)
              return Promise.reject(err)
          })
    }
}

// returns Promise, even on error
// uploads the image files using urls in the metaData returned from the uploadsmulti call
function handleBsliPostCSP(metaData, json, wait, url, removeLockUrl) {
    let promises = [], metaObjects = [], file
    const isFileReady = doc => !isNullish(doc) && doc instanceof File
    metaData.forEach((metaObject, index) => {
        let image
        if (json.some(j => isSectionArray(j))) {
            let firstOrSecond
            image = json.find(j => {
                const [firstImg, secImg] = j
                let finalResult = false
                if (firstImg.meta.id === metaObject.id) {
                    firstOrSecond = 0
                    finalResult = true
                } if (secImg.meta.id === metaObject.id) {
                    firstOrSecond = 1
                    finalResult = true
                }
                return finalResult
            })[firstOrSecond]
        }
        file = image ? image.file : json[index].file
        if (metaObject.signedS3Url && isFileReady(file)) {
            let blob = new Blob([file], { type: file.type })
            let promise = uploadToS3(blob, metaObject)
            promises.push(promise)
            metaObjects.push(metaObject)
        }
    })
    if (promises.length > 0) {
        // wait for all the S3 image uploads to finish
        return Promise.allSettled(promises)
          .then(results => {
              if (!wait) {
                  // not going to wait for the S3Status to be 'done'
                  return Promise.resolve('not going to wait for the S3Status to be done')
              }
              let pollUrl = postUrlToPollUrl(url, results, metaObjects)
              // start a promise chain waiting for the S3Status call to return 'done'
              // pass in uploadId so that the right uploadId is used
              return checkStatusAgain(pollUrl, metaObjects[0].uploadId, removeLockUrl)
          })
          .catch(err => {
              let msg = 'some uploadToS3 had an error'
              console.log(msg, err)
              return Promise.reject(msg)
          })

    } else {
        // let msg = 'handleBsliPostCSP There are no promises to wait for'
        //   console.log(msg)
        return Promise.resolve([])
    }
}

// creates a pollUrl like
// api/sections/68166/assignments/32300001/uploadsmult/status?subIds=528&subIds=527&subIds=526
function postUrlToPollUrl(url, results, metaObjects) {
    let endIndex = url.indexOf('?');
    let pollUrl = (endIndex === -1) ? url : url.substring(0, endIndex).trim()
    let first = true
    _.each(results, (result, index) => {
        if (result.status === 'fulfilled') {
            if (first) {
                pollUrl = pollUrl + '/status?'
            } else {
                pollUrl = pollUrl + '&'
            }
            pollUrl = pollUrl + 'subIds=' + metaObjects[index].lambdauploadSubId
            first = false
        }
    })
    // console.log('handleBsliPostCSP pollUrl' + pollUrl)
    return pollUrl
}

// returns a Promise
function uploadToS3(formData, metaObject) {
    if (!metaObject.signedS3Url) {
        console.error('signedS3Url not good')
        return Promise.reject()
    } else {
        pollingCnt = 0
        return bsliPutCSP(metaObject.signedS3Url, formData, updateProgress.bind(this), metaObject.metadata)
    }
}

async function checkS3Status(pollUrl, uploadId, removeLockUrl) {
    return fetchRequest('GET', pollUrl, {
        credentials: 'include'
    }).then(json => {
        if (json.done) {
            const statuses = json.uploadMultiStatuses?.map(j => ({
                ...j,
                uploadId
            }))
            return Promise.resolve(statuses)
        } else {
            if (pollingCnt > 15) {
                removeLock(removeLockUrl); 
                let msg = ' This submission could not be saved. Please try again.'              
                return Promise.reject(msg)
            } else {
                return checkStatusAgain(pollUrl, uploadId, removeLockUrl)
            }
        }
    })
      .catch(err => {
          let msg = 'checkS3Status failed'
          console.log('checkS3Status ' + msg)
          return Promise.reject(msg)
      })
}

function checkStatusAgain(url, uploadId, removeLockUrl) {
    return new Promise((resolve,reject) => {
        pollingCnt++
        setTimeout(async () => {
            try {
                const res = await checkS3Status(url, uploadId, removeLockUrl)
                resolve(res)
            } catch (e) {
                console.error('Error from checkStatusAgain function: ', e)
                reject('deferred fail')
            }
        }, 4000)
    })
}

function updateProgress() {
    var xhr = new window.XMLHttpRequest();
    xhr.upload.addEventListener("progress", function (evt) {
        if (evt.lengthComputable) {
            var percentComplete = (evt.loaded / evt.total) * 100;
            percentComplete = Math.round(percentComplete);
            ////console.log('percent complete ' + percentComplete);
        }
    }, false);
    return xhr
}

function validateAndGetUploadMetaDataWR(url, formData, wait) {
    let filename = 'DUMMY.pdf'
    let dataFilename = { filename: filename }
    return bsliPostCSP(url, dataFilename)
      .then(metaData => {
          return uploadToS3WR(formData, metaData, url, wait, updateProgress, uploadFinished, uploadFailed)
      })
      .catch(err => {
          console.error('validateAndGetUploadMetaData bsliPostCSP failed ' + err.responseText)
          return Promise.reject(err.responseText)
      })
}

function uploadFailed(msg, err) {
    if (err) {
        console.error(msg + ', ' + JSON.stringify(err))
    } else {
        console.error(msg)
    }
}

function uploadFinished() {

}
