import { StudentStatusCellComp } from './studentStatusCell'
import { TeamSubRow } from './teamSubRow'

const BUTTON_STATE = { COLLAPSED: 1, EXPANDED: 2, WAITING: 3 }

export const TeamRow = props => {
  const [state, setState] = useState({ buttonState: BUTTON_STATE.COLLAPSED })
  const toolKey = useState(props.toolKey)

  useEffect(() => {
    if (state.buttonState !== BUTTON_STATE.COLLAPSED) setState({
      buttonState: BUTTON_STATE.COLLAPSED,
    })
  }, [props])

  let teamModel = props.teamModel
  let studentRows = () => {
    if (state.buttonState === BUTTON_STATE.EXPANDED) {
      return _.map(teamModel.jsonStudents, (jsonStudent, index) => {
        let newToolKey = toolKey + '_student_' + jsonStudent.dpPersonId
        let newProps = {
          isWe: props.isWe,
          jsonStudent: jsonStudent,
          teamModel: teamModel,
          lessons: props.lessons,
          extraColumnData: '',
          sectionId: props.sectionId,
          toolKey: newToolKey,
          selectTeam: props.selectTeam,
        }
        return (
          <TeamSubRow {...newProps} key={newToolKey} />
        )
      })
    } else {
      return null
    }
  }

  let statusCells = () => {
    return _.map(props.lessons, (lesson, index) => {
      let cellId = 'teamTab_' + teamModel.jsonTeam.id + '_' + index
      let newToolKey = toolKey + '_lesson_' + lesson.abbreviation
      let newProps = {
        studentData: undefined,
        scorableHolder: teamModel.scorables[index],
        team: teamModel.jsonTeam,
        isWe: false,
        sectionId: props.sectionId,
        lesson: lesson,
        toolKey: newToolKey,
        cellId: cellId,
        selectTeam: props.selectTeam
      }
      return <StudentStatusCellComp {...newProps} key={newToolKey} />
    })
  }
  let title = props.teamModel.jsonTeam.title
  const getBtnClass = () => {
    if (state.buttonState === BUTTON_STATE.WAITING) {
      return 'cb-btn cb-btn-sm cb-btn-square cb-expand-button js-expand disabled'
    } else if (state.buttonState === BUTTON_STATE.COLLAPSED) {
      return 'cb-btn cb-btn-sm cb-btn-square cb-expand-button js-expand'
    } else if (state.buttonState === BUTTON_STATE.EXPANDED) {
      return 'cb-btn cb-btn-sm cb-btn-square cb-expand-button js-expand'
    }
  }

  const getIconClass = () => {
    if (state.buttonState === BUTTON_STATE.WAITING) {
      return 'fa fa-circle-o-notch fa-spin'
    } else if (state.buttonState === BUTTON_STATE.COLLAPSED) {
      return 'cb-glyph cb-plus'
    } else if (state.buttonState === BUTTON_STATE.EXPANDED) {
      return 'cb-glyph cb-minus'
    }
  }

  const getAriaExpanded = () => {
    if (state.buttonState === BUTTON_STATE.WAITING) {
      return false
    } else if (state.buttonState === BUTTON_STATE.COLLAPSED) {
      return false
    } else if (state.buttonState === BUTTON_STATE.EXPANDED) {
      return true
    }
  }

  const toggleStudents = () => {
    if (props.teamModel.jsonStudents.length > 0) { // only switch the button if there are students to show
      if (state.buttonState === BUTTON_STATE.COLLAPSED) {
        setState({ buttonState: BUTTON_STATE.EXPANDED })
      } else {
        setState({ buttonState: BUTTON_STATE.COLLAPSED })
      }
    }
  }


  return (
    <>
      <tr>
        <th scope='row'>
          <strong>{title}</strong>
        </th>
        {statusCells()}
        <td className='text-center'>
          <button type='button' className={getBtnClass()} tabIndex='0'
                  aria-expanded={getAriaExpanded()} onClick={toggleStudents}>
              <span className={getIconClass()} aria-hidden='true'>
                <span className='sr-only'>View students in {title}</span>
              </span>
          </button>
        </td>
      </tr>
      {studentRows()}
    </>
  )
}