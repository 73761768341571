import React from 'react'
import { NakedButton } from '@cb/apricot-react'
import {resize} from './resizeUtils';

export const CSP_WR_Canvas = (props) => {
    const canvasRef = useRef(null)
    const imgRef = useRef(null)
    let imgSrc, altText
    if (typeof props.img === 'string') {
        imgSrc = props.img
        altText = 'Thumbnail of an image whose file name was not recorded'
    } else {
        imgSrc = props.img.bytes
        altText = `Thumbnail of an image whose file name is, ${props.img.fileName}`
    }

    const deleteImg = () => {
        props.clearImg({ index: props.index, id: props.id })
    }

    return (
        <div className='canvas' data-id={props.id}>
            {
            <NakedButton type="button" className="cb-no-padding cb-btn-close"
                onClick={deleteImg}>
                <span className={`cb-glyph cb-x-mark ${props.hiddenCls}`} aria-hidden="true" id={`btn${props.id}`} />
                <span className="sr-only">Click to delete image</span>
            </NakedButton>
            }
            <div id={`div_${props.id}`} tabIndex='0'>
                <img
                    ref={imgRef}
                    id={props.id}
                    className="drop-image hidden" tabIndex='-1'
                    index={props.index}
                    src={imgSrc}
                    alt={altText}
                    onLoad={() => {
                        if (canvasRef.current && imgRef.current && imgSrc) {
                            const image = new Image()
                            image.src = imgRef.current.src
                            image.altText = imgRef.current.altText
                            const canvas = canvasRef.current
                            resize(image, canvas, {
                                unsharpAmount: 80,
                                unsharpRadius: 0.6,
                                unsharpThreshold: 2
                            }).then(result => console.log(`resize done!  ${result}`))
                                .catch(err => console.log('picaInst.resize failed', err))
                            const ctx = canvas?.getContext("2d")
                            ctx?.drawImage(image, 0, 0, imgRef.current.width, imgRef.current.height)
                        }
                    }}
                />
                <canvas height="100" width="100"
                    data-id={props.id}
                    data-testid={props.id}
                    ref={canvasRef}
                    onMouseEnter={(event) => props.onMouseAction(event, props.id)}
                    onDragOver={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        const element = e.currentTarget
                        if (!element.classList.contains('highlight')) {
                            element.classList.add('highlight')
                        }
                    }}
                    onDragLeave={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        const element = e.currentTarget
                        if (element.classList.contains('highlight')) {
                            element.classList.remove('highlight')
                        }
                    }}
                    onDrop={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        props.onDragCallback(e)
                    }}
                ></canvas>
            </div>
            <span className="sr-only">{altText}</span>
        </div>
    )
}
