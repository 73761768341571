import React, { Fragment }                                      from 'react';
import { Button, BlackButton, YellowButton }                    from '@cb/apricot-react'
import { getCurrentPerson }                                     from '../../entities/person.jsx'
import { Upload }                                               from '../entities/upload'
import DPToolTip                                                from '../../utility/tooltip'
import { reactRender, focusOnFirst, recordFocus }               from '../../utility/util'
import { runOriginality, viewOriginality }                      from '../../utility/originality'
import { ConfirmModal }                                     from '../../_core/views/modal.jsx'
import {bsliGet}                                                from '../../_core/util/request'
import { alertGeneralErrorOnModal }                             from '../../_core/views/alert'
import { getCurrentSectionId, getSection, getCourseParameter,
  getWeCourseParameter, isAfterHardEndDate, isAfricanAmericanStudies,
  hasLateSubmissionDate, isAfterLateSubmissionDate, isSeminar }            from '../../entities/courseinstance'
import UploadModalComp                                          from './uploadModal'
import SubmitFinalModalComp                                     from './submitFinalModal'
import PDFConversionModal                                       from './pdfConversionModal'
import { subject, updateSubscription}                           from '../../utility/service'
import { uploadAndScorable }                                    from '../entities/upload'

export default class MenuBarComp extends React.Component {
  constructor(props) {
    super(props);
    this.toolKey = 'menu_' + this.props.parms.personId
    this.toolTip = new DPToolTip(this.toolKey)

    this.ui = {
      turnitinForm: () => $('.js-turnitin-form')
    }
    _.bindAll(this, 'cancelSubmission', 'showCancelSubmissionModal', 'showFileUploadModal', 'showSubmitFinalModal',
      'runOriginality', 'viewOriginality', 'downloadLatest', 'onCloseOriginality',
      'hideTooltip', 'refreshUploads')

      this.state = {
        isSaving: false,
        updateCnt: 0
      }

      this.subscription = subject.subscribe(message => {
        if (message.refreshMenuBar) {
          this.refreshUploads()
        }
      })
      updateSubscription('refreshMenuBar', this.subscription)
  }

  componentDidMount() {
    this.toolTip.createTooltips()
    let selectedButtonId = sessionStorage.getItem("selectedButtonId")
    if (selectedButtonId) {
     sessionStorage.removeItem("selectedButtonId");
     recordFocus(selectedButtonId)
    }
  }
  componentDidUpdate() {
    this.toolTip.createTooltips()
  }

  render() {
    ////console.log('MenuBarComp refreshState ' + this.props.refreshState)
    let helpers = this.serializeData(this.props.parms.menuParms)
    //console.log('MenuBarComp helpers',helpers)
    let srMsg = ''
    if (helpers.submittedAt) {
      srMsg = 'Final Submitted' + helpers.submittedAt
    } else if (helpers.awaitingAttestations) {
      srMsg = 'Submit Final is partially complete. All team members must confirm submission.'
    }

    let toolTipParms = {placement: 'bottom'}
    this.toolTip.removeTooltips()

    return (
      <div>
        <form ref='formToSubmit' method="POST" target="_blank" action="" name="tiiForm" className="js-turnitin-form" encType="application/x-www-form-urlencoded"></form>
        {/* TODO: Refactor modals used in this component */}
        <div id='region-modal' />
        <div id='menuBar'>
          {helpers.showUpload &&
            <BlackButton type="button" className="js-upload"
              small={true}
              onClick={this.showFileUploadModal}
              data-cb-apricot='tooltip' id={this.toolTip.addToolTip(toolTipParms)}
              data-cb-title="Upload New File">
                Upload New
                <span className="sr-only">{'File ' + srMsg}</span>
            </BlackButton>
          }

          {helpers.showDownload &&
            <BlackButton type="button" className="js-download-file"
              small={true}
              onClick={this.downloadLatest}
              data-cb-apricot='tooltip' id={this.toolTip.addToolTip(toolTipParms)}
              data-cb-title="Download Latest Uploaded File">
              Download Latest
              <span className="sr-only">{'Uploaded File ' + srMsg}</span>
            </BlackButton>
          }

          {helpers.rubricUrl &&
            <a href={helpers.rubricUrl} target="_blank"
              className="cb-btn cb-btn-sm cb-btn-black" role='button'
              data-cb-apricot='tooltip' id={this.toolTip.addToolTip(toolTipParms)}
              data-cb-title="Download Assignment Rubric"
              onClick={this.hideTooltip}>
              View Rubric
            </a>
          }

          {helpers.showOriginalityCheck &&  !this.state.isSaving &&
            <BlackButton type="button" className="js-check-originality"
              small={true}
              onClick={this.runOriginality}
              data-cb-apricot='tooltip' id={this.toolTip.addToolTip(toolTipParms)}
              data-cb-title={'Submit ' + helpers.fileName + ' to TurnItIn'}
              data-load-text="Sending...">
              Run Originality
            </BlackButton>
          }

          {this.state.isSaving &&
            <Button small={true} loading>
              Default
                <div className="cb-btn-pulse-dots">
                  <div className="cb-btn-dots"></div>
                </div>
            </Button>
          }

          {helpers.showViewReport &&
            <BlackButton type="button" className="js-view-originality"
              small={true}
              onClick={this.viewOriginality}
              data-cb-apricot='tooltip' id={this.toolTip.addToolTip(toolTipParms)}
              data-cb-title={'View Originality Report in TurnItIn'}>
              View Originality
            </BlackButton>
          }

          {helpers.canCancel &&
            <BlackButton type="button" className="js-retract"
              small={true}
              onClick={this.showCancelSubmissionModal}
              data-cb-apricot='tooltip' id={this.toolTip.addToolTip(toolTipParms)}
              data-cb-title="Cancel Final Submission">
              Cancel Submission
            </BlackButton>
          }

          {helpers.showSubmitFinal &&
            <Fragment>
              {helpers.unsubmitted &&
                <YellowButton type="button" className="js-submit"
                  small={true}
                  onClick={this.showSubmitFinalModal}
                  data-cb-apricot='tooltip' id={this.toolTip.addToolTip(toolTipParms)}
                  data-cb-title={'Submit ' + helpers.fileName + ' as Final for Teacher Review'}>
                  Submit Final
                </YellowButton>
              }
              {helpers.needToConfirm &&
                <YellowButton type="button" className="js-submit"
                  small={true}
                  onClick={this.showSubmitFinalModal}
                  data-cb-apricot='tooltip' id={this.toolTip.addToolTip(toolTipParms)}
                  data-cb-title={'Confirm ' + helpers.fileName + ' as Final for Teacher Review'}>
                  Confirm Submission
                </YellowButton>
              }
              {helpers.submittedAt &&
                <strong className="cb-green1-color btn-sm-align">
                  <span className="cb-glyph cb-check" aria-hidden='true' />
                  <span style={{ paddingRight: '5px', fontWeight: '900', 'whiteSpace': 'nowrap' }}
                    aria-hidden="true">
                    Final Submitted {helpers.submittedAt}
                  </span>
                </strong>
              }
              {helpers.awaitingAttestations &&
                <strong className="cb-red1-color btn-sm-align">
                  Submit Final is partially complete. All team members must confirm submission.
                </strong>
              }
            </Fragment>
          }
        </div>
      </div>
    )
  }

  cancelSubmission() {
    this.props.parms.menuParms.scorableHolder.scorable.modifyScorableStatus(scorableStatus().DraftIn)
    .catch(xhr => {
      let parms = {
        title: 'Cancel Submission Failed',
        hideYesButton: true,
        noButtonText: 'Ok',
        modalId: 'cancelModal'
      }
      let modal = <ConfirmModal parms={parms}></ConfirmModal>
      reactRender(modal, 'region-modal', parms.modalId)
      alertGeneralErrorOnModal({ message: xhr.responseText, id: 'cancel-submission' }, modal)
      })
    .finally(() => {
      Assignment.trigger('wip:outdated')
    })
  }

  showCancelSubmissionModal() {
    let parms = {
      title: 'Cancel Submission',
      message: 'Are you sure you want to cancel this submission?',
      onConfirmYes: this.cancelSubmission,
      modalId: 'cancelSubmissionModal'
    }
    let modal = <ConfirmModal parms={parms}></ConfirmModal>
    reactRender(modal, 'region-modal', 'cancelSubmissionModal')
}

  async showFileUploadModal(e) {
    if (e && e.target) {
      sessionStorage.setItem( "selectedButtonId" , e.target.id)
    }
    let assignment = this.props.parms.menuParms.assignment
    let modalId = 'assignmentUploadModal'

    let parms = {
      url: new Upload(assignment.id).urlWithoutId(),
      assignment: assignment,
      fileReqs: {
        extensions: assignment.permittedFileExtensions,
        maxSize: assignment.permittedMaxFileSize,
        minSize: assignment.permittedMinFileSize
      },
      modalId: modalId
    }
    let modal = <UploadModalComp parms={parms} key={'uploadModal'}></UploadModalComp>
    reactRender(modal, 'region-modal', parms.modalId)
    focusOnFirst()
  }

  showSubmitFinalModal(e) {
    if (e && e.target) {
      sessionStorage.setItem("selectedButtonId" , e.target.id)
    }
    let assignment = this.props.parms.assignment
    let menuParms = this.props.parms.menuParms
    let lesson = menuParms.lesson
    var wipFilename = menuParms.upload.fileName
    var permittedFileExtensions = assignment.permittedFinalSubmissionFileExtensions
    var wipIsPermittedFileExtension = _.find(permittedFileExtensions, extension => {
      return wipFilename.toLowerCase().includes('.' + extension.toLowerCase())
    })

    if (wipIsPermittedFileExtension) {
      let parms = {
        // this url will be used by
        url: new Upload(assignment.id).urlWithoutId(),
        assignment: assignment,
        lesson: lesson,
        upload: menuParms.upload,
        scorableHolder: menuParms.scorableHolder,
        fileReqs: {
          extensions: assignment.permittedFileExtensions,
          maxSize: assignment.permittedMaxFileSize,
          minSize: assignment.permittedMinFileSize
        },
        modalId: 'finalUploadModal',
        modalOpen: true,
        onClose: () => {},
        isSeminar: isSeminar(),
        isAfricanAmericanStudies: isAfricanAmericanStudies()
      }
      let modal = <SubmitFinalModalComp props={parms} />
      reactRender(modal, 'region-modal', parms.modalId)
      focusOnFirst()

      //var module = parms.modules.getModuleByLessonId(parms.lesson.id)
      // dont have the moduleHolder object
      document.dispatchEvent(new CustomEvent('pagevisit', {detail: /*module.abbreviation + '/' + */parms.lesson.abbreviation + '/submit'}))
    } else {
      let parms = {
        modalId: 'pdfConversionModal'
      }
      let modal = <PDFConversionModal parms={parms}></PDFConversionModal>
      reactRender(modal, 'region-modal', parms.modalId)
    }
  }

  // hide the tooltip by focusing on something else
  hideTooltip() {
    let elems = document.getElementsByClassName("active active-trail")
    if (elems.length > 0) {
      elems[elems.length-1].focus()
      document.getElementById('region-assignment-menu').scrollIntoView()
    }
  }

  onCloseOriginality() {
    this.setState(state => {
      return {
        updateCnt: state.updateCnt++
      }
    })
  }

  runOriginality() {
    ////console.log('runOriginality')
    this.hideTooltip()
    runOriginality(this, this.props.parms.menuParms.upload, true, this.onCloseOriginality)
  }

  viewOriginality() {
    ////console.log('viewOriginality')
    this.hideTooltip()
    viewOriginality(this.props.parms.menuParms.upload, this.refs.formToSubmit)
  }

  async downloadLatest(e) {
    let uploadDownloadLink = this.props.parms.menuParms.upload.getDownloadUrl()
    await bsliGet(uploadDownloadLink).then(url => window.open(url.url, '_blank'))
    this.hideTooltip()
  }

  serializeData(menuParms) {
    //console.log('MenuBarComp serializeData menuParms',menuParms)
    let { assignment, upload, scorableHolder } = menuParms
    let scorable = scorableHolder.scorable

    let isStudent = getCurrentPerson().isStudent()
    let scoringEnabled = getCourseParameter('scoringEnabled')
    let afterHardEndDate = isAfterHardEndDate()
    let turnitinEnabled
    let isPastSoftDate

    let currentSection = getSection(getCurrentSectionId())
    let isArchived = currentSection.isArchived;
    if (menuParms.isWeLesson) {
      // refer to the course that is 'only WE', course 12
      turnitinEnabled = getWeCourseParameter('turnitinEnabled', false);
      isPastSoftDate =  currentSection.weCoursePastSoftEndDate ;
      afterHardEndDate = currentSection.weCoursePastHardEndDate;
    } else {
      turnitinEnabled = getCourseParameter('turnitinEnabled', false);
      isPastSoftDate = currentSection.pastSoftEndDate;
    }

    let courseReadOnly = isPastSoftDate ? !scorable.enrollmentHasExtension : false;
    let isAfterHardOrLateDate = hasLateSubmissionDate() ? isAfterLateSubmissionDate() : afterHardEndDate

    let showOriginalityCheck =
      turnitinEnabled &&
      assignment.isDocument() &&
      upload.exists() &&
      !upload.hasBeenSubmittedToTurnitin() &&
      !(menuParms.isCSP_WR && menuParms.isFinalSubmitted)
    let showSubmitFinal =
      isStudent &&
      scoringEnabled &&
      !isArchived &&
      upload.exists() &&
      assignment.allowsFinalSubmission() &&
      !courseReadOnly &&
      !isAfterHardOrLateDate
    let showUpload =
      isStudent &&
      assignment.isDocument() &&
      !isArchived &&
      scorableHolder.isUnsubmitted() &&
      !courseReadOnly &&
      !isAfterHardOrLateDate

    let data = {
      showUpload: showUpload,
      showDownload: assignment.isDocument() && upload.exists(),
      fileName: upload.fileName,
      downloadUrl: upload.getDownloadUrl(),
      rubricUrl: menuParms.rubricUrl,
      showOriginalityCheck: showOriginalityCheck,
      showViewReport: turnitinEnabled && upload.hasBeenSubmittedToTurnitin(),
      showSubmitFinal: showSubmitFinal,
      unsubmitted: scorableHolder.isUnsubmitted(),
      needToConfirm: scorableHolder.isAttestationIncomplete() && !scorableHolder.hasBeenSubmittedByPerson() && !courseReadOnly && !afterHardEndDate,
      canCancel: isStudent && scorableHolder.isAttestationIncomplete(),
      awaitingAttestations: scorableHolder.isAttestationIncomplete(),
      submittedAt: scorable.submittedAt ? moment(new Date(scorable.submittedAt)).format('MM/DD') : null
    };
    return data
    // not sure what advantage returning false would have
    //return data.showUpload || data.showDownload || data.rubricUrl || data.showOriginalityCheck || data.showSubmitFinal ? data : false;
  }

  async refreshUploads () {
     let result = await uploadAndScorable(this.props.parms.assignment.id, this.props.parms.personId)
    if (result) {
      // console.log (' RESULT ' , result)
      this.props.parms.menuParms.upload = result[0]
      this.props.parms.menuParms.scorableHolder = result[1]
    }
    this.setState(state => {
      return {
        updateCnt: state.updateCnt++
      }
    })
  }
}
