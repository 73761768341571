import { RightMenuComp } from "./rightMenu";
import { getCurrentPerson } from "../../entities/person.jsx";
import { amOnACourseSpecificPage } from "../../entities/course";
import {
    getCourseInstanceBySectionId,
    getCourseInstanceId,
    getCourseInstances,
    getCurrentCourseInstance,
    getCurrentOrg,
    getCurrentSection,
    getSection,
    saveToCache
} from "../../entities/courseinstance";
import { haveWeSections } from "../../progress/entities/progress";
import { showCourseProgress } from "../../progress/progress_module";
import { subject, updateSubscription } from "../../utility/service";
import { Link, useNavigate as navigate } from "react-router-dom";
import CBLocalNavigation from "@cb/apricot/CBLocalNavigation";
import { LeftMenu } from "./leftMenu";

export const TopMenu = ({ props }) => {
    const [org, setOrg] = useState(getCurrentOrg())
    const [refreshApricotNav, setRefreshApricotNav] = useState(true)
    const [apricotNav, setApricotNav] = useState(null)

    let subscription = subject.subscribe(message => {
        if (message.orgChanged) {
            setRefreshApricotNav(true)
            setOrg(message.orgChanged.org)
        }
    })
    updateSubscription('orgChanged', subscription)

    useEffect(() => {
        try {
            // the only time we create local navigation is when first loading and when the org changes
            if (refreshApricotNav) {
                let elem = document.querySelector('#dp-menu1.cb-local-navigation')
                if (elem) {
                    if (apricotNav) {
                        //console.log('TopMenuOneComp going to destroy local navigation')
                        setRefreshApricotNav(false)
                        apricotNav.destroy()
                    }
                    //console.log('TopMenuOneComp useEffect calling CBLocalNavigation')
                    setRefreshApricotNav(false)
                    setApricotNav(CBLocalNavigation({
                        elem: elem,
                        mobileInteractionClose: true,
                        sticky: false
                    }))
                } else {
                    console.error('TopMenuOneComp could not access .cb-local-navigation')
                }
            } else {
                //console.log('TopMenuOneComp its not time to refresh local navigation')
            }

            // update the help url every time
            let helpElem = document.querySelector('.cb-mobile-navigation ' + "[data='help']")
            if (helpElem) {
                //console.log('TopMenuOneComp changing href to ' + helpers.helpURL)
                helpElem.setAttribute('href', helpers.helpURL)
            } else {
                console.error('TopMenuOneComp could not find helpElem')
            }
        } catch (e) {
            console.error('TopMenuOneComp had trouble with useEffect', e)
        }
    })

    let helpers = templateHelpers(props, org)

    return (
        <div>
            <div id='dp-menu1' className="cb-local-navigation cb-white-bg cb-box-shadow-bottom" data-cb-apricot="local-navigation">
                <div className="cb-desktop-navigation container">
                    <div className='row'>
                        <nav className="col-xs cb-nav-container cb-no-padding" aria-label="Navigation for Digital Portfolio">
                            <div className="cb-site-name">
                                <Link to='/'>AP Digital Portfolio</Link>
                            </div>
                            <div className="cb-nav-items hidden-xs-only">
                                <RightMenuComp helpers={helpers} />
                            </div>
                            <div className="cb-mobile-trigger hidden-sm-up">
                                <Link to="#" className="cb-glyph cb-menu cb-no-text-decoration cb-link-black"
                                   aria-expanded="false" aria-hidden="true" role='button'>
                                    <span className="sr-only">Display Site Navigation</span>
                                </Link>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="cb-mobile-navigation"></div>
            </div>
        </div>
    )
}

export const BottomMenu = ({ props }) => {
    let courseInstances = getCourseInstances()
    const [org, setOrg] = useState(getCurrentOrg())
    let orgs = courseInstances.getOrgs();
    let helpers = templateHelpers(props)

    function templateHelpers(parms) {
        let person = getCurrentPerson()
        return {
            currentAcademicYear: parms.currentAcademicYear,
            isTeacherOrCoordinator: person.isTeacher() || person.isCoordinator(),
        }
    }

    function changeOrg(e) {
        let orgId = e.getAttribute('data-org')
        let org = orgs.find(org => org.orgId === orgId)
        getCurrentOrg(org)
        saveToCache()
        setOrg(org)

        let coordinatorProgress = /courses\/(\d*)\/progress\/(\d*)/.exec(window.location.href)
        if (coordinatorProgress && coordinatorProgress.length > 2) {
            let nextWe = coordinatorProgress[2]
            if (nextWe === '1') {
                if (!haveWeSections()) {
                    // navigate to '/0' since WE course for the new org does not have any sections
                    let url = window.location.href
                    let start = url.indexOf('/') + 2
                    url = url.substr(start, url.length - start - 1) + '0'
                    navigate(url)
                    nextWe = 0
                }
            }
            ////console.log('progress:course:show running')
            showCourseProgress(coordinatorProgress[1], nextWe)
        } else {
            props.changeOrg(org)
        }
    }

    return (
        <div id='region-top-menu2'>
            {helpers.isTeacherOrCoordinator &&
                <>
                    <div id='dp-menu2' className='container'>
                        <div className="row" style={{ marginTop: '12px' }}>
                            <LeftMenu props={{ helpers, org, orgs, changeOrg }} />
                        </div>
                    </div>
                    <hr />
                </>
            }
        </div>
    )
}

export const MenuTitle = ({ nonCourseTitle, section }) => {
    let isCoordinator = getCurrentPerson().isCoordinator()

    let currentCourse = getCourseInstanceBySectionId(section?.id) || getCurrentCourseInstance()
    let courseTitle = currentCourse && currentCourse.title.replace('AP ', '')
    let isCurrentCourseArchived = currentCourse && !currentCourse.active
    let academicYear = currentCourse && currentCourse.academicYear

    let currentSection = getSection(section?.id) // could be null
    let sectionTitle = currentSection && currentSection.fullTitle

    // the margin 0 below is needed to prevent too much margin, I don't understand the mechanism
    return (
        <>
            <div className="cb-gray5-bg">
                <div className="container"
                     style={{ 'paddingTop': '10px', 'paddingBottom': '10px', 'marginTop': '10px' }}>
                    {nonCourseTitle &&
                        <h1 className="cb-h3 even-spaced" style={{ 'margin': '0', letterSpacing:'0'}}>
                            {nonCourseTitle}
                        </h1>
                    }
                    {!nonCourseTitle &&
                        <h1 className="cb-h3 subtitled" style={{ 'margin': '0', letterSpacing:'0'}}>
                            <span className="logo">
                                <span className="sr-only">AP</span>
                            </span>
                            {courseTitle}<br />
                            <small>
                                {!isCoordinator && sectionTitle &&
                                    <span>{sectionTitle}</span>
                                }
                                {isCurrentCourseArchived &&
                                    <span>
                                        <em className="bulleted">Archived</em>
                                        ({academicYear})
                                    </span>
                                }
                            </small>
                        </h1>
                    }
                </div>
            </div>
        </>
    )
}

export const templateHelpers = (parms, org) => {
    let courseInstances = getCourseInstances()
    let person = getCurrentPerson()
    let activeCourses = courseInstances?.getCourses(org, true)
    let courses = addURLSuffixParameter(parms, activeCourses)
    let helpURL = (parms.enrollment ? '/enrollment/' : '/instruction/') +
        (amOnACourseSpecificPage()
            ? `courses/${getCourseInstanceId()}/` : '') +
        'help';

    return {
        courseInstances: courses,
        showCourseNameOnly: !person.isTeacher() ||
            (activeCourses.length === 1 && activeCourses[0].sections.length === 1),
        hasArchivedCourses: courseInstances.hasArchivedCourses(),
        helpURL: (!parms.disabled || parms.enrollment) && helpURL,
        enabled: !parms.disabled,
        isCoordinator: person.isCoordinator(),
        isTeacherOrCoordinator: person.isTeacher() || person.isCoordinator(),
        isStudent: person.isStudent()
    }
}

// computes the section url suffix for all the classes under 'My Classes'
const addURLSuffixParameter = (parms, courses) => {
    let currentSection = getCurrentSection() // could be null
    return courses?.map(course => {
        // set default suffix
        course.sectionsOfOrg?.forEach(section => section.suffix = 'overview')

        let weLessons = parms?.modules ? parms.modules.getWeLessonIds() : []

        let isSameCourse = currentSection &&
            currentSection.courseInstanceId === course.id

        if (isSameCourse) {
            let groups = /sections\/(\d+)\/(.*)/.exec(location.hash)
            let suffix = groups && groups.length > 2 ? groups[2] : null
            if (suffix) {
                let arr = /lessons\/(\d+)/.exec(suffix)
                let lesson = arr && arr.length > 1 ? arr[1] : null
                if (lesson) {
                    course.sectionsOfOrg?.forEach(section => isCompatible(section, lesson, weLessons) ? section.suffix = suffix : null)
                }
            }
        }
        return course
    })
}

const isCompatible = (section, lesson, weLessons) => {
    // lesson is the current lesson ofthe url
    // section is of the same course as the lesson
    // question is: does the section have the lesson
    // the answer is yes execpt for the case of WE lessons, not all sections
    // have WE sections

    // module/lesson related to WE Service are not compatible with
    // DP only section (courseType 1)
    if (section.courseType === 1) {
        return !_.contains(weLessons, lesson)
    } else {
        return true
    }
}
