import React, { Fragment }      from "react";
import { subject, updateSubscription }    from "../../utility/service";
import { isArtSI, isArt3D , isArt_SW_Images, isArtSI_Images}     from '../../entities/courseinstance'
import { AlertComp }                      from "../../_core/views/alert.jsx";
import { parse }                          from "../../assignment/art/entities/downloadArt";
import Viewer from "./viewer";
import ImageGalleryTeacher from "../../assignment/art/views/galleryTeacher";

const SubmissionLayout = ({ parms }) => {
  const [alertProps, setAlertProps] = useState(false)
  let _isMounted = false

  const cmsLesson = parms.cmsLesson;
  const guidelines = cmsLesson ? cmsLesson.scoringGuidelines : false;
  const isArtSWImages =  isArt_SW_Images (parms.lesson.id )
  const isArtSIImages =  isArtSI_Images(parms.lesson.id)
  const isImages  = isArtSWImages || isArtSIImages
  const showViewer = parms.upload && parms.upload.isPdf() && !isImages;
  const showGallery = parms.upload && (parms.lesson.title === "Images" || parms.lesson.title.includes("Works"));
  const compData = parse(parms.data);
  const galleryId = parms.upload ? parms.upload.id : 0;
  const sectionId = parms.scorableHolder.sectionId;
  //uploadId is not used if not showing gallery
  const uploadId = parms.upload ? parms.upload.id : 0
  const assignmentId = parms.lesson.assignmentId;
  const isArt_SI_Images = showGallery && isArtSI(assignmentId)
  const is3DArt = isArt3D();

  const viewerSelectedCls = showViewer ? " cb-selected" : "";
  const guideSelectedCls = showViewer ? "" : " cb-selected";
  const gallerySelectedCls = showViewer ? "" : " cb-selected";

  useEffect(() => {
    _isMounted = true;
    window.cb.apricot.CBTab({
      elem: document.querySelector(".cb-tabs-menu"),
    });
    checkGuidelines(parms.cmsLesson, parms.upload && parms.upload.isPdf(), isImages);

    const subscription = subject.subscribe((message) => {
      if (message.alert) {
        showAlert(message.alert.message, message.alert.id);
      }
    });
    updateSubscription("alert", subscription);

    return () => {
      _isMounted = false;
      updateSubscription("alert", null);
    }
  }, [])

  const showAlert = (message, uniqueId) => {
    setAlertProps({
      isDismissable: false,
      showAlert: true,
      message,
      uniqueId,
    });
  }

  const checkGuidelines = (cmsLesson, isPdfSubmission, isImages) => {
    if (!cmsLesson && !isPdfSubmission && !isImages) {
      showAlert("Error: Content not available.", "guidelines-missing");
    }
  }

  const arrowKeyAccessibleTabs = (e) => {
    if (e.keyCode === 39 || e.keyCode === 37) {
      e.preventDefault();

      const $tab = $(e.currentTarget).parent();
      const menuLen = $tab.closest(".nav").children().length;
      const index = $tab.index();

      if (e.keyCode == 39 && index !== menuLen - 1)
        //right arrow
        this.ui
          .navItems()
          .get(index + 1)
          .focus();
      else if (e.keyCode === 37 && index !== 0)
        //left arrow
        this.ui
          .navItems()
          .get(index - 1)
          .focus();
    }
  }

  return (
    <Fragment>
      <div
        id="submissionLayout"
        className="cb-tabs-menu"
        data-cb-apricot="tabs-menu">

        <ul role="tablist">
          {showViewer && (
            <li role="presentation" className="js-submission-tab">
              <a
                href="#region-view-submission"
                className={"cb-menu-link" + viewerSelectedCls}
                role="tab"
                aria-controls="region-view-submission"
                aria-labelledby="region-view-submission"
                onKeyDown={arrowKeyAccessibleTabs}
              >
                <span>View Submission</span>
              </a>
            </li>
          )}
          {guidelines && (
            <li role="presentation" className="js-guidelines-tab">
              <a
                href="#region-guidelines"
                className={"cb-menu-link" + guideSelectedCls}
                role="tab"
                aria-controls="region-guidelines"
                aria-labelledby="region-guidelines"
                onKeyDown={arrowKeyAccessibleTabs}
              >
                <span>Guidelines and Samples</span>
              </a>
            </li>
          )}
          {showGallery && (
            <li role="presentation" className="js-guidelines-tab">
              <a
                href="#region-guidelines"
                className={"cb-menu-link" + gallerySelectedCls}
                role="tab"
                aria-controls="region-view-gallery"
                aria-labelledby="region-view-gallery"
                onKeyDown={arrowKeyAccessibleTabs}
              >
                <span>View Gallery</span>
              </a>
            </li>
          )}
        </ul>
      </div>
      <div className="cb-tabs-container">
        {showViewer && (
          <div
            className={"cb-tabs-content" + viewerSelectedCls}
            role="tabpanel"
            id="region-view-submission"
          >
            <Viewer {...{upload: parms.upload}} />
          </div>
        )}
        {guidelines && (
          <div
            className={"cb-tabs-content cb-black1-color" + guideSelectedCls}
            role="tabpanel"
            id="region-guidelines"
          >
            <div dangerouslySetInnerHTML={{ __html: guidelines }} />
          </div>
        )}
        {showGallery && (
          <div
            className={"cb-tabs-content" + gallerySelectedCls}
            role="tabpanel"
            id="region-view-gallery"
          >
            <div id={"gallery" + galleryId} className="cb-margin-top-24">
              <ImageGalleryTeacher
                galleryId="gallerySubmission"
                sectionId={sectionId}
                assignmentId={assignmentId}
                uploadId={uploadId}
                isArt_SI_Images={isArt_SI_Images}
                is3DArt = {is3DArt}
              />
            </div>
          </div>
        )}
      </div>
      <div className="alert-wrapper container-fluid add-top-margin-2x">
        {alertProps && alertProps.showAlert && (
            <AlertComp params={alertProps}></AlertComp>
          )}
      </div>
    </Fragment>
  );
}

export default SubmissionLayout
