import React, { Fragment } from 'react';
import { SquareButton } from '@cb/apricot-react';

import FileUploadS3Modal from '../../_core/views/fileUploadS3Modal'
import { removeFakepath } from '../../utility/util' 

// formerly Assignment.UploadModal, used only by MenuBarComp
export default class UploadModalComp extends FileUploadS3Modal {
    constructor(props) {
        super(props);
        // props.parms has url, assignment, fileReqs, modalId
        // fileReqs has extensions, maxSize, minSize  

        _.bindAll(this, 'fileChange', 'loadMetadata','onClick')
        this.helpers = this.serializeData()
        this.state = {
            alertProps: false, // baseModal shows the alert
            submit: this.SUBMIT_REGULAR(),
            submitEnabled: false
        }
        this.fileFacts = {
            file: false,
            isVideo: false,
            first: true,
            duration: 0
        }
        // avi, mpg are excluded since it is not supported by <video> , duration validated in upload_lambda 
        this.VALID_VIDEO_EXTENSIONS = ['wav',  'wmv', 'm4a',  'mov', 'm4v', 'mp4', 'mp3', 'aif', 'webm']; 
    }

    componentDidMount() {
        //console.log('UploadModalComp componentDidMount')
        const uploadBtn = document.getElementById('file-select')
        window.cb.apricot.CBForm.fileUpload({
            elem: uploadBtn
        })
        // custom event when selected file has been removed
        uploadBtn.addEventListener('apricot_fileRemoved', (e) => {
            //console.log('File Removed')
            this.fileRemove()
        })
        uploadBtn.addEventListener('apricot_fileSelected', (e) => {
          //console.log('File Added')
        })
        window.addEventListener('resize', () => { 
            removeFakepath(this.props.parms.modalId) 
            setTimeout(() => {
                removeFakepath(this.props.parms.modalId) 
            },0)
        });
      }
    
    componentDidUpdate() {
        //console.log('UploadModalComp componentDidUpdate')
    }

    getHeader() {
        return (
            <React.Fragment>
                <h2 className="cb-h5 cb-modal-title" id="modalTitle">Upload File</h2>
                <SquareButton small='true' icon='x-mark' onClick={this.defaultClose} 
                    className='cb-btn-black cb-btn-close' data-cb-modal-close>
                    <span className="sr-only">Close Window</span>
                </SquareButton>
            </React.Fragment>
        )
    }
    getContent() {
        let file = this.fileFacts.file
        let isVideo = file && this.fileFacts.isVideo
        let src = isVideo ? URL.createObjectURL(file) : ''
        return (
            //key={this.state.refreshCnt}
            <React.Fragment>
                <video controls={true} className='hidden' preload='metadata' id="video"
                 onLoadedMetadata={e => {
                        if  (!this.stopEvent) {
                          this.isMetadataLoaded = true;
                          this.loadMetadata(e.target.duration)
                        }
                    }}>
                </video>
                <div id='file-upload' className='cb-file-upload' data-cb-apricot='file-upload'>
                    <input type='file' id='file-select'
                        accept={this.helpers.acceptExtensions} aria-labelledby="uploadFileLimitations"
                        onChange={this.fileChange} />
                    <span className="sr-only" id="uploadFileLimitations">
                        Document needs to be one of the follow file types:
                        {this.helpers.fileExtensions}, with a size between {this.helpers.fileMinSize} and {this.helpers.fileMaxSize}.
                    </span>
                </div>
            </React.Fragment>
        )
    }
    getFooter() {
        let submitCls = this.state.submitEnabled ? '' : 'cb-disabled'
        let isLoading = this.state.submit === this.SUBMIT_LOADING()
        return (
            <React.Fragment>
                <button type="button" className="cb-btn cb-btn-naked cb-no-padding cb-btn-close"
                    onClick={this.onClick} data-cb-modal-close>
                    Cancel
                </button>
                {!isLoading &&
                    <button type="button" 
                        className={'cb-btn cb-btn-sm cb-btn-yellow btn-sm btn-primary js-submit ' + submitCls}
                        onClick={this.uploadToS3}>
                        Submit
                </button>
                }
                {isLoading &&
                    <button className="cb-btn cb-btn-sm cb-btn-pulse" type="button">
                        Default
                        <div className="cb-btn-pulse-dots">
                            <div className="cb-btn-dots"></div>
                        </div>
                    </button>
                }
            </React.Fragment>
        )
    }

    fileChange(e) {
        //console.log('upload modal fileChange', e)
        this.removeErrorMessage();
        this.enableSubmit(false)
        let fileList = e.target.files
        if (fileList.length === 0) {
            //console.log('fileChange NO FILE')
        } else {
            //console.log('fileChange FILE ' + fileList[0].name)
            let file = fileList[0]
            let fileExtension = file.name.split('.').pop().toLowerCase();
            let isVideoAllowed = this.props.parms.fileReqs.extensions.includes('mov')
            let isVideo = isVideoAllowed &&  this.VALID_VIDEO_EXTENSIONS.includes(fileExtension);     
            this.fileFacts = {
                file: file,
                isVideo: isVideo,
                first: true,
                duration: 0    
            }
            if (!isVideo ) { // if video, after metadata loads, we call this method
                this.validateAndGetUploadMetaData()
            } else {
                this.isMetadataLoaded = false;
                this.stopEvent = false;
                let video = document.getElementById("video");
                video.src = URL.createObjectURL(file); 
                setTimeout( () =>  { 
                     if (!this.isMetadataLoaded)  {
                        this.stopEvent=true;
                        this.validateAndGetUploadMetaData()
                     }
                }, 500)
            }
        }
    }

    loadMetadata(duration) {
        //console.log('loadMetadata duration' + duration)
        this.fileFacts.duration = duration
        if (this.fileFacts.first) {
            this.fileFacts.first = false // prevent an infinite loop
            this.validateAndGetUploadMetaData();
        } else {
            //console.log('loadMetadata, we already saw this file')
        }
    }

    onClick() {
        this.close(false)
      }
}