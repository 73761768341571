import React, { Fragment, useState, useEffect } from 'react';
import { getCurrentPerson } from '../../entities/person.jsx'
import { getMessages } from '../../entities/message'
import { getModuleHolder } from '../../entities/modules'
import { MessageComp } from './message'
import Name from './name'
import DPToolTip from '../../utility/tooltip'
import { subject } from '../../utility/service'

const ActivityFeedComp = (props) => {
  const [defaultVisible, setDefaultVisible] = useState(5);
  const toolTip = new DPToolTip('activityFeed');
  const [isTeacher, setIsTeacher] = useState(getCurrentPerson().isTeacher());
  const [messageHolder, setMessageHolder] = useState(null);
  const [ariaExpanded, setAriaExpanded] = useState(null);
  const [title, setTitle] = useState('Show more messages');
  const [text, setText] = useState('Show more');
  const getMessageParms = async (assignment, personId) => {
    var messageHolder = await getMessages(assignment.id, personId || getCurrentPerson().id);
    let moduleHolder = await getModuleHolder()

    let first = true
    let regex = /.\s+Reason:/
    messageHolder.messages = messageHolder?.messages?.map( message => {
      if (assignment.isCSP_WR(moduleHolder)) {
        if (message.type == '1') {
          message.message = message.message.replace('Uploaded', 'Saved')
          if (!first) {
            message.upload = null
            message.message = message.message.replace(':', '')
          }
          first = false
        }
      }
      message.message = message.message.replace(regex, '.')
      return message
    })
    return messageHolder
  }

  const refreshMessageAfterLoad = async () => {
    const messageHolder = await getMessageParms(props.parms.assignment, props.parms.personId)
    setMessageHolder(messageHolder)
  }

  useEffect(() => {
    subject.subscribe(async (message) => {
      if (message.refreshActivityFeed) {
       const messageHolder = await getMessageParms(props.parms.assignment, props.parms.personId)
       setMessageHolder(messageHolder)
      }
    })
    refreshMessageAfterLoad()
    toolTip.createTooltips()
  }, [])

  const serializeData = () => {
    return { isTeacher: getCurrentPerson().isTeacher() }
  }
  let assignment = props.parms.assignment
  let person = getCurrentPerson()
  toolTip.removeTooltips()

  let isArtAssignment = props.parms.menuParms.isArt_SI_Images || props.parms.menuParms.isArt_SW_Images
  let isWrittenEvidenceAssignment = props.parms.menuParms.isArt_WrittenEvidence
  let sectionId = props.parms.menuParms.scorableHolder.sectionId
  let assignmentId = props.parms.menuParms.scorableHolder.assignmentId
  let uploadId = props.parms.menuParms.scorableHolder.scorable.uploadId

  const galleryLink = (message) => {
    return <button data-cb-title={'View Gallery'}
      data-testid={'View Gallery'}
      className='cb-btn cb-btn-sm cb-btn-naked cb-btn-blue js-download-file'
      data-cb-apricot='tooltip' id={props.parms.menuParms.scorableHolder.scorable.uploadId}
      onClick={() => props.showGallery(sectionId, assignmentId, uploadId, message, props.title)}>
      View Gallery
    </button>
  }

  const weMessage = (message, index) => {
    return <div tabIndex='-1' className='media' key={'artMessage_' + index}>
      <p>
        <Name firstName={message.firstName} lastName={message.lastName} prefFirstName={message.prefFirstName} /> |
        <span className="text-muted">{moment(message.timestamp).format('ddd MMMM DD, YYYY [at] h:mmA')}</span>
      </p>
      <p>
        {message.message.replace('Uploaded', 'Saved').replace(':', '')}
        {message.rejectionReason &&
          <span style={{ display: 'block' }}>Reason: {message.rejectionReason}</span>
        }
      </p>
    </div>
  }

  const artMessage = (message, index) => {
    if (index === 0 && isTeacher) {
      return <div tabIndex='-1' className='media' key={'artMessage_' + index}>
        <p>
          <Name firstName={message.firstName} lastName={message.lastName} prefFirstName={message.prefFirstName} /> |
          <span className="text-muted">{moment(message.timestamp).format('ddd MMMM DD, YYYY [at] h:mmA')}</span>
        </p>
        <p>
          {message.message.replace('Uploaded', 'Saved').replace(':', '')}
          {message.rejectionReason ?
            <span style={{ display: 'block' }}>Reason: {message.rejectionReason}
              {props.showGallery && galleryLink(message)}
            </span> :
            galleryLink(message)
          }
        </p>
      </div>
    } else {
      return <div tabIndex='-1' className='media' key={'artMessage_' + index}>
        <p>
          <Name firstName={message.firstName} lastName={message.lastName} prefFirstName={message.prefFirstName} /> |
          <span className="text-muted">{moment(message.timestamp).format('ddd MMMM DD, YYYY [at] h:mmA')}</span>
        </p>
        <p>
          {message.message.replace('Uploaded', 'Saved').replace(':', '')}
          {message.rejectionReason &&
            <span style={{ display: 'block' }}>Reason: {message.rejectionReason}</span>
          }
        </p>
      </div>
    }
  }

  let list = () => {
    let showLess = !ariaExpanded
    let isLinkForWEDisplayed = false;
    if (messageHolder && messageHolder.messages) {
      return messageHolder?.messages?.map((message, index) => {
        if (showLess && index >= defaultVisible) {
          return null
        } else {
          let hasFocus = false
          if (ariaExpanded !== null) {
            hasFocus = showLess && index == 0 || !showLess && index == defaultVisible
          }
          let key = 'message_' + assignment.id + '_' + index
          if (!isArtAssignment && !isWrittenEvidenceAssignment) {
            return <MessageComp message={message} assignment={assignment} toolKey={key} key={key} hasFocus={hasFocus} />
          } else if (isWrittenEvidenceAssignment) {
            if (!isLinkForWEDisplayed && (message.message.startsWith('Uploaded') || message.message.startsWith('Saved'))) {
              isLinkForWEDisplayed = true;
              message.message = message.message.replace('Uploaded', 'Saved');
              return <MessageComp message={message} assignment={assignment} toolKey={key} key={key} hasFocus={hasFocus} />
            } else {
              return weMessage(message, index);
            }
          }
          else {
            return artMessage(message, index);
          }
        }
      })
    } else {
      return null
    }
  }

  const toggleComments = (e) => {
    // toggle expanded state
    if (ariaExpanded) {
      setAriaExpanded(false);
      setTitle('Show more messages')
      setText('Show more');

    } else {
      setAriaExpanded(true);
      setTitle('Show less messages')
      setText('Show less');
    }
  }

  let show = messageHolder && (messageHolder.messages.length > 0)
  let showButton = show && (messageHolder.messages.length > defaultVisible)
  let cls = isTeacher ? 'h3' : ''
  let srMsg = ''
  if (isTeacher && messageHolder && messageHolder.messages.length > 0) {
    let message = messageHolder.messages[0]
    srMsg = ' For ' + message.firstName + ' ' + message.lastName
  } else {
    srMsg = ' For ' + person.fullName()
  }

  return (<div id={'messages_' + props.parms.assignment.id}>
      {show &&
        <Fragment>
          <h5 className={cls}>
            <span>Activity Feed</span>
            <span className='sr-only'>{srMsg}</span>
          </h5>
          <div className="media-container">
            {list()}
          </div>
          {showButton &&
            <button className='cb-btn cb-btn-sm cb-btn-naked cb-btn-blue' onClick={(e) => toggleComments(e)}
              data-cb-apricot='tooltip' id={toolTip.addToolTip()}
              data-cb-title={title}
              aria-expanded={ariaExpanded}>
              {text}
            </button>
          }
        </Fragment>
      }
    </div>)
}

export default ActivityFeedComp;