import React, { Fragment } from 'react'
import { getCourseInstanceId, getCourseParameter, isArt, isCSP, isResearch, isSeminar } from '../../entities/courseinstance'

const SubmitFinalActionComp = ({ lesson, checkboxChange }) => {
	const templateHelpers = () => {
		let title = lesson && lesson.title
		let isAcademicPaper = title && /\.*Academic\s*P/.test(title)
		return {
			csp: /^42/.test(getCourseInstanceId()),
			sem: /^40/.test(getCourseInstanceId()),
			res: /^41/.test(getCourseInstanceId()),
			artDrawing: /^34/.test(getCourseInstanceId()),
			art2D: /^32/.test(getCourseInstanceId()),
			art3D: /^33/.test(getCourseInstanceId()),
			isAcademicPaper: isAcademicPaper
		}
	}

	let helpers = templateHelpers()
	let confirmScorables = getCourseParameter('confirmScorables')
	let art = isArt()
	let nonArt = isCSP() || isResearch() || isSeminar()
	let chatGPTText = art
		? 'I did not use any Artificial Intelligence tools (e.g., ChatGPT or DALL-E) or essay writing services (e.g., Chegg or Course Hero) to guide, brainstorm, draft, or create any aspect of my AP Art and Design portfolio.'
		: nonArt
			? 'I did not use any Artificial Intelligence tools (e.g., ChatGPT or DALL-E) or essay writing services (e.g., Chegg or Course Hero) to guide, brainstorm, draft, or create any aspect of my performance task assessment.'
			: ''

	useEffect(() => {
		document.querySelectorAll('.cb-checkbox').forEach((elem) => {
			window.cb.apricot.CBForm.customFormElement({
				elem: elem.querySelector('input')
			})
		})
		document.getElementById('attestCheckbox4').focus()
	}, [])

	return (
		<Fragment>
			<div className="cb-checkbox">
				<label htmlFor="attestCheckbox4">
					<input type="checkbox" id="attestCheckbox4" className="js-attest" onChange={checkboxChange} />
					<span className='cb-margin-left-8'>I agree to the Digital Portfolio AP Exam Terms and Conditions available at&nbsp;
						<a
							href="https://apstudents.collegeboard.org/ap/pdf/ap-digital-portfolio-terms-and-conditions.pdf"
							target="_blank"
							title="Digital Portfolio Exam Terms and Conditions" rel="noreferrer">
                    cb.org/dpexamterms
						</a>
                  . I understand that there are serious consequences for violating these Terms and Conditions.
					</span>
				</label>
			</div>

			{helpers.csp &&
        <Fragment>

        	<div id='finalAction' className="cb-checkbox">
        		<label htmlFor="attestCheckbox1">
        			<input type="checkbox" id="attestCheckbox1" className="js-attest" onChange={checkboxChange} />
        			<span className='cb-margin-left-8'> 
					The work submitted is my own and any work developed with peers or generative AI tools is properly attributed, and that they have read and understood the&nbsp;
        				<a
        					if-course-parameter="confirmScorables"
        					href="https://apcentral.collegeboard.org/courses/ap-computer-science-principles/course/frequently-asked-questions/what-policy-plagiarism"
        					target="_blank"
        					title="AP Computer Science Principles Policy on Academic Integrity and Plagiarism. (opens in a new window)" rel="noreferrer">
                  AP Computer Science Principles Policy on Academic Integrity and Plagiarism
        				</a>.
        			</span>
        		</label>
        	</div>
        	<div className="cb-checkbox">
        		<label htmlFor="attestCheckbox2">
        			<input type="checkbox" id="attestCheckbox2" className="js-attest" onChange={checkboxChange} />
        			<span>
                I hereby affirm that the work I am submitting is my own and that I have read the&nbsp;
        				<a
        					href="https://apcentral.collegeboard.org/pdf/ap-csp-student-task-directions.pdf?course=ap-computer-science-principles"
        					target="_blank"
        					title="Student Handout (opens in a new window)" rel="noreferrer">
                  Student Handouts
        				</a>.
        			</span>
        		</label>
        	</div>
        	<div className="cb-checkbox">
        		<label htmlFor="attestCheckbox3">
        			<input type="checkbox" id="attestCheckbox3" className="js-attest" onChange={checkboxChange} />
        			<span>I hereby affirm that:<br />
        				<ul>
        					<li>I did not collaborate on the individual video or the creation of Personalized Project Reference for the performance task.</li>
        					<li>My Personalized Project Reference does not contain comments. It only includes program code used in my program.</li>
        				</ul>
        			</span>
        		</label>
        	</div>
        </Fragment>
			}
			{(helpers.res || helpers.sem) &&
        <Fragment>

        	<div className="cb-checkbox">
        		<label htmlFor="attestCheckbox5">
        			<input type="checkbox" id="attestCheckbox5" className="js-attest" onChange={checkboxChange} />
        			<span className='cb-margin-left-8'> 
					I hereby affirm that the work I am submitting is my own and I have read and understand the         				{!confirmScorables &&
                  <React.Fragment>
                    &nbsp;
                  	<a
                  		href="https://apstudent.collegeboard.org/takingtheexam/exam-policies/ap-capstone-policies"
                  		target="_blank" title="AP Capstone Policies (opens in a new window)" rel="noreferrer">
                    AP Capstone policy on Plagiarism and Falsification and Fabrication of Information
                  	</a>
                  </React.Fragment>
        				}.
        			</span>
        		</label>
        	</div>
			<div className="cb-checkbox">
        		<label htmlFor="attestCheckbox6">
        			<input type="checkbox" id="attestCheckbox6" className="js-attest" onChange={checkboxChange} />
        			<span >
					I hereby affirm that the work I am submitting is my own and I have read and understand the 
					&nbsp;
					{!confirmScorables &&
                  	<a
                  		href="https://apcentral.collegeboard.org/exam-administration-ordering-scores/administering-exams/preparing-for-exam-day/exam-security/artificial-intelligence-tools"
                  		target="_blank" title=" Use of Generative AI" rel="noreferrer">
                      Use of Generative AI
                  	</a>
        				}.
        			</span>
        		</label>
        	</div>
        	<div className="cb-checkbox">
        		<label htmlFor="attestCheckbox7">
        			<input type="checkbox" id="attestCheckbox7" className="js-attest" onChange={checkboxChange} />
        			<span>
					I hereby affirm that I have read the overview and directions for the AP Capstone Performance Tasks. 
        			</span>
        		</label>
        	</div>
        	<p if-course-parameter="turnitinEnabled" style={{marginTop: '10px'}}>
            If you wish to run an originality report, please close the submission and click the <strong>Run Originality</strong> button.
        	</p>
        </Fragment>
			}

			{(helpers.artDrawing || helpers.art2D ||  helpers.art3D) &&
        <Fragment>
        	<div id='finalAction' className="cb-checkbox">
        		<label htmlFor="attestCheckbox8">
        			<input type="checkbox" id="attestCheckbox8" className="js-attest" onChange={checkboxChange} />
        			<span className='cb-margin-left-8'>
					 I agree to comply with the AP Art and Design &nbsp;
        				<a
        					if-course-parameter="confirmScorables"
        					href="https://apstudents.collegeboard.org/art-design-program/ap-art-design-portfolio-policies"
        					target="_blank"
        					title="Artistic Integrity Agreement" rel="noreferrer">
                    Artistic Integrity Agreement. 
        				</a> 
						The works of art I am submitting are my original creations and I did not use artificial intelligence at any stage of the creative process. 
        			</span>
        		</label>
        	</div>
        </Fragment>
			}
		</Fragment>
	)
}

SubmitFinalActionComp.propTypes = {
	lesson: PropTypes.object.isRequired,
	checkboxChange: PropTypes.func.isRequired
}

export default SubmitFinalActionComp