import React from 'react';
import { Select} from '@cb/apricot-react';

export function SelectComp({ type, options, selectedValue, defaultLabel, updateSelectedValue }) {
    let disabled = options.length === 0

    return (
        <Select
            id={"Select_".concat(type)}
            name={"Select_".concat(type)}
            ariaLabel={"Select ".concat(type)}
            values={optionList()}
            onChange={(val) => onChange(val)}
            value={selectedValue}
            disabled={disabled}
        />
    )

    function onChange(value) {
        updateSelectedValue(value, type)
    }

    function optionList() {
        let res = [];
        res = [{ value: '', label: defaultLabel }].concat(
            _.map(options, (option) => {
                return { value: option.value, label: option.label }
            }))
        return res;
    }
}