import React, { Fragment, useState, useEffect }         from 'react';
import { Select }                                       from '@cb/apricot-react';
import { commaName, ariaName }                          from '../../utility/util'
import { isAfterHardEndDate }                           from '../../entities/courseinstance'
import * as drag                                        from './dragging'

export function StudentToAssign({teams, unAttachedEnrollments, canEditSection, setDragName, addEnrollmentToTeam, createTeam, clearTeamSelection}) {
    const [selectedEnrollmentId, setSelectedEnrollmentId] = useState(0)
    //console.log('StudentToAssign')

    useEffect(() => {
        //console.log('useEffect')
        let elem = document.querySelector('#availableEnrollments select')
        if (elem) elem.focus()

        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    function handleScroll(e) {
        for (let item of document.getElementsByClassName("draggable")) {
            item.classList.remove("draggable");
        }
    }

    let availableTeams = teams.filter(
        team => {
            let everyTeamMemberHasExtension = _.every(team.enrollment, enrollment => { return enrollment.hasExtension })
            let isScored = team.teamHasUpload || team.teamHasScore
            return !isScored && (canEditSection || everyTeamMemberHasExtension) && !isAfterHardEndDate()
        }
    )
    let optionList = [{ label: 'Create New Team', value: 0 }]
    .concat(
        _.map(availableTeams, team => {
            return { value: team.id, label: team.title }
        })
    )

    const showSelections = event => {
        let element = event.target
        let enrollmentId = element.id || element.parentElement.id
        if (enrollmentId) {
            setSelectedEnrollmentId(enrollmentId)
        }
    }

    const keyDown = (event) => {
        //console.log('keyDown ' + event.keyCode)
        if (event.keyCode === 13) {
            showSelections(event)
        }
    }

    const onMouseDown = event => {
        showSelections(event)
    }

    let onMouseEnter = (ev) => {
        setSelectedEnrollmentId(0)
        drag.onMouseEnter(ev)
        let el = ev.target
        el.focus()
    }


    let addEnrollment = async (teamId) => {
        let enrollmentId = Number(selectedEnrollmentId);
        //console.log('addEnrollment has enrollmentId=' + enrollmentId + ', teamId=' + teamId)
        if (teamId && teamId > 0) {
            await addEnrollmentToTeam(enrollmentId, teamId);    
        } else {
            await createTeam(enrollmentId);        
        }
        setSelectedEnrollmentId(0);
    }

    let getClasses = () => {
        return "cb-card cb-no-box-shadow cb-no-border-radius cb-border-bottom cb-card-content cb-spacerv-bottom-4"
    }

    if (unAttachedEnrollments && unAttachedEnrollments.length > 0) {
        let list = unAttachedEnrollments.map((enrollment, index) => {
            let name = commaName(enrollment)
            let ariaLabel = 'click to drag ' + ariaName(name) + ' into a team on the left, or press return to pick a team from a menu'
            if (enrollment.canDrag) {
                return (
                    <Fragment key={'available_student_' + index}>
                        <div id={enrollment.id} draggable="true" className={getClasses()} key={'enrollment_' + enrollment.id}
                            onDragStart={e => onDragStart(e, name, setDragName)}
                            onDrag={drag.dragging}
                            onDragEnd={e => drag.onDragEnd(e, name, setDragName)}
                            onMouseEnter={onMouseEnter} onMouseLeave={drag.onMouseLeave}
                            onKeyDown={keyDown} onMouseDown={onMouseDown}
                            aria-label={ariaLabel} tabIndex='0'>
                            <span>{name}</span>
                        </div>
                        {selectedEnrollmentId == enrollment.id &&
                            <Select id='selectId' key={'select_' + index}
                                labelNoValue='Please Select Team'
                                ariaLabel='Select the team to add the student to'
                                values={optionList}
                                onChange={(id) => addEnrollment(id)}
                                disabled={false}
                            />
                        }
                    </Fragment>
                )
            } else {
                return (
                    <div id={enrollment.id} tabIndex='0' className={getClasses()} key={'enrollment_' + enrollment.id}>
                        <span>{name}</span>
                    </div>
                )
            }
        })
        return (
            <div id="availableEnrollments" aria-label='click and drag students from this list to create, or add to, a new team'>
                {list}
            </div>
        )
    } else {
        return (
            <div className="cb-text-disabled">
                <span>There are no students currently awaiting team assignment.</span>
            </div>
        )
    }
    function onDragStart(ev, name, setDragName) {
        clearTeamSelection()
        drag.onDragStart(ev, name, setDragName)
    }
}
