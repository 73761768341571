
import React                        from 'react';
import { FileUpload }               from '../../_core/views/fileUpload'
import { checkInjection }           from '../../utility/util'

export function StepTwoContent(scoreeName, csp, art, reportRequired, stageTwoUpdate, rationaleError, setRationaleError) {
    let allowReportUpload = reportRequired

    let [text1, text2, text3, text4, text5, text6, text7, text8, text9, text10] = getText(csp)

    //console.log('StepTwoContent initializing')

    return (
        <form id='stepTwoPlag'>
            {art ? (
              <p>
                You have indicated that you suspect {scoreeName} of not adhering to the Artistic Integrity Agreement for AP Art and Design portfolios. Please present College Board with evidence to support our investigation.
              </p>
            ) : (
              <p>
                You have indicated that you suspect {scoreeName} of submitting plagiarized{text6} work{text7}. Please present the College Board with evidence to support our investigation.
              </p>
            )}
            {allowReportUpload &&
                <div className="form-group">
                    <label><strong>Turnitin Report (Required)</strong></label>
                    <p>You must upload the Turnitin report for this submission.</p>
                    <div className="region-reportFile">
                        <FileUpload name='reportFile' title='Report File'
                                    extensions='pdf'
                                    fileSelectedCallback={hasFile => {
                                        if (hasFile) {
                                            stageTwoUpdate({reportFileDefined: hasFile})
                                        } else {
                                            stageTwoUpdate({reportFileDefined: hasFile, reportFileGood: false})
                                        }
                                    }}
                                    errorCallback={good => {
                                        stageTwoUpdate({reportFileGood: good})
                                    }}>
                        </FileUpload>
                    </div>
                </div>
                }
            <div className="form-group">
                <label id="plagLabel">
                    {art ? (
                      <strong>Plagiarism or Violation Rationale (Required)</strong>
                    ) : (
                      <strong>Plagiarism{text1} Rationale (Required)</strong>
                    )}
                </label>
                {art ? (
                  <p>
                    In the box below, provide a detailed explanation of why you suspect the student's work does not adhere to the Artistic Integrity Agreement, and how your evidence supports this claim.
                  </p>
                ) : (
                  <p>
                    In the box below, provide a detailed explanation of why you suspect the student's work is plagiarized{text2}, and how your evidence supports this claim.
                  </p>
                )}
                <div className="cb-input">
                    {!art && <label htmlFor="rationalID">{'Plagiarism' + text3 + ' Rationale'}</label>}
                    <textarea id='rationalID' onChange={reportRationaleGood}
                              aria-label={'Plagiarism' + text3 + ' Rationale'}
                              name="rationale" className="form-control" rows="3" maxLength="1200">
                    </textarea>
                    {!!rationaleError && (
                        <p style={{ marginTop: "0.5rem", color: "red" }}>{rationaleError}</p>
                    )}
                </div>
                <br />
                <label>
                  {art ? (
                    <strong>Evidence of Plagiarism or Violation(Optional)</strong>
                  ) : (
                    <strong>Evidence of Plagiarism{text4} (Optional)</strong>
                  )}
                </label>
                {art ? (
                  <p>Attach a single file below containing the evidence that the student did not adhere the Artistic Integrity Agreement.</p>
                ) : (
                  <p>Attach a single file below containing the evidence of plagiarism{text5}.</p>
                )}
                {!art && <p>
                    For written responses or videos, this must include where the plagiarized{text8} material can be found (e.g., page number and paragraph or time stamp).
                </p>}
                {csp &&
                    <p>
                        For program code, provide evidence of the code segment(s) that are suspected to be plagiarized or of violating the performance task guidelines and where this information can be found
                        Be sure to include the exact code segments from the student's program code as part of your evidence.
                    </p>
                }
                {art ? (
                  <p>
                    Other evidence could be in  the form of links or files that clearly indicate how the student's adhere to the Artistic Integrity agreement. If you are able to identify the original source, include that information in your evidence file. All evidence must be compiled into a single file for submission.
                  </p>
                ) : (
                  <p>
                    Other evidence could be in the form of links or files that clearly indicate how the student's work is plagiarized{text9}.
                    If you are able to identify the original source that was plagiarized, include that information in your evidence file.{text10}
                  </p>
                )}
                {!csp && !art &&
                    <p>
                        Videos of presentations should be provided to College Board outside of the Digital Portfolio.
                        Please contact customer service at <a href="mailto:apcapstone@info.collegeboard.org">apcapstone@info.collegeboard.org</a> for detailed instructions.
                    </p>
                }
                <div className="region-evidenceFile">
                    <FileUpload name='evidenceFile' title='Evidence File'
                                extensions='doc, docx, odt, pages, pdf'
                                fileSelectedCallback={hasFile => {
                                    if (hasFile) {
                                        stageTwoUpdate({evidenceFileDefined: hasFile})
                                    } else {
                                        stageTwoUpdate({evidenceFileDefined: hasFile, evidenceFileGood: false})
                                    }
                                }}
                                errorCallback={good => {
                                    stageTwoUpdate({evidenceFileGood: good})
                                }}>
                                
                    </FileUpload>
                </div>
            </div>
        </form>
    )
    function getText(csp) {
        let text1 = '', text2 = '', text3 = '', text4 = '', text5 = ''
        let text6 = '', text7 = '', text8 = '', text9 = '', text10 = ''
        if (csp) {
            text1 = ' or Violation'
            text2 = ' or has violated the performance task guidelines '
            text3 = ' or Violation'
            text4 = ' or Violation'
            text5 = ' or evidence that the student violated the performance task guidelines'
            text7 = ' or not adhering to the AP Computer Science Principles guidelines for completing the performance tasks'
            text9 = ' or violates the performance task guidelines'
            text10 = ' All evidence must be compiled into a single file for submission.'
        } else {
            text2 = ' and/or falsified'
            text5 = ' or falsification'
            text6 = '/falsified'
            text8 = ' or falsified'
            text9 = ' and/or falsified'
        }
        return [text1, text2, text3, text4, text5, text6, text7, text8, text9, text10]
    }

    function containsSpecialChars(str) {
        const allowedChars = /^[a-zA-Z0-9\t\n ,./<>?;:"'`~!@#$%^&*()\[\]{}_+=|\\-]*$/;
        return !allowedChars.test(str);
    }

    function reportRationaleGood() {
        const rationale = $('#stepTwoPlag textarea').val().trim();
        const doesContainSpecialChars = containsSpecialChars(rationale);
        let isRationaleGood = rationale.length > 0 && !doesContainSpecialChars;
        stageTwoUpdate({rationaleGood: isRationaleGood});
        if (doesContainSpecialChars) {
            setRationaleError('Error: The text you entered cannot contain special characters. Please remove special characters and try again.');
        } else {
            setRationaleError('')
        }
      }
  }