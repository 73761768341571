import { getCourseInstances, getCurrentOrg, saveToCache } from "../entities/courseinstance";
import { getCurrentAcademicYear } from "../entities/academicyear";
import { useLocation } from "react-router-dom";
import { ArchiveComp } from "./views/archive";
import EmptyComp from "./views/empty";
import { getCurrentPerson } from "../entities/person";
import CourseContainer from "./views/courseContainer";
import { Spinner } from "@cb/apricot-react";

const expandCourses = (activeCourses) => {
  let courses = []
  _.each(activeCourses, course => {
    let title = course.title
    if (course.isDPCourse) {
      courses.push
      ({
        'title': title,
        courseId: course.id,
        sectionId: course.sections[0].id
      })
    } else {
      let uniqueTitles = []
      _.each(course.sectionsOfOrg, section => {
        let fullTitle = title + ': ' + section.subTitle
        if (!_.contains(uniqueTitles, fullTitle)) {
          uniqueTitles.push(fullTitle)
          courses.push
          ({
            'title': fullTitle,
            courseId: course.id,
            sectionId: section.id
          })
        }
      })
    }
  })
  return courses
}

const Dashboard = ({ compProps }) => {
  let courseInstances = getCourseInstances()

  const role = getCurrentPerson()
  const firstLoginRoute = true
  const location = useLocation()
  const isArchive = location.pathname === 'archive'
  const [props, setProps] = useState({ courseInstances, loading: true })
  const hasArchivedCourses = props.courseInstances?.hasArchivedCourses()
  const hasOneActiveCourseAndSkipDashboard = props.courses?.length === 1 && props.courses[0]?.sections?.length === 1 && firstLoginRoute

  const getYear = async () => {
    const res = await getCurrentAcademicYear()
    setProps(state  => ({ ...state, year: res }))
  }

  useEffect(() => {
    getYear()
    let currentOrg = getCurrentOrg()
    let courses = courseInstances.getCourses(currentOrg, !isArchive)
    saveToCache()
    setProps(state => ({ ...state, courses, org: currentOrg, loading: false }))
  }, [])

  useEffect(() => {
    if (compProps) {
      let courses = courseInstances.getCourses(compProps, !isArchive)
      saveToCache()
      setProps(state => ({ ...state, courses, org: compProps, loading: false }))
    }
  }, [compProps])

  if (props.loading) return <Spinner />

  if (isArchive) {
    let archiveCourses = props.courses.group(c => c.academicYear)
    return <ArchiveComp courses={archiveCourses} />
  }

  if (props.courses?.length) {
    const expandedCourses = expandCourses(props.courses)
    const componentProps = {
      activeCourses: props.courses,
      expandedCourses: expandedCourses,
      hasArchivedCourses: hasArchivedCourses,
      academicYear: props.year,
      org: props.org
    }

    document.dispatchEvent(new CustomEvent('pagevisit', {detail: 'home'}))
    return <CourseContainer props={componentProps} />
  }

  if (!props.courses?.length) {
    const componentProps = {
      hasArchivedCourses,
      academicYear: props.year
    }
    document.dispatchEvent(new CustomEvent('pagevisit', {detail: 'no-classes'}))
    return <EmptyComp parms={componentProps} />
  }

  if (hasOneActiveCourseAndSkipDashboard) {
    if (role.isCoordinator()) {
      // do stuff
    } else {
      let sectionId = props.courses[0].sections[0].id
      let keyword = 0
      const url = `instruction/sections/${sectionId}/overview/${keyword}`
    }
  }

  return null
}

export default Dashboard