import React from 'react';

import { getCurrentPerson }                   from '../entities/person.jsx'
import { getCourseInstances, getCachedYear, 
  isActive, saveToCache, getCurrentOrg }      from '../entities/courseinstance'
import { getCurrentAcademicYear }             from '../entities/academicyear'
import { navigate }                           from '../utility/history'
import { showMainContent }                    from '../main/instruction_controller'
import { ArchiveComp }                        from './views/archive'
import EmptyComp                              from './views/empty'
import CourseContainerComp                    from './views/courseContainer'
import { showCourseProgress }                 from '../progress/progress_module'

export function reshowDashboard() {
  let paintArchive = /archive/.test(window.location.href)
  if (paintArchive) {
    showArchive();
  } else {
    dashboardLogic(false);
  }
}

export function checkShouldShowDashboard(firstLoginRoute) {
  dashboardLogic(firstLoginRoute)
}

export async function showArchive() {
  var academicYear = await getCurrentAcademicYear();
  getCachedYear(academicYear)
  isActive(false)
  saveToCache()

  let courseInstances = getCourseInstances()
  let currentOrg = getCurrentOrg()
  let courses = courseInstances.getCourses(currentOrg, false)

  var archiveCourses = _.groupBy(courses, course => course.academicYear);
  var archiveView = <ArchiveComp courses={archiveCourses}></ArchiveComp>
  showMainContent(archiveView, 'My Archived Digital Portfolio Classes', academicYear)
}

export async function showAccess(firstLoginRoute) {
  var academicYear = await getCurrentAcademicYear();
  getCachedYear(academicYear)
  let props = {}
  var accessView = <EmptyComp parms={props}></EmptyComp>
  showMainContent(accessView, 'Welcome to the Digital Portfolio', academicYear, true)
  if (firstLoginRoute) navigate('instruction/access');
  document.dispatchEvent(new CustomEvent('pagevisit', {detail: 'no-classes'}))
}

function dashboardLogic(firstLoginRoute) {
  var courseInstances = getCourseInstances()
  if (courseInstances.courses.length < 1) {
    showAccess(true)
    return
  }

  var currentOrg = getCurrentOrg();
  var activeCourses = courseInstances.getCourses(currentOrg, true);

  showDashboard(courseInstances, activeCourses, firstLoginRoute)
}

async function showDashboard(courseInstances, activeCourses, firstLoginRoute) {
  var academicYear = await getCurrentAcademicYear();
  getCachedYear(academicYear)
  isActive(true)
  saveToCache()
  let currentOrg = getCurrentOrg();

  var hasArchivedCourses = courseInstances.hasArchivedCourses();
  var dashboardView
  if (activeCourses.length) {
    let expandedCoursesJson = expandCourses(activeCourses)
    let props = {
      'activeCourses': activeCourses,
      'expandedCourses': expandedCoursesJson,
      'hasArchivedCourses': hasArchivedCourses,
      'academicYear': academicYear,
      'org': currentOrg
    }
    dashboardView = <CourseContainerComp parms={props} />
  } else {
    let props = {
      'hasArchivedCourses': hasArchivedCourses,
      'academicYear': academicYear
    }
    dashboardView = <EmptyComp parms={props} />
    //   dashboardView = new Dashboard.EmptyView
    //   ({
    //     hasArchivedCourses: hasArchivedCourses, academicYear: academicYear
    //   })
  }

  //console.log('firstLoginRoute ' + firstLoginRoute)
  if (firstLoginRoute) navigate('instruction/dashboard');

  //console.log('going to call show:main:content ')
  showMainContent(dashboardView, 'Welcome to the Digital Portfolio', academicYear)
  document.dispatchEvent(new CustomEvent('pagevisit', {detail: 'home'}))
}

