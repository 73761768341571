import { bsliGet } from '../_core/util/request'

let academicYear = _.memoize(() => {
  return bsliGet('academicYear/current')
})

export async function getCurrentAcademicYear() {
  const result = await academicYear()
  return result.currentAcademicYear
}
