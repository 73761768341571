import { TopMenu, MenuTitle, BottomMenu } from "./topMenu";
import TimeoutComp from "./timeout";
import { Outlet } from "react-router-dom";

const InstructionLayout = ({ props }) => (
    <>
        <TopMenu props={props} />
        <BottomMenu props={props} />
        <TimeoutComp />
        <MenuTitle nonCourseTitle={props?.title} section={props?.section} />
        <div id='region-main-content' className='container add-top-margin-3x add-top-margin-4x js-action-on-dropdown'>
            <Outlet />
        </div>
    </>
)

export default InstructionLayout