import React from 'react'
import { getCourseParameter, isArt, isCSP } from '../../entities/courseinstance'
import { getIconClass } from '../../utility/util'


export const Legend = (props) => {

  const serializeData = (props) => {
    let scoringEnabled = !props.isStudent && getCourseParameter('scoringEnabled')
    let isScorable = scoringEnabled && !((isCSP() || isArt()) && !props.isWe)
    return {
      isScorable: isScorable,
    }
  }

  let helpers = serializeData(props)
  let noDraftCls = getIconClass('NoDraft')
  let draftInCls = getIconClass('DraftIn')
  let readyToScoreCls = getIconClass('ReadyToScore')
  let completeCls = getIconClass('FinalSubmitted')
  return (
    <div className='row'>
      <div className='legend offset-md-6 col-md-6 col-sm-12 col-xs-12 pull-right' aria-hidden='true'>
        <h3 className='heading'>Legend</h3>
        <div className='row'>
          {helpers.isScorable &&
            <div style={{ width: '100%' }}>
              <div className='col-md-3 col-sm-3 col-xs-3 legend-heading'>
                <span className={noDraftCls + ' block add-padding-small'}></span>
                No Draft
              </div>
              <div className='col-md-3 col-sm-3 col-xs-3 legend-heading'>
                <span className={draftInCls + ' block add-padding-small'}></span>
                Draft In
              </div>
              <div className='col-md-3 col-sm-3 col-xs-3 legend-heading'>
                <span className={readyToScoreCls + ' block add-padding-small'}></span>
                Ready To Score
              </div>
              <div className='col-md-3 col-sm-3 col-xs-3 legend-heading'>
                <span className={completeCls + ' block add-padding-small'}></span>
                Complete
              </div>
            </div>
          }
          {!helpers.isScorable &&
            <div style={{ width: '100%' }}>
              <div className='col-md-4 col-sm-4 col-xs-4 legend-heading'>
                <span className={noDraftCls + ' block add-padding-small'}></span>
                No Draft
              </div>
              <div className='col-md-4 col-sm-4 col-xs-4 legend-heading'>
                <span className={draftInCls + ' block add-padding-small'}></span>
                Draft In
              </div>
              <div className='col-md-4 col-sm-4 col-xs-4 legend-heading'>
                <span className={completeCls + ' block add-padding-small'}></span>
                Complete
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}