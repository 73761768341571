import React, { Fragment } from 'react';
import { isArtSI_Images,  isArt_SW_Images} from "../../entities/courseinstance";

const TitleComp = ({ parms }) => {
    const data = {
        teamName: parms.scoree.title(),
        studentNames: parms.scoree.prefFullName(),
        fullName: parms.scoree.fullName(),
        prefFirstName: parms.scoree.enrollment?.prefFirstName ? parms.scoree.enrollment.prefFirstName : '',
        scoredBy: parms.scorableHolder.scorable.scoredBy,
        isImages:  isArt_SW_Images (parms.lesson.id) ||  isArtSI_Images (parms.lesson.id),
        iThenticate: parms?.assignment?.iThenticate,
    }

    if (parms.upload) {
        _.extend(data, {
            fileName: parms.upload.fileName,
            dateSubmitted: moment(new Date(parms.scorableHolder.scorable.submittedAt)).format('MM/DD')
        })
    }

    return (
        <div className="cb-black1-color cb-align-left">
            {data.teamName &&
                <div>
                    <h5 className="h5 add-vertical-space-halfx break-word">
                        {data.teamName}
                        {data.fileName &&
                            <span className="font-normal">| {!data.isImages && data.fileName} | {data.dateSubmitted}</span>
                        }
                    </h5>
                    <h5>{data.studentNames.map((student, index) => (
                      <span key={index}>
                        {index !== 0 ? ', ' : ''}
                        {student.fullName}
                        {student.prefFirstName ? <span style={{ fontWeight: 400 }}> (Preferred Name: {student.prefFirstName})</span> : null }
                      </span>
                    ))}</h5>
                </div>
            }
            {!data.teamName &&
                <div>
                    <h5 className="h5 add-vertical-space-halfx break-word">
                        {data.fullName}
                        {data.prefFirstName ? <span className="cb-font-weight-regular"> (Preferred Name: {data.prefFirstName})</span> : null }
                        <br />
                        {(data.fileName && !data.isImages) && <span className="cb-font-size-small">{data.fileName} | {data.dateSubmitted}</span>}
                        {data.isImages &&
                            <span className="cb-font-size-small"> | {data.dateSubmitted}</span>
                        }
                        {data.iThenticate?.overallMatch &&
                            <span className="cb-font-size-small"> | Ithenticate Overall % Match: {data.iThenticate?.overallMatch}</span>
                        }
                    </h5>
                </div>
            }
            {data.scoredBy &&
                <p>Scored By: {data.scoredBy}</p>
            }
        </div>
    )
}

export default TitleComp
