import StudentStaticComp from './studentStatic'
import { Legend } from './legend'
import { StudentTable } from './studentTable'

export default class StudentProgressComp extends React.Component {
  constructor(props) {
    super(props)
    // props.parms: isStudent, studentProgress, moduleHolder, sectionId, isWe
  }

  render() {
    let legendProps = { isStudent: true, isWe: false }
    let tableProps = {
      moduleHolder: this.props.parms.moduleHolder,
      studentProgress: this.props.parms.studentProgress,
      sectionId: this.props.parms.sectionId,
      isStudent: true,
      isWe: false,
    }
    let staticProps = {
      sectionId: this.props.parms.sectionId,
    }
    return (
      <div>
        <div id='region-static-text'>
          <StudentStaticComp parms={staticProps} />
        </div>
        <div id='region-legend'>
          <Legend {...legendProps} />
        </div>
        <div id='region-student-table'>
          <StudentTable {...tableProps} />
        </div>
      </div>
    )
  }
}