import React, { Fragment }                from 'react';
import { YellowButton }                   from '@cb/apricot-react'
import { getCurrentPerson }               from '../../entities/person.jsx'
import {pathToUrl}                        from '../../content/static_content'
import { ConfirmModal }               from '../../_core/views/modal.jsx'
import { reactRender }                    from '../../utility/util'

export function PublishableComp({ publishable, refetchCMSContent, publishMaterial, moduleId }) {
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [confirmModalProps, setConfirmModalProps] = useState(null)

  const templateHelpers = (publishable) => {    
    const publishedDate = publishable.publishedDate
    const isPublished = !!publishedDate
    const cmsProperties = publishable.cmsProperties
    const person = getCurrentPerson()

    return {
      isTeacher: person.isTeacher(),
      isPublished: isPublished,
      publishedDate: isPublished && moment(publishedDate).format('MM/DD'),
      showDownload: isPublished || !person.isStudent(),
      fileUrl: pathToUrl(cmsProperties.File)
    }
  }

  const openConfirmModal = (publishable, publishMaterial, moduleId) => {
    const cmsProperties = publishable.cmsProperties
    const title = cmsProperties.title
    const message = 'You are about to publish the ' + title +
      ' containing sources that your students will need to complete the ' + cmsProperties.taskTitle +
      '. These materials will be made available to the students in this class section only. ' +
      'If you are teaching additional classes or sections, navigate to the additional class/section and repeat these steps. ' +
      'Once you publish these sources to your students, they cannot be unpublished.'

    setConfirmModalProps({
      title: 'Publish ' + title,
      noButtonText: 'Cancel',
      yesButtonText: 'Ok',
      hideNoButton: false,
      message: message,
      onConfirmYes: () => { publishMaterial(publishable, moduleId) },
      modalId: 'publishSupportingMaterialModal',
    })
    setShowConfirmModal(true)
  }

  const helpers = templateHelpers(publishable)
  const cmsProperties = publishable.cmsProperties
  const cls1 = helpers.showDownload ? 'col-md-9 col-sm-8' : 'col-md-12 col-sm-12'
  const cls2 = helpers.showDownload ? ' add-right-border' : ''
  return (
    <div className="row well remove-horizontal-padding add-top-margin-3x">
      <div className={cls1 + ' col-xs-12'}>
        <div className="row">
          <div className="col-md-2 col-sm-2 col-xs-3 text-center remove-right-padding">
            <span className="cb-glyph cb-glyph-md cb-exclamation">
              <span className="sr-only">Attention:</span>
            </span>
          </div>
          <div
            className={'col-md-10 col-sm-10 col-xs-9 remove-left-padding' + cls2}
            dangerouslySetInnerHTML={{ __html: cmsProperties.Body }} />
        </div>
      </div>
      {helpers.showDownload &&
        <div className="col-md-3 col-sm-4 col-xs-9 pull-right remove-left-padding-xs">
          <div className="add-top-margin-1x">
            <span className="icon-svg wide-svg" aria-hidden="true"></span>
            <a href={helpers.fileUrl} title={'Download File: ' + cmsProperties.title} target="_blank">
              <span>{cmsProperties.title}</span>
              <span className="sr-only">Download File: </span>
            </a>
            <small>{cmsProperties.Size}</small>
          </div>
          <div className="add-top-margin-3x">
            {helpers.isTeacher &&
              <Fragment>
                {helpers.isPublished &&
                  <strong className="cb-green1-color">
                    <span className="cb-glyph cb-check" aria-hidden="true"></span>
                    <span>Published {helpers.publishedDate}</span>
                  </strong>
                }
                {!helpers.isPublished &&
                  <YellowButton type="button" className="js-publish"
                    onClick={() => { openConfirmModal(publishable, publishMaterial, moduleId) }}>
                    Publish
                  </YellowButton>
                }
              </Fragment>
            }
            {!helpers.isTeacher && helpers.isPublished &&
              <a href={helpers.fileUrl} className="btn btn-sm btn-primary btn-stretch"
                title={'Download File: ' + cmsProperties.title} target="_blank">
                Download <span className="sr-only">File: {cmsProperties.title}</span>
              </a>
            }
          </div>
        </div>
      }
      <ConfirmModal
        {...confirmModalProps}
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false)
          refetchCMSContent && refetchCMSContent()
        }}
      />
    </div>
  )
}
