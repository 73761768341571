import { Dropdown, DropdownItem } from "@cb/apricot-react";

export function ReportMenuAdmin({ reportTypes, nav }) {
  const items = () => Object.entries(reportTypes).map((report, key) => (
    <DropdownItem label={report[1]} iconLeft={true} dropdownItemId={`report_${key}`} key={key} />
  ))

  const callBack = (node) => {
    const reportNum = parseInt(node.id.slice(7)) + 1
    const url = `/admin/view-reports/${reportNum}`
    nav(url)
  }

  return (
    <>
      <Dropdown title="Reports" closeOnClickOutside closeOnClick callBack={callBack}>
        {items()}
      </Dropdown>
    </>
  )
}
