import { bsliGet } from '../_core/util/request'
import { getCurrentSectionId } from '../entities/courseinstance'
import { sortByName } from '../utility/util'
import { isArtSI_Images, isArt_WrittenEvidence, isArt_SW_Images } from "./courseinstance"

var capitalize = s => s[0].toUpperCase() + s.slice(1)
var insertSpaceBeforeCapitals = s => s.replace(/[A-Z]/g, c => ' ' + c)

export class Assignment {
  constructor(assignment) {
    this.assignmentAbbreviation = assignment.assignmentAbbreviation
    this.assignmentDescription = assignment.assignmentDescription
    this.assignmentTitle = assignment.assignmentTitle
    this.assignmentType = assignment.assignmentType
    this.buttonText = assignment.buttonText
    this.dueDate = assignment.dueDate
    this.dueInDays = assignment.dueInDays
    this.id = assignment.id
    this.lessonId = assignment.lessonId
    this.isIWRAssignment = assignment.isIWRAssignment
    this.isArt_SI_Images          = isArtSI_Images(this.lessonId)
    this.isArt_WrittenEvidence    = isArt_WrittenEvidence(this.lessonId)
    this.isArt_SW_Images          = isArt_SW_Images(this.lessonId)
    this.isSubmissionAssignment = assignment.isSubmissionAssignment
    this.permittedFileExtensions = assignment.permittedFileExtensions
    this.permittedFinalSubmissionFileExtensions = assignment.permittedFinalSubmissionFileExtensions
    this.permittedMaxFileSize = assignment.permittedMaxFileSize
    this.permittedMinFileSize = assignment.permittedMinFileSize
    this.rubricFile = assignment.rubricFile
    this.metadata = this.parse(assignment)
  }
  isDocument() {
    return this.isSubmissionAssignment
  }
  allowsFinalSubmission() {
    return !_.isEmpty(this.permittedFinalSubmissionFileExtensions)
  }
  isCSP_WR(modules) {
    return this.isIWRAssignment
  }
  parse(assignment) {
    let metadata = _.clone(assignment.metadata) || []
    _.each(metadata, meta => {
      if (meta.options) meta.options = _.sortBy(meta.options, l => l == 'Other' ? 'ZZZ' : l)
      meta.title = capitalize(insertSpaceBeforeCapitals(meta.name)) // researchMethod -> Research Method
    })
    return metadata
  }
}

class AssignmentHolder { // formerly Entities.AssignmentCollection
  constructor() {
    this.assignments = []
  }

  init(lessonId, sectionId) {
    return bsliGet('sections/' + (sectionId || getCurrentSectionId()) + '/lessons/' + lessonId + '/assignments')
      .then(json => {
        //console.log('assignment json', json)
        _.each(json, assignment => {
          this.assignments.push(new Assignment(assignment))
        }
        )
        //TODO need to sort json, we do?
        // json is an array of module info including their lessons
        //this.parse(json)
      })
  }
}

class AssignmentStatus { // formerly Entities.AssignmentStatus
  constructor(jsonStatus) {
    this.id                 = jsonStatus.id
    this.firstName          = jsonStatus.firstName
    this.lastName          = jsonStatus.lastName
    this.submissionStatus   = jsonStatus.submissionStatus
    this.submittedAt        = jsonStatus.submittedAt
    this.title              = jsonStatus.title
    this.enrollment         = jsonStatus.enrollment // actually an array
  }

  isTeam() { return !!this.title }

  titleName() {
    return this.isTeam() ? this.title.trim() : (this.lastName + ', ' + this.firstName)
  }

  teamNames() {
    var enrollments = this.enrollment
    if (enrollments) {
      return _.map(enrollments, (enrollment) => {
        return enrollment.lastName + ', ' + enrollment.firstName
      })
      .sort().join()
    } else {
      return false
    }
  }

  getPersonId() {
    var enrollment = this.enrollment
    return this.isTeam() ? (enrollment.length && enrollment[0].personId) : this.id;
  }
}

class AssignmentStatuses {
  constructor() {
    this.statuses = []
    //this.test()
  }

  init(sectionId, assignmentId, type) {
    return bsliGet('sections/' + sectionId + '/assignments/' + assignmentId + '/' + type)
      .then(json => {
        //console.log('assignment status json', json)
        _.each(json, status => {
          this.statuses.push(new AssignmentStatus(status))
        })
        this.sort(type)
      })
  }

  sort(type) {
    if (type === 'team') { // original code had 'teams' which is incorrect
      this.statuses = _.sortBy(this.statuses, 'title')
    } else {
      this.statuses = sortByName(this.statuses)/*_.sortBy(this.statuses, this.compare)*/
    }
  }

}

export function getAssignmentStatuses(sectionId, assignmentId, type) {
  //app.reqres.setHandler('assignment:statuses', function(sectionId, assignmentId, type) {
  let assignmentStatuses = new AssignmentStatuses()

  return new Promise((resolve, reject) => {
    assignmentStatuses.init(sectionId, assignmentId, type)
    .then(() => {
      resolve(assignmentStatuses)
    })
    .catch(() => {
      reject('assignment statuses did not load')
    })
  })
}

export function getAssignmentTeamStatus(sectionId, assignmentId) {
  return bsliGet('sections/' + sectionId + '/assignments/' + assignmentId + '/team')
    .then(json => {
      return new AssignmentStatus(json)
    })
}

export function getAssignmentHolder(lessonId, sectionId) { // formerly setHandler('assignments'
  let assignmentHolder = new AssignmentHolder()

  return new Promise((resolve, reject) => {
    assignmentHolder.init(lessonId, sectionId)
    .then(() => {
      resolve(assignmentHolder)
    })
    .catch(() => {
      reject('assignmentHolder did not load')
    })
  })
}
