import { bsliGet } from '../../_core/util/request'
import { noContentError } from '../../utility/util'
import { NakedButton } from '@cb/apricot-react'
import { entitiesUpload, Upload } from '../../assignment/entities/upload'
import { runOriginality, viewOriginality } from '../../utility/originality'

export function AssignmentInfoComp({ results }) {

  if (!results || !results.json || !results.json.assignment || !results.json.section) {
    return noContentError()
  }

  let assignment = results.json.assignment
  let section = results.json.section

  let assignmentId = results.assignmentId
  let sectionId = section.id
  let uploadId = assignment.uploadId

  let relatedList = []
  let reasonList = []


  const [reportLinkLoading, setReportLinkLoading] = useState(false)
  const [reportError, setReportError] = useState(null)
  const [assignmentUpload, setAssignmentUpload] = useState({})
  const [originalityLoading, setOriginalityLoading] = useState(false)
  const [originalityLabel, setOriginalityLabel] = useState('')
  const [_s, setIsSaving] = useState(false)
  const formToSubmitRef = useRef(null)

  relatedList = _.map(assignment.relatedAssignments, (related, index) => {
    //  console.log('related', related)
    let elem
    let uploadId = related.id
    if (uploadId) {
      elem = (
        <button className='cb-btn cb-btn-sm cb-btn-naked cb-btn-blue cb-no-padding'
                onClick={() => downloadSubmission(assignmentId, sectionId, uploadId)}>
          {related.title}
        </button>
      )
    } else {
      elem = 'n/a'
    }
    return (
      // <li>{{{createDownloadFileLink title ../sectionId ../assignmentId id }}}</li>
      <li key={'related_' + index}>
        {elem}
      </li>
    )
  })
  reasonList = _.map(assignment.plagiarismReasons, (reason, index) => {
    return (
      <li key={'reason_' + index}
          dangerouslySetInnerHTML={{ __html: reason }}>
      </li>
    )
  })

  useEffect(() => {
    const getUploadInfo = async () => {
      const upload = new Upload()
      setAssignmentUpload(await upload.initFromUploadId(uploadId, assignmentId, sectionId))
    }

    if (assignmentId && uploadId) {
      getUploadInfo()
    }
  }, [assignmentId, uploadId])

  useEffect(() => {
    if (assignmentUpload?.submittedToTurnitin) setOriginalityLabel('View')
    else setOriginalityLabel('Run')
  }, [assignmentUpload])

  return (
    <>
      {results && assignment &&
        <>
          <h6 className='cb-spacerv-48'>Step 2: Review evidence submitted by teacher</h6>
          {assignment.wasScored === true &&
            <div className='cb-spacerv-bottom-16'>
              <strong>Item was scored: </strong>
              Yes
            </div>
          }
          {assignment.wasScored === false &&
            <div className='cb-spacerv-bottom-16'>
              <strong>Item was scored: </strong>
              No
            </div>
          }
          <div className='cb-spacerv-bottom-16'>
            <strong>Student submission: </strong>
            {assignment.uploadId &&
              <button
                className='cb-btn cb-btn-sm cb-btn-naked cb-btn-blue cb-no-padding'
                title='Download Submission'
                onClick={() => downloadSubmission(assignmentId, sectionId, uploadId)}>
                {assignment.submissionFilename}
              </button>
            }
          </div>
          {assignment.relatedAssignments && assignment.relatedAssignments.length &&
            <div className='cb-spacerv-bottom-16'>
              <strong>Related components:</strong>
              <div className='cb-spacerv-bottom-8'></div>
              <ul className='list-unstyled'>
                {relatedList}
              </ul>
            </div>
          }
          {assignment.plagiarismReasons &&
            <div className='cb-spacerv-bottom-16'>
              <strong>Reasons:</strong>
              <div className='cb-spacerv-bottom-8'></div>
              <ul>
                {reasonList}
              </ul>
            </div>
          }
          {assignment.plagiarismRationale &&
            <div className='cb-spacerv-bottom-8'>
              <strong>Rationale:</strong>
              <div className='cb-spacerv-bottom-8'></div>
              <p>{assignment.plagiarismRationale}</p>
            </div>
          }
          {assignmentUpload && uploadId &&
            <div>
              <strong>Run/View Originality: </strong>
              <NakedButton loading={originalityLoading} className='cb-margin-left-4' onClick={async () => {
                setOriginalityLoading(true)
                await originality()
                setOriginalityLoading(false)
              }} noPadding noOutline>
                {originalityLabel} Originality
              </NakedButton>
              <form ref={formToSubmitRef} method='POST' target='_blank' action='' name='tiiForm'
                    className='js-turnitin-form' encType='application/x-www-form-urlencoded'>
              </form>
            </div>
          }


          {assignment.tiiReportUploadId &&
            <div className='cb-spacerv-bottom-16'>
              <strong>Turnitin report submitted by teacher: </strong>
              <button className='cb-btn cb-btn-sm cb-btn-naked cb-btn-blue cb-no-padding'
                      onClick={() => turnitinDownloadFile(assignment.tiiReportUploadId)}>{assignment.tiiReportFilename}</button>
            </div>
          }
          {assignment.evidenceUploadId &&
            <div className='cb-spacerv-bottom-16'>
              <strong>Evidence submitted by teacher: </strong>
              <button className='cb-btn cb-btn-sm cb-btn-naked cb-btn-blue cb-no-padding'
                      onClick={() => plagiarismDownloadFile(assignment.evidenceUploadId)}> {assignment.evidenceFilename}</button>
            </div>
          }
          {assignment.iThenticate && assignment.iThenticate.overallMatch &&
            <div className='cb-spacerv-bottom-16'>
              <strong>iThenticate Overall % Match:</strong>
              {assignment.iThenticate.overallMatch}
            </div>
          }
          {assignment.sisId &&
            <div className='cb-spacerv-bottom-16'>
              <strong>iThenticate Report: </strong>
              <NakedButton loading={reportLinkLoading} target='_blank'
                           title='Click to go in to iThenticate and view report'
                           onClick={() => retrieveIthenticateReportUrl(assignment.sisId)} noOutline noPadding
                           className='ithenticate-report-url'>
                Click to view
              </NakedButton>
              {reportError !== null ?
                <>
                  <span>{reportError.errorMessage}</span>
                </> : null}
            </div>
          }
        </>
      }
    </>
  )

  async function originality() {
    if (assignmentUpload.submittedToTurnitin) {
      viewOriginality(assignmentUpload, formToSubmitRef)
    } else {
      runOriginality(this, assignmentUpload, false, () => {
      }, setIsSaving, async () => {
        const newUpload = await entitiesUpload(uploadId, assignmentId)
        if (newUpload) setAssignmentUpload(newUpload)
      })
    }
  }

  function downloadSubmission(assignmentId, sectionId, uploadId) {
    if (!!uploadId && !!assignmentId && !!sectionId) {
      let downloadUrl = 'sections/' + sectionId + '/assignments/' + assignmentId + '/uploads/' + uploadId + '/download'
      bsliGet(downloadUrl)
        .then(json => {
          window.open(json.url, '_blank')
        })
    } else {
      console.error('downloadSubmission did not have needed parameters', arguments)
    }
  }

  async function plagiarismDownloadFile(uploadId) {
    let url = generatePlagiarismDownloadUrl(uploadId)
    await bsliGet(url).then(url => window.open(url.url, '_blank'))
  }

  async function turnitinDownloadFile(tiiUploadId) {
    let url = generatePlagiarismDownloadUrl(tiiUploadId)
    await bsliGet(url).then(url => window.open(url.url, '_blank'))
  }

  function generatePlagiarismDownloadUrl(uploadId) {
    return '/review/plagiarism/upload/' + uploadId + '/download'
  }

  async function retrieveIthenticateReportUrl(sisId) {
    try {
      setReportLinkLoading(true)
      let url = `/review/plagiarism/reports/${sisId}`
      let resp = await bsliGet(url)
      setReportLinkLoading(false)
      return window.open(new URL(resp), '_blank')
    } catch (e) {
      setReportError(e)
    }
  }
}
