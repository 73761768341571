import { getCurrentSectionId, getCurrentCourseInstance } from '../../entities/courseinstance'
import { subject, updateSubscription} from '../../utility/service'
import {getSubmissionAlertData} from '../../assignment/assignment_module'

const SubmissionAlert = (props) => {
  const [alertReason, setAlertReason] = useState(props.alertReason)

  useEffect(() => {
    let subscription

    if (props.index === 0) {
        subscription = subject.subscribe(message => {
            if (message.refreshAlert) {
                if (props.lesson) {
                    getAlert(props.lesson);
                }
            }
        })
        updateSubscription('refreshAlert', subscription)
    } else {
        subscription = subject.subscribe(message => {
            if (message.refreshAlert1) {
                if (props.lesson) {
                    getAlert(props.lesson);
                }
            }
        })
        updateSubscription('refreshAlert1', subscription)
    }
  }, [])

  const templateHelpers = (alertReason) => {
      const list = ['intentNo', 'intentNoApNumberMissing', 'intentNotSelected',
          'intentNotSelectedApNumberMissing', 'apNumberMissing', 'submitFinal', 'registerExam']
      const obj = {
          courseTitle: getCurrentCourseInstance().title,
          currentSectionId: getCurrentSectionId(),
      }

      const bools = {}
      list.forEach((str) => {
        bools[str] = alertReason && alertReason === str
      })
      bools.any = Object.values(bools).some(Boolean);

      return { ...obj, ...bools }
  }

  const getAlert = async (lesson) => {
      const alertReason = await getSubmissionAlertData(lesson)
      setAlertReason(alertReason)
  }

  const helpers = templateHelpers(alertReason)

  return (
      <>
          {helpers.any &&
              <>
                  <br />
                  <div className="alert alert-warning cb-alert-heading"
                      style={{ padding: '1em 0', backgroundColor: 'inherit', margin: 0 }}>
                      <h4>Alert</h4>
                      {helpers.intentNo &&
                          <>
                              <p>This final submission will <strong>not</strong> be sent to the College Board for scoring unless you indicate that you plan on taking the end-of-course exam. If you do not plan on taking the end-of-course exam, no further action is needed for this submission.</p>
                              <p><a href={'#instruction/sections/' + helpers.currentSectionId + '/overview'}>
                                  Go to Overview to change your intent
                              </a></p>
                          </>
                      }
                      {helpers.intentNoApNumberMissing &&
                          <>
                              <p>This final submission will <strong>not</strong> be sent to the College Board for scoring unless you indicate that you plan on taking the {helpers.courseTitle} end-of-course exam <strong>and</strong> you enter your AP number from your AP Student Pack.</p>
                              <p><a href={'#instruction/sections/' + helpers.currentSectionId + '/overview'}>
                                  Go to Overview to change your intent
                              </a></p>
                          </>
                      }
                      {helpers.intentNotSelected &&
                          <>
                              <p>This final submission will <strong>not</strong> be sent to the College Board for scoring unless you indicate that you plan on taking the end-of-course exam. If you do not plan on taking the end-of-course exam, no further action is needed for this submission.</p>
                              <p><a href={'#instruction/sections/' + helpers.currentSectionId + '/overview'}>
                                  Go to Overview to indicate your intent
                              </a></p>
                          </>
                      }
                      {helpers.intentNotSelectedApNumberMissing &&
                          <>
                              <p>This final submission will <strong>not</strong> be sent to the College Board for scoring unless you indicate that you plan on taking the {helpers.courseTitle} end-of-course exam <strong>and</strong> you enter your AP number from your AP Student Pack.</p>
                              <p><a href={'#instruction/sections/' + helpers.currentSectionId + '/overview'}>
                                  Go to Overview to indicate your intent
                              </a></p>
                          </>
                      }
                      {helpers.apNumberMissing &&
                          <>
                              <p>This final submission will <strong>not</strong> be sent to the College Board for scoring unless you enter your AP Number from your AP Student Pack.</p>
                              <p><a href={'#instruction/sections/' + helpers.currentSectionId + '/overview'}>
                                  Go to Overview to enter your AP Number
                              </a></p>
                          </>
                      }
                      {helpers.submitFinal &&
                          <p>Your work will <strong>not</strong> be sent to the College Board for scoring unless you submit your work as final.</p>
                      }
                      {helpers.registerExam &&
                          <>
                              <p>This final submission will <strong>not</strong> be sent to the College Board for scoring because you're not registered for the {helpers.courseTitle} end-of-course exam.</p>
                              <p>Contact your AP coordinator to register for the exam.</p>
                          </>
                      }
                  </div>
              </>
          }
      </>
  )
}
export default SubmissionAlert
