import { AlertComp } from '../../_core/views/alert.jsx'
import CMSDocsComp from '../../supportingmaterial/views/cmsDocs'
import { SupportingMaterialLayoutComp } from '../../supportingmaterial/views/supportingMaterialLayout'

export const OverviewComp = ({ data, moduleHolder, html, failMessage, publishMaterial, refetchCMSContent }) => {
	let alertProps = failMessage ? { message: failMessage } : false

	return (
		<div>
			<h3 id="title" tabIndex="-1" aria-labelledby="title alert-container text-region cmsdocuments-region"
				className="h4">
				Overview
			</h3>
			<div id="alert-container"><AlertComp params={alertProps}/></div>
			<div id="region-modal" />
			{html &&
				<div id="text-region" dangerouslySetInnerHTML={{__html: html}}/>
			}
			<div id="cmsdocuments-region">
				<CMSDocsComp parms={data.cmsDocs} />
			</div>
			<div id="teacheruploads-region">
				<SupportingMaterialLayoutComp
					supportingMaterialData={data.supportingMaterialData}
					refetchCMSContent={refetchCMSContent}
					lessonId={0} moduleId={data.moduleId}
					moduleHolder={moduleHolder}
					publishMaterial={publishMaterial} />
			</div>
		</div>
	)
}
