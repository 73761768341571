import { clickIt, selectedLink }        from '../../utility/util'
import { Link } from "react-router-dom";

export function ScoringMenuAdmin() {

    return (
        <>
            <a href="#" id="scoringDropdownLink" className='cb-dropdown-toggle admin-menu-link'
                onClick={(e) => {selectedLink(e,'admin-menu-link')}} 
                role='button' aria-expanded="false" aria-controls='scoringDropdown'>
                <span>Scoring</span>
                <span className="sr-only">Press enter for dropdown.</span>
                <span className="cb-glyph cb-down" aria-hidden="true" />
                <span className="cb-glyph cb-up cb-hidden" aria-hidden="true" />
            </a>
            <div id='scoringDropdown' className="cb-dropdown-menu left-align cb-box-shadow">
                <ul>
                    <li className='cb-nav-link-second' key='teacher'>
                        <Link to="/admin/teacher-scoring-provisioning" className='cb-menu-link'
                            onClick={() => clickIt('scoringDropdownLink')}>
                            <span>Provision Teacher Scoring</span>
                        </Link>
                    </li>
                    <li className='cb-nav-link-second' key='scores'>
                        <Link to="/admin/delete-score" className='cb-menu-link'
                            onClick={() => clickIt('scoringDropdownLink')}>
                            <span>Delete Scores</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}