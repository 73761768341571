import React, {Fragment, useEffect}             from 'react';
import { AccordionList, AccordionItem }         from '@cb/apricot-react';
import { CBAccordion } from "@cb/apricot";

export function FaqComp({faqGroups}) {
    useEffect(() => {
        CBAccordion({
            elem: document.querySelector('.cb-accordion')
        })
    }, []); // the second parameter ensures useEffect runs only once

    const faqList = faqGroups => {
        const faqGroupList = (subSectionList, mainIndex) => {
            return _.map(subSectionList, (faq, subIndex) => {
                return (
                    <AccordionItem title={faq.Question} key={'grouplist_' + mainIndex + '_' + subIndex}>
                        <div dangerouslySetInnerHTML={{ __html: faq.Answer }}></div>
                    </AccordionItem>
                )
            })
        }

        const faqMainList = mainSectionList => {
            return _.map(mainSectionList, (subSectionList, index) => {
                return (
                    <div key={'mainList_' + index} style={{borderTop: '1px solid #d9'}}>
                        {subSectionList[0].subtitle &&
                        <h5>{subSectionList[0].subtitle}</h5>
                        }
                        {faqGroupList(subSectionList, index)}
                    </div>
                )
            })
        }

        let index = 0
        // faqGroups are: basic, student, teacher
        return _.map(faqGroups, (mainSectionList, mainSectionName) => {
            let indx = index++
            //console.log('mainSectionName ' + mainSectionName)
            return (
                <div key={'mainSection' + indx} id={'faqGroup_' + indx} tabIndex="-1">
                    <AccordionList title={mainSectionName} controls='true'>
                        {faqMainList(mainSectionList)}
                        <p><a href="#topHelp" className="js-scroll-to" title="Back to top">Back to top</a></p>
                    </AccordionList>
                </div>
            )
        })
    }

    return (
        <Fragment>
            {faqList(faqGroups)}
        </Fragment>
    )
}