import React                        from 'react';
import ReactDOM                     from 'react-dom'
import { reactRemove }              from '../../utility/util'
import { YellowButton }             from "@cb/apricot-react";
import { SelectComp }               from './select'
import { AlertComp }                from '../../_core/views/alert.jsx'
import { searchForStudent, jsonToStudent, getPlagiarizedLesson }        from '../entities/plagiarism.jsx'
import { showLoadingView, closeLoadingView }                            from '../../_core/views/loading_view'
import { isValidStudentId }                                             from '../../utility/util'
import { fetchCourseConfiguration } from '../../entities/courseinstance'

const SubmissionSearchComp = (props) => {
    const [student, setStudent] = useState(props.student)
    const [studentDefault, setStudentDefault] = useState(props.studentDefault)
    const [alertProps, setAlertProps] = useState(false)

    useEffect(() => {
        checkButtonAvailability()
    }, [student])

    // resets the form keeping the given apNumber intact
    const resetForm = (apNumber) => {
        let newStudent = _.extend(_.clone(studentDefault),
            {
                apNumber: apNumber
            }
        )
        setStudent(newStudent)
    }

    const addErrorMessage = (message) => {
        setAlertProps({
            message: 'Error: ' + message,
            isDismissable: false,
            uniqueId: 'general-alert-apnumber-error',
            className: 'alert alert-danger alert-dismissable',
            dismissAction: clearErrorMessage()
        })
    }

    const clearErrorMessage = () => {
        setAlertProps(false)
    }

    const onAPNumberChange = () => {
        props.setResults(null)
        clearErrorMessage()
        let $elem = $('#plagiarismInputAPNumber')
        var apNumber = $elem.val()

        checkButtonAvailability()

        if (!isValidStudentId(apNumber)) {
            addErrorMessage('AP number/AP ID should be eight digits/letters. Please recheck your entry.')
            resetForm(apNumber)
            return
        }

        if (apNumber === student.apNumber) {
            return
        }

        $elem.prop('disabled', true)

        searchForStudent(apNumber)
            .then(json => {
                setStudent(jsonToStudent(json, apNumber))
            })
            .catch((xhr) => {
                var message = (xhr.status === 404) ? 'AP Number does not exist.' : 'Error retrieving AP Number.'
                addErrorMessage(message)
                resetForm(apNumber)
            })
            .finally(() => {
                $elem.prop('disabled', false)
                checkButtonAvailability()
                $elem.focus()
            })
    }

    const checkButtonAvailability = () => {
        let apNumber = $('#plagiarismInputAPNumber').val()
        let noCourse = student.selectedCourse === '';
        let badApNumber = !isValidStudentId(apNumber)
        $('#plagiarism-form button').prop('disabled', noCourse || badApNumber)
    }

    const updateSelectedValue = async (value, type) => {
        props.setResults(null)
        if (type === 'course') {
            let course = _.find(student.courses, (c) => c.courseId == value)
            let newStudent = _.extend(_.clone(student),
                {
                    selectedCourse: value,
                    lessonOptions: course ? course.lessonOptions : [],
                }
            )
            setStudent(newStudent)
        }

        checkButtonAvailability()
        clearErrorMessage()
    }

    const getResults = () => {
        var getTitle = (arr, value) => {
            var selected = _.find(arr, c => c.value == value) || {}
            return selected.label
        }
        showLoadingView('region-spinner')
        getPlagiarizedLesson(student.apNumber, student.courses[student.selectedCourse - 1].lessonOptions[0].value, true)
            .then(json => {
                let results = {
                    json: json,
                    apNumber: student.apNumber,
                    courseTitle: getTitle(student.courseOptions, student.selectedCourse),
                    course: student.courses[student.selectedCourse - 1],
                    courseInstanceid: String(student.lessonOptions[0].value).slice(0, 4),
                }
                sessionStorage.setItem('apId', student.apNumber)
                fetchCourseConfiguration()
                clearErrorMessage();
                const resultObj = { ...results, courseInstanceId: results?.courseInstanceid}
                delete resultObj.courseInstanceid
                props.setResults(resultObj)
                closeLoadingView('region-spinner')
                checkButtonAvailability()
            })
            .catch((response) => {
                closeLoadingView('region-spinner')
            })
    }

    return (
        <div id='plagiarism-form'>
            <p className='cb-margin-bottom-16 cb-margin-up-16'>Enter AP number/AP ID and select course to view submission.</p>
            <div className="row">
                <div className="cb-width-100">
                    <div id="alertWrapper">
                        <AlertComp params={alertProps}></AlertComp>
                    </div>
                    <div className="cb-input form-group cb-margin-bottom-16">
                        <input type="text" maxLength="8"
                            placeholder="Enter AP Number/AP ID" aria-label="Enter AP Number/AP ID"
                            id="plagiarismInputAPNumber"
                            onChange={onAPNumberChange} />
                    </div>
                    <div className="form-group cb-margin-bottom-16" id="plagiarismCourseSelectRegion">
                        <SelectComp
                            type='course'
                            options={student.courseOptions}
                            selectedValue={student.selectedCourse}
                            defaultLabel='Select Course'
                            updateSelectedValue={updateSelectedValue}
                        />
                     </div>
                    <div className="form-group cb-margin-bottom-4">
                        <YellowButton onClick={getResults} title="View Student Information" disabled={!student.apNumber && !student.selectedCourse}>View</YellowButton>
                    </div>
                    <div className="cb-spacerv-32"></div>
                </div>
            </div>
        </div>
    )
}

export default SubmissionSearchComp
