// Special environments.  Note that by default, the environment is hidden behind /api.

let environments = [
  { 'dp.collegeboard.org:3000': 'local' },
  { 'localhost.collegeboard.org:3000': 'local' },
  { 'localhost:9090': 'remote_dit' },
]

let durlMap = {
  'dit.dp-nonprod.collegeboard.org': 'instruction',
  'qa.dp-nonprod.collegeboard.org': 'instruction',
  'digitalportfolio.collegeboard.org': '',
  // 'apcapstone.collegeboard.org': 'instruction',
  // 'apcapstoneportfolio.collegeboard.org': 'instruction',
  // 'aproster.collegeboard.org': 'enrollment',
  // 'myclasses.collegeboard.org': 'enrollment',
  // 'apparticipation.collegeboard.org': 'enrollment',
}

let defaultHash = durlMap[window.location.host] || ''

let durl = function () {
  let getDurl = function () {
    let url = window.location.protocol + '//' + window.location.host + '/' + defaultHash
    return encodeURIComponent(url)
  }

  return getDurl()
}

// Add environment-specific configuration values to this object.
// Retrieve them with the 'config:value' reqres handler by passing
//  the configuration key to the request.
export const redirectUrls = {
  iamLogout: 'https://account.collegeboard.org/login/logout?appId=295&skipEnroll=Y&DURL=' + durl(),
  iamLogin: 'https://account.collegeboard.org/login/login?appId=295&skipEnroll=Y&DURL=' + durl(),
  iamEnrollmentAccess: 'https://cbaccount.collegeboard.org/professional/enroll?appId=366&DURL=' + durl(), //use APRO access code for NOROLE
  enStudent: '/instruction',
  enProfessional: '/instruction',
  iamUpdate: 'https://account.collegeboard.org/iamweb/secure/smartUpdate?appId=295&DURL=' + durl(),
  iamAccess: 'https://account.collegeboard.org/professional/dashboard?appId=295&DURL=' + durl(),
  portfolio: '/instruction',
  portfolioAccess: '/instruction',
  adminDashboard: '/admin',
  reviewerDashboard: '/reviewer',
  terms: 'termsandconditions',
}

export const MYAP_URLS = {
  oak: 'https://dev.apfym-nonprod.collegeboard.org',
  palm: 'https://bertha-qa.apfym-nonprod.collegeboard.org',
  apple: 'https://uat.apfym-nonprod.collegeboard.org',
  maple: 'https://oat.apfym-nonprod.collegeboard.org',
  spruce: 'https://pt.apfym-nonprod.collegeboard.org/',
  pine: 'https://myap.collegeboard.org'
}

let redirectConfiguration = {
  //TODO: clean this up
  0: redirectUrls.portfolio, //success
  1: redirectUrls.iamLogin, //login fail
  2: redirectUrls.iamLogout, //logout
  3: redirectUrls.iamLogin, //no user profile
  4: redirectUrls.iamLogin, //no user type
  5: {
    //no portfolio enrollments
    st: redirectUrls.enStudent,
    pf: redirectUrls.enProfessional,
    u: true,
  },
  6: {
    //not confirmed
    st: redirectUrls.enStudent,
    pf: redirectUrls.enProfessional,
    u: true,
  },
  7: redirectUrls.iamLogin, //no portfolio agreement
  8: redirectUrls.iamUpdate, //no matched account
  9: redirectUrls.iamUpdate, //no aicode, shouldn't get this code ever
  10: redirectUrls.iamLogin, //no enrollment agreement
  11: redirectUrls.iamLogin, //generic code
  12: redirectUrls.iamEnrollmentAccess, //no role
  14: redirectUrls.portfolio, //teacher with only portfolio role, shouldn't be used anymore
  15: redirectUrls.portfolioAccess, //teacher with only portfolio and no enrollments, shouldn't be used anymore
  16: redirectUrls.adminDashboard, //digital portfolio program admin
  17: redirectUrls.adminDashboard, //digital portfolio system admin
  18: redirectUrls.reviewerDashboard, //digital portfolio plagiarism reviewer
  19: redirectUrls.portfolio,
}

const APIKEY = 'pacapstone-6hfl4qji-39x2-4ife-hn6c-ic6bvy 35la79'
let configurations = {
  local: {
    //wsBaseUrl: 'http://localhost:8080/api',
    wsBaseUrl: 'https://digitalportfolio.collegeboard.org/api',
    apiKey: APIKEY,
  },
  remote_dit: {
    wsBaseUrl: 'https://dit-bsli.dp-nonprod.collegeboard.org',
    apiKey: APIKEY,
  },
  api: {
    wsBaseUrl: '/api',
    apiKey: APIKEY,
  },
}

export function determineEnvironment() {
  let hostName = window.location.host // This includes the port #
  let envList = environments.filter(env => {
    const key = Object.keys(env)[0]
    return key === hostName
  })
  let envName = envList.length ? envList[0][hostName] : 'api'
  return envName
}

let determineConfigValue = function (key) {
  let envName = determineEnvironment()
  let configValue = configurations[envName][key]
  return configValue
}

let API = {
  getEnvironmentName: function () {
    return determineEnvironment()
  },

  getConfigValue: function (key) {
    return determineConfigValue(key)
  },

  getRedirectUrl: function (code) {
    let url = redirectConfiguration[code]
    //console.log('getRedirectUrl code=' + code + ', url=' + url)
    return url
  },

  getApiKey: function () {
    return this.getConfigValue('apiKey')
  },
}

export function request(name) {
  let argLen = arguments.length
  switch (name) {
    case 'env:name':
      return API.getEnvironmentName()
    case 'config:value':
      if (arguments.length > 1) {
        return API.getConfigValue(arguments[1])
      } else {
        console.error('config:value did not get a required argument')
      }
      break
    case 'baseUrl':
      return API.getConfigValue('wsBaseUrl')
    //return app.request('config:value', 'wsBaseUrl');
    case 'defaultHash':
      return defaultHash
    case 'config:redirect':
      if (arguments.length > 1) {
        return API.getRedirectUrl(arguments[1])
      } else {
        console.error('config:redirect did not get a required argument')
      }
      break
    case 'config:apikey':
      return API.getApiKey()
    default:
      console.error('request ' + name + ' was not found')
      break
  }
}
