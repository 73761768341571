import { checkFileType, checkFileSize, checkMinFileSize } from '../entities/artUtil'
import { getDragUtil } from '../entities/dragging'
import { ArtCardTop } from './cardTop'
import { ArtCardBottom } from './cardBottom'
import { isArt3D } from '../../../entities/courseinstance'

export const ArtCard = ({
    idRoot,
    index,
    type,
    readOnly,
    isSaving,
    showOneCard,
    setScreenSize,
    section,
    onImageChange,
    onBlur,
    onImageError,
    setCanSave,
    isReorder,
    clickedSave,
    setClickedSave,
}) => {
    const [metaHasError, setMetaHasError] =  useState(false)
    const [reorderCount, setReorderCount] = useState(0)

    let dragUtil = getDragUtil()
    useEffect(() => {
        initializeDrag()
    }, [])

    useEffect(() => {
        section.hasError = metaHasError
    })

    useEffect(() => updateReorderCount(), [isReorder])

    const initializeDrag = () => {
        if (readOnly) {
            dragUtil.initReadOnly()
        } else {
            let parms = {
                checkFileType: checkFile
            }
            dragUtil.init(parms)
        }
    }

    const updateReorderCount = () => setReorderCount(prev => prev + 1)

    return (
        <div id={`card_${idRoot}${index}`} key={idRoot + section.key} style={{ marginTop: '10px'}}>
            <div
                className='row card cb-card'
                data-id={idRoot + index}
                data-index={index}
            >
                <ArtCardTop
                    type={type}
                    idRoot={idRoot}
                    index={index}
                    readOnly={readOnly}
                    isSaving={isSaving}
                    section={section}
                    uploadError={uploadError}
                    checkFile={checkFile}
                    showOneCard={showOneCard}
                    setScreenSize={setScreenSize}
                    onChange={onImageChange}
                    is3D={isArt3D()}
                />
                <ArtCardBottom
                    key={`cardbottom-${reorderCount}`}
                    idRoot={idRoot}
                    index={index}
                    type={type}
                    readOnly={readOnly}
                    showOneCard={showOneCard}
                    isSaving={isSaving}
                    section={section}
                    onBlur={onBlur}
                    is3D={isArt3D()}
                    setHasError={setMetaHasError}
                    setCanSave={setCanSave}
                    clickedSave={clickedSave}
                    setClickedSave={setClickedSave}
                />
            </div>
        </div>
    )

    function checkFile(file, imageIndex) {
        let fileOk = false
        if (!checkFileType(file)) {
            let msg = 'Error: Only the following file types are allowed: JPEG, PNG'
            onImageError(index, msg, imageIndex)
        } else if (!checkFileSize(file)) {
            let msg = 'Error: The file can\'t be larger than 4 MB. Please upload a file that is less than 4 MB.'
            onImageError(index, msg, imageIndex)
        } else if (!checkMinFileSize(file)) {
            let msg = 'Error: The file can\'t be smaller than 5 KB. Please upload a file that is larger than 5 KB.'
            onImageError(index, msg, imageIndex)
        }else {
            fileOk = true
        }
        return fileOk
    }

    function uploadError(id, msg, imageIndex) {
        let message = msg || 'Error: An attempted image upload failed'
        onImageError(index, message, imageIndex)
    }
}