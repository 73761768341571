
import React                    from 'react';
import { subject }              from '../utility/service'
import { reactRender }          from '../utility/util'
import { ConfirmModal }     from '../_core/views/modal.jsx'
import { bsliPut }              from '../_core/util/request'

//TODO Remove context.setState and setIsSaving (function shouldn't be controlling state, component should)
export function runOriginality(context, upload, showConfirmation, onClose, setIsSaving, getUpdatedUpload) {
    if (context) {
      context.setState({
          isSaving: true
      })
    } else {
      setIsSaving(true)
    }
    let url = upload.url()
    let data = { 'submittedToTurnitin': true }
    bsliPut(url, data)
        .then(json => {
            if (json) upload.initFromPlagJson(json)
            if (showConfirmation) {
                showOriginalityCheckModal(onClose)
            }
            getUpdatedUpload && getUpdatedUpload()
        }).catch((xhr) => {
            console.error('Error: ', xhr.message)
            showOriginalityCheckModal(onClose, xhr)
        }).finally(() => {
            if (context) {
              context.setState({
                  isSaving: false
              })
            } else {
              setIsSaving(false)
            }
        })
        
}

export function viewOriginality(upload, formToSubmit) {
    upload.getViewOriginalityUrl(
        url => {
            // we should use the hook useRefs here
            // we need to make the class a function to do that
            // the jquery submit method changes url
            if (formToSubmit.setAttribute) {
              formToSubmit.setAttribute('action', url)
              formToSubmit.submit()
            } else {
              formToSubmit.current.setAttribute('action', url)
              formToSubmit.current.submit()
            }
        })
}


function showOriginalityCheckModal(onClose, xhr) {
    let parms = {
        title: 'Run Originality',
        message: getMsg(xhr),
        hideYesButton: false,
        hideNoButton: true,
        yesButtonText: 'Ok',
        onClose: onClose,
        modalId: 'checkOriginality',
        onConfirmYes: onClose,
        open: true
    }
    let modal = <ConfirmModal {...parms} key={'checkOriginality'} />
    reactRender(modal, 'region-modal', parms.modalId)
}

function showAlert(xhr) {
    //TODO
    //if (model.status == 422) Assignment.trigger('wip:outdated')
    if (xhr) {
        subject.next({
            alert: {
                message: getMsg(xhr),
                id: 'check-originality'
            }
        })
    }
}

function getMsg(xhr) {
    if (xhr) {
        if (xhr.message) {
          return xhr.message
        } else if (xhr.responseText) {
            return xhr.responseText
        } else if (xhr.statusText) {
            return xhr.statusText
        } else {
            return 'Had Error'
        }
    } else {
        return 'Your file is being processed by Turnitin. It can take several minutes for your originality report to be made available to you. Please check back later to view your originality report.'
    }
}
