import SectionRowComp from './sectionRow'
import DPToolTip from '../../utility/tooltip'
import { isArt, isCSP } from '../../entities/courseinstance'
import { getCurrentPerson } from '../../entities/person'

const SectionTableComp = ({ parms }) => {
    const [sortBy, setSortBy] = useState('');
    const toolKey = 'section_table_' + parms.courseInstanceId;
    const toolTip = new DPToolTip(toolKey);

    useEffect(() => {
        toolTip.createTooltips();
        return () => toolTip.removeTooltips();
    }, [toolTip]);

    const serializeData = (parms) => {
        return {
            moduleHolder: parms.moduleHolder,
        };
    };

    const selectChange = (event) => {
        setSortBy(event.currentTarget.value);
    };

    const helpers = serializeData(parms);
    const art = isArt();
    const csp = isCSP();
    const isCoordinator = getCurrentPerson().isCoordinator();

    const header = modules => modules.map((module, index) => {
        if (module.lessons.length) {
            return (
                <th colSpan={module.lessons.length} scope="col" className="colgroup text-center" key={'header_' + index}>
                    {module.title}
                </th>
            );
        }
        return null;
    });

    const columns = modules => {
        let keyIndex = 1;
        return modules.flatMap(module =>
            module.lessons.map(lesson => (
                <th scope="col" className="text-center colgroup" key={'lesson_' + keyIndex++}>
                    <span data-cb-apricot='tooltip' id={art ? toolTip.removeTooltips() : toolTip.addToolTip()}
                        data-cb-title={lesson.title}
                        aria-describedby={'desc-' + lesson.abbreviation}>
                        {lesson.abbreviation}
                    </span>
                    <span id={'desc-' + lesson.abbreviation} className='sr-only'>{lesson.title}</span>
                </th>
            ))
        );
    };

    const sectionRows = () => {
        return parms.coordinatorProgress.sections.map((sectionModel, index) => {
            const toolKeySection = toolKey + '_section_' + sectionModel.jsonSection.sectionId;
            const parmsRow = {
                moduleHolder: parms.moduleHolder,
                isWe: parms.isWe,
                sectionModel: sectionModel,
                toolKey: toolKeySection,
                index: index
            };
            return <SectionRowComp parms={parmsRow} key={toolKeySection}></SectionRowComp>;
        });
    };

    return (
        <table className='table data-table'>
            <caption className="sr-only">Student Progress by Assignment, Per Section</caption>
            <thead>
                <tr>
                    <th className="empty" scope="col"><span className="sr-only">Student Info</span></th>
                    {header(helpers.moduleHolder.modules)}
                    {art && isCoordinator && <th className="empty" scope="col" style={{ width: '135px' }}><span className="sr-only">Download Portfolio</span></th>}
                    {csp && isCoordinator && <th className="empty" scope="col" style={{ width: '162px' }}><span className="sr-only">Download PPR</span></th>}
                    <th className="empty" scope="col"><span className="sr-only">Verification Status</span></th>
                </tr>
                <tr>
                    <th scope="col" className='text-center colgroup'>Name / Status</th>
                    {columns(helpers.moduleHolder.modules)}
                    {art && isCoordinator && <th scope="col" className="text-center colgroup">Download Portfolio</th>}
                    {csp && isCoordinator && !parms.isWe && <th scope="col" className="text-center colgroup">Download PPR</th>}
                    <th scope="col" className="text-center colgroup">Expand</th>
                </tr>
            </thead>
            <tbody id='studentRows'>
                {sectionRows()}
            </tbody>
        </table>
    );
};

export default SectionTableComp;
