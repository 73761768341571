import React, { Fragment } from "react"
import { Button } from "@cb/apricot-react"
import { reactRender } from "../../utility/util"
import {
  getCourseParameter, getCurrentSection,
  isAfterHardEndDate, isArt, isResearch_PREP, isSeminar_IRR_CP, isSeminar_IWA_CP
} from "../../entities/courseinstance"
import { pathToUrl } from "../../content/static_content"
import { bsliGet } from "../../_core/util/request"
import { runOriginality, viewOriginality } from "../../utility/originality"
import ReturnFileModalComp from "./returnFileModal"
import {entitiesUpload}    from '../../assignment/entities/upload'

const ScoringMenu = (props) => {
  const [state, setState] = useState({
    isSaving: false,
    upload: props.upload,
    updateCnt: 0
  })
  const [showReturnFileModal, setShowReturnFileModal] = useState(false)
  const [returnFileModalProps, setReturnFileModalProps] = useState(null)
  const formToSubmitRef = useRef(null)
  const [isSaving, setIsSaving] = useState(false)

  const templateHelpers = () => {
    let upload = state.upload
    let fileName = upload && upload.fileName ? upload.fileName : ""
    return {
      showSubmission: props.lesson.title !== "Images",
      showTurnitinButton: props.lesson.title !== "Images" && getCourseParameter("turnitinEnabled"),
      originalityRan: upload && upload.hasBeenSubmittedToTurnitin(),
      fileName: fileName,
      submissionUrl: upload ? (upload.url() + "/download") : "javascript:void(0)",
      printUrl: pathToUrl(props.cmsLesson.rubric),
      isArtWE: props.lesson.title === "Written Evidence",
      isDraftIn: props.scorableHolder.scorable.status === 2
    }
  }

  const printRubric = async () => {
    let url = pathToUrl(props.cmsLesson.rubric)
    try {
      let container = $("<div class=\"print hidden\" />").appendTo("body")
      let pdfDoc = await PDFJS.getDocument(url).promise
      for (let pageNumber = 1; pageNumber <= pdfDoc.numPages; pageNumber++) {
        let page = await pdfDoc.getPage(pageNumber)
        let viewport = page.getViewport({ scale: 300 / 96 })
        let canvas = $("<canvas>").attr({ height: viewport.height, width: viewport.width })[0]
        page.render({
          canvasContext: canvas.getContext("2d"),
          viewport: viewport,
          intent: "print"
        })
        container.append(canvas)
      }
      window.print()
    } catch (e) {
      console.log('PD Error:  ',e)
    } finally {
      container.remove()
    }
  }

  const getFileName = (url) => {
    let groups = /\/files\/(.*)$/.exec(url)
    if (_.isEmpty(groups) || groups.length < 1) {
      return "file.pdf"
    } else {
      return groups[1]
    }
  }

  const onCloseOriginality = () => {
    setState(state => {
      return {
        updateCnt: state.updateCnt++,
        ...state
      }
    })
  }

  const getUpdatedUpload = async () => {
    let upload = state.upload
    const updatedUpload = upload.id ? await entitiesUpload(upload.id, upload.assignmentId) : null
    state.upload = updatedUpload
    setState(state)
  }


  const runOrViewOriginality = () => {
    let upload = state.upload
    if (upload.hasBeenSubmittedToTurnitin()) {
      viewOriginality(upload, formToSubmitRef)
    } else {
      runOriginality(null, upload, false, onCloseOriginality, setIsSaving,  getUpdatedUpload)
    }
  }

  const openReturnFileModal = () => {
    setReturnFileModalProps({
      upload: state.upload,
      modalId: "returnFileModal",
      isWe: props.isWe,
      onClose: () => setShowReturnFileModal(false)
    })
    setShowReturnFileModal(true)
  }

  const downloadSubmission = async () => {
    let url = state.upload.getDownloadUrl()
    const response = await bsliGet(url)
    window.open(response.url, '_blank')
  }

  let helpers = templateHelpers()
  let label = helpers.originalityRan ? "View" : "Run"
  let title = helpers.originalityRan ? "View originality in TurnItIn" : "Submit " + helpers.fileName + " to TurnItIn"

  let upload = state.upload
  let noSubmission = !upload
  let currentSection = getCurrentSection()
  let student = props.scoree
  let pastHardEndDate = isAfterHardEndDate()
  let readOnly = currentSection.isArchived || ((pastHardEndDate || currentSection.pastSoftEndDate) ? !(student && student.enrollment && student.enrollment.hasExtension) : false)
  let disableCheckOriginality = noSubmission 
  let disableReturnFile = noSubmission || readOnly
  const hideButtonsForResAndSemSpecificAssignments = isSeminar_IWA_CP(props.lesson.assignmentId) || isSeminar_IRR_CP(props.lesson.assignmentId) || isResearch_PREP(props.lesson.assignmentId)

  return (
    <div className={`btn-toolbar pull-right-md ${hideButtonsForResAndSemSpecificAssignments ? 'hidden' : ''}`} role="toolbar" aria-label="Utility Bar">
      <div className="btn-group" role="group">
        {helpers.showTurnitinButton &&
          <> 
          <div id='region-modal'></div>
          <Button type="button"
                  className="js-turnitin" small="true"
                  disabled={disableCheckOriginality}
                  onClick={runOrViewOriginality}
                  loading={isSaving}
                  title={title}>
            {label} Originality 
          </Button>         
          </>
        }
        <Button type="button" className="js-return-file"
                disabled={disableReturnFile || helpers.isDraftIn} small="true"
                onClick={openReturnFileModal} data-target="#btnReturnFileToStudent">
          Return File to Student
        </Button>
        {helpers.showSubmission && (helpers.isArtWE || !isArt()) &&
          <Button type="button" className="js-download-file" small="true"
                  disabled={noSubmission} onClick={downloadSubmission}>
            View/Print Submission
          </Button>
        }
        {helpers.showSubmission && !isArt() &&
          <Button small="true" disabled={!props.cmsLesson.rubric} onClick={printRubric}>
            Print Rubric
          </Button>
        }
      </div>
      <form ref={formToSubmitRef} method="POST" target="_blank" action="" name="tiiForm"
            className="js-turnitin-form" encType="application/x-www-form-urlencoded">
      </form>
      <ReturnFileModalComp {...returnFileModalProps} open={showReturnFileModal} />
    </div>
  )
}

export default ScoringMenu
