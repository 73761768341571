import React                                    from 'react'
import SubmissionSearchComp                     from './submissionSearch'
import SubmissionResultsComp                    from './submissionResults'
import { bsliPostScores }                       from '../../_core/util/request'
import { showLoadingView , closeLoadingView}    from '../../_core/views/loading_view'
import { makeSureCourseModelHasData }           from "../../entities/courseinstance";

const ViewSubmission = (props) => {
    const [results, setResults] = useState(null)

    useEffect(() => {
      makeSureCourseModelHasData()
    }, [])

    return (
        <div id='view-submission'>
            <h4 className='cb-margin-top-16 cb-margin-bottom-16 cb-padding-top-8 cb-padding-bottom-8 cb-padding-left-8 cb-gray4-bg'>Submission Dashboard</h4>
            <div id="submission-search-form">
                <SubmissionSearchComp
                    student={props.params.student}
                    studentDefault={props.params.studentDefault}
                    setResults={setResults}
                />
            </div>
            <div id="region-spinner" className="cb-spacerv-32"></div>
            <div id="submission-search-results">
                <SubmissionResultsComp
                    results={results}
                />
            </div>
        </div>
    )
}

export default ViewSubmission
