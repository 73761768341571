import React, { useEffect, useState }       from 'react';
import { Select, Input, Modal, 
    NakedButton, YellowButton }             from '@cb/apricot-react';
import { noModalEscape, recordFocus, isValidTheme }                    from '../../utility/util'
import { isSeminar as is_seminar, 
    isResearch as is_research }             from '../../entities/courseinstance'
import CBForm                               from "@cb/apricot/CBForm";
import CBModal                              from "@cb/apricot/CBModal";
import { cleanUpBody }                      from '../../_core/views/modal';

let departmentValues = [
    {value: 0, label: 'English/Language Arts'},
    {value: 1, label: 'History and Social Sciences'},  
    {value: 2, label: 'Mathematics/Computer Science'},
    {value: 3, label: 'Science'},                  
    {value: 4, label: 'World Languages/Cultures'},     
    {value: 5, label: 'Interdisciplinary'},
    {value: 6, label: 'Other'}]

let fulfillValues = [
    {value: 0, label: 'A graduation requirement in the department you selected'},
    {value: 1, label: 'A graduation elective requirement'},
    {value: 2, label: 'Does not fulfill a graduation requirement'}]

let grades = [
    {value: 'Grade 9',        label: 'Grade 9'},
    {value: 'Grade 10',       label: 'Grade 10'},
    {value: 'Grade 11',       label: 'Grade 11'},
    {value: 'Grade 12',       label: 'Grade 12'},
    {value: 'Not applicable', label: 'Not applicable'},
    {value: 'Not sure',       label:  'Not sure'}]

let schedules = [
    {value: 'Traditional', label: 'Traditional'},
    {value: 'A/B Block',   label: 'A/B Block'},
    {value: 'Trimester',   label: 'Trimester'},
    {value: '4x4 Block',   label: '4x4 Block'}]   

export default function SeminarResearchSelectionModal(props) {
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [alertProps, setAlertProps] = useState(false);
    const [step, setStep] = useState(1)
    const [footerCls, setFooterCls] = useState()//          this.getFooterCls(this.minWindow),
    const [department, setDepartment] = useState(props.selections.department)
    const [fulfill, setFulfill] = useState(props.selections.fulfill)
    const [themes, setThemes] = useState(props.selections.themes)
    const [grade, setGrade] = useState(props.selections.grade)
    const [schedule, setSchedule] = useState(props.selections.schedule)
    const [isSeminar, setIsSeminar] = useState(is_seminar());
    const [isResearch, setIsResearch] = useState(is_research());
    const [closeEnable, setCloseEnable] = useState(false);
    const [mappedDepartmentValues, setMappedDepartmentValues] = useState([])
    const [mappedFulfillValues, setMappedFulfillValues] = useState([])


    useEffect(() => {
        document.querySelectorAll('.selectionContent').forEach(function (elem) {
            CBForm.customFormElement({
                elem: elem.querySelector('checkbox')
            })
        })
        if (props.parms.mustAnswer) {
            noModalEscape(props.parms.modalId)
        }

        if(isSeminar) {
            setCloseEnable(props.selections.department !== "" &&
                props.selections.fulfill !== "" &&
                props.selections.themes !== "" && 
                props.selections.grade !== "" &&
                props.selections.schedule !== "" )
        }

        if(isResearch) {
            setCloseEnable(props.selections.department !== "" &&
                props.selections.fulfill !== "")
        }

        setSaveDisabled(true)

        setMappedDepartmentValues(departmentValues.map(value => {
            const newValue = value.label
            return ({
                ...value,
                value: newValue
            })
        }))

        setMappedFulfillValues(fulfillValues.map(fulfill => {
            const newValue = fulfill.label
            return ({
                ...fulfill,
                value: newValue
            })
        }))
    }, [])

    useEffect(() => {    
        if(isSeminar) {
            setSaveDisabled(department === "" || fulfill === "" || themes === "" || grade === "" || schedule === "" || 
                (department === props.selections.department && 
                 fulfill === props.selections.fulfill && 
                 themes === props.selections.themes &&
                 grade === props.selections.grade && 
                 schedule === props.selections.schedule
                )
            )
        }
        if(isResearch) {
            setSaveDisabled(department === "" || fulfill === "" ||
            (department === props.selections.department && 
                fulfill === props.selections.fulfill))
        }
    }, [department, fulfill, themes, grade, schedule])

    const submit = async () => {
        await props.parms.save(props.parms.sectionId, {department, fulfill, themes, grade,schedule})
        recordFocus('editBtn')
        if (props.parms.modalId) {
            CBModal({
                elem: props.parms.modalId
            }).close()
            cleanUpBody()
        }
        props.parms?.getUpdatedData()
    }

    const inputChange = (event, property) => {
        const val = event.target.value
        if (isValidTheme(val)) {
            setThemes(val)
        }
    }

    const showAlert = (message, uniqueId) => {
        setAlertProps({
            isDismissable: false,
            showAlert: true,
            message: message,
            uniqueId: uniqueId
        })
    }

    const getContent = () => {
        //Save button becomes enabled
        return (
            <div className='selectionContent'>
                {/* question 1  */}
                <div>
                 {(isSeminar) &&
                   <label className='question' htmlFor="department">Which department does your AP Seminar section belong to?</label>
                }
                 {(isResearch) &&
                   <label className='question' htmlFor="department">Which department does your AP Research section belong to?</label>
                }
                </div>
                <div>
                    <Select
                        id="department" name="department"
                        ariaLabel="Select Department"
                        values={mappedDepartmentValues} labelNoValue="Select Department"
                        value={department}
                        onChange={e => setDepartment(e)}
                    />
                </div>
                {/* question 2 */}
                <div style={{ marginTop: '20px' }}>
                {(isSeminar) &&
                    <label className='question' htmlFor="department">What does your AP Seminar section fulfill?</label>
                }
                {(isResearch) &&
                    <label className='question' htmlFor="department">What does your AP Research section fulfill?</label>
                }
                 </div>
                 <div>
                    <Select
                        id="fulfill" name="fulfill"
                        ariaLabel="What section fulfills"
                        values={mappedFulfillValues} labelNoValue="Select Fulfillment"
                        value={fulfill}
                        onChange={e => setFulfill(e)}
                    />
                </div>

                {/* question 3, 4, 5 for Seminar only */} 
                {(isSeminar) && <>
                    <div style={{ marginTop: '20px' }}>
                        <label className='question'> For students in the United States who have to pass a state exam in English to graduate high school, in what grade do students typically take the state exam?</label>
                    </div>   
                    <div>
                        <Select
                        id="fulfill" name="fulfill"
                        ariaLabel="What section fulfills"
                        values={grades} labelNoValue="Select Grade"
                        value={grade}
                        onChange={e => setGrade(e)}
                        />
                    </div>

                    <div style={{ marginTop: '20px' }}>
                        <label className='question'>What themes do you incorporate into your AP Seminar instruction before students begin Performance Tasks (Example: African Diaspora, Food Scarcity, Technology, etc.)?</label>
                    </div>    
                    <div>
                         <Input type="text" 
                            name='themes' id='themes' 
                            required="true"
                            placeholder='Theme(s) Incorporated...'
                            defaultValue={themes} 
                            maxLength='255'
                            onChange={e => inputChange(e)} 
                         />
                    </div> 
                                 
                    <div style={{ marginTop: '20px' }}>
                        <label className='question'>Which schedule best describes how Seminar is offered?</label>
                    </div>    
                    <div>
                        <Select
                            id="schedule" name="schedule"
                            ariaLabel="Select Schedule"
                            values={schedules} labelNoValue="Select Schedule"
                            value={schedule}
                            onChange={e => setSchedule(e)}
                        />
                    </div>                    
                    </>
                }
            </div>
        )
    }

    const Footer = (
        <Fragment>
            {!props.parms.mustAnswer &&
                <NakedButton type="button" id='close-btn' className="cb-no-padding cb-btn-close"
                    data-cb-modal-close>
                    Cancel
                </NakedButton>
            }
            <YellowButton type="button" className='js-submit-final' small='true'
                onClick={() => submit()} disabled={saveDisabled}>
                Save
            </YellowButton>
        </Fragment>
    )

    return (
        <Modal 
            title={props.parms.title}
            open={true}
            escClose={false}
            withHeader
            withFooter
            modalId={props.parms.modalId}
            footer={Footer}
            clickOverlayToClose={closeEnable}
            showHeaderCloseGlyph={closeEnable}
            showHeaderCloseButton = {closeEnable}
        >
            {getContent()}
        </Modal>
    )
}
