import { bsliGet, bsliPostScores } from '../_core/util/request'

export class ScoreHolder {
  constructor(scores) {
    this.scores = scores
  }

  init(scorableId) {
    return bsliGet('scorables/' + scorableId + '/scores')
      .then(json => {
        this.scorableId             = json.scorableId // is the same as passed in parameter
        this.scores                 = json.scores // scores have same properties as Rubric
        this.submittedPlagiarism    = json.submittedPlagiarism
        this.notes                  = json.notes
        this.comments               = json.comments
        this.notPlagiarismAttestationInd = json.notPlagiarismAttestationInd
        //console.log('ScoreHolder init', json)
        return this
      })
  }

  save() {
    let data = this.toJSON()
    let dataStr = data || {}
    //console.log('dataStr ' + dataStr)
    // the regular post method does not take json
    return bsliPostScores('scorables/' + this.scorableId + '/scores', dataStr)
  }

  toJSON() {
    return {
      scorableId: this.scorableId,
      scores: _.map(this.scores, score => {
        return {
          rubricDimensionId:  score.rubricDimensionId,
          score:              score.score,
          comments:           score.comments || ''
        }
      }),
      submittedPlagiarism: !!this.submittedPlagiarism,
      notPlagiarismAttestationInd:this.notPlagiarismAttestationInd,
      notes: this.notes || '',
    }
  }

  scoreForDimension(dimensionId) {
    return _.findWhere(this.scores, { rubricDimensionId: dimensionId })
  }

  setScore(score, dimensionId) {
    this.scoreForDimension(dimensionId).score = score
  }

  deadlinePassed() {
    return this.scoringDeadlineReached === true
  }

  clearScores() {
    this.scores = []
    // this.scores').forEach(function (score) {
    //   delete score.score
    // });
  }

  setComments(comments, dimensionId) {
    this.scoreForDimension(dimensionId).comments = comments
  }
}

export function entitiesScore(scorableId) {
  //app.reqres.setHandler('entities:score', function(scorableId) {
  return (new ScoreHolder()).init(scorableId)
  //return new Entities.Score({scorableId: scorableId}).fetch()
}

