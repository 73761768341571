import { Notification } from "@cb/global-widgets-react";
import { notificationLoad, getBannerMessage } from "./utils"

const NotificationWidget = () => {
	const [notifications, setNotifications] = useState([])

  const retrieveBannerMsgOnly = async () => {
		const bannerMessage = await getBannerMessage()
		setNotifications(bannerMessage)
  }

  const retrieveNotifications = async () => {
    const results = await notificationLoad()
		const bannerMessage = await getBannerMessage()
		if (bannerMessage.length && notifications.find(notification => notification.id === bannerMessage[0].id)) {
			setNotifications(results)
		} else {
			setNotifications([...results, ...bannerMessage])
		}
  }

	useEffect(() => {
		if (location.pathname.includes('dashboard') || location.pathname.includes('overview') && !location.pathname.includes('modules') || location.pathname.includes('courses')) {
			retrieveNotifications()
		}	else {
			retrieveBannerMsgOnly()
		}
	}, [location.pathname])

	return notifications?.length ? <Notification notifications={notifications} useService={false} appId={295} /> : null
}

export default NotificationWidget
