import React                                    from 'react';
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker                               from "react-datepicker";

import { bsliGet, bsliDelete, bsliPut }         from '../../_core/util/request';
import { YellowButton, SquareButton }           from '@cb/apricot-react';
import { cleanUpBody  }                         from '../../_core/views/modal.jsx'
import { isValidEnterprisePersonId, isValidSectionId }   from '../../utility/util'
import CBModal                                  from '@cb/apricot/CBModal';

export default class ManageExtensionComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //state is by default an object
            sections: [],
            showSectionDiv: false,
            showEnrollmentDiv: false,
            isDisabled:true,
            showErrorMessage:false,
            showExtDialog:false,
            date:'',
            filteredSections: [],
            showModal:false,
            selAproEnrollmentId:'',
            selAproSectionId:''
        }
        this.changeHandler = this.changeHandler.bind(this);

    }
    componentDidMount() {
        document.querySelectorAll('.form-group').forEach(function (elem) {
            window.cb.apricot.CBForm.customSelectElement({
                elem: elem.querySelector('select')
            })
        })
    }
    changeHandler(event) {
        var inputId = event.target.value
        let $elem = $('#searchby')
        var searchby = $elem.val()
        if (searchby === 'section'){
            if (isValidSectionId(inputId)){
                this.setState({isDisabled: false});
            } else {
                this.setState({isDisabled: true});
            }
        } else {
           if (isValidEnterprisePersonId(inputId)) {
                this.setState({isDisabled: false});
            } else {
                this.setState({isDisabled: true});
            }
        }
    }
    render() {
        const DatePickerInput = React.forwardRef(({onChange, placeholder, value, id, onClick}, ref) => {
            return <span className="row">
               <span>
                <input
                    onChange={onChange}
                    placeholder={placeholder}
                    value={value}
                    id={id}
                    onClick={onClick}
                />
               </span>
                <span>
                <button type="button" className="ui-datepicker-trigger" onClick={onClick}>Show Calendar</button>
                </span>
           </span>
        });

        const renderModal =  (action) => {
        return (
            <div  className="cb-modal" id={"sectionModal".concat(action)} aria-hidden="true"
            data-cb-apricot="modal">
            <div className="cb-modal-overlay" data-cb-modal-close>
              <div className="cb-modal-container" role="dialog" aria-modal="true" aria-labelledby="title">
                <div className="cb-modal-header">
                  <h4 id="title" className="h4"> {action} Section Extension</h4>
                  <button id='close' type="button" className="cb-btn cb-btn-square cb-btn-greyscale cb-btn-close"
                          aria-describedby="title close" tabIndex='0'
                          data-cb-modal-close>
                    <span className="cb-glyph cb-x-mark" aria-hidden="true"></span>
                    <span className="sr-only">Close</span>
                  </button>
                </div>
                <div className="cb-modal-content">
                  {renderModalContent(action)}
                </div>
                <div className="cb-modal-footer">
                  <div className="cb-btn-row">
                      <button id='cancel' type="button" className="cb-btn cb-btn-naked cb-no-padding cb-btn-close"
                              aria-describedby="title cancel" tabIndex='0'
                              data-cb-modal-close>
                          Cancel
                      </button>
                      {action != "Delete" ?
                          <YellowButton disabled={!this.selectionMade()}
                                        onClick={(e) => this.executeAction(e.target, action, "section")}>Submit</YellowButton>
                          : <YellowButton  onClick={(e) => this.executeAction(e.target, action, "section")}>Submit</YellowButton>

                      }</div>
                </div>
              </div>
            </div>
          </div>
        )}

        const renderModalContent = (action) => {
            if(this.state.sections.length > 0) {
                return   this.state.sections.map((section, index) => {
                    let { aproSectionId, courseTitle, sectionId,orgId,sectionTitle,softEndDate,teacherName,hardEndDate,extendedEndDate } = section //destructuring
                    return (
                        <div key={aproSectionId}>
                            { action != "Delete" ?
                            <div className="row" key={"edit".concat(aproSectionId)}>
                                <p>Enter the extended end date for this section.</p>
                            </div>
                            :<div className="row" key={"delete".concat(aproSectionId)}>
                            <p>Are you sure you want to delete this extended end date?</p>
                        </div>}
                            <br/>
                            <div className="row" key={"aproSectionId".concat(aproSectionId)}>
                                <span><b>Apro Section ID :</b> {aproSectionId}</span>
                            </div>
                            <div className="row" key={"courseTitle".concat(aproSectionId)}>
                                <span><b>Course Title :</b> {courseTitle}</span>
                            </div>
                            <div className="row" key={"sectionTitle".concat(aproSectionId)}>
                                <span><b>Section Title :</b> {sectionTitle}</span></div>
                            <div className="row" key={"teacherName".concat(aproSectionId)}>
                                <span><b>Teacher :</b> {teacherName}</span></div>
                            <div className="row" key={"softEndDate".concat(aproSectionId)}>
                                <span><b>Soft End Date :</b> {moment(softEndDate).format('MM/DD/yyyy')}</span>
                            </div>
                            <div className="row"  key={"hardEndDate".concat(aproSectionId)}>
                                <span><b>Hard End Date :</b> {moment(hardEndDate).format('MM/DD/yyyy')}</span>
                            </div>
                            { action == "Delete" ?
                                <div className="row" key={"extendedEndDate".concat(aproSectionId)}>
                                    <span><b>Extended End Date :</b> {moment(extendedEndDate).format('MM/DD/yyyy')}</span>
                                </div>
                                :null}
                            { action != "Delete" ?

                                <div className="cb-input form-group" key={"editExtendedEndDate".concat(aproSectionId)}>
                                    <DatePicker
                                        placeholderText="mm/dd/yyyy"
                                        maxDate = {hardEndDate}
                                        minDate = {new Date(moment(softEndDate).add(1, 'day').format('MM/DD/yyyy'))}
                                        dateFormat="MM/dd/yyyy"
                                        selected={this.state.date?this.state.date:extendedEndDate}
                                        onChange={ date =>  this.setState ({["date"]: date, ["url"]: null})}
                                        customInput={<DatePickerInput />}

                                    />
                                </div>
                                :null}
                        </div>
                    )
                })
            }
        }

        const renderStudentModal =  (action) => {
            return (
                <div  className="cb-modal" id={"studentModal".concat(action)} aria-hidden="true" data-cb-apricot="modal">
                <div className="cb-modal-overlay" data-cb-modal-close>
                    <div className="cb-modal-container" role="dialog" aria-modal="true" aria-labelledby="title">
                        <div className="cb-modal-header">
                            <h4 id="title" className="h4"> {action} Student Extension</h4>
                            <button id='close' type="button" className="cb-btn cb-btn-square cb-btn-greyscale cb-btn-close"
                                    aria-describedby="title close" data-cb-modal-close>
                                <span className="cb-glyph cb-x-mark" aria-hidden="true"></span>
                                <span className="sr-only">Close</span>
                            </button>
                        </div>
                        <div className="cb-modal-content">
                            {renderStudentModalContent(action)}
                        </div>
                        <div className="cb-modal-footer">
                            <div className="cb-btn-row">
                                <button id='cancel' type="button" className="cb-btn cb-btn-naked cb-no-padding cb-btn-close"
                                        aria-describedby="title cancel" data-cb-modal-close>
                                    Cancel
                                </button>
                                {action != "Delete" ?
                                    <YellowButton disabled={!this.selectionMade()}
                                                  onClick={(e) => this.executeAction(e.target, action, "enrollment")}>Submit</YellowButton>
                                    : <YellowButton   onClick={(e) => this.executeAction(e.target, action, "enrollment")}>Submit</YellowButton>

                                } </div>
                        </div>
                    </div>
                </div>
            </div>
            )}

        const renderStudentModalContent = (action) => {
            if(this.state.filteredSections.length > 0) {
                return this.state.filteredSections.map((section, index) => {
                    let {enrollmentId, aproEnrollmentId, dpPersonId, enterprisePersonId, firstName, lastName, aproSectionId, courseTitle, sectionTitle, teacherName, softEndDate, hardEndDate, extendedEndDate,lateSubmissionsDt} = section //destructuring
                    return (
                        <div key={aproEnrollmentId}>
                            { action != "Delete" ?
                            <div key={"add".concat(aproEnrollmentId)} className="row">
                                <p>Enter the extended end date for this section.</p>
                            </div>
                                :<div key={"delete".concat(aproEnrollmentId)} className="row">
                                    <p>Are you sure you want to delete this extended end date?</p>
                                </div>}
                            <br/>
                            <div key={"aproEnrollmentId".concat(aproEnrollmentId)} className="row">
                                <span><b>Apro Enrollment ID :</b> {aproEnrollmentId}</span>
                            </div>
                            <div  key={"student".concat(aproEnrollmentId)} className="row">
                                <span><b>Student :</b> {lastName} {firstName}</span>
                            </div>
                            <div key={"aproSectionId".concat(aproEnrollmentId)} className="row">
                                <span><b>Apro Section ID :</b> {aproSectionId}</span></div>
                            <div key={"courseTitle".concat(aproEnrollmentId)} className="row">
                                <span><b>Course Title :</b> {courseTitle}</span></div>
                            <div  key={"sectionTitle".concat(aproEnrollmentId)} className="row">
                                <span><b>Section Title :</b> {sectionTitle}</span></div>
                            <div className="row"  key={"teacherName".concat(aproEnrollmentId)}  >
                                <span><b>Teacher :</b> {teacherName}</span></div>
                            <div className="row" key={"softEndDate".concat(aproEnrollmentId)} >
                                <span><b>Soft End Date :</b> {moment(softEndDate).format('MM/DD/yyyy')}</span>
                            </div>
                            <div className="row" key={"EndDate".concat(aproEnrollmentId)}>
                                <span><b>Hard End Date :</b> {moment(hardEndDate).format('MM/DD/yyyy')}</span>
                            </div>
                            <div className="row" key={"lateSubmissionDt".concat(aproEnrollmentId)}>
                                <span><b>Late Submission Date :</b>  {lateSubmissionsDt != null ? moment(lateSubmissionsDt).format('MM/DD/yyyy') : null}</span>
                            </div>
                            { action == "Delete" ?
                                <div className="row">
                                    <span><b>Extended End Date :</b> {moment(extendedEndDate).format('MM/DD/yyyy')}</span>
                                </div>
                                :null}
                            { action != "Delete" ?

                                <div className="cb-input form-group">
                                    <DatePicker
                                        placeholderText="mm/dd/yyyy"
                                        maxDate = {lateSubmissionsDt}
                                        minDate = {new Date(moment(softEndDate).add(1, 'day').format('MM/DD/yyyy'))}
                                        dateFormat="MM/dd/yyyy"
                                        selected={this.state.date?this.state.date:extendedEndDate}
                                        onChange={ date =>  this.setState ({["date"]: date, ["url"]: null})}
                                        customInput={<DatePickerInput />}

                                    />
                                </div>
                                :null}
                        </div>
                    )
                })
            }
        }
        const searchData = () => {
            this.search();
        };
        return (
            <div>
                <div id="admin-search-form" className="row ">
                    <div className="col-lg-8 col-md-9 col-xs-12 padding-0">
                        <h4 className="h1">Manage Extensions</h4>
                        <div  className="form-group cb-select report">
                            <label htmlFor="inputID">Search by:</label>
                            <select className="form-control" name="searchby" id="searchby"  >
                                <option value="section">Section ID</option>
                                <option value="student">Student ID</option>
                            </select>
                        </div>
                        <div className="cb-input">
                            <label htmlFor="inputID"></label>
                            <input type="text" id="inputID"  onChange={this.changeHandler} maxLength={20}/>
                        </div>
                        <div className="cb-input form-group">
                            <button id="searchbtn" type="submit" className="cb-btn cb-btn-sm cb-btn-yellow js-submit"
                                disabled={this.state.isDisabled}
                                data-cb-apricot="tooltip" onClick={searchData} data-cb-title="Search">
                                Search
                            </button>
                        </div>
                    </div>
                    <div className="cb-input form-group col-lg-12 col-md-12 col-xs-16 padding-0">{ this.state.showErrorMessage ?
                        <div>
                            <hr/><br/><span className="cb-red1-color"> {this.rendeErrorMessage() }</span>
                        </div>
                        :null}
                    </div>
                    <div className="cb-input form-group col-lg-12 col-md-12 col-xs-16 padding-0">{ this.state.showSectionDiv ?
                        <table id='courses' className="cb-table">
                            <thead>
                            <tr>
                                <th scope="col">Section ID</th><th scope="col">APRO Section ID</th><th scope="col">Section Title</th><th scope="col">Course Title</th>
                                <th scope="col">Org ID</th><th scope="col">Teacher Name</th><th scope="col">Soft End Date</th><th scope="col">Hard End Date</th>
                                <th style={{
                                    textAlign:"center"
                                }}>Extended End Date</th>
                            </tr></thead>
                            <tbody>{this.renderSectionDetail()}</tbody>
                        </table>
                        :null}
                    </div>
                    <div className="cb-input form-group col-lg-12 col-md-12 col-xs-16 padding-0">{ this.state.showEnrollmentDiv ?
                        <table id='courses' className="cb-table">
                            <thead>
                            <tr>
                                <th scope="col">Enrollment ID</th>
                                <th scope="col">APRO Enrollment ID</th>
                                <th scope="col">DP Person ID</th>
                                <th scope="col">Enterprise Person ID</th>
                                <th scope="col">First Name</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">APRO Section ID</th>
                                <th scope="col">Course Title</th>
                                <th scope="col">Section Title</th>
                                <th scope="col">Teacher Name</th>
                                <th scope="col">Soft End Date</th>
                                <th scope="col">Hard End Date</th>
                                <th scope="col">Late Submission Date</th>
                                <th style={{
                                    textAlign:"center"
                                }}>Extended End Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderEnrollmentDetail()}
                            </tbody>
                        </table>
                        :null}

                    </div>
                    <div>
                        {renderModal("Add")}
                        {renderModal("Edit")}
                        {renderModal("Delete")}
                        {renderStudentModal("Add")}
                        {renderStudentModal("Edit")}
                        {renderStudentModal("Delete")}

                    </div>
                    </div>

            </div>
        )
    }

    selectionMade () {
        if (this.state && this.state.date) {
            return true;
        } else {
            return false;
        }
    }

    executeAction(target,action,module) {
        let url= ""
        if(module == "section"){
            url = 'admin/extension/section/'+this.state.selAproSectionId.aproSectionId;
        }
        else{
            url = 'admin/extension/enrollment/'+this.state.selAproEnrollmentId;
        }
        var extendedEndDateMilliseconds = moment(this.state.date , 'MM/DD/YYYY hh:mm:ss a Z').valueOf();
        let datestr = moment(extendedEndDateMilliseconds).format('MM/DD/yyyy')
        if(action == 'Delete'){
            bsliDelete(url, {
                "extendedEndDate": extendedEndDateMilliseconds,
            }).then(() => {
                let el = target ? target.previousElementSibling : null
                if (el) {
                    el.click();
                }
               // this.bounceState();
                this.search();
            })
            .catch(() => {
                this.displayAlert("Error happenned please try again.", "editmodule");
            })
        }else{
            bsliPut(url, {
                "extendedEndDate": extendedEndDateMilliseconds,
            }).then(() => {
                let el = target ? target.previousElementSibling : null
                if (el) {
                    el.click();
                }
                // this.bounceState();
                this.search();
            })
            .catch(() => {
                this.displayAlert("Error happenned please try again.", "editmodule");
            })
        }

    }
    rendeErrorMessage() {
        let $elem = $('#searchby')
        var searchby = $elem.val()
        if(searchby == 'section'){
            return "Sorry, problem getting the results for this section ID."
        }else{
            return "Sorry, there were no results for that student ID. Check the ID and try your search again."
        }

    }

    renderSectionDetail() {
        if(this.state.sections.length > 0){
            return this.state.sections.map((section, index) => {
                let { aproSectionId, courseTitle, sectionId,orgId,sectionTitle,softEndDate,teacherName,hardEndDate,extendedEndDate } = section //destructuring
                return (
                    <tr key={sectionId}>
                        <td>{sectionId}</td><td>{aproSectionId}</td><td>{sectionTitle}</td><td>{courseTitle}</td><td>{orgId}</td><td>{teacherName}</td><td>{moment(softEndDate).format('MM/DD/yyyy')}</td>
                        <td>{moment(hardEndDate).format('MM/DD/yyyy')}</td>
                        <td align="center" width="16%"> {extendedEndDate != null ? <div>{moment(extendedEndDate).format('MM/DD/yyyy')}
                                <span> <SquareButton icon = "compose" type="button" className="cb-no-padding"  onClick={ () => this.popModalSection("sectionModalEdit",{aproSectionId})}>
                            </SquareButton></span>
                                <span> <SquareButton icon = "x-mark" type="button" className="cb-no-padding"   onClick={ () => this.popModalSection("sectionModalDelete",{aproSectionId})}>
                            </SquareButton></span>
                            </div>:
                            <div>{hardEndDate != softEndDate ? <SquareButton icon = "plus" type="button" className="cb-no-padding"  onClick={ () => this.popModalSection("sectionModalAdd",{aproSectionId})}>
                            </SquareButton>:null }</div>}
                        </td>
                    </tr>
                )
            })
        }

    }

    renderEnrollmentDetail() {
        if(this.state.sections.length > 0) {
            return this.state.sections.map((section, index) => {
                let {enrollmentId, aproEnrollmentId, dpPersonId, enterprisePersonId, firstName, lastName, aproSectionId, courseTitle, sectionTitle, teacherName, softEndDate, hardEndDate, lateSubmissionsDt, extendedEndDate} = section //destructuring
                return (
                    <tr key={enrollmentId}>
                        <td key={"enrollmentId".concat(enrollmentId )} >{enrollmentId}</td>
                        <td key={"aproEnrollmentId".concat(enrollmentId )}>{aproEnrollmentId}</td>
                        <td key={"dpPersonId".concat(enrollmentId )}>{dpPersonId}</td>
                        <td key={"enterprisePersonId".concat(enrollmentId )}>{enterprisePersonId}</td>
                        <td key={"firstName".concat(enrollmentId )}>{firstName}</td>
                        <td key={"lastName".concat(enrollmentId )}>{lastName}</td>
                        <td key={"aproSectionId".concat(enrollmentId )}>{aproSectionId}</td>
                        <td key={"courseTitle".concat(enrollmentId )}>{courseTitle}</td>
                        <td key={"sectionTitle".concat(enrollmentId )}>{sectionTitle}</td>
                        <td key={"teacherName".concat(enrollmentId )}>{teacherName}</td>
                        <td key={"softEndDate".concat(enrollmentId )}>{moment(softEndDate).format('MM/DD/yyyy')}</td>
                        <td key={"hardEndDate".concat(enrollmentId )}>{moment(hardEndDate).format('MM/DD/yyyy')}</td>
                        <td key={"lateSubmissionsDt".concat(enrollmentId )}>{moment(lateSubmissionsDt).format('MM/DD/yyyy')}</td>
                        <td key={"extension".concat(enrollmentId )} width="17%" align="center"> {extendedEndDate != null ? <span>{moment(extendedEndDate).format('MM/DD/yyyy')}
                                <span> <SquareButton icon = "compose" type="button" className="cb-no-padding"   onClick={ () => this.popModal("studentModalEdit",{enrollmentId})}>
                            </SquareButton></span>
                                <span> <SquareButton icon = "x-mark" type="button" className="cb-no-padding"   onClick={ () => this.popModal("studentModalDelete",{enrollmentId})}>
                            </SquareButton></span>
                            </span>:
                            <span>{hardEndDate != softEndDate ? <SquareButton icon = "plus" type="button" className="cb-no-padding"   onClick={ () => this.popModal("studentModalAdd",{enrollmentId})}>
                            </SquareButton > :null }</span>}
                        </td>
                    </tr>
                )
            })
        }
    }
    async search() {
        let $elem = $('#searchby')
        var searchby = $elem.val()
        try {
            if(searchby == 'section'){
                await this.showSectionResults();
            } else{
                await this.showEnrollmentResults();
            }
        } catch(e) {
            console.log('search threw error',e)
            this.setState({["showSectionDiv"]: false});
            this.setState({["showEnrollmentDiv"]: false});
            this.setState({["showErrorMessage"]: true});
        }
    }

    async showSectionResults () {
        let $elem = $('#inputID')
        var inputID = $elem.val()
        let searchData = await bsliGet('admin/extension/section/'+inputID);
        this.setState({["sections"]: searchData});
        if(searchData.length > 0) {
            this.setState({["showSectionDiv"]: true});
            this.setState({["showEnrollmentDiv"]: false});
            this.setState({["showErrorMessage"]: false});

        } else {
            this.setState({["showSectionDiv"]: false});
            this.setState({["showEnrollmentDiv"]: false});
            this.setState({["showErrorMessage"]: true});
        }
    }

    async showEnrollmentResults () {
        const inputID = document.getElementById('inputID').value;
        let searchData = await bsliGet('admin/extension/person/'+inputID);
        this.setState({["sections"]: searchData});
        if(searchData.length > 0) {
            this.setState({["showSectionDiv"]: false});
            this.setState({["showEnrollmentDiv"]: true});
            this.setState({["showErrorMessage"]: false});
        } else {
            this.setState({["showSectionDiv"]: false});
            this.setState({["showEnrollmentDiv"]: false});
            this.setState({["showErrorMessage"]: true});
        }
    }

    popModalSection(target,aproSectionId) {
        this.setState ({["date"]: null})
        this.state.selAproSectionId=aproSectionId;
        CBModal({
            elem: target
        }).show()
    }

    popModal(target,id) {
        this.setState({ ["showModal"]: true });
        this.setState ({["date"]: null})
        if(this.state.sections.length > 0) {
            this.state.sections.map((section, index) => {
                let {enrollmentId, aproEnrollmentId, dpPersonId, enterprisePersonId, firstName, lastName, aproSectionId, courseTitle, sectionTitle, teacherName, softEndDate, hardEndDate, extendedEndDate} = section //destructuring
                if(id.enrollmentId == enrollmentId){
                    this.state.filteredSections[0]=section;
                    this.state.selAproEnrollmentId=aproEnrollmentId;

                }
            })
        }
        CBModal({
            elem: target
        }).show()
    }

    dissmissModal(target) {
        CBModal({
            elem: target
        }).close()
        cleanUpBody()
    }

}
