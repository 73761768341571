import {Enrollment} from '../enrollment/entities/enrollment'
import {getCurrentSectionId} from '../entities/courseinstance'
import {getRubricHolder} from '../entities/rubric'
import {entitiesScore} from '../entities/score'
import {entitiesUpload} from '../assignment/entities/upload'
import {subtask} from '../content/static_content'
import {isEmpty} from 'underscore'
import Title from '../scoring/views/title'
import ScoringMenu from '../scoring/views/scoringMenu'
import SubmissionLayout from '../scoring/views/submissionLayout'
import RubricLayout from '../scoring/views/rubricLayout'
import React from 'react'
import {Spinner} from '@cb/apricot-react'
import {useLocation, useNavigate} from 'react-router-dom'
import Header from '../scoring/views/header'
import {useTeam} from '../entities/team'

let plagiarismData = {}

const ScoringExperience = () => {
  const location = useLocation(), navigate = useNavigate()
  const { scorableHolder, team, isWe, lesson, studentData } = location?.state || {}
  const scoree = lesson?.isTeamLesson ? { team: useTeam(team) } : new Enrollment(studentData)
  const weIndicator = isWe ? '1' : '0'
  const url = `scoring/sections/${scorableHolder?.sectionId || getCurrentSectionId()}/scorable/${
    scorableHolder?.scorable.id
  }/${weIndicator}`
  const [params, setParams] = useState(null)
  const [loading, setLoading] = useState(false)
  let scorable = null

  if (scorableHolder) scorable = scorableHolder.scorable

  const getRubricAndScoreData = async funcs => {
    const [rubricHolder, scoreHolder, upload, cmsLesson] = await Promise.all(funcs)
    if (isEmpty(scoreHolder.scores)) scoreHolder.scores = rubricHolder.rubrics?.map(rubric => ({ rubricDimensionId: rubric.id }))
    plagiarismData = { scorable, scoree, upload, scoreHolder }
    setParams({
      url,
      lesson,
      cmsLesson,
      upload,
      rubricHolder,
      scoree,
      scorable,
      scorableHolder,
      scoreHolder,
      isWe,
    })
  }

  useEffect(() => {
    if (!location.state) {}
    else {
      setLoading(true)
      getRubricAndScoreData([
        getRubricHolder(scorable.assignmentId),
        entitiesScore(scorable.id),
        scorable.uploadId ? entitiesUpload(scorable.uploadId, scorable.assignmentId) : null,
        subtask(lesson.id || lesson.lessonId),
      ])
    }
  }, [])

  useEffect(() => {
    if (params) setLoading(false)
  }, [params])

  useEffect(() => {
    if (!loading && !params && !location.state) return navigate('..', { relative: 'path' })
  }, [loading, params, location.state])

  if (loading) return <Spinner />

  if (params)
    return (
      <>
        <Header {...params} />
        <div className='container-fluid'>
          <div className='row add-top-margin-3x'>
            <div className='col-md-6' id='region-title'>
              <Title {...params} />
            </div>
            <div className='col-md-6 flex-nowrap' id='region-utility'>
              <ScoringMenu {...params} />
            </div>
          </div>
          <div className='row add-top-margin-3x'>
            <div className='col-md-7 col-sm-7' id='region-submission'>
              <SubmissionLayout {...params} />
            </div>
            <div className='col-md-5 col-sm-5' id='region-rubric'>
              <RubricLayout {...params} />
            </div>
          </div>
        </div>
      </>
    )

  return null
}

export default ScoringExperience
