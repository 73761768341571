import React, { Fragment }          from 'react';
import { getCourseInstanceId }      from '../../entities/courseinstance'
import { navigate }                 from '../../utility/history'

import { BaseModal } from '../../_core/views/modal'

// formerly Assignment.UploadModal
export default class PDFConversionModal extends BaseModal {
  constructor(props) {
    super(props);
    // props.parms has modalId
    _.bindAll(this, 'showInst', 'onClose')
  }

  getHeader() {
    return (
      <React.Fragment>
        <h2 className="cb-h5 cb-modal-title" id="modalTitle">Final Submission</h2>
        <button type="button" className="cb-btn cb-btn-sm cb-btn-square cb-btn-greyscale cb-btn-close"
          onClick={this.onClose} data-cb-modal-close>
          <span className="cb-glyph cb-x-mark" aria-hidden='true'/>
          <span className="sr-only">Close Window</span>
        </button>
      </React.Fragment>
    )
  }

  getContent() {
    return (
      <React.Fragment>
        <p>
          The file you are trying to submit is not in PDF format. Please save your file as a PDF and try again. 
          Remember to remove all personal information from your final submission.
        </p>
        <p>
          <a href="#" className="js-help-link" title="FAQ: Instructions for Converting a File to PDF"
            onClick={this.showInst}>
            Click here</a> for instructions on converting a file to PDF.
        </p>
      </React.Fragment>
    )
  }

  getFooter() {
    return (
      <React.Fragment>
        <button type="button" className="cb-btn cb-btn-sm btn-secondary"
          onClick={this.onClose} data-cb-modal-close>
          Close
        </button>
      </React.Fragment>
    )
  }

  showInst(e) {
    e.preventDefault()
    this.close(false)
    let courseInstanceId = getCourseInstanceId()
    navigate('instruction/courses/' + courseInstanceId + '/help/pdf')
  }

  onClose() {
    this.close(true)
  }
}


