import OrgList from './orgList'
import { OrgSingleComp } from './orgSingle'

export const LeftMenu = ({ props }) => {
    let helpers = props.helpers
    const currentOrg = props.org
    let orgs = props.orgs
    let hasNoOrgsOrNoName = orgs.length === 0 || !currentOrg
    let hasOneOrg = orgs.length === 1
    let orgSelectionDisabled = /sections|help/.test(window.location.href)
    let isHome = window.location.pathname === "/instruction/dashboard"
    let singleOrg = hasNoOrgsOrNoName || hasOneOrg || orgSelectionDisabled

    return (
        <div id='left-menu' className='col-xs-12 col-sm-6 cb-no-padding'>
            {helpers.isTeacherOrCoordinator &&
                <div>
                    <div className='col-xs-4 col-sm-8 col-md-5' style={{paddingRight: 0, paddingLeft: 0}}>
                        {singleOrg ? (
                                <OrgSingleComp currentOrg={props.org} />
                            ) : (
                                isHome ? (
                                    <OrgList orgs={props.orgs} currentOrg={currentOrg} changeOrg={props.changeOrg}/>
                                ) : (
                                    <OrgSingleComp currentOrg={props.org} />
                                )
                            )
                        }
                    </div>
                    <div className='col-xs-8 col-sm-4 no-pointer cb-margin-top-16' key='no-pointer'>
                        <span>{helpers.currentAcademicYear}</span>
                    </div>
                </div>
            }
        </div>
    )
}