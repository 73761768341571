import {
  getCourseInstanceBySectionId,
  getCourseInstanceId, getCurrentCourseInstance,
  getCurrentSectionId,
  isWeCourse
} from "../entities/courseinstance";
import { filterModules, getModuleHolder } from "../entities/modules";
import { cmsDocuments, contentField } from "./static_content";
import { getCurrentPerson } from "../entities/person";
import { enrollmentForSection } from "../enrollment/entities/enrollment";
import { getStudentProgress } from "../progress/entities/progress";
import { StudentContentOverview } from "./views/studentContentOverview";
import { TeacherContentOverview } from "./views/teacherContentOverview";
import { Spinner } from "@cb/apricot-react";
import { useParams } from "react-router-dom";
import CourseLayout from "../main/views/courseLayout";
import { getTeacherSaveSelections, getTeacherSelections } from "./content_module";

export function filterStudentModules(moduleHolder) {
  let moduleFilter = module => !/ASSIGNMENT/.test(module.title)
  let lessonFilter = lesson => !/Oral Def|TMP|IMP|OD|IWA CP|IRR CP|Process and Reflection Portfolio/.test(lesson.abbreviation)

  // these lessons appear in the attributes of the models
  moduleHolder.lessons = _.filter(moduleHolder.lessons, lesson => {
    return lessonFilter(lesson)
  })

  // filter models
  moduleHolder.modules = _.filter(moduleHolder.modules, module => {
    return moduleFilter(module)
  })

  // for each model, filter lessons
  _.each(moduleHolder.modules, module => {
    let lessons = module.lessons
    if (lessons && lessons.length>0) {
      lessons = _.filter(lessons, lesson => {
        return lessonFilter(lesson)
      })
      module.lessons = lessons
    }
  })
}

const ContentOverview = () => {
  const [cmsContent, setCmsContent] = useState({})
  const [props, setProps] = useState({})
  const [loading, setLoading] = useState(true)
  const params = useParams()
  let role = getCurrentPerson()
  let sectionId = params.sectionId
  let courseInstanceId = getCurrentCourseInstance()?.courseId

  const getAllContent = async () => {
    let moduleHolder = null
    let docs = []

    // When the sectionId changes, we may need to get a different course instance so that's what this condition handles
    if (sectionId) courseInstanceId = getCourseInstanceBySectionId(sectionId).courseId

    try {
      const results = await Promise.allSettled([getModuleHolder(courseInstanceId), cmsDocuments()])
      let result = (results && results.length > 0) ? results[0] : null
      if (!!result && result.status==='fulfilled') {
        moduleHolder = result.value
      } else {
        console.error('getModuleHolder should at least return an empty value')
      }
      result = (results && results.length > 1) ? results[1] : null
      if (!!result && result.status==='fulfilled') {
        docs = result.value
      }
    } catch (err) {
      return new Error(`getAllContent function throw an error: ${err}`)
    }

    let cmsRole = role.isStudent() ? 'student': 'teacher'
    let textContent = await contentField(cmsRole + '_class_summary', courseInstanceId)
    let roles = getCurrentPerson().roles;
    let filtered = _.filter(docs, doc => {
      let publishable = doc.publishable === 1 || doc.publishable === '1'
      return doc['Target Audience'] &&
          _.intersection(doc['Target Audience'].split(', '), roles).length &&
          !publishable &&
          doc.LocationId && _.contains(doc.LocationId.split(', '), courseInstanceId)
    })

    let partition = filtered && _.partition(filtered, doc => {
      return !(doc.File === null || doc.File.length === 0)
      //doc.File !== null
    })
    setCmsContent({ partition, textContent, moduleHolder })
  }

  const getProps = async (role, content) => {
    if (content) {
      const { moduleHolder } = content
      if (role.isStudent() && moduleHolder) {
        let notWeCourse = !isWeCourse()
        let enrollment = enrollmentForSection(sectionId)
        if (notWeCourse) {
          let newModuleHolder = filterModules(moduleHolder, false)
          filterStudentModules(newModuleHolder)
          const studentProgress = await getStudentProgress(sectionId, newModuleHolder)
          setProps({
            ...content,
            sectionId,
            courseInstanceId,
            studentProps: {
              isStudent: true,
              studentProgress,
              moduleHolder: newModuleHolder,
              sectionId,
              isWe: false,
              permissionToReuse: !!enrollment.enrollment.permissionToReuse,
              enrollment
            }
          })
        } else {
          let newModuleHolder = filterModules(moduleHolder, true)
          filterStudentModules(newModuleHolder)
          const studentProgress = await getStudentProgress(sectionId, newModuleHolder)
          setProps({
            ...content,
            sectionId,
            courseInstanceId,
            studentProps: {
              isStudent: true,
              moduleHolder: newModuleHolder,
              sectionId,
              isWe: true,
              studentProgress,
              permissionToReuse: !!enrollment.enrollment.permissionToReuse,
              enrollment
            }
          })
        }
      } else if (role.isTeacher() && moduleHolder) {
        let newModuleHolder = filterModules(moduleHolder, false)
        let selections = await getTeacherSelections(sectionId)
        let saveSelections = getTeacherSaveSelections()
        setProps({
          moduleHolder: newModuleHolder,
          ...content,
          selections,
          saveSelections,
          sectionId,
        })
      }
    }
  }

  const getUpdatedData = () => {
    getProps(role, cmsContent)
  }

  useEffect(() => {
    setLoading(true)
    getAllContent(role)
  }, [sectionId])

  useEffect(() => {
    getProps(role, cmsContent)
  }, [cmsContent])

  useEffect(() => {
    if (props.studentProps || props.textContent) setLoading(false)
    document.dispatchEvent(new CustomEvent('pagevisit', { detail: 'overview' }))
  }, [props])

  if (loading) return <Spinner />

  if (role.isStudent() && props.studentProps) return (
      <>
        <CourseLayout props={{ moduleHolder: props.moduleHolder }}>
          <StudentContentOverview {...props} />
        </CourseLayout>
      </>
  )

  if (role.isTeacher()) return (
      <>
        <CourseLayout props={{ moduleHolder: props.moduleHolder }}>
          <TeacherContentOverview {...props} getUpdatedData={getUpdatedData} />
        </CourseLayout>
      </>
  )

  return null

}

export default ContentOverview
