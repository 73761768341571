import { Modal, NakedButton, PrimaryButton } from "@cb/apricot-react";

export const ImageButtons = ({ section, is3DAndSW }) => {
  if (is3DAndSW) {
    const { newIndex1, newIndex2, hiddenFileInputRef0, hiddenFileInputRef1, handleInputChange, imgId1, id1, fileName1, setModalOpenImageOne, modalOpenImageOne,
      imgId2, id2, fileName2, setModalOpenImageTwo, modalOpenImageTwo, imgHandler, disableInput, url1, url2, hasImage1, hasImage2 } = section
    return (
      <>
        <div className='col-md-6 cb-margin-top-16 cb-margin-bottom-16' id={`buttonsDiv_${newIndex1}`}>
          <div>
            <input
              type='file'
              className="browse hidden"
              id='fileupload'
              name='fileupload'
              ref={hiddenFileInputRef0}
              onChange={(event) => handleInputChange(event, id1, imgId1, '0')}
            />
            <PrimaryButton type="button" small
                           data-type='upload' data-index={newIndex1} data-id={id1} data-filename={fileName1}
                           disabled={disableInput}
                           onClick={() => imgHandler(hiddenFileInputRef0)}>
              <span data-id={`uploadButton-${newIndex1}`}>Upload</span>
              <span className="sr-only">Click to upload an image using the file selection menu</span>
            </PrimaryButton>
          </div>
          <div>
            <NakedButton type="button" className='cb-btn-black'
                         style={{paddingTop: '10px' }} icon='full-screen' disabled={!hasImage1}
                         data-type='bigImage' data-index={newIndex1} data-id={imgId1} id={'bigImage_' + imgId1}
                         onClick={() => setModalOpenImageOne(true)}>
              <span>Full Size</span>
              <span className="sr-only">Click to view the work in its original size</span>
            </NakedButton>
            <Modal
              title={`Work: ${newIndex1}, Filename: ${fileName1}`}
              escClose
              open={modalOpenImageOne}
              onClose={() => setModalOpenImageOne(false)}
              withHeader
              withFooter
            >
              <img src={url1} alt={`Fullsize Uploaded Image 3D: Work #${newIndex1}`} style={{ width: '100%', height: '100%' }} />
            </Modal>
          </div>
        </div>
        <div className='col-md-6 cb-margin-top-16 cb-margin-bottom-16 cb-border cb-border-left-1 cb-border-dashed' id={`buttonsDiv_${newIndex2}`}>
          <div>
            <input
              type='file'
              className="browse hidden"
              id='fileupload'
              name='fileupload'
              ref={hiddenFileInputRef1}
              onChange={(event) => handleInputChange(event, id2, imgId2, '1')}
            />
            <PrimaryButton
              type='button'
              small
              data-type='upload'
              data-index={newIndex2}
              data-id={id2}
              data-filename={fileName2}
              disabled={disableInput}
              onClick={() => imgHandler(hiddenFileInputRef1)}
            >
              <span data-id={`uploadButton-${newIndex2}`}>Upload</span>
              <span className="sr-only">Click to upload an image using the file selection menu</span>
            </PrimaryButton>
          </div>
          <div>
            <NakedButton type="button" className='cb-btn-black'
                         style={{paddingTop: '10px' }} icon='full-screen' disabled={!hasImage2}
                         data-type='bigImage' data-index={newIndex2} data-id={imgId2} id={'bigImage_' + imgId2}
                         onClick={() => setModalOpenImageTwo(true)}>
              <span>Full Size</span>
              <span className="sr-only">Click to view the work in its original size</span>
            </NakedButton>
            <Modal
              title={`Work: ${newIndex2}, Filename: ${fileName2}`}
              escClose
              open={modalOpenImageTwo}
              onClose={() => setModalOpenImageTwo(false)}
              withHeader
              withFooter
            >
              <img src={url2} alt={`Fullsize Uploaded Image 3D: Work #${newIndex2}`} style={{ width: '100%', height: '100%' }} />
            </Modal>
          </div>
        </div>
      </>
    )
  } else {
    const { hiddenFileInputRef, handleInputChange, id, imgId, imgHandler, fileName, url, noImage, setModalOpen, modalOpen, index, showHide, showFull, disableInput } = section
    return (
      <>
        <div className='col-md-6 cb-margin-top-16 cb-margin-bottom-8' style={{textAlign: 'right'}}>
          <input
            type='file'
            className="browse hidden"
            id='fileupload'
            name='fileupload'
            ref={hiddenFileInputRef}
            onChange={(event) => handleInputChange(event, id, imgId)}
          />
          <PrimaryButton type="button" small
                         data-type='upload' data-index={index} data-id={id} data-filename={fileName}
                         disabled={disableInput}
                         onClick={() => imgHandler(hiddenFileInputRef)}>
            <span>Upload</span>
            <span className="sr-only">Click to upload an image using the file selection menu</span>
          </PrimaryButton>
        </div>
        <div className='col-md-6 cb-margin-top-16 cb-margin-bottom-8' style={{textAlign: 'left'}}>
          {showFull &&
            <>
              <NakedButton type="button" className='cb-btn-black'
                           style={{paddingTop: '10px' }} icon='full-screen' disabled={noImage}
                           data-type='bigImage' data-index={index} data-id={imgId} id={'bigImage_' + imgId}
                           onClick={() => setModalOpen(true)}>
                <span>Full Size</span>
                <span className="sr-only">Click to view the work in its original size</span>
              </NakedButton>
              <Modal
                title={`Work: ${index+1}, Filename: ${fileName}`}
                escClose
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                withHeader
                withFooter
              >
                <img src={url} alt={`Fullsize Uploaded Image: Work#${index+1}`} style={{ width: '100%', height: '100%' }} />
              </Modal>
            </>
          }
          {showHide &&
            <>
              <NakedButton type="button" className='cb-btn-black'
                           style={{paddingTop: '10px' }} icon='exit-full-screen'
                           data-type='smallImage' data-index={index} data-id={imgId} id={'smallImage_' + imgId}
                           onClick={() => setModalOpen(true)}>
                <span>Hide Full Size</span>
                <span className="sr-only">Click to hide the work in its original size</span>
              </NakedButton>
              <Modal
                title={`Work: ${index+1}, Filename: ${fileName}`}
                escClose
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                withHeader
                withFooter
              >
                <img src={url} alt={`Fullsize Uploaded Image: Work#${index+1}`} style={{ width: '100%', height: '100%' }} />
              </Modal>
            </>
          }
        </div>
      </>
    )
  }
}