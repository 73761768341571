
import React, { Fragment, useEffect, useState }     from 'react';
import { Select, Input }                            from '@cb/apricot-react';
import { isAlphaNumericWords, validateKey }        from '../../utility/util'


export function MetadataString({ meta, changeMeta }) {
    let id = 'metadata-' + meta.id
    return (
        <div className="cb-input form-group" tabIndex='-1'>
            <label className="font-normal" htmlFor={id}>{meta.label}</label>
            <Input type="text" className="form-control" id={id} onChange={e => {updatedStringData(e,meta,changeMeta)}} 
                   onBlur={e => {updatedStringData(e,meta,changeMeta)}} onKeyPress={validateKey} maxLength="500" 
                   placeholder={'Please enter the ' + meta.name.toLowerCase()} />
        </div>
    )
}

export function MetadataTextarea({ meta, changeMeta }) {
    let id = 'metadata-' + meta.id
    return (
        <div className="cb-input form-group" tabIndex='-1'>
            <label className="font-normal" htmlFor={id}>
                {metadata.label}
            </label>
            <textarea id={id} className="form-control"
                      onBlur={e => {updatedStringData(e,meta,changeMeta)}} onKeyPress={validateKey} rows="5" maxLength="1000">
            </textarea>
        </div>
    )
}

function updatedStringData(e,meta,changeMeta) {
    let newVal = e.target.value
    let update = { id: meta.id, value: newVal.trim() }
    let isValid = newVal.trim() != '' &&  isAlphaNumericWords(newVal.trim()) 
    changeMeta(isValid, update)
}
