import React from 'react';

export default class FooterComp extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let helpers = this.templateHelpers()
        return (
            <div className="container-fluid add-top-margin-4x">
                <p>&copy; {helpers.year} The College Board</p>
            </div>
        )
    }
    templateHelpers() {
        var date = new Date()
        return { year: date.getFullYear() }
    }
}
