import React, { Fragment } from 'react';

export default class StudentInfoComp extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let results = this.props.results
        let student = results ? results.json.student : null
        let teacher = results ? results.json.teacher : null
        let section = results ? results.json.section : null
        let team = results ? results.json.team : null
        let teamMembers = team ? team.members.join(', ') : ''
        return (
            <Fragment>
                {results &&
                    <Fragment>
                        <h6 className="cb-spacerv-48" >Step 1: Review student information and indicate this student has appealed.</h6>
                        <div className="row">
                            <div className="col-xs-4 cb-no-padding">
                                <p><strong>Student First Name:</strong> {student.firstName}</p>
                                <p><strong>Student Last Name:</strong> {student.lastName}</p>
                                <p><strong>AP Number/AP ID:</strong> {results.apNumber}</p>
                            </div>
                            <div className="col-xs-4 cb-no-padding">
                                <p><strong>Course:</strong> {results.courseTitle}</p>
                                <p><strong>Class/Section:</strong> {section.name}</p>
                                <p><strong>Lesson:</strong> {results.lessonTitle}</p>
                                {team &&
                                    <div>
                                        <p><strong>Team Name:</strong> {team.name}</p>
                                        <p><strong>Team Members:</strong>{teamMembers}</p>
                                    </div>
                                }
                            </div>
                            <div className="col-xs-4 cb-no-padding">
                                <p><strong>Teacher Name:</strong> {teacher.teacherName}</p>
                                <p><strong>Teacher Email: </strong> {teacher.email}</p>
                                <p><strong>AI Code: </strong> {teacher.aiCode}</p>
                            </div>
                        </div>
                    </Fragment>
                }
            </Fragment>
        )
    }
}