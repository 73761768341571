function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* ========================================================================
 * Apricot's Photo Gallery
 * ======================================================================== */
// SCSS
//import "../scss/includes/apricot-base.scss";
//import "../scss/includes/photo-gallery.scss"; // javaScript

import CBModal from "./CBModal";
import Utils from "./CBUtils";
/**
 * Photo Gallery
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Element} data.modal
 * @param {Number} data.galleryRange1
 * @param {Number} data.galleryRange2
 * @param {Number} data.galleryRange3
 * @param {Number} data.galleryRange4
 * @param {Number} data.galleryRange5
 * @param {Number} data.autoPlayTime
 * @param {String} data.altTxt
 * @returns {{destroy: Function}}
 */

var PhotoGallery = function PhotoGallery() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var defaultData = {
    elem: null,
    modal: null,
    galleryRange1: 1146,
    galleryRange2: 1048,
    galleryRange3: 754,
    galleryRange4: 656,
    galleryRange5: 290,
    autoPlayTime: 2000,
    altTxt: "Photo Gallery"
  };
  var elem = data.elem;
  if (!Utils.elemExists(elem)) return false;
  var modal = data.modal;
  if (!Utils.elemExists(modal)) return false;
  data = _objectSpread(_objectSpread({}, defaultData), data);
  var elemId = Utils.attr(elem, "id") ? Utils.attr(elem, "id") : Utils.uniqueID(5, "apricot_");
  var modalId = Utils.attr(modal, "id") ? Utils.attr(modal, "id") : Utils.uniqueID(5, "apricot_");
  var imgId = Utils.uniqueID(5, "apricot_");
  var column = 0;
  var hasDesc = true;
  var singleImg = false;
  var isPlaying = false;
  var layoutType = "";
  var viewport = 0;
  var autoPlayTimeOut = 0;
  var tabList = [];
  var plugin = {};
  var $ul = null;
  var $hit = null;
  var $close = null;
  var $play = null;
  var $fullscreen = null;
  var $next = null;
  var $prev = null;
  var $des = null;
  var $img = null;

  var init = function init() {
    if (!elem.photoGallery) elem.photoGallery = "cb";
    Utils.attr(elem, "id", elemId);
    Utils.attr(modal, "id", modalId);
    plugin.autoPlayTime = data.autoPlayTime;
    $ul = elem.querySelectorAll("ul")[0];
    $hit = modal.querySelector(".cb-gallery-hit");
    $close = modal.querySelector(".cb-btn-close");
    $play = modal.querySelector(".cb-btn-play");
    $fullscreen = modal.querySelector(".cb-btn-fullscreen");
    $next = modal.querySelector(".cb-next-img");
    $prev = modal.querySelector(".cb-prev-img");
    $des = modal.querySelector(".cb-gallery-des");
    $img = modal.querySelector(".cb-gallery-image-container"); // first make sure the layout is correct

    if (!Utils.hasClass($ul, "cb-photo-gallery-grid")) {
      thumbnailLayoutClass();
    } else {
      layoutType = "cb-photo-gallery-grid";
    }

    if ($ul.querySelectorAll(".cb-photo-thumb-info").length === 0) {
      Utils.addClass(elem, "cb-no-desc");
    } // retrieve data

    retrieveDataset(); // start building the gallery

    setupGalleryModal(plugin); // add events

    removeThumbnailEvents()
    thumbnailEvents(plugin, $img); // modal events
    $next && $next.removeEventListener("click", playClickEvent);
    $next && $next.addEventListener("click", playClickEvent);
    navigationEvents(); // get focusable elements

    setTabList(); // A11Y

    Utils.attr(modal.querySelector('.cb-gallery-counter[role="region"]'), "aria-labelledby", "".concat(imgId));
    Utils.attr($prev, "aria-controls", imgId);
    Utils.attr($next, "aria-controls", imgId);
    window.addEventListener("resize", gallerySizeAdjustment);
    Utils.breakpoints();
    viewport = Utils.viewport().prefix;
    document.addEventListener("apricot_breakpointChange", breakpointChange);
  };

  var breakpointChange = function breakpointChange(e) {
    var data = e.data;
    viewport = data.prefix;
  };

  var gallerySizeAdjustment = function gallerySizeAdjustment() {
    if ($ul && !Utils.hasClass($ul, "cb-photo-gallery-grid")) {
      thumbnailLayoutClass();
    }

    if (Utils.hasClass(modal, "cb-open")) {
      adjustImgSize();
    }
  };

  var thumbnailLayoutClass = function thumbnailLayoutClass() {
    var cWidth = parseInt(Utils.width(elem), 10);
    layoutType = "";

    if (cWidth >= data.galleryRange2) {
      //1146 - 1048: 5
      column = 5;
      layoutType = "cb-photo-gallery-5";
    } else if (cWidth <= data.galleryRange2 - 1 && cWidth >= data.galleryRange3) {
      //1047 - 754: 4
      column = 4;
      layoutType = "cb-photo-gallery-4";
    } else if (cWidth <= data.galleryRange3 - 1 && cWidth >= data.galleryRange4) {
      //753 - 656: 3
      column = 3;
      layoutType = "cb-photo-gallery-3";
    } else if (cWidth <= data.galleryRange4 - 1 && cWidth >= data.galleryRange5) {
      //655 - 290: 2
      column = 2;
      layoutType = "cb-photo-gallery-2";
    } else if (cWidth < data.galleryRange5) {
      //290: 1
      column = 1;
      layoutType = "cb-photo-gallery-1";
    }

    if ($ul && !Utils.isBlank(layoutType)) {
      Utils.removeAttr($ul, "class");
      Utils.addClass($ul, layoutType);
    }
  };
  
  var retrieveDataset = function retrieveDataset() {
    var items = [];
    var obj = {};
    elem.querySelectorAll(".cb-photo-gallery > ul > li").forEach(function (li, index) {
      var $thumbImg = li.querySelector(".cb-photo-thumb");
      var $thumbInfo = li.querySelector(".cb-photo-thumb-info");
      var $detailsInfo = li.querySelector(".cb-photo-details-info");  // its for thumbnails. ignore
      var img = "";
      var alt = "";
      var title = "";
      var $title = {};
      var subTitleArr = [];
      var detailsObj = {};
      Utils.attr(li, "data-cb-thumb", "".concat(index));
      obj = {}; // large image

      img = Utils.attr($thumbImg, "data-cb-img");
      alt = !!Utils.attr($thumbImg.querySelector("img"), "alt") ? Utils.attr($thumbImg.querySelector("img"), "alt") : data.altTxt;

      if ($thumbInfo) {
        if ($thumbInfo.hasChildNodes()) {
          hasDesc = true;
        } // main: title


        $title = $thumbInfo.querySelectorAll("h2, h3, h4, h5, h6");

        if ($title.length > 0) {
          title = $title[0].innerHTML;
        } // main: sub titles

        $thumbInfo.querySelectorAll("p").forEach(function (item) {
          subTitleArr.push(item.innerHTML);
        });
      }

      detailsObj = {};

      if ($detailsInfo) {
        detailsObj = $detailsInfo.cloneNode(true);
        Utils.removeClass(detailsObj, "cb-photo-details-info");

        if ($detailsInfo.hasChildNodes()) {
          hasDesc = true;
        }
      }

      obj.img = img;
      obj.alt = alt;
      obj.title = title;
      obj.titleTag = !!$title[0] ? $title[0].tagName.toLowerCase() : "";
      obj.subTitleArr = subTitleArr;
      obj.details = detailsObj;
      items.push(obj);
    });

    if (items.length === 1) {
      singleImg = true;
    } else {
      singleImg = false;
    }
    items.sort((item => item.alt))
    plugin.items = items;
    plugin.count = elem.querySelectorAll(".cb-photo-gallery > ul > li").length;
  };

  var setupGalleryModal = function setupGalleryModal(plugin) {
     let singleImg = plugin.items.length === 1
    if (singleImg) {
      Utils.attr($prev, "tabIndex", "-1");
      Utils.attr($prev, "aria-hidden", "true");
      Utils.addClass($prev, "cb-hidden");
      Utils.attr($next, "tabIndex", "-1");
      Utils.attr($next, "aria-hidden", "true");
      Utils.addClass($next, "cb-hidden");
      Utils.addClass(modal, "cb-single-img");
    } else {
      Utils.attr($prev, "tabIndex", "0");
      Utils.attr($prev, "aria-hidden", "false");
      Utils.removeClass($prev, "cb-hidden");
      Utils.attr($next, "tabIndex", "0");
      Utils.attr($next, "aria-hidden", "false");
      Utils.removeClass($next, "cb-hidden");
      Utils.removeClass(modal, "cb-single-img");
    }
    if (!hasDesc) {
      // always display description (meta)
     // Utils.addClass(modal, "cb-no-desc");
    }
  };
  //data, current, plugin, $img

  var removeThumbnailEvents = function removeThumbnailEvents() {
    elem.querySelectorAll(".cb-photo-gallery > ul > li").forEach(function (li) {
      li.removeEventListener('click', thumbnailEvent);
    })
  }
  var thumbnailEvent = function thumbnailEvent(e) {
    e.preventDefault();
    var li = e.currentTarget
    var current = Utils.attr(li, "data-cb-thumb");
    var _plugin = getPlugin();
    var data = _plugin.items[current];
    let button = e.target
    if (button.classList.contains('cb-glyph') || button.classList.contains('cb-btn')) {}
    else setGalleryData(data, current, _plugin, $img)
  }

  var thumbnailEvents = function thumbnailEvents(plugin, $img) {
    elem.querySelectorAll(".cb-photo-gallery > ul > li").forEach(function (li) {
      li.addEventListener("click", thumbnailEvent)
      // accessibility, enter/space

      li.addEventListener("keydown", function (e) {
        if (Utils.whichKey(e) === "ENTER" || Utils.whichKey(e) === "SPACE") {
          e.stopPropagation();
          e.preventDefault();
          var current = Utils.attr(li, "data-cb-thumb");
          var _data = plugin.items[current];
          setGalleryData(_data, current, plugin, $img);
        }
      });
      var $a = li.querySelector("a");

      if ($a) {
        $a.addEventListener("blur", function (e) {
          Utils.removeClass($a, "cb-focus");
        });
        $a.addEventListener("focus", function (e) {
          Utils.addClass($a, "cb-focus");
        });
      }
    });
  }; // keyboard navigation is clockwise


  var navigationEvents = function navigationEvents() {
    if (!singleImg) {
      var controller = modal.querySelector(".cb-gallery-controls");
      var btnCount = controller.querySelectorAll(".cb-btn").length;
      var counter = modal.querySelector(".cb-gallery-counter");

      var _$hit = modal.querySelector(".cb-gallery-hit");

      if (counter) {
        Utils.addClass(counter, "cb-btn-".concat(btnCount));
      }

      Utils.swipe(_$hit);

      _$hit.addEventListener("swipe_end", swipeNavigation);
    } // close


    $close && $close.addEventListener("keydown", function (e) {
      var k = e.which || e.keyCode;

      if (k === 9 && !e.shiftKey) {
        //tab
        e.preventDefault();
        getFocusable($close, true);
      }

      if (k === 9 && e.shiftKey) {
        //shift & tab
        e.preventDefault();
        getFocusable($close, false);
      }
    }); // arrows

    $next && $next.removeEventListener("click", playClickEvent);
    $next && $next.addEventListener("click", playClickEvent); // accessibility, enter/space=

    $next && $next.addEventListener("keydown", function (e) {
      var k = e.which || e.keyCode; //  Enter or space

      if (k === 13 || k === 32) {
        e.preventDefault();
        $next.click();
      }

      if (k === 9 && !e.shiftKey) {
        //tab
        e.preventDefault();
        getFocusable($next, true);
      }

      if (k === 9 && e.shiftKey) {
        //shift & tab
        e.preventDefault();
        getFocusable($next, false);
      }
    }); // Description accessibility

    $des && $des.addEventListener("keydown", function (e) {
      var k = e.which || e.keyCode;

      if (k === 9 && !e.shiftKey) {
        //tab
        e.preventDefault();
        getFocusable($des, true);
      }

      if (k === 9 && e.shiftKey) {
        //shift & tab
        e.preventDefault();
        getFocusable($des, false);
      }
    }); // Prev

    $prev && $prev.removeEventListener("click", prevClickEvent);
    $prev && $prev.addEventListener("click", prevClickEvent); // accessibility, enter/space

    $prev && $prev.addEventListener("keydown", function (e) {
      var k = e.which || e.keyCode; // 13: enter
      // 32: space

      if (k === 13 || k === 32) {
        e.preventDefault();
        $prev.click();
      } // 9: tab


      if (k === 9 && !e.shiftKey) {
        //tab
        e.preventDefault();
        getFocusable($prev, true);
      }

      if (k === 9 && e.shiftKey) {
        //shift & tab
        e.preventDefault();
        getFocusable($prev, false);
      }
    }); // add events if fullscreen button exists

    if ($fullscreen) {
      Utils.addClass($img, "cb-has-fullscreen");
      $fullscreen.removeEventListener("click", fullBtnClickEvent);
      $fullscreen.addEventListener("click", fullBtnClickEvent);

      if (document.addEventListener) {
        document.addEventListener("webkitfullscreenchange", escFullScreen, false);
        document.addEventListener("mozfullscreenchange", escFullScreen, false);
        document.addEventListener("fullscreenchange", escFullScreen, false);
        document.addEventListener("MSFullscreenChange", escFullScreen, false);
      } // accessibility, enter/space


      $fullscreen.addEventListener("keydown", function (e) {
        var k = e.which || e.keyCode; // 13: enter
        // 32: space

        if (k === 13 || k === 32) {
          e.preventDefault();
          $fullscreen.click();
        } // 9: tab


        if (k === 9 && !e.shiftKey) {
          //tab
          e.preventDefault();
          getFocusable($fullscreen, true);
        }

        if (k === 9 && e.shiftKey) {
          //shift & tab
          e.preventDefault();
          getFocusable($fullscreen, false);
        }
      });
    } // add events if play button exists


    if ($play) {
      Utils.attr($next, "data-cb-time", plugin.autoPlayTime);
      $play.removeEventListener("click", playBtnClickEvent);
      $play.addEventListener("click", playBtnClickEvent); // accessibility, enter/space

      $play.addEventListener("keydown", function (e) {
        var k = e.which || e.keyCode; // 13: enter
        // 32: space

        if (k === 13 || k === 32) {
          e.preventDefault();
          $play.click();
        } // 9: tab


        if (k === 9 && !e.shiftKey) {
          //tab
          e.preventDefault();
          getFocusable($play, true);
        }

        if (k === 9 && e.shiftKey) {
          //shift & tab
          e.preventDefault();
          getFocusable($play, false);
        }
      });
    }
  };

  var setTabList = function setTabList() {
    tabList = [];
    if ($close) tabList.push($close);
    if ($next && !singleImg) tabList.push($next);
    if ($des && hasDesc) tabList.push($des);
    if ($prev && !singleImg) tabList.push($prev);
    if ($fullscreen) tabList.push($fullscreen);
    if ($play && !singleImg) tabList.push($play);
  };

  var getFocusable = function getFocusable(node, next) {
    var index = tabList.indexOf(node);

    if (next) {
      index++;
    } else {
      index--;
    }

    if (index < 0) {
      index = tabList.length > 0 ? tabList.length - 1 : 0;
    } else if (index === tabList.length) {
      index = 0;
    }

    tabList[index].focus();
  };

  var swipeNavigation = function swipeNavigation(e) {
    if (e && e.data) {
      var _data2 = e.data;

      if (_data2.offset.x !== 0) {
        if (_data2.direction.x === "left") {
          $prev.click();
        } else if (_data2.direction.x === "right") {
          $next.click();
        }
      }
    }
  };

  /*

  var playBtnClickEvent = function playBtnClickEvent(e) {
    if (e) e.preventDefault();
    var span = $play.querySelector(".cb-glyph");

    if (!isPlaying) {
      Utils.addClass(span, "cb-slideshow-pause");
      Utils.removeClass(span, "cb-slideshow-play");
      isPlaying = true;
      playClickEvent(e);
      Utils.attr($play, "aria-pressed", "true");
    } else {
      stopAutoPlay();
    }
  };
*/

  const playBtnClickEvent = (e) => {
    if (e) {
      e.preventDefault()
      e.stopImmediatePropagation()
      e.stopPropagation()
    }

    const span = $play.querySelector('.cb-glyph')

    if (!isPlaying) {
      Utils.addClass(span, 'cb-slideshow-pause')
      Utils.removeClass(span, 'cb-slideshow-play')
      isPlaying = true

      playClickEvent()
      Utils.attr($play, 'aria-pressed', 'true')
    } else {
      stopAutoPlay()
    }
  }

  var stopClickEvent = function stopClickEvent() {
    clearTimeout(autoPlayTimeOut);
  };

  var stopAutoPlay = function stopAutoPlay() {
    //   if ($play) {
    var span = $play.querySelector(".cb-glyph");
    Utils.removeClass(span, "cb-slideshow-pause");
    Utils.addClass(span, "cb-slideshow-play");
    isPlaying = false;
    Utils.attr($play, "aria-pressed", "false");
    //}
    stopClickEvent();
  };

  var fullBtnClickEvent = function fullBtnClickEvent(e) {
    e.preventDefault();

    if (Utils.hasClass(modal, "cb-photo-full")) {
      exitFullscreen(); // Utils.attr($fullscreen, 'aria-pressed', 'false')
    } else {
      activateFullscreen($img); // Utils.attr($fullscreen, 'aria-pressed', 'true')
    }
  };

  var escFullScreen = function escFullScreen() {
    if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      Utils.removeClass(modal, "cb-photo-full");
    } else {
      Utils.addClass(modal, "cb-photo-full");
    }
  };

  var activateFullscreen = function activateFullscreen($img) {
    if ($img.requestFullscreen) {
      $img.requestFullscreen();
    } else if ($img.mozRequestFullScreen) {
      $img.mozRequestFullScreen();
    } else if ($img.webkitRequestFullscreen) {
      $img.webkitRequestFullscreen();
    } else if ($img.msRequestFullscreen) {
      $img.msRequestFullscreen();
    }
  };

  var exitFullscreen = function exitFullscreen() {
    try {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    } catch (error) {
      console.log(error);
    }
  };

  var getPlugin = function getPlugin() {
    retrieveDataset()
    return plugin
  }

  var playClickEvent = function playClickEvent(e) {
    var $nextPlay = null;
    if (e) {
      e.stopImmediatePropagation()
      stopAutoPlay();
      $nextPlay = modal.querySelector(".cb-next-img");
    } else {
      $nextPlay = modal.querySelector(".cb-next-img");
      //  var time = parseInt(Utils.attr($nextPlay, "data-cb-time"), 10);
      autoPlayTimeOut = setTimeout(playClickEvent, 2000);
    }

    var selected = Utils.attr($nextPlay, "data-cb-item");

    var _plugin = getPlugin();
    data = _plugin.items[selected];
    var next = getItemsNumber(0, selected)
    Utils.attr($nextPlay, "data-cb-item", "".concat(next));
    setGalleryData(data, selected, _plugin, $img);
  };

  var prevClickEvent = function prevClickEvent(e) {
    e.stopImmediatePropagation()  
    stopAutoPlay();    
    var $prevPlay = e.currentTarget;
    var selected = Utils.attr($prevPlay, "data-cb-item");
    var _plugin = getPlugin();
    data = _plugin.items[selected];
    var previous = getItemsNumber(1, selected);
    Utils.attr($prevPlay, "data-cb-item", "".concat(previous));
    setGalleryData(data, selected, _plugin, $img);
  }; // update modal


  var setGalleryData = function setGalleryData(data, current, plugin, $img) {
    if (!data) return
    var $container = modal.querySelector(".cb-gallery-content");
    Utils.removeClass($container, "cb-no-info"); // hide current one

    Utils.removeClass($img, "cb-active-effect");
    Utils.addClass($img, "cb-hide-effect");
    var previous = getItemsNumber(1, current);
    var next = getItemsNumber(0, current);


    var $des = modal.querySelector(".cb-gallery-des");
    $des.innerHTML = "";
    $img.innerHTML = "";

    var count = parseInt(plugin.count, 10);
    current = parseInt(current, 10) + 1;
    modal.querySelectorAll(".cb-count-num").forEach(function ($count) {
      $count.innerText = count;
    });
    modal.querySelectorAll(".cb-current-num").forEach(function ($current) {
      $current.innerText = current;
    });
    Utils.attr($next, "data-cb-item", "".concat(next));
    Utils.attr($next, "aria-label", "next gallery image, show image ".concat(parseInt(next + 1, 10), " of ").concat(count));


/*     
    let x = document.getElementById('testnext')
    if (!x) {
      x = document.createElement("SPAN");
      x.setAttribute("id", "testnext");
      $next.appendChild(x);
    }
    x.innerText = "" + next;

    let y = document.getElementById('testprevious')
    if (!y) {
      y = document.createElement("SPAN");
      y.setAttribute("id", "testprevious");
      $prev.appendChild(y);
    }
    y.innerText = "" + previous;

 */
    Utils.attr($prev, "data-cb-item", "".concat(previous));
    Utils.attr($prev, "aria-label", "previous gallery image, show image ".concat(parseInt(previous + 1, 10), " of ").concat(count));

    if (data && !!data.img) {
      Utils.attr($img, "data-cb-thumb", current);
      getImgDimension(data.img, data.alt, $img);
      setTimeout(function () {
        Utils.removeClass($img, "cb-hide-effect");
        Utils.addClass($img, "cb-active-effect");
      }, 200);
    }

    var $span = document.createElement("SPAN");
    Utils.addClass($span, "sr-only");
    $span.innerText = "Gallery Image ".concat(current, " of ").concat(count, ", ").concat(data.alt);
    Utils.append($img, $span); // Add label to role: dialog

    var modalContainer = modal.querySelector(".cb-modal-container");
    Utils.attr(modalContainer, "aria-label", "Gallery Image ".concat(current, " of ").concat(count)); // set header tag based on thumb section

    var hasInfo = false;

    if (data.titleTag !== "") {
      var $h = document.createElement(data.titleTag.toUpperCase());
      Utils.addClass($h, "cb-main-title");
      $h.innerText = data.title;
      Utils.append($des, $h);
      hasInfo = true;
    }

    for (var st in data.subTitleArr) {
      var $p = document.createElement("P");
      Utils.addClass($p, "cb-main-info");
      $p.innerText = data.subTitleArr[st];
      Utils.append($des, $p);
      hasInfo = true;
    }

    if (data.details.nodeType == 1) {
      Utils.append($des, data.details);
      hasInfo = true;
    }

    var $info = modal.querySelector(".cb-info-container");

    if ($info) {
      if (!hasInfo) {
        Utils.addClass($info, "cb-no-info");
        Utils.addClass($container, "cb-no-info");
      } else {
        Utils.removeClass($info, "cb-no-info");
      }
    }

    if (!Utils.hasClass(modal, "cb-open")) {
      // open modal
      modal.addEventListener("apricot_modalShow", function (e) {
        // user may not want a close button in the modal
        if ($close) {
          $close.focus();
        }
      }); // close modal
      // set focus back to active image

      modal.addEventListener("apricot_modalClose", function (e) {
        var current = Utils.attr(modal.querySelector(".cb-gallery-image-container"), "data-cb-thumb");
        var focusNum = current > 0 ? current - 1 : 0;
        var $li = elem.querySelector("[data-cb-thumb=\"".concat(focusNum, "\"]"));
        var customEvent = new CustomEvent("apricot_photoGallery_close");
        elem.dispatchEvent(customEvent);
        var $a = $li.querySelectorAll("a")[0];

        if ($a) {
          Utils.addClass($a, "cb-focus");
          $a.focus();
        }

        stopClickEvent();

        if (Utils.hasClass(modal, "cb-photo-full")) {
          exitFullscreen();
        }
      });
      CBModal.show({
        targetModal: modalId
      });
      document.getElementById(modalId).addEventListener("apricot_modalClose", function (e) {
        stopAutoPlay();
      });
    }
  };

  var getImgDimension = function getImgDimension(src, alt, $img) {
    var $parent = modal.querySelector(".cb-gallery-image");
    var img = document.createElement("IMG");
    var imgW = 0;
    var imgH = 0;
    Utils.attr(img, "src", src);
    Utils.attr(img, "alt", alt);
    Utils.attr(img, "id", imgId);
    img.addEventListener("load", function () {
      imgW = img.width;
      imgH = img.height; // these are the base values

      Utils.attr(img, "data-cb-width", imgW);
      Utils.attr(img, "data-cb-height", imgH);
      var sizeObj = calculateAspectRatioFit(img);
      img.style.width = sizeObj.width;
      img.style.height = sizeObj.height;

      if (viewport === "xs" || viewport === "sm") {
        $parent.style.width = null;
      } else {
        $parent.style.width = sizeObj.width;
      }

      let imgs = $img.querySelectorAll('img')
      if (imgs && imgs.length > 0) {
        imgs.forEach(i => i.remove())
      }


      //   if (!elem.photoGallery || elem.photoGallery === "cb")
      Utils.append($img, img);
      if (Utils.hasClass($img, "cb-has-fullscreen")) {
        img.addEventListener("click", fullBtnClickEvent);
      }
    });
  };

  var adjustImgSize = function adjustImgSize() {
    var img = $img.querySelector("img");
    var $parent = modal.querySelector(".cb-gallery-image");

    if (img) {
      var sizeObj = calculateAspectRatioFit(img);
      img.style.width = sizeObj.width;
      img.style.height = sizeObj.height;

      if (viewport === "xs" || viewport === "sm") {
        $parent.style.width = null;
      } else {
        $parent.style.width = sizeObj.width;
      }
    }
  };

  var calculateAspectRatioFit = function calculateAspectRatioFit(img) {
    var $container = modal.querySelector(".cb-gallery-content");
    Utils.removeClass($container, "cb-image-padding");
    var maxWidth = viewport === "xs" || viewport === "sm" ? Utils.width($img) : Utils.width($container) * (2 / 3);
    var maxHeight = viewport === "xs" || viewport === "sm" ? Utils.windowsDimension().height - 71 : Utils.height($img);
    var width = Utils.attr(img, "data-cb-width");
    var height = Utils.attr(img, "data-cb-height");
    var newW = 0;
    var newH = 0;
    var ratio = 0;

    if (width > height) {
      newW = maxWidth;
      ratio = height / width;
      newH = ratio * newW;
    } else {
      newH = maxHeight;
      ratio = width / height;
      newW = ratio * newH;
    }

    if (newH > maxHeight) {
      while (newH > maxHeight) {
        newH--;
        newW = 0;
      }
    }

    if (newW > maxWidth) {
      while (newW > maxWidth) {
        newW--;
        newH = 0;
      }
    }

    if (maxHeight - newH > 2) {
      Utils.addClass($container, "cb-image-padding");
    }

    if (isNaN(newW) || newW === 0) {
      newW = "auto";
    } else {
      newW = "".concat(newW, "px");
    }

    if (isNaN(newH) || newH === 0) {
      newH = "auto";
    } else {
      newH = "".concat(newH, "px");
    }

    return {
      width: newW,
      height: newH
    };
  }; // mode: 1, previous
  // mode: 0, next


  var getItemsNumber = function getItemsNumber(mode, current) {
    var number = 0;
    var count = parseInt(plugin.count, 10);
    current = parseInt(current, 10);

    if (!!mode) {
      // previous  mode = 0
      number = current > 0 ? current - 1 : count - 1;
    } else {
      // next      mode = 1
      number = current < count - 1 ? current + 1 : 0;
    }

    return number;
  };

  var destroy = function destroy() {
    if (elem.photoGallery === "cb") {
      elem.photoGallery = null;
      window.removeEventListener("resize", gallerySizeAdjustment);
      document.removeEventListener("apricot_breakpointChange", breakpointChange);
    }
    $next && Utils.swipe($next, true)
    $prev && Utils.swipe($prev, true)
    removeThumbnailEvents()
  };

  if (elem.photoGallery !== "cb") {
    init();
  }

  return {
    destroy: destroy
  };
};

export default PhotoGallery;