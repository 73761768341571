import React                                            from 'react'
import { getStatusFields, getExpandedStudentData, getDownloadDt, setPPRDownloadDt}      from './student_view'
import { StudentStatusCellComp }                        from './studentStatusCell'
import DPToolTip                                        from '../../utility/tooltip'
import { requestIndividual, stopStatusCheck }           from '../../utility/upload'
import { getCurrentPerson }                             from '../../entities/person.jsx'
import moment                                           from 'moment-timezone'
import { isAfterHardEndDate, isArt, isCSP, getCourseParameter, getWeCourseParameter,
  getCurrentSectionId, getCurrentCourseInstance }       from '../../entities/courseinstance'
import { NakedButton, YellowButton, Spinner }           from '@cb/apricot-react'
import RequestExtensionModal                            from './requestExtensionModal'
import { getAllPortfolioSubmissions, PortfolioPDF }     from '../../utility/portfoliodownload'



export default class SectionSubRowComp extends React.Component {
  constructor(props) {
    super(props)
    // props.parms: hasTeamLessons, isWe, student, toolKey, index
    // student has studentModel, lessons, extraColumnData, classNames, sectionId, isWe, id
    // studentModel has jsonStudent, completionStatus

    this.clickHandler = this.clickHandler.bind(this)
    this.setExtendedEndDate = this.setExtendedEndDate.bind(this)
    this.downloadPortfolio = this.downloadPortfolio.bind(this)
    let dpPersonId = this.props.parms.student.studentModel.jsonStudent.dpPersonId
    this.toolKey = this.props.parms.toolKey + '_student_' + dpPersonId
    this.toolTip = new DPToolTip(this.toolKey)
    this.failureErr = 'We were unable to download your file at this time. Please try again shortly.'
    this.timeoutErr = 'We were unable to download your file at this time. Please refesh your screen and try again.'
    this.startTimeout = null
    this.endTimeout = null

    this.state = {
      isDownloading: false,
      requestExtensionModalOpen: false,
      extendedEndDate: this.props.parms.student.extendedEndDate,
      downloadErrMsg: '',
      showDownloadAlertMsg: false,
    }
  }

  componentDidMount() {
    this.toolTip.createTooltips()
    if(this.props.parms.student.extendedEndDate) {
      this.setState({ extendedEndDate: this.props.parms.student.extendedEndDate })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.toolTip.createTooltips()

    if (this.state.isDownloading !== prevState.isDownloading) {
      if (this.state.isDownloading) {
        this.startTimeout = setTimeout(() => {
          this.setState({ showDownloadAlertMsg: true });
        }, 45000); // 45 seconds

        this.endTimeout = setTimeout(() => {
          this.setState({
            showDownloadAlertMsg: false,
            downloadErrMsg: this.state.timeoutErr,
            isDownloading: false,
          });
          stopStatusCheck()
        }, 120000); // 2 minutes
      } else {
        clearTimeout(this.startTimeout);
        clearTimeout(this.endTimeout);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.startTimeout);
    clearTimeout(this.endTimeout);
  }

  setExtendedEndDate(extendedEndDate) {
    const newExtendedEndDate = new Date(extendedEndDate)
    newExtendedEndDate.setHours(23, 59, 59, 999)
    this.setState({ extendedEndDate: newExtendedEndDate })
  }

  async downloadPortfolio(lessons, studentData, statusFields) {
    try {
      this.setState({ isDownloading: true })
      const lessonIds = lessons.map(lesson => lesson.id)
      const currentSectionId = getCurrentSectionId()
      const submissions = await getAllPortfolioSubmissions(lessonIds, currentSectionId, studentData.dpPersonId, studentData.enrollmentId)
      if (submissions) {
        await PortfolioPDF(submissions, { firstName: studentData.firstName, lastName: studentData.lastName, apUid: statusFields.aproStudentId })
      }
      this.setState({ isDownloading: false })
    } catch(error) {
      this.setState({ isDownloading: false })
    }
  }

  render() {
    //console.log('StudentSubRowComp props', this.props)
    const { requestExtensionModalOpen, extendedEndDate, isDownloading } = this.state
    let { studentModel, lessons, sectionId, extraColumnData } = this.props.parms.student
    let statusFields = getStatusFields(studentModel.jsonStudent, this.props.parms.isWe)
    let studentData = getExpandedStudentData(studentModel)
    let rowCls = statusFields.statusClass ? statusFields.statusClass : 'has-ap-number'
    // original code computed a readOnly parameter which it called createdSection,
    // however the method below ignored this readOnly parameter
    this.toolTip.removeTooltips()
    let isStudent = getCurrentPerson().isStudent()
    let isCoordinator = getCurrentPerson().isCoordinator()
    let fullName = `${studentData.lastName}, ${studentData.firstName}`
    let	art = isArt()
    let	csp = isCSP()
    let isAllSubmitted = studentModel.scorables.some(score => score.scorable.status !== 4)
    let isPPRSubmitted = studentModel.scorables[2]?.scorable?.status === 4
    let isPastHardEndDate = isAfterHardEndDate()
    const requestExtensionEnabledDate = this.props.parms.isWe ? getWeCourseParameter('requestExtensionEnabledDate') : getCourseParameter('requestExtensionEnabledDate')
    const extensionBtnVisible = (new Date(requestExtensionEnabledDate) < new Date())
    const requestExtensionDisabledDate = this.props.parms.isWe ? getWeCourseParameter('requestExtensionDisabledDate') : getCourseParameter('requestExtensionDisabledDate')
    const extensionBtnDisable = (new Date(requestExtensionDisabledDate) < new Date())
    const { title : courseTitle} = getCurrentCourseInstance()

    let statusCells = () => {
      return _.map(lessons, (lesson, index) => {
        // added id to toolKey string for when lesson abbreviation is identical in more than 1 element
        let toolKey = this.toolKey + '_lesson_' + lesson.abbreviation + '_id_' + index
        let cellId = 'sectionTab_' + studentData.dpPersonId + '_lesson_' + index
        let props = {
          studentData: studentData,
          scorableHolder: studentModel.scorables[index],
          team: studentModel.team,
          isWe: this.props.parms.isWe,
          sectionId: sectionId,
          lesson: lesson,
          toolKey: toolKey,
          cellId: cellId,
        }
        return <StudentStatusCellComp {...props} key={toolKey}></StudentStatusCellComp>
      })
    }
    
    const downloadedDate = getDownloadDt(studentModel.jsonStudent , 422500005)

    return ( // same tmpl as studentRow
      <tr role='row' id={'sectionTab' + studentData.dpPersonId} className='sub-row'>
        <th scope="row" className={rowCls}>
          {fullName}
          {studentData.prefFirstName && studentData.prefFirstName !== 'null' && !isStudent ? <span style={{ fontWeight: 400 }}> (Preferred Name: {studentData.prefFirstName})</span> : null}
          {statusFields.showAPNumber &&
                        <div className="no-bold no-break-word-md" style={{marginTop: '2px'}}>
                          {statusFields.aproStudentId &&
                                <span>{'#' + statusFields.aproStudentId}</span>
                          }
                        </div>
          }
          {statusFields.intentNo &&
                        <div className="no-bold no-break-word-md" style={{marginTop: '2px'}}>
                            Not Taking Exam
                        </div>
          }
          {statusFields.notRegistered &&
                        <div className="no-bold no-break-word-md" style={{marginTop: '2px'}}>
                          <a href="#" data-cb-apricot='tooltip' id={this.toolTip.addToolTip()} onClick={this.clickHandler}
                            className="progress_icon" data-cb-title="The student is not registered for the exam."
                            aria-describedby={'desc-' + statusFields.aproStudentId}>
                            <span className="cb-icon-icn_deadline cb-glyph cb-glyph-circular text-danger"
                              style={{color: 'white', float: 'left', marginTop: '4px'}}>
                            </span>
                            <span id={'desc-' + statusFields.aproStudentId} className='sr-only'>
                              {fullName} is not registered for the exam.
                            </span>
                          </a>
                          <span style={{verticalAlign: 'top', fontSize: '12px', fontWeight: 400, position: 'relative', top: '4px'}}>
                                No Selection
                          </span>
                        </div>
          }
        </th>
        { statusCells() }
        {art && isCoordinator && <td>
          <div>
            {isDownloading ? <Spinner size='small' className='cb-margin-right-4' /> :
              <YellowButton
                disabled={isAllSubmitted}
                small='true'
                onClick={() => this.downloadPortfolio(lessons, studentData, statusFields)}
              >
                      Download
              </YellowButton>
            }
          </div>
        </td>}
        {csp && isCoordinator && !this.props.parms.isWe && <td>
          <div className='cb-align-center'>
            {isDownloading ? (
              <>
                <Spinner size='small' className='cb-margin-right-4' />
                {this.state.showDownloadAlertMsg && (
                  <div className='cb-red1-color cb-align-center'>
                    <span
                      style={{ fontSize: '11px' }}
                      className='cb-font-weight-black position-relative position-top align-self-center cb-padding-top-8'
                    >
                      The system is generating the PPR.  Do not refresh your screen.
                    </span>
                  </div>
                )}
              </>
             ) :
              <>
                <YellowButton
                  disabled={!isPPRSubmitted}
                  small='true'
                  onClick={
                    async () => {
                      try {
                        this.setState({ isDownloading: true, downloadErrMsg: '' })
                        this.setState({ showDownloadAlertMsg: false })
                        const res = await requestIndividual(statusFields.aproStudentId)
                        if (res === 'success') {
                          setPPRDownloadDt(studentModel.jsonStudent , 422500005)
                        } else {
                          this.setState({ downloadErrMsg: this.failureErr })
                        }
                      } catch (err) {
                        this.setState({ downloadErrMsg: this.failureErr })
                      } finally {
                        this.setState({ isDownloading: false, showDownloadAlertMsg: false })
                      }
                    }
                  }
                >
                  Download PPR
                </YellowButton>
                {!this.state.downloadErrMsg && downloadedDate && (
                  <div className='cb-black1-color cb-align-center'>
                    <span
                      style={{ fontSize: '9px' }}
                      className='cb-font-weight-black position-relative position-top align-self-center cb-padding-top-8'
                    >
                      Last printed on {moment(new Date(downloadedDate)).tz(moment.tz.guess()).format('MM/DD/yyyy')}
                    </span>
                  </div>
                )}
                {this.state.downloadErrMsg && (
                  <div className='cb-red1-color cb-align-center'>
                    <span
                      style={{ fontSize: '11px' }}
                      className='cb-font-weight-black position-relative position-top align-self-center cb-padding-top-8'
                    >
                      {this.state.downloadErrMsg}
                    </span>
                  </div>
                )}
              </>
            }
          </div>
        </td>}
        <td className="hover-table-cell">
          <div className="display-flex flex-column">
            <p className='text-center align-content-center cb-padding-top-16 cb-padding-left-8 cb-padding-right-8 cb-font-size-small'>{extraColumnData}</p>
            { extensionBtnVisible && !isPastHardEndDate && !extendedEndDate ? <p className='cb-margin-top-16 cb-padding-bottom-8'><NakedButton id='extension-btn' small noOutline noPadding disabled={extensionBtnDisable} onClick={() => this.setState({ requestExtensionModalOpen: true})}><strong>Request Extension</strong></NakedButton></p> : null}
            {extendedEndDate ?
              <p className='text-center cb-padding-bottom-16 cb-font-size-small'>
                {`Extension: ${moment(extendedEndDate).tz(moment.tz.guess()).format('M/DD [at] hh:mmA z')}`}
              </p> : null
            }
            <RequestExtensionModal fullName={`${studentData.firstName} ${studentData.lastName}`} courseTitle={courseTitle} enrollmentId={studentData.enrollmentId} requestExtensionDisabledDate={requestExtensionDisabledDate} modalOpen={requestExtensionModalOpen} setModalOpen={(open) => this.setState({requestExtensionModalOpen: open})} setExtendedEndDate={this.setExtendedEndDate}/>
          </div>
        </td>
      </tr>
    )
  }

  clickHandler(e) {
    e.preventDefault()
  }
}
