import { upload }                       from './artAjax'
import { getArtUtil }                   from './artUtil'
import { getDragUtil }                  from './dragging'
import { uploadAndScorable }            from '../../entities/upload'
import { handleChange }                 from '../../../utility/history'
import { getCurrentPerson }             from '../../../entities/person'

let artUtil = getArtUtil()
let dragUtil = getDragUtil()
let setCanSave,setIsSaving,setIsFinalSaving,getCurrentData, setModalProps
let saveUrl, removeLockUrl

export function initSave(setCanSaveFunc, setIsSavingFunc, setIsFinalSavingFunc, urlSave, setModalPropsFunc, removeLockUrl) {
    setCanSave          = setCanSaveFunc
    setIsSaving         = setIsSavingFunc
    setIsFinalSaving    = setIsFinalSavingFunc
    saveUrl             = urlSave
    setModalProps       = setModalPropsFunc
    removeLockUrl       =  removeLockUrl
}

export function saveHandler(dataToSave) {
    savePreamble()
    // savePostamble will be called after artAjax.upload in both success and failure cases
    return save(true,dataToSave)
}

export async function finalSubmitHandler(finalSubmitInfo) {
    finalSubmitPreamble()
    return await finalSubmit(finalSubmitInfo)
}

export function finalSubmitCancel() {
    setCanSave(false)
    setIsFinalSaving(false)
    setModalProps(({ modalOpen: false, onClose: () => {} }))
}

export function save(callPostamble,dataToSave) {
    try {
        if (dataToSave.length > 0) {
            return upload(saveUrl, dataToSave, true, false, removeLockUrl)
                .then(statuses => {
                    if (callPostamble) {
                        savePostamble(false)
                    }
                    return statuses
                })
                .catch(err => {
                    console.log('upload failed ', err)
                    if (callPostamble) {
                        savePostamble(false)
                    }
                    throw err
                })
        } else {
            if (callPostamble) {
                savePostamble(false)
            }
            return Promise.resolve()
        }
    } catch (e) {
        console.error('SI_Image save had an error', e)
        if (callPostamble) {
            savePostamble(false)
        }
        return Promise.resolve()
    }
}

async function finalSubmit(finalSubmitInfo) {
    let assignment = finalSubmitInfo.assignment
    let upload
    // if the user has not previously saved the document, then upload will be an empty object
    // and we must ask for it. It will be available now since in this case we just saved.
    if (finalSubmitInfo.upload.id === undefined) {
        let personId = getCurrentPerson().id
        await uploadAndScorable(assignment.id, personId)
            .then(arr => {
                upload = arr[0]
                //console.log('had to ask for the upload object',upload)
            })
    } else {
        upload = finalSubmitInfo.upload
    }
    let props = {
        // this url will be used by
        //url: new Upload(assignment.id).urlWithoutId(),
        assignment: assignment,
        lesson: finalSubmitInfo.lesson,
        upload: upload,
        scorableHolder: finalSubmitInfo.scorableHolder,
        fileReqs: {
            extensions: assignment.permittedFileExtensions,
            maxSize: assignment.permittedMaxFileSize,
            minSize: assignment.permittedMinFileSize
        },
        artData: finalSubmitInfo.artData ? finalSubmitInfo.artData : '',
        artType: finalSubmitInfo.artType ? finalSubmitInfo.artType : '',
        onClose: () => {
            finalSubmitPostamble(false)
        },
        modalOpen: true
    }
    return props
}

// after the use clicks ok to the error modal launched by artAjax.upload, handleSaveError runs
// see showErrorModal in CSP_WR_Ajax
function handleSaveError() {
    savePostamble(true)
}

// preambles, code to run prior to saving in the various ways
function commonPreamble() {
    dragUtil.disable()
}

function savePreamble() {
    setCanSave(false)
    setIsSaving(true)
    commonPreamble()
}

function finalSubmitPreamble() {
    //console.log('finalSubmitPreamble')
    setCanSave(false)
    setIsFinalSaving(true)
    commonPreamble()
}

// postambles, code to run when to saving in the various ways completes
function commonPostamble(reload) {
    dragUtil.enable()
    if (reload) {
        handleChange(location)
    }
}

function savePostamble(reload) {
    //console.log('savePostamble')
    setCanSave(true)
    setIsSaving(false)
    commonPostamble(reload)
}

function finalSubmitPostamble(reload) {
    setCanSave(false)
    setIsFinalSaving(false)
    setModalProps(({ modalOpen: false, onClose: () => {} }))
    commonPostamble(reload)
}

// needs rewrite
function prepareSaveData() {
    let clone = getCurrentData()
    let dataToSend = _.filter(clone, section => {
        let shouldSend = section.altered/* && section.ready*/
        return shouldSend
    })
    return dataToSend
}

// we suspend updating the image in state for the few milliseconds
// that ajax is capturing the state during auto save
function suspendStateUpdate(bool) {
    suspend = bool
}
