import { bsliPut, bsliDelete, bsliPostScores }  from '../_core/util/request';

export function addEnrollment(enrollment,teamId) {
    let url = '/teams/' + teamId + '/members/'
    let enrollmentStr = JSON.stringify([enrollment])
    return bsliPostScores(url, enrollmentStr)
}

export function deleteEnrollment(teamId,enrollmentId) {
    let url = '/teams/' + teamId + '/members/' + enrollmentId;
    return bsliDelete(url);
}

export function createTeam(enrollment,selectedModuleId,sectionId,title) {
    let url = '/sections/' + sectionId + '/modules/' + selectedModuleId + '/teams'
    let enrollmentArr = [enrollment];
    let team = JSON.stringify({
        title: title,
        enrollment: enrollmentArr
    })
    return bsliPostScores(url, team)
}

export function saveTeam(team) {
    let url = '/teams/' + team.id;
    return bsliPut(url, team);
}

export function deleteTeam(teamId) {
    let url = '/teams/' + teamId;
    return bsliDelete(url)
}
