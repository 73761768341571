import { getIconClass }                     from '../../utility/util'

const LegendComp = () => {
    let noDraftCls = getIconClass('NoDraft')
    let draftInCls = getIconClass('DraftIn')
    let completeCls = getIconClass('FinalSubmitted')
    return (
        <div className='row cb-margin-top-16 cb-margin-bottom-24'>
            <div className="legend offset-md-6 col-md-6 col-sm-12 col-xs-12 pull-right" aria-hidden="true">
                <h3 className="heading">Legend</h3>
                <div className="row">
                    <div className="col-md-4 col-sm-4 col-xs-4 legend-heading">
                        <span className={noDraftCls + ' block add-padding-small cb-font-size-regular'}></span>
                        No Draft
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 legend-heading">
                        <span className={draftInCls + ' block add-padding-small cb-font-size-regular'}></span>
                        Draft In
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 legend-heading">
                        <span className={completeCls + ' block add-padding-small cb-font-size-regular'}></span>
                        Complete
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LegendComp
