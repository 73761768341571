import { ariaName } from '../../utility/util'

let scrollLimits


export function onDragStart(ev, name, setDragName) {
    //console.log('onDragStart ' + name)
    let div = document.getElementById("region-main-content");
    let rect = div.getBoundingClientRect();
    //console.log('rect',rect)
    let min = 200
    let max = rect.height-200
    scrollLimits = (min < max) ? {min: min, max: max} : false

    ev.dataTransfer.setData("text", ev.target.id);
    setDragName(ariaName(name))
}

export function onDragEnd(ev, name, setDragName) {
    //console.log('onDragEnd ' + name)
    ev.target.classList.remove("draggable");
    removeDecoration()
    setDragName(null)
}

export function onDragLeave() {
    //console.log('onDragEnd')
    removeDecoration()
}

export function onDragOver(e) {
    //console.log('onDragOver')
    e.preventDefault();
    let el = e.target;
    decorateDropArea(el);
}

export function dragging(ev) {
    const scroll = step => {
        //console.log('scroll step=' + step)
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        //let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft;
        window.scroll({ top: scrollTop + step * 5, behavior: 'smooth' });
    }

    if (scrollLimits) {
        let scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
        let posInScrollArea = ev.pageY - scrollPos;

        // posInScrollArea must be kept within scrollLimits
        // if posInScrollArea is too small, scroll up, too big, scroll down
        if (posInScrollArea < scrollLimits.min) {
            scroll(-4);
            return false;
        } else if (posInScrollArea > scrollLimits.max) {
            scroll(4)
            return false
        }
    }
}

export function onMouseEnter(e) {
    //console.log('onMouseEnter')
    let el = e.target;
    el.classList.add("draggable")
}

export function onMouseLeave(e) {
    //console.log('onMouseLeave')
    let el = e.target;
    el.classList.remove("draggable")
}

function decorateDropArea(el) {
    if (el.id && el.id === "createTeam") {
        el.classList.add('row-hover');
        showInstruction(el)
        return;
    } else if (el.id && el.id.startsWith("team")) {
        el.classList.add('row-hover');
        showInstruction(el)
        return;
    } else {
        let p = el.parentElement;
        decorateDropArea(p)
    }
}

export function removeDecoration() {
    let hoverElements = document.getElementsByClassName('row-hover');
    if (hoverElements && hoverElements.length > 0) {
        for (const e of hoverElements) {
            e.classList.remove('row-hover');
        }
    }
    let elems = document.getElementsByClassName('dropInstruction');
    if (elems && elems.length > 0) {
        for (const e of elems) {
            hideInstruction(e)
        }
    }
}

function showInstruction(el) {
    let inst = el.querySelector('.dropInstruction')
    if (inst && inst.classList.contains('hidden')) {
        inst.classList.remove('hidden')
        //console.log('showInstruction removing hidden in ' + el.id)
    }
}

function hideInstruction(inst) {
    if (inst && !inst.classList.contains('hidden')) {
        //console.log('hideInstruction adding hidden to',inst)
        inst.classList.add('hidden')
    }
}
