import React, { useState, useEffect, useRef, useMemo } from 'react'
import { CSP_WR_Canvas } from './CSP_WR_Canvas'
import Quill from 'quill/dist/quill.min.js'
import {resize} from './resizeUtils';

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'

const CSP_WR_Image = (props) => {
    const [displayBigCanvas, setDisplayBigCanvas] = useState([])
    const [enabled, setEnabled] = useState(true)
    const [showQuillEditor, setShowQuillEditor] = useState(true)
    const quillEditorDiv = useRef()
    const inputFileRef = useRef()
    const [warning, setWarning] = useState(false)

    const checkFileType = (file, id) => {
        let fileOk = false
        if (!(!file || file.type.indexOf('image/') !== 0)) {
            let arr = file.type.match(/.*image\/(.*)/)
            fileOk = arr && arr.length > 1 &&
                ['png', 'jpg', 'jpeg'].some(type => type === arr[1].toLowerCase())
        } 
        return fileOk
    }

    const onMouseEnterLeave = (event, id) => {
        if (event.type === "mouseenter") {
            const elements = document.getElementsByClassName("bigCanvas")
            if (elements) {
                Array.from(elements).forEach((el) => {
                    el.classList.add("hidden")
                })
            }
            setDisplayBigCanvas([id])
        } else if (event.type === "mouseleave") {
            const bigCanvas = event.currentTarget
            bigCanvas.classList.add("hidden")
            setDisplayBigCanvas([])
        }
    }

    const loadFromDesktop = (file, id, source) => {
        setWarning(false)
        var reader = new FileReader()
        reader.onload = function () {
            const image = new Image();
            image.onload =  () => {
                const resizedCanvas = document.createElement('canvas');
                // estimated byte/pixel < 1.5
                // keep the size width < 750 and total pixel < 5M / 1.5
                let widthReduct = Math.min(1.0, 750 / image.width)

                // max height = max pixel / width
                let maxHeight = (5000000 / 1.5) / (widthReduct * image.width)

                let heightReduct = Math.min(1.0, maxHeight / image.height)
                let reduction = Math.min(widthReduct, heightReduct)

                resizedCanvas.height = image.height * reduction
                resizedCanvas.width = image.width * reduction
                resize(image, resizedCanvas, {
                    unsharpAmount: 80,
                    unsharpRadius: 0.6,
                    unsharpThreshold: 2
                }).then(result => props.onChange({ name: file.name, data: result.toDataURL(), source: source }, id))
                    .catch(err => console.log('picaInst.resize failed', err))
            }
            image.src = reader.result;
        }
        reader.onerror = function (error) {
            console.error('Error: ', error)
        }
        reader.readAsDataURL(file)
    }

    const handleFileSelection = (event) => {
        const file = event.target.files[0]
        const elem = quillEditorDiv?.current
        let id = elem.parentNode.getAttribute("data-id")
        if (checkFileType(file)) {
            loadFromDesktop(file, id, "dropzone")
        } else {
            setWarning(true)
            return false
        }
    }
    const imgHandler = id => {
        inputFileRef.current.value = ""
        inputFileRef.current.click()
    }

    useEffect(() => {
        if (quillEditorDiv?.current !== null && props.id) {
            if (!props.readOnly) {                
                let options = {
                    modules: {
                        toolbar: ['image'],
                        keyboard: {
                            bindings: {
                                tab: {
                                    key: 9,
                                    handler: ()=> true
                                }
                            }
                        },
                    },
                    theme: 'snow'
                }
                const elem = quillEditorDiv?.current
                let id = elem.getAttribute("id")
                let dataId = elem.getAttribute('data-id') 
                var quill = new Quill(`#${id}`, options)
                quill.getModule("toolbar").addHandler("image", function () {
                    let id = elem.parentNode.getAttribute("data-id")
                    imgHandler(id)
                })
                quill.setContents([
                    { insert: 'Drop Images Here\n', attributes: { bold: true, align: 'center' } }
                ])
                quill.disable()
            }
        }
    }, [quillEditorDiv, props.id, showQuillEditor])

    useEffect(() => {
        const elements = document.getElementsByClassName("bigCanvas")
        if (elements) {
            Array.from(elements).forEach((el) => {
                el.classList.add("hidden")
            })
        }
        if (displayBigCanvas.length > 0) {
            displayBigCanvas.forEach((id) => {
                const bigCanvasId = `big${id.charAt(0).toUpperCase()}${id.slice(1)}`
                const bigCanvas = document.getElementById(bigCanvasId)?.children[0]
                if (bigCanvas) {
                    const ctx = bigCanvas.getContext("2d")
                    const image = new Image()
                    image.src = document.getElementById(id).src
                    ctx.canvas.width = image.width
                    ctx.canvas.height = image.height
                    ctx.drawImage(image, 0, 0, image.width, image.height)
                    bigCanvas.classList.remove("hidden")
                }
            })
        }
    }, [displayBigCanvas])

    const warningCls = warning ? '' : 'hidden'
    const hiddenCls = props.readOnly ? 'hidden' : ''

    useEffect(() => {
        const handleClick = (event) => {
            setWarning(false);
        }
        document.body.addEventListener('click', handleClick);
        return () => {
            document.body.removeEventListener('click', handleClick);
        }
    }, []);
    useEffect(() => {
        if (props.images.length >= 3) {
            setShowQuillEditor(false)
        } else {
            setShowQuillEditor(true)
        }
    }, [props.images.length])
    const canvases = useMemo(() => {
        const smallCanvases = props.images?.map((image, index) => {
            return (
                <CSP_WR_Canvas
                    key={`small_${index}`} index={index}
                    id={`canvas_${props.id}_${index}`}
                    hiddenCls={hiddenCls}
                    onLoad={() => { }}
                    onError={() => { }}
                    clearImg={(param) => { props.clearImg(param) }}
                    img={props.images[index]}
                    onMouseAction={onMouseEnterLeave}
                    readOnly={props.readOnly}
                    onDragCallback={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        const dataId = e.currentTarget.getAttribute("data-id")
                        const element = e.currentTarget
                        if (element.classList.contains('highlight')) {
                            element.classList.remove('highlight')
                        }
                        const file = e.dataTransfer.files[0]
                        if (checkFileType(file)) {
                            loadFromDesktop(file, dataId, "canvas")
                        } else {
                            setWarning(true)
                        }
                    }}
                />
            )
        })
        return smallCanvases

    }, [JSON.stringify(props.images), props.readOnly])

    const bigCanvases = useMemo(() => {
        const bigCanvasesInt = props.images?.map((image, index) => {
            const img = new Image()
            img.src = image.byte
            return (
                <div id={`bigCanvas_${props.id}_${index}`}
                    key={`big_${index}`}>
                    <canvas className="bigCanvas hidden"
                        tabIndex="-1"
                        aria-label={`Full size of an image whose file name is, ${image.fileName}`}
                        style={{
                            maxWidth: '750px'
                        }}
                        onMouseLeave={(event) => onMouseEnterLeave(event, props.id)}
                    ></canvas>
                </div>
            )
        })
        return bigCanvasesInt
    }, [JSON.stringify(props.images), props.readOnly])

    useEffect(() => {
        setDisplayBigCanvas([]);
    }, [JSON.stringify(props.images)])
    
    return (
        <div className='image indent4'>
            <input type="file" className="browse hidden" name="fileupload" ref={inputFileRef} onChange={(event) => { handleFileSelection(event) }} />
            {showQuillEditor && props.readOnly === false && <div data-id={`dropzone${props.id}`} className={`drop-zone${hiddenCls}`}>
                <div
                    className="quill img"
                    data-id={props.id}
                    id={`div_img_${props.id}`}
                    data-testid={`div_img_${props.id}`}
                    ref={quillEditorDiv}
                    onDragOver={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        const dataId = e.currentTarget.getAttribute("data-id")
                        const element = document.querySelector(`[data-id="dropzone${dataId}"]`)
                        if (!element.classList.contains('highlight')) {
                            element.classList.add('highlight')
                        }
                    }}

                    onDragLeave={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        const dataId = e.currentTarget.getAttribute("data-id")
                        const element = document.querySelector(`[data-id="dropzone${dataId}"]`)
                        if (element.classList.contains('highlight')) {
                            element.classList.remove('highlight')
                        }
                    }}

                    onDrop={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        const dataId = e.currentTarget.getAttribute("data-id")
                        const element = document.querySelector(`[data-id="dropzone${dataId}"]`)
                        if (element.classList.contains('highlight')) {
                            element.classList.remove('highlight')
                        }
                        const file = e.dataTransfer.files[0]
                        if (checkFileType(file)) {
                            loadFromDesktop(file, dataId, "dropzone")
                        } else {
                            setWarning(true)
                        }
                    }}
                >
                </div>
                <img className="drop-image hidden" id={`dropzone${props.id}`} />
            </div>}
            <div aria-live='polite'>
                <span className={`warning ${warningCls}`}>{'Error: Only the following file types are allowed: JPEG, PNG'}</span>
            </div>
            <div className='smallContainer' data-id={`dropzone${props.id}`}>
                {canvases}
            </div>
            <div className='largeContainer' data-id={`dropzone${props.id}`}>
                {bigCanvases}
            </div>
        </div>
    )

}

export default CSP_WR_Image
