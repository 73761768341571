import {
	getCourseParameter,
	getSection,
	isArt,
	isCSP, isResearch, isResearch_PREP,
	isSeminar_IRR_CP,
	isSeminar_IWA_CP
} from '../../entities/courseinstance'
import {bsliGet} from '../../_core/util/request'
import React from 'react'

export const checkScoringAccess = async params => {
	const { scorableHolder, sectionId, lesson } = params, { isArchived } = getSection(sectionId)

	const isSemOrResSpecificAssignment = isSeminar_IWA_CP(lesson.assignmentId) || isSeminar_IRR_CP(lesson.assignmentId) || isResearch_PREP(lesson.assignmentId)
	let teacherScoringWarning = `This submission was recently opened by your co-teacher. If this teacher is also ${isSemOrResSpecificAssignment ? 'attesting' : 'scoring'}, you could overwrite each other’s ${isSemOrResSpecificAssignment ? 'attestations' : 'scores'}. Click Continue to proceed to the submission. Click Cancel to return to the Progress page.`
	let teacherRescoringWarning = `The submission you are attempting to ${isSemOrResSpecificAssignment ? 'attest' : 'score'} was recently opened by your co-teacher. If this teacher is also ${isSemOrResSpecificAssignment ? 'attesting' : 'scoring'}, you could overwrite each other’s ${isSemOrResSpecificAssignment ? 'attestations' : 'scores'}. Click Continue to proceed to the submission. Click Cancel to return to the Progress page.`
	let rescoreMessage = `${isSemOrResSpecificAssignment ? 'You have already submitted your attestion for this submission' : 'This submission has already been scored'}. Click Continue to proceed to the submission. Click Cancel to return to the Progress page.`
	let reconfirmMessage = 'This submission has already been confirmed.  Click Continue to proceed to the submission.  Click Cancel to return to the Progress page.'

	const url = `scorables/${scorableHolder.scorable.id}/scoreStatus`
	const response = await bsliGet(url)
	const { scoringPermissionWarningMessage: accessDeniedMessage, scoringWarningFlag: warningFlag } = response

	const confirmScorables = getCourseParameter('confirmScorables'), isScored = scorableHolder.isScored()
	const warningMessage = warningFlag && (isScored ? teacherRescoringWarning : teacherScoringWarning), finalRescoreMessage = isScored && (confirmScorables ? reconfirmMessage : rescoreMessage)

	if (isArt() || isCSP()) {
		return {
			showScoringPage: true
		}
	}

	if (!isArchived && (accessDeniedMessage || warningFlag || isScored)) {
		const message = accessDeniedMessage || warningMessage || finalRescoreMessage
		let title
		if (accessDeniedMessage) title = 'Scoring Access';
		else if (!isScored) title = confirmScorables ? 'Confirm Submission' : 'Score Assignment';
		else title = isSemOrResSpecificAssignment ? 'Re-Attest' : confirmScorables ? 'Reconfirm Submission' : 'Rescore Assignment';

		return {
			confirmModalTitle: title,
			showConfirmModal: true,
			accessDenied: !!accessDeniedMessage,
			message,
			showScoringPage: false,
			confirmModalButtons: {
				yesButtonText: accessDeniedMessage ? 'Ok':  'Continue',
				noButtonText: 'Cancel',
				hideNoButton: accessDeniedMessage,
			}
		}
	} else return { showScoringPage: true }
}
