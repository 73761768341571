import React, { Fragment }                        from 'react';
import { Button }                                 from '@cb/apricot-react'
import { reactRender }                            from '../../utility/util'
import {
  getCourseParameter, getCurrentSection,
  isAfterHardEndDate, isArt, isCSP
} from "../../entities/courseinstance";
import {pathToUrl}                                from '../../content/static_content'
import {bsliGet }                                 from '../../_core/util/request'
import { runOriginality, viewOriginality }        from '../../utility/originality'
import {entitiesUpload}                           from '../../assignment/entities/upload'

const ScoringMenuComp = ({ parms }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [upload, setUpload] = useState(parms.upload)
  const [updateCnt, setUpdateCnt] = useState(0)
  const [reportLinkLoading, setReportLinkLoading] = useState(false)
  const [label, setLabel] = useState('View')

  const formToSubmitRef = useRef(null)

  const helpers = {
    showSubmission: parms.lesson.title !== "Images" ,
    showTurnitinButton: parms.lesson.title !== "Images" && getCourseParameter('turnitinEnabled'),
    originalityRan: upload && upload.hasBeenSubmittedToTurnitin(),
    fileName: upload?.fileName || '',
    submissionUrl: upload ? (upload.url() + '/download') : 'javascript:void(0)',
    printUrl: pathToUrl(parms.cmsLesson.rubric),
    isArtWE: parms.lesson.title === 'Written Evidence',
    iThenticate: parms?.assignment?.iThenticate,
  }

  useEffect(() => {
    setLabel(helpers.originalityRan ? 'View' : 'Run')
  }, [upload])

  const title = helpers.originalityRan ? 'View originality in TurnItIn' : 'Submit ' + helpers.fileName + ' to TurnItIn'
  const noSubmission = !upload;
  const art = isArt()
  const csp = isCSP();
  const currentSection = getCurrentSection()
  const student = parms.scoree;
  const pastHardEndDate = isAfterHardEndDate()
  const readOnly = currentSection?.isArchived || pastHardEndDate ||
    currentSection?.pastSoftEndDate ? !(student && student.enrollment && student.enrollment.hasExtension) : false;
  const disableCheckOriginality = csp || art || noSubmission || pastHardEndDate || (readOnly && !upload.hasBeenSubmittedToTurnitin())

  const onCloseOriginality = () => {
    setUpdateCnt(updateCnt + 1)
  }

  const getUpdatedUpload = async () => {
    const { scorable } = parms
    const updatedUpload = scorable.uploadId ? await entitiesUpload(scorable.uploadId, scorable.assignmentId) : null
    setUpload(updatedUpload)
  }

  const runOrViewOriginality = () => {
    if (upload.hasBeenSubmittedToTurnitin()) {
      viewOriginality(upload, formToSubmitRef)
    } else {
      runOriginality(null, upload, false, onCloseOriginality, setIsSaving, getUpdatedUpload)
    }
  }

  const downloadSubmission = async () => {
    const url = upload.getDownloadUrl()
    await bsliGet(url).then(url => window.open(url.url, '_blank'))
  }

  const retrieveIthenticateReportUrl = async (sisId) => {
      try {
          setReportLinkLoading(true)
          let url = `/review/plagiarism/reports/${sisId}`
          let resp = await bsliGet(url)
          setReportLinkLoading(false)
          return window.open(new URL(resp), '_blank')
      } catch (e) {
        console.log(e)
      }
  }

  return (
    <div className='btn-toolbar pull-right-md' rol='toolbar' aria-label='Utility Bar'>
      <div className="btn-group" role="group">
        {helpers.showTurnitinButton && isSaving &&
          <Button small='true' loading>Loading</Button>
        }
        {!noSubmission && (helpers.isArtWE || !art) && <Button type="button" className="js-download-file modal-btn" small='true' onClick={downloadSubmission}>
            View/Print Submission
        </Button>}
        {parms?.assignment?.sisId && parms?.assignment?.iThenticate?.overallMatch && !art && <Button className="modal-btn" small='true' loading={reportLinkLoading} onClick={() => retrieveIthenticateReportUrl(parms?.assignment?.sisId)}>
          View Ithenticate Report
        </Button>}
        {!disableCheckOriginality && <Button type="button"
           className="js-turnitin modal-btn" small='true'
           onClick={runOrViewOriginality}
           title={title}
           loading={isSaving}
         >
           {label} Originality
         </Button>}
      </div>
      <form ref={formToSubmitRef} method="POST" target="_blank" action="" name="tiiForm"
        className="js-turnitin-form" encType="application/x-www-form-urlencoded">
      </form>
    </div>
  )
}

export default ScoringMenuComp
