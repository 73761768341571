import React from 'react'

import CSP_WR_Title from './CSP_WR_Title'
import CSP_WR_Text from './CSP_WR_Text'
import CSP_WR_Image from './CSP_WR_Image'

const CSP_WR_3C = (props) => {
    const labels = ['i.', 'ii.', 'iii.', 'iv.', 'v.']
    const titles = [
        'The first-program code segment must be a student-developed procedure that:',
        "The second program code segment must show where your student-developed procedure is being called in your program.",
        'Describes in general what the identified procedure does and how it contributes to the overall functionality of the program.',
        'Explains in detailed steps how the algorithm implemented in the identified procedure works. Your explanation must be detailed enough for someone else to recreate it.'
    ]
    const rows = props.data.map((row, index) => {
        if (index < 2) {
            return (
                <div key={index}>
                    <CSP_WR_Title
                        title={titles[index]}
                        label={labels[index]}>
                    </CSP_WR_Title>
                    <CSP_WR_Image
                        id={`3C_${index}`}
                        index={index}
                        readOnly={props.readOnly}
                        absIndex={props.sectionOffset + index}
                        images={row.images}
                        badImage={props.badImage}
                        initializeDrag={props.initializeDrag}
                        onUploadError={props.onUploadError}
                        onChange={props.onChange}
                        clearImg={(param) => { props.clearImg(param) }}
                    >
                    </CSP_WR_Image>
                </div>
            )
        } else {
            return (
                <div key={index}>
                    <CSP_WR_Title
                        title={titles[index]}
                        label={labels[index]}>
                    </CSP_WR_Title>
                    <CSP_WR_Text
                        id={`3C_${index}`}
                        index={index}
                        absIndex={props.sectionOffset + index}
                        wouldExceed={props.wouldExceed}
                        frozen={props.frozen}
                        img={row.img}
                        wordCounts={props.wordCounts}
                    >
                    </CSP_WR_Text>
                </div>
            )
        }
    })
    return (
        <div className='section'>
            <table role="presentation">
                <tbody>
                    <tr>
                        <td className='tableTd1 cb-k-12-program'>Procedure:</td>
                        <td>
                            Capture and paste two program code segments you developed during the administration of this task that contain a student-developed procedure that implements an algorithm used in your program and a call to that procedure.
                        </td>
                    </tr>
                </tbody>
            </table>
            <CSP_WR_Title
                title={titles[0]}
                label={labels[0]}>
            </CSP_WR_Title>
            <ul className='indent4'>
                <li>
                    <span>Defines the procedure's name and return type (if necessary)</span>
                </li>
                <li>
                    <span>Contains and uses one or more parameters that have an effect on the
                        functionality of the procedure
                    </span>
                </li>
                <li>
                    <span>Implements an algorithm that includes sequencing, selection and iteration</span>
                </li>
            </ul>

            <CSP_WR_Image
                id={'3C_0'}
                images={props.data[0].images}
                index={0}
                readOnly={props.readOnly}
                absIndex={props.sectionOffset + 0}
                badImage={props.badImage}
                initializeDrag={props.initializeDrag}
                onUploadError={props.onUploadError}
                onChange={props.onChange}
                clearImg={(param) => { props.clearImg(param) }}
            >
            </CSP_WR_Image>

            {rows[1]}
        </div>
    )
}
export default CSP_WR_3C
