import React, { Fragment } from 'react';
import { getCurrentPerson } from '../../entities/person.jsx'
import SupportingMaterialListComp from './supportingMaterialList'
import { PublishableComp } from './publishable'

export function SupportingMaterialLayoutComp({ supportingMaterialData, refetchCMSContent, lessonId, moduleId, publishMaterial, moduleHolder }) {
  // supportingMaterialData has: publishableMaterial, teacherMaterials
  // teacherMaterials: fileName, fileSize, id, title, url
  //console.log('SupportingMaterialLayoutComp arguments', arguments)

  if (supportingMaterialData) {
    let publishableMaterial = supportingMaterialData.publishableMaterial
    let teacherMaterials = supportingMaterialData.teacherMaterials

    let isTeacher = getCurrentPerson().isTeacher()
    let isAvailable = publishableMaterial && publishableMaterial.isAvailable
    return (
      <Fragment>
        <hr className='hr-margin' />
        <div id="source-material-region">
          {isAvailable &&
            <PublishableComp
                publishable={publishableMaterial}
                publishMaterial={publishMaterial}
                refetchCMSContent={refetchCMSContent}
                moduleId={moduleId} />
          }
        </div>
        <div id="supplemental-material-region" className="add-top-margin-2x">
          {(isTeacher || teacherMaterials.length > 0) &&
            <SupportingMaterialListComp materials={teacherMaterials} lessonId={lessonId} moduleId={moduleId} moduleHolder={moduleHolder} />
          }
        </div>
      </Fragment>
    )
  } else {
    return (
      <div></div>
    )
  }
}
