import { getCurrentPerson } from '../../entities/person.jsx'
import { isReadOnlyMode } from '../../entities/courseinstance'
import { ConfirmModal } from '../../_core/views/modal.jsx'
import { reactRender } from '../../utility/util'
import { bsliDelete, bsliGet } from '../../_core/util/request'
import { handleChange } from '../../utility/history'
import DPToolTip from '../../utility/tooltip'

const SupportingMaterialItemComp = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState({});
  const toolKey = 'material_' + props.material.id;
  const toolTip = new DPToolTip(toolKey);

  useEffect(() => {
    toolTip.createTooltips();
    return () => toolTip.removeTooltips();
  }, []);

  const templateHelpers = () => {
    const fileSize = props.material.fileSize;
    return {
      isTeacher: getCurrentPerson().isTeacher(),
      url: props.material.url + '/download',
      fileName: props.material.fileName,
      formattedFileSize: fileSize && filesize(fileSize).toUpperCase(),
      title: props.material.title
    };
  };

  const downloadLatest = async () => {
    const uploadDownloadLink = props.material.url + '/' + props.material.id + '/download';
    await bsliGet(uploadDownloadLink).then(url => window.open(url.url, '_blank'));
    $('.active.active-trail').focus();
  };

  const showConfirmModal = () => {
    setOpenModal(true);
    setConfirmModalProps({
      title: 'Delete Supplemental Material',
      message: 'Are you sure you want to delete this resource?',
      onConfirmYes: deleteItem,
      onClose: () => {},
      modalId: 'deleteSupportingMaterialModal'
    });
  };

  const deleteItem = () => {
    let url = props.material.url + '/' + props.material.id;
    bsliDelete(url)
      .then(json => {
        props.reloadTeacherMaterials();
      })
      .catch(() => {
        addErrorMessage('Error: An error occurred deleting this resource. Please try again.', 'deleteSubmission');
      });
  };

  const helpers = templateHelpers();
  let href = props.material.href;
  let cls = href ? 'cb-new-window' : 'cb-download';
  let toolTipParms = { placement: 'right' };

  return (
    <li>
      <span className={'cb-glyph ' + cls + ' text-muted'} aria-hidden="true" />
      {href &&
        <a href={href} target="_blank" data-cb-apricot='tooltip' id={toolTip.addToolTip(toolTipParms)}
          className='cb-btn cb-btn-sm cb-btn-naked' role='button'
          data-cb-title={helpers.title + ' (Opens in a new window)'}>{helpers.title}
        </a>
      }
      {!href &&
        <React.Fragment>
          <button className='cb-btn cb-btn-sm cb-btn-naked cb-btn-blue js-download-file'
            onClick={downloadLatest}
            data-cb-apricot='tooltip' id={toolTip.addToolTip(toolTipParms)} data-cb-title={'Download ' + helpers.title}>
            {helpers.title}
          </button>
          <small>{helpers.formattedFileSize}</small>
        </React.Fragment>
      }
      {helpers.isTeacher && !props?.isReadOnly &&
        <button className="js-delete-item cb-btn cb-btn-sm cb-btn-naked cb-btn-blue danger-on-hover"
          onClick={showConfirmModal}
          data-cb-apricot='tooltip' id={toolTip.addToolTip(toolTipParms)}
          data-cb-target={'Delete resource named ' + helpers.title}>
          <span className="cb-glyph cb-x-mark" aria-hidden="true" />
          <span className="sr-only">
            {'Delete resource named ' + helpers.title}
          </span>
        </button>
      }
      <ConfirmModal {...confirmModalProps} open={openModal} onClose={() => { setOpenModal(false)} } />
    </li>
  )
};

export default SupportingMaterialItemComp;
