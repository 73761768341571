import { NakedButton, RadioButton, RadioButtonGroup, SpacerV, Tab, Tabs, YellowButton } from '@cb/apricot-react'
import {
  getCourseInstanceId,
  getCourseParameter,
  getCurrentSectionId,
  getSection,
  isAfricanAmericanStudies,
  isAfterHardEndDate,
  isArt,
  isResearch_PREP,
  isScorableAssignment,
  isSeminar_IRR_CP,
  isSeminar_IWA_CP,
} from '../../entities/courseinstance'
import { ConfirmModal } from '../../_core/views/modal.jsx'
import { bsliDelete, bsliGet, bsliPut } from '../../_core/util/request'
import { RubricDimension } from './rubricDimension'
import PlagiarismModalComp from './plagiarismModal'
import { useNavigate } from 'react-router-dom'
import { getCurrentPerson } from '../../entities/person.jsx'

const RubricLayout = props => {
  const [state, setState] = useState({
    isSaving: false,
    alertText: '',
    showTextArea: props.scoreHolder.notes && props.scoreHolder.notes.length > 0,
    updateCnt: 0,
    notPlagiarismAttestationInd: props.scoreHolder?.notPlagiarismAttestationInd,
  })
  const navigate = useNavigate()
  const [plagiarismModalProps, setPlagiarismModalProps] = useState(null)
  const [showPlagiarismModal, setShowPlagiarismModal] = useState(false)
  const [confirmModalProps, setConfirmModalProps] = useState(null)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [deleted, setDeleted] = useState(null);
  const hideButtonsForResAndSemSpecificAssignments =
    isSeminar_IWA_CP(props.lesson.assignmentId) ||
    isSeminar_IRR_CP(props.lesson.assignmentId) ||
    isResearch_PREP(props.lesson.assignmentId)

  const changeNotes = e => {
    props.scoreHolder.notes = e.target.value
  }

  const getReadOnly = () => {
    let currentSection = getSection(getCurrentSectionId())
    let student = props.scoree.enrollment
    let team = props.scoree.team
    let readOnly = false
    let isPastSoftDate = props.isWe ? currentSection.weCoursePastSoftEndDate : currentSection.pastSoftEndDate
    let isPastHardDate = props.isWe ? currentSection.weCoursePastHardEndDate : currentSection.pastHardEndDate
    if (student && (student.personId != null || student.dpPersonId != null) && !hideButtonsForResAndSemSpecificAssignments) {
      readOnly = isPastSoftDate || isPastHardDate ? !(student && student.hasExtension) : false
    }
    if (isPastSoftDate && team && team.enrollment && !hideButtonsForResAndSemSpecificAssignments) {
      let lockedEnrollments = team.enrollment.filter(e => !e.hasExtension)
      if (lockedEnrollments && lockedEnrollments.length > 0) {
        readOnly = true
      }
    }
    let isTeacher = getCurrentPerson().isTeacher()
    let isCoordinator = getCurrentPerson().isCoordinator()
    if ((isTeacher || isCoordinator) && isScorableAssignment(props.lesson?.assignmentId) && !isAfterHardEndDate()) {
      readOnly = false
    }
    return readOnly
  }

  const showTextarea = () => {
    setState(state => {
      if (!state.showTextArea) {
        return {
          showTextArea: true,
        }
      }
    })
  }

  const templateHelpers = () => {
    let scoreHolder = props.scoreHolder
    let readOnly = getReadOnly()
    let courseCd = getCourseInstanceId().slice(0, 2)
    let showPlagarism = !props.isWe && _.include(['40', '41', '42', '43', '32', '33', '34'], courseCd)
    return {
      showPlagiarism: showPlagarism,
      submittedPlagiarism: !!scoreHolder.submittedPlagiarism,
      csp: courseCd === '42',
      scoresCaptured: !getCourseParameter('confirmScorables'),
      hideDelete: readOnly,
      notPlagiarismAttestationInd: state.notPlagiarismAttestationInd,
      art: isArt(),
      isDraftIn: props.scorableHolder.scorable.status === 2,
    }
  }

  const scoreHolderChanged = () => {
    setState(state => {
      return {
        updateCnt: state.updateCnt + 1,
      }
    })
  }

  const openPlagiarismModal = async () => {
    if (isAfricanAmericanStudies()) {
      window.open('https://forms.collegeboard.org/reportcheating', '_blank')
    } else {
      let assignmentId = props.scorable.assignmentId
      let rationales = await bsliGet('assignments/' + assignmentId + '/plagiarism/rationales')
      let scoree = props.scoree

      setPlagiarismModalProps({
        scorable: props.scorable,
        assignmentId: assignmentId,
        rationales: rationales,
        upload: props.upload,
        scoreeName: scoree.team ? scoree.team.fullName : scoree.enrollment.fullName,
        plagiarismReported: plagiarismReported,
        modalId: 'plagiarism-modal',
      })
      setDeleted(false);
      setShowPlagiarismModal(true)
    }
  }

  const getNumberOfScoredRubrics = scores => {
    let scoresToCount = scores ? scores : props.scoreHolder.scores
    return _.compact(_.pluck(scoresToCount, 'score')).length
  }

  const saveScores = e => {
    e.stopPropagation()
    setState({
      isSaving: true,
    })
    props.scoreHolder
      .save()
      .then(() => {
        setState({
          isSaving: false,
        })
        navigate('..', { relative: 'path' })
      })
      .catch(() => {
        setState({
          alertText: 'Error: There was an error while saving scores. Please try again.',
        })
      })
  }

  const plagiarismReported = () => {
    let scoreHolder = props.scoreHolder
    scoreHolder.submittedPlagiarism = true
    scoreHolderChanged()
  }

  const showDeletePlagiarismModal = e => {
    e.preventDefault()
    e.stopPropagation()
    var plagiarismUrl = 'scorables/' + props.scorableHolder.scorable.id + '/plagiarism'
    setConfirmModalProps({
      title: 'Remove Plagiarism Report',
      message: 'Are you sure you want to delete the plagiarism report?',
      onConfirmYes: () => {
        bsliDelete(plagiarismUrl).then(() => {
          props.scoreHolder.submittedPlagiarism = false
          scoreHolderChanged();
          setDeleted(true);
        })
      },
      reloadOnConfirm: false,
      closeWhenConfirmed: true,
      modalId: 'deletePlag',
    })
    setShowConfirmModal(true)
  }

  const updatePlagiarisedAttestation = attestation => {
    setState(state => ({ ...state, notPlagiarismAttestationInd: attestation }))
    var plagiarismUrl = 'scorables/' + props.scorableHolder.scorable.id + '/notplagiarism/attestation/' + attestation
    bsliPut(plagiarismUrl, {}).catch(() => {
      displayAlert('Error happened please try again.', 'updatePlagiarisedAttestation')
    })
  }

  let scoreHolder = props.scoreHolder
  let scores = scoreHolder.scores
  const previouslySaved = getNumberOfScoredRubrics(scores) > 0
  const rubrics = props.rubricHolder.rubrics
  let helpers = templateHelpers()

  let saveText = `Save ${
    previouslySaved && !hideButtonsForResAndSemSpecificAssignments
      ? ' Updates'
      : helpers.scoresCaptured && !hideButtonsForResAndSemSpecificAssignments
      ? ' Scores'
      : ''
  }`
  let plagText
  if (helpers.csp) {
    if (helpers.submittedPlagiarism) {
      plagText = 'Plagiarism has been reported for this submission.'
    } else {
      plagText = 'Report Plagiarism or Violation'
    }
  } else if (!isArt() && isAfricanAmericanStudies()) {
    plagText = 'Report Plagiarism'
  } else if (helpers.art) {
    if (helpers.submittedPlagiarism) {
      plagText = 'Plagiarism has been reported for this submission.'
    } else {
      plagText = 'Report Plagiarism'
    }
  } else {
    if (helpers.submittedPlagiarism) {
      plagText = 'Plagiarism or Falsification has been reported for this submission.'
    } else {
      plagText = 'Report Plagiarism or Falsification'
    }
  }

  let pastHardEndDate = isAfterHardEndDate(props.isWe)
  let currentSection = getSection(getCurrentSectionId())

  let readOnly = currentSection.isArchived || getReadOnly()
  let numScores = getNumberOfScoredRubrics()
  let saveDisable = numScores < rubrics.length || readOnly
  let plagDisabled = numScores < rubrics.length || readOnly

  let notesDisabled = readOnly
  let notesBtnCls = state.showTextArea ? 'hidden' : ''
  let notesTextAreaCls = state.showTextArea ? '' : ' hidden'

  let alertCls = state.alertText?.length > 0 ? '' : 'hidden'

  // rubricHolder.rubrics is a list of type Rubric
  let rubricList = () =>
    rubrics.map((rubric, index) => {
      const props = {
        rubric: rubric,
        scoreHolder: scoreHolder,
        readOnly: readOnly,
        scoreHolderChanged: scoreHolderChanged,
      }
      return <RubricDimension {...props} key={`rubric-dimension-${index}`} />
    })

  return (
    <>
      <h3 className="sr-only">Score Submission</h3>
      <Tabs>
        <Tab selected label={`${hideButtonsForResAndSemSpecificAssignments ? 'Attestion' : 'Scoring'}`} panelId="rubricLayout">
          <form role="form">
            {rubrics.length > 0 && (
              <div>
                <div className="js-dimension-region">{rubricList()}</div>
                <div className="form-group add-top-margin-3x">
                  <NakedButton
                    light
                    icon="plus"
                    iconLeft
                    iconDecorative
                    className={`cb-blue5-color cb-no-padding-left ${notesBtnCls}`}
                    onClick={showTextarea}
                  >
                    Add General Notes (Optional)
                  </NakedButton>

                  <div className={'cb-input js-general-notes ' + notesTextAreaCls} style={{ marginBottom: '10px' }}>
                    <label htmlFor="notesOptional">General Notes (Optional)</label>
                    <textarea
                      id="notesOptional"
                      className="form-control"
                      title="Add General Notes"
                      rows="3"
                      maxLength="2000"
                      onChange={changeNotes}
                      disabled={notesDisabled}
                      defaultValue={scoreHolder.notes}
                    ></textarea>
                  </div>
                </div>

                <p className={'alert alert-danger js-save-scores-error remove-bottom-margin ' + alertCls} role="alert">
                  {state.alertText}
                </p>

                <div className="add-top-margin-3x add-bottom-margin-1x">
                  <YellowButton onClick={saveScores} disabled={saveDisable} loading={state.isSaving}>
                    {saveText}
                  </YellowButton>
                </div>
              </div>
            )}
            {helpers.showPlagiarism && (
              <>
                {helpers.submittedPlagiarism && (
                  <>
                    <p className="add-top-margin-3x add-bottom-margin-1x cb-red1-color">{plagText}</p>
                    {!helpers.hideDelete && !pastHardEndDate && (
                      <>
                        <NakedButton onClick={showDeletePlagiarismModal}>Delete Report</NakedButton>
                        <ConfirmModal {...confirmModalProps} open={showConfirmModal} onClose={() => setShowConfirmModal(false)} />
                      </>
                    )}
                  </>
                )}
                {!helpers.submittedPlagiarism && (
                  <>
                    <div
                      className={`add-top-margin-3x add-bottom-margin-1x ${
                        hideButtonsForResAndSemSpecificAssignments ? 'hidden' : ''
                      }`}
                    >
                      {helpers.csp && (
                        <>
                          <span>
                            If you have evidence that the student has plagiarized or violated the performance task guidelines,
                            click on the button below. For more information on what constitutes plagiarism, view the AP Computer
                            Science Principles policy on plagiarism. The performance task guidelines can be found here in
                            the&nbsp;
                          </span>
                          <a
                            href="https://apcentral.collegeboard.org/pdf/ap-csp-student-task-directions.pdf?course=ap-computer-science-principles"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            AP Computer Science Principles Student Handouts
                          </a>
                          .
                        </>
                      )}
                      {!helpers.csp && !isArt() && isAfricanAmericanStudies() && (
                        <>
                          <span>If you have evidence that the student has plagiarized, click on the button below.</span>
                        </>
                      )}
                      {!helpers.csp && !isArt() && !isAfricanAmericanStudies() && (
                        <>
                          <span>
                            If you have evidence that the student has plagiarized or falsified information, click on the button
                            below. For more information on what constitutes plagiarism, view the AP Capstone policy on plagiarism
                            and falsification or fabrication of information.
                          </span>
                        </>
                      )}
                      {isArt() && (
                        <>
                          <span>
                            After reviewing my student’s work according to the AP Art and Design Artistic Integrity Agreement:
                          </span>
                          <SpacerV size="32" />
                          <RadioButtonGroup onChange={updatePlagiarisedAttestation} defaultValue ={`${helpers.notPlagiarismAttestationInd}`} vertical required name="optRadios">
                            <RadioButton
                              label="I can verify this is my student’s original work."
                              value="true"
                              disabled={helpers.isDraftIn || pastHardEndDate}
                            />
                            <RadioButton
                              label="I am unsure or unable to confirm if my student created this work."
                              value="false"
                              disabled={helpers.isDraftIn || pastHardEndDate}
                            />
                          </RadioButtonGroup>
                          <SpacerV size="32" />
                          <span>
                            If you have evidence that your student has plagiarized or used Artificial Intelligence, click the
                            button below. For more information on what constitutes plagiarism, view the&nbsp;
                          </span>
                          <a
                            href="https://apstudents.collegeboard.org/art-design-program/ap-art-design-portfolio-policies"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            AP Art and Design Artistic Integrity Agreement.
                          </a>
                        </>
                      )}
                    </div>
                    <div
                      className={`add-top-margin-3x add-bottom-margin-1x ${
                        hideButtonsForResAndSemSpecificAssignments ? 'hidden' : ''
                      }`}
                    >
                      <button
                        type="button"
                        className="cb-btn cb-btn-sm cb-btn-yellow btn-sm btn-primary js-report-plagiarism"
                        onClick={openPlagiarismModal}
                        disabled={plagDisabled || helpers.isDraftIn}
                      >
                        {plagText}
                      </button>
                      <PlagiarismModalComp
                        {...plagiarismModalProps}
                        open={showPlagiarismModal && deleted !== true}
                        onClose={() => setShowPlagiarismModal(false)}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </form>
        </Tab>
      </Tabs>
    </>
  )
}

export default RubricLayout
