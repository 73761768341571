import React from 'react';
import {getCurrentPerson} from '../../entities/person.jsx'

export default class EmptyComp extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let parms = this.props.parms
        let helpers = this.templateHelpers(parms)
        const textA = helpers.isCoordinator ? 'your school' : 'you'

        return (
            <div className='col-sm-12 add-top-margin-4x' style={{padding: 0}}>
                {parms.hasArchivedCourses &&
                    <div>
                        <h4 className="col-sm-12 bordered-bottom-gray" style={{padding: 0}}>
                            We don't see any {parms.academicYear} classes for {textA} in our system.
                        </h4>
                        {helpers.isCoordinator &&
                            <p>You can view <a href="/instruction/archive"><strong><u>archived classes</u></strong></a>.</p>
                        }
                        {helpers.isTeacher &&
                            <div>
                                <p>Please work with your AP coordinator to set up your class sections.</p>
                                <p>You can also view <a href="/instruction/archive"><strong><u>archived classes</u></strong></a>.</p>
                            </div>
                        }
                        {helpers.isStudent &&
                            <div>
                                <p>Go to <a href="https://MyAP.collegeboard.org">MY AP</a> to enroll in a class section.</p>
                                <p>You can also view <a href="/instruction/archive"><strong><u>archived classes</u></strong></a>.</p>
                            </div>
                        }
                    </div>
                }
                {!parms.hasArchivedCourses &&
                    <div>
                        <h4 className="col-sm-12 bordered-bottom-gray">We don't see any classes for {textA} in our system.</h4>
                        {helpers.isTeacher &&
                            <p>Please work with your AP coordinator to set up your class sections.</p>
                        }
                        {helpers.isStudent &&
                            <p>Go to <a href="https://MyAP.collegeboard.org">MY AP</a> to enroll in a class section.</p>
                        }
                    </div>
                }
            </div>
        )
    }

    templateHelpers(parms) {
        var person = getCurrentPerson();
        return {
            isStudent: person.isStudent(),
            isTeacher: person.isTeacher(),
            isCoordinator: person.isCoordinator(),
            hasArchivedCourses: parms.hasArchivedCourses,
            academicYear: parms.academicYear
        }
    }
}
