import { Input, Modal, NakedButton, Select, YellowButton } from "@cb/apricot-react"
import { useNavigate } from "react-router-dom"
import { bsliPut } from "../../_core/util/request"
import { AlertComp } from "../../_core/views/alert"

// formerly Assignment.UploadModal
export default function ReturnFileModalComp(props) {
  const navigate = useNavigate()
  const [alertProps, setAlertProps] = useState(null)
  const [open, setOpen] = useState(false)
  const [inputProperties, setInputProperties] = useState({ disableReturnButton: true, disableOtherReasonInput: true })
  const [finalReason, setFinalReason] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  const Footer = (
    <>
      <NakedButton type="button" className="cb-btn cb-btn-naked cb-no-padding cb-btn-close" data-cb-modal-close>
        Cancel
      </NakedButton>
      <YellowButton type="button" className="cb-btn cb-btn-sm btn-sm js-return" onClick={async e => await returnFile(e)} disabled={inputProperties.disableReturnButton} loading={loading}>
        Return File
      </YellowButton>
    </>
  )

  const returnFileFailed = () => {
    setAlertProps({
      showAlert: true,
      message: "Error: An error occurred returning this file. Please try again.",
      uniqueId: "download-submission"
    })
  }

  const returnFile = async (e) => {
    try {
      e.stopPropagation()
      setLoading(true)
      const rejectionReason = !inputProperties.disableOtherReasonInput ? 'Other' : finalReason
      const rejectionOtherText = !inputProperties.disableOtherReasonInput ? finalReason : ''

      const url = props.upload.url()
      const data = { rejectionReason, rejectionOtherText }
      await bsliPut(url, data)
      setLoading(false)
      navigate("..", { relative: "path" })
    } catch (e) {
      returnFileFailed()
    }
  }

  const validateSelect = (value) => {
    const isOtherReason = (value === "Other")

    if (!isOtherReason) {
      setInputProperties(prevState => ({ ...prevState, disableOtherReasonInput: true, disableReturnButton: false }))
      setFinalReason(value)
    } else {
      setInputProperties(prevState => ({ ...prevState, disableOtherReasonInput: false, disableReturnButton: true }))
    }
  }

  const validateInput = value => {
    if (!inputProperties.disableOtherReasonInput) {
      if (value.target.value) {
        setFinalReason(value.target.value)
        setInputProperties({
          disableReturnButton: false,
          disableOtherReasonInput: false
        })
      } else {
        setInputProperties({
          disableReturnButton: true,
          disableOtherReasonInput: false
        })
      }
    }
  }

  const selectOptions = [
    { label: "Wrong File Submitted", value: "WrongFile" },
    { label: "Corrupt file submitted", value: "CorruptFile" },
    { label: "Technical Error", value: "TechnicalError" },
    { label: "Other", value: "Other" },
  ]

  return (
    <Modal
      title="Return File to Student"
      open={open}
      escClose={false}
      withHeader
      withFooter
      modalId={props.modalId}
      footer={Footer}
      onClose={() => {
        props.onClose()
      }}
    >
      <>
        {alertProps && alertProps.showAlert &&
          <div id='alert-container'>
            <AlertComp {...alertProps} />
          </div>
        }
        <p>Please select the reason for sending this submission back to the student. Students will see the reason you
          select, but please remind them in-person to address the issue and resubmit.</p>
        <div className="cb-select form-group">
          <Select
            label="Reason For Sending Back:"
            labelNoValue="Select Reason"
            values={selectOptions}
            onChange={validateSelect}
          />
        </div>
        <div className="cb-input form-group">
          <Input
              label="If other, please enter a reason:"
              name="otherReasonInput"
              floating
              disabled={inputProperties.disableOtherReasonInput}
              onChange={validateInput}
          />
        </div>
      </>
    </Modal>
  )
}
