import React, { Fragment, useState }                  from 'react';
import StudentStatusForTeacherComp          from './studentStatusForTeacher'
import NoStudentsComp                       from './noStudents'
import CMSDocsComp                          from '../../supportingmaterial/views/cmsDocs'
import { SupportingMaterialLayoutComp }     from '../../supportingmaterial/views/supportingMaterialLayout'
import ImageGalleryTeacher              from "../../assignment/art/views/galleryTeacher";
import {  isArt, isArt3D, isScorableAssignment }				    from '../../entities/courseinstance'

const TeacherLayoutComp = (props) => {

    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const [sectionId, setSectionId] = useState();
    const [assignmentId, setAssignmentId] = useState();
    const [uploadId, setUploadId] = useState();
    const [message, setMessage] = useState();
    const [statetitle, setStatetitle] = useState();

    let studentsForAssignment = assignment => {
        if (props.parms.students.length === 0) {
            return <NoStudentsComp isTeamLesson={isTeamLesson} key={'no_students'} />
        } else {
            // statuses is an array of AssignmentStatus
            return props.parms.students.statuses.map((status, index) => {
                let parms = {
                    status: status,
                    assignment: assignment,
                    index: index,
                    showGallery: (sectionId, assignmentId, uploadId, message, title) => {
                        setIsGalleryOpen(true);
                        setSectionId(sectionId);
                        setAssignmentId(assignmentId)
                        setUploadId(uploadId)
                        setMessage(message)
                        setStatetitle(title);
                    },
                    title: title
                  }
                let key = `student_status_${assignment.id}_${status.id}`;
                
                return (
                    <StudentStatusForTeacherComp parms={parms} key={key} />
                )
            })
        }
    }

    let assignments = () => {
        return props.parms.assignments.map((assignment, index) => {
            return (
                <Fragment key={`assignment_${index}`}>
                <div className="row" key={`assignment_${assignment.assignmentAbbreviation}`}>
                    <div className="offset-md-4 col-md-8 offset-sm-4 col-sm-8">
                        <h5 className="remove-bottom-margin">Student Submissions</h5>
                    </div>
                </div>
                {studentsForAssignment(assignment)}
                </Fragment>
           )
        })
    }

    const activityFeed  = ( lesson, isArt, title, concatAbbreviation, abbreviation ) => {
        const isScorableAssignmentFlag = isScorableAssignment(props.parms.assignments[0].id, props.parms.assignments[0].assignmentAbbreviation)

        return (<Fragment>
            <h3 className="h1" tabIndex='-1'>{titleAdj}</h3>
            <div className="alert-wrapper"></div>
            {props.parms.lessonInfo &&
                <div id="region-lesson"
                    dangerouslySetInnerHTML={{ __html: props.parms.lessonInfo }} >
                </div>
            }
            <div id="region-cmsmaterial">
                <CMSDocsComp parms={props.parms.cmsData}/>
            </div>
            <div id="region-supportingmaterial">
                <SupportingMaterialLayoutComp
                    supportingMaterialData={props.parms.supportingMaterialData}
                    lessonId={lesson.id} moduleId={0}
                    moduleHolder={props.parms.moduleHolder}
                    publishMaterial={() => { console.error('StudentLayoutComp should not publish') }} />
            </div>
            <hr className='hr-margin' />
            {!isScorableAssignmentFlag && <div id="region-assignments">
                {assignments()}
            </div>}
        </Fragment>)
     }

    const showGallery = (sectionId, assignmentId, uploadId, message, title) => {
        let isArt_SI_Images = props.parms.assignments[0].isArt_SI_Images;
        let is3DArt = isArt3D();
        let elem = <div>
            <header id="region-header" className="cb-padding-bottom-24">
                <div className="cb-blue1-bg ">
                    <div className="container-fluid">
                        <div className="row" style={{ paddingTop: '10px', paddingBottom: '10px', paddingRight: '0px', paddingLeft: '0px' }}>
                            <div className="col-md-10 col-sm-10 col-xs-10">
                                <h5 className="add-vertical-space-halfx"><span><strong>{title ? title : 'Images'}</strong> | {message.firstName} {message.lastName} </span></h5>
                            </div>
                            <div className="col-md-2 col-sm-2 col-xs-2"><button type="button"
                                className="cb-btn cb-btn-square cb-btn-sm cb-btn-floating cb-float-right" aria-label="Exit Gallery" onClick={() => { setIsGalleryOpen(false) }}>
                                <span className="cb-glyph cb-x-mark cb-black1-color" aria-hidden="true"></span>
                                <span className="sr-only">Exit Gallery</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <ImageGalleryTeacher
                galleryId="galleryTeacher"
                sectionId={sectionId}
                assignmentId={assignmentId}
                uploadId={uploadId}
                isArt_SI_Images={isArt_SI_Images}
                is3DArt={is3DArt}
                title={title}
                isActivityPage
            />
            <footer>
                <div className="cb-btn-row">
                    <button type="button" onClick={() => { setIsGalleryOpen(false) }}
                        className="cb-btn  cb-btn-card-primary cb-btn-floating cb-float-right" data-cb-modal-close="true" aria-label="Close Gallery">Close</button>
                </div>
            </footer>
        </div>
        return elem
    }

    let lesson = props.parms.lesson
    let	art = isArt()
    let { title, concatAbbreviation, abbreviation, isTeamLesson } = lesson
    let titleAdj = art ? title : concatAbbreviation ? `${title} (${abbreviation})` : title


    if (isGalleryOpen) {
        window.scroll(0, 100)
        return showGallery(sectionId, assignmentId, uploadId, message, statetitle)
    } else {
        return activityFeed(lesson, isArt, title, concatAbbreviation, abbreviation)
    }
}

export default TeacherLayoutComp;
