import React, { Fragment, useEffect, useRef }       from 'react';
import { Upload }                           from '../../assignment/entities/upload'
import DPToolTip                            from '../../utility/tooltip'
import {bsliGet}                            from '../../_core/util/request'
import { getCurrentPerson }                 from '../../entities/person.jsx'
import Name                                 from './name.jsx'

export function MessageComp({message, assignment, toolKey, hasFocus }) {
  let toolTip = new DPToolTip(toolKey)
  const nodeRef = useRef();

  useEffect(() => {
    toolTip.createTooltips()
    if (hasFocus) {
      nodeRef.current.focus();
    }
  })
  //console.log('useEffect initializing')
  // fileSelectedCallback(isFileDefined())


  let helpers = serializeData()
  toolTip.removeTooltips()
  //console.log('MessageComp helpers', helpers)
  //dynamic-asset-link-track={helpers.upload.name} TODO
  return (
    <div tabIndex='-1' className='media' ref={nodeRef}>
      <Fragment>
        <p>
          <Name firstName={helpers.firstName} lastName={helpers.lastName} prefFirstName={helpers.prefFirstName} /> |
          <span className="text-muted">{helpers.formattedTimestamp}</span>
        </p>

        <p>
          {helpers.message}
          {helpers.upload &&
            <button data-cb-title={'Download ' + helpers.upload.name}
              className='cb-btn cb-btn-sm cb-btn-naked cb-btn-blue js-download-file'
              data-cb-apricot='tooltip' id={toolTip.addToolTip()}
              onClick={downloadSubmission}>
              {helpers.upload.name}
            </button>
          }
          {helpers.rejectionReason &&
            <span style={{ display: 'block' }}>Reason: {helpers.rejectionReason}</span>
          }
        </p>
      </Fragment>
    </div>
  )


  async function downloadSubmission() {
    let helpers = serializeData()
    let url = helpers.upload.url
    //let fileName = helpers.upload.name
    await bsliGet(url).then(url => window.open(url.url, '_blank'))
    $('.active.active-trail').focus()
  }

  function serializeData() {
    // message has firstName, lastName, message, rejectionReason, timestamp, type, upload
    // upload has id, fileName, submittedToTurnitin
    let uploadObj
    if (message.upload) {
      uploadObj = new Upload()
      uploadObj.initFromAssignmentId(message.upload, assignment.id)
    }

    return _.extend({}, message, {
      upload: uploadObj && { name: uploadObj.fileName, url: uploadObj.getDownloadUrl() },
      formattedTimestamp: moment(message.timestamp).format('ddd MMMM DD, YYYY [at] h:mmA')
    });
  }
}
