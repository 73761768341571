import { CBTooltip } from "@cb/apricot";

export default class DPToolTip {
    constructor(tag) {
        this.tag = tag || 'tooltip_'
        if (this.tag.includes('undefined')) {
            console.error('BAD TAG ' + this.tag)
        }
        this.tag = this.tag.replace(/\s/g, '');
        this.toolTips = [] // will contain objects with properties id and CBTooltip instance
    }
    // the tooltips created by CBTooltip have text equal to the title attribute
    createTooltips() {
        //console.log('TOOLTIP creating tooltips', this.toolTips)
        _.each(this.toolTips, tooltip => {
            try {
                let parms = _.extend({ markup: true, style: 'dp-tooltip' }, tooltip.parms)
                if (tooltip.instance) {
                 //   console.log('TOOLTIP already have an instance for ' + tooltip.id)
                } else {
                    let container = parms.elem ? parms.elem : document.getElementById(tooltip.id)
                    if (container) {
                        tooltip.instance = CBTooltip({
                            elem: parms.elem
                        })
                        // on the first show, its not placed correctly
                        // so we do a quick show/hide sequence that the user won't notice
                        // they did notice
                        /*
                        try {
                            tooltip.instance.show()
                            // there was a case when the hide failed
                            setTimeout(() => {
                                try {
                                    if (tooltip.instance) {
                                        tooltip.instance.hide()
                                    }    
                                } catch (e) {
                                    console.error('TOOLTIP hide after delay failed ', e)
                                }
                            },5)    
                        } catch(e) {
                            console.error('tooltip had a problem',e)
                        }*/
                    } else {
                        console.error('TOOLTIP createTooltips failed on id=' + tooltip.id)
                    }
                }
            } catch (e) {
                console.error('TOOLTIP CBTooltip failed ', e)
            }
        })
    }

    removeTooltips() {
        //console.log('TOOLTIP disposing tooltips', this.toolTips)
        _.each(this.toolTips, tooltip => {
            try {
                if (tooltip.instance) {
                    //console.log('TOOLTIP dispose ', tooltip.instance)
                    //tooltip.instance.hide() dispose does this
                    tooltip.instance.destroy()
                }
            } catch (e) {
             //   console.log('ERROR removeTooltips',e)
             //   console.log('ERROR tooltip instance:',tooltip.instance)
            }
        })
        this.toolTips = []
    }

    // hideTooltips() {
    //     _.each(this.toolTips, tooltip => {
    //         if (tooltip.instance) {
    //             tooltip.instance.hide()
    //         }
    //      })
    // }

    addToolTip(parms = {}, newId = '') {
        // several tooltips add position in parms
        //console.log('TOOLTIP adding tooltip to', this.toolTips)
        // if (parms) {
        //     console.log('TOOLTIP with parms', parms)
        // }
        if (newId !== '') {
            this.toolTips.push({ id: newId, instance: null, parms })
            return newId
        } else {
            let toolTipId = this.tag + '_' + (this.toolTips.length + 1)
            this.toolTips.push({ id: toolTipId, instance: null, parms })
            return toolTipId
        }
    }
}
export function cleanDeadTooltips() {
    $('.dp-tooltip').each(function(index) {
        let $this = $(this)
        let id = $this.attr('id')
        if ($('button[aria-controls=' + id + ']').length===0 && $('a[aria-controls=' + id + ']').length===0) {
         //   console.log('ERROR TOOLTIP hid a dead tooltip with id ' + id)
            $this.addClass('hidden')
        }
    })
}
