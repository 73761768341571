import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import TimeoutComp from "../../main/views/timeout";
import { AdminMenuComp } from "./adminMenu";
import { bsliGet } from "../../_core/util/request";
import { Spinner } from "@cb/apricot-react";
import DeniedComp from "../../reviewer/views/denied";

export let savedGetRequests = _.memoize(function (url) {
  return bsliGet(url)
})

const AdminLayout = ({ role }) => {
  const [reports, setReports] = useState(null)
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const isRestrictedPage = location.pathname === '/manage-courses' || location.pathname.includes('manage-courses') || location.pathname === '/manage-extensions' || location.pathname === '/teacher-scoring-provisioning'

  useEffect(() => {
    getReports()
    setLoading(false)
  }, [])

  const getReports = async () => {
    let reportTypes = {};
    try {
      let reportJson = await savedGetRequests('reports');
      reportJson.forEach(obj => {
        reportTypes[String(obj.id)] = obj.name
      })
      setReports(reportTypes)
    } catch(e) {
      console.error('showAdminNavigation could not get the report types')
    }
  }

  if (loading) return <Spinner />

   if (!role.isAdmin() && !role.isSystemAdmin() && isRestrictedPage) return <DeniedComp />

  return (
    <>
      <a href="#" className="sr-only wi-globalheader-skip">Skip to main content</a>
      <AdminMenuComp reportTypes={reports} />
      <TimeoutComp />
      <div id="region-main-content" className="container">
        <Outlet />
      </div>
    </>
  )
}

export default AdminLayout
