import { Checkbox, Spinner, YellowButton } from "@cb/apricot-react"
import { getCurrentPerson } from "../../entities/person.jsx"
import { cmsTerms } from "../../content/static_content"
import { CBForm } from "@cb/apricot"

const TermView = ({ acceptedCallback }) => {
  const [accepted, setAccepted] = useState(false)
  const [cmsText, setCMS] = useState("")
  const [loading, setLoading] = useState(true)
  document.title = "Terms and Conditions | The College Board"
  let role = getCurrentPerson().isStudent() ? "student" : "teacher"

  const textCallback = (html) => setCMS(html)
  const onChange = (checked) => setAccepted(checked)
  const onAgree = () => acceptedCallback()

  useEffect(() => {
    cmsTerms()
      .then(res => {
        setLoading(false)
        textCallback(res[role])
      })
  }, [])

  useEffect(() => {
    document.querySelectorAll(".cb-checkbox").forEach((elem) => {
      CBForm.customFormElement({
        elem: elem.querySelector("input")
      })
    })
    if (cmsText) {
      document.querySelectorAll('h2').forEach(elem => {
        elem.classList.add('cb-h5')
        elem.classList.add('cb-spacerv-top-16')
        elem.classList.add('cb-spacerv-bottom-24')
      })
    }
  }, [cmsText])

  if (loading) return <Spinner />

  if (cmsText) return (
    <div className="container">
      <h1 className="cb-h4 cb-spacerv-bottom-24">Terms and Conditions</h1>
      <div id="region-terms-content">
        <span dangerouslySetInnerHTML={{ __html: cmsText }} />
      </div>
      <div id="region-terms-form" style={{ marginTop: "30px", marginBottom: "50px" }}>
        <form role="form">
          <span style={{ float: "left" }}>
              <Checkbox id="accept-checkbox" label="I agree to the applicable Terms and Conditions" onChange={checked => onChange(checked)} />
          </span>
          <span style={{ marginLeft: "80px" }}>
            <YellowButton onClick={onAgree} disabled={!accepted} small>
                Agree
            </YellowButton>
          </span>
        </form>
      </div>
    </div>
  )
}

export default TermView