import { bsliGet } from '../../_core/util/request'

//GET: review/plagiarism/{apNumber}
//POST : review/plagiarism/{apNumber}/assignment/{assignmentId}
//POST: review/plagiarism/{apNumber}/assignment/{assignmentId}/appeal

var urlBase = 'review/plagiarism'

// this used to be called by reviewer results
// now this url is accessed by reviewer results in updateStudentHasAppealed and the id is called sisid
// export function getPlagiarizedDecision(scorableId) {
//     return bsliGet(urlBase + '/scorable/' + scorableId)
// }

export function searchForStudent(apNumber) {
    return bsliGet(urlBase + '/' + apNumber)
}

export function getPlagiarizedLesson(apNumber, assignment, isViewSubmission) {
    return bsliGet(`${urlBase}/${apNumber}/assignment/${assignment}${isViewSubmission ? '/viewsubmission' : ''}`)
}

function lessonOptionsFromCourse(course) {
    let lessonOptions = []
    _.each(course.lessons, lesson => {
        lessonOptions.push({ label: lesson.title, value: lesson.id })
    })
    return lessonOptions
}

function coursesFromJson(json) {
    let courseOptions = []

    let courses = _.map(json, (course, index) => {
        let courseId = index + 1
        courseOptions.push({ label: course.title, value: courseId })

        let lessonOptions = lessonOptionsFromCourse(course)

        return { courseId: courseId, lessonOptions: lessonOptions }
    })

    return {
        courses: courses,
        courseOptions: courseOptions
    }
}

export function jsonToStudent(json, apNumber) {
    let data = coursesFromJson(json)
    return {
        apNumber: apNumber,
        courseOptions: data.courseOptions,
        lessonOptions: [],
        courses: data.courses,
        selectedCourse: '',
        selectedLesson: '',
        error: null
    }
}

export function showPlagiarismSearchResults(searchCriteria) {
    //console.log('showPlagiarismSearchResults is not written')
    
    let func = co.wrap(function* (searchCriteria) {
        // var { apNumber, lessonsSelected, lessons, courses, coursesSelected } = searchCriteria
        let results = getPlagiarizedLesson(searchCriteria.apNumber, searchCriteria.lessonsSelected)
        results
        .then(res => {
            res
        })
        .catch( response => {
                //console.log('plagiarism fetch failed status=' + response.status + ', message=' + response.responseText)
                return {}
        })
    })
}


