import React from 'react'

import { YellowButton } from '@cb/apricot-react'
import { cleanUpBody } from '../../_core/views/modal.jsx'
import { canEdit } from '../../entities/courseinstance'
import { deepClone } from '../../utility/util'
import { addEnrollment, createTeam, deleteEnrollment, deleteTeam, saveTeam } from '../teamAjax'
import { TeamList } from './teamList'
import { StudentToAssign } from './studentToAssign'
import { NewTeam } from './newTeam'
import * as drag from './dragging'
import CBModal from '@cb/apricot/CBModal'

export class ManageTeamsLayoutComp extends React.Component {
    constructor(props) {
        //props has teams, enrollments, modules, selectedModuleId, sectionId };
        //console.log('ManageTeamsLayoutComp props',props)
        super(props);
        let teams               = props.parms.teams;
        this.enrollments        = props.parms.enrollments;
        this.modules            = props.parms.modules;
        this.sectionId          = props.parms.sectionId
        let selectedModuleId    = props.parms.selectedModuleId;
        let selectedModule = this.modules.find(m => m.id === selectedModuleId);

        this.state = {
            selectedModuleId:       selectedModuleId,
            selectedModule:         selectedModule,
            teams:                  teams,
            flag:                   false,
            selectedTeamId:         0,
            selectedTeamTitle:      "",
            showAlert:              false,
            alertMsg:               "",
            alertSection:           "",
            dragName:               null,
            focus:                  false
        };

        _.bindAll(this, 'setFocusOnTeam', 'initDelete',
            'clearTeamSelection', 'showAlert', 'findTeam', 'editTeam', 'saveTeam', 'addEnrollmentToTeam', 'createTeam',
            'deleteEnrollmentFromTeam', 'addEnrollmentOnDrop', 'onTeamChange')
    }

    componentDidUpdate() {
        if (this.state.focus && this.state.focus.id) {
            let elem = document.getElementById(this.state.focus.id)
            if (elem) {
                //console.log('setting focus  on team ' + elem.id)
                elem.focus()
            }
        }
    }

    render() {
        let canEditSection = canEdit(this.sectionId)
        let availableEnrollments = this.availableEnrollments(canEditSection)
        let unAttachedEnrollments = this.unAttachedEnrollments(canEditSection)
        //console.log('canEditSection=' + canEditSection)
        return (
            <div id="manageTeams" aria-labelledby='pageTitle'>
                <h1 id='pageTitle' style={{ letterSpacing: 0 }} className="cb-h4 cb-spacerv-bottom-16 cb-no-padding-left" >
                    Manage Teams: {this.state.selectedModule?.title}
                </h1>
                <div className="row">
                    <div className="col-md-9 col-sm-8 cb-no-padding-left">
                        <h2 className="cb-h5 cb-spacerv-bottom-16" style={{ letterSpacing: 0 }} >
                            Current Teams
                        </h2>
                        {this.showAlert("teams")}
                        {availableEnrollments && availableEnrollments.length > 0 &&
                            <NewTeam
                                createTeam={this.createTeam}
                                dragName={this.state.dragName}
                            />
                        }
                        <TeamList
                            teams={this.state.teams}
                            dragName={this.state.dragName} canEditSection={canEditSection}
                            addEnrollmentOnDrop={this.addEnrollmentOnDrop}
                            onTeamChange={this.onTeamChange}
                            initDelete={this.initDelete}
                            deleteEnrollmentFromTeam={this.deleteEnrollmentFromTeam}
                            selectedTeamId={this.state.selectedTeamId}
                            selectedTeamTitle={this.state.selectedTeamTitle}
                            clearTeamSelection={this.clearTeamSelection}
                            saveTeam={this.saveTeam} editTeam={this.editTeam}
                            showAlert={this.showAlert} hasAlert={!!this.state.showAlert} focus={this.state.focus}
                        />
                    </div>
                    {unAttachedEnrollments && unAttachedEnrollments.length > 0 &&
                        <div className="col-md-3 col-sm-4"> <h2 className="cb-h5 cb-spacerv-bottom-16" style={{ letterSpacing: 0 }} >Students</h2>
                            <StudentToAssign
                                teams={this.state.teams} unAttachedEnrollments={unAttachedEnrollments}
                                canEditSection={canEditSection}
                                setDragName={name => { this.setState({ dragName: name }) }}
                                addEnrollmentToTeam={this.addEnrollmentToTeam} createTeam={this.createTeam}
                                clearTeamSelection={this.clearTeamSelection}
                            />
                        </div>
                    }
                </div>
                {this.modalConfirmMessage(this.state.selectedTeamId, this.state.selectedTeamTitle)}
            </div>
        )
    }

    // an enrollment passes the date restriction if canEditSection is true or enrollment/student has an extension
    availableEnrollments(canEditSection) {
        if (!this.state.teams || this.state.teams.length === 0) {
            return this.enrollments;
        } else {
            // get list of all the person ids that are in a team
            let teamPersonids = this.state.teams.map(team => team.enrollment)
            .reduce((pre, cur) => pre.concat(cur), [])
            .map(enrollment => enrollment.personId)

            // return a list of all the enrollmnents that are not in a team and pass date restrictions
            return this.enrollments.filter(
                enrollment => {
                    let passDateRestriction = canEditSection || enrollment.hasExtension
                    let isNotInTeam = teamPersonids.indexOf(enrollment.personId) < 0
                    //console.log('availableEnrollments name=' + commaName(enrollment) + ', isNotInTeam=' + isNotInTeam + ', passDateRestriction=' + passDateRestriction)
                    return passDateRestriction && isNotInTeam
                }
            )
        }
    }

    // has all students/enrollments in 'availableEnrollments' plus all the other enrollments that are not in a team
    unAttachedEnrollments(canEditSection) {
        let cloneEnrollments = deepClone(this.enrollments)
        let teamPersonids = []
        if (this.state.teams && this.state.teams.length > 0) {
            // get list of all the person ids that are in a team
            teamPersonids = this.state.teams.map(team => team.enrollment)
                .reduce((pre, cur) => pre.concat(cur), [])
                .map(enrollment => enrollment.personId)
        }

        // return a list of all the enrollmnents that are not in a team and pass date restrictions
        return cloneEnrollments.filter(
            enrollment => {
                let passDateRestriction = canEditSection || enrollment.hasExtension
                let isNotInTeam = teamPersonids.indexOf(enrollment.personId) < 0
                if (isNotInTeam) {
                    enrollment.canDrag = passDateRestriction
                    return true
                } else {
                    return false
                }
            }
        )
    }

    async createTeam(enrollmentId) {
        this.clearTeamSelection();
        drag.removeDecoration();
        this.clearAlert();
        let selectedEnrollment = this.findEnrollment(enrollmentId);
        let title = this.generateTitle();
        try {
            let newTeam = await createTeam(selectedEnrollment, this.state.selectedModuleId, this.sectionId, title)
            this.setState(prevState => {
                let newTeams = _.clone(prevState.teams)
                newTeams.unshift(newTeam)
                return {
                    teams: newTeams
                }
            })
            this.setFocusOnTeam('team', newTeam.id)
        }
        catch (exception) {
            this.setState({ showAlert: true, alertMsg: "Error happened. Please try again.", alertSection: "teams" })
        }
    }

    // enrollment is already one of the 'available ones', so  just find the enrollment in the list of all of them
    findEnrollment(enrollmentId) {
        return this.enrollments.find(enrollment => enrollment.id === enrollmentId);
    }

    generateTitle() {
        let teams = this.state.teams;
        const regex = /^Team\s\d+$/;
        if (!(teams && teams.length > 0)) {
            return "Team 1";
        } else {
            let titles = teams.map(t => t.title).filter((ttl) => {
                return regex.test(ttl);
            });
            if (!titles || titles.length === 0) {
                return "Team 1"
            } else {
                let nums = titles.map((t) => {
                    let terms = t.split(" ");
                    return Number(terms[1]) === NaN ? 0 : Number(terms[1]);
                }).sort((a, b) => (b - a));
                let lastNum = nums[0]
                return "Team " + (lastNum + 1)
            }
        }
    }

    async addEnrollmentOnDrop(ev, teamId) {
        ev.preventDefault();
        drag.removeDecoration();
        this.clearAlert();
        this.clearTeamSelection();
        let data = ev.dataTransfer.getData("text");
        let enrollmentId = Number(data)
        await this.addEnrollmentToTeam(enrollmentId, teamId);
    }

    async addEnrollmentToTeam(enrollmentId, teamId) {
        let enrollment = this.findEnrollment(enrollmentId);
        this.clearAlert();
        try {
            await addEnrollment(enrollment, teamId)
            let team = this.findTeam(teamId);
            team.enrollment.push(enrollment);
            this.setFocusOnTeam('edit', teamId);
        } catch (exception) {
             this.setState({ showAlert: true, alertMsg: "Error happened. Please try again.", alertSection: "teams" })
        }
    }

    async deleteEnrollmentFromTeam(teamId, enrollmentId) {
        this.clearTeamSelection();
        this.clearAlert();

        try {
            await deleteEnrollment(teamId, enrollmentId)
        } catch (exception) {
            this.setState({ showAlert: true, alertMsg: "Error happened. Please try again.", alertSection: "teams" })
            return;
        }

        let team = this.findTeam(teamId);
        let enrollment = team.enrollment;
        team.enrollment = enrollment.filter((e) => e.id !== enrollmentId);

        if (team.enrollment.length === 0) {
            let teams = _.clone(this.state.teams)
            let reducedTeams = teams.filter(t => t.id != teamId);
            this.setState({ teams: reducedTeams, focus: false });
            //this.setFocus('manageTeams')
        } else {
            this.setFocusOnTeam('team', teamId)
            this.bounceState();
        }
    }

    onTeamChange(e) {
        this.setState({ selectedTeamTitle: e.target.value })
        this.clearAlert();
    }

    initDelete(teamId, title) {
        this.setState({ selectedTeamId: teamId, selectedTeamTitle: title });
        this.clearAlert();
        this.showModal("confirmModal");
    }

    async deleteTeam(teamId) {
        // this call always fails with a parse error, even when it returns a '200'
        // I suspect its because the return is not a proper object
        const res = await deleteTeam(teamId)
        this.dissmissModal("confirmModal")
        this.clearTeamSelection()
        if (res.message) {
            let teams = _.clone(this.state.teams)
            let reducedTeams = teams.filter(t => t.id !== teamId);
            this.setState({ teams: reducedTeams, focus: false });
        } else {
            this.setState({ showAlert: true, alertMsg: res.responseText, alertSection: "teams" })
        }
    }

    editTeam(teamId) {
        let team = this.findTeam(teamId)
        this.setState({ selectedTeamId: teamId, selectedTeamTitle: team ? team.title : "" });
        this.setFocusOnTeam('title', teamId)
    }

    async saveTeam(teamId) {
        this.clearAlert();
        let team = this.findTeam(teamId);
        let oldTitle = team.title
        let title = this.state.selectedTeamTitle;
        //console.log('title =' + title + ', oldTitle=' + oldTitle)
        if (title === oldTitle) {
            this.setFocusOnTeam('edit', teamId);
            return;
        }
        if (title && !this.titleExists(title, teamId)) {
            team.title = title;
            try {
                await saveTeam(team);
                this.setFocusOnTeam('edit', teamId);
                this.clearTeamSelection();
            }
            catch (exception) {
                this.setState({ showAlert: true, alertMsg: "Error happened. Please try again.", alertSection: "teams" })
                this.setFocusOnTeam('title', teamId);
            }
        } else {
            //console.log('setting alert on team ' + teamId)
            if (!title || title.trim() === "") {
                this.setState({ showAlert: true, alertMsg: "Error: Team name is blank.", alertSection: "team" + teamId });
            } else {
                this.setState({ showAlert: true, alertMsg: " Error: Team name is not unique.", alertSection: "team" + teamId });
            }
            this.setFocusOnTeam('title', teamId);
        }
    }

    findTeam(teamId) {
        let teams = this.state.teams;
        if (!(teams && teams.length > 0)) {
            return null
        }

        if (typeof teamId === "string") {
            teamId = Number(teamId);
        }

        return teams.find(t => t.id === teamId);
    }

    titleExists(title, titleId) {
        let teams = this.state.teams;
        if (!teams || teams.length < 2) {
            return false;
        }
        let titles = teams.filter(t => t.id != titleId).map(t => t.title)
        if (titles.indexOf(title) === -1) {
            return false
        } else {
            return true;
        }
    }

    modalConfirmMessage(teamId, title) {
        let message = "Are you sure you would like to delete \"" + title + "\"? This action cannot be undone. "
        return (
            <div className="cb-modal" id="confirmModal" aria-hidden="true" data-cb-apricot="modal" tabIndex='-1'>
                <div className="cb-modal-overlay" data-cb-modal-close tabIndex='-1'>
                    <div className="cb-modal-container" role="dialog"
                        aria-modal="true" aria-labelledby="modalTitle" aria-describedby='modalContent'>
                        <div className="cb-modal-header">
                            <h2 className="cb-modal-title" id="modalTitle">Delete Team</h2>
                        </div>
                        <div id="modalContent" className="cb-modal-content">
                            {message}
                        </div>
                        <div className="cb-modal-footer">
                            <div className="cb-btn-row">
                                <button type="button" data-cb-modal-close
                                    className="cb-btn cb-btn-naked cb-no-padding"
                                    onClick={() => this.clearTeamSelection(teamId)}>
                                    No
                                    <span className='sr-only'>, click this button to cancel team deletion</span>
                                </button>
                                <YellowButton onClick={(e) => { this.deleteTeam(teamId) }} small>
                                    Yes
                                    <span className='sr-only'>, click this button to delete team</span>
                                </YellowButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    showAlert(section) {
        //console.log('showAlert ' + section,this.state)
        if (this.state.showAlert && this.state.alertSection === section) {
            return (
                <div id='alertMsg' className='alert alert-danger' aria-live='polite'>
                    <span>{this.state.alertMsg}</span>
                </div>
            )
        }
    }

    clearAlert() {
        this.setState({ showAlert: false, alertMsg: "", alertSection: "" });
    }

    showModal(target) {
        CBModal({
            elem: target
        }).show()
        document.getElementById('confirmModal').focus()
    }

    dissmissModal(target) {
        CBModal({
            elem: target
        }).close()
        cleanUpBody()
    }

    clearTeamSelection(teamId) {
        this.setState({ selectedTeamId: false, selectedTeamTitle: "" })
        this.setFocusOnTeam('edit', teamId)
    }

    setFocusOnTeam(teamPart, teamId) {
        if (teamPart && teamId) {
            //console.log('setting focus on ' + teamPart + ', ' + teamId)
            this.setState({
                focus: { id: teamPart + teamId }
            })
        } else {
            this.setState({ focus: false })
        }
    }

    bounceState() {
        this.setState({ flag: !this.state.flag });
    }
}

