import { getCourseInstanceId, getCourseParameter } from '../../entities/courseinstance'
import { StepOneContent } from './plagiarismStep1'
import { StepTwoContent } from './plagiarismStep2'
import { addBaseUrl } from '../../_core/util/URL.jsx'
import { fetchRequest } from '../../_core/util/request'
import { Modal, NakedButton, YellowButton } from '@cb/apricot-react'
import CBForm from '@cb/apricot/CBForm'

const PlagiarismModalComp = props => {
  const [step, setStep] = useState(1)
  const [isSaving, setIsSaving] = useState(false)
  const [nextEnable, setNextEnable] = useState(false)
  const [submitEnable, setSubmitEnable] = useState(false)
  const [rationaleIds, setRationaleIds] = useState([])
  const [stageTwoState, setStageTwoState] = useState({
    evidenceFileGood: false,
    reportFileGood: false,
    evidenceFileDefined: false,
    reportFileDefined: false,
    rationaleGood: false,
  })
  const reportRequired = getCourseParameter('turnitinEnabled', false) && props.upload && props.upload.isPdf()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(props.open)
  }, [props])

  const clearState = () => {
    setNextEnable(false)
    setSubmitEnable(false)
    setRationaleIds([])
  }

  const Footer = (
    <>
      {(step === 3 || step === 4) && (
        <YellowButton id='close-btn' className='cb-btn cb-btn-sm cb-btn-yellow btn-sm btn-primary' onClick={clearState} data-cb-modal-close>
          Close
        </YellowButton>
      )}
      {(step === 1 || step === 2) && (
        <NakedButton id='close-btn' className='cb-btn-close' onClick={clearState}  data-cb-modal-close>
          Cancel
        </NakedButton>
      )}
      {step === 1 && (
        <YellowButton
          className='js-next'
          disabled={!nextEnable}
          onClick={() => nextWindow()}
          small
        >
          Next
        </YellowButton>
      )}
      {step === 2 && (
        <>
          <YellowButton
            type='button'
            className='cb-btn cb-btn-sm cb-btn-yellow btn-sm btn-primary js-submit-final'
            disabled={!submitEnable}
            onClick={() => submit()}
            loading={isSaving}
          >
              Submit
          </YellowButton>
        </>
      )}
    </>
  )

  const stepOneGood = (good, ids) => {
    setNextEnable(good)
    setRationaleIds(ids)
  }

  const stageTwoUpdate = update => {
    setStageTwoState(prev => {
      const merged = {
        ...prev,
        ...update,
      }
      let reportGood = merged.reportFileGood || (!reportRequired && !merged.reportFileDefined)
      let evidenceGood = merged.evidenceFileGood || !merged.evidenceFileDefined
      let submitEnable = merged.rationaleGood && reportGood && evidenceGood
      setSubmitEnable(submitEnable)
      return merged
    })
  }

  // argument is event, not step
  const nextWindow = () => {
    const nextStep = step + 1
    setStep(nextStep)
  }

  const setWindow = newStep => {
    const nextStep = newStep ? newStep : step + 1
    setStep(nextStep)
  }

  const submit = () => {
    const form = document.getElementById('stepTwoPlag')
    const form2 = $('#' + props.modalId).find('form')[0]
    let formData = new FormData(form)
    formData.append('rationaleIds', rationaleIds)
    let url = addBaseUrl('scorables/' + props.scorable.id + '/plagiarism')
    setIsSaving(true)
    fetchRequest('POST', url, {
      credentials: 'include',
      body: formData,
    })
      .then(() => {
        setWindow(3)
        props.plagiarismReported()
      })
      .catch(() => {
        setWindow(4)
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  return (
    <Modal
      title={getModalTitle(step)}
      open={open}
      escClose={false}
      withHeader
      withFooter
      modalId={props.modalId}
      footer={Footer}
      onClose={() => {
        setStep(1)
        setNextEnable(false)
        props.onClose()
      }}
    >
      <>
        <div id='contentPlag'></div>
        <ContentFunc {...props} reportRequired={reportRequired} step={step} stepOneGood={stepOneGood} stageTwoUpdate={stageTwoUpdate} />
      </>
    </Modal>
  )
}

function ContentFunc (props) {
  let courseInstanceId = getCourseInstanceId()

  const csp = /^42/.test(courseInstanceId)
  const art = /^32/.test(courseInstanceId)
  const courseTitle = csp ? 'AP Computer Science Principles' : 'AP Capstone'

  const [rationaleError, setRationaleError] = useState('')
  const { reportRequired, step, stepOneGood, stageTwoUpdate, isSaving, scoreeName, rationales } = props

  useEffect(() => {
    document.querySelectorAll('.cb-checkbox').forEach(elem => {
      CBForm.customFormElement({
        elem: elem.querySelector('input'),
      })
    })
    if (!isSaving) {
      // document.getElementById('modalTitle').focus()
    }
  }, [props])

  if (step === 1) {
    return StepOneContent(scoreeName, courseTitle, csp, art, rationales, stepOneGood)
  } else if (step === 2) {
    return StepTwoContent(scoreeName, csp, art, reportRequired, stageTwoUpdate, rationaleError, setRationaleError)
  } else if (step === 3) {
    return (
      <div id='stepThreePlag' tabIndex='-1' className='alert alert-success cb-alert-heading' role='alert'>
        <h4>Your submission has been received.</h4>
      </div>
    )
  } else if (step === 4) {
    return (
      <div id='stepFourPlag' tabIndex='-1' className='alert alert-danger cb-alert-heading' role='alert'>
        <h4>Error: The plagiarism report was not submitted.</h4>
      </div>
    )
  }
}

function getModalTitle (step) {
  let courseInstanceId = getCourseInstanceId()
  const csp = /^42/.test(courseInstanceId)
  const art = /^32/.test(courseInstanceId)

  if (step === 1) {
    return 'Suspected Plagiarism' + (csp || art ? ' or Violation' : '') + ' - Step One'
  } else if (step === 2) {
    return 'Suspected Plagiarism' + (csp || art ? ' or Violation' : '') + ' - Step Two'
  } else if (step === 3) {
    return 'Suspected Plagiarism' + (csp || art ? ' or Violation' : '') + ' - Confirmation'
  } else if (step === 4) {
    return 'Suspected Plagiarism' + (csp || art ? ' or Violation' : '') + ' - Error'
  }
}

export default PlagiarismModalComp
