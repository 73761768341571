
// this pair is only called by the second pair in this file
$.fn.disableIf = function(condition) {
  return this.each(function() {
    $(this).prop('disabled', !!condition).toggleClass('disabled', !!condition);
  });
};

$.fn.enableIf = function(condition) {
  return this.disableIf(!condition);
};

// this pair is called in submitFinalMetadataStep.jsx
$.fn.disable = function() {
  return this.disableIf(true);
};

$.fn.enable = function() {
  return this.enableIf(true);
};

// this pair is used in menu.jsx
$.fn.removeActiveText = function() {
	return $(this).find('.sr-only').remove()
}

$.fn.addActiveText = function() {
	return $(this).prepend('<span class="sr-only">Active Page: </span>')
}
