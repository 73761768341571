import { getCurrentPerson }               from '../../entities/person.jsx'

const Name = ({ firstName, lastName, prefFirstName }) => {
  const isTeacher = getCurrentPerson().isTeacher()
  const fullName = isTeacher ? `${firstName} ${lastName}` : `${prefFirstName || firstName} ${lastName}`
  const prefName = isTeacher && prefFirstName && prefFirstName !== 'null' ? ` (Preferred Name: ${prefFirstName})` : ''

  return (
    <>
      <strong>{fullName}</strong>{prefName}
    </>
  )
}

export default Name
