import React from 'react'
import { getCurrentPerson } from '../../entities/person.jsx'
import { isDPCourse } from '../../entities/course'
import {
  getCourseInstanceId,
  getCurrentCourseInstance,
  getCurrentSection,
  getCurrentSectionId,
  isArt,
  isCSP,
} from '../../entities/courseinstance'
import { filterModules } from '../../entities/modules'
import { haveWeSections } from '../../progress/entities/progress'
import { Link } from 'react-router-dom'
import { AppContext } from '../../../context'

export default class MenuComp extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.onPrimaryClick = this.onPrimaryClick.bind(this)
    this.onSecondaryClick = this.onSecondaryClick.bind(this)
    this.togglePrimaryLink = this.togglePrimaryLink.bind(this)
    this.toggleSecondaryLink = this.toggleSecondaryLink.bind(this)
  }

  componentDidMount() {
    var $linkMatchingUrl = $('.secondary-link').filter('[href="' + location.pathname + '"]')
    if ($linkMatchingUrl.length) {
      this.toggleSecondaryLink($linkMatchingUrl, true)
    } else {
      //console.log('location.hash ' + location.hash + ', did not match any secondary links')
    }
    this.readLessonsLinks();
  }

  componentDidUpdate() {
    var $linkMatchingUrl = $('.secondary-link').filter('[href="' + location.pathname + '"]')
    if ($linkMatchingUrl.length) {
      this.toggleSecondaryLink($linkMatchingUrl, true)
    } else {
      //console.log('location.hash ' + location.hash + ', did not match any secondary links')
    }
  }

  whichRoleIsUser() {
    return getCurrentPerson().role()
  }


  render() {
    let helpers = this.serializeData()
    let srText = ', For course ' + helpers.courseTitle + ', and section ' + helpers.sectionTitle

    return (
      <AppContext.Consumer>
        {value => {

          let lessonList = module => {
            return _.map(module.lessons, lesson => {
              return (
                <li key={'lesson_' + lesson.id}>
                  <Link to={'/instruction/sections/' + helpers.sectionId + '/lessons/' + lesson.id}
                        className='secondary-link'
                        onClick={e => this.onSecondaryClick(e, value)}>
                    <span>{lesson.title}</span>
                  </Link>
                </li>
              )
            })
          }

          let moduleList = () => {
            return _.map(helpers.modules, module => {
              return (
                <li className="collapsed cb-nested primary-container" key={'module_' + module.id}>
                  <Link to='#' aria-expanded="false"
                        className="primary-link button-border"
                        onClick={this.onPrimaryClick}>
                    <span>{module.title}</span>
                  </Link>
                  <div className='cb-dropdown-menu cb-menu-right-xs' style={{paddingRight: 0}}>
                    <ul className='modules menu'>
                      {module.hasOverview &&
                        <li>
                          <Link to={'/instruction/sections/' + helpers.sectionId + '/modules/' + module.id + '/overview'}
                                className='secondary-link'
                                onClick={e => this.onSecondaryClick(e, value)}>
                            <span>Overview</span>
                          </Link>
                        </li>
                      }
                      {lessonList(module)}
                    </ul>
                  </div>
                </li>
              )
            })
          }

          return (
            <div className="left-navigation">
              <nav className="cb-menu-list" aria-label='menu containg class summaries, overviews and progresses'>
                <ul>
                  {helpers.isCoordinator &&
                    <li className="collapsed cb-nested primary-container">
                      <Link to='#' aria-expanded="false"
                            className="primary-link button-border"
                            onClick={this.onPrimaryClick}>
                        <span>Class Summary</span>
                        <span className='sr-only'>{srText}</span>
                      </Link>
                      <div className='cb-dropdown-menu cb-menu-right-xs' style={{paddingRight: 0}}>
                        <ul className='menu'>
                          <li>
                            <Link to={'/instruction/courses/' + helpers.courseInstanceId + '/progress/0'}
                                  className='secondary-link'
                                  onClick={e => this.onSecondaryClick(e, value)}>
                              <span>Progress</span>
                            </Link>
                          </li>
                          {helpers.showProgressWELink &&
                            <li >
                              <Link to={'/instruction/courses/' + helpers.courseInstanceId + '/progress/1'}
                                    className='secondary-link'
                                    onClick={e => this.onSecondaryClick(e, value)}>
                                <span>AP with WE Service Progress</span>
                              </Link>
                            </li>
                          }
                        </ul>
                      </div>
                    </li>
                  }
                  {!helpers.isCoordinator &&
                    <div>
                      <li className="collapsed cb-nested primary-container">
                        <Link to='#' aria-expanded="false"
                              className="primary-link button-border"
                              onClick={this.onPrimaryClick}>
                          <span>Class Summary</span>
                          <span className='sr-only'>{srText}</span>
                        </Link>
                        <div className='cb-dropdown-menu cb-menu-right-xs' style={{paddingRight: 0}}>
                          <ul className='menu'>
                            <li>
                              <Link to={'/instruction/sections/' + helpers.sectionId + '/overview'}
                                    className='secondary-link'
                                    onClick={e => this.onSecondaryClick(e, value)}>
                                <span>Overview</span>
                              </Link>
                            </li>

                            {helpers.isTeacher &&
                              <div>
                                {helpers.hasTeamAssignments &&
                                  <li>
                                    <Link to={'/instruction/sections/' + helpers.sectionId + '/groups'}
                                          className='secondary-link'
                                          onClick={e => this.onSecondaryClick(e, value)}>
                                      <span>Manage Teams</span>
                                    </Link>
                                  </li>
                                }

                                {helpers.showProgressLink &&
                                  <li>
                                    <Link to={'/instruction/sections/' + helpers.sectionId + '/progress/0'}
                                          className='secondary-link'
                                          onClick={e => this.onSecondaryClick(e, value)}>
                                      <span>Progress</span>
                                    </Link>
                                  </li>
                                }
                                {helpers.showProgressWELink &&
                                  <li>
                                    <Link to={'/instruction/sections/' + helpers.sectionId + '/progress/1'}
                                          className='secondary-link'
                                          onClick={e => this.onSecondaryClick(e, value)}>
                                      {helpers.showProgressLink &&
                                        <span>AP with WE Service Progress</span>
                                      }
                                      {!helpers.showProgressLink &&
                                        <span>Progress</span>
                                      }
                                    </Link>
                                  </li>
                                }
                              </div>
                            }
                          </ul></div>
                      </li>
                      {moduleList()}
                    </div>
                  }
                </ul></nav></div>
          )
        }}
      </AppContext.Consumer>
    )
  }

  onPrimaryClick(e) {
    e.preventDefault()
    var $link = $(e.currentTarget)
    this.togglePrimaryLink($link, $link.attr('aria-expanded') !== 'true')
  }

  onSecondaryClick(e, contextValue) {
    let cspSave, artSave
    const isStudent = this.whichRoleIsUser() === 'student', isCorrectLocation = location.pathname.includes('lessons')
    if (isArt() && isStudent && isCorrectLocation) {
      artSave = document.getElementById("save")
      if (!contextValue.clickEvent && !artSave.hasAttribute('disabled')) {
        contextValue.updateHasUnsavedWork(true)
        contextValue.updateClickEvent(true)
        contextValue.setDestinationLink(e.currentTarget.href)
        e.preventDefault()
      }
    }
    else if (isCSP() && isStudent && isCorrectLocation) {
      cspSave = document.getElementById("CSP_WR_1_1")
      if (!contextValue.clickEvent && cspSave && !cspSave.hasAttribute('disabled')) {
        contextValue.updateHasUnsavedWork(true)
        contextValue.updateClickEvent(true)
        contextValue.setDestinationLink(e.currentTarget.href)
        e.preventDefault()
      }
    } else {
      this.toggleSecondaryLink($('.secondary-link').filter('.active'), false)
      this.toggleSecondaryLink($(e.currentTarget), true)
    }
  }

  togglePrimaryLink($link, active) {
    ////console.log('togglePrimaryLink',$link)
       $link.toggleClass('active active-trail', active).attr('aria-expanded', active)
       $link.parents('li').toggleClass('active-trail', !active).toggleClass('collapsed', !active)
  }

  toggleSecondaryLink($link, active) {
    ////console.log('toggleSecondaryLink',$link)
    $('.secondary-link').removeActiveText()
    if (active) 
      $($link).addActiveText()
      $link.add($link.parent()).toggleClass('active active-trail', active)
      this.togglePrimaryLink($link.parents('li.primary-container').find('a.primary-link'), active)
  }

  serializeData() {
    let isCoordinator = getCurrentPerson().isCoordinator()
    let courseId = getCourseInstanceId()

    let currentCourse = getCurrentCourseInstance()
    let courseTitle = currentCourse ? currentCourse.title.replace('AP ', '') : ''
    let currentSection = getCurrentSection()
    let sectionTitle = currentSection ? currentSection.fullTitle : ''

    if (isCoordinator) {
      return {
        isCoordinator: true,
        courseInstanceId: courseId,
        showProgressWELink: haveWeSections() && isDPCourse(courseId),
        courseTitle: courseTitle,
        sectionTitle: sectionTitle
      }
    } else {

      // 1 DP without WE, 2 non DP, 3 DP with WE
      // dont want to show the WE module for DP without WE
      let courseType = currentSection.courseType
      let moduleHolder = courseType === 1 ?
        filterModules(this.props.moduleHolder, false) : this.props.moduleHolder

      return {
        modules: moduleHolder.modules,
        isTeacher: getCurrentPerson().isTeacher(),
        isCoordinator: getCurrentPerson().isCoordinator(),
        sectionId: getCurrentSectionId(),
        showProgressLink: courseType == '1' || courseType == '3',
        showProgressWELink: courseType == '2' || courseType == '3',
        courseInstanceId: getCourseInstanceId(),
        hasTeamAssignments: moduleHolder.hasTeamLessons(),
        courseTitle: courseTitle,
        sectionTitle: sectionTitle
      }
    }
  }

  // getUnstyledNavItems() {
  //   return this.$el.find('> ul > li').removeAttr('class');
  // }

  // read  the state saved when navigating to view score (progress_module.js: checkAccessAndGoToScoring )
  readLessonsLinks() {
    let lessonLinks = window.sessionStorage.getItem("lessonLinks");
    let res = lessonLinks ? JSON.parse(lessonLinks) : null;
    if (res) {
      $('.secondary-link').each((i, sl) => $(sl).addClass(res[i]));
      window.sessionStorage.removeItem("lessonLinks");
    }
  }
}

