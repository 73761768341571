import { Link } from 'react-router-dom'

export function CourseComp({course, href}) {
    let adjustedTitle = course.title.replace('AP ', '')

    return (
        <div className='cb-band cb-band-panel'>
            <div className='cb-band-panel-content'>
                <div className="cb-band-panel-heading" id={'heading' + course.courseId}>
                    <Link className="cb-band-panel-hover-link" to={`../../${href}`}>
                        <span className="logo logo-sm">
                            <span className="sr-only">AP</span>
                        </span>
                        <span className='title'>{adjustedTitle}</span>
                        <span className='view'>View</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}
