import { Dropdown, DropdownItem } from '@cb/apricot-react'

const OrgList = ({ orgs, currentOrg, changeOrg }) => {
    let orgName = currentOrg ? currentOrg.orgName : ''
    const rows = orgs.map((org, index) => <DropdownItem key={index} className='cb-nav-link-second' label={org.orgName} data-org={org.orgId} />)

    const changeOrgHandler = node => changeOrg(node)

    useEffect(() => {
        const dropdownToggle = document.getElementById('orgList')
        dropdownToggle.style.paddingLeft = '0px'
        dropdownToggle.style.paddingRight = '0px'
    }, [])

    return (
        <Dropdown dropdownId='orgList' title={orgName} closeOnClick callBack={changeOrgHandler}>
            {rows}
        </Dropdown>
    )
}

export default OrgList
