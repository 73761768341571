import React from 'react';

import MenuLinkComp                         from './menuLink'
import { TeacherSelections }                from './teacherSelections'
import CMSDocsComp                          from '../../supportingmaterial/views/cmsDocs'
import { canEdit }                          from '../../entities/courseinstance'
import { filterModules }                    from '../../entities/modules'

export function TeacherContentOverview({sectionId, selections, saveSelections, textContent, partition, getUpdatedData}) {
    let canEditSelections = canEdit(sectionId)
    return (
        <div id={'overview_' + sectionId} key={'overview_' + sectionId}>
            <h3 id='title' tabIndex='-1' aria-labelledby='title region-static-text' className="h3">Overview</h3>
            <div id="text-region" dangerouslySetInnerHTML={{ __html: textContent }} />
            <div id="cmsmaterials-region">
                <CMSDocsComp partition={partition}></CMSDocsComp>
            </div>
            <div id="suppmaterials-region"></div>
            <div id="teacher-selections-region">
                {selections &&
                    <TeacherSelections
                        sectionId={sectionId}
                        selections={selections}
                        canEdit={canEditSelections}
                        save={saveSelections}
                        getUpdatedData={getUpdatedData}
                    />
                }
            </div>
        </div>
    )
}
