import React, { Fragment } from 'react'
import { isReadOnlyMode } from '../../entities/courseinstance'

// replaced stuff in supportingmaterial_views
export default class SupportingMaterialEmptyComp extends React.Component {
  constructor(props) {
    super(props);
    //console.log('SupportingMaterialEmptyComp ', this.props.parms)
  }

  render() {
    let isReadOnly = isReadOnlyMode()
    let cls = isReadOnly ? '' : 'visible-xs'

    return (
      <li>
        <span className={cls}>No additional resources have been uploaded.</span>
        {!isReadOnly &&
        <span className="hidden-xs">Click the 'Add' button to upload additional resources.</span>
        }
      </li>
    )
  }
}
