import { recordFocus } from '../../utility/util'
import { Modal, NakedButton, YellowButton } from '@cb/apricot-react'
import { AlertComp } from '../../_core/views/alert'

export default function NoResponseModal(props) {
  const [isSaving, setIsSaving] = useState(false)
  const [swapped, setSwapped] = useState(false)
  const [alertProps, setAlertProps] = useState(false)
  const [open, setOpen] = useState(false)
  const [successResponse, setSuccessResponse] = useState(null)

  useEffect(() => {
    setOpen(props.open)
  }, [props])

  const Footer = (
    <>
      {!swapped &&
        <NakedButton type='button' id='close-btn' className='cb-btn-close' data-cb-modal-close>
          Cancel
        </NakedButton>
      }
      {swapped &&
        <YellowButton type='button' data-cb-modal-close className='js-next'>
          Ok
        </YellowButton>
      }
      {isSaving &&
        <button className='cb-btn cb-btn-sm cb-btn-pulse' type='button'>
          Default
          <div className='cb-btn-pulse-dots'>
            <div className='cb-btn-dots'></div>
          </div>
        </button>
      }
      {!isSaving && !swapped &&
        <YellowButton type='button' className='js-submit-final' small='true' onClick={() => saveNoResponseStatus()}>
          Submit
        </YellowButton>
      }
    </>
  )

  const saveNoResponseStatus = () => {
    const { studentData, lesson, sectionId, scorableHolder } = props
    const isNonParticipant = lesson.isTeamLesson && !!studentData

    removeErrorMessage()
    setIsSaving(true)

    const promise = isNonParticipant ?
      scorableHolder.addNonParticipant(studentData.dpPersonId, sectionId) :
      scorableHolder.modifyScorableStatus(scorableHolder.enums.NoResponse, null, sectionId)
    promise
      .then(saveSuccessFunction)
      .catch(saveErrorFunction)
      .finally(() => {
        setIsSaving(false)
        if (!isNonParticipant) {
          reloadTeams()
        }
      })
  }

  const reloadTeams = () => {
    if (props.selectTeam) {
      props.selectTeam(true)
    } else {
      setOpen(false)
    }
  }
  const removeErrorMessage = () => {
    setAlertProps(false)
  }
  const addErrorMessage = (message, type) => {
    setAlertProps({
      isDismissable: false,
      showAlert: true,
      message: message,
      uniqueId: type,
    })
  }

  const saveErrorFunction = (xhr) => {
    const { status, responseText } = xhr
    const isOutdated = status === 422

    if (isOutdated || status === 403) {
      if (isOutdated) {
        setOpen(false)
      } else {
        setSwapped(true)
      }
    } else {
      setSwapped(false)
    }
    const errorMessage = isOutdated ?
      'Error: The status of this item has changed since it was last retrieved. Your screen will be refreshed.' :
      (responseText || errorMessage)

    addErrorMessage(errorMessage, 'noResponseErrorAlertMessage')
  }

  const close = () => {
    if (props.focusOnClose) {
      recordFocus(props.focusOnClose)
    }
  }


  const saveSuccessFunction = (response) => {
    setSuccessResponse(response)
    setOpen(false)
  }

  const getNoResponseIntroText = () => {
    const student = props.studentData
    const lesson = props.lesson
    const noResponseText = props.noResponseText
    const name = student ? student.fullName : props.team?.title

    if (student) {
      if (lesson.isTeamLesson) {
        return 'By selecting "' + noResponseText +
          '," you are confirming that nothing was submitted for this task by ' + name +
          '. To select "' + noResponseText +
          '" for the entire team, go to the "By Team" tab.'
      } else {
        if (props.isWe) {
          return 'By selecting "' + noResponseText +
            '", you are confirming that ' + name +
            ' is not participating in AP with WE Service.'
        } else {
          return 'By selecting "' + noResponseText +
            '", you are confirming that nothing was submitted for this task by ' + name + '.'
        }
      }
    } else {
      return 'By selecting "' + noResponseText +
        '," you are confirming that nothing was submitted for this task by team ' + name +
        '. "' + noResponseText +
        '" will be applied to all students on the team.'
    }
  }

  return (
    <Modal
      title={props.noResponseText}
      open={open}
      escClose={false}
      withHeader
      withFooter
      modalId={props.modalId}
      footer={Footer}
      onClose={() => {
        close()
        props.onClose(successResponse)
      }}
    >
      <div className='alert-wrapper'>
        {alertProps && alertProps.showAlert &&
          <AlertComp props={alertProps} />
        }
      </div>
      <p className='cb-align-left'>
        {swapped ? '' : getNoResponseIntroText()}
      </p>
    </Modal>
  )
}
