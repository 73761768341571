import { Link } from 'react-router-dom'

export default class MenuLinkComp extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        //let moduleHolder = this.props.parms.moduleHolder
        let lessonList = module => {
            return _.map(module.lessons, (lesson, index) => {
                return (
                    <li key = {'lesson_' + index}>
                        <Link to={'/instruction/sections/' + this.props.parms.sectionId + '/lessons/' + lesson.id}>
                            {lesson.title}
                        </Link>
                    </li>
                )
            })
        }

        let moduleList = modules => {
            return _.map(modules, (module, index) => {
                return (
                    <div key = {'module_' + index}>
                        <h5>
                            <p>&nbsp;</p>
                            <Link to={'/instruction/sections/' + this.props.parms.sectionId + '/modules/' + module.id + '/overview'}
                                className="text-normal">
                                {module.title}
                            </Link>
                        </h5>
                        <ul className="list-unstyled">
                            {lessonList(module)}
                        </ul>
                    </div>
                )
            })
        }

        return (
            <div>
            { moduleList(this.props.parms.moduleHolder.modules) }
            </div>
        )
    }
}