import CBLocalNavigation from "@cb/apricot/CBLocalNavigation"
import { getCurrentPerson } from "../../entities/person.jsx";
import { ReportMenuAdmin } from "./reportMenu";
import { ScoringMenuAdmin } from "./scoringMenu";
import { selectedLink } from "../../utility/util";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'

export function AdminMenuComp({reportTypes}) {
    useEffect(() => {
        try {
            let elem = document.querySelector('.cb-local-navigation')
            if (elem) {
                 CBLocalNavigation({
                    elem: elem,
                    mobileInteractionClose: true,
                    sticky: false
                })
            } else {
                console.error('AdminMenuComp could not access .cb-local-navigation')
            }
        } catch (e) {
            console.error('AdminMenuComp had trouble with useEffect', e)
        }
    })

    let helpers = templateHelpers(reportTypes)
    let navigate = useNavigate()

    return (
        <div className='cb-local-navigation container' aria-label='Digital Portfolio Admin Dashboard Navigation'
            data-cb-apricot="local-navigation">
            <div className="cb-desktop-navigation container" style={{ paddingRight: 0, paddingLeft: 0, marginRight: 0 }}>
                <div className="row" style={{marginRight: 0}}>
                    <div id='admin-menu' className="offset-sm-4 col-sm-8 offset-md-5 col-md-7 offset-lg-6 col-lg-6 padding-none">
                        <div className='col-xs cb-nav-container padding-none'>
                            <div className="cb-nav-items col-xs-12 hidden-xs-only padding-none" style={{ height: 'fit-content' }}>
                                <nav className="cb-menu-list cb-menu-list-horizontal" aria-label="menu containing home courses report scoring and manage extensions" style={{ height: 'fit-content' }}>
                                    <ul>
                                        <Fragment>
                                            <li className='home col-xs-4 cb-nav-link cb-dropdown'/* + helpers.offsetCls}*/>
                                                <Link to="/" className='cb-menu-link admin-menu-link' role='button'
                                                    onClick={(e) => {selectedLink(e,'admin-menu-link')}}>
                                                    <span className="cb-glyph cb-home left-glyph cb-margin-right-8"
                                                        aria-hidden="true" style={{ marginLeft: 0 }}>
                                                    </span>
                                                    <span>Home</span>
                                                </Link>
                                            </li>
                                            {helpers.systemAdmin &&
                                                <li className="cb-nav-link cb-dropdown col-xs-2">
                                                    <Link to="/admin/manage-courses" className='cb-menu-link admin-menu-link'
                                                        role='button'
                                                        onClick={(e) => {selectedLink(e,'admin-menu-link')}}>
                                                        <span>Courses</span>
                                                    </Link>
                                                </li>
                                            }
                                            {helpers.reportTypes &&
                                                <li className="cb-nav-link col-xs-2 padding-none">
                                                    <div>
                                                        <ReportMenuAdmin reportTypes={helpers.reportTypes} nav={navigate} />
                                                    </div>
                                                </li>
                                            }
                                            <li className="cb-nav-link col-xs-2 padding-none">
                                                <div className='cb-dropdown'>
                                                    <ScoringMenuAdmin />
                                                </div>
                                            </li>
                                            <li className="cb-nav-link col-xs-4 col-lg-3 cb-dropdown">
                                                <Link to="/admin/manage-extensions" className='cb-menu-link admin-menu-link'
                                                    role='button'
                                                    onClick={(e) => {selectedLink(e,'admin-menu-link')}}>
                                                    <span>Manage Extensions</span>
                                                </Link>
                                            </li>
                                        </Fragment>
                                    </ul>
                                </nav>
                            </div>
                            <div className="cb-mobile-trigger hidden-sm-up"
                                style={{ float: 'right', paddingTop: 0, paddingBottom: 0 }}>
                                <a href="#" className="cb-glyph cb-menu cb-no-text-decoration cb-link-black"
                                    aria-expanded="false" role='button' aria-hidden="true">
                                    <span className="sr-only">Display Site Navigation</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cb-mobile-navigation"></div>
        </div>
    )
}

function templateHelpers(reportTypes) {
    let person = getCurrentPerson()
    let systemAdmin = person.isSystemAdmin()
    let missing = 5
    if (systemAdmin) missing = missing - 2
    if (reportTypes) missing = missing - 2
    return {
        admin: person.isAdmin(),
        systemAdmin: systemAdmin,
        reportTypes: reportTypes,
        anyAdmin: person.isAdmin() || person.isSystemAdmin(),
        offsetCls: 'offset-xs-' + missing
    }
}
