import React from 'react'
import { reactRender } from '../utility/util'

import { alterHTML, contentField } from '../content/static_content'
import { getCourseProgress, getProgressSection } from './entities/progress'
import { isDPCourse } from '../entities/course'
// import { Team }                                     from '../entities/team'
import { getCourseParameter, getCurrentSectionId, getSection, isArt, isCSP } from '../entities/courseinstance'
import { filterModules, getModuleHolder } from '../entities/modules'
import CoordinatorLayoutComp from './views/coordinatorLayout'
import { ProgressTeacherLayout } from './views/progressTeacherLayout'
import { showLoadingView } from '../_core/views/loading_view'
import { bsliGet } from '../_core/util/request'
import { Enrollment } from '../enrollment/entities/enrollment'
import { showScoringLayout } from '../scoring/scoring_module'
import { ConfirmModal } from '../_core/views/modal'
import { showCourseContent } from '../main/instruction_controller'


let shownTab = 'student'

// returns {students: [], error: xxx} one of which is undefined
export function getStudentProgressRowsBySection(sectionId, moduleHolder, isWe) {
  // called when coordinator presses the expand button on a course
  // getStudentProgressRowsBySection: co.wrap(function* (sectionId, modules, isWe, viewToAnchor) {
  try {
    //var modules = yield getModuleHolder()
    //var [studentProgress, teamProgress] = getProgressSection(sectionId, modules, true)

    return getProgressSection(sectionId, moduleHolder, true)
      .then(progressSection => {
        return {
          // progressSection[0] is the studentCollection, it contains an array of type StudentModel
          students: _.map(progressSection[0].students, function(studentModel, index) {
            let jsonStudent = studentModel.jsonStudent
            let allComplete = studentModel.completionStatus,
              csp = isCSP(),
              art = isArt(),
              takingExam = jsonStudent.examIntent,
              intentEnabled = getCourseParameter('examintentEnabled'),
              apNumberEnabled = getCourseParameter('apnumberEnabled'),
              hasAproNumber = !_.isEmpty(jsonStudent.aproStudentId),
              completionText,
              extendedEndDate = jsonStudent.extendedEndDate
            if (intentEnabled && takingExam === false) {
              completionText = 'N/A'
            } else if (!(csp || art) && apNumberEnabled && takingExam === false) {
              completionText = 'N/A'
            } else if ((!(csp || art) && apNumberEnabled) || ((csp || art) && intentEnabled)) {
              completionText = (allComplete && takingExam === true) ? 'Complete' : 'Incomplete'
            } else {
              completionText = (allComplete && hasAproNumber && (!intentEnabled || takingExam)) ? 'Complete' : 'Incomplete'
            }
            var classNames = !allComplete && 'cb-gray4-bg hidden'
            return {
              studentModel: studentModel,
              lessons: progressSection[0].lessons,
              extraColumnData: completionText,
              extendedEndDate,
              classNames: classNames,
              sectionId: sectionId,
              isWe: isWe,
              id: 'student' + index,
            }
          }),
          error: undefined,
        }
      })
  } catch (e) {
    return {
      students: undefined,
      error: e,
    }
  }
}

export const outdatedDataRefreshMessage = 'Error: The status of this item has changed since it was last retrieved. Your screen will be refreshed.'

function openScoring(parms) {
  let { scorableHolder, lesson, isWe } = parms
  var scoree = lesson.isTeamLesson ? new Team(parms.team) : new Enrollment(parms.studentData)
  showScoringLayout(scorableHolder, scoree, lesson, isWe)
}

export const checkAccessAndGoToScoring = async (parms) => {
  //console.log('checkAccessAndGoToScoring parms', parms)
  let teacherScoringWarning = 'This submission was recently opened by your co-teacher. If this teacher is also scoring, you could overwrite each other’s scores. Click Continue to proceed to the submission. Click Cancel to return to the Progress page.'
  let teacherRescoringWarning = 'The submission you are attempting to rescore was recently opened by your co-teacher. If this teacher is also scoring, you could overwrite each other’s scores. Click Continue to proceed to the submission. Click Cancel to return to the Progress page.'
  let rescoreMessage = 'This submission has already been scored. Click Continue to proceed to the submission.  Click Cancel to return to the Progress page.'
  let reconfirmMessage = 'This submission has already been confirmed.  Click Continue to proceed to the submission.  Click Cancel to return to the Progress page.'

  let { scorableHolder, lesson, isWe, sectionId } = parms
  let isArchived = getSection(sectionId)?.isArchived
  // progressObj is the options from StatusCell
  let url = 'scorables/' + scorableHolder.scorable.id + '/scoreStatus'
  var [accessDeniedMessage, warningFlag] = await bsliGet(url)
    .then(json => [json.scoringPermissionWarningMessage, json.scoringWarningFlag])

  var confirmScorables = getCourseParameter('confirmScorables')
  var isScored = scorableHolder.isScored()
  var warningMessage = warningFlag && (isScored ? teacherRescoringWarning : teacherScoringWarning)
  var rescoreMess = isScored && (confirmScorables ? reconfirmMessage : rescoreMessage)

  if (isArt() || isCSP()) {
    openScoring(parms)
    return
  }

  if (!isArchived && (accessDeniedMessage || warningFlag || isScored)) {
    var message = accessDeniedMessage || warningMessage || rescoreMess
    var title
    if (accessDeniedMessage) title = 'Scoring Access'
    else if (!isScored) title = confirmScorables ? 'Confirm Submission' : 'Score Assignment'
    else title = confirmScorables ? 'Reconfirm Submission' : 'Rescore Assignment'

    let onConfirmYes = () => {
      if (!accessDeniedMessage) {
        openScoring(parms)
      }
    }

    let open = true
    let modalParms = {
      title: title,
      noButtonText: 'Cancel',
      yesButtonText: accessDeniedMessage ? 'Ok' : 'Continue',
      hideNoButton: accessDeniedMessage,
      message: message,
      onConfirmYes: onConfirmYes,
      //width: 500, // Farhaz says no
      modalId: 'scoreModal',
    }
    let modal = <ConfirmModal {...parms} open={open} onClose={() => open = false} />
    reactRender(modal, 'region-modal', modalParms.modalId, true)
  } else
    openScoring(parms)
}


export async function showSectionProgress(sectionId, we) {
  let sectId = sectionId
  showLoadingView('region-course-content')
  if (sectId === undefined || we === undefined) {
    //capture these values from the current url
    let reg = /sections\/(\d+)\/progress\/(\d+)/
    let groups = reg.exec(window.location.href)
    if (groups && groups.length === 3) {
      sectId = groups[1]
      we = groups[2]
    } else {
      console.error('url was not what was expected')
      return
    }
  }
  let awaitedData = await getSectionData()
  let staticText = awaitedData.staticText
  let moduleHolder = awaitedData.moduleHolder

  if (!moduleHolder) {
    console.error('moduleHolder was false')
    return
  }
  //console.log('staticText', staticText)
  let marker = isWe ? 'WE_PROGRESS' : 'DP_PROGRESS'
  staticText = alterHTML(staticText, marker)

  //var moduleHolder = await getModuleHolder()
  let isWe = we == 1
  moduleHolder = filterModules(moduleHolder, isWe)
  sectId = sectId || getCurrentSectionId()

  // studentProgress is of type ProgressStudentCollection
  // which has a property 'students' which is an array of StudentModel
  var [studentProgress, teamProgress] = await getProgressSection(sectId, moduleHolder, false)

  let hasTeams = moduleHolder.hasTeamLessons() && !isWe
  let reload = false
  let baseParms = {
    moduleHolder: moduleHolder,
    studentProgress: studentProgress,
    isStudent: false,
    sectionId: sectId,
    isWe: isWe,
    tab: shownTab,
    selectStudent: async () => {
      if (shownTab = 'team') {
        showSectionProgress(sectionId, we)
      }
      shownTab = 'student'
    },
    selectTeam: (refresh) => {
      if (refresh) {
        showSectionProgress(sectionId, we)
      }
      shownTab = 'team'
    },
    staticText: staticText,
  }

  let elem
  if (hasTeams) {
    let teamParms = baseParms
    teamParms.teamProgress = teamProgress
    elem = <ProgressTeacherTeamLayoutComp parms={teamParms}></ProgressTeacherTeamLayoutComp>
  } else {
    elem = <ProgressTeacherLayout parms={baseParms}></ProgressTeacherLayout>
  }
  showCourseContent(elem)
}

export async function showCourseProgress(courseInstanceId, we) {
  showLoadingView('region-course-content')

  var moduleHolder = await getModuleHolder()
  let isWe

  // for DP courses we filter/split the modules
  if (isDPCourse(courseInstanceId)) {
    isWe = we == 1
    moduleHolder = filterModules(moduleHolder, we == 1)
  } else {
    // for non-Dp courses, we=0 but we want to tell progress page it is a we course
    isWe = true
  }

  // instance of ProgressSectionCollection
  var coordinatorProgress = await getCourseProgress(courseInstanceId, moduleHolder, we)

  //console.log('showCourseProgress coordinatorProgress', coordinatorProgress)
  //app.request('progress:course', courseInstanceId, moduleHolder, we)

  let parms = {
    courseInstanceId: courseInstanceId,
    moduleHolder: moduleHolder,
    coordinatorProgress: coordinatorProgress,
    isWe: isWe,
  }
  let elem = <CoordinatorLayoutComp parms={parms}></CoordinatorLayoutComp>
  showCourseContent(elem)
  document.dispatchEvent(new CustomEvent('pagevisit', { detail: 'progress' }))
}

async function getSectionData() {
  let getVal = (val, onErrVal) => {
    if (val.status === 'fulfilled') {
      return val.value
    } else {
      return onErrVal
    }
  }
  try {
    let asyncFuncs = [
      contentField('teacher_progress'),
      getModuleHolder(),
    ]
    const awaitedData = await Promise.allSettled(asyncFuncs)
    return {
      staticText: getVal(awaitedData[0], ''),
      moduleHolder: getVal(awaitedData[1], false),
    }
  } catch (e) {
    return {
      staticText: '',
      moduleHolder: false,
    }
  }
}
