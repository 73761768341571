import { Link } from 'react-router-dom'
import { clickIt, selectedLink } from '../../utility/util'
import { isArt, isCSP } from "../../entities/courseinstance"

export const MyClasses = ({ helpers, context }) => {
    const checkOnClick = (contextValue, event) => {
        let cspSave, artSave
        if (isArt()) {
            artSave = document.getElementById("save")
            if (!contextValue.clickEvent && !artSave.hasAttribute('disabled')) {
                contextValue.updateHasUnsavedWork(true)
                contextValue.updateClickEvent(true)
                contextValue.setDestinationLink(event.currentTarget.href)
                event.preventDefault()
            }
        }
        else if (isCSP()) {
            cspSave = document.getElementById("CSP_WR_1_1")
            if (!contextValue.clickEvent && cspSave && !cspSave.hasAttribute('disabled')) {
                contextValue.updateHasUnsavedWork(true)
                contextValue.updateClickEvent(true)
                contextValue.setDestinationLink(event.currentTarget.href)
                event.preventDefault()
            }
        }
    }

    const createLinksForDropdown = (helpers) => {
        const { isStudent, showCourseNameOnly, courseInstances, hasArchivedCourses } = helpers
        let links, mobileLinks

        const archiveLink = () => (
            <li key='archived'>
                <Link to="/instruction/archive" className='cb-menu-link' onClick={e => checkOnClick(context, e)}>
                    <span>View your archived classes</span>
                </Link>
            </li>
        )

        const archiveListLink = () => (
            <ul className='cb-no-padding-top' key='archived'>
                <li className='cb-nav-link-second' >
                    <Link to="/instruction/archive" className='cb-menu-link' style={{ paddingLeft: '4px' }} onClick={e => checkOnClick(context, e)}>
                    <span className="section-title hidden-xs" style={{ textAlign: 'left' }}>
                        <strong>View your archived classes</strong></span>
                    </Link>
                    <hr className="cb-spacerv-bottom-8" />
                </li>
            </ul>
        )

        if (isStudent) {
            links = courseInstances?.map((course, index) => (
                course.isDPCourse ? <li className='cb-nav-link-second' key={'listA_' + index}>
                    <Link to={'/instruction/sections/' + course.sections[0].id + '/overview'}
                          className='cb-menu-link'
                          onClick={e => checkOnClick(context, e)}>
                        <span>{course.title}</span>
                    </Link>
                </li> : course.sectionsOfOrg?.map((section, index) => (
                    <li className='cb-nav-link-second cb-no-padding-left'
                        key={'listA_' + index + '_we'}>
                        <Link to={'/instruction/sections/' + section.id + '/' + section.suffix}
                              className='cb-menu-link'
                              onClick={e => checkOnClick(context, e)}>
                            <span className="visible-xs">{course.title}: {section.subTitle}</span>
                            <span className="hidden-xs">{course.title}: {section.subTitle}</span>
                        </Link>
                    </li>
                ))
            ))
            if (hasArchivedCourses) links.push(archiveLink())
            links = links.flat()
        }

        else if (!isStudent && showCourseNameOnly) {
            links = courseInstances?.map((course, index) => {
                let href = ''
                if (helpers.isCoordinator) {
                    href = '/instruction/courses/' + course.id + '/progress/0'
                } else {
                    href = '/instruction/sections/' + course.sections[0].id + '/overview'
                }
                return (
                    <li className='cb-menu-link cb-menu-link-vertical' key={'listB_' + index}>
                        <Link to={href} className='cb-menu-link'>
                            <span>{course.title}</span>
                        </Link>
                    </li>
                )
            })
            if (hasArchivedCourses) links.push(archiveLink())
            links = links.flat()
        }

        else {
            const courseList = (course, id) => {
                return course.sectionsOfOrg?.map(section => {
                    const href = '/instruction/sections/' + section.id + '/' + section.suffix;
                    const sectionName = section.fullTitle
                    return (
                        <li className='cb-nav-link-second' key={'listC_' + section.id}>
                            <Link to={href} className='cb-menu-link' aria-describedby={id} onClick={() => {
                                const link = document.getElementById('dropdownLink')
                                if (link) link.click()
                            }}>
                                <span className='sr-only'>{'course ' + course.title + ' section '}</span>
                                <span key={'name_' + section.id}>{sectionName}</span>
                            </Link>
                        </li>
                    )
                })
            }
            const mobileCourseList = course => {
                return course.sectionsOfOrg?.map(section => {
                    const href = '/instruction/sections/' + section.id + '/' + section.suffix;
                    const courseAndSection = course.title + ' - ' + section.fullTitle
                    return (
                        <li className='cb-nav-link-second' key={'listD_' + section.id}>
                            <Link to={href} className='cb-menu-link'>
                            <span key={'name_small_' + section.id}>
                                {courseAndSection}
                            </span>
                            </Link>
                        </li>
                    )
                })
            }
            links = courseInstances?.map((course, index) => {
                let id = 'course_' + course.id
                return (
                    <Fragment key={'fragment_' + index}>
                        <div id='id' style={{ textAlign: 'left' }}
                             className='section-title hidden-xs cb-spacerv-8'>
                            <div className="cb-margin-left-4"><strong>{course.title}</strong></div>
                            <hr/>
                        </div>
                        <ul key={'ul_' + course.title}>
                            {courseList(course,id)}
                        </ul>
                    </Fragment>
                )
            })
            mobileLinks = courseInstances?.map(course => (mobileCourseList(course)))

            if (hasArchivedCourses) {
                links.push(archiveListLink())
                mobileLinks.push(archiveLink())
            }
            mobileLinks = mobileLinks.flat()
        }

        return !isStudent && !showCourseNameOnly ? (
            <>
                <div className='hidden-xs-up'>
                    <ul>
                        {mobileLinks}
                    </ul>
                </div>
                <div className='hidden-xs-only cb-padding-top-8'>
                    {links}
                </div>
            </>
        ) : (
            <ul>
                {links}
            </ul>
        )
    }

    return (
        <>
            <a id="dropdownLink" className="cb-dropdown-toggle right-menu-link" href="#" aria-controls='myClassesDropdown' aria-expanded="false">
                <span>My Classes</span>
                <span className="sr-only">Press enter for dropdown.</span>
                <span className="cb-icon cb-down" aria-hidden="true" />
                <span className="cb-icon cb-up cb-hidden" aria-hidden="true" />
            </a>
            <div id='dropdownMenu' className='cb-dropdown-menu cb-box-shadow cb-menu-right-always'>
                {createLinksForDropdown(helpers)}
            </div>
        </>
    )
}
