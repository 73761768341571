import React, { Fragment, useEffect, useState }     from 'react';
import { Select, Input }                            from '@cb/apricot-react';
import { isAlphaNumericWords, validateKey }        from '../../utility/util'

export function MetadataList({ meta, changeMeta }) {
    const [value, setValue] = useState(-1)
    const [id] = useState(meta.id)
    const [otherText, setOtherText] = useState(''); 

    let otherOption = _.find(meta.options, option => { return option.label.toLowerCase().indexOf('other') >= 0})
    let otherOptionValue = otherOption ? otherOption.value : 'missing'

    let optionList = meta => {
        return _.map(meta.options, (option, index) => {
            return {
                value: option.value,
                label: option.label,
                key:'option_' + meta.id + '_' + index
            }
        })
    }

    let containsOtherOption = otherOptionValue !== 'missing'
    let otherDisabled = value != otherOptionValue
    let selectId = 'metadata-' + id
    let otherId = selectId + '-other'

    return (
        <Fragment>
            <div className="cb-select form-group" tabIndex='-1'>
            <Select
                id={selectId}
                name={meta.label} label={meta.label} ariaLabel={meta.label}
                labelNoValue={'Select ' + meta.name}
                values={optionList(meta)}
                onChange={selectChange}
                value={value}
            />
            </div>
            {containsOtherOption &&
                <div className="cb-input form-group">
                    <label htmlFor={otherId} className="font-normal">
                        If other, please enter the {meta.name.toLowerCase()}.
                    </label>
                    <Input type="text" name='other' className="form-control" id={otherId}
                        required="true" maxLength="50" disabled={otherDisabled}
                        placeholder={'Please enter the ' + meta.name.toLowerCase()}
                        value={otherText}
                        onChange={ e => otherChange(e,true)} onBlur={e => {otherChange(e,true)}} onKeyPress={validateKey}/>
                        </div>
            }
        </Fragment>
    )

    function selectChange(val) {
        setValue(val)
        if (containsOtherOption && val != otherOptionValue) {
            setOtherText('');
            updateMeta(val, '');
        } else {
            updateMeta(val, otherText);
        }
    }

    function otherChange(evt,save) {
        let newVal = evt.target.value
        let isValid = isAlphaNumericWords(newVal.trim())
        if (isValid) {
            setOtherText(newVal) 
            if (save) {
                updateMeta(value, newVal)
            }
        }
    }

   // changeMeta will either keep the data or delete it from the metalist
    // we delete when the options value is not set (= -1) or when the otherText is not set when option other is selected
    function updateMeta(val, newOtherText) {
        let data = { id: id, value: val }
        let keepData = true
        if (val && val != -1) {
            if (containsOtherOption && val == otherOptionValue) {
                keepData = newOtherText && newOtherText.length > 0
                data.otherText = newOtherText
            }
        } else {
            keepData = false
        }
        changeMeta(keepData, data)
    }
}
