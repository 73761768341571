import React, { Fragment } from 'react';
import FileUploadModal from './fileUploadModal'
import { addBaseUrlAndApiKey }  from '../../_core/util/URL.jsx'
import { getCurrentSectionId }  from '../../entities/courseinstance'
import { bsliPostCSP, fetchRequest }           from '../../_core/util/request'
import {refreshActivityFeed, refreshMenuBar} from '../../utility/service'

var invalidFileTypeMessage          = "Error: Only the following file types are allowed: {content}.";
var invalidFileMinSizeMessage       = "Error: Your uploaded file must be greater than {content}. Please try again.";
var invalidFileMaxSizeMessage       = "Error: The file can't be larger than {content}.";
var uploadFailedMessage             = "Error: The file was not uploaded. Please try again.";
var invalidBrowserVersion           = "Error: This feature will not work with your current internet browser. Please use Chrome, Firefox, Internet Explorer 10 and above, or Safari.";
const metaDataFailed                = 'Error: Upload meta data not returned by server.'
const uploadCountLimitFailed        = 'Error: You have exceeded the upload limit. Please try again in a few minutes'

// formerly Common.FileUploadToS3Modal used only by Assignment.UploadModal
export default class FileUploadS3Modal extends FileUploadModal {
    constructor(props) {
        super(props);
        // props.parms has url, assignment, fileReqs, modalId
        // fileReqs has extensions, maxSize, minSize  

        _.bindAll(this, 'uploadFailed', 'uploadToS3',
            'setUploadingButtonState', 'startS3Polling',
            'validateAndGetUploadMetaData')
        this.POSTdata = {}
    }

    async validateAndGetUploadMetaData() {
        //console.log('validateAndGetUploadMetaData url=' + this.props.parms.url)
        let file = this.fileFacts.file
        //var metadata = this.state.metadata
        var isValid = this.validate(file,this.fileFacts.duration)
        if (isValid && this.props.parms.url) {
            this.setUploadingButtonState()
            let options = {
                type: 'POST',
                data: JSON.stringify({ filename: file.name }),
                xhrFields: {
                    'withCredentials': true
                },
                headers: {
                    'Authorization': Cookies.get('cb_login')
                },
                contentType: 'application/json',
                cache: false,
                dataType: 'json'
            }
            try {
                await bsliPostCSP(this.props.parms.url, {
                    filename: file.name
                }).then(data => {
                    //console.log('validateAndGetUploadMetaData DONE', data)
                    this.POSTdata = data
                    this.resetUploadingButtonState()
                    this.enableSubmit(true)
                })
            } catch (e) {
                // console.log('Error Message:'+ e);
                if (e.message ==='You have exceeded the upload limit. Please try again in a few minutes') {
                    this.uploadFailed(e, uploadCountLimitFailed);
                } else {
                    this.uploadFailed(e, metaDataFailed);
                }
            }
        } else {
            this.uploadFailed()
        }
    }

    uploadToS3() {
        //console.log('uploadToS3 POSTdata', this.POSTdata)
        this.setUploadingButtonState()
        this.enableSubmit(false)
        let file = this.fileFacts.file
        var blob = new Blob([file], { type: file.type })
        //console.log('uploadToS3 blob', blob)
        var url = this.POSTdata.signedS3Url
        var metadata = this.POSTdata.metadata

        if (!url) {
            this.uploadFailed()
            return
        }

        fetchRequest('PUT', url, {
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'x-amz-meta-payload': metadata
            },
            body: blob
        }, false).then(() => {
            this.startS3Polling()
        }).catch((err) => {
            this.uploadFailed(err);
        })
    }

    startS3Polling() {
        //console.log('startS3Polling starting')
        var url = addBaseUrlAndApiKey('sections/' + getCurrentSectionId() + '/assignments/' + this.props.parms.assignment.id + '/uploads/' + this.POSTdata.lambdaUploadId + '/status');
        fetchRequest('GET', url, {
            credentials: 'include'
        }).then((data) => {
            if (data.uploadStatusInfo.done) {
                this.uploadFinished()
             //   location.reload()
                refreshActivityFeed ()  
                refreshMenuBar()
            } else {
                //console.log('startS3Polling going to poll again')
                setTimeout(this.startS3Polling, 1000)
            }
        }).catch(response => {
            console.error('startS3Polling failed with ',response)
            this.uploadFailed(response)
        })
    }

    // this was done on modal close
    // reloadPage() {
    //     if (this.options.status === 405) {
    //         location.reload();
    //     }
    // }
}
