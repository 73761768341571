import { subject, updateSubscription } from "../../utility/service"
import { isArt3D, isArt_SW_Images, isArtSI, isArtSI_Images } from "../../entities/courseinstance"
import { AlertComp } from "../../_core/views/alert.jsx"
import ImageGalleryTeacher from "../../assignment/art/views/galleryTeacher"
import { parse } from "../../assignment/art/entities/downloadArt"
import Viewer from "../../progress/modal/viewer"
import { Tab, Tabs } from "@cb/apricot-react"

const SubmissionLayout = (props) => {
  const [alertProps, setAlertProps] = useState(null)
  const [helpers, setHelpers] = useState(null)

  const templateHelpers = () => {
    let cmsLesson = props.cmsLesson
    let guidelines = cmsLesson ? cmsLesson.scoringGuidelines : false
    let isArtSWImages = isArt_SW_Images(props.lesson.id)
    let isArtSIImages = isArtSI_Images(props.lesson.id)
    let isImages = isArtSWImages || isArtSIImages
    let showViewer = props.upload && props.upload.isPdf() && !isImages
    let showGallery = props.upload && props.lesson.title === "Images"
    let compData = parse(props.data)
    let galleryId = props.upload ? props.upload.id : 0
    let sectionId = props.scorableHolder.sectionId
    let uploadId = props.upload ? props.upload.id : 0
    let assignmentId = props.lesson.assignmentId
    let isArt_SI_Images = showGallery && isArtSI(assignmentId)
    let is3DArt = isArt3D()
    return {
      guidelines: guidelines,
      showViewer: showViewer,
      showGallery: isImages,
      galleryId: galleryId,
      compData: compData,
      sectionId: sectionId,
      uploadId: uploadId,
      assignmentId: assignmentId,
      isArt_SI_Images: isArt_SI_Images,
      is3DArt: is3DArt
    }
  }

  const showAlert = (message, uniqueId) => {
    setAlertProps({
      isDismissable: false,
      showAlert: true,
      message: message,
      uniqueId: uniqueId,
    })
  }

  const checkGuidelines = (cmsLesson, isPdfSubmission, isImages) => {
    if (!cmsLesson && !isPdfSubmission && !isImages) {
      showAlert("Error: Content not available.", "guidelines-missing")
    }
  }

  useEffect(() => {
    const { cmsLesson, upload } = props
    const isArtSWImages = isArt_SW_Images(props.lesson.id), isArtSIImages = isArtSI_Images(props.lesson.id)
    const isImages = isArtSWImages || isArtSIImages
    setHelpers(templateHelpers())

    checkGuidelines(cmsLesson, upload && upload.isPdf(), isImages)
    let subscription = subject.subscribe((message) => {
      if (message.alert) {
        showAlert(message.alert.message, message.alert.id)
      }
    })
    updateSubscription("alert", subscription)
  }, [])

  const showTabs = () => {
    let tabToShow = null
    if (helpers.showViewer && props.upload) tabToShow = (
      <Tab selected label='View Submission' panelId='submissionTab'>
        <div
          role="tabpanel"
          id="region-view-submission"
        >
          <Viewer upload={props.upload} />
        </div>
      </Tab>
    )
    else if (helpers.guidelines) tabToShow = (
      <Tab selected label='Guidelines and Samples' panelId='guidelinesTab'>
        <div
          role="tabpanel"
          id="region-guidelines"
        >
          <div dangerouslySetInnerHTML={{ __html: helpers.guidelines }} />
        </div>
      </Tab>
    )
    else if (helpers.showGallery) tabToShow = (
      <Tab selected label='View Gallery' panelId='galleryTab'>
        <div
          role="tabpanel"
          id="region-view-gallery"
        >
          <div id={"gallery" + helpers.galleryId} className="cb-margin-top-24">
            <ImageGalleryTeacher
              galleryId="gallerySubmission"
              sectionId={helpers.sectionId}
              assignmentId={helpers.assignmentId}
              uploadId={helpers.uploadId}
              isArt_SI_Images={helpers.isArt_SI_Images}
              is3DArt={helpers.is3DArt}
            />
          </div>
        </div>
      </Tab>
    )
    return tabToShow
  }

  if (helpers) {
    return (
      <>
        <div id="submissionLayout">
          <Tabs>
            {showTabs()}
          </Tabs>
        </div>
        <div className="alert-wrapper container-fluid add-top-margin-2x">
          {alertProps && alertProps.showAlert && (
            <AlertComp {...alertProps} />
          )}
        </div>
      </>
    )
  }
}

export default SubmissionLayout
