import React                                    from 'react'
import { Tabs, Tab }                            from '@cb/apricot-react'
import { ToggleButtonGroup, ToggleButton } from '@cb/apricot-react';
import ViewSubmission                           from './viewSubmission'
import ViewPlagiarismReport                     from './viewPlagiarismReport'
import { bsliPostScores }                       from '../../_core/util/request'
import { showLoadingView , closeLoadingView}    from '../../_core/views/loading_view'

const ReviewerComp = (props) => {
    const [selectedTab, setSelectedTab] = useState('plagiarism')

    const handleTabChange = (panelId) => {
        setSelectedTab(panelId)
    }

    return (
        <div id='reviewer-container'>
            <a href="#" className="sr-only wi-globalheader-skip">Skip to main content</a>
            <div id="notification-widget-hook" className="cb-apricot notification"></div>
            <main className="container">
                <ToggleButtonGroup
                    name="reviewerTab"
                    defaultValue={selectedTab}
                    ariaLabel="Select tab to see its search form"
                    onChange={handleTabChange}
                >
                    <ToggleButton value="plagiarism">PLAGIARISM</ToggleButton>
                    <ToggleButton value="submissions">SUBMISSIONS</ToggleButton>
                </ToggleButtonGroup>
                {selectedTab === 'plagiarism' ? (
                  <ViewPlagiarismReport {...props} />
                ) : (
                  <ViewSubmission {...props} />
                )}
            </main>
        </div>
    )
}

export default ReviewerComp
