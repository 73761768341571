import React, {useEffect, useState} from 'react'
import {deepClone, hasText, validateArtMetaKey} from '../../../utility/util'
import {AlertComp} from '../../../_core/views/alert.jsx'
import {ArtButtonBar} from './buttonBar'
import {upload} from '../entities/artAjax'
import {finalSubmitHandler, initSave} from '../entities/saveUtil'
import SubmitFinalModal from '../../views/submitFinalModal'
import {refreshActivityFeed} from '../../../utility/service'
import {subject, updateSubscription} from '../../../utility/service'
import moment from 'moment'
import {redirectUrls} from '../../../config/config'


export function ArtWrittenEvidenceComp({parms}) {
	// parms: saveUrl, finalDate, readOnly, data, finalSubmitInfo, scoringEnabled

	let data = deepClone(parms.data)
	if (!data) {
		data = {}
	}
	const [compData, setCompData] = useState(data)
	const [compDataOrig, setCompDataOrig] = useState(data)
	const [inquiryCnt, setInquiryCnt] = useState(data.inquiry ? data.inquiry.length : 0)
	const [evidenceCnt, setEvidenceCnt] = useState(data.evidence ? data.evidence.length : 0)
	const [canSave, setCanSave] = useState(false)
	const [canFinalSave, setCanFinalSave] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [isFinalSaving, setIsFinalSaving] = useState(false)
	const [alertProps, setAlertProps] = useState(false)
	const [inquiryTrimCnt, setInquiryTrimCnt] = useState(data.inquiry ? data.inquiry.trim().length : 0)
	const [evidenceTrimCnt, setEvidenceTrimCnt] = useState(data.evidence ? data.evidence.trim().length : 0)
	const [readOnly] = useState(parms.readOnly ? parms.readOnly : false)
	const [modalProps, setModalProps] = useState({modalOpen: false})
	const setFinalSubmitDate = () => parms.finalDate = moment(new Date()).format('MM/DD')
	let idRoot = 'Art_WE_'
	const [disabled, setDisabled] = useState(false)
	let subscription

	useEffect(() => {
		initSave(setCanSave, setIsSaving, setIsFinalSaving, parms.saveUrl, setModalProps)
		setCanFinalSave(isAllReady(compData))
		subscription = subject.subscribe(message => {
			if (message.refreshMenuBar) {
				setFinalSubmitDate()
				setDisabled(true)
			}
		})
		updateSubscription('refreshMenuBar', subscription)
	}, [])

	useEffect(() => {
		setCanSave(isDifferentFromOrig(compData, compDataOrig))
	}, [inquiryCnt, evidenceCnt])

	let buttonBar = (
		<ArtButtonBar
			save={onSave}
			isSaving={isSaving}
			isFinalSaving={isFinalSaving}
			finalDate={parms.finalDate}
			canSave={canSave} canFinalSave={evidenceTrimCnt > 0 && inquiryTrimCnt > 0}
			finalSubmit={onFinalSubmit}
			//downloadLatest={downloadLatest}
			scoringEnabled={parms.scoringEnabled}
		/>
	)

	const setCountAndData = (event, type) => {
		if (type === 'inquiry') {
			const inquiry = event.target.value
			setInquiryCnt(inquiry.length)
			setInquiryTrimCnt(inquiry.trim().length)
			let clone = deepClone(compData)
			clone[type] = inquiry
			setCompData(clone)
		} else {
			const evidence = event.target.value
			setEvidenceCnt(evidence.length)
			setEvidenceTrimCnt(evidence.trim().length)
			let clone = deepClone(compData)
			clone[type] = evidence
			setCompData(clone)
		}
	}

	return (
		<div id="Art_WrittenEvidence">
			<div className="alert-wrapper container-fluid">
				{alertProps && alertProps.showAlert &&
					<AlertComp params={alertProps}/>
				}
			</div>
			<div className="row">
				<div className="col-md-12 cb-input">
					<label htmlFor={idRoot + 'inquiry'}>
						1. Identify the inquiry that guided your sustained investigation.
						(Max. 600 characters and spaces) *
					</label>
					<textarea id={idRoot + 'inquiry'} rows="7" disabled={disabled || readOnly || isSaving}
							  defaultValue={compData.inquiry} data-type="inquiry"
							  maxLength="600" onKeyPress={validateArtMetaKey}
							  onChange={event => setCountAndData(event, 'inquiry')}
							  onBlur={onBlur}/>
					<div className="count">
						<span>{inquiryCnt + '/600'}</span>
						{inquiryCnt === 600 &&
							<span className="alert-info"> - You have reached the maximum allowable count of 600 characters and spaces. </span>
						}
						{inquiryCnt > 600 &&
							<span className="alert-danger"> - Your response to this question cannot exceed 600 characters and spaces. </span>
						}
					</div>
				</div>
				<div className="col-md-12 cb-input" style={{marginTop: '10px'}}>
					<label htmlFor={idRoot + 'evidence'}>
						2. Describe ways your sustained investigation developed through practice, experimentation, and
						revision.
						(Max. 600 characters and spaces) *
					</label>
					<textarea id={idRoot + 'evidence'} rows="7" disabled={disabled || readOnly || isSaving}
							  defaultValue={compData.evidence} data-type="evidence"
							  maxLength="600" onKeyPress={validateArtMetaKey}
							  onChange={(event) => setCountAndData(event, 'evidence')}
							  onBlur={onBlur}/>
					<div className="count">
						<span>{evidenceCnt + '/600'}</span>
						{evidenceCnt === 600 &&
							<span className="alert-info"> - You have reached the maximum allowable count of 600 characters and spaces. </span>
						}
						{evidenceCnt > 600 &&
							<span className="alert-danger"> - Your response to this question cannot exceed 600 characters and spaces. </span>
						}
					</div>
				</div>
			</div>
			{buttonBar}
			<SubmitFinalModal props={modalProps}/>
		</div>
	)

	function onSave(finalSave) {
		if (canSave) {
			setIsSaving(true)
			return new Promise((resolve, reject) => {
				try {
					upload(parms.saveUrl, compData, true, true)
						.then(() => {
							setIsSaving(false)
							let clone = deepClone(compData)
							setCompData(clone)
							setCompDataOrig(clone)
							resolve()
							refreshActivityFeed()
							setCanSave(false)
						})
						.catch(err => {
							//     console.log("saveHandler had an error err=" + err)
							let message = typeof err?.message === 'string' ? `Error: ${err.message}` : 'Error: This submission could not be saved.  Please try again.'
							setAlertProps({
								showAlert: true,
								message: message,
								uniqueId: 'writenEvidence-save'
							})
							setIsSaving(false)
							reject('upload had an Error')

						})
				} catch (e) {
					console.error('onSave had error', e)
					reject('Save had an Error')
					setIsSaving(false)
				}
			})
		} else {
			//   console.log("cant save or dont need to")
			return Promise.resolve()
		}
	}

	function onFinalSubmit() {
		setIsFinalSaving(true)
		onSave(true)
			.then(async () => {
				const modalProps = await finalSubmitHandler(parms.finalSubmitInfo)
				setModalProps(modalProps)
			})
			.catch(err => {
				//   console.log("onFinalSubmit had an error err=" + err)
				let message = typeof err?.message === 'string' ? `Error: ${err.message}` : 'Error: This submission could not be saved.  Please try again.'
				setAlertProps({
					showAlert: true,
					message: message,
					uniqueId: 'art-finalSubmit'
				})
				setIsFinalSaving(false)
			})
	}

	function onBlur(event) {
		let elem = event.target
		if (!!elem.dataset && !!elem.dataset.type) {
			let type = elem.dataset.type
			let value = elem.value
			let clone = deepClone(compData)
			clone[type] = value
			setCompData(clone)
		}
	}

	function isDifferentFromOrig(curr, orig) {
		if (curr && orig) {
			let result
			if (orig.inquiry === undefined && curr.inquiry === '' && orig.evidence === curr.evidence) result = false
			if (orig.evidence === undefined && curr.evidence === '' && orig.inquiry === curr.inquiry) result = false
			else result = orig.inquiry !== curr.inquiry || orig.evidence !== curr.evidence
			return result
		} else {
			//    console.log("isDifferentFromOrig called on null or undefined parameters")
			return false
		}
	}

	function isAllReady(data) {
		return data && hasText(data.inquiry) && hasText(data.evidence)
	}


}
