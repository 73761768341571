import React, { Fragment }                      from 'react';
import {Checkbox}                               from '@cb/apricot-react';
import StudentInfoComp                          from './studentInfo'
import { AssignmentInfoComp }                   from './assignmentInfo'
import AcceptanceComp                           from './acceptance'

export default class PlagiarismResultsComp extends React.Component {

    constructor(props) {
        // props has results
        super(props);
    }

    render() {
        let results = this.props.results
        return (
            <Fragment>
                {results &&
                    <Fragment>
                        <h5 className="cb-spacerv-48">Plagiarism Review</h5>
                        <div className="cb-input form-group">
                            <Checkbox label="*Student has appealed (must select before entering appeal results)"
                                onChange={(c, e) => this.props.updateStudentHasAppealed(!!c)}
                                checked={results.appeal.studentHasAppealed} disabled={results.isAcceptancePosted} />
                        </div>
                        <div id="searchResultsStudentInfo">
                            <StudentInfoComp results={results} />
                        </div>
                        <div id="searchResultsAssignmentInfo">
                            <AssignmentInfoComp results={results} />
                        </div>
                        <div id="acceptanceForm">
                            <AcceptanceComp results={results} updateAppeal={this.props.updateAppeal} />
                        </div>
                    </Fragment>
                }
            </Fragment>
        )
    }
}
