import {checkFileSize,checkMinFileSize} from "./artUtil";

let enabled = true
let checkFileType

export function init(parms) {
    checkFileType = parms.checkFileType
}

function initReadOnly() {}

export function dataTransfer(event,id) {
    return new Promise((resolve,reject) => {
        if (event.dataTransfer && event.dataTransfer.files.length > 0) {
            let file = event.dataTransfer.files[0]
            if (checkFileType(file)) {
                loadFromDesktop(file, id)
                .then(() => { resolve(file) })
            } else if (!checkFileSize(file)) {
                reject("Error: The file can\'t be larger than 4 MB. Please upload a file that is less than 4 MB.")
            } else if (!checkMinFileSize(file)) {
                reject('Error: The file can\'t be smaller than 5 KB. Please upload a file that is larger than 5 KB.')
            }
            else {
                reject('Error: Only the following file types are allowed: JPEG, PNG')
            }
        } else {
            console.error('drop did not have a file')
            reject('no file')
        }
    })
}

function getId(elem) {
    if ($(elem).hasClass('reorder-panel')) {
        return elem.dataset.id
    } else {
        return $(elem).parents('.reorder-panel')[0].dataset.id
    }
}
// drag..Panel handle dragging an image onto the card
export function dragStartPanel(event) {
    //event.preventDefault()
    let elem = $(event.target).parents()
    event.dataTransfer.setData('id', getId(event.target));

    // let $elem = $(event.target).parents('.card')
    // $elem.addClass('highlight');
}

export function dragOverPanel(event) {
    //console.log('dragOverPanel')
    event.preventDefault()
    // let $elem = $(event.target).parents('.card')
    // $elem.addClass('highlight');
}

export function dragLeavePanel(event) {

    // let $elem = $(event.target).parents('.card')
    // $elem.removeClass('highlight')
}

export function dragDropPanel(event) {
    event.preventDefault();
    let fromId = event.dataTransfer.getData('id');
    let toId = getId(event.target)
    return {fromId: fromId, toId: toId}

    // let $elem = $(event.target).parents('.card')
    // let id = $elem.attr('data-id')
    // if ($elem.hasClass('highlight')) {
    //     $elem.removeClass('highlight')
    //     if (event.dataTransfer && event.dataTransfer.files.length > 0) {
    //         let file = event.dataTransfer.files[0]
    //         if (checkFileType(file, id)) {
    //             loadFromDesktop(file, id);
    //         }
    //     } else {
    //         console.error('drop did not have a file')
    //     }
    // }
    //$('#app-region').triggerHandler('drop.wr')
    //return false;
}

export function loadFromDesktop(file, id) {
    return new Promise((resolve, reject)  => {
        if (enabled) {
            let selector = `.drop-image[id='${id}']`
            var reader = new FileReader();
            reader.onload = () => {
                let curSrc = $(selector).attr('src') ? $(selector).attr('src') : ''
                let newSrc = reader.result
                let curLen = curSrc.length
                let newLen = newSrc.length
                $(selector).attr('data-filename', file.name);
                // there is an issue with dropping the same image
                if (curLen === newLen) {
                    $(selector).attr('src', '');
                    setTimeout(() => {
                        $(selector).attr('src', newSrc);
                        resolve(id)
                    }, 100)
                } else {
                    $(selector).attr('src', newSrc);
                    resolve(id)
                }
            }
            return reader.readAsDataURL(file)
        } else {
            resolve(id)
        }
    })
}

function disable() {
    enabled = false
}
function enable() {
    enabled = true
}

export function getDragUtil() {
    return {
        init:                   init,
        initReadOnly:           initReadOnly,
        //loadFromSrc:            loadFromSrc,
        loadFromDesktop:        loadFromDesktop,
        disable:                disable,
        enable:                 enable
        // dragOverCard:           dragOverCard,
        // dragLeaveCard:          dragLeaveCard,
        //dropOnCard:             dropOnCard
    }
}

// dragging a div
//https://www.w3schools.com/howto/howto_js_draggable.asp
