const CSP_WR_Title = (props) => {
  return (
      <div className='indent3 title'>
        <table role="presentation">
        <tbody>
            <tr>
              <td className='tableTd1 cb-k-12-program'>{props.label}</td>
              <td>{props.title}</td>
            </tr>
        </tbody>
       </table>
      </div>
  )
}
export default CSP_WR_Title
