import { bsliGet } from '../_core/util/request'
import { getCourseInstanceId } from '../entities/courseinstance'

//export default function(Entities, app) {

let isWeModule = module => {
  return module.title.toLowerCase().indexOf('we service') >= 0
}

class ModuleHolder {
  constructor() {
    this.modules = []
    this.lessons = []
  }

  init(courseInstanceId) {
    return bsliGet('course-instances/' + courseInstanceId + '/modules')
      .then(json => {
        // json is an array of module info including their lessons
        this.parse(json)
        //console.log('ModuleHolder.init resolved ', this.modules)
        //return this
        return 'done'
      })
  }

  createByParams({ modules, lessons }) {
      this.modules = modules;
      this.lessons = lessons;
  }

  deepClone() {
    let copy = this.clone()
    // copy.lessons = JSON.parse(JSON.stringify(this.lessons))
    // copy.options = JSON.parse(JSON.stringify(this.options))
    return copy
  }

  parse(json) {
    this.lessons = _.flatten(_.pluck(json, 'lessons')) // all the lessons of all modules
    _.each(json, module => {
      module.title = module.title.replace('With We Service', 'with WE Service')
      module.abbreviation = module.abbreviation.replace('With We Service', 'with WE Service')
      this.modules.push(module)
    })
  }

  adjust() {
    this.lessons = _.flatten(this.getLessonsFromModules()) // all the lessons of all modules
    // this is redundant as it was already done before filtering
    _.each(this.modules, module => {
      module.title = module.title.replace('With We Service', 'with WE Service')
      module.abbreviation = module.abbreviation.replace('With We Service', 'with WE Service')
    })
  }

  lessonById(lessonId) {
    return _.findWhere(this.lessons, { id: lessonId })
  }

  isTeamLesson(lesson) { return lesson.isTeamLesson }

  hasTeamLessons() {
    return _.any(this.lessons, this.isTeamLesson)
  }

  anyLessonHasRubrics() {
    return _.any(this.lessons, _.property('hasRubricDimensions', true))
  }

  getLessons(teamOnly) {
    return teamOnly ? _.filter(this.lessons, this.isTeamLesson) : this.lessons
  }

  getLessonsFromModules() {
    return _.flatten(_.pluck(this.modules, 'lessons'))
  }

  getWeLessonIds() {
    let ids = []
    _.each(this.modules, module => {
      if (isWeModule(module)) {
        _.each(module.lessons, lesson => {
          ids.push(lesson.id)
        })
      }
    })
    return ids
  }

  getModuleById(moduleId) {
    return _.findWhere(this.modules, { id: moduleId })
  }

  getModuleByLessonId(lessonId) {
    return _.find(this.modules, module => {
      return _.findWhere(module.lessons, { id: lessonId })
    })
  }

  getTeamModules() {
    return _.filter(this.modules, module => _.any(module.lessons, this.isTeamLesson))
  }
}

// retrieving a fresh promise/fetch when the course changed works in all cases
var lastCourseRequestId
var lastModuleHolder

var getModulesHolderFor = function (courseInstanceId) {
  if (courseInstanceId !== lastCourseRequestId || !lastModuleHolder) {
    //console.log('returning modules from NEW REST call')
    let moduleHolder = new ModuleHolder()
    return moduleHolder.init(courseInstanceId)
      .then(() => {
        lastCourseRequestId = courseInstanceId
        lastModuleHolder = moduleHolder
        return moduleHolder
      })
      .catch(() => {
        return 'modules did not load'
      })
  } else {
    //console.log('returning modules from PREVIOUS REST call')
    return new Promise((resolve) => resolve(lastModuleHolder))
  }
}

export function getModuleHolderForReviewer(params) {
  let moduleHolder = new ModuleHolder()
  moduleHolder.createByParams(params)
  return moduleHolder
}

// returns ModuleHolder
export function getModuleHolder(courseInstanceId) {
  let courseId = courseInstanceId || getCourseInstanceId()
  //console.log('returning modules for course ' + courseId)
  return getModulesHolderFor(courseId)
    .then(moduleHolder => {
      return moduleHolder
    })
}

// filters the given ModuleHolder
export function filterModules(moduleHolder, WE) {
  let filteredHolder = new ModuleHolder()
  _.each(moduleHolder.modules, module => {
    let isWE = isWeModule(module)
    if (WE ? isWE : !isWE) {
      filteredHolder.modules.push(_.clone(module))
    }
  })
  filteredHolder.lessons = _.flatten(filteredHolder.getLessonsFromModules()) // all the lessons of all modules
  // console.log(filteredHolder)
  return filteredHolder
}
