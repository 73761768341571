import { getCurrentPerson } from '../../entities/person.jsx'
import {
  getCourseParameter,
  getCurrentSectionId,
  getSection,
  getWeCourseParameter,
  getWeServiceCourseInstance,
  isAfricanAmericanStudies,
  isAfterHardEndDate,
  isAfterSoftEndDate,
  isResearch,
  isResearch_PREP,
  isScorableAssignment,
  isSeminar,
  isSeminar_IRR_CP,
  isSeminar_IWA_CP,
} from '../../entities/courseinstance'
import { setSectionId } from '../../entities//course'
import { getIconClass } from '../../utility/util'
import { getButtonText, isSectionExamOnly } from '../entities/progress'
import { scorableStatus } from '../../assignment/entities/scorable'

export function serializeData(parms, scorableHolder) {
   // props.parms: studentData, scorableHolder, team, isWe, sectionId, lesson, toolKey, index
  let { studentData, team, isWe, sectionId, lesson, toolKey, index } = parms

  // lesson could be an instance of lessonAndAssignmentCombo (see progress.js) or it could be just a lesson from modules
  // if it's the former, it has the method isDocument used below

  let isTeacher = getCurrentPerson().isTeacher()
  let isReviewer = getCurrentPerson().isReviewer()
  let isCoordinator = getCurrentPerson().isCoordinator()
  if ((!team && lesson.isTeamLesson) || !scorableHolder) {
    //console.log('not assigned to a team, or no scorableHolder')
    return null
  }

  let sectId
  if (isTeacher) {
    sectId = getCurrentSectionId()
  } else {
    if (getCurrentPerson().isCoordinator() && sectionId) {
      setSectionId(sectionId)
    }
    sectId = sectionId || getCurrentSectionId()
  }
  let section = getSection(sectId)
  let isPastSoftDate, isArchived, hasExtension
  if (section) {
    isPastSoftDate = isWe ? section.weCoursePastSoftEndDate : section.pastSoftEndDate
    isArchived = section.isArchived
    hasExtension = section.hasExtension
  } else {
    isPastSoftDate = false
    isArchived = false
    hasExtension = false
  }
  let readOnly
  if (lesson.isTeamLesson) {
    let teamExtension = true
    team.enrollment.forEach(e => {
      teamExtension = e.hasExtension === false ? false : teamExtension
    })
    readOnly = isArchived || (isPastSoftDate && !hasExtension) ? !teamExtension : false
  } else if (studentData) {
    readOnly = isArchived || (isPastSoftDate && !hasExtension) ? !studentData.hasExtension : false
  }  else {
    readOnly = isArchived || (isPastSoftDate && !hasExtension)
  }

  if ((isTeacher || isCoordinator) && isScorableAssignment(lesson?.assignmentId?.toString()) && !isAfterHardEndDate()) {
    readOnly = false
  }

  hasExtension = hasExtension || studentData?.hasExtension

  let disableScoring = false
  if ((isTeacher || isCoordinator) && section) {
    if (isSeminar() || isResearch()) {
      if (isAfterHardEndDate(isWe) && !hasExtension) {
        disableScoring = true
      }
    } else if (isWe) {
      const weService = getWeServiceCourseInstance()
      if (weService?.readOnly || weService?.gracePeriod) {
        disableScoring = true
      }
    } else if (isAfricanAmericanStudies() && isAfterSoftEndDate(isWe) && !hasExtension) {
      disableScoring = true
    } else if (isAfterHardEndDate(isWe) && (isAfricanAmericanStudies() && !hasExtension)) {
      disableScoring = true
    }
  }

  //the isEnrollmentCoordinatorForSection will throw a type error for non coordinators
  let enrollmentCoordinator = getCurrentPerson().isCoordinator() ? getCurrentPerson().isEnrollmentCoordinatorForSection(sectId) : false

  let scoringEnabled
  let noResponseAvailable
  if (isWe) {
    scoringEnabled = getWeCourseParameter('scoringEnabled')
    noResponseAvailable = getWeCourseParameter('noResponseAvailable')
  } else {
    scoringEnabled = getCourseParameter('scoringEnabled')
    noResponseAvailable = getCourseParameter('noResponseAvailable')
  }
  //teachers and coordinators who are allowed have scoring access
  let scoringAccess = (enrollmentCoordinator && studentData.scoreFromSameSection) || isTeacher
  let progressNotInReadOnlyMode = !readOnly && scoringEnabled
  let isIndividualInTeam = lesson.isTeamLesson && !!studentData
  let isNonParticipant = studentData && scorableHolder.isNonParticipant(studentData.dpPersonId) && !scorableHolder.isNoResponse()
  let studentName = studentData && studentData.fullName
  let noResponseIsActionable = noResponseAvailable && (isIndividualInTeam || !((lesson.isDocument && scorableHolder.isReadyToScore()) || scorableHolder.isScored()))

  const showNoResponseForSemAndRes = !getCurrentPerson().isStudent() ? isResearch_PREP(lesson?.assignmentId?.toString()) || isSeminar_IWA_CP(lesson?.assignmentId?.toString()) || isSeminar_IRR_CP(lesson?.assignmentId.toString()) : false

  return {
    lessonTitle: lesson.title,
    name: lesson.isTeamLesson ? team.title : studentName,
    showNoResponseButton: scoringAccess && progressNotInReadOnlyMode && !(scorableHolder.isNoResponse() || isNonParticipant) && noResponseIsActionable && !showNoResponseForSemAndRes,
    showUndoNoResponseButton: scoringAccess && progressNotInReadOnlyMode && ((scorableHolder.isNoResponse() && !isIndividualInTeam) || isNonParticipant) && !showNoResponseForSemAndRes,
    scoreButtonText: scoringAccess && !isNonParticipant && getScoreButtonText(parms.lesson, scorableHolder, readOnly),
    noResponseText: getNoResponseText(parms),
    display: getDisplayData(parms, scorableHolder, readOnly),
    isWe,
    scoringEnabled,
    isTeacher,
    isReviewer,
    isCoordinator,
    showDraftInScoring: (isTeacher || isCoordinator && isSectionExamOnly(sectionId)) && getDisplayData(parms, scorableHolder, readOnly).text === 'Draft In',
    isSectionExamOnly: isSectionExamOnly(sectionId),
    disableScoreButtonForResAndSemOnly: true,
    disableScoring,
  }
}

function getScoreButtonText(lesson, scorableHolder, readOnly) {
  var hasRubrics = lesson.hasRubricDimensions,
    scored = scorableHolder.isScored(),
    isReadyToScore = scorableHolder.isReadyToScore(),
    suffix = lesson.isTeamLesson ? ' Team' : '',
    buttonType

  if (!(scored || isReadyToScore)) return

  if (readOnly) {
    if (hasRubrics) {
      buttonType = 'progressCompleteReadOnlyText'
    } else {
      buttonType = 'progressIncompleteReadOnlyText'
    }
  } else {
    if (!hasRubrics) {
      buttonType = 'progressIncompleteReadOnlyText'
    } else if (!scored) {
      buttonType = 'progressActionType'
    } else {
      buttonType = 'progressCompleteActionText'
    }
  }
  return getButtonText(lesson.assignmentId, buttonType) + suffix
}

export function getNoResponseText(parms) {
  return parms.isWe ? 'Not Participating' : 'No Response'
}

function display(status, label, isWe, noResponseText) {
  let statusText = {
    NoDraft: 'No Draft',
    DraftIn: 'Draft In',
    AttestationIncomplete: 'Draft In',
    ReadyToScore: 'Ready to Score',
    FinalSubmitted: 'Complete',
    Scored: label + 'd',
    NoResponse: noResponseText,
    SubmissionConfirmed: 'Submission Confirmed'
  }

  let icon
  if (status === 'NoResponse') {
    if (isWe) {
      icon = getIconClass('NoResponseWe')
    } else {
      icon = getIconClass('NoResponse')
    }
  } else {
    icon = getIconClass(status)
  }
  //console.log('display status ' + status + ', icon ' + icon)

  let text = statusText[status]
  if (text) {
    return { 'icon': icon, 'text': text }
  } else {
    //console.log('no status icon for status ' + status)
    return { icon: '', text: 'none' }
  }
}

function getDisplayData(parms, scorableHolder, readOnly) {
  var { studentData } = parms
  var personId = studentData && studentData.dpPersonId
  // scorableStatus() returns an enum with entries like NoDraft: 1, calling invert makes it 1: 'NoDraft'
  var statuses = _.invert(scorableStatus())

  var status = statuses[scorableHolder.scorable.status], // would be 'NoDraft' if status=1
    hasRubrics = parms.lesson.hasRubricDimensions,
    scored = scorableHolder.isScored()

  if (scorableHolder.isNonParticipant(personId) || scorableHolder.isNoResponse()) {
    status = 'NoResponse'
  } else if (scorableHolder.isAttestationIncomplete() && scorableHolder.hasBeenSubmittedByPerson(personId)) {
    status = 'SubmissionConfirmed'
  } else if (hasRubrics) {
    status = scored ? 'Scored' : 'ReadyToScore'
  }

  let id = parms.lesson.assignmentId
  let btnText = id ? getButtonText(id, 'progressActionType') : 'button text'
  return display(status, btnText, parms.isWe, getNoResponseText(parms))
}
