import React, { Fragment } from 'react';
import HeaderComp           from './header'
import FooterComp           from './footer'
import TitleComp            from './title.jsx'
import ScoringMenuComp      from './scoringMenu.jsx'
import SubmissionLayout     from './submissionLayout'
import RubricLayoutComp      from './rubricLayout'

// called from scoring_module
export default class ScoringLayoutComp extends React.Component {
    constructor(props) {
        super(props);
        //console.log('ScoringLayoutComp props',props)
        // props.parms has url, lesson, cmsLesson, upload, rubricHolder, scorable, scoree, scoreHolder, scorableHolder, isWe
        // upload is Upload class from upload.js
        // scoree: Enrollment from enrollment/entities/enrollment
    }

    componentDidMount() {
        //console.log('ScoringLayoutComp didMount parms',this.props.parms)
    }

    render() {
        return (
            <React.Fragment>
                <div id='region-modal'></div>
                <header id="region-header">
                    <HeaderComp
                        lesson={this.props.parms.lesson} rubricHolder={this.props.parms.rubricHolder}
                        isWe={this.props.parms.isWe}>
                    </HeaderComp>
                </header>
                <div id="notification-widget-hook" className="cb-apricot notification"></div>
                <div className="container-fluid">
                    <div className="row add-top-margin-3x">
                        <div className="col-md-6" id="region-title">
                            <TitleComp parms={this.props.parms}></TitleComp>
                        </div>
                        <div className="col-md-6 flex-nowrap" id="region-utility">
                            <ScoringMenuComp parms={this.props.parms}></ScoringMenuComp>
                        </div>
                    </div>
                    <div className="row add-top-margin-3x">
                        <div className="col-md-8 col-sm-8" id="region-submission">
                            <SubmissionLayout parms={this.props.parms}></SubmissionLayout>
                        </div>
                        <div className="col-md-4 col-sm-4" id="region-rubric">
                            <RubricLayoutComp parms={this.props.parms}></RubricLayoutComp>
                        </div>
                    </div>
                </div>
                <footer id="region-footer">
                    <FooterComp></FooterComp>
                </footer>
            </React.Fragment>
        )
    }
}
