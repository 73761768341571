export const AlertComp = ({ params }) => {
  const [defaults, setDefaults] = useState({
    message: '',
    isDismissable: false,
    uniqueId: '',
    className: 'alert alert-danger',
    dismissAction: () => {
    },
  })

  // if props is false or message is empty, no alert is displayed
  let alert = _.extend({}, defaults, params || {})

  let isDismissable = alert.isDismissable
  let uniqueId = alert.uniqueId
  let message = alert.message
  let className = alert.className
  let dismissAction = alert.dismissAction

  let msg = _.isString(message) ? message : JSON.stringify(message)
  let type = 'text'
  let htmlIndicator = '<!doctype html>'
  if (msg && msg.toLowerCase().startsWith(htmlIndicator)) {
    msg = msg.substr(htmlIndicator.length)
    type = 'html'
  }

  return (
    <div className='alert-wrapper' aria-live='polite'>
      {msg &&
        <div className={className}>
          {isDismissable &&
            <button type='button' className='cb-btn cb-btn-sm cb-btn-square cb-btn-greyscale cb-btn-close'
                    onClick={dismissAction} aria-label='Close'>
              <span className='cb-glyph cb-x-mark'></span>
              <span className='sr-only'>Close Window</span>
            </button>
          }
          <span id='alertLabel' className='sr-only'>An Alert has appeared on the page</span>
          {type === 'html' &&
            <div dangerouslySetInnerHTML={{ __html: msg }} />
          }
          {type === 'text' &&
            <p id={uniqueId}>{msg}</p>
          }
        </div>
      }
    </div>
  )
}

export function staticCallback(context, html) {
  if (context.isFunctional) {
    if (context.state !== html) {
      context.setState(html)
    }
  } else {
    context.setState(state => {
      if (state.staticHtml === html) {
        //console.log('staticCallback no change')
      } else {
        return {
          staticHtml: html,
        }
      }
    })
  }
}

export function errorCallback(context, message) {
  if (context.isFunctional) {
    if (context.alertProps && context.alertProps.message === message) {
      context.setAlertProps(message)
    }
  } else {
    context.setState(state => {
      if (state.alertProps && state.alertProps.message === message) {
        //console.log('errorCallback no change')
      } else {
        return {
          alertProps: {
            message: message,
          },
        }
      }
    })
  }
}

export function alertGeneralErrorOnModal(error, modal, isDismissable) {
  //console.log('alertGeneralError called')
  let alertProps = {
    isDismissable: isDismissable,
    uniqueId: 'general-alert-' + error.id,
    message: error.message,
    className: 'alert alert-danger' + (isDismissable ? ' alert-dismissable' : ''),
  }
  modal.addErrorMessageProps(alertProps)
}

// formerly app.execute('alert:general:error', { message: message, id: id }, view)
export function alertGeneralError(error, view, isDismissable) {
  //console.log('alertGeneralError called')
  var errorContainer = view.ui.errorContainer.length ? view.ui.errorContainer : view.$el.find(view.ui.errorContainer.selector)
  let alertProps = {
    isDismissable: isDismissable,
    uniqueId: 'general-alert-' + error.id,
    message: error.message,
    className: 'alert alert-danger' + (isDismissable ? ' alert-dismissable' : ''),
  }
}

export function showError(view, id, statuses, defaultMessage) {
  return function(a, b) {
    var xhr = a.status ? a : b
    if (xhr && xhr.status) {
      var message = _.contains(statuses, xhr.status) ? xhr.responseText : defaultMessage
      alertGeneralError({ message: message, id: id }, view)
    } else {
      alertGeneralError({ message: message, id: id }, view)
    }
  }
}
