import { BlackButton, Modal, PrimaryButton, YellowButton } from "@cb/apricot-react";
import { AppContext } from "../../context";
import { Link } from 'react-router-dom'

const LocationChanged = () => {
  const ContextChild = value => {
    let link = ''

    if (value.destinationLink?.length) {
      link = value.destinationLink.replace(location.origin, '')
    }

    const closeModal = () => {
      value.updateClickEvent(false)
      value.updateHasUnsavedWork(false)
      value.setDestinationLink('')
    }

    const Footer = () => (
      <div className='display-flex justify-content-end'>
        <BlackButton className='cb-margin-right-16' onClick={closeModal}><Link to={link} style={{ textDecoration: 'none' }} className='cb-black1-color'>Leave this Page</Link></BlackButton>
        <YellowButton onClick={closeModal}>Stay on this Page</YellowButton>
      </div>
    )

    if (value.clickEvent && value.hasUnsavedWork) {
      return (
        <Modal
          open={value.clickEvent}
          title='Unsaved Work'
          onClose={closeModal}
          escClose
          footer={<Footer />}
        >
          <div>
            <p>You have changes on this page that have not been saved. Are you sure you want to leave without saving your work?</p>
          </div>
        </Modal>
      )
    } else return null
  }

  return (
    <AppContext.Consumer>
      {ContextChild}
    </AppContext.Consumer>
  )
}

LocationChanged.propTypes = {
  location: PropTypes.object
}

export default LocationChanged