import React from 'react';
import { reactRender }            from '../utility/util'

import ReviewerComp from './views/reviewer'
import DeniedComp from './views/denied'
import {getCurrentPerson} from '../entities/person.jsx'
import { setAppRegionContent } from '../main/instruction_controller'

export function showReviewerDashboard() {
	var isReviewer = getCurrentPerson().isReviewer();
			let layout;
			if (isReviewer) {
				let student = {
					apNumber: null,
					courseOptions: [],
					lessonOptions: [],
					courses: [],
					selectedCourse: '',
					selectedLesson: '',
					error: null
				}
				let props = {
					'student': student,
					'studentDefault': _.clone(student)
				}

				layout = <ReviewerComp parms={props}/>
			} else {
				layout = <DeniedComp/>
			}

            reactRender(layout, 'app-region')
			document.title = 'Plagiarism Dashboard | Digital Portfolio | The College Board'
			setAppRegionContent('reviewerLayout')
			document.documentElement.lang = 'en'

}