const CSP_WR_Text = (props) => {
  const frozenCls = props.frozen ? '' : 'hidden'
  const frozenMsg = 'You have reached the maximum allowable word count of 850 words'
  const exceedCls = props.wouldExceed ? '' : 'hidden'
  const exceedMsg = 'Your response cannot exceed 850 words'
  const wordCntCls = 'wordCnt cb-k-12-program'
  const entry = props.wordCounts.find(item => item.sectionAndIndex === props.id);
  const wordCnt = entry ? entry.wordCnt : 0
  const wordCntMsg = wordCnt + ' words'

  return (
      <div data-id={`dropzone${props.id}`} className='text indent4 drop-zone'>
          <div>
              <div className={wordCntCls}>
                <span>{wordCntMsg}</span>
              </div>
              <div className="quill text"
                  data-id={props.id}
                  id={`div_text_${props.id}`}>
              </div>
              <div><span className={`warning ${frozenCls}`} aria-live='polite'>{frozenMsg}</span></div>
              <div><span className={`warning ${exceedCls}`} aria-live='polite'>{exceedMsg}</span></div>
          </div>
      </div>
  )
}

export default CSP_WR_Text
