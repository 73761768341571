import { isNullish, deepClone, hasText } from '../../../utility/util'

// Function to check if section is an array or not
export const isSectionArray = arr => Array.isArray(arr)

// meaning:
// the data is an array of sections or an array of arrays for each section (Art 3D course)
// key never changes, it helps react know what to update
// id is changed when you reorder, it tells the order of the image cards
// meta contains information about the id, height, width, materials...
// img is a structure {bytes,fileName}
// hasImage indicates whether the section has a non-empty image

// section will be sent to backend iff it is different from the one sent from the backend
// that way we can erase a previously saved image
// metadata cannot be entered if there is no image, however, if you delete the image the metadata remains
const useImages = () => {
    const getEmptyData = (type, is3D) => {
        let arr  = []
        let numSection = 15
        if (type && type==='SW') {
            numSection = 5
        }
        for (let i=0; i<numSection; i++) {
            arr.push(createNewSection(i,i+1, is3D && type === 'SW'))
        }
        return arr
    }

    const sectionEqual = (a,b) => {
        const isEqual = (a, b) => {
            if (imageUrl(a).length !== imageUrl(b).length) {
                return false
            }
            // substr does not complain if the indicies are out of range
            if (imageUrl(a).substr(0,10) !== imageUrl(b).substr(0,10)) {
                return false
            }
            if (imageFilename(a) !== imageFilename(b)) {
                return false
            }
            if (imageHeight(a) !== imageHeight(b)) {
                return false
            }
            if (imageDepth(a) !== imageDepth(b)) {
                return false
            }
            if (imageWidth(a) !== imageWidth(b)) {
                return false
            }
            if (imageMaterials(a) !== imageMaterials(b)) {
                return false
            }
            if (imageProcesses(a) !== imageProcesses(b)) {
                return false
            }
            if (imageCitations(a) !== imageCitations(b)) {
                return false
            }
            if (imageIdeas(a) !== imageIdeas(b)) {
                return false
            }
            return true
        }
        if (isSectionArray(a) && isSectionArray(b)) {
            let answer;
            for (let i = 0; i < a.length; i++) {
                answer = isEqual(a[i], b[i])
                if (!answer) break;
            }
            return answer
        } else return isEqual(a, b)
    }

    const isDifferentFromOrig = (curr,orig) => {
        if (curr && orig) {
            if (curr.length !== orig.length) {
                console.error('isDifferentFromOrig had different lengths')
                return true
            }  else {
                for (let i=0; i<orig.length; i++) {
                    if (!sectionEqual(orig[i],curr[i])) {
                        return true
                    }
                }
                return false
            }
        } else {
            return false
        }
    }



    const getDataToSave = (curr, orig) => {
        let changed = []
        if (curr.length !== orig.length) {
            console.error('getDataToSave had different lengths')
        } else {
            for (let i = 0; i < orig.length; i++) {
                if (!sectionEqual(orig[i], curr[i])) {
                    changed.push(curr[i])
                } else {
                    //console.log('section ' + i + ' has NOT changed')
                }
            }
        }
        return changed
    }

    const eraseFileObjects = (compData) => {
        let clone = getClone(compData)
        clone.forEach(c => {
            if (isSectionArray(c)) {
                imageFile(c[0], {})
                imageFile(c[1], {})
            } else imageFile(c, {})
        })
        return clone
    }

    const isAllReady = (sections, hasIdeas, is3dart) => {
        return  !is3dart ?  sections.every( section => section.hasImage &&
          hasText(section.meta.height) &&
          hasText(section.meta.width) &&
          hasText(section.meta.materials) &&
          hasText(section.meta.processes) &&
          (!hasIdeas || hasText(section.meta.ideas))
        ) :  sections.every( section => {
            let ready = Array.isArray(section) ?  section.every ( subsection => subsection.hasImage &&
                hasText(subsection.meta.height) &&
                hasText(subsection.meta.width) &&
                hasText(subsection.meta.depth)&&
                hasText(subsection.meta.materials) &&
                hasText(subsection.meta.processes) &&
                (!hasIdeas || hasText(subsection.meta.ideas))) :
              section.hasImage &&
              hasText(section.meta.height) &&
              hasText(section.meta.width) &&
              hasText(section.meta.depth)&&
              hasText(section.meta.materials) &&
              hasText(section.meta.processes) &&
              (!hasIdeas || hasText(section.meta.ideas))
            return ready
        })


    }

    const imageUrl = (section,str, index = null) => {
        if (isNullish(str) || !_.isString(str)) {
            if (isSectionArray(section)) return section.map(s => s.img.url)
            else return section.img.url
        } else {
            if (str.startsWith('data:image')) {
                //  console.log('imageUrl is a data Url')
            }
            if (!!index && isSectionArray(section)) section[parseInt(index)].img.url = str
            else section.img.url = str
            return setImageBools(section)
        }
    }

    const imageFilename = (section,str, index = null) => {
        if (isNullish(str) || !_.isString(str)) {
            if (isSectionArray(section)) return section.map(s => s.img.fileName)
            else return section.img.fileName
        } else {
            if (index && isSectionArray(section)) section[parseInt(index)].img.fileName = str
            else section.img.fileName = str
            return setImageBools(section)
        }
    }

    const imageFile = (section,file, index = null) => {
        if (isNullish(file)) {
            if (isSectionArray(section)) section.map(s => s.file)
            else return section.file
        } else {
            if (index && isSectionArray(section)) section[parseInt(index)].file = file
            else section.file = file
            setImageBools(section)
        }
    }

    const imageDocumentId = (section,uploadSubDocumentId, index = null) => {
        if (isNullish(uploadSubDocumentId)) {
            if (isSectionArray(section)) section.map(s => s.uploadSubDocumentId)
            else return section.uploadSubDocumentId
        } else {
            if (index && isSectionArray(section)) section[parseInt(index)].uploadSubDocumentId = uploadSubDocumentId
            else section.uploadSubDocumentId = uploadSubDocumentId
            setImageBools(section)
        }
    }

    const imageUploadId = (section,uploadId, index = null) => {
        if (isNullish(uploadId)) {
            if (isSectionArray(section)) return section.map(s => s.uploadId)
            else return section.uploadId
        } else {
            if (index && isSectionArray(section)) section[parseInt(index)].uploadId = uploadId
            else section.uploadId = uploadId
        }
    }

    const imageUploadError = (section,uploadError, index = null) => {
        if (isNullish(uploadError)) {
            if (isSectionArray(section)) section.map(s => s.uploadError)
            else return section.uploadError
        } else {
            if (index && isSectionArray(section)) section[parseInt(index)].uploadError = uploadError
            else section.uploadError = uploadError
        }
    }

    let metaNames = ['id', 'height', 'width', 'ideas', 'materials', 'processes', 'citations', 'depth']

    const imageMeta = (section,type, value) => {
        const addMeta = (image, type, value, index = null) => {
            if (isNullish(value) || !(_.isString(value) || _.isNumber(value))) {
                return image.meta[type]
            } else {
                if (type === 'id' && typeof index === 'number') {
                    value = !/[^\w\s]/.test(value) ? `${value}.${index+1}` : `${value}`
                }
                image.meta[type] = value
                setImageBools(image)
            }
        }
        if (isSectionArray(section)) {
            return section.map((s, index) => (addMeta(s, type, value, index)))
        }
        else {
            return addMeta(section, type, value)
        }
    }

    const imageId =          (section,value) =>    { return imageMeta(section,metaNames.find(m => m === 'id'),value) }
    const imageHeight =      (section,value) =>    { return imageMeta(section,metaNames.find(m => m === 'height'),value) }
    const imageWidth =       (section,value) =>    { return imageMeta(section,metaNames.find(m => m === 'width'),value) }
    const imageIdeas =       (section,value) =>    { return imageMeta(section,metaNames.find(m => m === 'ideas'),value) }
    const imageMaterials =   (section,value) =>    { return imageMeta(section,metaNames.find(m => m === 'materials'),value) }
    const imageProcesses =   (section,value) =>    { return imageMeta(section,metaNames.find(m => m === 'processes'),value) }
    const imageCitations =   (section,value) =>    { return imageMeta(section,metaNames.find(m => m === 'citations'),value) }
    const imageDepth =       (section,value) =>    { return imageMeta(section,metaNames.find(m => m === 'depth'),value) }

    const createNewSection = (key, id, is3D) => {
        let section = is3D ?
          [{ key, hasImage: false, meta: {}, img: {} }, { key, hasImage: false, meta: {}, img: {} }]
          : { key, hasImage: false, meta: {}, img: {} }
        imageHeight(section, '')
        imageWidth(section, '')
        imageId(section, id)
        imageIdeas(section, '')
        imageMaterials(section, '')
        imageProcesses(section, '')
        imageCitations(section, '')
        imageDepth(section, '')
        if (is3D) {
            imageUrl(section, '', '0')
            imageUrl(section, '', '1')
            imageFilename(section, '', '0')
            imageFilename(section, '', '1')
            imageFile(section, {}, '0')
            imageFile(section, {}, '1')
        } else {
            imageUrl(section, '')
            imageFilename(section, '')
            imageFile(section, {})
        }
        setImageBools(section)
        return section
    }

    // called to erase a card so that save will send a 'D' action
    const deleteSection = (section) => {
        const callFns = (image) => {
            imageHeight(image, '')
            imageWidth(image, '')
            imageDepth(image, '')
            imageIdeas(image, '')
            imageMaterials(image, '')
            imageProcesses(image, '')
            imageCitations(image, '')
            imageUrl(image, '')
            imageFilename(image, '')
            imageFile(image, {})
            // imageDocumentId(section,'') dont blank this or isUpdate will be false and 'D' wont be sent
            // imageUploadId(section,'')
            imageUploadError(image,'')
            setImageBools(image)
        }
        if (isSectionArray(section)) section.map(val => callFns(val))
        else callFns(section)
    }

    const isEmpty = (section) => {
        const isImageEmpty = (image) => (
          imageHeight(image)  === '' &&
          imageWidth(image) === '' &&
          imageIdeas(image) === '' &&
          imageDepth(image) === '' &&
          imageMaterials(image) === '' &&
          imageProcesses(image) === '' &&
            imageCitations(image) === '' &&
          imageUrl(image) === '' &&
          imageFilename(image) === '' &&
          _.isEmpty(imageFile(image))
        )
        if (isSectionArray(section)) section.map(val => isImageEmpty(val))
        else isImageEmpty(image)
    }

    const blankIfNullish = (str) => {
        return isNullish(str) ? '' : str
    }

    const setImageBools = (section) => {
        const setBoolsForImage = (image) => {
            if (!!image) {
                // blank is false
                image.hasImage = !!image.img.url
                // section.hasThumbnail = !!section.img.thumbnail
            }
        }
        if (isSectionArray(section)) section.map(val => setBoolsForImage(val))
        else setBoolsForImage(section)
    }

    const getClone = (compData) => {
        let clone = deepClone(compData)
        compData.map((section,index) => {
            if (isSectionArray(section)) {
                section.map((s, secInd) => {
                    clone[index][secInd].file = s.file
                })
            } else clone[index].file = section.file
        })
        //console.log('getClone',clone)
        return clone
    }

    const imageReport = (compData) => {
        let report = 'These sections have images ['
        _.each(compData, (section,index) => {
            if (section.hasImage) {
                report = report + ' (' + index + ', ' + imageUrl(section).length + ')'
            }
        })
        return report + ']'
    }

    // finds the section to update or null
    // item is an object that has property documentIndex
    const findSection = (item,sections) => {
        if (!!sections && sections.length > 0 && !!item) {
            try {
                let id = sections.some(s => isSectionArray(s)) ? parseFloat(item.documentIndex) : parseInt(item.documentIndex)
                if (id) {
                    let section = sections.find(section => {
                        if (isSectionArray(section)) {
                            const img = section.find(s => parseFloat(s.meta.id) === id)
                            return id === parseFloat(img?.meta?.id)
                        } else {
                            return id === parseInt(section?.meta?.id)
                        }
                    })
                    return !!section ? section : null
                } else {
                    console.info('id was out of range ' + id)
                }
            } catch(e) {
                console.error('findSection had error',e)
            }
        } else {
            console.error('findSection failed')
        }
        return null
    }

    // item is a section from the downloadArt response
    const itemToSection = (item,sections) => {
        let section = findSection(item, sections), index
        if (isSectionArray(section)) {
            const origSection = section
            section = origSection.find(s => s.meta.id === item.documentIndex)
            index = origSection.indexOf(section)
        }
        if (!!section) {
            try {
                imageHeight(section,        blankIfNullish(item.documentHeight))
                imageWidth(section,         blankIfNullish(item.documentWidth))
                imageDepth(section,         blankIfNullish(item.documentDepth))
                imageId(section,            blankIfNullish(item.documentIndex))
                imageIdeas(section,         blankIfNullish(item.documentIdeas))
                imageMaterials(section,     blankIfNullish(item.documentMaterial))
                imageProcesses(section,     blankIfNullish(item.documentProcess))
                imageCitations(section,     blankIfNullish(item.citation))
                imageUrl(section,           blankIfNullish(item.downloadUrl), index)
                imageFilename(section,      blankIfNullish(item.fileName), index)
                imageDocumentId(section,    blankIfNullish(item.uploadSubDocumentId), index)
                imageUploadId(section,      blankIfNullish(item.uploadId), index)
                imageUploadError(section,   '', index)
                imageFile(section,          {}, index)
                setImageBools(section)
            } catch (e) {
                console.log('updateSection had error', e)
            }
            //console.log('updateSection finished section', section)
        }
    }

    // all uploadId values are the same, find the first one from listB and pass it to all the sections in listA
    const passAroundImageId = (listA,listB) => {
        const item = listB.find(section => {
            if (isSectionArray(section)) {
                return !!imageUploadId(section[0]) || !!imageUploadId(section[1])
            } else return !!imageUploadId(section)
        })
        if (item) {
            let uploadId = imageUploadId(item)
            if (Array.isArray(uploadId)) {
                uploadId = uploadId.find(u => !!u)
            }
            listA.forEach(section => {
                if (isSectionArray(section)) {
                    imageUploadId(section[0],uploadId)
                    imageUploadId(section[1], uploadId)
                } else imageUploadId(section, uploadId)
            })
        } else {
            // console.log('passAroundImageId, none of the sections in listB had an uploadId')
        }
    }

    const sectionsHaveErrors = (sections) => {
        if (!sections || !Array.isArray (sections)) {
            return false
        } else {
            const found = sections.some(section => section.hasError);
            return found
        }
    }

    return {
        getEmptyData,
        sectionEqual,
        isDifferentFromOrig,
        getDataToSave,
        eraseFileObjects,
        isAllReady,
        imageMeta,
        imageUrl,
        imageFilename,
        imageFile,
        imageDocumentId,
        imageUploadId,
        imageUploadError,
        imageId,
        imageHeight,
        imageWidth,
        imageIdeas,
        imageMaterials,
        imageProcesses,
        imageCitations,
        imageDepth,
        deleteSection,
        isEmpty,
        blankIfNullish,
        setImageBools,
        getClone,
        findSection,
        itemToSection,
        passAroundImageId,
        sectionsHaveErrors
    }

}

export default useImages