
import React                                    from 'react';
import { getCurrentPerson }                     from '../entities/person.jsx'
import { getModuleHolder }                      from '../entities/modules'
import { getAssignmentStatuses }                from '../entities/teacherHelper'
import { getAssignmentTeamStatus, getAssignmentHolder }   from '../entities/browserHelper'
import { supportingData, cmsMaterials }         from '../supportingmaterial/supportingmaterial_controller'
import { subtask }                              from '../content/static_content'
import StudentLayoutComp                        from './views/studentLayout'
import TeacherLayoutComp                        from './views/teacherLayout'
import { showLoadingView }                      from '../_core/views/loading_view'
import { showCourseContent }                    from '../main/instruction_controller'
import { noContentError }                       from '../utility/util'

export async function studentBrowser(sectionId, lessonId) {
  const missingTeamMessage = "Error: You are not assigned to a team for this assignment. Please contact your instructor.";
  //console.log('sectionId: ' + sectionId + ' lessonId: ' + lessonId)
  try {
    var asyncFuncs = [
      getAssignmentHolder(lessonId),
      getModuleHolder(),
      getSupportingMaterialAndCMS(lessonId),
      getLessonInfo(lessonId)
    ]
    const [assignmentHolder, moduleHolder, SMAndCMS, lessonInfo] = await Promise.all(asyncFuncs)
    
    var lesson = moduleHolder.lessonById(lessonId)
    if (lesson) {
      let teamNames = []
      let inTeamWithoutMembers
      if (lesson.isTeamLesson) {
        try {
          var team = await getAssignmentTeamStatus(sectionId, assignmentHolder.assignments[0].id)
          if (team) {
            teamNames = team.teamNames()
            inTeamWithoutMembers = false
          } else {
            inTeamWithoutMembers = true
          }
        } catch (e) {
          inTeamWithoutMembers = true
        }
      } else {
        // if its not a team lesson, the person is the team
        inTeamWithoutMembers = false
      }

      let parms = {
        assignments: assignmentHolder.assignments,
        lesson: lesson,
        inTeamWithoutMembers: inTeamWithoutMembers,
        teamNames: teamNames,
        message: missingTeamMessage,
        cmsData: SMAndCMS.cmsData,
        supportingMaterialData: SMAndCMS.supportingMaterialData,
        lessonInfo: lessonInfo
      }
      let studentLayout = <StudentLayoutComp parms={parms} ></StudentLayoutComp>
      showCourseContent(studentLayout)
      var module = moduleHolder.getModuleByLessonId(lessonId)
      document.dispatchEvent(new CustomEvent('pagevisit', { detail: module.abbreviation + '/' + parms.lesson.abbreviation }))
    } else {
      //console.log('lesson does not exist for id ' + lessonId)
      showCourseContent(noContentError())
    }
  } catch (e) {
    console.error('studentBrowser had error')
    showCourseContent(noContentError())
  }
}

export async function teacherBrowser(sectionId, lessonId) {
  try {
    var asyncFuncs = [
      getAssignmentHolder(lessonId),
      getModuleHolder(),
      getSupportingMaterialAndCMS(lessonId),
      getLessonInfo(lessonId)
    ]

    let assignmentHolder, moduleHolder, SMAndCMS, lessonInfo
    await Promise.allSettled(asyncFuncs)
      .then(results => {
        //console.log('teacherBrowser all promises finished', results)
        if (!!results) {
          _.each(results, (result, index) => {
            if (result.status === 'fulfilled') {
              switch (index) {
                case 0:
                  assignmentHolder = result.value
                  break
                case 1:
                  moduleHolder = result.value
                  break
                case 2:
                  SMAndCMS = result.value
                  break
                case 3:
                  lessonInfo = result.value
                  break
              }
            } else {
              let msg = 'teacherBrowser promise ' + index + ' was not fulfilled'
              //console.error(msg, result)
              if (index===2 || index===3) {
                // we can live without CMS, actually #2, #3 never fail
              } else {
                throw msg  
              }
            }
          })
        }
      })

    var lesson = !!moduleHolder ? moduleHolder.lessonById(lessonId) : {}
    var students = await getAssignmentStatuses(sectionId, assignmentHolder.assignments[0].id, lesson.isTeamLesson ? 'teams' : 'individuals');

    let parms = {
      lesson: lesson,
      students: students, // has property 'statuses' which is an array of AssignmentStatus
      assignments: !!assignmentHolder ? assignmentHolder.assignments : [],
      cmsData: !!SMAndCMS ? SMAndCMS.cmsData : false,
      supportingMaterialData: !!SMAndCMS ? SMAndCMS.supportingMaterialData : false,
      lessonInfo: !!lessonInfo ? lessonInfo : false
    }
    let teacherLayout = <TeacherLayoutComp parms={parms}></TeacherLayoutComp>
    showCourseContent(teacherLayout)
    var moduleAbbreviation = moduleHolder.getModuleByLessonId(lessonId).abbreviation
    document.dispatchEvent(new CustomEvent('pagevisit', { detail: moduleAbbreviation + '/' + parms.lesson.abbreviation }))

  } catch (e) {
    console.error('teacherBrowser had error ', e)
    showCourseContent(noContentError())
  }
}

export function artifactBrowserRouter(sectionId, lessonId) {
  showLoadingView('region-course-content');
  if (getCurrentPerson().isTeacher()) {
    teacherBrowser(sectionId, lessonId)
  } else {
    studentBrowser(sectionId, lessonId)
  }
  //this[role+'Browser'](sectionId, lessonId);
}