import React, { Fragment } from "react";
import { CheckboxGroup, Checkbox, Input } from "@cb/apricot-react";

export function CSPStep2({curriculums, curriculumChange, curriculumOther, inputChange}) {
  let curriculumLabels = [
    'Apple',
    'Beauty and Joy of Computing(BJC)',
    'CodeHS',
    'Code.org',
    'Computer Science (CS) Matters',
    'CS50',
    'Microsoft MakeCode',
    'Mobile Computer Science Principles (Mobile CSP)',
    'Popfizz CS',
    'Project Lead The Way (PLTW)',
    'Project STEM (formerly Edhesive)',
    'Tynker',
    'UTeach',
    'Zulama by Carnegie Learning',
    'None',
    'Other (specify)'
  ]

    let keys = _.keys(curriculums)
    let noneDisabled = _.some(keys, key => {
        let found = key !== 'None' && curriculums[key]
        return found
    })

    let nonNoneDisabled = !!curriculums['None']
    let curriculumOtherDisabled = !curriculums['Other (specify)']

    //console.log('noneDisabled=' + noneDisabled + ', nonNoneDisabled=' + nonNoneDisabled)

    let checkboxes1 = _.map(curriculumLabels.slice(0, 3), (label, index) => {
        let disabled = label === 'None' && noneDisabled || label !== 'None' && nonNoneDisabled
        return (
            <Checkbox
                label={label} onChange={val => { curriculumChange(label, val) }} key={'checkbox1_' + index}
                disabled={disabled}
                checked={!!curriculums[label]} className='col-md-4' />
        )
    })
    let checkboxes2 = _.map(curriculumLabels.slice(3, 6), (label, index) => {
        let disabled = label === 'None' && noneDisabled || label !== 'None' && nonNoneDisabled
        return (
            <Checkbox
                label={label} onChange={val => { curriculumChange(label, val) }} key={'checkbox2_' + index}
                disabled={disabled}
                checked={!!curriculums[label]} className='col-md-4' />
        )
    })
    let checkboxes3 = _.map(curriculumLabels.slice(6, 8), (label, index) => {
        let disabled = label === 'None' && noneDisabled || label !== 'None' && nonNoneDisabled
        return (
            <Checkbox
                label={label} onChange={val => { curriculumChange(label, val) }} key={'checkbox3_' + index}
                disabled={disabled}
                checked={!!curriculums[label]} className='col-md-4' />
        )
    })
    let checkboxes4 = _.map(curriculumLabels.slice(8, 11), (label, index) => {
        let disabled = label === 'None' && noneDisabled || label !== 'None' && nonNoneDisabled
        return (
            <Checkbox
                label={label} onChange={val => { curriculumChange(label, val) }} key={'checkbox4_' + index}
                disabled={disabled}
                checked={!!curriculums[label]} className='col-md-4' />
        )
    })
    let checkboxes5 = _.map(curriculumLabels.slice(11, 14), (label, index) => {
        let disabled = label === 'None' && noneDisabled || label !== 'None' && nonNoneDisabled
        return (
            <Checkbox label={label} onChange={val => { curriculumChange(label, val) }} key={'checkbox5_' + index}
                disabled={disabled}
                checked={!!curriculums[label]} className='col-md-4' />
        )
    })
    let checkboxes6 = _.map(curriculumLabels.slice(14, 16), (label, index) => {
        let disabled = label === 'None' && noneDisabled || label !== 'None' && nonNoneDisabled
        return (
            <Checkbox
                label={label} onChange={val => { curriculumChange(label, val) }} key={'checkbox6_' + index}
                disabled={disabled}
                checked={!!curriculums[label]} className='col-md-4' />
        )
    })

  return (
    <Fragment>
      <label className="question" style={{ marginTop: "20px" }}>
        What curriculum(s) have you implemented? Check all that apply.
      </label>
      <CheckboxGroup vertical={false}>{checkboxes1}</CheckboxGroup>
      <CheckboxGroup vertical={false}>{checkboxes2}</CheckboxGroup>
      <CheckboxGroup vertical={false}>{checkboxes3}</CheckboxGroup>
      <CheckboxGroup vertical={false}>{checkboxes4}</CheckboxGroup>
      <CheckboxGroup vertical={false}>{checkboxes5}</CheckboxGroup>
      <CheckboxGroup vertical={false}>{checkboxes6}</CheckboxGroup>
      <div style={{ marginTop: "20px" }}>
        <label className="question" htmlFor="other">
          If other, please enter Curriculum
        </label>
      </div>
      <div>
        <Input
          type="text"
          name="curriculumOther"
          id="curriculumOther"
          disabled={curriculumOtherDisabled}
          placeholder="Other..."
          value={curriculumOther}
          clearable={false}
          maxLength="50"
          onChange={(event) => {
            inputChange(event, "curriculumOther");
          }}
        />
      </div>
    </Fragment>
  );
}
