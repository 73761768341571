import CSP_WR_Title from './CSP_WR_Title'
import CSP_WR_Text from './CSP_WR_Text'
import CSP_WR_Image from './CSP_WR_Image'

const CSP_WR_3B = (props) => {
  const labels = ['i.', 'ii.', 'iii.', 'iv.', 'v.']
  const titles = [
      'The first program code segment must show how data have been stored in the list.',
      "The second program code segment must show the data in the same list being used, such as creating new data from the existing data or accessing multiple elements in the list, as part of fulfilling the program's purpose.",
  ]
  const rows = props.data.map((row,index) => {
      // first two are image sections the rest are text
      if (index < 2) {
          return (
              <div key={index}>
                  <CSP_WR_Title
                      title={titles[index]}
                      label={labels[index]}>
                  </CSP_WR_Title>
                  <CSP_WR_Image
                      id={`3B_${index}`}
                      index={index}
                      readOnly={props.readOnly}
                      absIndex={props.sectionOffset + index}
                      images={row.images}
                      badImage={props.badImage}
                      onUploadError={props.onUploadError}
                      initializeDrag={props.initializeDrag}
                      onChange={props.onChange}
                      clearImg={(param) => { props.clearImg(param)}}
                   >
                  </CSP_WR_Image>
              </div>
          )
      } else {
          return (
              <div key={index}>
                  <CSP_WR_Title
                      title={titles[index]}
                      label={labels[index]}>
                  </CSP_WR_Title>
                  <CSP_WR_Text
                      id={`3B_${index}`}
                      index={index}
                      absIndex={props.sectionOffset + index}
                      wouldExceed={props.wouldExceed}
                      frozen={props.frozen}
                      //text={row.text}
                      wordCounts={props.wordCounts}>
                  </CSP_WR_Text>
              </div>
          )
      }
  })
  return (
      <div className='section'>
          <table role="presentation">
          <tbody>
              <tr>
                  <td className='tableTd1 cb-k-12-program'>List:</td>
                  <td>
                    Capture and paste two program code segments you developed during the administration of this task that contain a list (or other collection type) being used to manage complexity in your program.
                  </td>
              </tr>
          </tbody>
          </table>
          {rows[0]}
          {rows[1]}
      </div>
  )
}
export default CSP_WR_3B
