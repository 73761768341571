import React from 'react';
import { bsliGet, bsliPut, bsliDelete } from '../../_core/util/request';
import { Input, Select, YellowButton, Expandable, Modal, NakedButton } from '@cb/apricot-react';
import CBRegExpValidation from '@cb/apricot/CBRegExpValidation'

export default class ShowRubricsComp extends React.Component {

    constructor(props) {
        super(props);
        let originalparams = [];
        originalparams = JSON.parse(JSON.stringify(this.props.parms.parameters));
        this.state = {rubrics: null, selectedRubricScore: null, selectedRubric: null, flag: true, showAlert: false, alertMsg: '', alertSection: '' };
    }


    componentDidUpdate() {

    }

    componentDidMount() {
    }

    render() {
        const showAlert = (section) => {
            if (this.state.showAlert && this.state.alertSection === section) {
                return <div className='alert alert-danger'>
                    <span>{this.state.alertMsg}</span>
                </div>
            }
        }

        const showEditDeleteButtonRubricScore = (rubricScore, rubricId, assignmentId, index) => {
            let show = this.props.parms.courseCreationEnabled && this.props.parms.model.isCourseActive;
            if (show) {
                let editScoreId = "editScore" + rubricScore.id;
                let deleteScoreId = "deleteModule" + rubricScore.id;
                let score = rubricScore.score;
                let description = rubricScore.description;
                let abbrev = rubricScore.abbrev;
                const editDeleteButtonRubricsScore = <div className="cb-btn-row">
                    <NakedButton type="button" id={editScoreId} noPadding data-cb-modal-close >
                        Cancel
                    </NakedButton>
                    <YellowButton
                        onClick={(e) => { this.editRubricScore(assignmentId, rubricId, rubricScore.id, this.state.selectedRubricScore.score, this.state.selectedRubricScore.abbrev, this.state.selectedRubricScore.description, e.target, index) }
                        }>Save</YellowButton>
                </div>
                const deleteButtonRubricsScore = <div className="cb-btn-row">
                    <NakedButton type="button" id={deleteScoreId} noPadding data-cb-modal-close >
                        No
                    </NakedButton>
                    <YellowButton
                        onClick={(e) => this.deleteRubricScore(assignmentId, rubricId, rubricScore.id, e.target, index)}>Yes</YellowButton>
                </div>
                return <span>
                    <NakedButton type="button" className="btn-link add-left-margin-small cb-no-padding" id={editScoreId} >
                        <span className="cb-glyph cb-compose" aria-hidden='true'>
                        </span> <span className="sr-only">Edit Rubric Score</span>
                    </NakedButton>
                    <NakedButton type="button" className="js-delete-task btn-link cb-gray4-color remove-left-margin cb-no-padding" id={deleteScoreId}>
                        <span className="cb-glyph cb-x-mark cb-red1-color " aria-hidden='true'/>
                        <span className="sr-only">Delete Rubric Score</span>
                    </NakedButton>
                    <Modal
                        onClose={() => { this.setState({ ["selectedRubricScore"]: null }) }}
                        onShow={() => { this.setState({ ["selectedRubricScore"]: this.copyObject(rubricScore) }) }}
                        customAttributes={null}
                        title="Edit Rubric Score"
                        trigger={editScoreId}
                        withFooter
                        withHeader
                        footer={editDeleteButtonRubricsScore}
                        id={rubricScore.id}
                    >
                        <div className="row">
                            {showAlert("editrubricscore")}
                            <div className="col-xs-4">
                                <label htmlFor={"score".concat(rubricScore.id)}>Score</label>
                            </div>
                            <div className="col-xs-8" >
                                <Input type="text" name="score" id={"score".concat(rubricScore.id)}
                                    defaultValue={this.state.selectedRubricScore ? this.state.selectedRubricScore.score : score}
                                    required="true"
                                    onChange={(e) => { this.state.selectedRubricScore.score = e.target.value }}/>
                            </div>
                        </div>
                        <br/>

                        <div className="row">
                            <div className="col-xs-4">
                                <label htmlFor={"abbred".concat(rubricScore.id)} > Alternate text (shown instead of score) </label>
                            </div>
                            <div className="col-xs-8" >
                                <Input type="text" id={"abbred".concat(rubricScore.id)}
                                    defaultValue={this.state.selectedRubricScore ? this.state.selectedRubricScore.abbrev : abbrev}
                                    onChange={(e) => { this.state.selectedRubricScore.abbrev = e.target.value }} />
                            </div>
                        </div>
                        <br/>

                        <div className="row cb-input">
                            <div className="col-xs-4">
                                <label htmlFor={"rubricscoredescription".concat(rubricScore.id)} required  >Description </label>
                            </div>
                            <div className="col-xs-8" >
                                <textarea className="cb-Requied" id={"rubricscoredescription".concat(rubricScore.id)}
                                    defaultValue={this.state.selectedRubricScore ? this.state.selectedRubricScore.description : description}
                                    onChange={(e) => { this.state.selectedRubricScore.description = e.target.value }} rows="5" maxLength="2000">
                                </textarea>
                            </div>
                        </div>

                    </Modal>

                    <Modal
                        customAttributes={null}
                        title="Delete Rubric Score"
                        trigger={deleteScoreId}
                        withFooter
                        withHeader
                        footer={deleteButtonRubricsScore}
                        >
                        {showAlert("deleterubricscore")}
                        This will permanently delete this. Once deleted, it can not be restored. Are you sure you want to continue?

                    </Modal>


                </span>

            }
        }


        const showEditDeleteButtonRubric = (rubric, index) => {
            let show = this.props.parms.courseCreationEnabled && this.props.parms.model.isCourseActive;
            if (show) {

                let editModalId = "editRubric" + rubric.id;
                let deleteModalId = "deleteRubric" + rubric.id;
                let defaultDescription = rubric.defaultDescription;
                let name = rubric.title;
                let assignmentId = rubric.assignmentId //, 
                let rubricId = rubric.id;
                const editDeleteButtonRubrics = <div className="cb-btn-row">
                    <NakedButton type="button" id={editModalId} noPadding data-cb-modal-close >
                        Cancel
                    </NakedButton>
                    <YellowButton onClick={(e) => { this.editRubric(assignmentId, rubricId, this.state.selectedRubric.title, this.state.selectedRubric.defaultDescription, e.target, index) }} >Save</YellowButton>
                </div>
                const deleteButtonRubrics = <div className="cb-btn-row">
                    <NakedButton type="button" id={deleteModalId} noPadding data-cb-modal-close >
                        No
                    </NakedButton>
                    <YellowButton onClick={(e) => { this.editRubric(assignmentId, rubricId, this.state.selectedRubric.title, this.state.selectedRubric.defaultDescription, e.target, index) }} >Save</YellowButton>
                </div>
                return <span>
                    <NakedButton type="button" className="btn-link add-left-margin-small cb-no-padding" id={editModalId}>
                        <span className="cb-glyph cb-compose" aria-hidden='true'/>
                        <span className="sr-only">Edit Rubric</span>
                    </NakedButton >
                    <NakedButton type="button" className="js-delete-task btn-link cb-gray4-color remove-left-margin cb-no-padding" id={deleteModalId} >
                        <span className="cb-glyph cb-x-mark cb-red1-color" aria-hidden='true'/>
                        <span className="sr-only">Delete Rubric</span>
                    </NakedButton>

                    <Modal
                        actions={
                            <YellowButton onClick={(e) => { this.editRubric(assignmentId, rubricId, this.state.selectedRubric.title, this.state.selectedRubric.defaultDescription, e.target, index) }} >Save</YellowButton>
                        }
                        onClose={() => { this.setState({ ["selectedRubric"]: null }) }}
                        onShow={() => { this.setState({ ["selectedRubric"]: this.copyObject(rubric) }) }}
                        customAttributes={null}
                        title="Edit Rubric"
                        trigger={editModalId}
                        withFooter
                        footer={editDeleteButtonRubrics}
                        withHeader
                        id={rubric.id}
                    >
                        <div className="row">
                            {showAlert("editrubric")}
                            <div className="col-xs-4"> <label htmlFor={"name".concat(rubricId)}> Rubric Name </label> </div> <div className="col-xs-8" >
                                <Input type="text" maxLength="80" id={"name".concat(rubricId)}
                                    defaultValue={this.state.selectedRubric ? this.state.selectedRubric.title : name}
                                    onChange={(e) => { this.state.selectedRubric.title = e.target.value }} required='true' />  </div> </div>
                        <br/>
                        <div className="cb-input row">
                            <div className="col-xs-4">  <label htmlFor={"defaultDescription".concat(rubricId)} >Default Description</label> </div>
                            <div className="col-xs-8" >
                                <textarea id={"defaultDescription".concat(rubricId)}
                                    defaultValue={this.state.selectedRubric ? this.state.selectedRubric.defaultDescription : defaultDescription}
                                    onChange={(e) => { this.state.selectedRubric.defaultDescription = e.target.value }} rows="5" maxLength="256" ></textarea>
                            </div>
                        </div>

                    </Modal>

                    <Modal
                        customAttributes={null}
                        title="Delete Rubric"
                        trigger={deleteModalId}
                        withFooter
                        withHeader
                        footer={deleteButtonRubrics}>
                        {showAlert("deleterubric")}
                        This will permanently delete this. Once deleted, it can not be restored. Are you sure you want to continue?
                    </Modal>


                </span>

            }
        }

        const handleAssignmentChange = async (val) => {
            const rubrics = await this.showAssignmentRubrics(val);
            if (rubrics) {
                this.setState({ ["rubrics"]: rubrics, ["assignment"]: val });
            } else {
                console.log('handleAssignmentChange had error')
            }
        }

        const showRubricDescriptions = (descriptions, assignmentId, rubricId) => {
            let listDescriptions = descriptions.map((d, index) => {
                return <div className="row" className="cb-spacerv-top-16 cb-spacerh-left-24" key={d.id}>  {!!d.abbrev ? d.abbrev : d.score}-
                    <span dangerouslySetInnerHTML={{ __html: d.description }} /> {showEditDeleteButtonRubricScore(d, rubricId, assignmentId, index)} </div>
            });

            return listDescriptions;
        }

        const showExpandableRubric = (rubric) => {
            if (rubric.descriptions && rubric.descriptions.length > 0) {
                return <Expandable id={rubric.id} closedLabel="Show Scores" openLabel="Hide Scores" linkPosition="top" >
                    <span>
                        {showRubricDescriptions(rubric.descriptions, rubric.assignmentId, rubric.id)}
                    </span>
                </Expandable>
            }
        }

        const showRubrics = () => {
            if (this.state.rubrics && this.state.rubrics.length > 0) {
                return this.state.rubrics.map((rubric, index) =>
                    <div className="adminshowrubrics cb-spacerv-top-16 cb-spacerh-left-24" key={"rubric".concat(rubric.id)} >
                        <span id={"rubrictitle_".concat(index)} >{rubric.title}</span> {showEditDeleteButtonRubric(rubric, index)}
                        {showExpandableRubric(rubric)}
                    </div>
                )
            }
        }

        return (
            <div>
                <h6 className="cb-spacerv-top-24 cb-spacerv-bottom-8 " >Course Rubrics</h6>

                <Select
                    id="lesson"
                    name="lesson"
                    ariaLabel="Select Lesson"
                    values={this.assignmentLabelValues()}
                    onChange={handleAssignmentChange}
                />


                <div >
                    {showRubrics()}
                </div>
            </div>
        )

    }

    editRubricScore(assignmentId, rubricId, scoreId, score, abbrev, description, target, index) {
        let url = '/admin/assignments/' + assignmentId + '/rubric/' + rubricId + '/score/' + scoreId;
        this.clearAlert();
        let errorMessage = 'Input required for'
        let notValidParams = false
        if (!score) {
            errorMessage += ' score'
            notValidParams = true;
        }
        if (!description) {
            if (!notValidParams) {
                errorMessage += ' description'
            } else {
                errorMessage += ', description'
            }
            notValidParams = true
        }

        if (score && !CBRegExpValidation.number(score)) {
            if (!notValidParams) {
                errorMessage = 'Please enter only numeric value for score. '
            } else {
                errorMessage += '. ' + 'Please enter only numeric value for score. '
            }
            notValidParams = true
        }


        if (notValidParams === true) {
            this.displayAlert(errorMessage, "editrubricscore");
            return false;
        }
        bsliPut(url, {
            "score": score,
            "abbrev": abbrev,
            "description": description
        }).then(() => {
            let el = target ? target.previousElementSibling : null
            if (el) {
                el.click();
            }
            let rubric = this.state.rubrics.find((r) => r.id === rubricId);
            if (!!rubric) {
                rubric.descriptions[index].score = score;
                rubric.descriptions[index].abbrev = abbrev;
                rubric.descriptions[index].description = description;
                this.bounceState();
                return true;
            }

        }).catch(() => {
            this.displayAlert("Error happenned please try again.", "editrubricscore");
        })
    }

    deleteRubricScore(assignmentId, rubricId, scoreId, target, index) {
        let url = '/admin/assignments/' + assignmentId + '/rubric/' + rubricId + '/score/' + scoreId;
        this.clearAlert();
        bsliDelete(url).then(() => {
            let el = target ? target.previousElementSibling : null
            if (el) {
                el.click();
            }
            let rubric = this.state.rubrics.find((r) => r.id === rubricId);
            //console.log(rubric)
            if (!!rubric) {
                rubric.descriptions.splice(index, 1)
            }
            this.bounceState();
        }).catch(() => {
            this.displayAlert("Error happenned please try again.", "deleterubricscore");
        })
    }

    editRubric(assignmentId, rubricId, title, description, target, index) {
        let url = '/admin/assignments/' + assignmentId + '/rubric/' + rubricId;
        this.clearAlert();

        if (!title) {
            this.displayAlert("Input required for Rubric Title.", "editrubric");
            return;
        }
        bsliPut(url, {
            "title": title,
            "defaultDescription": description,
        }).then(() => {
            this.state.rubrics[index].title = title;
            this.state.rubrics[index].defaultDescription = description;
            let el = target ? target.previousElementSibling : null
            if (el) {
                el.click();
            }
            this.bounceState();
        }).catch(() => {
            this.displayAlert("Error happenned please try again.", "editrubric");
        })
    }


    deleteRubric(assignmentId, rubricId, target, index) {
        let url = '/admin/assignments/' + assignmentId + '/rubric/' + rubricId;
        this.clearAlert();

        bsliDelete(url).then(() => {
            this.state.rubrics.splice(index, 1);
            let el = target ? target.previousElementSibling : null
            if (el) {
                el.click();
            }
            setTimeout(() => { this.bounceState() }, 500);
        }).catch(() => {
            this.displayAlert("Error happenned please try again.", "deleterubric");
        })
    }

    assignmentLabelValues() {
        let lessons = this.props.parms.modules.map(m => m.lessons).
            reduce((pre, cur) => pre.concat(cur)).map(l => ({ value: l.assignmentId, label: l.title, key: l.assignmentId }));
        return [{ value: "-1", label: "Select Lesson", key: "-1" }].concat(lessons);
    }

    async showAssignmentRubrics(assignmentId) {
        try {
            let url = 'assignments/' + assignmentId + '/rubric';
            let rubrics = await bsliGet(url);
            return rubrics;    
        } catch(e) {
            console.error('showAssignmentRubrics had error')
            return null
        }
    }

    clearAlert() {
        this.setState({ ["showAlert"]: false, ["alertMsg"]: "", ["alertSection"]: "" });
    }

    displayAlert(message, section) {
        this.setState({ ["showAlert"]: true, ["alertMsg"]: message, ["alertSection"]: section });
    }

    copyObject(object) {
        return Object.assign({}, object)
    }

    // dummy method to force screen refresh

    bounceState() {
        let flag = this.state.flag;
        this.setState({ ["flag"]: !flag });
    }
}