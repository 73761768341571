import React, { useEffect }                                         from 'react';
import {YellowButton, BlackButton, NakedButton, Tooltip, SpacerH, CloseButton} from '@cb/apricot-react';
import { commaName }                                                from '../../utility/util'
import { isAfterHardEndDate }                                       from '../../entities/courseinstance'
import * as drag                                                    from './dragging'

export function TeamItem({ team, dragName, canEditSection, onDrop, onChange, initDelete, 
                           deleteEnrollmentFromTeam, selectedTeamId, selectedTeamTitle, 
                           clearTeamSelection, saveTeam, editTeam, showAlert, hasAlert, focus }) {
    useEffect(() => {
        if (focus && team.id===focus.teamId) {
            let elem = document.getElementById(focus.teamPart + focus.teamId)
            if (elem) {
                //console.log('focus on ' + focus.teamPart + ', ' + focus.teamId)
                elem.focus()
                //console.log('useEffect focus is on', document.activeElement)
            }    
        }
    })

    let key = 'team' + team.id
    let divCls = 'cb-card cb-no-box-shadow cb-no-border-radius cb-border-top cb-no-padding-left cb-no-padding-right'
    let isScored = team.teamHasUpload || team.teamHasScore
    let enrollments = getEnrollmentsList(team, isScored)
    isAfterHardEndDate
 
    if (isScored) {
        return ( getDivScored(team, enrollments) )
    } else {
        if (canEditSection) {
            return ( getDivDraggable(team, enrollments) )
        } else {
            // we can still edit the team iff
            // all team members have extension and before hard end date
            let everyTeamMemberHasExtension = _.every(team.enrollment, enrollment => { return enrollment.hasExtension }) 
            let canEditTeam = everyTeamMemberHasExtension && !isAfterHardEndDate()
            if (canEditTeam) {
                return ( getDivDraggable(team, enrollments) )
            } else {
                return ( getDivNotDraggable(team, enrollments) )
            }
        }
    }

    // returns a list of <li>
    function getEnrollmentsList(team, isScored) {
        return team.enrollment.map(
            enrollment => {
                // we can delete the enrollment in a team iff
                // team not scored and not after hard end date
                // section is editable or enrollment has an extension
                let canDelete = !isScored && !isAfterHardEndDate() && (canEditSection || enrollment.hasExtension)                    
                let name = commaName(enrollment)
                //console.log('getEnrollmentsList team ' + team.title + ', name=' + name + ', canDelete=' + canDelete)
                return (
                    <li className='col-md-6 col-sm-6' id={enrollment.id} key={enrollment.id}>
                        {canDelete &&
                            deleteEnrollmentButton(team.id, enrollment.id, enrollment.lastName, enrollment.firstName)
                        }
                        {name}
                    </li>
                )
            }
        )
    }

    function getDivScored(team, enrollments) {
        return (
            <div id={team.id} key={key} tabIndex='-1' aria-live='polite'
                className={divCls}>
                <div className="cb-card-content">
                    <div className="row">
                        <div className="col-md-4 col-sm-8 col-xs-8" > 
                            <strong> {team.title} </strong>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12" >
                            <ul className="list-unstyled list-col-2x">
                                {enrollments}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function getDivNotDraggable(team, enrollments) {
        let ariaLabel = team.title + ' has ' + enrollments.length + ' members '
        return (
            <div id={'team' + team.id} key={key} tabIndex='-1'
                aria-live='polite' aria-label={ariaLabel}
                className={divCls}>
                <div className="cb-card-content">
                    <div className="row">
                    <div className="col-md-4 col-sm-8 col-xs-8" > 
                            <strong> {team.title} </strong>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12" >
                            <ul className="list-unstyled list-col-2x">
                                {enrollments}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="cb-spacerv-8"></div>
            </div>
        )
    }

    function getDivDraggable(team, enrollments) {
        let ariaLabel = team.title + ' has ' + enrollments.length + ' members '
        let dropInstruction = ''
        if (dragName) {
            dropInstruction = 'drop ' + dragName + ' to add to ' + team.title
        }
        return (
            <div id={'team' + team.id} key={key} tabIndex='-1'
                aria-live='polite' aria-label={ariaLabel}
                className={divCls}
                onDrop={(e) => { onDrop(e, team.id) }}
                onDragOver={drag.onDragOver}
                onDragLeave={drag.onDragLeave}>

                <div className="cb-card-content">
                    <div className="row">
                        <div className="col-md-4 col-sm-8 col-xs-8" >
                            {editTeamArea(team.id, team.title)}
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12" >
                            <ul className="list-unstyled list-col-2x">
                                {enrollments}
                            </ul>
                        </div>
                        <div className="col-md-2 col-sm-4 col-xs-4 cb-float-top" >
                            {deleteTeamButton(team.id, team.title)}
                        </div>
                    </div>
                </div>
                <div className="cb-spacerv-8"></div>
                <span className='sr-only dropInstruction hidden'>{dropInstruction}</span>
            </div>
        )
    }

    function deleteEnrollmentButton(teamId, enrollmentId, lastName, firstName) {
        let title = "Delete " + commaName(firstName,lastName)
        return (
            <div>
                <CloseButton id={"deleteButton".concat(enrollmentId)} small
                             className="cb-spacerh-4" aria-label={"Delete Enrollment " + lastName + " " + firstName}
                             onClick={() => { deleteEnrollmentFromTeam(teamId, enrollmentId) }}>
                    <span className="sr-only">{title}</span>
                </CloseButton>
                <Tooltip
                    trigger={"deleteButton".concat(enrollmentId)}
                    placement="left"
                    style="customcolor">
                    {title}
                </Tooltip>
            </div>
        )
    }

    function editTeamButton(teamId, title) {
        return <NakedButton type="button" className="cb-no-padding" id={"edit".concat(teamId)} 
            onClick={(e) => editTeam(teamId)}  aria-label={"Edit Team " + title}>
            <span className="cb-glyph cb-compose" aria-hidden="true" />
            <span className="sr-only">Edit Team</span>
        </NakedButton >
    }

    // had to stop using Input widget because it does not support aria-describedby
    function editTeamArea(teamId, title) {
        if (teamId === selectedTeamId) {
            let id = 'title' + teamId
            return (
                <span id='editTeamArea' tabIndex='-1'>
                    {showAlert("team" + teamId)}
                    <div className="cb-input">
                        <input type="text" maxLength="25" id={id}
                            className="cb-spacerv-bottom-8"
                            aria-label='Title for the team'  aria-describedby='alertMsg' aria-invalid={hasAlert}
                            value={selectedTeamTitle}
                            onChange={onChange} />
                    </div>
                    <YellowButton small onClick={() => saveTeam(teamId)}>
                        Save
                    </YellowButton> 
                    <SpacerH size="4" />
                    <BlackButton small onClick={() => clearTeamSelection(teamId)}>
                        Cancel
                    </BlackButton>
                </span>
            )
        } else {
            return (
                <span>
                    <strong>{title} </strong> {editTeamButton(teamId , title)}
                </span>
            )
        }
    }
    function deleteTeamButton(teamId, title) {
        return (
            <BlackButton 
                className="cb-float-right cb-no-margin-right" aria-label={"Delete Team " + title} 
                aria-haspopup="dialog" small
                onClick={() => initDelete(teamId, title)}>
                Delete
            </BlackButton>
        )
    }
}