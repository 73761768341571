/* ========================================================================
 * Apricot's Utility Module
 * ======================================================================== */
//import apricotData from "./CBData"; // ------------------------------------  KEYBOARD

var KEYS = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  ESC: 27,
  SPACE: 32,
  PAGEUP: 33,
  PAGEDOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  PREV: 37,
  NEXT: 39,
  DOWN: 40,
  PLUS: 187,
  PLUSNUMERICKEYPAD: 107,
  MINUS: 189,
  MINUSNUMERICKEYPAD: 109,
  DEL: 46,
  A: 65,
  Z: 90,
  ZERO: 48,
  NINE: 57
}; // ------------------------------------  FOCUSABLE ELEMENTS

var FOCUSABLE_ELEMENTS = ["a[href]", "area[href]", 'input:not([disabled]):not([type="hidden"]):not([aria-hidden])', "select:not([disabled]):not([aria-hidden])", "textarea:not([disabled]):not([aria-hidden])", "button:not([disabled]):not([aria-hidden])", "iframe", "object", "embed", "[contenteditable]", '[tabindex]:not([tabindex^="-"])', "[data-cb-focusable]"];
/**
 * Check if any key in KEYS were clicked
 *
 * @export
 * @param {Object} event
 * @param {String} key
 * @returns {Boolean}
 */

var isKey = function isKey(event, key) {
  return KEYS[key] === event.which ? true : false;
};
/**
 * Get Key name
 *
 * @export
 * @param {Object} event
 * @returns {(String|null)}
 */


var whichKey = function whichKey(event) {
  for (var key in KEYS) {
    if (KEYS[key] === event.which) {
      return key;
    }
  }

  return null;
};
/**
 * Check id element exists
 *
 * @export
 * @param {Element} elem
 * @returns {Boolean}
 */


var elemExists = function elemExists(elem) {
  if (typeof elem !== "undefined" && elem !== null) {
    return true;
  } else {
    return false;
  }
};
/**
 * Get all elements with the specified class name
 *
 * @export
 * @param {String} className
 * @param {Element} [node=null]
 * @returns {Element[]}
 */


var getByClass = function getByClass(className) {
  var node = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  node = node || document;
  return node.getElementsByClassName(className);
};
/**
 * Get element whose id property matches the specified string
 *
 * @export
 * @param {String} idName
 * @param {Element} [node=null]
 * @returns {Element}
 */


var getById = function getById(idName) {
  var node = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  node = node || document;
  return node.getElementById(idName);
};
/**
 * Get all elements with the specified tag name
 *
 * @export
 * @param {String} tagName
 * @param {Element} [node=null]
 * @returns {Element[]}
 */


var getByTag = function getByTag(tagName) {
  var node = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  node = node || document;
  return node.getElementsByTagName(tagName);
};
/**
 * Return the first matching ancestor
 *
 * @export
 * @param {Element} elem
 * @param {String} selector
 * @returns {(String|null)}
 */


var getClosest = function getClosest(elem, selector) {
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (selector) {
      if (elem.matches(selector)) return elem;
    } else {
      return elem;
    }
  }

  return null;
};
/**
 * Get elements value
 *
 * @export
 * @param {Element} elem
 * @returns {(String|null)}
 */


var getValue = function getValue(elem) {
  if (elemExists(elem)) {
    return elem.value;
  } else {
    return null;
  }
};
/**
 * Check if element has a specific class
 *
 * @export
 * @param {Element} elem
 * @param {String} className
 * @returns {Boolean}
 */


var hasClass = function hasClass(elem, className) {
  return (" " + elem.className + " ").indexOf(" " + className + " ") > -1;
};
/**
 * Add one or multiple classes to element
 *
 * @export
 * @param {Element} elem
 * @param {String[]|String} className
 */


var addClass = function addClass(elem, className) {
  if (!elemExists(elem)) return;

  if (Array.isArray(className)) {
    className.forEach(function (cName) {
      checkClassName(elem, cName);
    });
  } else {
    checkClassName(elem, className);
  }
};

var checkClassName = function checkClassName(elem, className) {
  if (elem) {
    if (elem.classList) {
      elem.classList.add(className);
    } else {
      elem.className += " " + className;
    }
  }
};
/**
 * Remove specific class from element
 *
 * @export
 * @param {Element} elem
 * @param {String} className
 */


var removeClass = function removeClass(elem, className) {
  if (!elemExists(elem)) return;

  if (elem && elem.classList) {
    if (elem.classList.contains(className)) {
      elem.classList.remove(className);
    }
  }
};
/**
 * Toggle class name
 *
 * @export
 * @param {Element} elem
 * @param {String} className
 * @param {Boolean} state
 */


var toggleClass = function toggleClass(elem, className, state) {
  if (elem && elem.classList) {
    if (typeof state != "undefined") {
      if (state) {
        addClass(elem, className);
      } else if (!state) {
        removeClass(elem, className);
      }
    } else {
      elem.classList.toggle(className);
    }
  }
};
/**
 * Remove element
 *
 * @export
 * @param {Element} elem
 */


var remove = function remove(elem) {
  if (typeof elem !== "undefined" && elem !== null) {
    elem.parentNode && elem.parentNode.removeChild(elem);
  }
};
/**
 * Get first or specific previous sibling
 *
 * @export
 * @param {Element} elem
 * @param {String=} selector
 * @returns {Element}
 */


var getPreviousSibling = function getPreviousSibling(elem, selector) {
  var sibling = elem.previousElementSibling; // If there's no selector, return the first sibling

  if (!selector) return sibling; // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop

  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.previousElementSibling;
  }
};
/**
 * Get next or specific next sibling
 *
 * @export
 * @param {Element} elem
 * @param {String=} selector
 * @returns {Element}
 */


var getNextSibling = function getNextSibling(elem, selector) {
  // Get the next sibling element
  var sibling = elem.nextElementSibling; // If there's no selector, return the first sibling

  if (!selector) return sibling; // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop

  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.nextElementSibling;
  }
};
/**
 * Get parent element
 *
 * @export
 * @param {Element} elem
 * @returns {Element}
 */


var parent = function parent(elem) {
  return elem.parentNode;
};
/**
 * Insert element after referenced node
 *
 * @export
 * @param {Element} referenceNode
 * @param {Element} elem
 */


var insertAfter = function insertAfter(referenceNode, elem) {
  referenceNode.parentNode.insertBefore(elem, referenceNode.nextSibling);
};
/**
 * Insert element before referenced node
 *
 * @export
 * @param {Element} referenceNode
 * @param {Element} elem
 */


var insertBefore = function insertBefore(referenceNode, elem) {
  referenceNode.parentNode.insertBefore(elem, referenceNode);
};
/**
 * Append element
 *
 * @export
 * @param {Element} elem1
 * @param {Element} elem2
 */


var append = function append(elem1, elem2) {
  elem1.appendChild(elem2);
};
/**
 * Detect Browser
 *
 * @export
 * @returns {Object} browser object
 * @returns {String} browser.name
 * @returns {Boolean} browser.type - opera|msie|chrome|firefox|safari
 * @returns {Number} browser.version
 */


var browser = function browser() {
  var t = true,
      browserObj = {},
      detect;

  detect = function detect(ua) {
    function getFirstMatch(regex) {
      var match = ua.match(regex);
      return match && match.length > 1 && match[1] || "";
    }

    var versionIdentifier = getFirstMatch(/version\/(\d+(\.\d+)?)/i),
        result = {};

    if (/opera|opr/i.test(ua)) {
      result = {
        name: "Opera",
        opera: t,
        version: versionIdentifier || getFirstMatch(/(?:opera|opr)[\s\/](\d+(\.\d+)?)/i)
      };
    } else if (/msie|trident/i.test(ua)) {
      result = {
        name: "Internet Explorer",
        msie: t,
        version: getFirstMatch(/(?:msie |rv:)(\d+(\.\d+)?)/i)
      };
    } else if (/chrome|crios|crmo/i.test(ua)) {
      result = {
        name: "Chrome",
        chrome: t,
        version: getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.\d+)?)/i)
      };
    } else if (/firefox|iceweasel/i.test(ua)) {
      result = {
        name: "Firefox",
        firefox: t,
        version: getFirstMatch(/(?:firefox|iceweaselem)[ \/](\d+(\.\d+)?)/i)
      };
    } else if (/safari/i.test(ua)) {
      result = {
        name: "Safari",
        safari: t,
        version: versionIdentifier
      };
    } else {
      result = {};
    }

    return result;
  };

  browserObj = detect(typeof navigator !== "undefined" ? navigator.userAgent : "");
  return browserObj;
};
/**
 * Add browser + version to element
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} noVersion
 */


var addClassBrowser = function addClassBrowser(elem, noVersion) {
  var b = browser();
  if (isEmptyObject(b)) return;
  var className = b.msie ? "ie" : b.name.toLowerCase();
  var version = !noVersion ? parseInt(b.version, 10) : "";

  if (elemExists(elem)) {
    addClass(elem, className + version);
  }
};
/**
 * Detect OS
 *
 * @export
 * @returns {Object} os object
 * @returns {String} os.name
 */


var OSName = function OSName() {
  var osObj = {},
      detect;

  detect = function detect(ua) {
    var result = {};

    if (/android/i.test(ua)) {
      result = {
        name: "Android"
      };
    } else if (/win/i.test(ua)) {
      result = {
        name: "Windows"
      };
    } else if (/mac/i.test(ua)) {
      result = {
        name: "MacOS"
      };
    } else if (/linux/i.test(ua)) {
      result = {
        name: "Linux"
      };
    } else if (/x11/i.test(ua)) {
      result = {
        name: "UNIX"
      };
    } else {
      result = {
        name: "Unknown"
      };
    }

    return result;
  };

  osObj = detect(typeof navigator !== "undefined" ? navigator.userAgent : "");
  return osObj;
};
/**
 * Fix IE(11) Error: Object doesn't support this action
 * - CustomEvent
 * - Object.assign
 * - forEach (on NodeLis)
 * - matches
 *
 * @export
 */


var supportIEObjects = function supportIEObjects(elem) {
  var b = browser();
  if (isEmptyObject(b)) return;

  if (!!b.msie) {
    var _CustomEvent = function _CustomEvent(event, params) {
      params = params || {
        bubbles: false,
        cancelable: false,
        detail: undefined
      };
      var evt = document.createEvent("CustomEvent");
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
    };

    _CustomEvent.prototype = window.Event.prototype;
    window.CustomEvent = _CustomEvent; // missing assign

    if (typeof Object.assign != "function") {
      Object.assign = function (target) {
        "use strict";

        if (target === null) {
          throw new TypeError("Cannot convert undefined or null to object");
        }

        target = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var source = arguments[index];

          if (source !== null) {
            for (var key in source) {
              if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = source[key];
              }
            }
          }
        }

        return target;
      };
    } // missing forEach on NodeList for IE11


    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    } // missing match


    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector;
    }
  }
};
/**
 * Add safari + version to element is browser is Safari
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} noVersion
 */


var addClassSafari = function addClassSafari(elem, noVersion) {
  var b = browser();
  if (isEmptyObject(b)) return;
  var className = !noVersion ? "safari" + parseInt(b.version, 10) : "safari";

  if (!!b.safari) {
    if (elemExists(elem)) {
      addClass(elem, className);
    }
  }
};
/**
 * Add Chrome + version to element is browser is chrome
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} noVersion
 */


var addClassChrome = function addClassChrome(elem, noVersion) {
  var b = browser();
  if (isEmptyObject(b)) return;
  var className = !noVersion ? "chrome" + parseInt(b.version, 10) : "chrome";

  if (!!b.chrome) {
    if (elemExists(elem)) {
      addClass(elem, className);
    }
  }
};
/**
 * Add Firefox + version to element is browser is firefox
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} noVersion
 */


var addClassFirefox = function addClassFirefox(elem, noVersion) {
  var b = browser();
  if (isEmptyObject(b)) return;
  var className = !noVersion ? "firefox" + parseInt(b.version, 10) : "firefox";

  if (!!b.firefox) {
    if (elemExists(elem)) {
      addClass(elem, className);
    }
  }
};
/**
 * Add ie + version to element is browser is IE
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} noVersion
 */


var addClassIE = function addClassIE(elem, noVersion) {
  var b = browser();
  if (isEmptyObject(b)) return;
  var className = !noVersion ? "ie" + parseInt(b.version, 10) : "ie";

  if (!!b.msie) {
    if (elemExists(elem)) {
      addClass(elem, className);
    }
  }
};
/**
 * Truncate text
 *
 * @export
 * @param {String} value
 * @param {Number} maxChars
 * @param {String} position
 * @param {String} ellipseText
 * @returns {String}
 */


var textTruncate = function textTruncate(value, maxChars, position, ellipseText) {
  if (position === "last") {
    value = value.substr(0, maxChars - ellipseText.length) + ellipseText;
  } else if (position === "first") {
    value = ellipseText + value.substr(value.length - (maxChars - ellipseText.length));
  } else {
    var middle = Math.floor(maxChars / 2) - ellipseText.length;
    value = value.substr(0, middle) + ellipseText + value.substr(value.length - middle, value.length);
  }

  return value;
};
/**
 * Check if value is blank|undefined|null
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */


var isBlank = function isBlank(value) {
  if (!value) {
    value = "";
  }

  return /^\s*$/.test(value);
};
/**
 * Check if value is true
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */


var isTrue = function isTrue(value) {
  if (typeof value === "string") {
    value = value.trim().toLowerCase();
  }

  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;

    default:
      return false;
  }
};
/**
 * Get window dimension
 *
 * @export
 * @returns {Object} dimension object
 * @returns {Number} dimension.width
 * @returns {Number} dimension.height
 */


var windowsDimension = function windowsDimension() {
  var w = window;
  var d = document;
  var e = d.documentElement;
  var g = d.getElementsByTagName("body")[0];
  var x = w.innerWidth || e.clientWidth || g.clientWidth;
  var y = w.innerHeight || e.clientHeight || g.clientHeight;
  return {
    width: x,
    height: y
  };
};
/**
 * Get elements outer height (height+margin)
 *
 * @export
 * @param {Element} elem
 * @returns {Number}
 */


var outerHeight = function outerHeight(elem) {
  var height = elem.offsetHeight;
  var style = getComputedStyle(elem);
  height += parseInt(style.marginTop) + parseInt(style.marginBottom);
  return height;
};
/**
 * Get elements outer width (width+margin)
 *
 * @export
 * @param {Element} elem
 * @returns {Number}
 */


var outerWidth = function outerWidth(elem) {
  var width = elem.offsetWidth;
  var style = getComputedStyle(elem);
  width += parseInt(style.marginLeft) + parseInt(style.marginRight);
  return width;
};
/**
 * Get elements height (height)
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} handelHidden
 * @returns {Number}
 */


var height = function height(elem, handelHidden) {
  if (handelHidden) {
    return elem.clientHeight || width(elem.parentElement, handelHidden);
  } else {
    return elem.clientHeight;
  }
};
/**
 * Get elements width (width)
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} handelHidden
 * @returns {Number}
 */


var width = function width(elem, handelHidden) {
  if (handelHidden) {
    return elem.clientWidth || width(elem.parentElement, handelHidden);
  } else {
    return elem.clientWidth;
  }
};
/**
 * Get elements offset Height (height + border )
 *
 * @export
 * @param {Element} elem
 * @returns {Number}
 */


var offsetHeight = function offsetHeight(elem) {
  return elem.offsetHeight;
};
/**
 * Get elements offset Width (width + border )
 *
 * @export
 * @param {Element} elem
 * @returns {Number}
 */


var offsetWidth = function offsetWidth(elem) {
  return elem.offsetWidth;
};
/**
 * Get elements position, relative to the offset parent
 *
 * @export
 * @param {Element} elem
 * @returns {Object}
 */


var position = function position(elem) {
  return {
    top: elem.offsetTop,
    left: elem.offsetLeft
  };
};
/**
 * Get coordinates of the element, relative to the document.
 *
 * @export
 * @param {Element} elem
 * @returns {Object} object
 * @returns {Number} left
 * @returns {Number} top
 */


var offset = function offset(elem) {
  var rect = elem.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft
  };
};
/**
 * Get coordinates of the element, relative to the parent.
 *
 * @export
 * @param {Element} elem
 * @returns {Object} object
 * @returns {Number} top
 * @returns {Number} right
 * @returns {Number} bottom
 * @returns {Number} left
 */


var offsetToParent = function offsetToParent(elem) {
  var parentElem = parent(elem);
  var rectParent = parentElem.getBoundingClientRect();
  var rect = elem.getBoundingClientRect();
  return {
    top: rect.top - rectParent.top,
    right: rect.right - rectParent.right,
    bottom: rect.bottom - rectParent.bottom,
    left: rect.left - rectParent.left
  };
};
/**
 * Check is object is empty
 *
 * @export
 * @param {Object} obj
 * @returns {Boolean}
 */


var isEmptyObject = function isEmptyObject(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }

  return true;
};
/**
 * Get browsers viewport
 *
 * @export
 * @returns {Object} viewport
 * @returns {String} viewport.name
 * @returns {String} viewport.prefix
 */


var viewport = function viewport() {
  var viewportType = {},
      getViewPortWidth;

  getViewPortWidth = function getViewPortWidth(data) {
    var result = {
      name: "",
      prefix: "",
      width: 0
    },
        body = document.querySelector("body"),
        viewPortWidth = 0;
    body.style.overflow = "hidden";
    viewPortWidth = windowsDimension().width;
    body.style.overflow = "";

    if (isEmptyObject(data)) {
      return result;
    }

    if (viewPortWidth < data.mobile.max) {
      result = {
        name: "mobile",
        prefix: "xs"
      };
    } else if (viewPortWidth >= data.tablet.min && viewPortWidth <= data.tablet.max) {
      result = {
        name: "tablet",
        prefix: "sm"
      };
    } else if (viewPortWidth >= data.desktop.min && viewPortWidth <= data.desktop.max) {
      result = {
        name: "desktop",
        prefix: "md"
      };
    } else if (viewPortWidth >= data.large.min && viewPortWidth <= data.large.max) {
      result = {
        name: "large",
        prefix: "lg"
      };
    } else if (viewPortWidth >= data.xl.min && viewPortWidth <= data.xl.max) {
      result = {
        name: "xl",
        prefix: "xl"
      };
    } else if (viewPortWidth >= data.xl2.min && viewPortWidth <= data.xl2.max) {
      result = {
        name: "2xl",
        prefix: "2xl"
      };
    } else {
      result = {
        name: "3xl",
        prefix: "3xl"
      };
    }

    result.width = viewPortWidth;
    return result;
  };

 // viewportType = getViewPortWidth(!isEmptyObject(apricotData.viewports) ? apricotData.viewports : {});
  return viewportType;
};
/**
 * Trigger "apricot_breakpointChange" event on document when breakpoint changes
 *
 * @export
 * @param {Boolean} start
 * @returns {Event} event
 * @returns {Object} event.data: viewport object
 */


var breakpoints = function breakpoints(start) {
  //Make sure we only declare custom apricot_breakpointChange event once
  if (document.cbBreakpoints) {
    return false;
  }

  var vp = viewport();
  var event = new CustomEvent("apricot_breakpointChange"); // Default: false

  start = !!start ? true : false;
  document.cbViewport = vp; //If start, trigger event on page load

  if (!!start) {
    document.addEventListener("DOMContentLoaded", function () {
      // Dispatch the event
      event.data = viewport;
      document.dispatchEvent(event);
    });
  } //Check breakpoint status on resize


  window.addEventListener("resize", function () {
    var currentViewport = viewport();
    var oldViewport = document.cbViewport; // If viewport has changed trigger event

    if (oldViewport.name !== currentViewport.name) {
      event.data = currentViewport;
      document.dispatchEvent(event);
      document.cbViewport = currentViewport;
    }
  });
  document.cbBreakpoints = true;
};
/**
 * Check if monitor supports  retina display
 *
 * @export
 * @returns {Boolean}
 */


var isRetina = function isRetina() {
  return window.devicePixelRatio > 1 || window.matchMedia && window.matchMedia("only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)").matches;
};
/**
 * Generate unique ID for HTML element
 *
 * @export
 * @param {Number=} idLength
 * @param {String=} prefix
 * @returns {String}
 */


var uniqueID = function uniqueID(idLength, prefix) {
  var charArr = "_0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz".split(""),
      id = "";

  if (!idLength) {
    idLength = Math.floor(Math.random() * charArr.length);
  }

  for (var i = 0; i < idLength; i++) {
    id += charArr[Math.floor(Math.random() * charArr.length)];
  }

  if (!!prefix) {
    id = prefix + id;
  }

  if (elemExists(document.getElementById(id))) {
    return uniqueID(idLength);
  } else {
    return id;
  }
};
/**
 * Return or set HTML elements attribute
 *
 * @export
 * @param {Element} elem
 * @param {String} attribute
 * @param {String|null}
 */


var attr = function attr(elem, attribute) {
  var value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  if (!elemExists(elem)) return;

  if (value) {
    elem.setAttribute(attribute, value);
  } else {
    return elem.getAttribute(attribute);
  }
};
/**
 * Remove HTML elements attribute
 *
 * @export
 * @param {Element} elem
 * @param {String} attribute
 */


var removeAttr = function removeAttr(elem, attribute) {
  if (!elemExists(elem)) return;
  elem.removeAttribute(attribute);
};
/**
 * Detect HTML documents language setting
 *
 * @export
 * @returns {String}
 */


var detectLang = function detectLang() {
  var lang = !!document.documentElement.getAttribute("lang") ? document.documentElement.getAttribute("lang") : "en";

  if (lang.indexOf("-") >= 0) {
    lang = lang.split("-")[0];
  }

  return lang;
};
/**
 * Convert HTML string to element
 *
 * @param {String} HTML representing any number of sibling elements
 * @return {NodeList}
 */


function htmlToElements(html) {
  var template = document.createElement("template");
  html = html.trim();
  template.innerHTML = html;
  return template.content.childNodes;
}
/**
 * Element contains
 *
 * @export
 * @param {Element} elem
 * @param {Element} child
 */


var contains = function contains(elem, child) {
  return elem !== child && elem.contains(child);
};
/**
 * Wrap element inside wrapper element
 *
 * @export
 * @param {Element} elem
 * @param {Element} wrapper
 */


var wrap = function wrap(elem, wrapper) {
  elem.parentNode.insertBefore(wrapper, elem);
  wrapper.appendChild(elem);
};
/**
 * Wrap an element around another set of elements
 *
 * @export
 * @param {Element[]} elms
 * @param {Element} wrapper
 */


var wrapAll = function wrapAll(elms, wrapper) {
  var el = elms.length ? elms[0] : elms,
      parent = el.parentNode;
  wrapper.appendChild(el);

  while (elms.length) {
    wrapper.appendChild(elms[0]);
  }

  if (parent) {
    parent.appendChild(wrapper);
  }
};
/**
 * Unwrap element from parent
 *
 * @export
 * @param {Element} elem
 */


var unwrap = function unwrap(elem) {
  var parent = elem.parentNode; // move all children out of the element

  while (elem.firstChild) {
    parent.insertBefore(elem.firstChild, elem);
  }
};
/**
 * Get/Set Element text
 * @export
 * @param {Element} elem
 * @param {String} content
 * @returns {(String|null)}
 */


var elemText = function elemText(elem) {
  var content = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (content) {
    elem.textContent = content;
  } else {
    return elem.textContent;
  }
};
/**
 * Empty Element
 * @export
 * @param {Element} elem
 */


var empty = function empty(elem) {
  elem.innerHTML = "";
};
/**
 * Show element, set display to block
 *
 * @export
 * @param {Element} elem
 */


var show = function show(elem) {
  elem.style.display = "block";
};
/**
 * Hide element, set display to none
 *
 * @export
 * @param {Element} elem
 */


var hide = function hide(elem) {
  elem.style.display = "none";
};
/**
 * Toggle element visibility
 *
 * @export
 * @param {Element} elem
 * @param {Boolean} state
 */


var toggleDisplay = function toggleDisplay(elem, state) {
  if (typeof state !== "undefined") {
    if (state) {
      show(elem);
    } else if (!state) {
      hide(elem);
    }
  } else {
    // If the element is visible, hide it
    if (window.getComputedStyle(elem).display === "block") {
      hide(elem);
      return;
    } // Otherwise, show it


    show(elem);
  }
};
/**
 * Check if the given variable is a function
 * @export
 * @argument {Any} functionToCheck - variable to check
 * @returns {Boolean} answer to: is a function?
 */


var isFunction = function isFunction(functionToCheck) {
  var getType = {};
  return functionToCheck && getType.toString.call(functionToCheck) === "[object Function]";
};
/**
 * Check if prefers-reduced-motion is set
 * @export
 * @returns {Boolean}
 */


var reduceMotionChanged = function reduceMotionChanged() {
  var query = "(prefers-reduced-motion: reduce)";
  return window.matchMedia(query).matches;
};
/**
 * Check if high contrast is supported
 * @export
 * @param {String} color
 * @returns {Boolean}
 */


var isHighContrast = function isHighContrast(color) {
  // option to pass default font-color
  if (!color) {
    color = "rgb(30, 30, 30)";
  }

  if (document.querySelector("body").style.color !== color) {
    return true;
  } else {
    return false;
  }
};
/**
 * Display error message in terminal
 *
 * @export
 * @param {String} message
 * @param {Object} obj
 */


var error = function error(message, obj) {
  _message(true, message, obj);
};
/**
 * Display warning message in terminal
 *
 * @export
 * @param {String} message
 * @param {Object} obj
 */


var warning = function warning(message, obj) {
  _message(false, message, obj);
};

var _message = function _message(mode, message, obj) {
  switch (mode) {
    case true:
      if (obj) {
        console.error(message, obj);
      } else {
        console.error(message);
      }

      break;

    case false:
      if (obj) {
        console.warn(message, obj);
      } else {
        console.warn(message);
      }

      break;

    default:
      console.log(message, obj);
  }
};
/**
 * Include a css file, creating new link DOM element
 * @export
 * @argument {String} css_file - variable to check
 * @returns {Boolean} False
 */


var include_css = function include_css(css_file) {
  var html_doc = document.getElementsByTagName("head")[0];
  var css = document.createElement("link");
  css.setAttribute("rel", "stylesheet");
  css.setAttribute("type", "text/css");
  css.setAttribute("href", css_file);
  html_doc.appendChild(css); // alert state change

  css.onload = function () {
    var event = new CustomEvent("apricot_cssLoaded");
    html_doc.dispatchEvent(event);
  };

  return false;
};
/**
 * Include a js file, creating new script DOM element
 * @export
 * @argument {String} js_file - variable to check
 * @returns {Boolean} False
 */


var include_js = function include_js(js_file) {
  var html_doc = document.getElementsByTagName("head")[0];
  var js = document.createElement("script");
  js.setAttribute("type", "text/javascript");
  js.setAttribute("src", js_file);
  html_doc.appendChild(js);

  js.onload = function () {
    var event = new CustomEvent("apricot_jsLoaded");
    html_doc.dispatchEvent(event);
  };

  return false;
};
/**
 * Disable browser context menu on right click
 *
 * @export
 * @param {Element} elem
 */


var disableRightClick = function disableRightClick(elem) {
  elem.addEventListener("contextmenu", function (e) {
    e.preventDefault();
    return false;
  });
};
/**
 * swipe detection
 *
 * @export
 * @param {Element} elem
 * @returns {Event} events swipe_end|swipe_move
 * @returns {Object} event.data: swipe object
 */


var swipe = function swipe(elem, remove) {
  var touchDown = false;
  var originalPosition = null;

  var swipeInfo = function swipeInfo(e) {
    var x = 0;
    var y = 0;
    var dx, dy;

    if ("undefined" !== typeof e.pageX) {
      x = e.pageX;
      y = e.pageY;
    } else if (e.changedTouches && e.changedTouches[0]) {
      x = e.changedTouches[0].pageX;
      y = e.changedTouches[0].pageY;
    }

    dx = x > originalPosition.x ? "right" : "left";
    dy = y > originalPosition.y ? "down" : "up";
    return {
      direction: {
        x: dx,
        y: dy
      },
      offset: {
        x: x - originalPosition.x,
        y: originalPosition.y - y
      }
    };
  };

  var down = function down(e) {
    touchDown = true;

    if ("undefined" !== typeof e.pageX) {
      originalPosition = {
        x: e.pageX,
        y: e.pageY
      };
    } else if (e.changedTouches && e.changedTouches[0]) {
      originalPosition = {
        x: e.changedTouches[0].pageX,
        y: e.changedTouches[0].pageY
      };
    }
  };

  var up = function up(e) {
    var event = new CustomEvent("swipe_end");
    var d = swipeInfo(e);
    event.data = d;
    elem.dispatchEvent(event);
    touchDown = false;
    originalPosition = null;
  };

  var move = function move(e) {
    if (!touchDown) return;
    var event = new CustomEvent("swipe_move");
    event.data = swipeInfo(e);
    elem.dispatchEvent(event);
  };

  if (remove) {
    elem.removeEventListener("touchstart", down, {
      passive: true
    });
    elem.removeEventListener("mousedown", down, {
      passive: true
    });
    elem.removeEventListener("touchend", up, {
      passive: true
    });
    elem.removeEventListener("mouseup", up);
    elem.removeEventListener("touchmove", move, {
      passive: true
    });
    elem.removeEventListener("mousemove", move);
  } else {
    elem.addEventListener("touchstart", down, {
      passive: true
    });
    elem.addEventListener("mousedown", down);
    elem.addEventListener("touchend", up, {
      passive: true
    });
    elem.addEventListener("mouseup", up);
    elem.addEventListener("touchmove", move, {
      passive: true
    });
    elem.addEventListener("mousemove", move);
  }

  return true;
};
/**
 * copy to clipboard
 *
 * @export
 * @param {Element} elem
 * @param {String} text
 */


var copyToClipboard = function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    window.clipboardData.clearData();
    return window.clipboardData.setData("Text", text);
  } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed";
    document.body.appendChild(textarea);
    textarea.select();

    try {
      return document.execCommand("copy");
    } catch (ex) {
      console.warn("Failed to copy.", ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};
/**
 * Block ESC key for Apricot components
 *
 */


var blockEsc = function blockEsc() {
  attr(document.getElementsByTagName("body")[0], "data-cb-esc", "true");
};
/**
 * UN-Block ESC key for Apricot components
 *
 * @export
 * @param {Number} ms
 */


var unBlockEsc = function unBlockEsc() {
  var ms = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 150;
  setTimeout(function () {
    removeAttr(document.getElementsByTagName("body")[0], "data-cb-esc");
  }, ms);
};

export default {
  KEYS: KEYS,
  FOCUSABLE_ELEMENTS: FOCUSABLE_ELEMENTS,
  addClass: addClass,
  addClassBrowser: addClassBrowser,
  addClassChrome: addClassChrome,
  addClassFirefox: addClassFirefox,
  addClassIE: addClassIE,
  addClassSafari: addClassSafari,
  append: append,
  attr: attr,
  blockEsc: blockEsc,
  breakpoints: breakpoints,
  browser: browser,
  contains: contains,
  copyToClipboard: copyToClipboard,
  detectLang: detectLang,
  disableRightClick: disableRightClick,
  elemExists: elemExists,
  elemText: elemText,
  empty: empty,
  error: error,
  getByClass: getByClass,
  getById: getById,
  getByTag: getByTag,
  getClosest: getClosest,
  getNextSibling: getNextSibling,
  getPreviousSibling: getPreviousSibling,
  getValue: getValue,
  hasClass: hasClass,
  height: height,
  hide: hide,
  htmlToElements: htmlToElements,
  include_css: include_css,
  include_js: include_js,
  insertAfter: insertAfter,
  insertBefore: insertBefore,
  isBlank: isBlank,
  isEmptyObject: isEmptyObject,
  isFunction: isFunction,
  isHighContrast: isHighContrast,
  isKey: isKey,
  isRetina: isRetina,
  isTrue: isTrue,
  offset: offset,
  offsetHeight: offsetHeight,
  offsetToParent: offsetToParent,
  offsetWidth: offsetWidth,
  OSName: OSName,
  outerHeight: outerHeight,
  outerWidth: outerWidth,
  parent: parent,
  position: position,
  reduceMotionChanged: reduceMotionChanged,
  remove: remove,
  removeAttr: removeAttr,
  removeClass: removeClass,
  show: show,
  supportIEObjects: supportIEObjects,
  swipe: swipe,
  textTruncate: textTruncate,
  toggleClass: toggleClass,
  toggleDisplay: toggleDisplay,
  unBlockEsc: unBlockEsc,
  uniqueID: uniqueID,
  unwrap: unwrap,
  viewport: viewport,
  warning: warning,
  whichKey: whichKey,
  width: width,
  windowsDimension: windowsDimension,
  wrap: wrap,
  wrapAll: wrapAll
};