import { getCurrentCourseInstance, getCourseParameter,
         isCSP, isSeminar, isResearch, isArt }				from '../entities/courseinstance'
import { profileApnumber }									from '../profile/profile_module'

// returns a promise that resolves to alertReason
export async function getSubmissionAlertData(lesson) {
	//console.log('getSubmissionAlertData')
	let func = async function (lesson) {
		let courseCollection = getCurrentCourseInstance().sections
		let examIntent = null
		courseCollection.forEach(element => {
			if (element.enrollment.examIntent === true) {
				examIntent = true
			}
			else if (element.enrollment.examIntent === false && (examIntent === false || examIntent === null)) {
				examIntent = false
			}
		});

		let apNumberCls = await profileApnumber()
		let apNumberEnabled = getCourseParameter('apnumberEnabled')
		let	apNumberEnabledAndEntered = apNumberEnabled && apNumberCls.entered()
		let examIntentEnabled = getCourseParameter('examintentEnabled')

		let csp = isCSP()
		let research = isResearch()
		let seminar = isSeminar()
		let	art = isArt()

		let alertReason;
		// System should not display any alert message on Performance Task pages 
		// for assignments that do not have submissions in Digital Portfolio. (POD for Research) 
		// and (TMP, IMP, OD for Seminar)
		let code = lesson.abbreviation
		if (research && _.contains(['Presentation and Oral Defense', 'POD'], code) ||
			seminar && _.contains(['TMP', 'IMP', 'OD'], code)) {
			return
		}

		if (csp || art ) {
			if (examIntentEnabled && !examIntent ) {
				if (!apNumberEnabled || apNumberEnabledAndEntered) {
					alertReason = 'registerExam'
				}
			}	
		}

		if (research || seminar ) {
			if (!examIntentEnabled) {
				if (apNumberEnabledAndEntered) {
					if (!examIntent || examIntent === null) alertReason = 'registerExam';
				}	
			}
		}
		//console.log('getSubmissionAlertData alertReason ' + alertReason)
		return alertReason
		// if (alertReason) {
		// 	_.each(regions, region => {
		// 		region.show(new Assignment.SubmissionAlertView({ alertReason: alertReason }))
		// 	})
		// }
	}

	return func(lesson)
}

// returns a promise that resolves to alertReason
export async function getDraftAlertData() {
	let func = async function () {
		let courseCollection = getCurrentCourseInstance().sections
		let examIntent = null
		courseCollection.forEach(element => {
			if (element.enrollment.examIntent === true) {
				examIntent = true
			}
			else if (element.enrollment.examIntent === false && (examIntent === false || examIntent === null)) {
				examIntent = false
			}
		});
		let apNumberCls = await profileApnumber()
		let apNumberEnabled = getCourseParameter('apnumberEnabled')
		let	apNumberEntered = apNumberEnabled && apNumberCls.entered()
		let csp = isCSP()
		let research = isResearch()
		let seminar = isSeminar() ///^40/.test(getCourseInstanceId())
		let	art = isArt()
		let	scoringEnabled = getCourseParameter('scoringEnabled')
		let	examIntentEnabled = getCourseParameter('examintentEnabled')
		let	alertReason = null //initialize as null unless a condition is met


		if ((csp || art) && scoringEnabled) { 
			if (examIntentEnabled && apNumberEnabled) {
				if (examIntent === null && apNumberEntered) {
					alertReason = 'submitFinal'
				} else if (examIntent) {
					alertReason = 'submitFinal'
				}
			} else if (examIntentEnabled && !apNumberEnabled) {
				if (examIntent) {
					alertReason = 'submitFinal'
				}
			} else if (!examIntentEnabled && apNumberEntered && apNumberEnabled) {
				alertReason = 'submitFinal'
			}
		} else if ((research || seminar ) && scoringEnabled) {
			if (examIntent && !apNumberEnabled) {
				alertReason = 'submitFinal'
			} else if (examIntent === null && apNumberEnabled && apNumberEntered) {
				alertReason = 'submitFinal'
			} else if (examIntent && apNumberEnabled && apNumberEntered) {
				alertReason = 'submitFinal'
			}
		}
		//console.log('getDraftAlertData alertReason ' + alertReason)
		return alertReason
		// if (alertReason) {
		// 	_.each(regions, region => {
		// 		region.show(new Assignment.SubmissionAlertView({ alertReason: alertReason }))
		// 	})
		//}
	}

	//console.log('getDraftAlertData calling func')
	return func()
}
