import React                from 'react';
import { AlertComp }        from '../../_core/views/alert.jsx'
import { getAs }            from '../../_core/util/request'
import { showLoadingView, closeLoadingView }  from '../../_core/views/loading_view'
import {Spinner} from "@cb/apricot-react";

const Viewer = (props) => {
    const [loading, setLoading] = useState(false)
    const [onMount, setOnMount] = useState(false)
    const [pageDocument, setPageDocument] = useState({})
    const [alertProps, setAlertProps] = useState({})
    const [UIElements, setUIElements] = useState({
        pdfContainer:       () => document.querySelector('#pdfViewerLabel .js-pdf-container'),
        zoomButtons:        () => document.querySelectorAll('#zoomButtons button'),
        errorContainer:     () => document.querySelector('#pdfViewerLabel .alert-wrapper')
    })
    const [pages, setPages] = useState({
        savedPages: [],
        zoom: 1
    })

    const renderPage = (page, i, elem) => {
        let scale = Math.max(1, elem.clientWidth / page.view[2]) * pages.zoom
        let viewport = page.getViewport({ scale })
        let canvas = document.createElement('canvas')
        canvas.height = viewport.height
        canvas.width = viewport.width
        canvas.setAttribute('id', i);
        page.render({ canvasContext: canvas.getContext('2d'), viewport })
        return canvas
    }

    const addAccessibleText = (textContent) => {
        let text = _.pluck(textContent.items, 'str').join('')
        // style sheets don't apply to this panel built like this, so I have to make the sr-only one pixel by hand
        const readOnlyElem = document.createElement('div')
        readOnlyElem.innerHTML = text
        readOnlyElem.style.height = '1px'
        readOnlyElem.style.width = '1px'
        readOnlyElem.style.overflow = 'hidden'
        UIElements.pdfContainer().append(readOnlyElem)
    }

    const changePageZoom = (e) => {
        let { currentTarget: button } = e
        const isZoomIn = button.classList.contains('js-zoom-in')
        const newZoom = isZoomIn ? 0.1 : -0.1
        const finalZoom = pages.zoom + newZoom
        if (Math.round(finalZoom) !== 1) button.classList.add('cb-disabled')
        else {
            const isZoom1Disabled = UIElements.zoomButtons()[0].classList.contains('cb-disabled'), isZoom2Disabled = UIElements.zoomButtons()[1].classList.contains('cb-disabled')
            if (isZoom1Disabled) UIElements.zoomButtons()[0].classList.remove('cb-disabled')
            else if (isZoom2Disabled) UIElements.zoomButtons()[1].classList.remove('cb-disabled')
            setPages({ ...pages, zoom: finalZoom  })
        }
    }

    const scalePages = (resize) => {
        let canvases = document.querySelectorAll('#pdfViewerLabel canvas'), elem = document.querySelector('#pdfViewerLabel')
        for (let i = 0; i < pages.savedPages.length; i++) {
            let page = pages.savedPages[i]
            if (page && (page.zoom !== pages.zoom || resize)) {
                canvases[i].replaceWith(renderPage(page.page, i, elem))
                page.zoom = pages.zoom
            }
        }
    }

    const renderDocument = async (doc) => {
        if (doc._pdfInfo) {
            for (let i = 1; i <= doc._pdfInfo.numPages; i++) {
                let page = await doc.getPage(i)
                let textContent = await page.getTextContent()
                pages.savedPages.push({ page, zoom: pages.zoom })
                let elem = document.querySelector('#pdfViewerLabel')
                UIElements.pdfContainer().append(renderPage(page, i, elem))
                addAccessibleText(textContent)
            }
        }
    }

    const downloadDocument = async () => {
        try {
            setLoading(true)
            let url = props.upload.getPdfRenderUrl()
            let arrayBuffer = await getAs(url, 'arraybuffer');
            let responseArray = new Uint8Array(arrayBuffer);
            setPageDocument(await PDFJS.getDocument(responseArray).promise)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.error('Error: ', e)
            if (onMount) {
                setAlertProps({
                    showAlert: true,
                    message: 'Error: This submission could not be downloaded.  Please try again.',
                    uniqueId: 'download-submission'
                })
            }
            UIElements.pdfContainer().setAttribute('tabindex', '-1')
        }
    }

    useEffect(() => {
        const getDocument = async () => {
            await downloadDocument()
        }

        setOnMount(true)
        getDocument()
        return setOnMount(false)
    }, [])

    useEffect(() => {
        const pageRender = async (document) => await renderDocument(document)

        if (Object.keys(pageDocument)) pageRender(pageDocument)
    }, [pageDocument])

    useEffect(() => {
        const isCanvasLoaded = UIElements.pdfContainer()?.childElementCount > 1
        if (!loading && isCanvasLoaded) setUIElements({
            ...UIElements,
            zoomButtons: () => document.querySelectorAll('#zoomButtons button')
        })
    }, [loading, pageDocument])

    useEffect(() => {
        scalePages()
    }, [pages.zoom])

    if (loading) return <Spinner />
    else return (
        <>
            <div className="row" id="zoomButtons">
                <div className="col-xs-12 text-right">
                    <button type="button" className="cb-btn cb-btn-sm btn-minimal js-zoom-out disabled cb-float-right modal-btn"
                            onClick={changePageZoom}>
                        <small>Zoom Out</small>
                    </button>
                    <button type="button" className="cb-btn cb-btn-sm btn-minimal js-zoom-in disabled cb-float-right modal-btn"
                            onClick={changePageZoom}>
                        <small>Zoom In</small>
                    </button>
                </div>
            </div>

            <div className="alert-wrapper container-fluid">
                {alertProps && alertProps.showAlert &&
                    <AlertComp params={alertProps}></AlertComp>
                }
            </div>
            <div id='pdfViewerLabel' className='row pdf-viewer order-last js-pdf-container' role='document'
                 aria-label='Document view. PDF can also be downloaded for review in Adobe Reader.' >
                <div className="col-xs-12">
                    <div id="jsPdfContainer" className="js-pdf-container" role='region' aria-labelledby='pdfViewerLabel' style={{ height: '500px', overflowY: 'auto' , overflowX: 'auto' }} />
                </div>
            </div>
        </>
    )
}


export default Viewer
