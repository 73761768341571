import MenuLinkComp                         from './menuLink'
import { PermissionComp }                   from './permission'
import StudentProgressComp                  from '../../progress/views/studentProgress'
import CMSDocsComp                          from '../../supportingmaterial/views/cmsDocs'
import { getCourseParameter }               from '../../entities/courseinstance'
import { filterModules }                    from '../../entities/modules'

export function StudentContentOverview({sectionId,courseInstanceId, moduleHolder, studentProps, textContent, partition}) {
    let courseFilter =  !/43/.test(courseInstanceId)
    let menuProps = {
        moduleHolder: filterModules(moduleHolder, false),
        sectionId: sectionId
    }
    let showPermission = getCourseParameter('noreusecheckboxEnabled')

    return (
        <div id={'overview_' + sectionId} key={'overview_' + sectionId}>
            <h3 id='title' tabIndex='-1' aria-labelledby='title region-static-text' className="h3">Overview</h3>
            {studentProps  && courseFilter &&
                <div id="personal-info-box">
                    <div id="progress-region">
                        <StudentProgressComp parms={studentProps} />
                    </div>
                    <hr className="js-separator" />
                    <div id="publishpermission-region">
                        {showPermission &&
                            <PermissionComp
                                permissionToReuse={studentProps.permissionToReuse}
                                saveDenial={saveDenial} />
                        }
                    </div>
                </div>
            }

            <div id="text-region" dangerouslySetInnerHTML={{ __html: textContent }} />
            <div id="links-region">
                <MenuLinkComp parms={menuProps} />
            </div>
            <div id="cmsmaterials-region">
                <CMSDocsComp partition={partition}></CMSDocsComp>
            </div>
            <div id="suppmaterials-region"></div>
        </div>
    )

    function saveDenial(permission) {
        try {
            studentProps.enrollment.savePermissionToReuse(permission)
            .then(() => {
                //console.log('saveDenial finished permission ' + permission)
            })    
        } catch(e) {
            console.error('savePermissionToReuse failed',e)
        }
    }
}