import React from 'react';

export default class DeniedComp extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='container alert-danger'>
                <div className="jumbotron">
                    <h1>Error:</h1>
                    <p>You do not have access to view this page.</p>
                </div>
            </div>
        )
    }
}