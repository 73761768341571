import MenuComp from "../../menu/views/menu";

const CourseLayout = ({ props, children }) => (
  <div className='row'>
    <div id='region-left-menu' className='col-md-3 col-sm-3'>
      <MenuComp moduleHolder={props.moduleHolder} />
    </div>
    <div className='col-md-9 col-sm-9'>
      <div id="region-main-desktop-alert" />
      <div id='region-course-content'>
        {children}
      </div>
    </div>
  </div>
)

export default CourseLayout