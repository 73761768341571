import { subject } from '../../utility/service'
import { logout }   from '../../auth/auth_module'

// https://www.saltycrane.com/blog/2014/09/bank-style-session-timeout-example-using-jquery-bootstrap-and-flask/
export class SessionMonitor {
    constructor(options) {
        let defaults = {
            // Session(cbtoken) lifetime (milliseconds) read from session storage
            sessTimeoutMin: 30,

            // #minutes before session expiration for the warning to be shown
            beforeWarningMin: 5,
        }

        // Add default variables and methods, user specified options, and non-overridable
        // public methods to the session monitor instance.
        let optionsExtended = Object.assign({}, defaults, options)

        this.warningTimeoutID = optionsExtended.warningTimeoutID
        this.sessTimeoutMin = optionsExtended.sessTimeoutMin
        this.beforeWarningMin = optionsExtended.beforeWarningMin
 
        this.waiting = false
        //this.started = false
        // this.extendWork
        _.bindAll(this, 'onWarning', 'start', 'renew')
    }

    resetTimers() {
        //console.log('MONITOR resetTimers')
        // Reset the session warning and session expiration timers.
        this.waiting = true
        let sessionTimeoutMs = this.sessTimeoutMin * 60 * 1000
        let beforeWarningMs = this.beforeWarningMin * 60 * 1000
        let warningTimeout = sessionTimeoutMs - beforeWarningMs
        //console.log('MONITOR starting a timer with ' + warningTimeout + 'ms')

        // beforeWarningMs ms before the expiration (sessionTimeoutMs) we want to paint a warning message
        window.clearTimeout(this.warningTimeoutID);
        this.warningTimeoutID = window.setTimeout(this.onWarning, warningTimeout);
    }

    start() {
        //console.log('MONITOR starting monitor')
        // rounds minutes to nearest 30 seconds, 0, .5 , 1.0, 1.5 ,,,
        let round = time => {
            return Math.round(time * 2) / 2
        }

        // get the iam timeout
        let item = JSON.parse(sessionStorage.getItem('iamSession'))
        let val = item?.expireTimeInMS
        val = val - (new Date()).getTime()
        this.sessTimeoutMin = val / 60000
        //console.log('MONITOR sessTimeoutMin ' + this.sessTimeoutMin)

        // this is only for TESTING, so the warning appears in 30 seconds
        //this.beforeWarningMin = this.sessTimeoutMin - .5

        // we are accepting time rounded to 30 seconds
        this.sessTimeoutMin = round(this.sessTimeoutMin)
        this.beforeWarningMin = round(this.beforeWarningMin)

        // beforeWarning must be in [0.5 , timeout-0.5]
        this.beforeWarningMin = Math.min(this.beforeWarningMin, this.sessTimeoutMin - 0.5)
        this.beforeWarningMin = Math.max(this.beforeWarningMin, 0.5)

        this.resetTimers()
        // normally the credential expiration is renewed whenever the user make a backend call
        // in our case, the token is only renewed when the use signs in, in which case
        // the 'start' method is called, so the doWork, extendWork and extendSession are not needed
    
        // clear the message area, reset timer
        // let doWork = () => {
        //     console.log('MONITOR doWork')
        //     subject.next({
        //         session: {
        //             show: false
        //         }
        //     })
        //     this.resetTimers();
        // }
        // this.extendWork = _.debounce(doWork, 1000, true)
        //this.started = true
    }

    // logout to get new credentials
    renew() {
        window.clearTimeout(this.warningTimeoutID);
        sessionStorage.setItem('renewUrl', window.location)
        //console.log('MONITOR renewUrl set to ' + sessionStorage.getItem('renewUrl'))
        logout();    
    }

    // this sends a message to timeout.jsx to paint the warning message
    // the timer code will decrement the displayed time, we only send the initial value
    onWarning() {
        //console.log('MONITOR onWarning')
        this.waiting = false
        subject.next({
            session: {
                sessMon: this,
                beforeWarningMin: this.beforeWarningMin,
                show: true
            }
        })
    }
}
