import React                              from 'react';
import { getModuleHolder }                from '../entities/modules'
import { showCourseContent }              from '../main/instruction_controller'
import { cmsModule }                      from '../content/static_content'
import { supportingData, cmsMaterials }   from '../supportingmaterial/supportingmaterial_controller'
import { bsliPut }                        from "../_core/util/request";
import { OverviewComp }                   from './views/overview'

export async function showModuleOverview(moduleId) {
  //console.log('showModuleOverview for moduleId ' + moduleId)
  let html = false
  let failMessage = false
  let data = await loadState(moduleId)

  cmsModule(moduleId)
    .then(module => {
      //console.log('showModuleOverview cmsModule finished',module)
      if (!!module) {
        html = module.Body ? module.Body : module.body
      } else {
        console.error('cmsModule failed')
        html = ''
        failMessage = 'Error: Content could not be loaded. Please try again later.'
      }
      let overviewView = <OverviewComp
        data={data} html={html} failMessage={failMessage}
        publishMaterial={publishMaterial} />
      showCourseContent(overviewView, 'Overview')
    })

  let moduleHolder = await getModuleHolder()
  document.dispatchEvent(new CustomEvent('pagevisit', { detail: moduleHolder.getModuleById(moduleId).abbreviation + '/overview' }))
}

function publishMaterial(publishable,moduleId) {
 // console.log('publishMaterial ',publishable)

  bsliPut(publishable.urlPM)
    .then(json => {
      showModuleOverview(moduleId)
    })
    .catch(err => {
      console.log('publishMaterial failed',err)
      showModuleOverview(moduleId)
    })
}

async function loadState(moduleId) {
  try {
    var asyncFuncs = [
      supportingData(moduleId),
      cmsMaterials(moduleId)
    ]
    const awaitedData = await Promise.all(asyncFuncs)
    return {
      moduleId: moduleId,
      supportingMaterialData: awaitedData[0],
      cmsDocs: awaitedData[1]
    }
  } catch (e) {
    return {
      moduleId: moduleId,
      supportingMaterialData: false,
      cmsDocs: false
    }
  }
}

