import React, { Fragment, useEffect, useState } from 'react'
import { Button, BlackButton, YellowButton } from '@cb/apricot-react'
import { subject, updateSubscription } from '../../utility/service'

const CSP_WR_ButtonBar = (props) => {
    const [emptySections, setEmptySections] = useState(true)
    const [submitDisabled, setSubmitDisabled] = useState(true)

    useEffect(() => {
        setEmptySections(props.anyEmpty)
    }, [props.anyEmpty])

    const refreshUploads = async () => {
    }
    useEffect(() => {
        if (props.toolKey) {
            if (props.toolKey === 'CSP_WR_1') {
                let subscription = subject.subscribe(message => {
                    if (message.refreshMenuBarWR1) {
                        refreshUploads()
                    }
                })
                updateSubscription('refreshMenuBarWR1', subscription)
            } else {
                let subscription = subject.subscribe(message => {
                    if (message.refreshMenuBarWR) {
                        refreshUploads()
                    }
                })
                updateSubscription('refreshMenuBarWR', subscription)
            }
        }
    }, [props.toolKey])


    let isFinalSubmitted = props.finalDate?.length > 0
    let tooManyWords = props.wordCount > 850

    const finalSubmittedAria = 'this assignment has been final submitted and cannot be edited'
    const tooManyWordsAria = `this assignment has too many words, the maximum number of words is 850 and this assignment has ${props.wordCount}`
    const emptySectionsAria = 'this assignment has required sections that are empty'

    useEffect(() => {
        setSubmitDisabled(emptySections || tooManyWords || isFinalSubmitted || !props.scoringEnabled || (props.isPastSoftDate && !props.hasExtension))
    }, [emptySections, tooManyWords, isFinalSubmitted, !props.scoringEnabled, props.isPastSoftDate, props.hasExtension])

    let ariaInfo = (
        <Fragment>
            <span className='sr-only'>{isFinalSubmitted ? finalSubmittedAria : ''}</span>
            <span className='sr-only'>{tooManyWords ? tooManyWordsAria : ''}</span>
            <span className='sr-only'>{emptySections ? emptySectionsAria : ''}</span>
        </Fragment>
    )
    return (
        <div>
            {ariaInfo}
            <div className='buttonBar'>
                <span className='button' style={{ display: isFinalSubmitted ? 'none' : 'initial' }}>
                    {!props.isSaving && !isFinalSubmitted &&
                        <BlackButton
                            onClick={props.save}
                            disabled={tooManyWords || !props.canSave}
                            className='js-upload' small='true'
                            data-cb-apricot='tooltip'
                            id={`${props.toolKey}_1`}
                            data-cb-title="Save Changes">
                            Save
                        </BlackButton>
                    }
                    {props.isSaving && !isFinalSubmitted &&
                        <Button small='true' loading>Loading</Button>
                    }
                </span>
                <span className='button' style={{ display: isFinalSubmitted ? 'none' : 'initial' }}>
                    {!props.isFinalSaving && !isFinalSubmitted && props.scoringEnabled &&
                        <YellowButton
                            onClick={props.finalSubmit}
                            disabled={submitDisabled}
                            className='js-submit' small='true'
                            data-cb-apricot='tooltip' id={'CSP_Final_Submit'}
                            data-cb-title="Submit As Final">
                            Submit Final
                        </YellowButton>
                    }
                    {props.isFinalSaving && !isFinalSubmitted &&
                        <Button small='true' loading>Loading</Button>
                    }
                </span>
                {tooManyWords &&
                    <div className='warning' aria-live='polite'>There must be fewer than 850 words in the text entries, you have {props.wordCount}</div>
                }
            </div>
            {isFinalSubmitted &&
                <div className={'cb-gray9-bg add-padding'}>
                    <div>
                        <span className='button'>
                            <BlackButton
                                className='js-download-file' small='true'
                                onClick={props.downloadLatest}
                                data-cb-apricot='tooltip' id={'CSP_Download_Latest_WR'}
                                data-cb-title="Download Latest Saved File">
                                Download Latest
                                <span className="sr-only">{`Uploaded File, Final submitted at ${props.finalDate}`}</span>
                            </BlackButton>
                        </span>
                        <strong className={'cb-green1-color btn-sm-align'}>
                            <span className="cb-glyph cb-check" aria-hidden="true" />
                            <span> Final submitted {props.finalDate}</span>
                        </strong>
                    </div>
                </div>
            }
        </div>
    )
}

export default CSP_WR_ButtonBar
