import React, { useEffect } from 'react';
import { TeamItem } from './teamItem'

export function TeamList({ teams, dragName, canEditSection, addEnrollmentOnDrop, onTeamChange, initDelete,
    deleteEnrollmentFromTeam, selectedTeamId, selectedTeamTitle,
    clearTeamSelection, saveTeam, editTeam, showAlert, hasAlert, focus }) {

    let list = teams.map((team, index) => {
        return <TeamItem
            team={team} key={'team_' + index}
            dragName={dragName} canEditSection={canEditSection}
            onDrop={addEnrollmentOnDrop}
            onChange={onTeamChange}
            initDelete={initDelete}
            deleteEnrollmentFromTeam={deleteEnrollmentFromTeam}
            selectedTeamId={selectedTeamId}
            selectedTeamTitle={selectedTeamTitle}
            clearTeamSelection={clearTeamSelection}
            saveTeam={saveTeam} editTeam={editTeam}
            showAlert={showAlert} hasAlert={hasAlert} focus={focus}
        />
    });

    return (
        <div>
            {list}
        </div>
    )

}
