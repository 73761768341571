import simpleStorage from 'simplestorage.js'
import { request } from '../config/config'
import { getTeacherIds } from '../progress/entities/progress'

//export default function(Entities, app, Backbone, Marionette) {
//   Entities.Person = Entities.Model.extend({
//     urlRootPath : 'iam/auth',

//     defaults : {
//     firstName:'',
//     lastName:'',
//     orgUserRoles:[]
//   },
export class Person {
    constructor(person) {
        this.firstName = person.firstName
        this.lastName = person.lastName
        this.orgUserRoles = person.orgUserRoles
        this.roles = _.pluck(person.orgUserRoles, 'orgRole')
        this.id = person.id
    }

    role() {
        return (this.isStudent() ? 'student' :
            (this.isTeacher() ? 'teacher' :
                (this.isCoordinator() ? 'coordinator' :
                    (this.isAdmin() ? 'admin' :
                        (this.isReviewer() ? 'reviewer' :
                            '')))))
    }

    fullName() {
        return this.firstName + ' ' + this.lastName
    }

    getUniqueOrgs() {
        return _.uniq(this.orgUserRoles, (orgs) => orgs.orgId);
    }

    getOrgId() {
        return this.getUniqueOrgs()[0].orgId;
    }

    isTeacher() {
        return _.contains(this.roles, 'teacher');
    }

    isStudent() {
        return _.contains(this.roles, 'student');
    }

    isCoordinator() {
        return _.intersection(this.roles, ['ecoordinator', 'ApPortfolioCoordinator', 'ApCoordinator']).length > 0;
    }

    isEnrollmentCoordinatorForSection(sectionId) {
        let teacherIds = getTeacherIds(sectionId);
        return teacherIds.length > 0 ? teacherIds.includes(this.id) : false;
    }

    isProfessional() {
        return !this.isStudent();
    }

    isAdmin() {
        var adminRoles = ['ApProgramAdmin', 'ApPlatformAdmin']
        return _.intersection(this.roles, adminRoles).length > 0;
    }

    isSystemAdmin() {
        return _.contains(this.roles, 'ApPlatformAdmin')
    }

    isProgramAdmin() {
        return _.contains(this.roles, 'ApProgramAdmin')
    }

    isReviewer() {
        return _.contains(this.roles, 'ApPlagiarismReviewer')
    }
};

var currentPerson;

var API = {
    getCurrentPerson: () => {
        //logger.debug('Entities.API.getCurrentPerson');
        if (!currentPerson) {
            let personJSON = simpleStorage.get('person');
            if (personJSON) {
                currentPerson = new Person(personJSON);
            } else {
                currentPerson = null;
            }
        }
        return currentPerson;
    },

    setCurrentPerson: (person) => {
        //logger.debug('Entities.API.setCurrentPerson');
        //logger.debug('person:' + JSON.stringify(person));
        currentPerson = new Person(person);
        simpleStorage.set('person', person);
    },

    removeCurrentPerson: () => {
        //logger.debug('Entities.API.removeCurrentPerson');
        currentPerson = null;
        simpleStorage.deleteKey('person');
    }
};

export function getCurrentPerson() {
    return API.getCurrentPerson()
}

//app.reqres.setHandler('person', function() { return API.getCurrentPerson(); });

export function setCurrentPerson(person) {
    API.setCurrentPerson(person);
}

//   app.vent.on('authentication:success', function(person) {
//     //logger.debug("received 'authentication:success' event in Person");
//     API.setCurrentPerson(person);
//   });

export function removeCurrentPerson() {
    if (!!API.getCurrentPerson()) {
        API.removeCurrentPerson();
    }
}

//}
