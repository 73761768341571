import { bsliGet }                      from '../_core/util/request'
import { sortByName }                   from '../utility/util'
import { AssignmentStatus }             from './browserHelper'

// called by teacherBrowser
export function getAssignmentStatuses(sectionId, assignmentId, type) {
    let assignmentStatuses = new AssignmentStatuses()

    return new Promise((resolve,reject) => {
        assignmentStatuses.init(sectionId, assignmentId, type)
            .then(() => {
                resolve(assignmentStatuses)
            })
            .catch(() => {
                reject('assignment statuses did not load')
            })
        })
}

class AssignmentStatuses {
    constructor() {
        this.statuses = []
        //this.test()
    }

    init(sectionId, assignmentId, type) {
        return bsliGet('sections/' + sectionId + '/assignments/' + assignmentId + '/' + type)
            .then(json => {
           //     console.log('assignment status json', json)
                _.each(json, status => {
                    this.statuses.push(new AssignmentStatus(status))
                })
                this.sort(type)
            })
    }

    sort(type) {
        if (type === 'team') { // original code had 'teams' which is incorrect
            this.statuses = _.sortBy(this.statuses, 'title')
        } else {
            this.statuses = sortByName(this.statuses)/*_.sortBy(this.statuses, this.compare)*/
        }
    }
}
