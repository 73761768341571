import React, { Fragment } from 'react';

const NoStudentsComp = (props) => {

    return (
        <Fragment>
            {props.isTeamLesson &&
                <p>There are no students assigned to teams. Please go to Manage Teams to create teams.</p>
            }
            {!props.isTeamLesson &&
                <p>No students are enrolled in this class.</p>
            }
        </Fragment>
    )

}

export default NoStudentsComp;

