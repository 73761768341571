import { cmsModule } from "../content/static_content";
import { useParams } from "react-router-dom";
import { cmsMaterials, supportingData } from "../supportingmaterial/supportingmaterial_controller";
import { bsliPut } from "../_core/util/request";
import { showModuleOverview } from "./overview_module";
import { Spinner } from "@cb/apricot-react";
import { getModuleHolder } from "../entities/modules";
import CourseLayout from "../main/views/courseLayout";
import { OverviewComp } from "./views/overview";

const loadState = async (moduleId) => {
  try {
    var asyncFuncs = [
      supportingData(moduleId),
      cmsMaterials(moduleId)
    ]
    const awaitedData = await Promise.all(asyncFuncs)
    return {
      moduleId: moduleId,
      supportingMaterialData: awaitedData[0],
      cmsDocs: awaitedData[1]
    }
  } catch (e) {
    return {
      moduleId: moduleId,
      supportingMaterialData: false,
      cmsDocs: false
    }
  }
}
const publishMaterial = (publishable,moduleId) => {
 // console.log('publishMaterial ',publishable)

  bsliPut(publishable.urlPM)
    .then(json => {
   //   console.log('publishMaterial success',json)
    })
    .catch(err => {
      console.log('publishMaterial failed',err)
    })
}

const ModuleOverview = () => {
  const [loading, setLoading] = useState(true)
  const [props, setProps] = useState({})
  const [loadData, setLoadData] = useState(false)
  const { moduleId } = useParams()

  const refetchCMSContent = () => {
    setLoadData(!loadData)
  }

  const getCMSContent = async () => {
    let html = false
    let failMessage = false
    let data = await loadState(moduleId)
    let moduleHolder = await getModuleHolder()

    try {
      const res = await cmsModule(moduleId)
      html = res.Body ? res.Body : res.body
      setProps({
        html,
        failMessage,
        data,
        moduleHolder
      })
    } catch (err) {
      console.error('cmsModule failed: ', err)
      html = ''
      failMessage = 'Error: Content could not be loaded. Please try again later.'
      setProps({
        moduleHolder,
        html,
        failMessage,
        data
      })
    }
  }

  useEffect(() => {
    getCMSContent()
  }, [moduleId, loadData])

  useEffect(() => {
    if (props.moduleHolder) setLoading(false)
  }, [props.moduleHolder])

  if (loading) return <Spinner />

  if (props.moduleHolder) return (
    <>
      <CourseLayout props={{ moduleHolder: props.moduleHolder }}>
        <OverviewComp data={props.data} html={props.html} moduleHolder={props.moduleHolder} failMessage={props.failMessage} publishMaterial={publishMaterial} refetchCMSContent={refetchCMSContent} />
      </CourseLayout>
    </>
  )

  return null
}

export default ModuleOverview
