import React, { useEffect, useState }       from 'react';
import { CSPStep1 }                         from './cspStep1'
import { CSPStep2 }                         from './cspStep2'
import { focusOnFirst, noModalEscape, recordFocus,
    isValidProgLang, isValidCurriculum }      from '../../utility/util'
import CBForm                               from "@cb/apricot/CBForm";
import CBModal                              from "@cb/apricot/CBModal";
import { cleanUpBody }                      from '../../_core/views/modal';
import { Modal, NakedButton, YellowButton,
    Button }                                from '@cb/apricot-react';

export default function CSPSelectionModal(props) {
    const [alertProps, setalertProps] = useState(false);
    const [step, setStep] = useState(1);
    const [footerCls, setFooterCls] = useState()
    const [languages, setLanguages] = useState(props.selections.languages)
    const [langOther, setLangOther] = useState(props.selections.langOther)
    const [curriculums, setCurriculums] = useState(props.selections.curriculums)
    const [curriculumOther, setCurriculumOther] = useState(props.selections.curriculumOther)
    const [nextDisabled, setNextDisabled] = useState(true);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [closeEnable, setCloseEnable] = useState(false);

    useEffect(() => {
        document.querySelectorAll('.selectionContent').forEach(function (elem) {
            window.cb.apricot.CBForm.customFormElement({
                elem: elem.querySelector('checkbox')
            })
        })
        if (props.parms.mustAnswer) {
            noModalEscape(props.parms.modalId)
        }
        setFooterCls(getFooterCls(1))

        const langVals = Object.values(languages)
        const curriculumVals = Object.values(curriculums)
        setNextDisabled(langVals.length === 0 || !langVals.find(value => { return value}) ||
                            (!!languages['Other (specify)'] && langOther.length === 0))
        setSaveDisabled(curriculumVals.length === 0 || !curriculumVals.find(value => { return value}) ||
                            (!!curriculums['Other (specify)'] && curriculumOther.length === 0))
        const needData = langVals.length === 0 || curriculumVals.length === 0 ||
            (!!languages['Other (specify)'] && langOther.length === 0) ||
            (!!curriculums['Other (specify)'] && curriculumOther.length === 0)
        setCloseEnable(!needData)
    }, [])

    useEffect(() => {
        const langVals = Object.values(languages)
        setNextDisabled(langVals.length === 0 || !langVals.find(value => { return value}) ||
                            (!!languages['Other (specify)'] && langOther.length === 0))
    }, [languages, langOther])

    useEffect(() => {
        const curriculumVals = Object.values(curriculums)
        const isLangaugeSame = compareBooleanObjects(props.selections.languages, languages)
        const isLangOtherSame = compareBooleanObjects(props.selections.langOther, langOther)
        const isCurriculumsSame = compareBooleanObjects(props.selections.curriculums, curriculums)
        const isCurriculumOtherSame = compareBooleanObjects(props.selections.curriculumOther, curriculumOther)

        if (curriculumVals.length === 0 || isLangaugeSame && isLangOtherSame && isCurriculumsSame && isCurriculumOtherSame) {
          setSaveDisabled(true)
        } else {
          setSaveDisabled(!curriculumVals.find(value => { return value}) ||
            (!!curriculums['Other (specify)'] && curriculumOther.length === 0))
        }
    }, [
      props.selections.languages,
      props.selections.langOther,
      props.selections.curriculums,
      props.selections.curriculumOther,
      languages,
      langOther,
      curriculums,
      curriculumOther,
    ])

    const Footer = (
        <Fragment>
            {step === 1 && !props.parms.mustAnswer &&
                <NakedButton type="button" id='close-btn' className="cb-no-padding cb-btn-close"
                    data-cb-modal-close>
                    Cancel
                </NakedButton>
            }
            {step > 1 &&
                <Button type="button" className='js-submit-final' small='true'
                    onClick={() => prevWindow()} disabled={nextDisabled}>
                    Back
                </Button>
            }
            {step < 2 &&
                <YellowButton type="button" className='js-submit-final' small='true'
                    onClick={() => nextWindow()} disabled={nextDisabled}>
                    Next
                </YellowButton>
            }
            {step === 2 &&
                <YellowButton type="button" className='js-submit-final' small='true'
                    onClick={() => submit()} disabled={saveDisabled}>
                    Save
                </YellowButton>
            }
        </Fragment>
    )

    const languageChange = (label, val) => {
        let currentLanguages = {...languages}
        if (val) {
          currentLanguages[label] = val
        } else {
          delete currentLanguages[label]
        }

        // if other language is NOT selected, clear the other text
        if (currentLanguages['Other (specify)']) {
            setLanguages(currentLanguages)
        } else {
            setLanguages(currentLanguages)
            setLangOther('')
        }
    }

    const compareBooleanObjects = (obj1, obj2) => {
      const entries1 = Object.entries(obj1);
      const entries2 = Object.entries(obj2);

      if (entries1.length !== entries2.length) {
        return false
      }

      const hasSameProperties = entries1.every(([key]) => obj2.hasOwnProperty(key))
      if (!hasSameProperties) {
        return false
      }

      const areAllValuesEqual = entries1.every(([key, value]) => obj2[key] === value)
      if (!areAllValuesEqual) {
        return false
      }

      return true
    }

    const curriculumChange = (label, val) => {
        let currentCurriculums = {...curriculums}

        if (val) {
          currentCurriculums[label] = val
        } else {
          delete currentCurriculums[label]
        }

        if (currentCurriculums['Other (specify)']) {
            setCurriculums(currentCurriculums)
        } else {
            setCurriculums(currentCurriculums)
            setCurriculumOther('')
        }
    }

    const prevWindow = () => {
        const nextStep = Math.max(1, step - 1)
        if (nextStep !== step) {
            setStep(nextStep)
            setFooterCls(getFooterCls(nextStep))
        }
        focusOnFirst()
    }

    const nextWindow = () => {
        const nextStep = Math.min(2, step + 1)
        if (nextStep !== step) {
            setStep(nextStep)
            setFooterCls(getFooterCls(nextStep))
        }
        focusOnFirst()
    }

    const getFooterCls = (step) => {
        const showPrev = step > 1
        const showNext = step < 2
        const showSave = step === 2
        const showCancel = !showPrev && !props.parms.mustAnswer

        let numButton = 0;
        if (showPrev) numButton++
        if (showNext) numButton++
        if (showSave) numButton++
        if (showCancel) numButton++
        return numButton > 1 ? 'justify-content-between ' : ''
    }

    const inputChange = (event, property) => {
        let val = event.target.value
        //console.log('checkChange ' + val)
        let decision = false
        if (property === 'curriculumOther' && isValidCurriculum(val)) {
            setCurriculumOther(val)
        } else if (property === 'langOther' && isValidProgLang(val)) {
            setLangOther(val)
        }
        //console.log('checkChange decision ' + decision)
    }

    const submit = async() => {
        // console.log('submit state',this.state)
        // console.log('submit props.parms',JSON.stringify(this.props.parms))
        // console.log('submit props.parms Object',this.props.parms)
        await props.parms.save(props.parms.sectionId, {languages, langOther, curriculums, curriculumOther})
        recordFocus('editBtn')
        if (props.parms.modalId) {
            CBModal({
                elem: props.parms.modalId
            }).close()
            cleanUpBody()
        }
        props.parms?.getUpdatedData()
    }

    return (
        <Modal
            title={props.parms.title}
            open={true}
            escClose={false}
            withHeader
            withFooter
            modalId={props.parms.modalId}
            footer={Footer}
            clickOverlayToClose={closeEnable}
            showHeaderCloseGlyph={!props.parms.mustAnswer}
            showHeaderCloseButton = {!props.parms.mustAnswer}
        >
            <div className="selectionContent">
                {step === 1 &&
                    <CSPStep1 languages={languages} languageChange={languageChange}
                              langOther={langOther} inputChange={inputChange} />
                }
                {step === 2 &&
                    <CSPStep2 curriculums={curriculums} curriculumChange={curriculumChange}
                              curriculumOther={curriculumOther} inputChange={inputChange} />
                }
            </div>
        </Modal>
    )
}
