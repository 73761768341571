import { addApiKey, addBaseUrlAndApiKey }       from '../../_core/util/URL.jsx'
import { subtask, pathToUrl }                               from '../../content/static_content'
import { getCurrentPerson }                                 from '../../entities/person.jsx'
import { getCurrentSectionId, getCurrentCourseInstance }    from '../../entities/courseinstance'
import { getModuleHolder }                                  from '../../entities/modules'
import { ScorableHolder }                                   from './scorable'
import { downloadWR, getAutosaveAfterSave}                  from './scorable'
import { downloadArt }                                      from '../art/entities/artAjax'
import { bsliGet }                                          from '../../_core/util/request'

export class Upload {
    constructor(assignmentId, sectionId = null) {
        //this.isNew = false
        this.assignmentId = assignmentId
        this.id = ''
        this.sectionId = sectionId
    }

    initFromAssignmentId(json, assignmentId, sectionId = null) {
        //console.log('Upload initFromAssignmentId',json)
        this.id = json.id
        this.fileName = json.fileName
        this.submittedToTurnitin = json.submittedToTurnitin
        this.assignmentId = assignmentId
        this.sectionId = sectionId
        //this.isNew = false // was a backbone model property that turned false when saved to server
        // not sure how to translate this one, these two methods are GET calls not POST
    }

    initFromUploadId(uploadId, assignmentId, sectionId = null) {
        //console.log('Upload initFromUploadId',uploadId)
        this.assignmentId = assignmentId
        this.id = uploadId
        this.sectionId = sectionId
        //this.isNew = false
        return bsliGet(this.url())
            .then(json => {
                //console.log('initFromUploadId Upload', json)
                this.fileName = json.fileName
                this.submittedToTurnitin = json.submittedToTurnitin
                return this
            })
    }

    initFromPlagJson(json, sectionId = null) {
        try {
            this.id = json.upload.id
            this.fileName = json.upload.fileName
            this.submittedToTurnitin = json.upload.submittedToTurnitin
            this.assignmentId = json.scorable.assignmentId
            this.sectionId = sectionId
        } catch(e) {
            console.error('initFromPlagJson could not update with json',json)
        }
    }

    url() {
        var sectionId = getCurrentSectionId() || this.sectionId
        return 'api/sections/' + sectionId + '/assignments/' + this.assignmentId + '/uploads/' + this.id
    }

    urlWithoutId() {
        var sectionId = getCurrentSectionId()
        // not sure how the old code put in the api part
        return 'api/sections/' + sectionId + '/assignments/' + this.assignmentId + '/uploads'
    }

    exists() {
        return !!this.id
    }

    hasBeenSubmittedToTurnitin() {
        return this.submittedToTurnitin
    }

    getDownloadUrl() {
        // addApiKey adds api-key=...
        return addApiKey(this.url() + '/download')
    }

    getPdfRenderUrl() {
        let res = addBaseUrlAndApiKey(this.url() + '/renderSubmission');
        return res.replace('/api/api', '/api');
    }

    isPdf() {
        return /\.pdf$/i.test(this.fileName)
    }

    async getViewOriginalityUrl(callback) {
        const url = await bsliGet(this.url() + '/tiiLaunch')
        callback(url)
    }
}

// call by scoring_module in showScoringLayout
export function entitiesUpload(uploadId, assignmentId) {
    //console.log('entitiesUpload uploadId=' + uploadId + ', assignmentId=' + assignmentId)
    let upload = new Upload()
    return upload.initFromUploadId(uploadId, assignmentId)
}

// called from assignment.jsx getMenuParms
export function uploadAndScorable(assignmentId, personId) {
    const url = 'sections/' + getCurrentSectionId() + '/assignments/' + assignmentId + '/scorable-and-upload?personId=' + personId
    return bsliGet(url)
        .then((json) => {
            //console.log('uploadAndScorable resolved',json)
            let upload = new Upload()
            upload.initFromAssignmentId(json.upload || {}, assignmentId)
            return [
                upload,
                new ScorableHolder(json.scorable || {}, assignmentId),
                json.uploadStatusInfo || {}
            ]
        })
        .catch(() => {
            //console.log('FAIL uploadAndScorable url: ' + url)
            return [null, null, null]
        })
}

export async function getMenuParms(assignment, personId) {
    let result, subtaskNode, moduleHolder
    try {
        var asyncFuncs = [
            subtask(assignment.lessonId),
            getModuleHolder()
        ]
        const awaitedData = await Promise.all(asyncFuncs)
        subtaskNode = awaitedData[0]
        moduleHolder = awaitedData[1]
    } catch (e) {
        //console.log('awaitedData failed ', e)
        return false
    }

    let lessonId = assignment.lessonId
    let lesson = moduleHolder.lessonById(lessonId)

    let upload, scorableHolder
    if (lesson.isTeamLesson) {
        upload = new Upload(assignment.id)
        scorableHolder = new ScorableHolder({}, assignment.id)
    } else {
        result = await uploadAndScorable(assignment.id, personId)
        if (result) {
            upload = result[0]
            scorableHolder = result[1]
        }
    }
    let isCSP_WR                = assignment.isIWRAssignment;
    let isArt_SI_Images         = assignment.isArt_SI_Images;
    let isArt_SW_Images         = assignment.isArt_SW_Images;
    let isArt_WrittenEvidence   = assignment.isArt_WrittenEvidence;

    let weLessons = moduleHolder.getWeLessonIds()
    let isWeLesson = _.contains(weLessons, lessonId)
    let wrData,artData , autosaveAfterSave
    let isStudent = getCurrentPerson().isStudent()

    if (isStudent) {
        let currentCourseInstance = getCurrentCourseInstance()
        let sections = currentCourseInstance ? currentCourseInstance.sections : []
        let currentSectionId = getCurrentSectionId()
        let section = sections.filter(element => element.id == currentSectionId)[0]
        let enrollmentId = section.enrollment.id

        if (enrollmentId) {
            try {
                if (isCSP_WR) {
                    // if (window.wrData) {
                    //     console.log('window.wrData', window.wrData)
                    //     wrData = window.wrData
                    //     window.wrData = undefined
                    // } else {
                //        console.log('going to request wrData')
                        wrData = await downloadWR(assignment.id, enrollmentId);
                        //console.log('received wrData lenth=' + wrData.length)
                        window.wrData = wrData
                        autosaveAfterSave = await getAutosaveAfterSave(assignment.id)
                    // }
                }
                if (isArt_SI_Images || isArt_SW_Images) {
                    if (!!upload.id) {
                        artData = await downloadArt(assignment.id, upload.id);
                    } else {
                      //  console.log('NOT calling downloadArt because uploadId is missing, must be first time use')
                    }
                 }
                if (isArt_WrittenEvidence) {
                    let inquiry = ''
                    let evidence = ''
                    let data = await downloadWR(assignment.id, enrollmentId)
                    if (data) {
                        let beginIndex = data.indexOf("name=\"formData\"") + 15
                        let endIndex = data.lastIndexOf("}") + 1
                        let body = data.substring(beginIndex, endIndex).trim()
                        data = JSON.parse(body)
                        inquiry = data.Art_WE_inquiry
                        evidence = data.Art_WE_evidence
                    }
                    artData = {inquiry: inquiry, evidence: evidence}
                }
            } catch (error) {
                console.error('either downloadArt or downloadWR failed', error)
            }
        }
    }

    return {
        assignment: assignment,
        lesson: lesson,
        isWeLesson:             isWeLesson,
        isCSP_WR:               isCSP_WR,
        isArt_SI_Images:        isArt_SI_Images,
        isArt_SW_Images:        isArt_SW_Images,
        isArt_WrittenEvidence:  isArt_WrittenEvidence,
        rubricUrl: subtaskNode && pathToUrl(subtaskNode.rubric),
        upload: upload,
        scorableHolder: scorableHolder,
        wrData: wrData,
        artData: artData,
        autosaveAfterSave: autosaveAfterSave?  autosaveAfterSave.autosaveAfterSave: null
    }

}
