import { Spinner } from "@cb/apricot-react";
import HomeComp from "./views/home";


const Admin = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  if (loading) return <Spinner />

  else return <HomeComp />
}

export default Admin