import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Select, Spinner, YellowButton } from "@cb/apricot-react";
import { bsliGet, bsliPost } from "../../_core/util/request";
import DPToolTip from "../../utility/tooltip";
import { getCurrentAcademicYear } from "../../entities/academicyear";
import { savedGetRequests } from "./admin-layout";
import { Link } from "react-router-dom";

const Courses = () => {
    const [loading, setLoading] = useState(true)
    const [props, setProps] = useState(null)
    const [state, setState] = useState({ value: '-1' })
    const tooltip = new DPToolTip("courses")
    let toolTipParms = { placement: "bottom" }

    const getParams = async () => {
        let enabled = await savedGetRequests("/admin/course-instances/edit")
        let courses = await bsliGet("admin/current-course-instances")
        let templates = await bsliGet("admin/course-instance-values")
        let academicYear = await getCurrentAcademicYear()
        let parms = {
            courses,
            templates: templates.courseData,
            academicYear,
            courseCreationEnabled: enabled.courseCreationEnabled,
            educationPeriod: templates.educationPeriod
        }
        setProps({ parms })
    }


    const changeHandler = (event) => setState({ value: event })

    const createCourse = () => {
        createCourseCd(props.parms.educationPeriod)
    }

    const createCourseCd = async (educationPeriod) => {
        try {
            await bsliPost(`admin/create-course?courseInstance=${state.value}${educationPeriod}`)
            // navigate("admin/manage-courses")
        } catch (e) {
            console.error("createCourseCd had error")
        }
    }

    const populatetemplates = () => {
        let templates = props.parms.templates
        const temp = templates.map(t => ({ label: t.courseDesc, value: t.courseCd }))
        return [{ label: 'Select Course Template', value: '-1' }, ...temp]
    }

    const makeUrlWithParam = (urlString, attachParam) => {
        return (urlString + "/" + attachParam)
    }

    const renderTableData = () => {
        return props.parms.courses.map((course, index) => {
            let { id, courseId, courseStatus, title, courseYear, isCourseActive } = course //destructuring
            return (
              <tr key={courseId}>
                  <td>
                      {courseYear} {title}
                  </td>
                  <td>
                      {isCourseActive ? "Draft" : "Finalized"}
                      <span className="sr-only">{", course " + title + " for year " + courseYear}</span>
                  </td>
                  <td>
                      <Link to={makeUrlWithParam("../manage-courses", id)}>
                          {isCourseActive ? "Edit/Finalize" : "View/Edit"}
                          <span className="sr-only">{", course " + title + " for year " + courseYear}</span>
                      </Link>
                  </td>
              </tr>
            )
        })
    }
    const selectionMade = () => {
        return state && state.value !== "-1"
    }

    useEffect(() => {
        getParams()
        tooltip.createTooltips()
    }, [])

    useEffect(() => {
        if (props) setLoading(false)
        tooltip.createTooltips()
    }, [props])

    if (loading) return <Spinner />

    if (props) {
        tooltip.removeTooltips()
        return (
          <div>
              <div id="admin-search-form" className="row ">
                  <div className="col-lg-8 col-md-9 col-xs-12 padding-0">
                      <h4 className="h1">Manage Courses</h4>
                      <h5 className="h3">Create Course for Academic Year {props.parms.academicYear}</h5>
                      <div className="form-group cb-select report">
                          {/* <label className="sr-only" htmlFor="templateType">Select Course Template</label> */}
                          <Select className="form-control" name="template" id="templateType" onChange={changeHandler} values={populatetemplates()} />
                      </div>
                      <div className="cb-input form-group">
                          <YellowButton type="button" className="js-submit" small="true"
                                        id={tooltip.addToolTip(toolTipParms)} data-cb-apricot="tooltip"
                                        data-cb-title="Create Course"
                                        disabled={!selectionMade()} onClick={createCourse}>
                              Create Course
                          </YellowButton>
                      </div>
                      <div className="cb-input form-group">
                          <table id="courses" className="cb-table">
                              <thead>
                              <tr>
                                  <th scope="col">Course</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              {renderTableData()}
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

export default Courses
