import React, { Fragment } from 'react';

import { getCurrentPerson }                 from '../../entities/person.jsx'
import AssignmentLayoutComp                 from '../../assignment/views/assignmentLayout.jsx'
import { AlertComp }                        from '../../_core/views/alert.jsx'
import CMSDocsComp                          from '../../supportingmaterial/views/cmsDocs'
import { SupportingMaterialLayoutComp }     from '../../supportingmaterial/views/supportingMaterialLayout'
import { isArt }				            from '../../entities/courseinstance'

export default class StudentLayoutComp extends React.Component {
    constructor(props) {
        super(props);
        // props.parms: assignments, lesson, inTeamWithoutMembers, teamNames, message, cmsData, supportingMaterialData, lessonInfo
    }

    render() {
        //console.log('StudentLayoutComp parms', this.props.parms)
        let lesson = this.props.parms.lesson
        let	art = isArt()
        let { title, concatAbbreviation, abbreviation } = lesson
        let titleAdj = art ? title : concatAbbreviation ? `${title} (${abbreviation})` : title
        let alertProps = {
            isDismissable: null,
            uniqueId: lesson.id,
            message: this.props.parms.message
        }

        return (
            <Fragment>
                <h2 className="cb-h3" tabIndex='-1' style={{letterSpacing: '0'}}>{titleAdj}</h2>
                {this.props.parms.teamNames && this.props.parms.teamNames.length > 0 &&
                    <p><strong>{'Team Members: ' + this.props.parms.teamNames}</strong></p>
                }
                <div className="alert-wrapper">
                    {this.props.parms.inTeamWithoutMembers &&
                        <AlertComp params={alertProps}></AlertComp>
                    }
                </div>
                <div id="region-lesson"
                    dangerouslySetInnerHTML={{ __html: this.props.parms.lessonInfo }} >
                </div>
                <div id="region-cmsmaterial">
                    <CMSDocsComp parms={this.props.parms.cmsData}></CMSDocsComp>
                </div>
                <div id="region-supportingmaterial">
                <SupportingMaterialLayoutComp
                    supportingMaterialData={this.props.parms.supportingMaterialData}
                    lessonId={lesson.id} moduleId={0}
                    publishMaterial={()=> { console.error('StudentLayoutComp should not publish')} } />
                 </div>
                <div id="region-assignments">
                    <AssignmentLayoutComp assignments={this.props.parms.assignments} personId={getCurrentPerson().id} />
                </div>
            </Fragment>
        )
    }
}
