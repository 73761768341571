import FileUploadAndSubmissionComp                          from './fileUploadAndSubmission'
import ActivityFeedComp                                     from '../../messages/views/activityFeed'
import { getSubmissionAlertData, getDraftAlertData }        from '../assignment_module'
import { getCurrentPerson }                                 from '../../entities/person.jsx'
import {
  getCurrentSectionId,
  getCurrentCourseInstance,
  isSeminar_IWA_CP,
  isSeminar_IRR_CP, isResearch_PREP
} from '../../entities/courseinstance'
import { getModuleHolder }                                  from '../../entities/modules'
import { subtask, pathToUrl }                               from '../../content/static_content'
import { ScorableHolder }                                   from '../entities/scorable'
import { uploadAndScorable, Upload }                        from '../entities/upload'
import { downloadWR , getAutosaveAfterSave }                from '../entities/scorable'

export const AssignmentComp = (props) => {
  const [fileUploadId, setFileUploadId] = useState(0)

  const isArtAssignment = props.parms.menuParms.isArt_SI_Images ||
                        props.parms.menuParms.isArt_SW_Images ||
                        props.parms.menuParms.isArt_WrittenEvidence
  const isTeacher = getCurrentPerson().isTeacher()

  return (
      <>
          <div id="region-assignment-title"></div>
          { !(isArtAssignment && isTeacher) &&
          <div id="region-assignment-menu" style={{ marginBottom: '10px' }}>
              <FileUploadAndSubmissionComp parms={props.parms}></FileUploadAndSubmissionComp>
          </div>
          }
          <div id="region-assignment-discussion" className="row">
              <ActivityFeedComp parms={props.parms} showGallery={props.showGallery}  title={props.title}/>
          </div>
      </>
  )
}

// moved to /assignment/entities/upload : function getMenuParms(assignment, personId)

// returns a promise that resolves to an alertReason or false
export function getAlertReason(menuParms) {
  //console.log('getAlertReason menuParms',menuParms)
  // dont show warnings if this is a WE lesson
  // the draft alert was disabled by a Jira story
  // the second one will never happen for a WE lesson as they do not have a final
  // submit state
  const hideButtonsForResAndSemSpecificAssignments =
    isSeminar_IWA_CP(menuParms.assignment.id) ||
    isSeminar_IRR_CP(menuParms.assignment.id) ||
    isResearch_PREP(menuParms.assignment.id)
  if (!getCurrentPerson().isStudent() || menuParms.isWeLesson || !menuParms.scorableHolder || hideButtonsForResAndSemSpecificAssignments) {
    return Promise.resolve(false)
  }
  if (menuParms.scorableHolder.scorable.status === 2) { //if draft has been submitted
    let draftAlertData = getDraftAlertData()
    ////console.log('getDraftAlertData', draftAlertData)
    return draftAlertData
  } else if (menuParms.scorableHolder.scorable.submittedAt) { //if final has been submitted
    return getSubmissionAlertData(menuParms.lesson)
  }
  return Promise.resolve(false)
}
