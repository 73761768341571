import { initializeAuth, useAuth } from './modules/auth/auth_module'

import './modules/utility/csv'
import 'regenerator-runtime/runtime'
import { Spinner } from '@cb/apricot-react'
import Routes from './Routes'
import { BrowserRouter } from 'react-router-dom'
import '@cb/apricot/CBBase'
import '@cb/apricot/CBGrid'
import '@cb/apricot/CBGlyph'
import '@cb/apricot/CBLogo'
import '@cb/apricot/CBButton'

import { GlobalFooter, GlobalHeader } from '@cb/global-widgets-react'
import { SessionMonitor } from './modules/_core/util/session_monitor'
import { subject, updateSubscription } from './modules/utility/service'
import { MYAP_URLS } from './modules/config/config'

window.refreshCourseConfig = false

const headerProps = {
  icons: true,
  appId: 295,
  skipLocation: '#region-main-content',
  title: 'AP',
  programType: 'k-12',
  lockupLogo: true,
  lockupName: 'AP',
  useGlobalNavigation: true,
  useLoginWidget: true,
  useGlyphLogo: true,
  identityConfig: {
    appId: 295,
    logoutRedirectUrl: `${window.location.protocol}//${window.location.host}`
  }
}

const footerProps = {
  appId: 295,
  showLinks: true,
  showBackToTopLink: true,
  analyticsSiteCode: 'gf',
  fetchLinks: true
}

window.sessMon = new SessionMonitor({
  sessTimeoutMin: 30,
  beforeWarningMin: 29.5,
  pingUrl: '/health'
})

const App = () => {
  initializeAuth()
  const auth = useAuth(), myapUrl = MYAP_URLS[`${window.cb.core.iam.getCBEnv()}`]
  let callback

  useEffect(() => {
    let subscription = subject.subscribe(message => {
      if (message.beforeLocationChange) {
        if (!callback) callback = message.beforeLocationChange.callback
      }
    })
    updateSubscription('beforeLocationChange', subscription)
  }, [])

  useEffect(()  => {
    if (auth.loggedIn) window.sessMon.start()
  }, [auth.loggedIn])


  const hasProfile = () => {
    // Returning undefined condition to true for when backend has not been called (i.e. on logout)
    if (auth.hasProfile === undefined) return true
    else return !!auth.hasProfile
  }

  if (!hasProfile()) window.location.assign(myapUrl)
  if (auth.loading) return <Spinner background="gray4" show label="Loading Content" />
  if (auth.error) return <>Error has Occurred. Please reload this page.</>
  if (auth.loggedIn) return (
    <>
      <GlobalHeader {...headerProps} />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <GlobalFooter {...footerProps} />
    </>
  )

  return null
}

export default App
