import React, { useEffect }         from 'react';
import {isReadOnlyMode}             from '../../entities/courseinstance'

export function PermissionComp({ permissionToReuse, saveDenial }) {
    useEffect(() => {
        document.querySelectorAll('.cb-checkbox').forEach((elem) => {
            window.cb.apricot.CBForm.customFormElement({
                elem: elem.querySelector('input')
            });
        });
        document.getElementById("denyReuseCheckbox").checked = permissionToReuse
    }, [])

    let disabledState = isReadOnlyMode()
    return (
        <div style={{ marginTop: '15px' }}>
            <h5 id='permissionTitle'>Permission to Reuse Written Performance Task Materials</h5>
            <p id='permissionText'>
                Unless I check the box below, I grant the College Board the unlimited right to use, reproduce,
                and publish my written through-course performance task components for educational research and
                instructional purposes. My name and the name of my school will not be used in any way in
                connection with my written work. I understand that I am free to select “No” with no effect
                on my score or its reporting.
            </p>
            <div className="cb-checkbox" style={{ marginTop: '15px' }}>
                <label htmlFor="denyReuseCheckbox">
                    <input id="denyReuseCheckbox" disabled={disabledState} type="checkbox"
                        aria-describedby='permissionTitle permissionText'
                        onChange={onChange} />
                    No, I do not grant the College Board these rights.
                </label>
            </div>
        </div>
    )

    function onChange(event) {
        saveDenial(event.target.checked)
    }
}