import { Spinner } from '@cb/apricot-react'
import { useParams } from 'react-router-dom'
import { getCurrentSectionId } from '../entities/courseinstance'
import { alterHTML, contentField } from '../content/static_content'
import { filterModules, getModuleHolder } from '../entities/modules'
import { getCourseProgress, getProgressSection } from './entities/progress'
import { getCurrentPerson } from '../entities/person'
import { ProgressTeacherLayout, ProgressTeacherTeamLayout } from './views/progressTeacherLayout'
import CourseLayout from '../main/views/courseLayout'
import { isDPCourse } from '../entities/course'
import CoordinatorLayoutComp from './views/coordinatorLayout'
import { ProgressContext } from '../../context'

async function getSectionData() {
  let getVal = (val, onErrVal) => {
    if (val.status === 'fulfilled') {
      return val.value
    } else {
      return onErrVal
    }
  }
  try {
    let asyncFuncs = [
      contentField('teacher_progress'),
      getModuleHolder(),
    ]
    const awaitedData = await Promise.allSettled(asyncFuncs)
    return {
      staticText: getVal(awaitedData[0], ''),
      moduleHolder: getVal(awaitedData[1], false),
    }
  } catch (e) {
    return {
      staticText: '',
      moduleHolder: false,
    }
  }
}

let shownTab = 'student'

const Progress = () => {
  const [loading, setLoading] = useState(true)
  const [refreshContent, setRefreshContent] = useState(false)
  const [component, setComponent] = useState(null)
  const [props, setProps] = useState(null)
  let { courseInstanceId, sectionId, we } = useParams()
  let isWe = we === '1'
  sectionId = sectionId || getCurrentSectionId()
  const role = getCurrentPerson()

  useEffect(() => {
    if (refreshContent) {
      setLoading(true)
      getContent()
      setRefreshContent(false)
    }
  }, [refreshContent])

  const getContent = async () => {
    if (role.isTeacher()) {
      let data = await getSectionData()
      let { staticText, moduleHolder } = data
      let navModuleHolder = moduleHolder
      let marker = isWe ? 'WE_PROGRESS' : 'DP_PROGRESS'
      staticText = alterHTML(staticText, marker)
      moduleHolder = filterModules(moduleHolder, isWe)

      let [studentProgress, teamProgress] = await getProgressSection(sectionId, moduleHolder, false)
      let hasTeams = moduleHolder.hasTeamLessons() && !isWe
      let baseParams = {
        moduleHolder,
        studentProgress,
        isStudent: false,
        sectionId,
        isWe,
        tab: shownTab,
        selectStudent: async () => {
          if (shownTab === 'team') {
            //do stuff
          }
          shownTab = 'student'
        },
        selectTeam: async refresh => {
          if (refresh) {
            // do stuff
          }
          shownTab = 'team'
        },
        staticText,
      }

      if (hasTeams) {
        let teamParams = baseParams
        teamParams.teamProgress = teamProgress
        setComponent(<ProgressTeacherTeamLayout {...teamParams} />)
      } else {
        setComponent(<ProgressTeacherLayout {...baseParams} />)
      }
      setProps({ moduleHolder: navModuleHolder })
    } else {
      let moduleHolder = await getModuleHolder()
      let navModuleHolder = moduleHolder
      if (isDPCourse(courseInstanceId)) {
        moduleHolder = filterModules(moduleHolder, isWe)
      } else isWe = true

      let coordinatorProgress = await getCourseProgress(courseInstanceId, moduleHolder, isWe)
      let params = {
        courseInstanceId,
        moduleHolder,
        coordinatorProgress,
        isWe,
      }
      setComponent(<CoordinatorLayoutComp parms={params} />)
      setProps({ moduleHolder: navModuleHolder })
    }
  }

  // This useEffect checks we is needed and re-retrieves content for CoordinatorLayout component if so
  // Will get content again if isWe returns to false (Progress link in Class Summary is clicked)
  useEffect(() => {
    if (isWe || courseInstanceId) {
      setLoading(true)
      getContent()
    } else {
      setLoading(true)
      getContent()
    }
    document.dispatchEvent(new CustomEvent('pagevisit', { detail: 'progress' }))
  }, [we, courseInstanceId])

  useEffect(() => {
    if (component) setLoading(false)
  }, [component])

  if (loading) return <Spinner />

  if (component && props.moduleHolder) return (
    <>
      <ProgressContext.Provider value={{ refreshContent: setRefreshContent }}>
        <CourseLayout props={{ moduleHolder: props.moduleHolder }}>
          {component}
        </CourseLayout>
      </ProgressContext.Provider>
    </>
  )
}

export default Progress