// These are the assignment paths that will trigger the modal on unsaved work
import { createContext } from 'react'

export const urlPathsToCheck = [
  '0101',
  '0102',
  '0202'
]

export const isPathToCheck = urlPathsToCheck.some(url => location.pathname.includes(url))

export const contextObject = {
  clickEvent: false,
  hasUnsavedWork: false,
  destinationLink: '',
  setDestinationLink: () => {},
  updateClickEvent: () => {},
  updateHasUnsavedWork: () => {}
}

export const progressContextObject = {
  refreshContent: () => {}
}

export const ProgressContext = createContext(progressContextObject)

export const AppContext = createContext(contextObject)