import React, { Fragment, useEffect }                   from 'react';
import { focusOnFirst }                                 from '../../utility/util'
import { MetadataList }                                 from './metaList'
import { MetadataString, MetadataTextarea }             from './metaStr'

// metadata is immutable reference information about the data
// we alter the data via the changeMeta method from the parent
export function SubmitFinalMetadataStepComp({ metadata, changeMeta }) {

    useEffect(() => {
        document.querySelectorAll('#metaStep .cb-select').forEach(function (elem) {
            window.cb.apricot.CBForm.customSelectElement({
                elem: elem.querySelector('select')
            })
        })
        document.querySelectorAll('#metaStep .cb-input').forEach(function (elem) {
            window.cb.apricot.CBForm.customFormElement({
                elem: elem.querySelector('input')
            })
        })
        focusOnFirst()
    })

    let list = metadata.map((meta, index) => {
        if (meta.type === 'list') {
            return (
              <MetadataList meta={meta} changeMeta={changeMeta} key={'meta_' + index} />
            )
        } else if (meta.type === 'string') {
            return (
              <MetadataString meta={meta} changeMeta={changeMeta} key={'meta_' + index} />
            )
        } else if (meta.type === 'textarea') {
            return (
              <MetadataTextarea meta={meta} changeMeta={changeMeta} key={'meta_' + index} />
            )
        }
    })

    return (
        <div id='metaStep' aria-describedby='modalTitle'>
            {list}
        </div>
    )
}
