import { request } from '../../config/config'

export function addBaseUrl(path) {
    if (_.isString(path)) {
        let isAbsolute = /^http/.test(path)
        if (isAbsolute) {
            return path.replace('/api/api/', '/api/')
        } else {
            return request('baseUrl') + ensureLeadingSlash(path).replace('/api/api/', '/api/')
        }    
    } else {
        console.log('addBaseUrl path no good ' + path)
    }
}

export function pathToUrl(urlPath) {
    let url = addBaseUrl(urlPath).replace('/api/api/', '/api/')
    //console.log('pathToUrl ' + urlPath + '=>' + url)
    return url
}

export function addQueryToUrl(url, query) {
    var queryPrefix = /\?/.test(url) ? '&' : '?';
    return url + queryPrefix + query;
}

export function addApiKey(url) {
    var apiKey = request('config:apikey');
    return addQueryToUrl(url, 'api-key=' + apiKey);
}

export function addBaseUrlAndApiKey(path) {
    return addApiKey(addBaseUrl(path))
}

//var isAbsolute = RegExp.prototype.test.bind(/^http/);

function ensureLeadingSlash(s) {
    if (s[0]) {
        return s[0] === '/' ? s : '/' + s;
    }
    else return s
}

export function removeLeadingHash(s) {
    if (s[0]==='#') {
        return s.substring(1)
    } else {
        return s
    }
}
