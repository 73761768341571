import {getAssignmentHolder} from '../entities/browserHelper'
import {getCurrentCourseInstance} from '../entities/courseinstance'
import {uploadAndScorable} from '../assignment/entities/upload'
import {downloadArt} from '../assignment/art/entities/artAjax'
import {downloadWR} from '../assignment/entities/scorable'
import {getCurrentAcademicYear} from '../entities/academicyear'
import {renderToString} from 'react-dom/server'
import html2pdf from 'html2pdf.js'

// Function gets all submissions for a given art course and returns that data for all the assignments of the course
export const getAllPortfolioSubmissions = async (lessonIds, sectionId, personId, enrollmentId) => {
	const holders = await Promise.all([getAssignmentHolder(lessonIds[0], sectionId), getAssignmentHolder(lessonIds[1], sectionId), getAssignmentHolder(lessonIds[2], sectionId)])
	const assignments = holders.map(holder => holder.assignments[0])
	const imageAssignments = assignments.filter(a => a.isArt_SI_Images || a.isArt_SW_Images)
	const writtenEvidenceAssignment = assignments.filter(a => a.isArt_WrittenEvidence)
	const uploadsAndScoreables = await Promise.all([uploadAndScorable(imageAssignments[0].id, personId), uploadAndScorable(imageAssignments[1].id, personId)])
	const neededImageIds = uploadsAndScoreables.reduce((acc, val) => [...acc, { assignmentId: val[0].assignmentId, uploadId: val[0].id }], [])
	const imageData = await Promise.all([downloadArt(neededImageIds[0].assignmentId, neededImageIds[0].uploadId), downloadArt(neededImageIds[1].assignmentId, neededImageIds[1].uploadId)])
	const writtenEvidenceRawData = await downloadWR(writtenEvidenceAssignment[0].id, enrollmentId)
	let beginIndex = writtenEvidenceRawData.indexOf('name="formData"') + 15
	let endIndex = writtenEvidenceRawData.lastIndexOf('}') + 1
	let body = writtenEvidenceRawData.substring(beginIndex, endIndex).trim(), tempData = JSON.parse(body), inquiry = tempData.Art_WE_inquiry, evidence = tempData.Art_WE_evidence
	let weFinalData = { inquiry, evidence }
	return {
		weData: weFinalData,
		neededImagesIds: neededImageIds,
		imageData
	}
}

export const PortfolioPDF = async (data, studentInfo) => {
	const { title } = getCurrentCourseInstance(), academicYear = await getCurrentAcademicYear(), { firstName, lastName, apUid, courseName } = studentInfo
	const convertImages = async images => await Promise.all(images.map(async assignment => {
		const imageUrls = await Promise.all(assignment.map(async a => {
			const blob = await fetch(a.downloadUrl).then(res => res.blob())
			let imageProps = null
			let dataUrl = await new Promise((resolve) => {
				let reader = new FileReader()
				reader.onload = (f) => {
					const image = new Image()
					image.src = f.currentTarget.result
					image.onload = () => resolve(reader.result)
					imageProps = image
				}
				reader.readAsDataURL(blob)
			})
			if (!dataUrl.includes('image')) {
				let extension = a.fileName.substr(a.fileName.length - 3, a.fileName.length)
				extension = extension === 'jpg' || extension === 'jpeg' ? 'image/jpeg' : 'image/png'
				dataUrl = dataUrl.replace('application/octet-stream', extension)
			}
			return { url: dataUrl, id: a.documentIndex, width: imageProps.naturalWidth, height: imageProps.naturalHeight }
		}))
		return assignment.map(a => {
			let correctUrl = imageUrls.find(i => i.id === a.documentIndex)
			const { url, width, height } = correctUrl
			let metaData = {
				height: a.documentHeight,
				width: a.documentWidth,
				index: a.documentIndex,
				materials: a.documentMaterial,
				processes: a.documentProcess,
				citations: a.citation
			}
			if (a.documentIdeas.length) metaData = { ...metaData, ideas: a.documentIdeas }
			if (a.documentDepth.length) metaData = { ...metaData, depth: a.documentDepth }
			return { url, metaData, imageHeight: height, imageWidth: width }
		})
	}))

	const PrintPDF = (allData) => {
		const { weData, rawImageData } = allData
		let pageNum = 1
		// eslint-disable-next-line no-undef
		const partition = _.partition(rawImageData, elem => elem.length !== 15).map(piece => piece.reduce(acc => [...acc]), [])
		const sorted = partition.map(p => p.sort((a, b) => {
			let numA, numB
			if (a.metaData.index.includes('.') && b.metaData.index.includes('.')) {
				numA = parseFloat(a.metaData.index)
				numB = parseFloat(b.metaData.index)
			} else {
				numA = parseInt(a.metaData.index)
				numB = parseInt(b.metaData.index)
			}
			if (numA < numB) return -1
			if (numA > numB) return 1
			return 0
		}))
		const [works, sustained] = sorted

		const titlePage = (
			<div id='pdf' style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
				<div className='row display-flex'>
					<img src="/images/ap_logo_pdf.png" alt="AP logo" width="100px" />
				</div>
				<div className='justify-content-center cb-align-center' style={{ marginTop: '375px' }}>
					<h4 style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>{(title || courseName).replace('\&', 'and')} Portfolio</h4>
					<p>{firstName} {lastName}</p>
					<p>{apUid}</p>
					<p>20{academicYear.substring(5)}</p>
				</div>
			</div>
		)

		const calculateAspectRatio = (srcWidth, srcHeight, maxWidth, maxHeight) => {
			const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)
			return { width: srcWidth * ratio, height: srcHeight * ratio }
		}

		const writtenEvidencePage = (
			<div style={{ fontFamily: 'Arial, Helvetica, sans-serif', position: 'relative', width: '749px', height: '989px' }}>
				<div className='cb-font-size-xsmall' style={{ position: 'absolute',top: '960px', zIndex: 10, display: 'flex', width: '100%', justifyContent: 'space-between' }}>
					<div>
						<span>{title || courseName} | {firstName} {lastName}</span>
					</div>
					<div>
						<span>{pageNum++}</span>
					</div>
				</div>
				<div style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
					<h4 style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>Written Evidence</h4>
					<p>Sustained Investigation</p>
				</div>
				<div className='cb-margin-top-16'>
					<div className='display-flex'>
						<div className='col-sm-5 cb-font-size-xsmall cb-font-weight-medium'>Identify the inquiry that guided your sustained investigation.</div>
						<div className='col-sm-7 cb-border-style cb-border-black cb-border-left-1 cb-font-size-xsmall cb-font-weight-medium' style={{ wordWrap: 'break-word' }}>Response: <p className='cb-font-weight-regular cb-font-size-xsmall'>{weData.inquiry}</p></div>
					</div>
					<div className='cb-margin-top-48 display-flex'>
						<div className='col-sm-5 cb-font-size-xsmall cb-font-weight-medium'>Describe ways your sustained investigation developed through practice, experimentation, and revision.</div>
						<div className='col-sm-7 cb-border-style cb-border-black cb-border-left-1 cb-font-size-xsmall cb-font-weight-medium' style={{ wordWrap: 'break-word' }}>Response: <p className='cb-font-weight-regular cb-font-size-xsmall'>{weData.evidence}</p></div>
					</div>
				</div>
			</div>
		)

		const setupImagesHTML = (length, images) => images.map((i, index) => {
			const { width, height } = calculateAspectRatio(i.imageWidth, i.imageHeight, 500, 500)
			return (
				<div style={{ fontFamily: 'Arial, Helvetica, sans-serif', position: 'relative', width: '749px', height: '989px' }}>
					<div className='cb-font-size-xsmall' style={{ position: 'absolute',top: '960px', zIndex: 10, display: 'flex', width: '100%', justifyContent: 'space-between' }}>
						<div>
							<span>{title || courseName} | {firstName} {lastName}</span>
						</div>
						<div>
							<span>{pageNum++}</span>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-8'>
							<img src={i.url} width={width} height={height} style={{ objectFit: 'contain', maxWidth: '100%' }}/>
						</div>
						<div className='col-sm-4 cb-border-style cb-border-black cb-border-left-1 cb-font-size-xsmall'>
							<h4 className='cb-margin-left-8' style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>{length === 15 ? 'Image' : 'Work'} {i.metaData.index}</h4>
							<p className='cb-margin-left-8 cb-font-size-xsmall'>{length === 15 ? 'Sustained Investigation' : 'Selected Works'}</p>
							<div className='cb-margin-top-24 cb-margin-left-8'>
								<div className='cb-font-weight-medium'>Height: <p className='cb-font-size-xsmall display-inline-block cb-font-weight-regular'>{i.metaData.height}</p></div>
								<div className='cb-font-weight-medium' style={{ marginTop: '12px' }}>Width: <p className='cb-font-size-xsmall display-inline-block cb-font-weight-regular'>{i.metaData.width}</p></div>
								{i.metaData.depth ? <div className='cb-font-weight-medium' style={{ marginTop: '12px' }}>Depth: <p className='cb-font-size-xsmall display-inline-block cb-font-weight-regular'>{i.metaData.depth}</p></div> : null}
								{i.metaData.ideas ? <div className='cb-font-weight-medium' style={{ marginTop: '12px' }}>Idea(s): <p style={{ wordWrap: 'break-word', wordBreak: 'break-word' }} className='cb-font-size-xsmall display-inline-block cb-font-weight-regular'>{i.metaData.ideas}</p></div> : null}
								<div className='cb-font-weight-medium' style={{ marginTop: '12px' }}>Materials: <p style={{ wordWrap: 'break-word', wordBreak: 'break-word' }} className='cb-font-size-xsmall display-inline-block cb-font-weight-regular'>{i.metaData.materials}</p></div>
								<div className='cb-font-weight-medium' style={{ marginTop: '12px' }}>Process(es): <p style={{ wordWrap: 'break-word', wordBreak: 'break-word' }} className='cb-font-size-xsmall display-inline-block cb-font-weight-regular'>{i.metaData.processes}</p></div>
								{i.metaData.citations ? <div className='cb-font-weight-medium' style={{ marginTop: '12px' }}>Citation(s): <p style={{ wordWrap: 'break-word', wordBreak: 'break-word' }} className='cb-font-size-xsmall display-inline-block cb-font-weight-regular'>{i.metaData.citations}</p></div> : null}
							</div>
						</div>
					</div>
				</div>
			)})
		const sustainedPages = setupImagesHTML(sustained.length, sustained)
		const worksPages = setupImagesHTML(works.length, works)

		return {
			titlePage,
			sustainedPages,
			worksPages,
			writtenEvidencePage
		}
  }

	// createPDF function on-the-fly creates and structures HTML properly for the PDF
	// DO NOT CHANGE options for html2pdf package
	const createPDF = async html => {
		const { titlePage, sustainedPages, writtenEvidencePage, worksPages } = html
		const options = {
			jsPDF: { unit: 'pt', format: 'letter', precision: 1 },
			html2canvas: {
				scale: 3,
				scrollX: 0,
				scrollY: 0,
			},
			filename: `${apUid}_${title || courseName} Portfolio.pdf`,
			margin: 25
		}
		let pdfDoc = html2pdf().set(options)

		let stringTitlePage = renderToString(titlePage)
		const stringWrittenEvidencePage = renderToString(writtenEvidencePage)
		const stringSustainedPages = sustainedPages.map(sp => renderToString(sp))
		const stringWorksPages = worksPages.map(wp => renderToString(wp))

		pdfDoc = pdfDoc.from(stringTitlePage).toContainer().toCanvas().toPdf().get('pdf').then(pdf => pdf.addPage())
		pdfDoc = pdfDoc.from(stringWrittenEvidencePage).toContainer().toCanvas().toPdf().get('pdf').then(pdf => pdf.addPage())
		stringSustainedPages.forEach(page => { pdfDoc = pdfDoc.from(page).toContainer().toCanvas().toPdf().get('pdf').then(pdf => pdf.addPage()) })
		stringWorksPages.forEach((page, ind, arr) => {
			if (ind === arr.length - 1) pdfDoc = pdfDoc.from(page).toContainer().toCanvas().toPdf()
			else pdfDoc = pdfDoc.from(page).toContainer().toCanvas().toPdf().get('pdf').then(pdf => pdf.addPage())
		})
		return pdfDoc.save()
	}

	const { weData, imageData } = data
	const rawImageData = await convertImages(imageData)
	const html = PrintPDF({ rawImageData, weData })
	await createPDF(html)
}
