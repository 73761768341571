import { Navigate, Route, Routes as ReactRouterRoutes, useLocation } from 'react-router-dom'
import { getCurrentPerson } from './modules/entities/person'
import { redirectUrls } from './modules/config/config'
import { getCourseInstanceId, getCourseInstances, getSection } from './modules/entities/courseinstance'
import { getCurrentAcademicYear } from './modules/entities/academicyear'
import InstructionLayout from './modules/main/views/instructionLayout'
import { sectionIdFromUrl } from './modules/entities/course'
import Dashboard from './modules/dashboard/dashboard'
import ContentOverview from './modules/content/ContentOverview'
import Browser from './modules/artifactbrowser/Browser'
import ModuleOverview from './modules/overview/ModuleOverview'
import HelpComp from './modules/content/views/help'
import { ArchiveComp } from './modules/dashboard/views/archive'
import TermView from './modules/terms/views/termView'
import { confirmTermsAndConditions, saveTermsAndConditions } from './modules/terms/terms_module'
import Progress from './modules/progress/Progress'
import Teams from './modules/manageteams/manageteams'
import Reviewer from './modules/reviewer/Reviewer'
import Admin from './modules/admin/Admin'
import AdminLayout from './modules/admin/views/admin-layout'
import Reports from './modules/admin/views/reports'
import Courses from './modules/admin/views/courses'
import EditCourseComp from './modules/admin/views/editCourse'
import TSP from './modules/admin/views/tsp'
import DeleteComp from './modules/admin/views/delete'
import ManageExtensionComp from './modules/admin/views/manage_extension'
import { Spinner } from '@cb/apricot-react'
import NotificationWidget, { notificationLoad } from './modules/notification/notification_module'
import LocationChanged from './modules/global/locationChanged'
import { AppContext } from './context'
import ScoringExperience from './modules/progress/ScoringExperience'

const Routes = () => {
  const role = getCurrentPerson()
  const location = useLocation()
  const [sectionInfo, setSectionInfo] = useState(null)
  const [title, setTitle] = useState('')
  const [org, setOrg] = useState(null)
  const [loading, setLoading] = useState(false)
  const [termsNotAccepted, setTermsNotAccepted] = useState(null)
  const [currentAcademicYear, setCurrentAcademicYear] = useState(null)
  const [sectionId, setSectionId] = useState('')
  const [courseInstanceId, setCourseInstanceId] = useState('')
  let courseTitle = getCourseInstances()?.getCourseById(sectionInfo?.courseInstanceId)?.title || ''
  let disabled, isReviewer = role.isReviewer(), isAdmin = role.isAdmin()

  // Variables for context
  const [clickEvent, setClickEvent] = useState()
  const [hasUnsavedWork, setHasUnsavedWork] = useState()
  const [destinationLink, setDestinationLink] = useState()

  if (isAdmin || isReviewer) window.refreshCourseConfig = true

  // Passed to InstructionLayout comp for when teacher/coordinator switches orgs in dropdown
  const changeOrg = org => setOrg(org)

  // confirm terms and conditions
  const termsCall = async () => {
    setLoading(true)
    const res = await confirmTermsAndConditions()
    setTermsNotAccepted(res)
    setLoading(false)
  }

  const INSTRUCTION_ROUTES = [
    { element: <Dashboard compProps={org} />, path: 'dashboard' },
    { element: <ContentOverview />, path: 'sections/:sectionId/overview' },
    { element: <Browser />, path: 'sections/:sectionId/lessons/:lessonId' },
    { element: <ModuleOverview />, path: 'sections/:sectionId/modules/:moduleId/overview' },
    { element: <HelpComp />, path: 'help' },
    { element: <HelpComp />, path: 'courses/:courseInstanceId/help' },
    { element: <ArchiveComp />, path: 'archive' },
    { element: <Progress />, path: 'sections/:sectionId/progress/:we' },
    { element: <Progress />, path: 'sections/:sectionId/progress/:we' },
    { element: <Progress />, path: 'courses/:courseInstanceId/progress/:we' },
    { element: <Teams />, path: 'sections/:sectionId/groups' },
    { element: <ScoringExperience />, path: 'sections/:sectionId/progress/:we/teacher-scoring' },
    { element: <ScoringExperience />, path: 'courses/:courseInstanceId/progress/:we/teacher-scoring' },
  ]

  const REVIEWER_ROUTES = [
    { element: <Reviewer />, path: 'reviewer' }
  ]

  const ADMIN_ROUTES = [
    { element: <Admin />, path: '' },
    { element: <Reports />, path: 'view-reports' },
    { element: <Reports />, path: 'view-reports/:id' },
    { element: <Courses />, path: 'manage-courses' },
    { element: <EditCourseComp />, path: 'manage-courses/:id' },
    { element: <TSP />, path: 'teacher-scoring-provisioning' },
    { element: <DeleteComp />, path: 'delete-score' },
    { element: <ManageExtensionComp />, path: 'manage-extensions' },
  ]

  useEffect(() => {
    termsCall()
  }, [])

  useEffect(() => {
    if (termsNotAccepted === false) {
      getCurrentAcademicYear().then(data => setCurrentAcademicYear(data))
      setSectionId(sectionIdFromUrl())
      setCourseInstanceId(getCourseInstanceId())
    }
  }, [termsNotAccepted])

  useEffect(() => {
    if (!sectionId || sectionId !== sectionIdFromUrl()) setSectionId(sectionIdFromUrl())
    if (sectionId) {
      setSectionInfo(getSection(sectionId))
    }
    if (courseInstanceId) {
      if (location.pathname === '/instruction/dashboard' || location.pathname === '/instruction/help' || location.pathname === '/instruction/archive') {}
      else {
        setTitle('')
        courseTitle = getCourseInstances()?.getCourseById(courseInstanceId)?.title
      }
    }
  }, [location.pathname, sectionId, courseInstanceId])

  useEffect(() => {
    window.scrollTo(0, 0)
    // Change page title, document title, and course title (if appropriate)
    if (location.pathname === '/instruction/help') {
      courseTitle = ''
      setSectionInfo(null)
      setTitle('Digital Portfolio Help')
      document.title = 'Digital Portfolio Help | AP Digital Portfolio'
    }
    else if (location.pathname === '/instruction/dashboard') {
      disabled = false
      setSectionInfo(null)
      setTitle('Welcome to the Digital Portfolio')
      document.title = "Welcome to the Digital Portfolio | AP Digital Portfolio"
    }
    else if (location.pathname === '/instruction/archive') {
      disabled = false
      setSectionInfo(null)
      setTitle('My Archived Digital Portfolio Classes')
      document.title = 'My Archived Digital Portfolio Classes | AP Digital Portfolio'
    }
    else if (sectionInfo) {
      document.title = `AP | ${sectionInfo?.title} | ${courseTitle} | AP Digital Portfolio`
      setTitle('')
    }
    else if (location.pathname.includes('courses') && !role.isAdmin()) {
      document.title = `AP | ${courseTitle} | AP Digital Portfolio`
    }
    else if (isAdmin) {
      if (location.pathname === '/admin') document.title = 'Digital Portfolio Admin | The College Board'
      if (location.pathname.includes('manage-courses')) document.title = 'Manage Courses | Digital Portfolio Admin | The College Board'
      if (location.pathname.includes('manage-courses') && /[0-9]/.test(location.pathname)) document.title = 'Edit Course | Digital Portfolio Admin | The College Board'
      if (location.pathname.includes('reports')) document.title = 'View Reports | Digital Portfolio Admin | The College Board'
      if (location.pathname.includes('teacher-scoring-provisioning')) document.title = 'Teacher Scoring Provisioning | Digital Portfolio Admin | The College Board'
      if (location.pathname.includes('delete-score')) document.title = 'Delete Scores | Digital Portfolio Admin | The College Board'
      if (location.pathname.includes('manage-extensions')) document.title = 'Manage Extensions | Digital Portfolio Admin | The College Board'
    }
  }, [location.pathname, sectionInfo])

  if (loading) return <Spinner />

  if (termsNotAccepted && location.pathname !== '/termsandconditions') return <Navigate to={redirectUrls.terms}/>

  return (
    <div>
      <NotificationWidget />
      <AppContext.Provider value={{ clickEvent: clickEvent, hasUnsavedWork: hasUnsavedWork,  destinationLink: destinationLink, setDestinationLink: param => setDestinationLink(param), updateClickEvent: param => setClickEvent(param), updateHasUnsavedWork: param => setHasUnsavedWork(param) }}>
        <LocationChanged location={location} />
        <ReactRouterRoutes>
          {isAdmin ?
            <>
              <Route path='admin' element={<AdminLayout role={role} />}>
                {ADMIN_ROUTES.map((r, i) => <Route key={i} element={r.element} {...r} />)}
              </Route>
              <Route path='/' index element={<Navigate to='/admin' />} />
            </>
          : null}
          {isReviewer ?
            <>
              {REVIEWER_ROUTES.map((r, i) => (<Route key={i} element={r.element} {...r} />))}
              <Route path='/' index element={<Navigate to='/reviewer' />} />
            </>
          : null}
          <Route path='instruction' element={<InstructionLayout props={{ currentAcademicYear, title, disabled, section: sectionInfo, courseTitle, changeOrg }} />}>
            {INSTRUCTION_ROUTES.map((r, i) => (<Route key={i} path={r.path} element={r.element} {...r} />))}
          </Route>
          <Route path='/' index element={<Navigate to='/instruction/dashboard' />} />
          <Route element={<TermView acceptedCallback={saveTermsAndConditions} />} path={redirectUrls.terms} />
        </ReactRouterRoutes>
      </AppContext.Provider>
    </div>
  )
}

export default Routes
