import React from "react"
import { getCourseInstanceBySectionId } from "../../entities/courseinstance"

export default class StudentStaticComp extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        let helpers = this.serializeData()
        return (
            <div>
                {helpers.showCSPText &&
                <div>
                    <div className='progress-text'>
                        <p>
                            This table indicates whether or not you are registered for the exam and whether or not you have submitted all three components of your performance task as final. Both steps are required in order for you to receive an AP score.
                        </p>
                    </div>

                    <div style={{marginTop: '15px'}}>
                        <ul className='progress-text'>
                            <li>
                                If the Name/Status cell is white and your AP ID appears below your name, you are registered for the exam. If it isn’t and you think you should be registered, please contact your teacher or AP coordinator.                            </li>
                            <li>
                                When you have submitted a performance task component as final, a black check mark will appear for that task. Only task components with a black check mark will be sent to the College Board for scoring. Ensure that all three components of your performance task have black check marks by submitting your work as final.
                            </li>
                        </ul>
                    </div>
                </div>
                }
                {helpers.showText &&
                    <div>
                        <div className='progress-text'>
                            <p>
                                This table indicates whether or not you are registered for the exam and whether or not you have submitted
                                your performance tasks as final. Both steps are required in order for you to receive an AP score.
                            </p>
                        </div>

                        <div style={{marginTop: '15px'}}>
                            <ul className='progress-text'>
                                <li>
                                    If the Name/Status cell is white and your AP ID appears below your name, you are registered for the exam. If it isn’t and you think you should be registered, please contact your teacher or AP coordinator.
                                </li>
                                <li>
                                    When you have submitted a performance task component as final, a black check mark will appear for that task.
                                    Only task components with a black check mark will be sent to the College Board for scoring.
                                    Ensure that all your performance tasks have black check marks by submitting your work as final.
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {helpers.showArt &&
                <div>
                    <div className='progress-text'>
                        <p>
                            This page indicates if you’re registered for the {helpers.title} Exam. It also shows the sections of the portfolio that need to be submitted by the deadline. See the AP Exam calendar to review the deadline.
                        </p>
                    </div>

                    <div style={{marginTop: '15px'}}>
                        <ul className='progress-text'>
                            <li>
                                If the Name/Status cell is white and your AP ID appears below your name, you are registered for the exam. If it isn’t and you think you should be registered, please contact your teacher or AP coordinator.
                            </li>
                            <li>
                                When you’ve submitted a section, a check mark will appear in that section’s checkbox. Only sections that have a check mark by the deadline will be sent to the AP Program for scoring.
                            </li>
                        </ul>
                    </div>
                </div>
                }
            </div>
        )
    }

    serializeData() {
        const courseInstance = getCourseInstanceBySectionId(this.props.parms.sectionId)
        const showText = /^41|^40/.test(courseInstance.id)
        const title = courseInstance.title
        const showArt = ['32', '33', '34'].indexOf(courseInstance.id.slice(0, 2)) > -1
        const showCSPText = courseInstance.id.slice(0, 2) === '42'
        return {
            showText,
            showArt,
            title,
            showCSPText
        }
    }
}