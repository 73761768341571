import { bsliGet } from '../_core/util/request'
import { amOnACourseSpecificPage }	from '../entities/course'
import { getCourseInstanceId, getCurrentSection, getWeServiceCourseInstance }		from '../entities/courseinstance'
import { cmsNotifications }			from '../content/static_content'
import { getCurrentAcademicYear }  from '../entities/academicyear'
import { getCurrentSectionId, getCurrentCourseInstance,
         getSection, getWeHardEndDate, getCourseName }  from '../entities/courseinstance'
import { getCurrentPerson } from '../entities/person'
import { getModuleHolder } from '../entities/modules'

let messageWidget = null
let lastRendered = null
let bannerMessageIds = {}

function getBannerMessageId(msg, courseId) {
  bannerMessageIds = JSON.parse(localStorage.getItem('bannerMessageIds')) || {}

  const key = `${msg}-${courseId}`

  if (bannerMessageIds[key]) {
    return bannerMessageIds[key]
  }

  const len = Object.keys(bannerMessageIds).length
  bannerMessageIds[key] = 10000 + len
  localStorage.setItem('bannerMessageIds', JSON.stringify(bannerMessageIds))

  return 10000 + len
}

async function checkSectionStudentsExtension(sectionId) {
  const res = await bsliGet(`sections/${sectionId}/progress`)
  if (res?.students.find(student => student.hasExtension === false)) {
    return true
  }
  return false
}

async function getDateCheck(course) {
  let dateCheck = (new Date()).getTime() > course.softEndDate
  if (dateCheck) {
    let person = getCurrentPerson()
    if (person.isCoordinator()) {
    } else {
      let currentSection = getSection(getCurrentSectionId())
      if (person.isStudent()) {
        dateCheck = currentSection.enrollment.hasExtension === false
      } else if (person.isTeacher()) {
        dateCheck = currentSection.hasExtension === false && await checkSectionStudentsExtension(currentSection.id)
      } else {
        dateCheck = false
      }
    }
  }
  return dateCheck
}

async function isWeService() {
  const moduleHolder = await getModuleHolder(getCourseInstanceId())
  const pathname = window.location.pathname
  if (pathname.includes('modules')) {
    const moduleId = pathname.split('/')[5]
    const module = moduleHolder.modules.find(module => module.id === moduleId)

    if (module.title.toLowerCase().includes('we service')) {
      return true;
    }
  } else if (pathname.includes('lessons')) {
    const lessonId = pathname.split('/')[5]
    const module = moduleHolder.modules.find(module => {
      const lesson = module.lessons.find(lesson => lesson.id === lessonId)
      return !!lesson
    })

    if (module.title.toLowerCase().includes('we service')) {
      return true;
    }
  } else if (pathname.includes('progress')) {
    const progressId = pathname.split('/')[5]
    if (progressId === '1') {
      return true;
    }
  }
  return false
}

async function getBannerCourseName(courseName, courseTitle) {
  const moduleHolder = await getModuleHolder(getCourseInstanceId())
  const pathname = window.location.pathname

  if (pathname.includes('modules')) {
    const moduleId = pathname.split('/')[5]
    const module = moduleHolder.modules.find(module => module.id === moduleId)

    if (module.title.toLowerCase().includes('we service')) {
      return module.title;
    }
  } else if (pathname.includes('lessons')) {
    const lessonId = pathname.split('/')[5]
    const module = moduleHolder.modules.find(module => {
      const lesson = module.lessons.find(lesson => lesson.id === lessonId)
      return !!lesson
    })

    if (module.title.toLowerCase().includes('we service')) {
      return module.title;
    }
  } else if (pathname.includes('progress')) {
    const progressId = pathname.split('/')[5]
    if (progressId === '1') {
      return courseTitle;
    }
  }

  return `${courseName || courseTitle}`
}

export async function getBannerMessage() {
	try {
    let academicYear = await getCurrentAcademicYear()
    let course = getCurrentCourseInstance()
		let courseName = getCourseName()
    if (await isWeService()) {
      course = getWeServiceCourseInstance()
    }

    if (course && await getDateCheck(course)) {
      const bannerCourseName = await getBannerCourseName(courseName, course.title)
      const msg = `The AP Digital Portfolio for the ${academicYear} academic year is now closed for ${bannerCourseName}. You can view items; however, you cannot make any changes.`
      const id = getBannerMessageId(msg, course.id)

      return [{
				id,
				title: 'Alert:',
				message: msg,
				type: 'lv-alert'
			}]
    }
		return []
  } catch (e) {
    console.error('could not obtain academicYear')
    return []
  }
}

export async function notificationLoad() {
	let courseSpecificPage = amOnACourseSpecificPage()
	let courseInstanceId = courseSpecificPage ? getCourseInstanceId() : 'general'
	if (courseSpecificPage && lastRendered === courseInstanceId && (!location.pathname.includes('overview') && !location.pathname.includes('modules'))) return [];
	lastRendered = courseInstanceId
	let notifications = await cmsNotifications()
	messageWidget = { notifications }
	let notificationsList = []
	messageWidget.notifications.forEach(message => {
		if (message) {
			notificationsList.push({
				id: message.id,
				title: message.title,
				message: message.message,
				type: 'lv-alert'
			})
		}
	})

	return notificationsList
}
