import {Enrollment} from '../enrollment/entities/enrollment'
import {isEmpty} from 'underscore'

export const useTeam = (team) => {
  let teamState = {}

  if (isEmpty(team)) return
  let enrollments = team.enrollment
  let hasExtension = enrollments.every(e => { return e.hasExtension })
  teamState = {
    url: `teams/${team.id}/members`,
    enrollment: enrollments ? enrollments : [],
    title: team.title,
    hasExtension,
    prefFullName: enrollments?.map(e => (new Enrollment(e)).prefFullName()),
    fullName: enrollments?.map(e => (new Enrollment(e)).fullName()).join(', ')
  }

  return teamState
}
