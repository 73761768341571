import React, { Fragment }                  from 'react';
import { CheckboxGroup, Checkbox, Input }   from '@cb/apricot-react';

export function CSPStep1({languages, languageChange, langOther, inputChange}) {

    let languageLabels = [
        'Alice',        'App Inventor',     'App Lab',      'EarSketch',        
        'Greenfoot',    'Java',             'JavaScript',   'MakeCode',
        'Python',       'Quorom',           'Scratch',      'Snap!',        
        'Swift',        'Other (specify)'
    ]

    let checkboxes1 = _.map(languageLabels.slice(0,4), (label,index) => {
        return (
            <Checkbox label={label} onChange={ val => { languageChange(label,val)} } key={'checkbox_1_' + index}
                      checked={!!languages[label]} className='col-md-3' />
        )
    })
    let checkboxes2 = _.map(languageLabels.slice(4,8), (label,index) => {
        return (
            <Checkbox label={label} onChange={ val => { languageChange(label,val)} } key={'checkbox_2_' + index}
                      checked={!!languages[label]} className='col-md-3' />
        )
    })
    let checkboxes3 = _.map(languageLabels.slice(8,12), (label,index) => {
        return (
            <Checkbox label={label} onChange={ val => { languageChange(label,val)} } key={'checkbox_3_' + index} 
                      checked={!!languages[label]} className='col-md-3'/>
        )
    })
    let checkboxes4 = _.map(languageLabels.slice(12,16), (label,index) => {
        return (
            <Checkbox label={label} onChange={ val => { languageChange(label,val)} } key={'checkbox_4_' + index} 
                      checked={!!languages[label]} className='col-md-3'/>
        )
    })

    let langOtherDisabled = !languages['Other (specify)']

    return (
        <Fragment>
            <label className='question'>
                What programming language(s) do you teach? Check all that apply.
            </label>
            <CheckboxGroup vertical={false}>
                {checkboxes1}
            </CheckboxGroup>
            <CheckboxGroup vertical={false}>
                {checkboxes2}
            </CheckboxGroup>
            <CheckboxGroup vertical={false}>
                {checkboxes3}
            </CheckboxGroup>
            <CheckboxGroup vertical={false}>
                {checkboxes4}
            </CheckboxGroup>

            <div style={{marginTop: '20px'}}>
                <label className='question' htmlFor='other'>If other, please enter programming language</label>
            </div>
            <div>
                <Input type="text" name="langOther" id='langOther' disabled={langOtherDisabled}
                    placeholder='Other...'
                    value={langOther} clearable={false}
                    maxLength='50'
                    onChange={event => {inputChange(event,'langOther')}} />
            </div>
        </Fragment>
    )
}
