import { AssignmentComp, getAlertReason } from '../../assignment/views/assignment'
import { getMenuParms } from '../../assignment/entities/upload'

const AssignmentLayoutComp = props => {
  const { assignments, personId } = props
  const [list, setList] = useState([])
  const [refreshCounter, setRefreshCounter] = useState(0)

  const refreshStudentLayout = () => {
    setRefreshCounter(refreshCounter + 1)
  }

  useEffect(() => {
    const fetchData = async () => {
      const promises = assignments.map(async (assignment, index) => {
        const menuParms = await getMenuParms(assignment, personId)
        const alertReason = await getAlertReason(menuParms)

        const parms = {
          assignment,
          personId,
          menuParms,
          alertReason,
          refreshStudentLayout,
        }

        return <AssignmentComp parms={parms} key={'assignmentComp_' + index} />
      })

      try {
        const awaitedData = await Promise.allSettled(promises)
        const reducedList = awaitedData.filter(item => item.status === 'fulfilled').map(item => item.value)

        setList(reducedList)
      } catch (error) {
        console.error('Error in data retrieval:', error)
        setList([])
      }
    }

    fetchData()
  }, [assignments, personId, refreshCounter])

  return <div>{list}</div>
}

export default AssignmentLayoutComp
