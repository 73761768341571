import React, {Fragment, useEffect}                 from 'react';
import { NakedButton }                              from '@cb/apricot-react'
import { reactRender, focusOnFirst }                from '../../utility/util'
import { isCSP, isSeminar, getCurrentSection, isResearch }      from '../../entities/courseinstance'
import CSPSelectionModal                            from './cspSelectionModal'
import SeminarResearchSelectionModal                from './SeminarResearchSelectionModal'
import { textToUI }                                 from '../../utility/util'

export function TeacherSelections({sectionId, selections, canEdit, save, getUpdatedData}) {
    // console.log('teacherSelections ', selections)

    useEffect(() => {
        // if the teacher has not made selections, present a pop-up here
        if (selections && canEdit) {
            let needData = false
            if (isCSP()) {
                let langVals = _.values(selections.languages)
                let curriculumVals = _.values(selections.curriculums)
                needData = _.isEmpty(langVals) || !_.find(langVals, value => { return value}) || 
                           _.isEmpty(curriculumVals) || !_.find(curriculumVals, value => { return value}) || 
                           (!!selections.languages['Other (specify)'] && _.isEmpty(selections.langOther)) ||
                           (!!selections.curriculums['Other (specify)'] && _.isEmpty(selections.curriculumOther))
            } else if (isSeminar()) {
                needData = _.isEmpty(selections.department) || _.isEmpty(selections.fulfill) ||
                _.isEmpty(selections.themes)
            } else if (isResearch()) {
                needData = _.isEmpty(selections.department) || _.isEmpty(selections.fulfill)    
            }

            if (needData) {
                launchModal(true)
            }
        }
    }, [])

    function launchModal(mustAnswer) {
        let modal
        let parms = {
            modalId:    'selectionModal',
            sectionId:  sectionId,
            mustAnswer: !!mustAnswer,
            save:       save,
            title:      getTitle(),
            getUpdatedData,
        }
        if (isCSP()) {
            modal = <CSPSelectionModal selections={selections} parms={parms} key={'selectionModal'} />
        } else if (isSeminar()) {
            modal = <SeminarResearchSelectionModal selections={selections} parms={parms} key={'selectionModal'} />
        } else if (isResearch()) {
            modal = <SeminarResearchSelectionModal selections={selections} parms={parms} key={'selectionModal'} />
        }

        // for some reason, reactRender was occuring before the above modal was instantiated
        setTimeout(() => {
            if (modal) {
                reactRender(modal, 'region-modal', 'selectionModal', true)
                //focusOnFirst('region-modal')
            }
        }, 500)
    }

    function getTitle() {
        let section = getCurrentSection()
        if (section && section.fullTitle) {
            return section.fullTitle + ' Section Information'
        } else {
            return 'Class Section Information'
        }    
    }

    return (
        <Fragment>
            <div id='region-modal'></div>
            {(isCSP() || isSeminar() || isResearch()) &&
                <div className='teacherSelections'>
                    <div className='row'>
                        <div id='header' className='col-xs-8'>
                            <h5>{getTitle()}</h5>
                        </div>
                        <div className='col-xs-4'>
                            <NakedButton 
                                id='editBtn' className='cb-no-padding' onClick={() => launchModal(false)}
                                disabled={!canEdit}>
                                Edit
                            </NakedButton>
                        </div>
                    </div>
                    <hr />
                    <table>
                        <tbody>
                            {isCSP() &&
                                <Fragment>
                                    <tr>
                                        <td className='col-xs-6 col-md-4 label'>Programming Language(s):</td>
                                        <td>{textToUI(selections.languages, selections.langOther)}</td>
                                    </tr>
                                    <tr>
                                        <td className='col-xs-6 col-md-4 label'>Curriculum:</td>
                                        <td>{textToUI(selections.curriculums, selections.curriculumOther)}</td>
                                    </tr>
                                </Fragment>
                            }

                            {(isSeminar() || isResearch())&&
                                <Fragment>
                                    <tr>
                                        <td className='col-xs-6 col-md-4 label'>Department:</td>
                                        <td>{selections.department}</td>
                                    </tr>
                                    <tr>
                                        <td className='col-xs-6 col-md-4 label'>Graduation Requirement:</td>
                                        <td>{selections.fulfill}</td>
                                    </tr>
                                    {isSeminar() && 
                                    <>
                                    <tr>
                                        <td className='col-xs-6 col-md-4 label'>Grade:</td>
                                        <td>{selections.grade}</td>
                                    </tr>
                                    <tr>
                                        <td className='col-xs-6 col-md-4 label'>Theme(s) Incorporated:</td>
                                        <td>{selections.themes}</td>
                                    </tr>
                                    <tr>
                                        <td className='col-xs-6 col-md-4 label'>Schedule:</td>
                                        <td>{selections.schedule}</td>
                                    </tr> 
                                    </>
                                    }
                                </Fragment>
                            }
                        </tbody>
                    </table>
                </div>
            }
        </Fragment>
    )
}