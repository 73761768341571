import SubmitFinalCheckFileComp from './submitFinalCheckFile'
import { SubmitFinalCheckGalleryComp } from './submitFinalCheckGallery'
import { SubmitFinalMetadataStepComp } from './submitFinalMetadataStep'
import SubmitFinalActionComp from './submitFinalAction'
import SubmitFinalSuccessComp from './submitFinalSuccess'
import { Modal, NakedButton, YellowButton } from '@cb/apricot-react'
import { AlertComp } from '../../_core/views/alert'
import { ImageGallery } from '../art/views/gallery'
import { refreshActivityFeed, refreshAlert, refreshMenuBar } from '../../utility/service'
import { finalSubmitCancel } from '../art/entities/saveUtil'

const SubmitFinalModal = ({ props }) => {
  const templateHelpers = () => {
    let title = props?.lesson && props?.lesson.title
    let isAcademicPaper = title && /\.*Academic\s*P/.test(title)
    return {
      isAcademicPaper: isAcademicPaper,
      modalCls: isAcademicPaper ? ' modal-dialog-wide' : '',
    }
  }

  const [galleryModal, setGalleryModal] = useState(false)
  const [state, setState] = useState({
    step: 1,
    alertProps: false,
  })
  const [disableCheckboxes, setDisableCheckboxes] = useState(true)
  let { assignment = {}, modalOpen, onClose, upload, lesson, artData, artType, scorableHolder } = props, metadata = {}
  const isArtImages = assignment.isArt_SI_Images || assignment.isArt_SW_Images,
    isArtWE = assignment.isArt_WrittenEvidence,
    isArt = isArtImages || isArtWE,
    helpers = templateHelpers(),
    finalCheckParms = {
      modalId: 'finalSubmitModal',
      upload,
      assignment,
      lesson,
    }
  const ui = {
    actionButtons: () => [...document.querySelectorAll('#finalSubmitModal .js-next'), ...document.querySelectorAll('#finalSubmitModal .js-submit-final')],
    submitButton: () => document.querySelectorAll('#finalSubmitModal .js-submit-final'),
    nextButton: () => document.querySelectorAll('#finalSubmitModal .js-next'),
    modalCloseButton: () => document.querySelectorAll('#finalSubmitModal #close-btn'),
  }

  const changeActionButtonsState = (bool) => {
    ui.actionButtons().forEach(btn => {
      if (bool) btn.setAttribute('disabled', true)
      else btn.removeAttribute('disabled')
    })
  }

  const changeMeta = (valid, update) => {
    if (valid) {
      metadata[update.id] = update
    } else {
      delete metadata[update.id]
    }
    let ok = Object.values(metadata).length !== props.assignment.metadata.length
    changeActionButtonsState(ok)
  }

  const checkboxChange = () => {
    const checkboxes = document.querySelectorAll('#finalSubmitModal input[type="checkbox"]')
    const areCheckboxesChecked = []
    checkboxes.forEach(c => areCheckboxesChecked.push(c.checked))
    const result = areCheckboxesChecked.every(c => c)
    if (result) {
      changeActionButtonsState(false)
    } else {
      changeActionButtonsState(true)
    }
  }

  const nextWindow = () => {
    if (state.step === 1 && _.isEmpty(props.assignment.metadata)) {
      setState({ step: 3 })
    } else {
      if (state.step === 4) {
        location.reload()
      } else {
        setState((state) => {
          if (state.step === 2) {
            return { step: state.step + 1, metadata }
          }
          if (state.step !== 3 && !props.isSeminar && props.assignment.metadata?.[0]?.id === 5 && props.assignment.metadata?.[1]?.id === 6) {
            return { step: 3 }
          }
          if (state.step !== 3 && props.isAfricanAmericanStudies && props.assignment.metadata?.[0]?.id === 2) {
            return { step: 3 }
          }
          return { step: state.step + 1 }
        })
      }
    }
  }

  const submitSubmission = async () => {
    const metadataToSubmit = state.metadata
    try {
      let submitFinal
      if (await scorableHolder.isUnsubmitted()) {
        submitFinal = metadataToSubmit ? scorableHolder.modifyScorableStatus(scorableHolder.enums.FinalSubmitted, Object.values(metadataToSubmit)) :
          scorableHolder.modifyScorableStatus(scorableHolder.enums.FinalSubmitted, metadataToSubmit)
      } else {
        submitFinal = scorableHolder.addAttestant()
      }

      await submitFinal
      refreshMenuBar()
      refreshActivityFeed()
      refreshAlert()
      if (props.finalSubmitCompleted) {
        props.finalSubmitCompleted()
      }
      nextWindow()
    } catch (err) {
      submitFailed(err)
    }
  }

  const submitFailed = (xhr) => {
    let msg = xhr?.message || 'Error: There was an error saving your final submission.'
    showAlert(msg, 'submit-final')
    ui.submitButton()[0].classList.add('hidden')
  }

  const showAlert = (message, uniqueId) => {
    setState({
      alertProps: {
        isDismissable: false,
        showAlert: true,
        message,
        uniqueId,
      },
    })
  }

  const modalOnClose = () => {
    if (isArtImages && state.step !== 4) {
      setState({ step: 1 })
      finalSubmitCancel()
      location.reload()
    } else if (state.step === 4) {
      onClose()
      setState({ step: 1 })
    } else {
      onClose()
      setState({ step: 1 })
    }
  }

  const galleryModalOnClose = () => {
    console.log('on close')
    if (!disableCheckboxes) return
    else setDisableCheckboxes(false)
    setGalleryModal(false)
  }

  const Footer = (
    <>
      {state.step === 4 &&
        <YellowButton type='button' id='close-btn' small='true'
                      data-cb-modal-close onClick={modalOnClose}>
          Close
        </YellowButton>
      }
      {state.step !== 4 &&
        <NakedButton type='button' id='close-btn' className='cb-no-padding cb-btn-close'
                     data-cb-modal-close onClick={modalOnClose}>
          Cancel
        </NakedButton>
      }
      {(state.step === 1 || state.step === 2) &&
        <YellowButton type='button' className='js-next' small='true'
                      onClick={nextWindow}>
          Next
        </YellowButton>
      }
      {state.step === 3 &&
        <YellowButton type='button' className='js-submit-final' small='true'
                      onClick={submitSubmission}>
          Submit
        </YellowButton>
      }
    </>
  )

  useEffect(() => {
    if (state.step !== 4) {
      changeActionButtonsState(true)
    }
  }, [state.step])

  return (
    <>
      <Modal
        title='Final Submission'
        open={modalOpen}
        onClose={modalOnClose}
        escClose={false}
        withHeader
        withFooter
        modalId={finalCheckParms.modalId}
        footer={Footer}
      >
        <div>
          {state && state.alertProps && state.alertProps.showAlert ?
            <div className='alert-wrapper'>
              <AlertComp params={state.alertProps} />
            </div>
            : null}
          {state.step === 1 && isArtImages &&
            <SubmitFinalCheckGalleryComp
              finalCheckParms={finalCheckParms}
              artData={artData}
              artType={artType}
              checkboxChange={checkboxChange}
              openGalleryModal={setGalleryModal}
              disableCheckboxes={disableCheckboxes}
            />
          }
          {state.step === 1 && !isArtImages &&
            <SubmitFinalCheckFileComp finalCheckParms={finalCheckParms} checkboxChange={checkboxChange} isArt={isArt}
                                      disableCheckboxes={disableCheckboxes}
                                      setDisableCheckboxes={setDisableCheckboxes} />
          }
          {state.step === 2 &&
            <SubmitFinalMetadataStepComp metadata={assignment.metadata} changeMeta={changeMeta} />
          }
          {state.step === 3 &&
            <SubmitFinalActionComp lesson={lesson} checkboxChange={checkboxChange} />
          }
          {state.step === 4 &&
            <SubmitFinalSuccessComp />
          }
        </div>
      </Modal>
      {state.step === 1 && isArtImages &&
        <Modal
          modalId='finalSubmitModal-Image'
          title='Images Gallery'
          escClose
          open={galleryModal}
          onClose={galleryModalOnClose}
          withFooter
          withHeader
        >
          <div style={{ width: '100%', minHeight: '500px' }}>
            <ImageGallery
              galleryId={'modal_gallery'}
              sections={artData}
              type={artType}
            />
          </div>
        </Modal>
      }
    </>
  )
}

SubmitFinalModal.propTypes = {
  assignment: PropTypes.object,
  lesson: PropTypes.object,
  upload: PropTypes.object,
  scorableHolder: PropTypes.object,
  fileReqs: PropTypes.object,
  artData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  artType: PropTypes.string,
  onClose: PropTypes.func,
  modalOpen: PropTypes.bool,
}

export default SubmitFinalModal
