import _ from 'underscore'

(function() {
	function CSV(filename) {
		this._filename = filename;
		this._lines = [];
		_.bindAll(this, 'addRow');
	}

	CSV.prototype.addRow = function(values) {
		this._lines.push(values.map(cellString).join(','));
		return this;
	};

	CSV.prototype.toString = function() {
		return this._lines.join('\r\n');
	};

	CSV.prototype.toBlob = function() {
		return new Blob([this.toString()], { type: 'text/csv;charset=utf-8;' });
	};

	CSV.prototype.toDataURI = function() {
		return 'data:text/csv;charset=utf-8,' + encodeURIComponent(this.toString());
	};

	CSV.prototype.open = function() {
		var blob = this.toBlob();
		if (navigator.msSaveBlob) { // IE >= 10
			navigator.msSaveBlob(blob, this._filename);
		} else {
			var link = document.createElement('a');
			link.setAttribute('download', this._filename);
			link.setAttribute('href', URL.createObjectURL(blob));
			link.dispatchEvent(new MouseEvent('click'));
		}
	};

	function cellString(value) { // wrap cell with " and replace " with "" in the value
		if (value === 0) value = '0'; // 0 was showing up as empty cells
		return '"' + (value || '').toString().trim().replace(/"/g, '""') + '"';
	}

	window.CSV = CSV;
})();
