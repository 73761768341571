import React, { Fragment } from "react"
import { getCurrentPerson } from "../../entities/person.jsx"
import { getCourseInstances, getCurrentCourseInstance, getCurrentOrg } from "../../entities/courseinstance"
import { Link } from 'react-router-dom'
import { getCurrentAcademicYear } from "../../entities/academicyear"

export function ArchiveComp({ courses }) {
  const [coursesState, setCoursesState] = useState(courses)
  const [helpers, setHelpers] = useState(undefined)
  const [academicYear, setAcademicYear] = useState(null)

  const getAcademicYear = async () => {
    let year = await getCurrentAcademicYear()
    setAcademicYear(year)
  }

  useEffect(() => {
    if (!academicYear) {
      getAcademicYear()
    }
    if (!coursesState) {
      let currentOrg = getCurrentOrg()
      let temp = getCourseInstances().getCourses(currentOrg, false)
      temp = _.groupBy(temp, course => course.academicYear)
      setCoursesState(temp)
    }
  }, [])

  useEffect(() => {
    if (!helpers && coursesState) {
      setHelpers(serializeData(coursesState))
    }
  }, [coursesState])

  let sectionsOfOrg = course => _.map(course.sectionsOfOrg, section => {
    return (
      <li key={"section_" + section.id}>
        <Link to={"/instruction/sections/" + section.id + "/overview"}>
          <span>
            <span className="sr-only">{"course " + course.title + " section "}</span>
            <span>{section.title}</span>
          </span>
        </Link>
      </li>
    )
  })

  let teacherList = coursesOfYear => _.map(coursesOfYear, course => {
    return (
      <Fragment key={"Fragment_teacher" + course.id}>
        <ul className="list-unstyled">
          <li role="presentation" key={"teacherList_" + course.id}>
            {course.title}
          </li>
          {sectionsOfOrg(course)}
        </ul>
      </Fragment>
    )
  })

  let nonTeacherList = coursesOfYear => _.map(coursesOfYear, course => {
    let href
    if (helpers.isCoordinator) {
      href = "/instruction/courses/" + course.id + "/progress/0"
    } else {
      href = "/instruction/sections/" + course.sections[0].id + "/overview"
    }
    return (
      <li key={"nonTeacherList_" + course.id}>
        <Link to={href}>
          {course.title}
        </Link>
      </li>
    )
  })

// courses is an object with year properties whose values are an array or courseInstance
  let courseList = courses => Object.entries(courses).sort((a, b) => {
    let yeara = Number(a[0].split("-")[0])
    let yearb = Number(b[0].split("-")[0])
    if (yeara > yearb) {
      return -1
    } else {
      return +1
    }
  }).map((coursesOfYearEntry, i) => {

    let year = coursesOfYearEntry[0]
    let coursesOfYear = coursesOfYearEntry[1]

    return (
      <Fragment key={"Fragment_course" + i}>
        <h4 className="bordered-bottom-gray " key={"coursesOf_" + year}>
          <span className="sr-only">Archived Digital Portfolio Class for year</span>
          <span>{year}</span>
        </h4>
        {helpers.isTeacher &&
          <div className="floating-columns cb-spacerv-bottom-24" key={"teacherList_" + year}>
            {teacherList(coursesOfYear)}
          </div>
        }
        {!helpers.isTeacher &&
          <ul className="list-unstyled cb-spacerv-bottom-24" key={"nonTeacherList_" + year}>
            {nonTeacherList(coursesOfYear)}
          </ul>
        }
      </Fragment>
    )
  })

  return helpers ? (
    <>
      {helpers.hasCourses &&
        <>
          {courseList(coursesState)}
        </>
      }
      {!helpers.hasCourses &&
        <span>We don't see any archived classes for your school in our system</span>
      }
    </>
  ) : null
}

function serializeData(courses) {
  let person = getCurrentPerson()
  return {
    hasCourses: !_.isEmpty(courses),
    isTeacher: person.isTeacher(),
    isCoordinator: person.isCoordinator()
  }
}


