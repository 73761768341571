import React from "react";
import ReactDOM from "react-dom";


import { getCurrentPerson } from "../entities/person.jsx";
import { getCurrentAcademicYear } from "../entities/academicyear";
import { reactRender } from "../utility/util";
import { bsliGet } from "../_core/util/request";
import { closeLoadingView } from "../_core/views/loading_view";
import DeniedComp from "../reviewer/views/denied";
import HomeComp from "./views/home";
import DeleteComp from "./views/delete";
import TspComp from "./views/tsp";
import CoursesComp from "./views/courses";
import ManageExtensionComp from "./views/manage_extension";
import AdminLayoutComp from "./views/admin-layout";

function ensureAdminLayout(onlySysAdmin) {
    let person = getCurrentPerson();
    let elem = document.getElementById('region-main-content')
    if (elem) {
        ReactDOM.unmountComponentAtNode(elem)
    }

    if (!person.isAdmin() && (onlySysAdmin && !person.isSystemAdmin())) {
        const element = <DeniedComp/>
        reactRender(element, 'app-region', null, false)
    }
    else {
        let main = document.getElementById('region-main-content');
        if (!main) {
           const element = <AdminLayoutComp/>
           reactRender(element, 'app-region', null, false)
        }
    }
}

function updatePageTitle(title) {
    document.title = (title ? title + ' | ' : '') + 'Digital Portfolio Admin | The College Board'
}    

var savedGetRequests = _.memoize(function (url) {
    return bsliGet(url)
})

export function showHome() {
    //console.log('showHome')
    ensureAdminLayout()
    const element = <HomeComp />
    reactRender(element, 'region-main-content', null, false)
    updatePageTitle()
    document.documentElement.lang = 'en'
}

export async function showTsp() {
    updatePageTitle('Teacher Scoring Provisioning')
    try {
        var courses = await bsliGet('admin/current-course-instances');
        ensureAdminLayout(true)
        const element = <TspComp courses={courses}/>
        reactRender(element, 'region-main-content', null, false)    
    } catch (e) {
        console.error('showTsp could not obtain course instances')
        closeLoadingView()
    }
}

export async function showViewReports(selectedReport) {
    //console.log('navigate showViewReports selectedReport=' + selectedReport)
    updatePageTitle('View Reports')
    try {
        let asyncFuncs = [
            savedGetRequests('reports'),
            savedGetRequests('courseInstancesByYear')
        ]
        let [reports, courses] = await Promise.all(asyncFuncs)
        //sort by year, desc:
        courses.sort((a, b) => { return a.year > b.year ? -1 : 1 });
        //create an object that matches the structure of the old response
        let reportTypes = {}
        _.each(reports, function (obj) {
            reportTypes[String(obj.id)] = obj.name;
        });

        let parms = {
            reports: reports,
            reportTypes: reportTypes,
            courseInstancesByYear: courses,
            selectedReport: selectedReport
        }
        ensureAdminLayout();
        const element = <ReportsComp parms={parms} />
        reactRender(element, 'region-main-content', null, true)

    } catch (e) {
        console.error('showViewReports had error')
        closeLoadingView()
    }
}

export function showDeleteScore() {
    //console.log('navigate showDeleteScore')
    updatePageTitle('Delete Scores')
    ensureAdminLayout();
    const element = <DeleteComp/>
    reactRender(element, 'region-main-content', null, true)
}

export async function showManageCourses() {
    //console.log('navigate showManageCourses')
    updatePageTitle('Manage Courses')
    try {
        var enabled = await savedGetRequests('/admin/course-instances/edit')
        var courses = await bsliGet('admin/current-course-instances')
        var templates = await bsliGet('admin/course-instance-values')
        var academicYear = await getCurrentAcademicYear()
        let parms = {
            courses: courses, 
            templates: templates, 
            academicYear: academicYear, 
            courseCreationEnabled: enabled.courseCreationEnabled
        }
        ensureAdminLayout(true) ;
        const element = <CoursesComp parms={parms}/>
        reactRender(element, 'region-main-content')    
    } catch(e) {
        console.error('showManageCourses had error')
        closeLoadingView()      
    }
}

export async function showEditCourses(courseInstanceId) {
    //console.log('navigate showEditCourses courseInstanceId=' + courseInstanceId)
    updatePageTitle('Edit Course')
    try {
        var enabled = await savedGetRequests('/admin/course-instances/edit')
        var modules = await bsliGet('course-instances/'+ courseInstanceId + '/modules')
        var assignmentTypes = await savedGetRequests('/admin/assignments/types')
        var parameters = await bsliGet('course-instances/' +  courseInstanceId +  '/config')
        var courses = await bsliGet('admin/current-course-instances')
        var course = _.find(courses, c => c.id == courseInstanceId)
        let parms = {
            model: course,
            modules: modules,
            assignmentTypes: assignmentTypes,
            parameters: parameters,
            courseInstanceId: courseInstanceId,
            courseCreationEnabled: enabled.courseCreationEnabled
        };
        ensureAdminLayout(true)
        const element = <EditCourseComp  parms={parms}/>
        reactRender(element, 'region-main-content')
    } catch(e) {
        console.error('showEditCourses had error')
    }
}

export function showManageExtensions() {
    updatePageTitle('Manage Extensions');
    ensureAdminLayout(true);
    const element = <ManageExtensionComp/>
    reactRender(element, 'region-main-content');
}

export async function showAdminNavigation() {
    // reportTypes will have id : name attributes
    let reportTypes = {};
    try {
        let reportJson = await savedGetRequests('reports');
        _.each(reportJson, obj => { reportTypes[String(obj.id)] = obj.name})

    } catch(e) {
        console.error('showAdminNavigation could not get the report types')
    }

    const menu1 = <AdminMenuComp reportTypes={reportTypes} />
    reactRender(menu1, 'region-top-menu1')

    const timeout = <TimeoutComp />
    reactRender(timeout, 'region-top-timeout')
}


