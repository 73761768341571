import React, { Fragment } from "react"
import { NakedButton, YellowButton } from "@cb/apricot-react"
// import { handleChange }                     from '../../utility/history'
import { recordFocus } from "../../utility/util"
import { AlertComp } from "./alert.jsx"
import CBModal from "@cb/apricot/CBModal"
import { Modal } from "@cb/apricot-react"

export class BaseModal extends React.Component {
  constructor(props) {
    super(props)
    ////console.log('BaseModal init on ' + this.props.parms.modalId)
    CBModal(
      {
        elem: this.props.parms.modalId
      }
    )
    CBModal({ elem: this.props.parms.modalId })?.close({ onClose: () => location.reload() })
    _.bindAll(this, "close", "addErrorMessage", "addErrorMessageProps", "removeErrorMessage")
  }


  render() {
    let modalCls = (this.helpers && this.helpers.modalCls) ? this.helpers.modalCls : ""
    let footerCls = (this.state && this.state.footerCls) ? this.state.footerCls : ""
    let modalStyle = this.props.parms.width ? { width: this.props.parms.width + "px" } : {}
    return (
      <React.Fragment>
        <div className={"cb-modal " + modalCls} id={this.props.parms.modalId} aria-hidden="true"
             data-cb-apricot="modal">
          <div className="cb-modal-overlay">
            <div className="cb-modal-container" role="dialog"
                 aria-modal="true" aria-labelledby="modalTitle" aria-describedby="modalContent"
                 style={modalStyle}>
              <div className="cb-modal-header">
                {this.getHeader()}
              </div>
              <div id="modalContent" className="cb-modal-content">
                <React.Fragment>
                  <div className="alert-wrapper">
                    {this.state && this.state.alertProps && this.state.alertProps.showAlert &&
                      <AlertComp params={this.state.alertProps}></AlertComp>
                    }
                  </div>
                  {this.getContent()}
                </React.Fragment>
              </div>
              <div className={"cb-modal-footer " + footerCls}>
                {this.getFooter()}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  getHeader() {
    //console.log('PLEASE OVERIDE ME')
    return (<div></div>)
  }

  getContent() {
    //console.log('PLEASE OVERIDE ME')
    return (<div></div>)
  }

  getFooter() {
    //console.log('PLEASE OVERIDE ME')
    return (<div></div>)
  }

  id() {
    return this.props.parms.id
  }

  // don't use as a handler because the argument will be the event not the boolean 'reload'
  close(reload) {
    ////console.log('BaseModal CLOSE')
    if (this.props.parms.onClose) {
      this.props.parms.onClose()
    }
    if (this.props.parms.modalId) {
      CBModal({
        elem: this.props.parms.modalId
      }).close()
      cleanUpBody()
    }
    if (reload) {
      // handleChange(location)
      location.reload()
    }
    if (this.props.parms.focusOnClose) {
      recordFocus(this.props.parms.focusOnClose)
    }
  }

  createErrorMessage(message, content) {
    return message.replace(/{content}/g, content)
  }

  addErrorMessage(message, type) {
    let alertProps = {
      isDismissable: false,
      showAlert: true,
      message: message,
      uniqueId: type
    }
    this.addErrorMessageProps(alertProps)
  }

  addErrorMessageProps(alertProps) {
    this.setState(state => {
      if (state.alertProps && state.alertProps.message === alertProps.message) {
        // no change
      } else {
        return {
          alertProps
        }
      }
    })
  }

  removeErrorMessage() {
    this.setState(state => {
      if (state.alertProps) {
        return {
          alertProps: false
        }
      }
    })
  }
}

export function ConfirmModal(props) {
  const [helpers, setHelpers] = useState({})
  const [open, setOpen] = useState(props.open? props.open: false)

  useEffect(() => {
    setHelpers(serializeData())
  }, [props])

  useEffect(() => {
    setOpen(props.open)
  }, [props.open])


  const Footer = (
    <>
      {!helpers.hideNoButton &&
        <NakedButton type="button" className="cb-btn-close"
                     onClick={(e) => confirmNo(e)} data-cb-modal-close>
          {helpers.noButtonText}
        </NakedButton>
      }
      {!helpers.hideYesButton &&
        <YellowButton type="button" className="js-yes"
                      small="true"
                      onClick={(e) => confirmYes(e, props.accessDenied)}>
          {helpers.yesButtonText}
        </YellowButton>
      }
    </>
  )

  const serializeData = () => {
    return {
      title: props.title,
      message: props.message,
      showX: props.noX === undefined ? true : !props.noX,
      hideYesButton: props.hideYesButton,
      yesButtonText: props.yesButtonText || "Yes",
      hideNoButton: props.hideNoButton,
      noButtonText: props.noButtonText || "No",
      closeWhenConfirmed: props.closeWhenConfirmed ? !!props.closeWhenConfirmed : true,
      isErrorMsg: !!props.isErrorMsg
    }
  }

  const confirmNo = (e) => {
    e.stopPropagation() // prevent x-editable from hiding the ap number editable on document click
    if (props.onConfirmNo) {
      props.onConfirmNo()
    }
  }

  const confirmYes = (e, access) => {
    e.stopPropagation() // prevent x-editable from hiding the ap number editable on document click
    if (props.onConfirmYes) {
      props.onConfirmYes(access)
    }
    if (helpers.closeWhenConfirmed) {
      setOpen(false)
    }
  }

  const close = () => {
    if (props.focusOnClose) {
      recordFocus(props.focusOnClose)
    }
  }

  return (
    <Modal
      title={helpers.title}
      open={open}
      escClose={false}
      onClose={() => {
        close()
        props.onClose()
      }}
      withHeader
      withFooter
      modalId={props.modalId}
      footer={Footer}
    >
      <>
        {helpers.isErrorMsg &&
          <span className="alert alert-danger block">
              {helpers.message}
          </span>
        }
        {!helpers.isErrorMsg &&
          <p className="cb-align-left">{helpers.message}</p>
        }
      </>
    </Modal>

  )
}

export function cleanUpBody() {
  // for unknown reasons, this class hangs around, which breaks scrolling
  setTimeout(() => {
    let $body = $("body")
    if ($body.hasClass("cb-modal-open")) {
      ////console.log('CONFIRM_MODAL HAD TO REMOVE cb-modal-open')
      $body.removeClass("cb-modal-open")
    }
  }, 50)
}
