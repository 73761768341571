import React from 'react'
import { bsliGet } from '../../_core/util/request'
import { focusOnFirst } from '../../utility/util'
import { Checkbox } from '@cb/apricot-react'


const SubmitFinalCheckFileComp = (props) => {
  useEffect(() => {
    focusOnFirst()
  }, [])

  const downloadLatest = async () => {
    try {
      let url = props.finalCheckParms.upload.getDownloadUrl()
      await bsliGet(url).then(url => window.open(url.url, '_blank'))
      props.setDisableCheckboxes(false)
      return false
    } catch (e) {
      console.error(e)
    }
  }

  const serializeData = () => {
    return {
      fileName: props.finalCheckParms.upload?.fileName,
      fileUrl: props.finalCheckParms.upload?.url() + '/download',
      taskName: props.finalCheckParms.lesson?.title,
    }
  }

  let helpers = serializeData()
  let art = props.isArt

  return (
    <>
      <p style={{ marginBottom: '10px' }}>
        You are about to submit the following as your final submission
        for {helpers.taskName}{(helpers.taskName === 'Written Evidence') ? ' within Sustained Investigation' : ''}. You
        must click and open the file below to verify that it is correct.
      </p>
      <div className='row check-file'>
        <div className='col-md-3 col-sm-4'>
          <button className='cb-btn cb-btn-sm cb-btn-yellow btn-sm' style={{ float: 'right' }}
                  onClick={downloadLatest} data-cb-title='View File'>
            View File
          </button>
        </div>
        <div className='col-md-9 col-sm-8' style={{ marginTop: '16px' }}>
          {helpers.fileName}
        </div>
      </div>
      <fieldset className='add-top-margin-2x'>
        <legend className='sr-only'>Confirm file</legend>
        <Checkbox
          label={`I have reviewed the ${(helpers.taskName === 'Written Evidence') ? '' : 'uploaded'} file to ensure it is the correct version.`}
          onChange={props.checkboxChange} disabled={props.disableCheckboxes} />
        <Checkbox
          label={`I have confirmed that my ${art ? 'submission' : 'file'} does not contain my name or any other personally identifying information.`}
          onChange={props.checkboxChange} disabled={props.disableCheckboxes} />
        <Checkbox
          label={`I understand that I will not be able to make any changes to my ${art ? 'submission' : 'file'} once I complete the final submission process.`}
          onChange={props.checkboxChange} disabled={props.disableCheckboxes} />
      </fieldset>
      <a id='downloadLink' className='hidden' />
    </>
  )
}

export default SubmitFinalCheckFileComp
