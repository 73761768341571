import { getCourseParameter, isArt, isCSP, isResearch, isSeminar } from '../../entities/courseinstance'
import { getStudentProgressRowsBySection } from '../progress_module'
import { sortBy } from '../../utility/util'
import SectionSubRowComp from './sectionSubRow'
import SectionColumnComp from './sectionColumn'
import { getCurrentPerson } from '../../entities/person'
import { requestSection, stopStatusCheck } from '../../utility/upload'
import { Spinner, YellowButton } from '@cb/apricot-react'
import { getStatusFields } from './student_view'

const BUTTON_STATE = { COLLAPSED: 1, EXPANDED: 2, WAITING: 3 };

const SectionRowComp = (props) => {
  const [students, setStudents] = useState([]);
  const [buttonState, setButtonState] = useState(BUTTON_STATE.COLLAPSED);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadErrMsg, setDownloadErrMsg] = useState('')
  const [showDownloadAlertMsg, setShowDownloadAlertMsg] = useState(false)
  const failureErr = 'We were unable to download your file at this time. Please try again shortly.'
  const timeoutErr = 'We were unable to download your file at this time. Please refesh your screen and try again.'

  // useEffect(() => {
  //   if (students.length === 0) {
  //     getStudentProgressRowsBySection(
  //       props.parms.sectionModel.jsonSection.sectionId,
  //       props.parms.moduleHolder,
  //       props.parms.isWe
  //     ).then(obj => {
  //       setStudents(obj.students);
  //     });
  //   }
  // }, [])

  useEffect(() => {
    let startTimeout;
    let endTimeout;

    if (isDownloading) {
      startTimeout = setTimeout(() => {
        setShowDownloadAlertMsg(true)
      }, 45000); // 45 seconds

      endTimeout = setTimeout(() => {
        setShowDownloadAlertMsg(false)
        setDownloadErrMsg(timeoutErr)
        setIsDownloading(false)
        stopStatusCheck()
      }, 120000); // 2 minutes
    }

    return () => {
      clearTimeout(startTimeout);
      clearTimeout(endTimeout);
    };
  }, [isDownloading]);

  const templateHelpers = (params) => {
    const jsonSection = params.sectionModel.jsonSection;
    const totalStudentsWithExamIntentYes = jsonSection.studentsWithExamIntentYes;
    const descr = jsonSection.descr;
    const subTitle = descr ? descr.trim() + ': ' : '';
    const sectionName = jsonSection.sectionName;
    const showIntent = showExamIntent();
    return {
      totalStudents: jsonSection.totalStudents,
      totalStudentsWithExamIntentYes: totalStudentsWithExamIntentYes,
      showIntent: showIntent,
      sectionName: subTitle + sectionName,
      teachers: jsonSection.teacherNames.join(' & ')
    };
  };

  const showExamIntent = () => {
    if (props.parms.isWe) { return false; }

    let examIntentEnabled = getCourseParameter('examintentEnabled');
    let apNumberEnabled = getCourseParameter('apnumberEnabled');
    let research = isResearch();
    let seminar = isSeminar();

    return (research || seminar) ? apNumberEnabled : examIntentEnabled;
  };

  const toggleStudents = () => {
    if (students.length === 0) {
      alterButton(BUTTON_STATE.WAITING);
      getStudentProgressRowsBySection(
        props.parms.sectionModel.jsonSection.sectionId,
        props.parms.moduleHolder,
        props.parms.isWe
      ).then(obj => {
        if (obj.students.length > 0) {
          alterButton(BUTTON_STATE.EXPANDED);
        } else {
          alterButton(BUTTON_STATE.COLLAPSED);
        }
        setStudents(obj.students);
      });
    } else if (students.length > 0) {
      alterButton(buttonState === BUTTON_STATE.COLLAPSED ? BUTTON_STATE.EXPANDED : BUTTON_STATE.COLLAPSED);
    }
  };

  const getBtnClass = () => {
    switch(buttonState) {
      case BUTTON_STATE.WAITING:
        return 'cb-btn cb-btn-sm cb-btn-square cb-expand-button js-expand disabled';
      default:
        return 'cb-btn cb-btn-sm cb-btn-square cb-expand-button js-expand';
    }
  };

  const getIconClass = () => {
    switch(buttonState) {
      case BUTTON_STATE.WAITING:
        return 'fa fa-circle-o-notch fa-spin';
      case BUTTON_STATE.EXPANDED:
        return 'cb-glyph cb-minus';
      default:
        return 'cb-glyph cb-plus';
    }
  };

  const getAriaExpanded = () => {
    return buttonState === BUTTON_STATE.EXPANDED ? 'true' : 'false';
  };

  const getSrText = (sectionName) => {
    if (students.length === 0) {
      return 'Expand to view students progress in section ' + sectionName;
    } else if (buttonState === BUTTON_STATE.COLLAPSED) {
      return 'Collapse students';
    } else {
      return 'View students';
    }
  };

  const alterButton = (state) => {
    setButtonState(state);
  };

  const studentRows = () => {
    if (buttonState === BUTTON_STATE.EXPANDED) {
      return sortBy(
        students.map(student => {
        return {
          ...student,
          firstName: student.studentModel.firstName,
          lastName: student.studentModel.lastName
        }
      }), ['lastName', 'firstName'])
      .map((student, index) => {
        const toolKey = props.parms.toolKey + '_student_' + student.studentModel.jsonStudent.dpPersonId;
        const parmsRow = {
          hasTeamLessons: props.parms.moduleHolder.hasTeamLessons(),
          isWe: props.parms.isWe,
          student: student,
          toolKey: toolKey
        };
        return <SectionSubRowComp parms={parmsRow} key={toolKey}></SectionSubRowComp>;
      });
    }
  };

  const columns = lessons => lessons.map((lesson, index) => {
    const parmsColumn = {
      lesson: lesson,
      index: index,
      jsonSection: props.parms.sectionModel.jsonSection
    };
    return (
      <td scope="col" className="text-center" key={'lesson_' + index}>
        <SectionColumnComp parms={parmsColumn}></SectionColumnComp>
      </td>
    );
  });

  const helpers = templateHelpers(props.parms);
  const art = isArt();
  const csp = isCSP();
  const isCoordinator = getCurrentPerson().isCoordinator();
  const btnCls = getBtnClass();
  const iconCls = getIconClass();
  const ariaExpanded = getAriaExpanded();
  const srText = getSrText(helpers.sectionName);
  let hasSubmittedPPR = false
  students?.forEach(student => {
    const statusFields = getStatusFields(student.studentModel.jsonStudent, props.parms.isWe)
    if (student.studentModel.scorables[2]?.scorable?.status === 4 && !statusFields.intentNo && !statusFields.notRegistered) {
      hasSubmittedPPR = true
    }
  })

  return (
    <>
      <tr role='row' id={'studentProgressRow_' + props.parms.index} className='main-row'>
        <th scope="row" style={{ fontSize: '14px' }}>
          {helpers.sectionName} - {helpers.teachers}
          {helpers.showIntent &&
            <div className="no-bold">
              {helpers.totalStudentsWithExamIntentYes}/{helpers.totalStudents} Taking Exam
            </div>
          }
        </th>
        {columns(props.parms.sectionModel.options.lessons)}
        {art && isCoordinator && <td scope="col" className="text-center cb-gray5-bg"></td>}
        {csp && isCoordinator && !props.parms.isWe && <td scope="col" className="text-center" style={{ background: "#faf9f7" }}>
          <div className='cb-align-center'>
            {isDownloading ? (
              <>
                <Spinner size='small' className='cb-margin-right-4' />
                {showDownloadAlertMsg && (
                  <div className='cb-red1-color cb-align-center'>
                    <span
                      style={{ fontSize: '11px' }}
                      className='cb-font-weight-black position-relative position-top align-self-center cb-padding-top-8'
                    >
                      The system is generating the PPR.  Do not refresh your screen.
                    </span>
                  </div>
                )}
              </>
             ) : (
              <>
                <YellowButton
                  disabled={!hasSubmittedPPR}
                  small='true'
                  onClick={ async () => {
                      try {
                        setIsDownloading(true)
                        setDownloadErrMsg('')
                        const res = await requestSection(props.parms.sectionModel.jsonSection.sectionId)
                        if (res === 'success') {
                          const obj = await getStudentProgressRowsBySection(
                            props.parms.sectionModel.jsonSection.sectionId,
                            props.parms.moduleHolder,
                            props.parms.isWe
                          )
                          if (obj) {
                            setStudents(obj.students)
                          }
                        } else {
                          setDownloadErrMsg(failureErr)
                        }
                      } catch (err) {
                        setDownloadErrMsg(failureErr)
                      } finally {
                        setIsDownloading(false)
                        setShowDownloadAlertMsg(false)
                      }
                    }
                  }
                >
                  Download Section
                </YellowButton>
                {downloadErrMsg && (
                  <div className='cb-red1-color cb-align-center'>
                    <span
                      style={{ fontSize: '11px' }}
                      className='cb-font-weight-black position-relative position-top align-self-center cb-padding-top-8'
                    >
                      {downloadErrMsg}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </td>}
        <td className="text-center">
          <button type="button" className={btnCls} tabIndex='0'
            aria-controls='studentRows' aria-expanded={ariaExpanded}
            onClick={toggleStudents}>
            <span className={iconCls}>
              <span className="sr-only">{srText}</span>
            </span>
          </button>
        </td>
      </tr>
      {studentRows()}
    </>
  );
};

export default SectionRowComp;
