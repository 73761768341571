import { createBrowserHistory }                                 from 'history'
import { showViewReports, showDeleteScore, showManageCourses,
    showEditCourses, showManageExtensions,
    showTsp , showHome }                                        from '../admin/admin_module'
import { artifactBrowserRouter }                                from '../artifactbrowser/artifactbrowser_module'
import { showOverview, showCourseInstanceHelp, showHelp }        from '../content/content_module'
// import { showTermsAndConditions, confirmTermsAndConditions }    from '../terms/terms_module'
// import { showInstructionHome }                                  from '../main/instruction_controller'
import { checkShouldShowDashboard, showArchive, showAccess }    from '../dashboard/dashboard_module'
import { showModuleOverview }                                    from '../overview/overview_module'
import { showReviewerDashboard }                                from '../reviewer/reviewer_module'
import { showSectionProgress, showCourseProgress }              from '../progress/progress_module'
// import {showManageTeams }                                       from '../manageteams/manageteams'
import { subject, updateSubscription }                          from '../utility/service'

let history, unlisten, subscription, callback

export function startHistory() {
    //console.log('HISTORY startHistory')
    history = createBrowserHistory()

    let subscription = subject.subscribe(message => {
        //console.log('HISTORY got message', message)
        if (message.beforeLocationChange) {
            if (callback) {
                //console.log('HISTORY already had callback set')
            } else {
                //console.log('HISTORY setting up callback')
                callback = message.beforeLocationChange.callback
            }
        }
      })
      updateSubscription('beforeLocationChange', subscription)

    unlisten = history.listen(({ location, action }) => {
        //console.log('HISTORY(listen) saw: ', action, location);
        handleChange(location)
    });
}

export async function handleChange(location) {
    //console.log('HISTORY (handleChange) saw: ',location);
    if (callback) {
        let answer = await callback()
        callback = null

        if (answer==='stay') {
            history.back()
            return
        } else {
            // continue processing the change
        }
    }
    //console.log('HISTORY handleChange started')

    let handled = false
    if (location.pathname==='/' || location.pathname==='/instruction/dashboard') {
        handled = await handleDashboard(location.hash)
    } else if (location.pathname==='/showTermsAndConditions') {
        await showTermsAndConditions()
        handled = true
    } else if (location.pathname==='/confirmTermsAndConditions') {
        await confirmTermsAndConditions()
        handled = true
    }
    if (!handled) {
        //console.log('HISTORY navigate location ' + location.pathname + ', ' + location.hash + ' WAS NOT HANDLED')
    }
    //console.log('HISTORY handleChange finished')
}

// route is the hash part, options is obsolete
// perhaps this method should be called 'handleHash' and 'handleChange' should be 'handleLocation'
// or handleChange could check its argument type
export function navigate(route) {
    //console.log('HISTORY navigate route=' + route)
    let pathName = /undefined/.test(location.pathName) ? '/' : location.pathName
    // history.push(pathName + route)
    window.location.replace(`${pathName}${route}`)
}

function handleDashboard(hash) {
    let groups = /#([a-z,A-Z]*)(\/(.*))?/.exec(hash)
    if (groups && groups.length > 0) {
        let category = groups.length > 1 ? groups[1] : null
        let partial = groups.length > 3 ? groups[3] : null
        if (category==='instruction') {
            return handleInstruction(partial)
        } else if (category==='admin') {
            return handleAdmin(partial)
        } else if (category==='enrollment') {
            return handleEnrollment(partial)
        } else if (category==='reviewer') {
            showReviewerDashboard()
            return true
        }
        return false
    }
}
// #instruction/sections/64835/groups

// hash is #instruction/partial
function handleInstruction(partial) {
    if (partial) {
        let groups = /([a-z,A-Z]*)(\/(.*))?/.exec(partial)
        let name1 = groups.length > 1 ? groups[1] : null
        let partial2 = groups.length > 3 ? groups[3] : null
        if (name1 === 'sections' || name1 === 'courses') {
            return handleSectionAndCourses(name1, partial2)
        } else if (name1 === 'archive') {
            showArchive()
            return true
        } else if (name1 === 'access') {
            showAccess()
            return true
        } else if (name1 === 'dashboard') {
            checkShouldShowDashboard()
            return true
        } else if (name1 === 'reviewer') {
            showReviewerDashboard()
            return true
        } else if (name1 === 'help') { //showHelp has a keyword
            return handleHelp(partial2)
        }
    } else {
        showInstructionHome()
        return true
    }
    return false
}

function handleSectionAndCourses(name1, partial) {
    let groups = /([a-z,A-Z,0-9]*)\/([a-z,A-Z]*)(\/([a-z,A-Z,0-9]*))?/.exec(partial)
    let id1 = groups && groups.length > 1 ? groups[1] : null
    let name2 = groups && groups.length > 2 ? groups[2] : null
    let id2 = groups && groups.length > 4 ? groups[4] : null

    if (name1 === 'sections' && name2 === 'lessons') {
        artifactBrowserRouter(id1, id2)
        return true
    } else if (name1 === 'sections' && name2 === 'modules') {
        showModuleOverview(id2) // section is not used
        return true
    } else if (name1 === 'sections' && name2 === 'overview') {
        showOverview(id1, id2)
        return true
    } else if (name1 === 'sections' && name2 === 'progress') {
        showSectionProgress(id1, id2)
        return true
    } else if (name1 === 'sections' && name2 === 'groups') {
        showManageTeams(id1,id2);
        return true
    } 
    else if (name1 === 'courses' && name2 === 'help') {
        showCourseInstanceHelp(id1, id2)
        return true
    } else if (name1 === 'courses' && name2 === 'progress') {
        showCourseProgress(id1, id2)
        return true
    }
    return false
}
// 'instruction/sections/:sectionId/progress/:we': 'showSectionProgress',
// 'instruction/courses/:courseInstanceId/progress/:we': 'showCourseProgress'
// }

function handleEnrollment(partial) {
    let groups = /([a-z,A-Z,0-9]*)/.exec(partial)
    let name = groups && groups.length > 1 ? groups[1] : null
    if (name==='sections') {
        checkShouldShowDashboard()
        return true    
    }
 }

function handleHelp(partial) {
    let groups = /([a-z,A-Z,0-9]*)/.exec(partial)
    let id1 = groups && groups.length > 1 ? groups[1] : null
    showHelp(id1)
    return true
}

// hash is #admin/partial
function handleAdmin(partial) {
    if (partial) {
        let groups = /([a-z,A-Z-]*)(\/(\d+))?/.exec(partial)
        //console.log('HISTORY handleAdmin groups',groups)
        let category = groups && groups.length > 1 ? groups[1] : null
        let id = groups && groups.length > 3 ? groups[3] : null
        if (category === 'view-reports') {
            showViewReports(id)
            return true
        } else if (category === 'delete-score') {
            showDeleteScore()
            return true
        } else if (category === 'manage-courses') {
            if (id === undefined) {
                showManageCourses()
                return true
            } else {
                showEditCourses(id)
                return true
            }
        } else if (category === 'manage-extensions') {
            showManageExtensions()
            return true
        } else if (category === 'teacher-scoring-provisioning') {
            showTsp()
            return true
        }
    } else {
        showHome()
        return true
    }
    return false
}

function handleManageTeams (partial) {

}  


export function getHistory() { return history }
export function stopHistory() { unlisten() }
