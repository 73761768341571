import { getCourseParameter, getWeCourseParameter, isArt } from '../../entities/courseinstance'
import { getSortStatus } from './student_view'
import { sortBy } from '../../utility/util'
import DPToolTip, { cleanDeadTooltips } from '../../utility/tooltip'
import { getCurrentPerson } from '../../entities/person'
import StudentRow from './studentRow'

export const StudentTable = (props) => {
  const [state, setState] = useState({ sortType: 'alpha' })
  const toolKey = `student_table_${props.sectionId}`
  const toolTip = new DPToolTip(toolKey)

  useEffect(() => {
    toolTip.createTooltips()
    return () => cleanDeadTooltips()
  }, [])

  const serializeData = (props) => {
    let hasTeams = props.moduleHolder.hasTeamLessons() && !props.isWe
    let examintentEnabled
    let apnumberEnabled
    if (props.isWe) {
      examintentEnabled = getWeCourseParameter('examintentEnabled')
      apnumberEnabled = getWeCourseParameter('apnumberEnabled')
    } else {
      examintentEnabled = getCourseParameter('examintentEnabled')
      apnumberEnabled = getCourseParameter('apnumberEnabled')
    }
    return {
      moduleHolder: props.moduleHolder,
      hasTeams: hasTeams,
      sortedByAlpha: state.sortType === 'alpha',
      sortedByStatus: state.sortType === 'status',
      sortedByTeam: state.sortType === 'team',
      showSortBy: (examintentEnabled || apnumberEnabled || hasTeams) &&
        props.studentProgress.students.length > 1, // if (AP number and/or intent to take exam is true) or (hasTeams)
    }
  }

  const selectChange = (event) => {
    setState({ sortType: event.currentTarget.value })
  }

  let helpers = serializeData(props)
  toolTip.removeTooltips()
  let isStudent = getCurrentPerson().isStudent()
  let isTeacher = getCurrentPerson().isTeacher()
  let isCoordinator = getCurrentPerson().isCoordinator()

  let colspan = helpers.hasTeams ? 2 : 1
  // let alphaSelected = helpers.sortedByAlpha ? 'selected' : ''
  // let statusSelected = helpers.sortedByStatus ? 'selected' : ''
  // let teamSelected = helpers.sortedByTeam ? 'selected' : ''
  let studentProgress = props.studentProgress

  // sort the students
  let students = studentProgress.students
  let sortType = state.sortType
  if (studentProgress.forCoordinator) {
    students = sortBy(students, ['completionStatus', 'lastName', 'firstName'])
  } else if (sortType === 'team') {
    students = _.sortBy(students, student => {
      return student.team ? student.team.title : ''
    })
  } else if (sortType === 'status') {
    // sortStatus needs to be generated here since it depends on the url we are on
    students = _.each(students, student => {
      student.sortStatus = getSortStatus(student.jsonStudent, props.isWe)
    })
    students = sortBy(students, ['sortStatus'])
  } else if (sortType === 'alpha') {
    students = sortBy(students, ['lastName', 'firstName'])
  }

  // comparator (student) {
  //     if (options.forCoordinator) return [student.get('completionStatus'), student.get('lastName'), student.get('firstName')]
  //     if (sortType === 'team') return student.get('team') && student.get('team').title
  //     else if (sortType === 'status') return student.get('sortStatus')
  //     return [student.get('lastName'), student.get('firstName')];
  // }

  let header = modules => _.map(modules, (module, index) => {
    if (module.lessons.length) {
      return (
        <th colSpan={module.lessons.length} scope='col'
            className='colgroup text-center' key={'header_' + index}>
          {module.title}
        </th>
      )
    }
  })

  let columns = modules => _.map(modules, module => {
    let keyIndx = 1
    return _.map(module.lessons, (lesson, index) => {
      return (
        <th scope='col' className='text-center colgroup' key={'lesson_' + keyIndx++}>
                        <span data-cb-apricot='tooltip' id={art ? toolTip.removeTooltips() : toolTip.addToolTip()}
                              data-cb-title={lesson.title}
                              aria-describedby={'desc-' + lesson.abbreviation}>
                            {lesson.abbreviation}
                        </span>
          <span id={'desc-' + lesson.abbreviation} className='sr-only'>{lesson.title}</span>
        </th>
      )
    })
  })

  let studentRows = () => { // students are studentModel
    return _.map(students, (studentModel, index) => {
      let newToolKey = toolKey + '_student_' + studentModel.jsonStudent.dpPersonId
      let newProps = {
        hasTeamLessons: props.moduleHolder.hasTeamLessons(),
        isWe: props.isWe,
        studentModel: studentModel,
        lessons: studentProgress.lessons,
        sectionId: props.sectionId,
        toolKey: newToolKey,
        apNumber: props?.apNumber,
        courseInstanceId: props.courseInstanceId,
      }
      return (
        <StudentRow {...newProps} key={index} />
      )
    })
  }

  let tableIsArt = () => {
    let { courseInstanceId } = props
    return ['32', '33', '34'].indexOf(courseInstanceId.slice(0, 2)) > -1
  }

  let art = isArt() || (props.courseInstanceId && tableIsArt()), isReviewer = getCurrentPerson().isReviewer()

  return (
    <table className='table data-table'>
      <caption className='sr-only'>Student Progress by Assignment</caption>
      <thead>
        <tr>
          {helpers.showSortBy &&
            <th id='nontable' scope='col' colSpan={colspan}
                className='empty remove-left-padding remove-top-padding'>
              <label htmlFor='sortby' style={{ color: 'black' }}>Sort By:</label>
              <div className='cb-select'>
                <select id='sortby' onChange={selectChange}>
                  <option value='alpha'>ALPHABETICAL</option>
                  <option value='status'>STATUS</option>
                  {helpers.hasTeams &&
                    <option value='team'>TEAM</option>
                  }
                </select>
              </div>
            </th>
          }
          {!helpers.showSortBy &&
            <th scope='col' colSpan={colspan} className='empty'>
              <span className='sr-only'>Student name and AP number</span>
            </th>
          }
          {header(helpers.moduleHolder.modules)}
          {art && (isStudent || isTeacher || isCoordinator || isReviewer) && !props.isWe &&
            <th scope='col' colSpan='1' className='empty' style={{ width: '135px' }}></th>}
        </tr>
        <tr>
          <th scope='col' className='text-center colgroup'>Name / Status</th>
          {helpers.hasTeams &&
            <th scope='col'>Team</th>
          }
          {columns(helpers.moduleHolder.modules)}
          {art && (isStudent || isTeacher || isCoordinator || isReviewer) && !props.isWe &&
            <th scope='col' className='text-center colgroup'>Download Portfolio</th>}
        </tr>
      </thead>
      <tbody>
        {studentRows()}
      </tbody>
    </table>
  )


}
