import { FaqComp } from "./faq";
import { getCourseInstances } from "../../entities/courseinstance";
import { useParams } from "react-router-dom";
import { cmsHelp } from "../static_content";
import { getModuleHolder } from "../../entities/modules";
import { getCurrentPerson } from "../../entities/person";
import { entitiesToChr } from "../../utility/util";
import CourseLayout from "../../main/views/courseLayout";
import { Spinner } from "@cb/apricot-react";

const matchesUser = (faq) => {
    var faqRoles = (faq['Target Audience'] || '').split(', ')
    // APPortfolioCoordiator and APCoordinator should be treated the same
    // that is, a faq with one should have the other
    let roleA = 'ApPortfolioCoordinator'
    let roleB = 'ApCoordinator'
    if (_.contains(faqRoles, roleA)) {
        faqRoles.push(roleB)
    } else if (_.contains(faqRoles, roleB)) {
        faqRoles.push(roleA)
    }
    var currentUserRoles = getCurrentPerson().roles
    return _.intersection(faqRoles, currentUserRoles).length > 0
}

//matches course if current course id matches one of the ids of the faq
const matchesCourse = (faq, courseId) => {
    let ids = faq['Course Instance Id'] // string, comma separated list of ids
    if (ids) {
        return _.some(ids.split(','), id => id.trim() === courseId)
    } else {
        return false
    }
}

const Help = () => {
    const [loading, setLoading] = useState(true)
    const [props, setProps] = useState({})
    const { courseInstanceId } = useParams()

    const getAllContent = async () => {
        const moduleHolder = await getModuleHolder(courseInstanceId)
        const faqs = await cmsHelp()
        let predicate, filtered = []
        if (courseInstanceId) {
            predicate = faq => matchesUser(faq) && matchesCourse(faq, courseInstanceId)
        } else {
            predicate = faq => matchesUser(faq) && !faq['Course Instance Id']
        }
        if (faqs) {
            filtered = faqs.filter(predicate)
            filtered.forEach(faq => {
                faq.Question = entitiesToChr(faq.Question)
                faq.Answer = entitiesToChr(faq.Answer)
            })
        }
        setProps({
            content: filtered,
            courseInstanceId,
            moduleHolder,
            keyword: 'help'
        })
    }

    useEffect(() => {
        getAllContent()
        setLoading(false)
    }, [])

    useEffect(() => {
        getAllContent()
        setLoading(false)
    }, [courseInstanceId])

    const MainComponent = ({ props }) => {
        const templateHelpers = (params, partition) => {
            let helpContent = partition[1][0];
            let pageTitle
            if (params.courseInstanceId) {
                pageTitle = getCourseInstances().getCourseById(params.courseInstanceId).title
            } else {
                pageTitle = 'General'
            }
            pageTitle = pageTitle + ' Help'
            let userGuidesContent = helpContent && helpContent['User Guides']
            let hasUserGuides = !_.isEmpty(userGuidesContent)

            let contactUsContent = helpContent && helpContent['Contact Us']
            let hasContactUs = !_.isEmpty(contactUsContent)

            let introContent = helpContent && helpContent['Help Intro']
            let hasIntro = !_.isEmpty(introContent)

            return {
                userGuidesContent,
                hasUserGuides,
                contactUsContent,
                hasContactUs,
                faqMainSectionNames: params.mainSectionNames,
                introContent,
                hasIntro,
                pageTitle
            }
        }

        // returns {main_name1: {sub_name1: [faqs], sub_name2: [faqs]}, ...}
        const transformFAQData = (keyword, partition) => { //create heirarchy with main and sub sections
            let grps = _.groupBy(partition[0], (faq) => nameFromLink(faq['FAQ Main Section']));
            let groups = {}
            _.each(grps, (value, key) => {
                if (key && key != 'null') {
                    groups[key] = value
                }
            })

            // groups is an object with entries: {name1: [faq1,faq2], name2: ...}
            // the lists will be grouped again by sub section
            return _.each(groups, (faqList, key) => {
                groups[key] = _.groupBy(faqList, (faq, num) => {
                    faq.id = key.replace(/\s/g, '') + num; //accessibility/bootstrap, need unique id for accordions to work properly
                    faq.subtitle = nameFromLink(faq['FAQ Sub Section']) || false; //accessibility, used to maintain proper heading heirarchy
                    faq.selected = keyword && faq.Question.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
                    return faq['FAQ Sub Section'];
                })
            })
        }

        const createFAQView = (keyword, partition) => {
            const faqGroups = transformFAQData(keyword, partition) || {}
            let faqMainSectionNames
            if (_.isEmpty(faqGroups)) {
                faqMainSectionNames = []
            } else {
                faqMainSectionNames = _.keys(faqGroups);
            }
            return { faqMainSectionNames, faqGroups }
        }

        const jumpLink = (e) => {
            e.preventDefault();
            scrollTo($(e.currentTarget).attr('href'))

        }

        const nameFromLink = (link) => {
            let groups = />(.*)</.exec(link)
            if(_.isEmpty(groups) || groups.length < 1) {
                return link
            } else {
                return groups[1]
            }
        }

        const scrollTo = (id) => {
            var $dest = $(id);
            // The 60 seems to account for the top menu's height
            $dest && $('html, body').animate({ scrollTop: $dest.offset().top - 60 }, 0);
            $dest.focus();
        }

        const partition = _.partition(props.content, item => !(item.Question === null || item.Question.length === 0))

        let keyword = props.keyword;
        let { faqMainSectionNames: mainSectionNames, faqGroups } = createFAQView(keyword, partition)
        const newProps = { mainSectionNames, faqGroups, ...props }
        let helpers = templateHelpers(newProps, partition)

        mainSectionNames = mainSectionNames.map((sectionName, index) => (
          <div key={sectionName}>
              <a href={'#faqGroup_' + index}
                 title={'Go to ' + sectionName}
                 onClick={jumpLink}>
                  {sectionName}
              </a>
          </div>
        ))

        return (
          <div>
              <h4 id="topHelp" className="h1">{helpers.pageTitle}</h4>

              <div className='general-help-link'>

                  {helpers.hasUserGuides &&
                    <div key='guides'>
                        <a href="#userguides" title="Go to User Guides"
                           onClick={jumpLink}>
                            User Guides
                        </a>
                    </div>
                  }
                  {mainSectionNames}
                  {helpers.hasContactUs &&
                    <div key='contacts'>
                        <a href="#contactus" title="Go to Contact Us"
                           onClick={jumpLink}>
                            Contact Us
                        </a>
                    </div>
                  }
              </div>

              {helpers.hasIntro &&
                <div id="helpintro" className="add-top-margin-3x"
                     dangerouslySetInnerHTML={{ __html: helpers.introContent }} >
                </div>
              }

              {helpers.hasUserGuides &&
                <div id="userguides" tabIndex="-1"
                     dangerouslySetInnerHTML={{ __html: helpers.userGuidesContent }} />
              }

              <div id="region-faqs">
                  <FaqComp faqGroups={faqGroups} />
              </div>

              {helpers.hasContactUs &&
                <div id="contactus" tabIndex="-1"
                     dangerouslySetInnerHTML={{ __html: helpers.contactUsContent }} />
              }
          </div>
        )
    }

    if (loading) return <Spinner />

    if (props.moduleHolder && props.content) {
        const { moduleHolder, ...remainingProps } = props

        if (!courseInstanceId) return <MainComponent props={remainingProps} />

        return (
          <>
              <CourseLayout props={{ moduleHolder }}>
                  <MainComponent props={remainingProps} />
              </CourseLayout>
          </>
        )
    }

    return null
}

export default Help