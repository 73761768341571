import React                            from 'react';
import { YellowButton }                 from '@cb/apricot-react';
import { bsliGet, bsliDelete }          from "../../_core/util/request";
import { AlertComp  }                   from '../../_core/views/alert.jsx'
import { cleanUpBody  }                 from '../../_core/views/modal.jsx'
import { isNumeric_0_9 }                from '../../utility/util'
import CBModal                          from '@cb/apricot/CBModal';

export default class DeleteComp extends React.Component {
    constructor(props) {
        super(props);
        this.defaultVerifyError = 'Error: There was an unexpected error when retrieving the details of this scorable.'
        this.defaultDeleteError = 'Error: There was an unexpected error when deleting the scores of this scorable.'
        this.state = { //state is by default an object
            scoreData: [],
            alertProps: false,
            showModal:false,formattedStudents:'',
            isDisabled:true
        }
        this.changeHandler = this.changeHandler.bind(this);
    }

    changeHandler(event) {
        var inputId = event.target.value
        if (isNumeric_0_9(inputId)) {
            this.setState({isDisabled: false});
        } else {
            this.setState({isDisabled: true});
        }
    }

    render() {
        const renderModalConfirm = () => {
            return    <div  className="cb-modal" id="confirmDeleteScore" aria-hidden="true"
                            data-cb-apricot="modal">
                <div className="cb-modal-overlay" data-cb-modal-close>
                    <div className="cb-modal-container" role="dialog" aria-modal="true" aria-labelledby="modalID1Title">
                        <div className="cb-modal-header">
                            <h5 className="h5" id="modalID1Title">Confirmation</h5>
                            <button type="button" className="cb-btn cb-btn-square cb-btn-greyscale cb-btn-close" aria-describedby="modalID1Title" data-cb-modal-close>
                                <span className="cb-glyph cb-x-mark" aria-hidden="true"></span>
                                <span className="sr-only">Close</span>
                            </button>
                        </div>
                        <div className='alert alert-danger'></div>
                        <div></div>
                        <br/>
                        <h5 className="h5 cb-glyph cb-check-circle cb-green1-color cb-glyph-64"><b>              Scores  Deleted</b></h5>
                        <div></div>
                        <br/>
                        <div className="cb-modal-header">
                            <p>The scores and teacher attestation have been deleted. The teacher can now return the file to the student via the scoring application.</p>
                        </div>
                        <div className="cb-modal-footer">
                            <div className="cb-btn-row">
                                <button type="button" className="cb-btn cb-btn-yellow" data-cb-modal-close
                                        aria-label="Close this dialog window">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        const renderModal = () => {
            return (
            <div  className="cb-modal" id="deleteScoreModal" aria-hidden="true"
                            data-cb-apricot="modal">
                <div className="cb-modal-overlay" data-cb-modal-close>
                    <div className="cb-modal-container" role="dialog" aria-modal="true" aria-labelledby="modalID1Title">
                        <div className="cb-modal-header">
                            <h5 className="h5" id="modalID1Title">Confirm Details</h5>


                            <button type="button" className="cb-btn cb-btn-square cb-btn-greyscale cb-btn-close" aria-describedby="modalID1Title" data-cb-modal-close>
                                <span className="cb-glyph cb-x-mark" aria-hidden="true"></span>
                                <span className="sr-only">Close</span>
                            </button>
                        </div>
                        <div className="cb-modal-header">
                            <p>You are about to delete scores and attestations to originality for:</p>
                        </div>
                        <div className="cb-modal-content">
                            {renderModalContent()}
                        </div>
                        <div className="cb-modal-header">
                            <p>Please ensure that this is the correct student, teacher and assignment.Once deleted scores can not be restored.</p>
                        </div>
                        <div className="cb-modal-footer">
                            <div className="cb-btn-row">
                                <button type="button" className="cb-btn cb-btn-naked cb-no-padding cb-btn-close" data-cb-modal-close>
                                    Cancel
                                </button>
                                <YellowButton onClick={(e) => this.executeAction(e.target)}>Submit</YellowButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }

        const renderModalContent = () => {
            return (
                <div>
                    <table id='scores' className="cb-table" border="1">

                        <tbody>
                            {this.state.scoreData.Team ?
                                <tr>
                                    <td><b>Team </b></td><td>{this.state.scoreData.Team}</td>
                                </tr>
                                : null}
                            {this.state.scoreData.Student ?
                                <tr>
                                    <td><b>Student </b></td><td>{this.state.scoreData.Student}</td>
                                </tr>
                                : null}
                            {this.state.scoreData.Students ?
                                <tr>
                                    <td><b>Student(s)</b></td><td>{this.state.formattedStudents}</td>
                                </tr>
                                : null}
                            <tr>
                                <td><b>Assignment</b></td><td>{this.state.scoreData.Assignment}</td>
                            </tr>
                            <tr>
                                <td><b>Teacher </b></td><td>{this.state.scoreData.Teacher}</td>
                            </tr>
                            <tr>
                                <td><b>Course </b></td><td>{this.state.scoreData.Course}</td>
                            </tr>
                            <tr>
                                <td><b>Section </b></td><td>{this.state.scoreData.Section}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }
        const searchData = () => {
            this.search();
        };

        return (
            <div>
                <div id="admin-search-form" className="row ">
                    <div className="col-lg-8 col-md-9 col-xs-12 padding-0">
                        <h4 className="h1">Delete Scores</h4>
                        <p className="h3">Enter the ID of the scorable whose scores you want to delete</p>
                        <div className="alert-wrapper">
                            {this.state && this.state.alertProps && this.state.alertProps.showAlert &&
                                <AlertComp params={this.state.alertProps}></AlertComp>
                            }
                        </div>
                        <div className="form-group cb-input">
                            <label  htmlFor="templateType">Enter Scorable ID</label>
                            <input type="text" id="scoreId"  onChange={this.changeHandler} maxLength={10}/>
                        </div>
                        <div className="cb-input form-group">
                            <span> <YellowButton  disabled={this.state.isDisabled} onClick={searchData} >Submit</YellowButton></span>
                        </div>

                    </div>
{                   <div>
                        {renderModal()}
                        {renderModalConfirm()}
                    </div>}
                </div>
            </div>
        )
    }

    async search() {
        this.clearAlert()
        let $elem = $('#scoreId')
        var inputID = $elem.val()
        let student = ''

        try {
            await bsliGet('scorables/' + inputID + '/deleteScore')
                .then(response => {
                    this.setState({ ["scoreData"]: response });
                    if (response.Students) {
                        response.Students.map(name => (
                            student = student.concat(name) + ", "
                        ))
                        let n = student.lastIndexOf(",")
                        student = student.substring(0, n)
                        this.setState({ ["formattedStudents"]: student });
                    }
                    this.popModal("deleteScoreModal")
                })
                .catch((error) => {
                    this.displayAlert(error.message);
                    return;
                })
        } catch (e) {
            console.error('createCourseCd had error')
        }
    }

    executeAction(target) {
        let $elem = $('#scoreId')
        var scoreId = $elem.val()
        let url = 'scorables/'+scoreId+'/deleteScore';

        bsliDelete(url).then(() => {
            let el = target ? target.previousElementSibling : null
            if (el) {
                el.click();
            }
            this.popModal("confirmDeleteScore")
        })
        .catch((model, xhr) => {
            this.displayAlert("Error happenned please try again.", "editmodule");
        })
    }

    popModal(target) {
        this.setState({ ["showModal"]: true });
        CBModal({
            elem: target
        }).show()
    }

    dissmissModal(target) {
        CBModal({
            elem: target
        }).close()
        cleanUpBody()
    }

    clearAlert() {
        this.setState({
            alertProps: false
        })
    }

    displayAlert(message) {
        this.setState({
            alertProps: {
                showAlert: true,
                message: message
            }
        })
    }

    // validate() {
    //     $(this.$('button')).enableIf(this.$('input').val().trim())
    //   }

  }
