import {Modal} from "@cb/apricot-react"
import React from 'react';
import TitleComp from './title'
import ScoringMenuComp from './scoringMenu'
import SubmissionLayout from './submissionLayout'

const SubmissionModal = ({open, parms, onClose}) => (
    open ? (
        <Modal
            open={open}
            withHeader={true}
            withFooter={false}
            title={<h5 className="cb-black1-color">{parms?.lesson.lessonTitle || parms?.lesson.title}</h5>}
            className='submission-modal'
            escClose={false}
            onClose={onClose}
            nested
        >
            <div className="container-fluid">
                <div className="row add-top-margin-3x">
                    <div className="col-md-6" id="region-title">
                        <TitleComp parms={parms}/>
                    </div>
                    <div className="col-md-6 flex-nowrap" id="region-utility">
                        <ScoringMenuComp parms={parms}/>
                    </div>
                </div>
                <div className="row add-top-margin-3x">
                    <div className="col-md-12 col-sm-12" id="region-submission">
                        <SubmissionLayout parms={parms}/>
                    </div>
                </div>
            </div>
        </Modal>
    ) : <></>
)

export default SubmissionModal
