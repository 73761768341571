import { Subject } from 'rxjs'

export const subject = new Subject()

let subscriptions = []

// replace the named subscription, unsubscribing from the old one
// a null subscription causes no harm
export function updateSubscription(name,subscription) {
    if (subscriptions[name]) {
        subscriptions[name].unsubscribe()
    }
    subscriptions[name] = subscription
}

export function refreshActivityFeed () {
    subject.next({
        refreshActivityFeed: {
        }
    })
}

export function refreshSupportingMaterials () {
    subject.next({
        refreshSupportingMaterials: {
        }
    })
}

export function refreshMenuBar() {
    subject.next({
        refreshMenuBar: {
        }
    })
}

export function refreshMenuBarWR1() {
    subject.next({
        refreshMenuBarWR1: {
        }
    })
}

export function refreshMenuBarWR() {
    subject.next({
        refreshMenuBarWR: {
        }
    })
}

export function refreshAlert() {
    subject.next({
        refreshAlert: {
        }
    })
    subject.next({
        refreshAlert1: {
        }
    })
}
