import React, { Fragment } from 'react'
import useImages from '../entities/imageData'
import {
    dragStartPanel, dragOverPanel,
    dragLeavePanel, dragDropPanel
} from '../entities/dragging'


export function ReorderComp({ sections, onChange }) {
    const { imageUrl, imageId } = useImages()
    const duplicateImgs = Array.isArray(sections[0]);
    let panels = sections.map((section, index) => {
        let url = imageUrl(section)//imageThumbnail(section)
        let hiddenCls = url && url.length > 0 ? '' : 'hidden'
        let id = imageId(section)
        let isArray = Array.isArray(section)
        let singleImg = !isArray
        let hasImages = isArray ? (section[0].hasImage || section[1].hasImage) : section.hasImage;
        if (singleImg) {
            return (<div
                key={'reorder_panel' + index} className='reorder-panel cb-card cb-no-border'
                style={{ height: '250px', border: '1px black solid', marginTop: '10px' }}
                draggable='true' data-id={imageId(section)}
                onDragStart={dragStartPanel} onDragOver={dragOverPanel}
                onDragLeave={dragLeavePanel} onDrop={onDrop}>
                {section.hasImage && !Array.isArray(section) &&
                    <Fragment>
                        <div className='cb-card-header' style={{ paddingLeft: '5%' }}>
                            {'Work ' + id}
                        </div>
                        <img id={'reorder_' + index} className={'reorder ' + hiddenCls}
                            style={{ objectFit: 'scale-down', maxHeight: '150px', verticalAlign: 'middle' }} src={url}
                        />
                    </Fragment>
                }
                {!hasImages &&
                    <div style={{ textAlign: 'center', paddingTop: '35%', paddingLeft: '5%', paddingRight: '5%', color: '#e57200' }}>
                        Work {id} does not have an image yet
                    </div>
                }
            </div>)
        } else {
            return (<div
                key={'reorder_panel' + index} className='row reorder-panel cb-card cb-no-border col-xs-12 col-md-12 flex-xs-row display-xs-flex cb-align-center'
                style={{ height: '350px', border: '1px black solid', marginTop: '10px' }}
                draggable='true' data-id={index + 1}
                onDragStart={dragStartPanel} onDragOver={dragOverPanel}
                onDragLeave={dragLeavePanel} onDrop={onDrop}>
                {hasImages &&
                    url.map((u, indx) => {
                        if (u) {
                            return <Fragment key={"frgmnt_" + index + "_" + indx} >
                                {indx == 0 &&
                                    <div className='cb-card-header col-md-12' style={{ paddingLeft: '5%' }} key={"workindx_" + index + "_" + indx} >
                                        {'Work ' + (index+1) }
                                    </div>
                                }
                                <div className="col-md-6 imageFilled-0.1 cb-white-bg img-container">
                                    <img id={'reorder_' + index} className={'cb-img-fluid cb-img-stretch thumbnail  reorder' + hiddenCls}
                                        style={{ objectFit: 'scale-down'}} src={u}
                                        key={"imgindx_" + indx}
                                    />
                                </div>
                            </Fragment>
                        } else {
                            return <Fragment key={"frgmnt_" + index + "_" + indx}  > {indx == 0 && 
                                <div className='cb-card-header col-md-12' style={{ paddingLeft: '5%'}} key={"workindx_" + index + "_" + indx} >
                                    {'Work ' + (index + 1) }
                                </div>
                            }
                                <div className="col-md-6 imageFilled-0.1 cb-white-bg img-container"  key={"workindxbtm_" + indx}>
                                       <div className={'cb-img-fluid cb-img-stretch thumbnail  reorder' + hiddenCls}></div>  
                                </div>
                            </Fragment>
                        }
                    }
                    )
                }
                {!hasImages &&
                    <div style={{ textAlign: 'center', paddingTop: '15%', paddingLeft: '5%', paddingRight: '5%', color: '#e57200' }} ey={"workindx_" + index}>
                        Work {index + 1} does not have an image yet
                    </div>
                }
            </div>
            )
        }

    })

    let getPanels = (a, b) => {
        let list = []
        if (a < sections.length) {
            for (let i = a; i < Math.min(sections.length, b); i++) {
                let divElem = !duplicateImgs ? (
                    <div className='col-xs-12 col-sm-3' key={'panel_' + i}>
                        {panels[i]}
                    </div>
                ) : (
                    <div className='col-xs-12 col-sm-6' key={'panel_' + i}>
                        {panels[i]}
                    </div>
                )
                list.push(divElem)
            }
        }
        return list
    }

    return (duplicateImgs ?
        <div>
            <div className='row'>
                {getPanels(0, 2)}
            </div>
            <div className='row'>
                {getPanels(2, 4)}
            </div>
            <div className='row'>
                {getPanels(4, 6)}
            </div>
            <div className='row'>
                {getPanels(6, 8)}
            </div>
            <div className='row'>
                {getPanels(8, 10)}
            </div>
            <div className='row'>
                {getPanels(10, 12)}
            </div>
            <div className='row'>
                {getPanels(12, 14)}
            </div>
            <div className='row'>
                {getPanels(14, 15)}
            </div>
        </div> :
        <div>
            <div className='row'>
                {getPanels(0, 4)}
            </div>
            <div className='row'>
                {getPanels(4, 8)}
            </div>
            <div className='row'>
                {getPanels(8, 12)}
            </div>
            <div className='row'>
                {getPanels(12, 15)}
            </div>
        </div>
    )




    function onDrop(event) {
        let inst = dragDropPanel(event)
        onChange(inst)
    }
}