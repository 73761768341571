import { NakedButton } from '@cb/apricot-react'
import { isNullish } from '../../../utility/util'
import useImages from '../entities/imageData'
import { dataTransfer, loadFromDesktop } from '../entities/dragging'
import { ImageCard } from './imageCard'
import { ImageButtons } from './imageButtons'

// this paints the top portion of the image card
export const ArtCardTop = ({
    idRoot,
    index,
    readOnly,
    section,
    uploadError,
    checkFile,
    showOneCard,
    setScreenSize,
    onChange,
    is3D,
    type,
    isSaving
}) => {
    const { imageUrl, imageFilename, imageUploadError } = useImages()

    let id = idRoot + index
    let imgId = `dropzone_${id}`
    let url = imageUrl(section)
    let fileName = imageFilename(section)

    let noImage             = !section.hasImage
    let disableInput        = showOneCard.bool || readOnly || isSaving
    let is3DAndSWCourse     = is3D && type === 'SW'
    let showFullScreenBtnCls, hideFullScreenBtnCls,showFull,showHide
    const [modalOpen, setModalOpen] = useState(false)
    const [modalOpenImageOne, setModalOpenImageOne] = useState(false)
    const [modalOpenImageTwo, setModalOpenImageTwo] = useState(false)

    // Variables for 2 Images
    let id1, id2, imgId1, imgId2, url1, url2, fileName1, fileName2, newIndex1, newIndex2, hasImage1, hasImage2
    if (is3DAndSWCourse) {
        const diffInd = index + 1
        newIndex1 = `${diffInd}.1`
        newIndex2 = `${diffInd}.2`
        id1 = `${idRoot}${diffInd}.1`
        id2 = `${idRoot}${diffInd}.2`
        imgId1 = `dropzone_${id1}`
        imgId2 = `dropzone_${id2}`
        url1 = imageUrl(section)[0]
        url2 = imageUrl(section)[1]
        fileName1 = imageFilename(section)[0]
        fileName2 = imageFilename(section)[1]
        hasImage1 = section[0].hasImage
        hasImage2 = section[1].hasImage
    }

    // This applies to sections that aren't multi-imaged
    if (section.hasImage) {
        if (showOneCard.bool) {
            showFullScreenBtnCls = 'hidden'
            hideFullScreenBtnCls = ''
            showFull = false
            showHide = true
        } else {
            showFullScreenBtnCls = ''
            hideFullScreenBtnCls = 'hidden'
            showFull = true
            showHide = false
        }
    } else {
        showFullScreenBtnCls = 'hidden'
        hideFullScreenBtnCls = 'hidden'
        showFull = true
        showHide = false
    }

    let sectionErrorMsg = imageUploadError(section)
    let sectionError3DMsg1 = is3DAndSWCourse ? imageUploadError(section[0]) : null
    let sectionError3DMsg2 = is3DAndSWCourse ? imageUploadError(section[1]) : null
    let showWarning,uploadErrorMsg

    if (isNullish(sectionErrorMsg) && isNullish(sectionError3DMsg1) && isNullish(sectionError3DMsg2)) {
        showWarning = false
        uploadErrorMsg = ''
    } if (!isNullish(sectionError3DMsg1) && sectionError3DMsg1 !== "") {
        showWarning = true
        uploadErrorMsg = sectionError3DMsg1
    } if (!isNullish(sectionError3DMsg2) && sectionError3DMsg2 !== "") {
        showWarning = true
        uploadErrorMsg = sectionError3DMsg2
    } if (!isNullish(sectionErrorMsg) && sectionErrorMsg !== "") {
        showWarning = true
        uploadErrorMsg = sectionErrorMsg
    }

    const warningCls = showWarning ? '' : 'hidden'
    let imgContainerCls = section.hasImage ? 'cb-white-bg' : 'cb-gray5-bg'
    let img1ContainerCls = hasImage1 ? 'cb-white-bg' : 'cb-gray5-bg'
    let img2ContainerCls = hasImage2 ? 'cb-white-bg' : 'cb-gray5-bg'

    const hiddenFileInputRef = useRef(null)
    const hiddenFileInputRef0 = useRef(null)
    const hiddenFileInputRef1 = useRef(null)

    useEffect(() => {
        if (is3DAndSWCourse) {
            if (hasImage1) document.getElementById(imgId1).setAttribute('draggable', 'false')
            if (hasImage2) document.getElementById(imgId2).setAttribute('draggable', 'false')
        } else if (section.hasImage) document.getElementById(`thumbnail_${id}`).setAttribute('draggable', 'false')
    }, [section.hasImage, hasImage1, hasImage2])

    const dragOver = (event = null) => {
        if (readOnly) return
        let elem
        if (is3DAndSWCourse) {
            elem = event.target.id ? document.getElementById(event.target.id) : event.target
            const noImageOneOrTwo = elem.id.split('-')[1]
            const thumbnailOneOrTwo = elem.id.split('_')[2]
            if (noImageOneOrTwo) {
                const topLevelDiv = document.getElementById(`noImageCard-${noImageOneOrTwo}`)
                topLevelDiv?.classList.add('highlight')
                topLevelDiv?.classList.remove(imgContainerCls)
            } else if (thumbnailOneOrTwo) {
                const topLevelDiv = document.getElementById(`imageCard-${thumbnailOneOrTwo}`)
                topLevelDiv?.classList.add('highlight')
                topLevelDiv?.classList.remove(imgContainerCls)
            }
        } else {
            elem = document.getElementById(`img_container_${id}`)
            elem?.classList.add('highlight')
            elem?.classList.remove(imgContainerCls)
        }
    }

    const dragLeave = (event = null) => {
        if (readOnly) return
        let elem
        if (is3DAndSWCourse) {
            elem = event.target.id ? document.getElementById(event.target.id) : event.target
            const noImageOneOrTwo = elem.id.split('-')[1]
            const thumbnailOneOrTwo = elem.id.split('_')[2]
            if (noImageOneOrTwo) {
                const topLevelDiv = document.getElementById(`noImageCard-${noImageOneOrTwo}`)
                topLevelDiv?.classList.remove('highlight')
                topLevelDiv?.classList.add(imgContainerCls)
            } else if (thumbnailOneOrTwo) {
                const topLevelDiv = document.getElementById(`imageCard-${thumbnailOneOrTwo}`)
                topLevelDiv?.classList.remove('highlight')
                topLevelDiv?.classList.add(imgContainerCls)
            }
        } else {
            elem = document.getElementById(`img_container_${id}`)
            elem?.classList.remove('highlight')
            elem?.classList.add(imgContainerCls)
        }
    }

    const onDrop = async (event, id) => {
        if (readOnly) return
        const useNewId = event.target.id.includes('.')
        const noImageOrThumbnail = 'dropzone'
        const newId = useNewId ? event.target.id.includes('.1') ? `${noImageOrThumbnail}_${id1}` : `${noImageOrThumbnail}_${id2}` : null
        const index = useNewId ? event.target.id.includes('.1') ? '0' : '1' : null
        const idForParent = event.target.id?.substring(12), whichImgContainer = idForParent.substring(2)
        const isImageOne = whichImgContainer === '1' && hasImage1, isImageTwo = whichImgContainer === '2' && hasImage2
        const whichImg = isImageOne || isImageTwo ? 'imageCard' : 'noImageCard'
        if (newId && useNewId) {
            try {
                const result = await dataTransfer(event, newId)
                if (result) {
                    let img = document.getElementById(newId)
                    onChange(img, result, index)
                    let parent = document.getElementById(`imageCard-${idForParent}`)
                    if (!parent) parent = document.getElementById(`noImageCard-${idForParent}`)
                    parent?.classList.remove('highlight')
                    if (whichImgContainer === '1' && !hasImage1) parent.classList.add(img1ContainerCls)
                    if (whichImgContainer === '2' && !hasImage2) parent.classList.add(img2ContainerCls)
                } else {
                  //  console.log('no file returned')
                }
            } catch (e) {
                let parent = document.getElementById(`${whichImg}-${idForParent}`)
                parent?.classList.remove('highlight')
                if (whichImgContainer === '1' && !hasImage1) parent.classList.add(img1ContainerCls)
                if (whichImgContainer === '2' && !hasImage2) parent.classList.add(img2ContainerCls)
                uploadError(newId, e, index)
            }
        } else {
            dataTransfer(event,imgId)
                .then(file => {
                    if (file) {
                        let $img = document.getElementById(imgId), parent = document.getElementById(`img_container_${id}`)
                        onChange($img,file)
                        parent.classList.remove('highlight')
                        parent.classList.add(imgContainerCls)
                    } else {
                      //  console.log('no file returned')
                    }
                })
                .catch(err => {
                    const $elem = document.getElementById(`img_container_${id}`)
                    $elem.classList.remove('highlight')
                    $elem.classList.add(imgContainerCls)
                    uploadError(id,err)
                })
        }
    }

    const onError = (id) => {
        console.error('img tag had an error')
        uploadError(id)
    }

    const deleteCard = (event) => {
        if (is3DAndSWCourse) {
            hiddenFileInputRef0.current.value = ''
            hiddenFileInputRef1.current.value = ''
        } else hiddenFileInputRef.current.value = ''
        document.querySelector(`#height_${id}`).value = ''
        document.querySelector(`#width_${id}`).value = ''
        const depthInput = document.querySelector(`#depth_${id}`)
        if (depthInput) {
          depthInput.value = ''
        }
        document.querySelector(`#card_${id} textarea`).value = ''
        setScreenSize(false,0)
        onChange(event, undefined, undefined, true)
    }

    const imgHandler = (inputRef) => {
        inputRef.current.click()
    }

    const handleInputChange = (event, id, imgId, imageIndex = null) => {
        const file = event.target.files[0]
        if (checkFile(file, imageIndex) && file) {
            loadFromDesktop(file, imgId)
                .then(() => {
                    const imgElement = document.getElementById(imgId)
                    onChange(imgElement, file, imageIndex)
                })
        }
    }

    const createImageCardSection = () => {
        if (is3DAndSWCourse) {
            return {
                hasImage1,
                hasImage2,
                newIndex1,
                newIndex2,
                img1ContainerCls,
                img2ContainerCls,
                url1,
                url2,
                imgId1,
                imgId2,
                id1,
                id2,
                fileName1,
                fileName2,
                onError,
                setModalOpenImageOne,
                setModalOpenImageTwo,
                sectionError3DMsg1,
                sectionError3DMsg2
            }
        } else {
            return {
                hasImage: section.hasImage,
                setModalOpen,
                showFullScreenBtnCls,
                hideFullScreenBtnCls,
                index,
                onError,
                fileName,
                url,
                imgId,
                id,
                sectionErrorMsg
            }
        }
    }

    const createImageButtonSection = () => {
        if (is3DAndSWCourse) {
            return {
                newIndex1, newIndex2, hiddenFileInputRef0, hiddenFileInputRef1, handleInputChange,
                imgId1, id1, fileName1, setModalOpenImageOne, modalOpenImageOne,
                imgId2, id2, fileName2, setModalOpenImageTwo, modalOpenImageTwo, imgHandler, disableInput, url1, url2, hasImage1, hasImage2
            }
        } else {
            return {
                hiddenFileInputRef, handleInputChange, id, imgId, imgHandler, fileName, url, noImage, setModalOpen, modalOpen, index, showHide, showFull, disableInput
            }
        }
    }

    return (
        <>
            <div id={'Art_Image_' + id} className={`image col-md-12`}>
                <div className='row'>
                    <div id= 'clearAll' className='offset-md-6 col-md-6'>
                        <NakedButton  type="button" className='cb-no-padding cb-btn-close'
                            disabled={is3DAndSWCourse ? (!hasImage1 && !hasImage2 || disableInput) : (noImage || disableInput)} icon='x-mark'
                            data-type='delete' data-index={index}
                            onClick={deleteCard}>
                            <span>Clear All</span>
                            <span className="sr-only">Click to delete the information and image of this work</span>
                        </NakedButton>
                    </div>
                </div>
                <div id='imageError' aria-live='polite' className='cb-font-size-small'>
                    <span className={'warning ' + warningCls}>{uploadErrorMsg}</span>
                </div>
                {/* Image portion */}
                <div id={'img_container_' + id} className={`${!is3DAndSWCourse ? `row img-container ${imgContainerCls}` : 'col-xs-12 col-md-12 flex-xs-row display-xs-flex'}
                cb-align-center align-items-center ${type === 'SW' ? '' : `${imgContainerCls} img-container`} ${!noImage ? 'fix-width' : ''}`} data-id={id}
                    onDragOver={dragOver} onDragLeave={dragLeave} onDrop={event => { onDrop(event, id) }}>
                    {<ImageCard section={createImageCardSection()} is3DAndSW={is3DAndSWCourse} />}
                </div>
                {/* Buttons below image */}
                <div className={`${!is3DAndSWCourse ? 'row' : 'col-xs-12 col-md-12 flex-xs-row display-xs-flex'} cb-border cb-border-bottom-1 cb-border-dashed cb-align-center`}>
                    {<ImageButtons section={createImageButtonSection()} is3DAndSW={is3DAndSWCourse} />}
                </div>
            </div>
         </>
    )
}
