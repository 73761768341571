import { TeamRow } from './teamRow'

export const TeamTable = props => {
  const toolKey = `team_table_${props.sectionId}`
  let teamProgress = props.teamProgress

  let lessons = () => {
    let keyIndx = 1
    return _.compact(_.map(props.moduleHolder.lessons, (lesson, index) => {
      if (lesson.isTeamLesson) {
        return (
          <th scope='col' className='text-center colgroup' key={'lesson_' + keyIndx++}>
            <span>{lesson.title}</span>
          </th>
        )
      } else {
        return null
      }
    }))
  }

  let teamRows = () => { // students are studentModel
    return _.map(teamProgress.teams, (teamModel, index) => {
      let newToolKey = toolKey + '_team_' + teamModel.jsonTeam.id
      let newProps = {
        isWe: props.isWe,
        teamModel: teamModel,
        lessons: teamProgress.lessons,
        sectionId: props.sectionId,
        toolKey: newToolKey,
        selectTeam: props.selectTeam,
      }
      return (
        <TeamRow {...newProps} key={toolKey} />
      )
    })
  }

  return (
    <table className='table data-table team-table'>
      <caption className='sr-only'>Team Progress by Assignment</caption>
      <thead>
        <tr>
          <th scope='col' className='colgroup'>Name</th>
          {lessons()}
          <th scope='col' className='text-center colgroup'>Expand</th>
        </tr>
      </thead>
      <tbody>
        {teamRows()}
      </tbody>
    </table>
  )
}