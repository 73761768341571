import { getCurrentPerson } from '../../entities/person.jsx'
import { getCourseInstanceId, getCurrentSectionId } from '../../entities/courseinstance'
import { ScoreHolder } from '../../entities/score'
import { bsliGet, bsliGetNoAuth, bsliPut } from '../../_core/util/request'
import { addBaseUrl } from '../../_core/util/URL.jsx'

const isPersonIdInArray = (arr, personId) => _.indexOf(arr, personId || getCurrentPerson().id) != -1

export function scorableStatus() {
    const statusEnum = {
        NoDraft: 1,
        DraftIn: 2,
        AttestationIncomplete: 3,
        FinalSubmitted: 4,
        Scored: 5,
        Returned: 6,
        NoResponse: 7
    }
    return statusEnum
}

// export function modelSaveAttrs() {
//     return { patch: true, type: 'PUT', wait: true }
// }

//var Scorable = Assignment.Scorable = app.Entities.Model.extend({
export class ScorableHolder {
    constructor(scorable, assignmentId, isEmpty) {
        this.scorable = scorable
        this.assignmentId = assignmentId
        this.sectionId = !isEmpty && getCurrentSectionId()
        this.enums = !isEmpty && scorableStatus()

        //console.log('ScorableHolder ctor has scorable',scorable)
    }

    async save(attrs, sectionId) {
        let url = 'sections/' + (sectionId ? sectionId : this.sectionId) + '/assignments/' + this.assignmentId + '/scorables/' + this.scorable.id
        return bsliPut(url, attrs)
    }

    isNoDraft() { return this.scorable.status == this.enums.NoDraft }
    isDraftIn() { return this.scorable.status == this.enums.DraftIn }
    isReadyToScore() { return this.scorable.status == this.enums.FinalSubmitted } //does this need to change to ScorableStatus.ReadyToScore?
    isFinalSubmitted() { return this.scorable.status == this.enums.FinalSubmitted }
    isScored() { return this.scorable.status == this.enums.Scored }
    isNoResponse() { return this.scorable.status == this.enums.NoResponse }
    isAttestationIncomplete() { return this.scorable.status == this.enums.AttestationIncomplete }
    isUnsubmitted() { return !this.isReadyToScore() && !this.isScored() && !this.isAttestationIncomplete() }
    isComplete(personId, lessons) {
        var csp = /^42/.test(getCourseInstanceId())
        let lesson = _.findWhere(lessons, { assignmentId: this.assignmentId })
        let hasRubrics = lesson ? lesson.hasRubricDimensions : false
        let complete = false

        if (!hasRubrics && this.isFinalSubmitted()) {
            complete = true;
        }
        return this.isScored() || this.isNoResponse() ||
            this.isNonParticipant(personId) ||
            (csp && this.isFinalSubmitted()) || complete
    }

    hasBeenSubmittedByPerson(personId) {
        return isPersonIdInArray(this.scorable.attestants, personId)
    }

    isNonParticipant(personId) {
        return isPersonIdInArray(this.scorable.nonParticipants, personId)
    }

    modifyScorableStatus(status, metaData) {
        var attrs = { action: 'statusUpdate', currentStatus: this.scorable.status, status: status }
        if (metaData) {
            attrs = _.extend(attrs, { metadata: metaData })
        }
        return this.save(attrs)
    }

    addAttestant() {
        var personId = getCurrentPerson().id
        var attestants = this.scorable.attestants || []
        attestants.push(personId)
        return this.save({ action: 'attestation', attestants: attestants })
    }

    addNonParticipant(personId, sectionId) {
        var nonParticipants = this.scorable.nonParticipants || []
        nonParticipants.push(personId)
        return this.save({ action: 'addNonParticipant', nonParticipants: nonParticipants }, sectionId)
    }

    removeNonParticipant(personId, sectionId) {
        var ids = _.without(this.scorable.nonParticipants, personId)
        this.sectionId = sectionId
        return this.save({ action: 'removeNonParticipant', nonParticipants: ids }, sectionId)
    }

    parse(json) {
        if (json.scores) this.scores = new ScoreHolder(json.scores)
        return json
    }
}

// this belongs somewhere else
export function downloadWR(assignmentId, enrollmentId) {
    //app.reqres.setHandler('assignment:downolad_wr', function (assignmentId, enrollmentId) {
    const url = 'sections/' + getCurrentSectionId() + '/assignments/' + assignmentId + '/enrollment/' + enrollmentId + '/downloadiwr';
    return bsliGet(url)
        .then((model) => {
            return bsliGetNoAuth(model.url)
                .catch(() => {
                    //console.log('FAIL downloadWR model.url: ' + model.url)
                    return {}
                })
        })
        .catch(() => {
            //console.log('FAIL downloadWR url: ' + url)
            return {}
        })
};


export function getAutosaveAfterSave(assignmentId) {
    var url = addBaseUrl('sections/' + getCurrentSectionId() + '/assignments/' + assignmentId +  '/autosaveaftersave');
    return bsliGet(url)
}
