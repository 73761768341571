import { getCurrentPerson } from '../../entities/person.jsx'
import { isReadOnlyMode, getWeServiceCourseInstance } from '../../entities/courseinstance'
import SupportingMaterialItemComp from './supportingMaterialItem'
import SupportingMateriaEmptyComp from './supportingMaterialEmpty'
import DPToolTip from '../../utility/tooltip'
import { addQueryToUrl, addBaseUrl } from '../../_core/util/URL'
import AddResourceModal from './addResourceModal';
import { BlackButton } from '@cb/apricot-react';
import { subject, updateSubscription } from '../../utility/service'
import { deepClone } from "../../utility/util"
import { supportingData } from "../../supportingmaterial/supportingmaterial_controller";
import { reactRender } from '../../utility/util'

const SupportingMaterialListComp = (props) => {
  const [materials, setMaterials] = useState(deepClone(props.materials));
  const materialsURL = props.materials.url;
  const toolKey = 'materials';
  const toolTip = new DPToolTip(toolKey);
  const [subscription, setSubscription] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalParams, setModalParams] = useState({
    modalId: 'adddModal'
  })

  useEffect(() => {
    setMaterials(deepClone(props.materials))
  }, [props.materials])

  useEffect(() => {
    toolTip.createTooltips();

    const sub = subject.subscribe(message => {
      if (message.refreshSupportingMaterials) {
        reloadTeacherMaterials();
      }
    });
    setSubscription(sub);
    updateSubscription('refreshSupportingMaterials', sub);

    return () => {
      toolTip.removeTooltips();
      subscription && subscription.unsubscribe();
    };
  }, []);

  const showSupportingMaterialsModal = () => {
    const url1 = addQueryToUrl(addBaseUrl(props.materials.url), 'lessonId=' + props.lessonId)
    setModalParams({
      url: addQueryToUrl(url1, 'moduleId=' + props.moduleId),
      fileReqs: {
        extensions: ['doc', 'docx', 'ppt', 'pptx', 'pdf', 'mov', 'wmv', 'mp4', 'avi', 'zip'],
        maxSize: 20971520, //20MB
        minSize: 0
      },
      modalId: 'adddModal'
    })
    setModalOpen(true)
  };

  let isTeacher = getCurrentPerson().isTeacher();

  let isReadOnly = isReadOnlyMode();
  let isWeService = false

  if (props.moduleId) {
    const currentModule = props.moduleHolder?.modules?.find(module => module.id === props.moduleId)
    isWeService = currentModule?.abbreviation === 'AP with WE Service'
  } else if (props.lessonId) {
    const weLessons = props.moduleHolder?.modules?.find(module => module?.abbreviation === 'AP with WE Service')?.lessons
    isWeService = weLessons?.length && weLessons?.some((lesson) => lesson.id === props.lessonId)
  }
  if (isWeService) {
    const weService = getWeServiceCourseInstance()
    isReadOnly = weService?.readOnly || weService?.gracePeriod
  }

  const reloadTeacherMaterials = async () => {
    const res = await supportingData(props.lessonId? props.lessonId : props.moduleId);
    setMaterials(res.teacherMaterials)
  };

  const materialList = () => {
    if (materials.length === 0) {
      return <SupportingMateriaEmptyComp />;
    } else {
      return materials.map((material, index) => {
        material.url = materialsURL;
        let key = `supporting-material_${material.id}_${index}`;
        return (
          <SupportingMaterialItemComp
            material={material}
            key={key}
            reloadTeacherMaterials={reloadTeacherMaterials}
            isReadOnly={isReadOnly}
          />
        );
      });
    }
  };

  return (
    <div className="row">
      <div className="col-md-9 col-sm-9">
        <h5 className="h5 remove-top-margin">Resources from Your Teacher</h5>
        <ul className="list-unstyled">
          {materialList()}
        </ul>
      </div>
      {isTeacher && !isReadOnly && <>
        <div className="col-md-3 col-sm-3">
          <BlackButton iconLeft icon={"plus"} small onClick={showSupportingMaterialsModal}
            data-cb-apricot='tooltip' id={toolTip.addToolTip()} data-cb-title="Add Resource">
            <span>Add</span>
            <span className="sr-only"> New Resource from Teacher</span>
          </BlackButton>
        </div>
         <div id="region-modal"></div>
         </>
      }
      <AddResourceModal
        open={modalOpen}
        {...modalParams}
        handleClose={() => {
          setModalOpen(false)
        }}
      />
    </div>
  );
};

export default SupportingMaterialListComp;
