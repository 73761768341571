import { BlackButton, Spinner } from '@cb/apricot-react'
import { PhotoGallery } from "../../../_core/gallery/photo-gallery"
import { bsliGet } from "../../../_core/util/request"
import useImages from "../entities/imageData"

const ImageGalleryTeacher = ({galleryId, sectionId, assignmentId, uploadId, isArt_SI_Images, is3DArt, title, isActivityPage}) => {
    const {
        imageHeight,
        imageWidth,
        imageId,
        imageUrl,
        imageIdeas,
        imageMaterials,
        imageProcesses,
        imageCitations,
        imageDepth,
        imageFilename
    } = useImages()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [sections, setSections] = useState([])
    const [loading, setLoading] = useState(false)

    const getArtData = async () => {
        let downloadUrl = `sections/${sectionId}/assignments/${assignmentId}/uploads/${uploadId}/downloadart`
        return await bsliGet(downloadUrl)
    }

    const downloadArtData = async () => {
        let artData = await getArtData()
        let res = []
        let i = 0
        for (const artItem of artData) {
            let item = newArtItem(artItem, i++)
            res.push(item)
        }
        setSections(res)
    }

    const downloadImage = async (event, s3url, fileName) => {
        event.preventDefault()
        event.stopPropagation()
        const downloadImageLink = document.createElement('a')
        downloadImageLink.href = s3url
        downloadImageLink.download = fileName
        await downloadImageLink.click()
        return false
    }

    const newArtItem = (artItem, key) => {
        let item = {
            key: key,
            hasImage: true,
            altered: false,
            meta: {},
            img: {bytes: "", fileName: "test" + key, url: ""},
        }
        item.meta["height"] = artItem.documentHeight
        item.meta["width"] = artItem.documentWidth
        item.meta["materials"] = artItem.documentMaterial
        item.meta["processes"] = artItem.documentProcess
        item.meta["citations"] = artItem.citation
        item.meta["ideas"] = artItem.documentIdeas
        item.meta["id"] = artItem.documentIndex
        item.img.url = artItem.downloadUrl
        item.meta["depth"] = artItem.documentDepth
        return item
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            setDataLoaded(false)
            await downloadArtData()
            setDataLoaded(true)
            setLoading(false)
        })()
    }, [])

    const getPanels = () => {
        let filtered = sections.filter(section => (
            section &&
            section.img &&
            (section.img.url || (section.img.bytes && section.img.bytes.length > 0))
        )).sort((a, b) => a.meta.id - b.meta.id)
        const lenSections = filtered.length;

        return filtered.map((section, index) => {
            let workId = imageId(section)
            let height = imageHeight(section)
            let width = imageWidth(section)
            let ideas = imageIdeas(section)
            let materials = imageMaterials(section)
            let processes = imageProcesses(section)
            let citations = imageCitations(section)
            let url = imageUrl(section)
            let thumbnail = imageUrl(section)
            let depth = imageDepth(section)
            let fileName = imageFilename(section)
            return (
                <li
                    className={`col-xs-12${lenSections > 1 || isActivityPage ? ' col-sm-6 col-md-4 col-lg-3' : '' }`}
                    data-cb-thumb={index}
                    key={"gallery_" + index}
                >
                    <a href="#" aria-haspopup="dialog">
                        <div className="cb-photo-thumb" data-cb-img={section.img.url}
                             style={{height: '153px', textAlign: 'center'}}>
                            <img
                                className="cb-lazy cb-fade-in cb-active-effect"
                                src={section.img.url}
                                data-cb-lazy={section.img.url}
                                style={{objectFit: 'scale-down', height: '100%', verticalAlign: 'middle'}}
                                alt={"ArtWork " + index}
                            />
                        </div>

                        <div className="cb-photo-thumb-info" style={{textAlign: "center"}}>
                            <h3>Work {workId}</h3>
                        </div>
                    </a>
                    <div className='row'>
                        <div className='col-md-12' style={{textAlign: 'center'}}>
                            <BlackButton
                                type="button"
                                className="js-download-file cb-btn"
                                small={true}
                                onClick={async e => await downloadImage(e, url, fileName)}
                                data-cb-title="Download Image"
                            >
                                <span
                                    className={"cb-glyph cb-batch-download cb-glyph-blue3"}
                                    id={"btn" + index}
                                >
                                  {" "} Download Image
                                </span>
                                <span className="sr-only">Click to Download Image</span>
                            </BlackButton>
                        </div>
                    </div>

                    {/* <!--MetaData description for modal --> */}
                    <div className="cb-photo-details-info" style={{color: 'black'}}>
                        {!isArt_SI_Images && <h5>Selected Works</h5>}
                        {isArt_SI_Images && <h5>Sustained Investigation</h5>}
                        <div className="display-inline" id="workHeight" style={{color: 'black'}}>
                            <h5>Height: </h5>
                            <span>{height}</span>
                        </div>
                        <div id="workWidth" style={{color: 'black'}}>
                            <h5>Width: </h5>
                            {width}
                        </div>
                        {depth &&
                            <div id="workDepth" style={{color: 'black'}}>
                                <h5>Depth: </h5>
                                {depth}
                            </div>
                        }
                        {!isArt_SI_Images &&
                            <div id="workIdeas" style={{color: 'black'}}>
                                <h5>Idea(s): </h5>
                                {ideas}
                            </div>
                        }
                        <div id="workMaterial" style={{color: 'black'}}>
                            <h5>Material(s): </h5>
                            {materials}
                        </div>
                        <div id="workProcess" style={{color: 'black'}}>
                            <h5>Process(es): </h5>
                            {processes}
                        </div>
                        {citations && <div id="workCitation" style={{color: 'black'}}>
                            <h5>Citation(s): </h5>
                            {citations}
                        </div>}
                        <div>
                        </div>
                    </div>
                </li>
            )
        })
    }
    return (
        <div id="gallery">
            {loading && <Spinner/>}
            {dataLoaded && (
                <PhotoGallery photoGalleryId={galleryId}>
                    <div
                        className="cb-photo-gallery cb-margin-left-48 cb-margin-top-48 cb-margin-bottom-48"
                        id="photoGallery"
                    >
                        <ul>{getPanels()}</ul>
                    </div>
                </PhotoGallery>
            )}
        </div>
    )
}

export default ImageGalleryTeacher
