import { ConfirmModal } from '../../../_core/views/modal'
import useImages from './imageData'
import { stateToJson } from './downloadArt'
import { downloadArt } from '../entities/artAjax'
import { validateDimensions } from '../../../utility/util'
import { isSectionArray } from '../entities/imageData'

let dirty = false
let dirtySinceSave = false
let hashChangeInProgress = false

const {
  imageUrl, imageFilename, imageFile,
  imageId, imageMeta, blankIfNullish,
  deleteSection, getClone, imageUploadError,
} = useImages()

// this may be only needed in CSP
function isMaxImage(id, index) {
  //console.log('isMaxImage id=' + id + ', index=' + index)
  return false
}

function setDirty() {
  dirty = true
  dirtySinceSave = true
}

function alterImage(clone, index, url, fileName, file, imageIndex) {
  if (index.includes('.')) index = index.substring(0, 1)
  // the rule is: don't alter state directly, clone it with map and replace
  let sectionClone = clone[index]

  imageUrl(sectionClone, blankIfNullish(url), imageIndex)
  imageFilename(sectionClone, blankIfNullish(fileName), imageIndex)
  imageFile(sectionClone, blankIfNullish(file), imageIndex)
  imageUploadError(sectionClone, '', imageIndex)
}

function alterMeta(compData, type, index, value) {
  let clone = getClone(compData)
  imageMeta(clone[index], type, value)
  return clone
}

function deleteCard(compData, index) {
  let clone = getClone(compData)
  deleteSection(clone[index])
  return clone
}

// reordering only affects the order, ids and keys, not the data itself
function reorderShuffle(compData, index1, index2) {
  let clone = getClone(compData)

  try {
    let startIndex = parseInt(index1)
    let endIndex = parseInt(index2)

    if (Array.isArray(clone[endIndex])) {
      if (!clone[endIndex][0].hasImage || !clone[endIndex][1].hasImage) {
        const startId1 = imageId(clone[startIndex][0]), startId2 = imageId(clone[startIndex][1]),
          endId1 = imageId(clone[endIndex][0]), endId2 = imageId(clone[endIndex][1]),
          startKey = clone[startIndex][0].key
        clone[startIndex][0].key = clone[endIndex][0].key
        clone[startIndex][1].key = clone[endIndex][1].key
        clone[startIndex][0].meta.id = endId1
        clone[startIndex][1].meta.id = endId2

        clone[endIndex][0].key = startKey
        clone[endIndex][1].key = startKey
        clone[endIndex][0].meta.id = startId1
        clone[endIndex][1].meta.id = startId2

        imageId(clone[startIndex][0], endId1)
        imageId(clone[startIndex][1], endId2)
        imageId(clone[endIndex][0], startId1)
        imageId(clone[endIndex][1], startId2)
      } else if (startIndex < endIndex) {
        const startKey = clone[startIndex][0].key, startId1 = imageId(clone[startIndex][0]),
          endId1 = imageId(clone[endIndex][0]), startId2 = imageId(clone[startIndex][1]),
          endId2 = imageId(clone[endIndex][1])
        clone[startIndex][0].key = clone[endIndex][0].key
        clone[startIndex][1].key = clone[endIndex][1].key
        clone[startIndex][0].meta.id = endId1
        clone[startIndex][1].meta.id = endId2

        imageId(clone[startIndex][0], endId1)
        imageId(clone[startIndex][1], endId2)
        for (let i = endIndex; i > startIndex; i--) {
          if (i === startIndex + 1) {
            clone[i][0].key = startKey
            clone[i][1].key = startKey
            clone[i][0].meta.id = startId1
            clone[i][1].meta.id = startId2
            imageId(clone[i][0], startId1)
            imageId(clone[i][1], startId2)
          } else {
            clone[i][0].key = clone[i - 1][0].key
            clone[i][1].key = clone[i - 1][1].key
            clone[i][0].meta.id = clone[i - 1][0].meta.id
            clone[i][1].meta.id = clone[i - 1][1].meta.id
            const otherId1 = imageId(clone[i - 1][0])
            const otherId2 = imageId(clone[i - 1][1])
            imageId(clone[i][0], otherId1)
            imageId(clone[i][1], otherId2)
          }
        }
      } else if (startIndex > endIndex) {
        const endKey = clone[endIndex][0].key, startId1 = imageId(clone[startIndex][0]),
          endId1 = imageId(clone[endIndex][0]), startId2 = imageId(clone[startIndex][1]),
          endId2 = imageId(clone[endIndex][1])
        clone[endIndex][0].key = clone[startIndex][0].key
        clone[endIndex][1].key = clone[startIndex][1].key
        clone[endIndex][0].meta.id = clone[startIndex][0].meta.id
        clone[endIndex][1].meta.id = clone[startIndex][1].meta.id
        imageId(clone[endIndex][0], startId1)
        imageId(clone[endIndex][1], startId2)
        for (let i = endIndex; i <= startIndex; i++) {
          if (i === startIndex) {
            clone[i][0].key = endKey
            clone[i][1].key = endKey
            clone[i][0].meta.id = endId1
            clone[i][1].meta.id = endId2
            imageId(clone[i][0], endId1)
            imageId(clone[i][1], endId2)
          } else {
            clone[i][0].key = clone[i + 1][0].key
            clone[i][1].key = clone[i + 1][1].key
            clone[i][0].meta.id = clone[i + 1][0].meta.id
            clone[i][1].meta.id = clone[i + 1][1].meta.id
            const otherId1 = imageId(clone[i + 1][0])
            const otherId2 = imageId(clone[i + 1][1])
            imageId(clone[i][0], otherId1)
            imageId(clone[i][1], otherId2)
          }
        }
      }
    } else {
      if (!clone[endIndex].hasImage) {
        const id1 = imageId(clone[startIndex]), id2 = imageId(clone[endIndex]), startKey = clone[startIndex].key
        clone[startIndex].key = clone[endIndex].key
        clone[startIndex].meta.id = id2
        clone[endIndex].key = startKey
        clone[startIndex].meta.id = id1
        imageId(clone[startIndex], id2)
        imageId(clone[endIndex], id1)
      } else if (startIndex < endIndex) {
        const startKey = clone[startIndex].key, startId = imageId(clone[startIndex]), endId = imageId(clone[endIndex])
        clone[startIndex].key = clone[endIndex].key
        clone[startIndex].meta.id = endId
        imageId(clone[startIndex], endId)
        for (let i = endIndex; i > startIndex; i--) {
          if (i === startIndex + 1) {
            clone[i].key = startKey
            clone[i].meta.id = startId
            imageId(clone[i], startId)
          } else {
            clone[i].key = clone[i - 1].key
            clone[i].meta.id = clone[i - 1].meta.id
            const otherId = imageId(clone[i - 1])
            imageId(clone[i], otherId)
          }
        }
      } else if (startIndex > endIndex) {
        const endKey = clone[endIndex].key, startId = imageId(clone[startIndex]), endId = imageId(clone[endIndex])
        clone[endIndex].key = clone[startIndex].key
        clone[endIndex].meta.id = startId
        imageId(clone[endIndex], startId)
        for (let i = endIndex; i <= startIndex; i++) {
          if (i === startIndex) {
            clone[i].key = endKey
            clone[i].meta.id = endId
            imageId(clone[i], endId)
          } else {
            clone[i].key = clone[i + 1].key
            clone[i].meta.id = clone[i + 1].meta.id
            const otherId = imageId(clone[i + 1])
            imageId(clone[i], otherId)
          }
        }
      }
    }

    clone = _.sortBy(clone, section => {
      return Array.isArray(imageId(section)) ? section[0].key : section.key
    })
  } catch (e) {
    console.error('reorderShuffle error', e)
  }
  return clone
}

function uploadErrorFileType(setBadImage) {
  //resetBad(setBadImage, id)
}

export function checkFileType(file) {
  let fileOk = false
  if (file) {
    let fileType = file.type.toLowerCase()
    fileOk = _.some(['image/png', 'image/jpg', 'image/jpeg'], goodType => {
      return goodType === fileType
    })
  }
  return fileOk
}

export function checkFileSize(file) {
  let fileOk = false
  if (file) {
    //4MB = 4000000 Bytes
    fileOk = file.size < 4000000
  }
  return fileOk
}

export function checkMinFileSize(file) {
  let fileOk = false
  if (file) {
    //5KB = 5000 Bytes
    fileOk = file.size > 5000
  }
  return fileOk
}

function hideImageEditor(id) {
  $('#' + id).addClass('hidden')
}

export function isDirty(val) {
  if (!_.isUndefined(val)) dirty = val
  return dirty
}

export function isDirtySinceSave(val) {
  if (!_.isUndefined(val)) dirtySinceSave = val
  return dirtySinceSave
}

export function isHashChangeInProgress(val) {
  if (!_.isUndefined(val)) hashChangeInProgress = val
  return hashChangeInProgress
}

// about dirty vs dirtySinceSave:
// when you press the 'Save' or 'FinalSubmit' buttons, the data goes to the S3 and a PDF is generated
// when the autosave feature fires, a PDF is not generated but the S3 is updated
// 'dirty' tracks the updates of S3 via the two buttons and autosave
// 'dirtySinceSave' tracks the updates of PDF and S3 via the two buttons
// when the data is loaded upon entry, the S3 version is loaded
function locationHashChanged() {
  return new Promise((resolve, reject) => {
    if (artUtil.isDirtySinceSave()) {
      artUtil.isHashChangeInProgress(true)
      let parms = {
        title: 'You have changes on this page that have not been saved.',
        message: 'Are you sure you want to leave without saving your work?',
        noX: true,
        yesButtonText: 'Stay on this Page',
        noButtonText: 'Leave this Page',
        onConfirmYes: () => {
          window.wrData = stateToJson(compData)
          // remember the current state of the dirty flags in case the c-tor is called
          window.wrDirty = {
            dirty: artUtil.isDirty(),
            dirtySinceSave: artUtil.isDirtySinceSave(),
          }
          artUtil.isHashChangeInProgress(false)
          resolve('stay')
        },
        onConfirmNo: () => {
          // if we leave, the changes since the last autosave will be lost
          // so when we come back, dirty should be false
          // but dirtySinceSave does not change since S3 may have data that has not
          // made it to the PDF yet, in the case autosave ran but save did not
          window.wrDirty = {
            dirty: false,
            dirtySinceSave: artUtil.isDirtySinceSave(),
          }
          artUtil.isHashChangeInProgress(false)
          resolve('leave')
        },
        modalId: 'locationChangeModal',
      }
      let modal = <ConfirmModal parms={parms}></ConfirmModal>
      reactRender(modal, 'region-modal', parms.modalId)
    } else {
      resolve('leave')
    }
  })
}

// export function updateReorder() {
//     console.log('updateReorder called')
//     for(let index=0; index<15; index++) {
//         let src = $('#thumbnail_' + index)
//         let dst = $('#reorder_' + index)
//         if (src.length > 0 && dst.length > 0) {
//             dst[0].src = src[0].src
//             dst.removeClass('hidden')
//         }
//     }
// }

export function getArtUtil() {
  return {
    isDirty: isDirty,
    isDirtySinceSave: isDirtySinceSave,
    isHashChangeInProgress: isHashChangeInProgress,
    uploadErrorFileType: uploadErrorFileType,
    checkFileType: checkFileType,
    isMaxImage: isMaxImage,
    alterImage: alterImage,
    alterMeta: alterMeta,
    reorder: reorderShuffle, //  or reorderSwap
    deleteCard: deleteCard,
    hideImageEditor: hideImageEditor,
    locationHashChanged: locationHashChanged,
  }
}

export function getFormatedData(data, isSW, is3Dart) {
  let newData = []
  if (isSW && is3Dart) {
    data?.map((item, index) => {
      let idx = getFilledItemIdx(item)
      if (idx < 0) {
        idx = 0
      }
      const dimensions = {
        height: item[idx]?.meta?.height && item[idx]?.meta?.height?.startsWith('.') ? `0${item[idx]?.meta?.height}` : item[idx]?.meta?.height,
        width: item[idx]?.meta?.width && item[idx]?.meta?.width?.startsWith('.') ? `0${item[idx]?.meta?.width}` : item[idx]?.meta?.width,
        depth: item[idx]?.meta?.depth && item[idx]?.meta?.depth?.startsWith('.') ? `0${item[idx]?.meta?.depth}` : item[idx]?.meta?.depth,
      }
      const newMeta = { ...item[idx]?.meta, ...dimensions }
      const newItem = [{ ...item[0], meta: { ...newMeta, id: `${index + 1}.1` } }, {
        ...item[1],
        meta: { ...newMeta, id: `${index + 1}.2` },
      }]
      newData.push(newItem)
    })
  } else {
    data?.map(item => {
      const dimensions = is3Dart ? {
        height: item?.meta?.height && item?.meta?.height?.startsWith('.') ? `0${item?.meta?.height}` : item?.meta?.height,
        width: item?.meta?.width && item?.meta?.width?.startsWith('.') ? `0${item?.meta?.width}` : item?.meta?.width,
        depth: item?.meta?.depth && item?.meta?.depth?.startsWith('.') ? `0${item?.meta?.depth}` : item?.meta?.depth,
      } : {
        height: item?.meta?.height && item?.meta?.height?.startsWith('.') ? `0${item?.meta?.height}` : item?.meta?.height,
        width: item?.meta?.width && item?.meta?.width?.startsWith('.') ? `0${item?.meta?.width}` : item?.meta?.width,
      }
      const newMeta = { ...item?.meta, ...dimensions }
      const newItem = { ...item, meta: newMeta }
      newData.push(newItem)
    })
  }
  return newData
}

function getFilledItemIdx(items) {
  return items?.findIndex(item => item?.meta?.height || item?.meta?.width || item?.meta?.depth || item?.meta?.materials || item?.meta?.processes || item?.meta?.citations || item?.meta?.ideas)
}

export async function checkAllImages(data, params) {
  // Retrieve artifactPaths for images from bsli
  const { assignment, upload } = params.finalSubmitInfo
  const currentArtData = await downloadArt(assignment.id, upload.id ? upload.id : uploadId)
  const artifactPaths = currentArtData.reduce((acc, curr) => {
    const { documentIndex, artifactPath } = curr
    return [...acc, { path: artifactPath, index: documentIndex }]
  }, [])
  const badImagesIndices = []

  for (let i = 0; i < artifactPaths.length; i++) {
    const { path, index } = artifactPaths[i]
    try {
      if (is3Dart && isSW) {
        const currentImage = data.find(d => d[0].meta.id === index || d[1].meta.id === index)
        const url = currentImage.find(c => c.meta.id === index).img.url
        if (url.includes('data:image')) {
        } else {
          const firstIndex = url.indexOf('.com') + 5, secondIndex = url.indexOf('?')
          const currentPath = url.substring(firstIndex, secondIndex)
          const newUrl = url.replace(currentPath, path)
          const resp = await fetch(newUrl)
          if (Number(resp.headers.get('Content-Length')) === 0) {
            badImagesIndices.push(index)
          }
        }

      } else {
        const currentImage = data.find(d => d.meta.id === index)
        const url = currentImage.img.url
        if (url.includes('data:image')) {
        } else {
          const firstIndex = url.indexOf('.com') + 5, secondIndex = url.indexOf('?')
          const currentPath = url.substring(firstIndex, secondIndex)
          const newUrl = url.replace(currentPath, path)
          const resp = await fetch(newUrl)
          if (Number(resp.headers.get('Content-Length')) === 0) {
            badImagesIndices.push(index)
          }
        }
      }
    } catch (err) {
      console.error(`Error: ${err.message}`)
    }
  }

  //setHasSaveProblem(badImagesIndices)
  return badImagesIndices
}

export function getOverLimitWork(data, isSW, is3Dart, params) {
  let overLimitItems = []
  let indexArray = []

  if (isSW && is3Dart) {
    overLimitItems = data?.filter(item =>
      item[0]?.meta?.materials?.length > 100
      || item[0]?.meta?.processes?.length > 100
      || item[0]?.meta?.citations?.length > 100
      || item[0]?.meta?.ideas?.length > 100
      || item[0]?.meta?.height && !validateDimensions(item[0]?.meta?.height, params.type, false)
      || item[0]?.meta?.width && !validateDimensions(item[0]?.meta?.width, params.type, false)
      || item[0]?.meta?.depth && !validateDimensions(item[0]?.meta?.depth, params.type, false),
    )
    overLimitItems.map(item => indexArray.push(item[0]?.meta?.id?.split('.')[0]))
  } else {
    overLimitItems = data?.filter(item =>
      item?.meta?.materials?.length > 100
      || item?.meta?.processes?.length > 100
      || item?.meta?.citations?.length > 100
      || item?.meta?.ideas?.length > 100
      || item?.meta?.height && !validateDimensions(item?.meta?.height, params.type, false)
      || item?.meta?.width && !validateDimensions(item?.meta?.width, params.type, false)
      || item?.meta?.depth && !validateDimensions(item?.meta?.depth, params.type, false),
    )
    overLimitItems.map(item => indexArray.push(item?.meta?.id))
  }

  if (overLimitItems.length === 0) {
    return false
  }

  return indexArray.join(', ')
}

export function hasUploadedFile(sections, fileName) {
  let fileFound = false
  // Check if multi-image sections or not
  if (sections.some(s => isSectionArray(s))) {
    sections.forEach(section => {
      section.forEach(image => {
        if (image.img.fileName === fileName) fileFound = true
      })
    })
  } else {
    const result = sections.some(s => s.img.fileName === fileName)
    if (result) fileFound = true
  }
  return fileFound
}

export function buildImageError(fileName, course, lesson) {
  return `Error: You have already uploaded ${fileName} for ${course} ${lesson === 'SI' ? 'Sustained Investigation' : 'Selected Works'}. You may not upload the same file again.`
}
