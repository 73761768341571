import React, { Fragment } from 'react';

const CMSDocsComp = (props) => {
  
  let hasData = props.parms
  if (hasData) {
    let showIt = props.parms.files.length > 0
    let files = props.parms.files.map((file, index) => {
      return (
        <li key={'file_' + index}>
          <span className="icon-svg" aria-hidden="true"></span>
          <a href={file.File} target="_blank" title="Download File: {file.title}">{file.title}</a>
        </li>
      )
    })

    return (
      <Fragment>
        {showIt &&
          <Fragment>
            <hr />
            <h5>{props.parms.header}</h5>
            <div id="text-region" dangerouslySetInnerHTML={{ __html: props.parms.blurb }} />
            <ul className="list-unstyled">
              {files}
            </ul>
          </Fragment>
        }
      </Fragment>
    )

  } else {
    return (
      <div></div>
    )
  }
}


export default CMSDocsComp;
