import useImages, {isSectionArray}                              from './imageData'
import { deepClone }                                            from '../../../utility/util'

const {
    itemToSection,
    imageFile,
    getEmptyData,
    imageDocumentId,
    imageUploadId
} = useImages()

export function parse(data,type, is3D = false) {
    if (data) {
        let studentUpload
        // if the data is a string, it came from the backend
        // if its an object, it was stored in window object
        try {
            if (_.isArray(data)) {
                studentUpload = data
            } else {
                studentUpload = []
            }
            return jsonToState(studentUpload,type, is3D)
        } catch (e) { 
            console.log('parse had error',e)
            return getEmptyData(type, is3D)
        }
    } else {
        return getEmptyData(type, is3D)
    }
}

// these are twin converters, json <==> state
// json is the backend structure returned by downloadart REST call, state is react UI structure
// state and json are both arrays whose elements are either called session or item
// in the state they are called section, in the json they are called item

function jsonToState(json,type, is3D) {
    let state = getEmptyData(type, is3D)
    _.each(json, item => {
        itemToSection(item,state)
    })
    return state
}

export function stateToJson(state) {
    let json = state.map(s => {
        if (isSectionArray(s)) {
            let obj1 = { meta: deepClone(s[0].meta), img: deepClone(s[0].img), file: imageFile(s[0]), uploadSubDocumentId: imageDocumentId(s[0]), uploadId: imageUploadId(s[0]) }
            let obj2 = { meta: deepClone(s[1].meta), img: deepClone(s[1].img), file: imageFile(s[1]), uploadSubDocumentId: imageDocumentId(s[1]), uploadId: imageUploadId(s[1]) }
            return [obj1, obj2]
        }
        else return {
            meta:                   deepClone(s.meta),
            img:                    deepClone(s.img),
            file:                   imageFile(s),
            uploadSubDocumentId:    imageDocumentId(s),
            uploadId:               imageUploadId(s)
        }
    })
    return json
}
