import React, {Fragment} from 'react';
import {getCourseInstanceId, getCourseParameter, isArt, isCSP} from '../../entities/courseinstance'
import { getButtonText } from '../entities/progress'

export default class SectionColumnComp extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    //console.log('SectionColumnComp props', this.props)
    let helpers = this.serializeData(this.props.parms)
    //console.log('SectionColumnComp helpers',helpers)
    //this.toolTip.removeTooltips()

    let text
    if (helpers.csp || helpers.art) {
      if (helpers.scored) {
        text = 'Scored'
      } else {
        text = helpers.confirmScorables ? 'Submitted' : 'Scored'
      }
    } else {
      text = helpers.scored ? 'Scored' : 'Submitted'
    }

    // isDone was removed as we never want to display the code below
    return (
      <div>
        {(helpers.csp || helpers.art) &&
          <Fragment>
            {helpers.examIntentEnabled &&
              <span style={{display: 'block'}}>{helpers.totalScoredStudents} of {helpers.totalStudentsWithExamIntentYes}</span>
            }
            {!helpers.examIntentEnabled &&
              <span style={{display: 'block'}}>{helpers.totalScoredStudents} of {helpers.totalStudents}</span>
            }
          </Fragment>
        }
        {!(helpers.csp || helpers.art) &&
          <Fragment>
            {helpers.apNumberEnabled &&
              <span style={{display: 'block'}}>{helpers.totalScoredStudentsWithExamIntentYes} of {helpers.totalStudentsWithExamIntentYes}</span>
            }
            {!helpers.apNumberEnabled &&
              <span style={{display: 'block'}}>{helpers.totalScoredStudents} of {helpers.totalStudents}</span>
            }
          </Fragment>
        }
        <span className="sr-only"> Students </span>
        <span>{' ' + text + ' '}</span>
        <span className="sr-only">for assignment {helpers.assignmentTitle}</span>
      </div>
    )
  }

  serializeData(parms) {
    let lesson = parms.lesson
    let options = this.childViewOptions(parms)
    //console.log('SectionColumnComp options',options)
    var { totalStudents, sectionName, totalScoredStudents, totalScoredStudentsWithExamIntentYes,
      totalStudentsWithExamIntentYes, apNumberEnabled, assignmentTitle } = options;
    let csp = isCSP();
    let art = isArt();
    let examIntentEnabled = getCourseParameter('examintentEnabled');
    let scored = lesson.assignmentTypeId === 3;
    // if (totalStudents !== 0 && totalStudents == lesson.totalScoredStudents) {
    //   console.log('isDone')
    // }

    return {
      // original code tried to look up the 'totalScoredStudents' from the model instead of use the above
      // value from options. problem is that the column model is a lesson and does not have this property
      // which is probably the reason it was stuck in the options give us by the row code
      // the result is 'isDone' was always false and icons never appeared in the main row, they do appear
      // in the sub-row
      // I suspect the users don't want to see icons in the main row, only the sub-row
      //isDone: false,//totalStudents !== 0 && totalStudents == totalScoredStudents,
      sectionName,
      totalStudents,
      totalScoredStudents: totalScoredStudents || 0,
      totalScoredStudentsWithExamIntentYes,
      totalStudentsWithExamIntentYes,
      confirmScorables: getCourseParameter('confirmScorables'),
      label: getButtonText(lesson.assignmentId, 'progressActionType'),
      csp,
      art,
      apNumberEnabled,
      examIntentEnabled,
      scored,
      assignmentTitle
    }
  }

  childViewOptions(parms) {
    let assignment = parms.lesson
    let index = parms.index
    let jsonSection = parms.jsonSection

    let status = _.findWhere(jsonSection.coordinatorStatusList, { assignmentId: parseInt(assignment.assignmentId) })
    let totalScoredStudentsWithExamIntentYes = status != null ? status.totalScoredStudentsWithExamIntentYes : 0;
    let totalStudentsWithExamIntentYes = jsonSection.studentsWithExamIntentYes;
    let apNumberEnabled = getCourseParameter('apnumberEnabled');
    let descr = jsonSection.descr
    let subTitle = descr ? descr.trim() + ': ' : ''
    let sectionName = jsonSection.sectionName

    return {
      totalStudents: jsonSection.totalStudents,
      sectionName: subTitle + sectionName,
      totalScoredStudents: status && status.totalScoredStudents,
      totalScoredStudentsWithExamIntentYes: totalScoredStudentsWithExamIntentYes,
      totalStudentsWithExamIntentYes: totalStudentsWithExamIntentYes,
      apNumberEnabled: apNumberEnabled,
      assignmentTitle: assignment.title
    }
  }
}