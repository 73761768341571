const areEqual = (prevProps, nextProps) => {
  return nextProps.is3DAndSW ? (
    prevProps.section.hasImage1 === nextProps.section.hasImage1 && prevProps.section.hasImage2 === nextProps.section.hasImage2
    && prevProps.section.sectionError3DMsg1 === nextProps.section.sectionError3DMsg1 && prevProps.section.sectionError3DMsg2 === nextProps.section.sectionError3DMsg2
    && prevProps.section.url1 === nextProps.section.url1 && prevProps.section.url2 === nextProps.section.url2
    && prevProps.section.fileName1 === nextProps.section.fileName1 && prevProps.section.fileName2 === nextProps.section.fileName2
  ) : (
    prevProps.section.hasImage === nextProps.section.hasImage && prevProps.section.url === nextProps.section.url &&
    prevProps.section.fileName === nextProps.section.fileName
  )
}

export const ImageCard = React.memo(({ section, is3DAndSW }) => {
  if (is3DAndSW) {
    const { hasImage1, hasImage2, newIndex1, newIndex2, img1ContainerCls, img2ContainerCls, url1, url2, id1, id2,
      imgId1, imgId2, onError, setModalOpenImageOne, setModalOpenImageTwo, fileName1, fileName2 } = section

    useEffect(() => {
      const imgTag1 = document.getElementById(imgId1), imgTag2 = document.getElementById(imgId2)
      if (section.sectionError3DMsg1) {
        imgTag1.removeAttribute('src')
        imgTag1.removeAttribute('data-filename')
        if (url1 && fileName1) {
          imgTag1.setAttribute('src', url1)
          imgTag1.setAttribute('data-filename', fileName1)
        }
      }
      if (section.sectionError3DMsg2) {
        imgTag2.removeAttribute('src')
        imgTag2.removeAttribute('data-filename')
        if (url2 && fileName2) {
          imgTag2.setAttribute('src', url2)
          imgTag2.setAttribute('data-filename', fileName2)
        }
      }
    }, [section.sectionError3DMsg1, section.sectionError3DMsg2])

    return (
      <>
        {hasImage1 ?
          <div className={`col-md-6 imageFilled-${newIndex1} ${img1ContainerCls} img-container`} id={`imageCard-${newIndex1}`}>
            <img id={imgId1} className='cb-img-fluid thumbnail drop-image'
                 src={url1} onClick={() => setModalOpenImageOne(true)} data-filename={fileName1}
                 data-index={newIndex1} crossOrigin='Anonymous' />
          </div> :
          <div className={`col-md-6 display-flex flex-column justify-content-center ${img1ContainerCls} img-container`} id={`noImageCard-${newIndex1}`}>
            <span id={`imagesText-${newIndex1}`} className='align-self-center'>Drop Images Here</span>
            <div id={`uploadIcon-${newIndex1}`} className="cb-glyph cb-upload" />
            <img id={imgId1} className='drop-image' data-index={newIndex1}
                 onError={event => onError(id1)}/>
          </div>
        }
        {hasImage2 ?
          <div className={`col-md-6 imageFilled-${newIndex2} ${img2ContainerCls} img-container`} id={`imageCard-${newIndex2}`}>
            <img id={imgId2} className='cb-img-fluid thumbnail drop-image'
                 src={url2} onClick={() => setModalOpenImageTwo(true)} data-filename={fileName2}
                 data-index={newIndex2} crossOrigin='Anonymous' />
          </div>
          :
          <div className={`col-md-6 display-flex flex-column justify-content-center ${img2ContainerCls} img-container`} id={`noImageCard-${newIndex2}`}>
            <span id={`imagesText-${newIndex2}`} className='align-self-center'>Drop Images Here</span>
            <div id={`uploadIcon-${newIndex2}`} className="cb-glyph cb-upload" />
            <img id={imgId2} className='drop-image' data-index={newIndex2}
                 onError={event => onError(id2)}/>
          </div>
        }
      </>
    )
  } else {
    const { hasImage, setModalOpen, showFullScreenBtnCls, hideFullScreenBtnCls, index, onError, fileName, url, imgId, id } = section
    return (
      <>
        { hasImage ?
          <>
            <img id={'thumbnail_' + id} className={'cb-img-fluid thumbnail ' + showFullScreenBtnCls}
                 src={url} onClick={() => setModalOpen(true)}
                 data-index={index} crossOrigin='Anonymous' />
            <div className={'col-md-12 ' + hideFullScreenBtnCls}>
              <img id={imgId} className='drop-image'
                   crossOrigin='Anonymous'
                   data-index={index} data-filename={fileName}
                   src={url} onError={event => onError(id)} />
            </div>
          </>
          :
          <div className='col-md-12 cb-margin-top-48 cb-margin-bottom-48'>
            <div>Drop Images Here</div>
            <div id='uploadIcon' className="cb-glyph cb-upload" />
            <img id={imgId} className='drop-image hidden' data-index={index}
                 onError={event => onError(id)}/>
          </div>
        }
      </>
    )
  }
}, areEqual)