import {getSection}		from '../../entities/courseinstance'
import { bsliPut }		from '../../_core/util/request'
import { getCurrentPerson } from '../../entities/person.jsx'

export class Enrollment {
	// an enrollment from a section is a student, has the same properties
	constructor(enrollment) {
		this.enrollment = enrollment
		this.Status = { EnrollmentRequested: 1, AwaitingConfirmation: 3, Confirmed: 4, DropRequested: 5, Dropped: 6 }
	}

	title() {
		return null
	}

	prefFullName() {
		const isTeacher = getCurrentPerson().isTeacher()
		const isCoordinator = getCurrentPerson().isCoordinator()
		return (isTeacher || isCoordinator)
			? {
				fullName: `${this.enrollment.firstName} ${this.enrollment.lastName}`,
				prefFirstName: this.enrollment.prefFirstName,
			}
		 	: { fullName: `${this.enrollment.prefFirstName || this.enrollment.firstName}` + ' ' + this.enrollment.lastName }
	}

	fullName() {
		const isTeacher = getCurrentPerson().isTeacher()
		const isCoordinator = getCurrentPerson().isCoordinator()
		return (isTeacher || isCoordinator)
			? `${this.enrollment.firstName} ${this.enrollment.lastName}`
		 	: `${this.enrollment.prefFirstName || this.enrollment.firstName}` + ' ' + this.enrollment.lastName
	}

	save() {
		let url = 'enrollments/' + this.enrollment.id
		let data = {
			status: this.Status.Confirmed
		}
		bsliPut(url,data)
	}

	savePermissionToReuse(permissionToReuse) {
		let url = 'enrollments/' + this.enrollment.id
		let data = {
			permissionToReuse: permissionToReuse
		}
		//console.log('savePermissionToReuse setting permissionToReuse ' + permissionToReuse)
		this.enrollment.permissionToReuse = permissionToReuse
		return bsliPut(url,data)
	}
}

var memoizeFunc = _.memoize(function(sectionId) {
    var section = getSection(sectionId)
    return new Enrollment(section.enrollment)
  })

export function enrollmentForSection(sectionId) {
	return memoizeFunc(sectionId)
}
