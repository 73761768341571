import React, { Fragment, useState, useEffect } from 'react';
import { NakedButton } from '@cb/apricot-react'

import { AssignmentComp, getAlertReason } from '../../assignment/views/assignment'
import { getMenuParms } from '../../assignment/entities/upload'
import { getCurrentPerson } from '../../entities/person.jsx'

// used by teacherLayout
const StudentStatusForTeacherComp = (props) => {

    const [assignmentParms, setAssignmentParms] = useState(false);
    const templateHelpers = () => {
        let status = props.parms.status
        let assignment = props.parms.assignment
        return {
            name: status.titleName(),
            teamNames: status.teamNames(),
            fullName: status.firstName && status.lastName ? `${status.lastName}, ${status.firstName}` : '',
            prefFirstName: status.prefFirstName,
            attestationIncomplete: status.submissionStatus === 'AttestationIncomplete',
            submissionDate: assignment.isDocument() && status.submittedAt &&
                moment(new Date(status.submittedAt)).format('MM/DD')
        }
    }

    const assignmentClicked = async () => {
        let assignment = props.parms.assignment
        let personId = props.parms.status.getPersonId()
        if (assignmentParms) {
            setAssignmentParms(false);
        } else {
            try {
                let menuParms = await getMenuParms(assignment, personId)
                let alertReason = await getAlertReason(menuParms)
                setAssignmentParms({
                    assignment: assignment,
                    personId: personId,
                    menuParms: menuParms,
                    alertReason: alertReason,
                    isTeacherView: true
                });
            } catch (e) {
                console.error('assignmentClicked had error')
            }
        }
    }
    
    let helpers = templateHelpers()
    let cls, text
    if (assignmentParms) {
        cls = ' add-top-margin-2x'
        text = 'Hide'
    } else {
        cls = ''
        text = 'View'
    }
    const isTeacher = getCurrentPerson().isTeacher()
    const usePreferredName = helpers.prefFirstName && helpers.prefFirstName !== 'null'

    return (
        <Fragment>
            <div className="row row-stacked">
                <div className="col-md-5 col-sm-5 col-xs-7">
                    <h6 className="remove-top-margin">{isTeacher && helpers.fullName ? helpers.fullName : helpers.name}</h6>{isTeacher && usePreferredName ? `(Preferred Name: ${helpers.prefFirstName})` : ''}
                    {helpers.teamNames}
                </div>
                <div className="hidden-xs col-md-3 col-sm-3 col-xs-3">
                    <NakedButton type="button" aria-expanded="false" onClick={assignmentClicked}
                        className='cb-btn-blue text-right js-view-assignment' small='true'>                       
                        {`${text} Details`}
                        <span className="sr-only">of assignment for {helpers.name}</span>
                    </NakedButton>
                </div>
                <div className="hidden-xs col-md-4 col-sm-4 col-xs-12 text-right">
                    {helpers.attestationIncomplete &&
                        <strong className="cb-red1-color">
                            Submit Final is partially complete. All team members must confirm submission.
                        </strong>
                    }
                    {helpers.submissionDate &&
                        <strong className="cb-green1-color">
                            <span className="cb-glyph cb-check" aria-hidden="true" />
                            <span style={{ fontWeight: '900' }} >
                                {`Final submitted ${helpers.submissionDate}`}
                            </span>
                        </strong>
                    }
                </div>
                <div className="visible-xs col-xs-5 text-right">
                    <div>
                        {helpers.attestationIncomplete &&
                            <strong className="cb-red1-color">
                                Submit Final is partially complete. All team members must confirm submission.
                            </strong>
                        }
                        {helpers.submissionDate &&
                            <strong className="cb-green1-color">
                                <span className="cb-glyph cb-check" aria-hidden="true" />
                                <span style={{ fontWeight: '900' }} aria-hidden="true">
                                    {`Final submitted ${helpers.submissionDate}`}
                                </span>
                            </strong>
                        }
                    </div>
                    <button type="button" aria-expanded="false" onClick={assignmentClicked}
                        className='cb-btn cb-btn-sm cb-btn-naked cb-btn-blue text-right js-view-assignment'>
                        {`${text} Details`}
                        <span className="sr-only">of assignment for {helpers.name}</span>
                    </button>
                </div>
            </div>
            <div className="row" key={'details_' + props.parms.status.id}>
                <div className={`col-md-12 js-region-assignment${cls}`}>
                    {assignmentParms &&
                        <AssignmentComp parms={assignmentParms} showGallery={props.parms.showGallery} title={props.parms.title}></AssignmentComp>
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default StudentStatusForTeacherComp;
