import {getCurrentPerson}         from '../entities/person.jsx'
import {getModuleHolder}          from '../entities/modules'
import {cmsDocuments}             from '../content/static_content'
import {getCurrentSectionId}      from '../entities/courseinstance'
import { cmsPublishable }         from '../content/static_content'
import {bsliGet}                  from '../_core/util/request'

function getTeacherUploadsUrl(type, id) {
  let url = 'sections/' + getCurrentSectionId() + '/teacherUploads?'
  if (type==='module') {
    url = url + 'moduleId=' + id
  } else {
    url = url + 'lessonId=' + id
  }
  return url
}
function getPMUrl(subtaskId) {
  var sectionId = getCurrentSectionId()
  let url = 'section/' + sectionId + '/subtask/' + subtaskId + '/documentPublishedDate'
  return url
}

export async function supportingData(subtaskId, existingModuleHolder = null) {
  //console.log('supportingData subtaskId ' + subtaskId)
  var moduleHolder = existingModuleHolder ? existingModuleHolder : await getModuleHolder()
  var isLesson = window.location.href.includes('module')
    ? false : window.location.href.includes('lesson')
    ? true : moduleHolder.getModuleByLessonId(subtaskId)

  let url = getTeacherUploadsUrl(isLesson ? 'lesson' : 'module', subtaskId)
  var teacherMaterials = await bsliGet(url)
  teacherMaterials.url = 'sections/' + getCurrentSectionId() + '/teacherUploads'
  var roles = getCurrentPerson().roles;

  //var cmsProperties = await app.request('cms:documents:publishable', subtaskId, roles)
  var cmsProperties = await cmsPublishable(subtaskId, roles)
  //console.log('cmsProperties', cmsProperties)

  let publishableMaterial
  if (cmsProperties) {
    let urlPM = getPMUrl(subtaskId)
    try {
      publishableMaterial = await bsliGet(urlPM)
      .catch(() => {
        console.log('supportingData GET failed on subtaskId ' + subtaskId)
      })

      publishableMaterial.urlPM = urlPM
      //console.log('supportingData GET returned', publishableMaterial)
    } catch (e) {
      publishableMaterial = false//{ isAvailable: false, subtaskId: subtaskId, urlPM: urlPM }
      //console.log('supportingData GET threw and error')
    }

    publishableMaterial.cmsProperties = cmsProperties
    publishableMaterial.taskTitle = moduleHolder.getModuleById(subtaskId).title
    return {
      teacherMaterials: teacherMaterials,
      publishableMaterial: publishableMaterial
    }
  } else {
    return {
      teacherMaterials: teacherMaterials,
      publishableMaterial: false
    }
  }
}

export async function cmsMaterials(id) {
  //console.log('cmsMaterials id=' + id)
  //let func = async function (id) {
    var docs = await cmsDocuments();
    var roles = getCurrentPerson().roles;
    //console.log('cmsMaterials roles',roles)
    //console.log('cmsMaterials id ' + id)

    let docsForAssignment = _.filter(docs, doc => {
      return doc.LocationId && _.contains(doc.LocationId.split(', '), id)
    })
    //console.log('cmsMaterials docsForAssignment',docsForAssignment)
    //console.log('cmsMaterials locations',_.pluck(docs,'LocationId'))

    if (_.isEmpty(docsForAssignment)) {
      //console.log('there were no docs for id ' + id)
      return null
    }

    var filtered = _.filter(docsForAssignment, doc => {
      let publishable = doc.publishable===1 || doc.publishable==='1'
      return doc['Target Audience'] && 
        _.intersection(doc['Target Audience'].split(', '), roles).length &&
        !publishable
    });

    //console.log('cmsMaterials filtered',filtered)
    //partition is two arrays: files, blurb
    var partition = filtered && _.partition(filtered, doc => {
      return !(doc.File===null || doc.File.length===0)
    }); 
    //console.log('cmsMaterials partition',partition)
    
    var intro = partition[1][0];
    if (partition[0].length && intro) { //if no files, don't show anything
      //console.log('cmsMaterials showing length ' + partition[0].length)
      //console.log('intro',intro)
      return {
        files: partition[0],
        header: intro && intro['Documents Header'],
        blurb: intro && intro.Body
      }
    } else {
      //console.log('there were no cmsMaterials for id ' + id)
      return null
    }
  //}
  //func(id)
}
