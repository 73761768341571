import { BlackButton, Checkbox, Input, Select, Spinner } from "@cb/apricot-react"
import { bsliGet, bsliPut } from "../../_core/util/request"
import { deepClone } from "../../utility/util"
import { CBForm } from "@cb/apricot";

const TSP = () => {
    const [state, setState] = useState({
        courseInstanceId: "",
        modules: [],
        headers: [],
        assignments: [],
        filter: "",
        extractedAccessData: [],
        filteredData: [],
        flag: false,
        alertMsg: "",
        showAlert: false,
        isRendering: false,
        sortColNum: 0,
        sortAsc: true
    })
    const [tableLoading, setTableLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [props, setProps] = useState(null)

    const showAlert = (showAlert, alertMsg) => {
        if (showAlert) {
            return <div className="alert alert-danger">
                <span>{alertMsg}</span>
            </div>
        }
    }

    const courseLabelValues = () => {
        let res = []
        res = [{ value: "-1", label: "Select Course" }].concat(props.courses.map(course => ({ value: course.id, label: course.title })))
        return res
    }

    const getScoringAccessDataHandler = async (courseInstanceId) => {
        setState({ extractedAccessData: [] })
        setTableLoading(true)
        let newState, filteredData
        try {
            newState = await getScoringAccessData(courseInstanceId)
        } catch (e) {
            console.error(e)
            console.error("TSP getScoringAccessData failed on courseInstanceId=" + courseInstanceId)
        }
        filteredData = filterData(newState.extractedAccessData)
        setState({ ...newState, ...filteredData })
        setTableLoading(false)
    }

    const getScoringAccessData = async (courseInstanceId) => {
        let newState = { filter: "", courseInstanceId: courseInstanceId }
        let modules = []
        let scoringAccessData = []
        let filterText = document.getElementById("filter")
        if (filterText) {
            filterText.value = ""
        }
        clearAlert()
        if (courseInstanceId === "" || courseInstanceId === "-1") {
            newState = _.extend(newState,
              {
                  courseInstanceId: "", scoringAccessData: [], modules: [], headers: [],
                  assignments: [], filter: "", extractedAccessData: [], filteredData: [],
                  flag: false, alertMsg: "", showAlert: false
              })
        } else {
            try {
                modules = await bsliGet("/course-instances/" + courseInstanceId + "/modules")
                let assignments = assignmensValues(modules)
                setState({ assignments })
                let headers = getCourseHeaders(assignments)
                let url = "admin/scoring-access-descriptors?courseInstanceId=" + courseInstanceId

                newState = _.extend(newState, {
                    headers: headers,
                    courseInstanceId: courseInstanceId,
                    modules: modules,
                    assignments: assignments
                })
                try {
                    scoringAccessData = await bsliGet(url)
                    newState = { ...newState, filter: "", extractedAccessData: extractAllAccessData(scoringAccessData, assignments) }
                } catch (error) {
                    displayAlert("Error: There was a problem retrieving this data. Please try again.")
                    newState = { ...newState, filter: "", extractedAccessData: [] }
                }
            } catch (error) {
                displayAlert("Error: There was a problem retrieving this data. Please try again.")
                newState = { ...newState, filter: "", extractedAccessData: [] }
            }
        }
        return newState
    }

    const showTable = (extractedAccessData) => {
        if (!extractedAccessData || extractedAccessData.length === 0) {
            return ""
        }
        let headers = showHeaders()
        let sortIndicators = showCourseSortCoulmnIndicators()
        let rows = displayRows()
        return <table id="tsp" className="cb-table cb-table-striped cb-spacerv-top-8  cb-spacerh-left-8">
            <thead>
            {headers}
            {sortIndicators}
            </thead>
            <tbody>{rows}</tbody>
        </table>
    }

    const showHeaders = () => {
        return <tr>{courseColumnsHeaders()}</tr>
    }

    const courseColumnsHeaders = () => {
        let assignments = state.assignments
        if (assignments && assignments.length > 0) {
            let headers = []
            headers.push("Name", "Username", "AI Code", "Turn Off Scoring")
            assignments.forEach((a) => {
                headers.push("Scoring Status " + a.abbreviation, "Manually Approve " + a.abbreviation)
            })
            headers.push("Email")
            return headers.map((h, i) => <th scope="col" key={i}>
                <div className="display-flex justify-content-center cb-align-center"><span className="cb-padding-4">{h}</span>
                </div>
            </th>)
        }
    }

    const showCourseSortCoulmnIndicators = () => {
        return <tr>{courseSortCoulmnIndicators()}</tr>
    }

    const courseSortCoulmnIndicators = () => {
        let assignments = state.assignments
        let data = state.filteredData
        if (assignments && assignments.length > 0 && data && data.length > 1) {
            let headers = []
            headers.push("Name", "Username", "AI Code", "Turn Off Scoring")
            assignments.forEach((a) => {
                headers.push("Scoring Status " + a.abbreviation, "Manually Approve " + a.abbreviation)
            })
            headers.push("Email")
            return headers.map((h, i) => {
                if (state.sortColNum !== i) {
                    return <td scope="col" className="cb-table-sort-joined" aria-sort="descending" key={"sortedid" + i}>
                        <a href="#" onClick={(e) => sortTable(e, i, h)} role="button">
                            <span className="cb-glyph cb-sort" aria-hidden="true" />
                            <span className="sr-only"> sort by {h} </span>
                        </a>
                    </td>
                } else if (state.sortAsc === true) {
                    return <td scope="col" className="cb-table-sort-joined" aria-sort="descending" key={"sortedid" + i}>
                        <a href="#" onClick={e => sortTable(e, i, h)} role="button">
                            <span className="cb-glyph cb-sort-asc" aria-hidden="true" />
                            <span className="sr-only"> sorted by {h} asc </span>
                        </a>
                    </td>
                } else {
                    return <td scope="col" className="cb-table-sort-joined" aria-sort="descending" key={"sortedid" + i}>
                        <a href="#" onClick={e => sortTable(e, i, h)} role="button">
                            <span className="cb-glyph cb-sort-desc" aria-hidden="true" />
                            <span className="sr-only"> sorted by {h} desc  </span>
                        </a>
                    </td>
                }
            })
        } else return []
    }

    const getCourseHeaders = (assignments) => {
        let headers = []
        if (assignments && assignments.length > 0) {
            headers.push("Name", "Username", "AI Code", "Turn Off Scoring")
            assignments.forEach((a) => {
                headers.push("Scoring Status " + a.abbreviation, "Manually Approve " + a.abbreviation)
            })
            headers.push("Email")
            return headers
        }
        return headers
    }

    const handleScoringDisabled = async (c, e, rownum, colnum) => {
        let recordId = state.filteredData[rownum][0][0].value
        let url = "/admin/scoring-access-descriptors/" + recordId
        let load = { scoringDisabled: c }
        clearAlert()
        try {
            setTableLoading(true)
            await bsliPut(url, load)
            setState(prev => {
                let copy = deepClone(prev)

                copy.filteredData[rownum][colnum][0].value = c
                let record = copy.extractedAccessData.find(r => r[0][0].value === recordId)
                if (record) {
                    record[colnum][0].value = !!c;
                }

                return {
                    ...prev,
                    filteredData: copy.filteredData,
                    extractedAccessData: copy.extractedAccessData
                }
            })
            setTableLoading(false)
        } catch (error) {
            setTableLoading(false)
            displayAlert("Error happenned please try again.")
        }
    }

    const handleManuallyApprove = async (c, e, rownum, colnum) => {
        let command = (c === true) ? "AddOverriddenAssignmentId" : "RemoveOverriddenAssignmentId"
        let recordId = state.filteredData[rownum][0][0].value
        let assignmentId = state.filteredData[rownum][colnum][2].value
        let url = "/admin/scoring-access-descriptors/" + recordId
        let load = { command: command, assignmentId: assignmentId }
        clearAlert()
        try {
            setTableLoading(true)
            await bsliPut(url, load)
            setState(prev => {
                let copy = deepClone(prev)
                copy.filteredData[rownum][colnum][1].value = c

                let record = copy.extractedAccessData.find(r => r[0][0].value === recordId)
                if (record) {
                    if (c) {
                        record[colnum][1].value = true
                    } else {
                        record[colnum][1].value = false
                    }
                }
                return {
                    ...prev,
                    filteredData: copy.filteredData,
                    extractedAccessData: copy.extractedAccessData
                }
            })
            setTableLoading(false)
        } catch (error) {
            setTableLoading(false)
            displayAlert("Error happenned please try again.")
        }
    }

    const assignmensValuesOld = (modules) => {
        if (modules || modules.length === 0) return []
        return modules.map(m => m.lessons).reduce((pre, cur) => pre.concat(cur), [])
    }

    const assignmensValues = (modules) => {
        if (!modules || modules.length === 0) return []
        let res = modules.map(m => m.lessons)
        let lessons = res.reduce((pre, cur) => pre.concat(cur), [])
        return lessons
    }

    const extractAccessData = (record, assignments) => {
        if (!assignments || assignments.length === 0) {
            return ""
        }
        let id = [{ name: "id", value: record.id }]
        let name = [{ name: "Name", value: record.lastName + ", " + record.firstName }]
        let username = [{ name: "UserName", value: record.userName }]
        let aiCode = [{ name: "AiCode", value: record.aiCode }]
        let turnOffScoring = [{ name: "scoringDisabled", value: record.scoringDisabled }, {
            name: "recordId",
            value: record.id
        }]
        let values = [id, name, username, aiCode, turnOffScoring]

        assignments.forEach((a) => {
            if (record.approvedAssignmentIds.indexOf(a.assignmentId.toString()) > -1) {
                values.push([{ name: "approvedStatus", value: true }],
                  [{ name: "manuallyApprove", value: false },
                      { name: "manuallyApprovedStatus", value: false },
                      { name: "AssignmentId", value: a.assignmentId }])
            } else
                values.push([{ name: "approvedStatus", value: false }],
                  [{ name: "manuallyApprove", value: true },
                      {
                          name: "manuallyApprovedStatus",
                          value: (record.overriddenAssignmentIds.indexOf(a.assignmentId.toString()) > -1)
                      },
                      { name: "AssignmentId", value: a.assignmentId }])
        })
        values.push([{ name: "Email", value: record.email }])
        return values
    }

    const extractAllAccessData = (scoringAccessData, assignments) => {
        let res = scoringAccessData.map(record => extractAccessData(record, assignments))
        return res
    }

    const displayRow = (values, rownum) => {
        if (!values) return null
        let recordId = values[0][0].value
        let res = values.map((v, colnum) => {
            // logger.log('filtered data: ', state.filteredData[rownum][colnum][0])
            if (v[0].name !== "scoringDisabled" && v[0].name !== "approvedStatus" && v[0].name !== "manuallyApprove" && v[0].name !== "id") {
                return <td key={v[0].name + "_" + colnum}>
                    <div className="display-flex justify-content-center cb-align-center"> {v[0].value}</div>
                </td>
            } else if (v[0].name === "scoringDisabled") {
                return (
                  <td key={v[0].name + "_" + colnum}>
                      <div className="display-flex justify-content-center cb-align-center">
                          <Checkbox id={v[1].value} checked={state.filteredData[rownum][colnum][0].value}
                                    onChange={(c, e) => {
                                        handleScoringDisabled(c, e, rownum, colnum)
                                    }}
                                    ariaLabel="toggle scoring enebled" />
                      </div>
                  </td>
                )
            } else if (v[0].name === "approvedStatus") {
                if (v[0].value === false) {
                    return <td key={v[0].name + "_" + colnum} />
                } else {
                    return (
                      <td key={v[0].name + "_" + colnum}>
                          <div className="display-flex justify-content-center cb-align-center">
                              approved
                          </div>
                      </td>
                    )
                }
            } else if (v[0].name === "manuallyApprove") {
                if (v[0].value === false) {
                    return <td key={v[0].name + "_" + colnum} />
                } else {
                    return (
                      <td key={v[0].name + "_" + colnum}>
                          <div className="display-flex justify-content-center cb-align-center">
                              <Checkbox id={recordId + "-" + v[2].value}
                                        checked={state.filteredData[rownum][colnum][1].value}
                                        onChange={(c, e) => {
                                            handleManuallyApprove(c, e, rownum, colnum)
                                        }}
                                        ariaLabel="toggle manually approve" />
                          </div>
                      </td>
                    )
                }
            } else if (v[0].name !== "id") {
                return null
            }
        })
        res.shift()
        return res
    }

    const displayRows = () => {
        if (!state.filteredData || state.filteredData.length === 0) return null
        let res = state.filteredData.map((values, rownum) => {
            let recordId = values[0][0].value
            return <tr key={recordId}>{displayRow(values, rownum)}</tr>
        })

        return res
    }

    const filterData = (data, filter) => {
        let res = (!filter || filter === "") ? data : data.filter(
          (record) => {
              let ucasedName = record[1][0].value.toUpperCase()
              let ucasedFilter = filter.toUpperCase()
              return ucasedName.indexOf(ucasedFilter) > -1

          })
        if (res.length > 1) {
            res.sort(function(a, b) {
                let nameA = a[1][0].value.toUpperCase() // ignore upper and lowercase
                let nameB = b[1][0].value.toUpperCase() // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })
        }
        return {
            filteredData: res,
            sortColNum: 0,
            sortAsc: true
        }
    }

    const sortTable = (e, colnum, h) => {
        if (e) {
            e.preventDefault()
        }
        let sortAsc = !state.sortAsc
        if (colnum != state.sortColNum) {
            setState(prev => ({ ...prev, ["sortColNum"]: colnum, ["sortAsc"]: true }))
            sortAsc = true
        } else {
            setState(prev => ({ ...prev, ["sortAsc"]: sortAsc }))
        }

        let stringCols = ["Name", "Username", "Email"]
        let data = state.filteredData
        if (data.length > 1) {
            if (stringCols.indexOf(h) > -1) {
                data.sort(function(a, b) {
                    let nameA = a[colnum + 1][0].value.toUpperCase()
                    let nameB = b[colnum + 1][0].value.toUpperCase()
                    if (nameA < nameB) {
                        return sortAsc === true ? -1 : 1
                    }
                    if (nameA > nameB) {
                        return sortAsc === true ? 1 : -1
                    }
                    return 0
                })

            } else if (h === "AI Code") {
                data.sort(function(a, b) {
                    let nameA = a[colnum + 1][0].value
                    let nameB = b[colnum + 1][0].value
                    if (nameA < nameB) {
                        return sortAsc === true ? -1 : 1
                    }
                    if (nameA > nameB) {
                        return sortAsc === true ? 1 : -1
                    }
                    return 0
                })
            } else if (h === "Turn Off Scoring") {
                data.sort(function(a, b) {
                    let nameA = a[colnum + 1][0].value
                    let nameB = b[colnum + 1][0].value
                    if (nameA < nameB) {
                        return sortAsc === true ? -1 : 1
                    }
                    if (nameA > nameB) {
                        return sortAsc === true ? 1 : -1
                    }
                    return 0
                })

            } else if (h.startsWith("Scoring Status")) {
                data.sort(function(a, b) {
                    let nameA = a[colnum + 1][0].value
                    let nameB = b[colnum + 1][0].value
                    if (nameA < nameB) {
                        return sortAsc === true ? -1 : 1
                    }
                    if (nameA > nameB) {
                        return sortAsc === true ? 1 : -1
                    }
                    return 0
                })
            } else if (h.startsWith("Manually Approve")) {
                data.sort(function(a, b) {
                    let nameA = a[colnum + 1][1].value
                    let nameB = b[colnum + 1][1].value
                    if (nameA < nameB) {
                        return sortAsc === true ? -1 : 1
                    }
                    if (nameA > nameB) {
                        return sortAsc === true ? 1 : -1
                    }
                    return 0
                })
            }

            setState(prev => ({ ...prev, ["filteredData"]: data }))
            bounceState()
        }
    }

    const onFilterChange = (event) => {
        let elem = event.target
        let filter = elem.value
        let newState = _.extend({ filter: filter }, filterData(state.extractedAccessData, filter))
        setState(prev => ({ ...prev, ...newState  }))
        bounceState()
    }

    const bounceState = () => {
        let flag = state.flag
        setState(prev => ({ ...prev, ["flag"]: !flag }))
    }

    const clearAlert = () => {
        setState(prev => ({ ...prev, ["showAlert"]: false, ["alertMsg"]: "" }))
    }

    const displayAlert = (message) => {
        setState(prev => ({ ...prev, ["showAlert"]: true, ["alertMsg"]: message }))
    }

    const downloadAsCsv = () => {
        let filename = "Scoring-Provisioning-" + moment().format("YYYYMMDD") + ".csv"
        let rows = []
        let headers = state.headers
        if (!headers || headers.length === 0) {
            return
        }
        rows.push((headers.join(",")))

        let filteredData = state.filteredData
        if (filteredData && filteredData.length > 0) {
            filteredData.forEach((values, rownum) => {
                let row = extractRowForCsv(values, rownum)
                rows.push(row.join(","))
            })
        }
        let allrows = rows.join("\r\n")
        let blob = new Blob([allrows], { type: "text/csvcharset=utf-8" })
        if (navigator.msSaveBlob) { // IE >= 10
            navigator.msSaveBlob(blob, filename)
        } else {
            let link = document.createElement("a")
            link.setAttribute("download", filename)
            link.setAttribute("href", URL.createObjectURL(blob))
            link.dispatchEvent(new MouseEvent("click"))
        }
    }

    const extractRowForCsv = (values) => {
        return values.filter(v => v[0].name != "id").map((v) => {
            if (v[0].name != "scoringDisabled" && v[0].name != "approvedStatus" && v[0].name != "manuallyApprove" && v[0].name != "Name") {
                return v[0].value
            } else if (v[0].name === "scoringDisabled") {
                return v[0].value === true ? "TRUE" : ""
            } else if (v[0].name === "approvedStatus") {
                return v[0].value === true ? "approved" : ""
            } else if (v[0].name === "manuallyApprove") {
                return v[1].value === true ? "TRUE" : ""
            } else if (v[0].name === "Name") {
                return ("\"" + v[0].value + "\"")
            }
        })
    }

    const getCourses = async () => {
        let courses = await bsliGet("admin/current-course-instances")
        setProps({ courses })
    }

    useEffect(() => {
        getCourses()
    }, [])

    useEffect(() => {
        if (props) setLoading(false)
    }, [props])

    if (loading) return <Spinner />

    return (
      <div>
          <h4 className="cb-spacerh-left-8">Teacher Scoring Provisioning</h4>
          <div className="cb-spacerv-bottom-16 cb-spacerh-left-8">
              <span>To view the Provision Teacher Scoring dashboard, please select a course.</span>
          </div>
          <div className="row cb-spacerv-bottom-16 ">
              <div className="cb-input form-group col-xs-4">
                  <Select
                    id="courses"
                    name="courses"
                    ariaLabel="Select Course"
                    values={courseLabelValues()}
                    onChange={getScoringAccessDataHandler}
                    value={state.courseInstanceId}
                  />
              </div>
              <div className="cb-input form-group col-xs-4">
                  <Input maxLength="100"
                         placeholder="Search By Teacher Name" aria-label="Enter a string to find a user name"
                         id="filter"
                         defaultValue={state.filter}
                         clearable={false}
                         onChange={onFilterChange}
                         disabled={state.courseInstanceId === "" || state.courseInstanceId === "-1"} />
              </div>
              <div className="cb-input form-group col-xs-4">
                  <div className="cb-float-right">
                      <BlackButton disabled={!state.filteredData || state.filteredData.length === 0} onClick={downloadAsCsv}>
                          Download as .csv
                      </BlackButton>
                  </div>
              </div>
          </div>
          {showAlert(state.showAlert, state.alertMsg)}
          {tableLoading ? <Spinner /> : null}
          {tableLoading ? null : showTable(state.extractedAccessData)}
      </div>
    )
}

export default TSP
