import React, { Fragment, useEffect }                       from 'react'
import { Button, BlackButton, YellowButton }                from '@cb/apricot-react'
import { getCourseInstanceByCurrentSectionId, getCurrentSection } from "../../../entities/courseinstance";

export function ArtButtonBar({ save, isSaving, isFinalSaving, finalDate, canSave, canFinalSave, finalSubmit, scoringEnabled }) {

    let isFinalSubmitted = finalDate.length > 0
    const finalSubmittedAria = 'this assignment has been final submitted and cannot be edited'
    const passedSoftEndDate = new Date(getCourseInstanceByCurrentSectionId().softEndDate) < new Date() && !getCurrentSection().enrollment.hasExtension

    return (
        <div>
            <span className='sr-only'>{isFinalSubmitted ? finalSubmittedAria : ''}</span>
            <div className='buttonBar'>
                <span className='button'>
                    {!isSaving && !isFinalSubmitted &&
                        <BlackButton
                            onClick={save}
                            disabled={!canSave}
                            className='js-upload' small='true'
                            data-cb-apricot='tooltip' id='save'
                            data-cb-title="Save Changes">
                            Save
                        </BlackButton>
                    }

                    {isSaving && isFinalSaving &&
                        <BlackButton
                            onClick={save}
                            disabled={true}
                            className='js-upload' small='true'
                            data-cb-apricot='tooltip' id='save'
                            data-cb-title="Save Changes">
                            Save
                        </BlackButton>
                    }

                    {isSaving && !isFinalSaving &&
                        <Button small='true' loading>Loading</Button>
                    }
                </span>
                {scoringEnabled &&
                    <span className='button'>
                        {!isFinalSaving && !isFinalSubmitted &&
                            <YellowButton
                                onClick={finalSubmit}
                                disabled={!canFinalSave || isFinalSubmitted || !scoringEnabled || isSaving || passedSoftEndDate}
                                className='js-submit' small='true'
                                data-cb-apricot='tooltip' id='submit'
                                data-cb-title="Submit As Final">
                                Submit Final
                            </YellowButton>
                        }
                        {isFinalSaving && !isFinalSubmitted &&
                            <Button small='true' loading>Loading</Button>
                        }
                    </span>
                }
            </div>
            {isFinalSubmitted &&
                <div className={'cb-gray9-bg add-padding'}>
                    <div>
                        <strong className={'cb-green1-color btn-sm-align'}>
                            <span className="cb-glyph cb-check" aria-hidden="true" />
                            <span> Final submitted {finalDate}</span>
                        </strong>
                    </div>
                </div>
            }
        </div>
    )
}
